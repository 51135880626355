/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import ReactPlayer from 'react-player';
import axios from 'axios';
import * as Icon from 'react-feather';

import countrys from '../../../config/pais';
import Modal from '../../ModalVideo';
import { app } from '../../../config/firebaseConfig';

import {
  DATOSGENERELES,
  REDESVISITADAS,
  REDESCHAMOPRESTA,
} from '../../../redux/types';

import { getSociales } from '../../../redux/actions/userAction';
import { isURL } from '../../../helpers/generales';
import { custom } from '../../../helpers/alertas';

const DatosGenerales = () => {
  const dispatch = useDispatch();

  //========== USE-STATES ==========//
  const [area, setArea] = useState([]);
  const [editarSocial, setEditarSocial] = useState(false);
  const [redVisitada, setRedVisitada] = useState([]);
  const [generales, setGenerales] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    fechaNacimiento: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: 'Venezuela - Transferencia: Cuenta Bancaria BDV',
    nombres: '',
    nombreEmpresa: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    sexo: 'M',
    telefonoOpcional: '',
    telegram: '',
    trabaja: 'No',
    whatsapp: '',
  });
  const [social, setSocial] = useState({
    fechaValidacion: null,
    gestor: null,
    id: '',
    idGestor: null,
    idSocial: '',
    idUsuario: app.auth().currentUser.uid,
    solicitudConfirmada: false,
    solicitudEnviada: false,
    tipo: '',
    url: '',
    validado: '',
  });
  const [redesSociales, setRedesSociales] = useState([]);
  const [defaultValue, setDefaultValue] = useState({
    value: generales.pais,
    label: generales.pais,
  });
  const [video, setVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalFacebook, setShowModalFacebook] = useState(false);

  //Mostrar listado de paises
  let arrayOptions = [];
  arrayOptions = countrys.listado.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  const [option, setOption] = useState(arrayOptions);

  //========== USE-SELECTORS ==========//
  const sociales = useSelector((state) => state.user.linksociales);
  const prestamoActual = useSelector((state) => state.user.prestamoActual);

  useEffect(() => {
    getGeoInfo();
    getDatosGenerales();
    dispatch(getSociales());
    getRedesChamopresta();
    getDatos();
  }, []);

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        let filter = countrys.listado.filter(
          (fila) => fila.nombre === data.country_name
        );
        //setGenerales({ ...generales, ["pais"]: data.country_name, ["codigoPais"]: filter[0].codigo })
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // metodo para llamar area trabajo
  const getDatos = async () => {
    await app
      .firestore()
      .collection('areaTrabajo')
      .orderBy('area', 'asc')
      .onSnapshot((query) => {
        const listado = [];
        query.forEach((doc) => {
          listado.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setArea(listado);
      });
  };

  const getDatosGenerales = (e) => {
    app
      .firestore()
      .collection('usuarios')
      .doc(`${app.auth().currentUser.uid}`)
      .get()
      .then((doc) => {
        let data;
        if (doc.exists) {
          data = {
            apellidos: doc.data().apellidos,
            areaTrabajo: doc.data().areaTrabajo,
            codigoPais:
              generales.codigoPais !== ''
                ? generales.codigoPais
                : doc.data().codigoPais,
            descripcionActividad: doc.data().descripcionActividad,
            fechaNacimiento: doc.data().fechaNacimiento,
            ingresoMensualUSD: doc.data().ingresoMensualUSD,
            localidad: doc.data().localidad,
            nombres: doc.data().nombres,
            numeroCedula: doc.data().numeroCedula,
            nombreEmpresa: doc.data().nombreEmpresa,
            numeroPasaporte: doc.data().numeroPasaporte,
            pais: generales.pais !== '' ? generales.pais : doc.data().pais,
            sexo: doc.data().sexo,
            telefonoOpcional: doc.data().telefonoOpcional,
            telegram: doc.data().telegram,
            trabaja: doc.data().trabaja,
            whatsapp: doc.data().whatsapp,
          };
          setGenerales(data);
          dispatch({ type: DATOSGENERELES, payload: data });
        } else {
          data = null;
          dispatch({ type: DATOSGENERELES, payload: data });
        }
      });
  };
  const changeGenerales = (e) => {
    const { name, value } = e.target;
    let dataUpdate = { ...generales, [name]: value };
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };
  const changePais = (e) => {
    const { value } = e.target;
    let filter = countrys.listado.filter((fila) => fila.nombre === value);
    setGenerales({
      ...generales,
      ['pais']: value,
      ['codigoPais']: filter[0].codigo,
    });
    dispatch({ type: DATOSGENERELES, payload: generales });
  };
  const changeValuePais = (e) => {
    setDefaultValue(e.label);
    const value = e.value;
    const label = e.label;
    setGenerales({
      ...generales,
      ['pais']: label,
      ['codigoPais']: value,
    });
    dispatch({ type: DATOSGENERELES, payload: generales });
  };
  const changeSexo = (e) => {
    const { value } = e.target;
    let dataUpdate = { ...generales, ['sexo']: value };
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };
  const ChangeTrabaja = (e) => {
    const { value } = e.target;
    let dataUpdate;
    if (value === 'No') {
      dataUpdate = {
        ...generales,
        ['trabaja']: value,
        ['areaTrabajo']: '',
        ['nombreEmpresa']: '',
        ['descripcionActividad']: '',
        ['ingresoMensualUSD']: '',
      };
    } else {
      dataUpdate = { ...generales, ['trabaja']: value };
    }
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };
  const changeSocial = (e) => {
    const { name, value } = e.target;
    setSocial({ ...social, [name]: value });
    dispatch({ type: DATOSGENERELES, payload: generales });
  };

  // const updateModel = (e) => {
  //   const { name, value } = e.target;
  //   const data = { ...generales, [name]: value };
  //   dispatch({ type: DATOSGENERELES, payload: data });
  // };

  const guardarSocial = (e) => {
    e.preventDefault();
    if (editarSocial) {
      if (social.url === '') {
        custom('Debes llenar el campo url', 'error');
      } else {
        // if (!isURL(social.url)) {
        //   custom('Dirección de red social incorrecta', 'error');
        // } else if (!validateFacebookInstagram(social.url)) {
        //   custom(
        //     'Solo se pueden ingresar perfiles de Facebook o Instagram',
        //     'error'
        //   );
        // } else {
        //   updateSocial();
        // }
        updateSocial();
      }
    } else {
      if (social.url === '') {
        custom('Debes llenar el campo url', 'error');
      } else {
        // if (!isURL(social.url)) {
        //   custom("Dirección de red social incorrecta", "error");
        // } else if (!validateFacebookInstagram(social.url)) {
        //   custom(
        //     "Solo se pueden ingresar perfiles de Facebook o Instagram",
        //     "error"
        //   );
        // } else if (searchLink(social.url)) {
        //   custom(
        //     "La url que intentas agregar ya se encuentra registrada.",
        //     "error"
        //   );
        // } else {
        //   addSocial();
        // }
        addSocial();
      }
    }
  };
  const buildType = (str) => {
    if (str.includes('facebook') || str.includes('fb')) {
      return 'facebook';
    } else if (str.includes('instagram')) {
      return 'instagram';
    } else {
      return '';
    }
  };
  const addSocial = () => {
    let data = social;
    data.tipo = buildType(data.url);
    app
      .firestore()
      .collection('sociales')
      .add(data)
      .then((resp) => {
        dispatch(getSociales());
        setSocial({
          fechaValidacion: null,
          gestor: null,
          id: '',
          idGestor: null,
          idSocial: '',
          idUsuario: app.auth().currentUser.uid,
          solicitudConfirmada: false,
          solicitudEnviada: false,
          tipo: '',
          url: '',
          validado: '',
        });
        setEditarSocial(false);
      });
  };
  const searchLink = (url) => {
    let filter = sociales.filter((row) => row.url === url);
    if (filter.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const updateSocial = () => {
    let data = social;
    data.tipo = buildType(data.url);
    app
      .firestore()
      .collection('sociales')
      .doc(`${social.id}`)
      .update(data)
      .then((resp) => {
        dispatch(getSociales());
        setSocial({
          fechaValidacion: null,
          gestor: null,
          id: '',
          idGestor: null,
          idSocial: '',
          idUsuario: app.auth().currentUser.uid,
          solicitudConfirmada: false,
          solicitudEnviada: false,
          tipo: '',
          url: '',
          validado: '',
        });
        setEditarSocial(false);
      });
  };
  const editarLinkSocial = (item) => {
    setSocial(item);
    setEditarSocial(true);
  };
  const validateFacebookInstagram = (str) => {
    if (
      str.includes('facebook') ||
      str.includes('fb') ||
      str.includes('instagram')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const facebook_url = (e) => {
    let url = e.target.value;
    let FBurl = /^(http|https)\:\/\/www.facebook.com\/.*/i;

    if (!url.match(FBurl)) {
      alert('This is not a Facebook URL');
    } else {
      alert('This IS a Facebook URL');
    }
  };

  const instagram_url = (e) => {
    // let url = e.target.value;
    // let p = /^\s*(http\:\/\/)?instagram\.com\/[a-z\d-_]{1,255}\s*$/i;
    // if (url.match(p)) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const cancel = () => {
    setSocial({
      fechaValidacion: null,
      idGestor: null,
      id: '',
      idSocial: '',
      idUsuario: app.auth().currentUser.uid,
      tipo: '',
      url: '',
      validado: '',
    });
    setEditarSocial(false);
  };
  const getRedesChamopresta = () => {
    app
      .firestore()
      .collection('redesSocialesEmpresa')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => {
          array.push(doc.data());
        });
        setRedesSociales(array);
        dispatch({ type: REDESCHAMOPRESTA, payload: array });
      });
  };
  const enviarSolicitud = (index) => {
    let array = [...redVisitada];
    if (redVisitada.length === 0) {
      array.push(index);
      setRedVisitada(array);
    } else {
      const filter = redVisitada.filter((fila) => fila === index);
      if (filter.length === 0) {
        array.push(index);
        setRedVisitada(array);
      }
    }
    dispatch({ type: REDESVISITADAS, payload: array });
  };
  const disableAreaTrabajo = () => {
    if (generales.trabaja === 'Si') {
      return false;
    }
    return true;
  };
  const disableSalario = () => {
    if (generales.trabaja === 'Si') {
      return false;
    }
    return true;
  };
  let placeHolderSocial = () => {
    if (sociales.length === 0) {
      return 'Ejemplo: https://www.facebook.com/alguien ó https://www.instagram.com/alguien';
    } else if (sociales.length === 2) {
      return 'Redes sociales cargadas correctamente';
    } else {
      return sociales.map((ele) =>
        ele.tipo === 'facebook'
          ? `Siguiente: https://www.instagram.com/alguien`
          : 'Siguiente: https://www.facebook.com/alguien'
      );
    }
  };

  const openVideo = () => {
    setVideo(true);
    setShowModal(true);
  };
  const openVideoFacebook = () => {
    setVideo(true);
    setShowModalFacebook(true);
  };
  const close = () => {
    setShowModal(false);
  };

  let placeholder =
    generales.pais === ''
      ? 'Seleccione  su país de residencia'
      : `Seleccione el pais registrado... ${generales.pais}`;

  return (
    <>
      <div className='col-span-12'>
        <div className='intro-y pr-1 chat'>
          {prestamoActual && (
            <div className='chat__tabs nav-tabs justify-center flex'>
              <a
                id='tabpersonales'
                href='#!'
                data-target='#personales'
                onClick={(e) => e.preventDefault()}
                className={[
                  'flex-1',
                  'py-2',
                  'rounded-md',
                  'text-center',
                  prestamoActual.tab === 'tabpersonales' ? 'active' : '',
                ].join(' ')}
              >
                Datos Personales
              </a>
              <a
                id='tabubicacion'
                href='#!'
                data-target='#ubicacion'
                onClick={(e) => e.preventDefault()}
                className={[
                  'flex-1',
                  'py-2',
                  'rounded-md',

                  'text-center',
                  prestamoActual.tab === 'tabubicacion' ? 'active' : '',
                ].join(' ')}
              >
                Datos de Ubicación
              </a>
              <a
                id='tablaborales'
                href='#!'
                data-target='#laborales'
                onClick={(e) => e.preventDefault()}
                className={[
                  'flex-1',
                  'py-2',
                  'rounded-md',
                  'text-center',
                  prestamoActual.tab === 'tablaborales' ? 'active' : '',
                ].join(' ')}
              >
                Datos Laborales
              </a>
              <a
                id='tabsociales'
                href='#!'
                data-target='#sociales'
                onClick={(e) => e.preventDefault()}
                className={[
                  'flex-1',
                  'py-2',
                  'rounded-md',
                  'text-center',
                  prestamoActual.tab === 'tabsociales' ? 'active' : '',
                ].join(' ')}
              >
                Redes Sociales
              </a>
            </div>
          )}
        </div>
        {prestamoActual && (
          <div className='tab-content'>
            {/* Datos Personales */}
            <div
              className={[
                'tab-content__pane',
                prestamoActual.tab === 'tabpersonales' ? 'active' : '',
              ].join(' ')}
              id='personales'
            >
              <div className='intro-y' id='data_personal'>
                <form action='' className='mt-8 mb-8'>
                  <div className='grid grid-cols-12 gap-4 row-gap-5'>
                    <div className='intro-y col-span-12 sm:col-span-12'>
                      <div className='font-medium text-gray-900 text-center'>
                        Servicio disponible solo para mayores de 22 años.
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Nombres completos :
                      </label>
                      <input
                        type='text'
                        name='nombres'
                        value={generales.nombres}
                        className='w-full rounded-full border intro-x input input--sm border mt-2'
                        autoFocus={true}
                        placeholder=' Nombres Completos'
                        onChange={changeGenerales}
                        onKeyPress={(event) => {
                          if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Apellidos completos :
                      </label>
                      <input
                        type='text'
                        name='apellidos'
                        value={generales.apellidos}
                        className=' w-full rounded-full border intro-x input input--sm border mt-2'
                        placeholder='Apellidos Completos'
                        onChange={changeGenerales}
                        onKeyPress={(event) => {
                          if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Cédula de identidad Venezolana :
                      </label>
                      <input
                        type='text'
                        name='numeroCedula'
                        value={generales.numeroCedula}
                        className='w-full rounded-full border intro-x input input--sm border mt-2'
                        placeholder='Numero de Cedula'
                        onChange={changeGenerales}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Pasaporte (Opcional) :
                      </label>
                      <input
                        type='text'
                        name='numeroPasaporte'
                        value={generales.numeroPasaporte}
                        className='w-full rounded-full border intro-x input input--sm border mt-2'
                        placeholder='Numero de pasaporte'
                        onChange={changeGenerales}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Fecha de nacimiento :
                      </label>
                      <input
                        type='date'
                        name='fechaNacimiento'
                        value={generales.fechaNacimiento}
                        className='input input--sm w-56 border block rounded-full mt-2'
                        onChange={changeGenerales}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Sexo :
                      </label>
                      <div className='flex flex-col sm:flex-row mt-2 mb-2'>
                        <div className='flex items-center text-gray-700 mr-2'>
                          <div className='flex items-center text-gray-700 mt-2'>
                            <input
                              type='radio'
                              className='input border mr-2'
                              id='mas'
                              name='sexo'
                              value='M'
                              checked={generales.sexo === 'M'}
                              onChange={changeSexo}
                            />
                            <label htmlFor='mas'>M</label>
                          </div>
                          <div className='flex items-center text-gray-700 mt-2'>
                            <input
                              type='radio'
                              className='input border ml-2 mr-2'
                              id='fem'
                              name='sexo'
                              value='F'
                              checked={generales.sexo === 'F'}
                              onChange={changeSexo}
                            />
                            <label htmlFor='fem'>F</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* Datos de ubicación */}
            <div
              className={[
                'tab-content__pane',
                prestamoActual.tab === 'tabubicacion' ? 'active' : '',
              ].join(' ')}
              id='ubicacion'
            >
              <div className='intro-y' id='data_location'>
                <form action='' className='mt-8 mb-10'>
                  <div className='grid grid-cols-12 gap-4 row-gap-5'>
                    <div className='intro-y col-span-12 sm:col-span-12'>
                      <div className='font-medium text-gray-900 text-center'>
                        Usted puede solicitar un préstamo desde cualquier país.
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        País :
                      </label>
                      <select
                        className='w-full rounded-lg border intro-x input input--sm border mt-2'
                        autoFocus={true}
                        name='pais'
                        value={generales.pais}
                        onChange={changePais}
                      >
                        <option className='bg-gray-100' value=''>
                          Seleccione país ...
                        </option>
                        {countrys.listado.map((item, k) => (
                          <option
                            className='bg-gray-100'
                            value={item.nombre}
                            key={k}
                          >
                            {item.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Localidad :
                      </label>
                      <input
                        type='text'
                        name='localidad'
                        value={generales.localidad}
                        className=' w-full rounded-full border intro-x input input--sm border mt-2'
                        placeholder='Provincia-Estado...'
                        onChange={changeGenerales}
                        onKeyPress={(event) => {
                          if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Whatsapp :
                      </label>
                      <div className='flex'>
                        <select
                          className='w-20 mr-2 rounded-lg border intro-x input input--sm border mt-2'
                          name='codigoPais'
                          value={generales.codigoPais}
                          onChange={changeGenerales}
                        >
                          <option value=''>00</option>
                          {countrys.listado.map((item, k) => (
                            <option value={item.codigo} key={k}>
                              {item.codigo}
                            </option>
                          ))}
                        </select>
                        <input
                          type='text'
                          name='whatsapp'
                          value={generales.whatsapp}
                          className='w-full rounded-full border intro-x input input--sm border mt-2'
                          placeholder='Numero de Whatsapp'
                          onChange={changeGenerales}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Número contacto : (Adicional)
                      </label>
                      <input
                        type='text'
                        name='telefonoOpcional'
                        value={generales.telefonoOpcional}
                        className='w-full rounded-full border intro-x input input--sm border mt-2'
                        placeholder='Otro número donde pueda ser contactado'
                        onChange={changeGenerales}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {/* <div className='intro-y col-span-12 sm:col-span-4'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Usuario telegram : (Opcional)
                      </label>
                      <input
                        type='text'
                        name='telegram'
                        value={generales.telegram}
                        className='w-full rounded-full border intro-x input input--sm border mt-2'
                        placeholder='Telegram'
                        onChange={changeGenerales}
                        onKeyPress={(event) => {
                          if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
            {/* Datos Laborales */}
            <div
              className={[
                'tab-content__pane',
                prestamoActual.tab === 'tablaborales' ? 'active' : '',
              ].join(' ')}
              id='laborales'
            >
              <div className='intro-y flex-1'>
                <form action='' className='mt-8 mb-8'>
                  <div className='grid grid-cols-12 gap-4 row-gap-5'>
                    <div className='intro-y col-span-12 sm:col-span-12'>
                      <div className='font-medium text-gray-900 text-center'>
                        Si cuentas con un trabajo o un emprendimiento,
                        suministra los detalles.
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-2'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Trabaja :
                      </label>
                      <div className='flex flex-col sm:flex-row mt-2 mb-2'>
                        <div className='flex items-center text-gray-700 mt-2'>
                          <input
                            type='radio'
                            className='input border mr-2'
                            id='trabajaSi'
                            name='trabaja'
                            value='Si'
                            checked={generales.trabaja === 'Si'}
                            onChange={ChangeTrabaja}
                          />
                          <label htmlFor='trabajaSi'>Si</label>
                        </div>
                        <div className='flex items-center text-gray-700 mt-2'>
                          <input
                            type='radio'
                            className='input border ml-2 mr-2'
                            id='trabajaNo'
                            name='trabaja'
                            value='No'
                            checked={generales.trabaja === 'No'}
                            onChange={ChangeTrabaja}
                          />
                          <label htmlFor='trabajaNo'>No</label>
                        </div>
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-5'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Área de trabajo :
                      </label>
                      <select
                        type='text'
                        className='w-full rounded-full border intro-x input input--sm  border mt-2'
                        aria-label='Default select example'
                        name='areaTrabajo'
                        value={generales.areaTrabajo}
                        disabled={disableAreaTrabajo()}
                        onChange={changeGenerales}
                      >
                        <option value='{true}'>Seleccione...</option>
                        {area.map((elemento, i) => (
                          <option key={i} className='flex justify-center'>
                            {elemento.area}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-5'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Nombre de empresa o emprendimiento :
                      </label>
                      <input
                        type='text'
                        className=' w-full rounded-full border intro-x input input--sm border mt-2'
                        name='nombreEmpresa'
                        value={generales.nombreEmpresa}
                        placeholder='Nombre de la empresa...'
                        onChange={changeGenerales}
                        disabled={disableAreaTrabajo()}
                        onKeyPress={(event) => {
                          if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Ingreso mensual promedio dólares :
                      </label>
                      <input
                        type='number'
                        className=' w-full rounded-full border intro-x input input--sm border mt-2'
                        name='ingresoMensualUSD'
                        value={generales.ingresoMensualUSD}
                        placeholder='Ingreso en dólares...'
                        onChange={changeGenerales}
                        disabled={disableSalario()}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className='col-span-12 sm:col-span-6'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Descripción de las actividades :
                      </label>
                      <textarea
                        rows='2'
                        cols='40'
                        wrap='hard'
                        type='text'
                        className='input input--sm w-full border mt-2 rounded-lg'
                        name='descripcionActividad'
                        value={generales.descripcionActividad}
                        onChange={changeGenerales}
                        disabled={disableAreaTrabajo()}
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* Datos Sociales */}
            <div
              className={[
                'tab-content__pane',
                prestamoActual.tab === 'tabsociales' ? 'active' : '',
              ].join(' ')}
              id='sociales'
            >
              <div className='intro-y flex-1'>
                <form className='mt-8' onSubmit={guardarSocial}>
                  <div className='grid grid-cols-12 gap-4 row-gap-5'>
                    <div className='intro-y col-span-12 sm:col-span-12 mb-3'>
                      <div className='font-medium text-gray-900 text-center'>
                        Necesitamos saber cómo poder encontrarte en Facebook e
                        Instagram, agregar tus enlaces sociales.
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-6 flex'>
                      <img
                        src='/botonTutorial.png'
                        alt='Play'
                        className='cursor-pointer w-6 h-6'
                        onClick={openVideo}
                      />
                      <span
                        href='#'
                        className='font-montserrat font-medium text-sm ml-3 mt-1 cursor-pointer'
                        onClick={openVideo}
                      >
                        Tutorial para obtener tu enlace de Instagram
                      </span>
                      <Icon.Instagram className='text-theme-6 w-5 h-5 ml-2 mt-1' />
                    </div>{' '}
                    <div className='intro-y col-span-12 sm:col-span-6 flex'>
                      <img
                        src='/botonTutorial.png'
                        alt='Play'
                        className='cursor-pointer w-6 h-6'
                        onClick={openVideoFacebook}
                      />

                      <span
                        href='#'
                        className='font-montserrat font-medium text-sm ml-3 mt-1 cursor-pointer'
                        onClick={openVideoFacebook}
                      >
                        Tutorial para obtener tu enlace de Facebook
                      </span>
                      <Icon.Facebook className='text-theme-3 w-5 h-5 ml-2 mt-1' />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-9 mt-3'>
                      <label className='mb-4'>
                        Indique las URLs de su facebook e instagram para validar
                        su identidad.
                      </label>
                      <input
                        type='text'
                        name='url'
                        value={social.url}
                        className='w-full rounded-full border intro-x input input--sm border mt-1'
                        placeholder={placeHolderSocial()}
                        // "Ejemplo: https://www.facebook.com/alguien"
                        onChange={changeSocial}
                      />
                    </div>
                    <div
                      className='intro-y col-span-12 sm:col-span-3 text-center mb-5'
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <div className='flex'>
                        <button className='flex button w-auto text-white bg-theme-1 xl:mr-3 p-2 items-center'>
                          <Icon.Save className='w-4 h-4 mr-1' />
                          Guardar
                        </button>
                        {editarSocial && (
                          <button
                            className='flex button w-auto text-white bg-theme-6 xl:mr-3 p-2 items-center'
                            onClick={cancel}
                          >
                            <Icon.XSquare className='w-4 h-4 mr-1' />
                            Cancelar
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <div className='grid grid-cols-12 gap-4 row-gap-5 overflow-auto lg:overflow-visible'>
                  <div className='intro-y col-span-12 sm:col-span-12'>
                    <table className='table table-report mt-2 bg-theme-2 box'>
                      <thead className='bg-theme-26 box'>
                        <tr>
                          <th className='whitespace-no-wrap'>URL</th>
                          <th className='whitespace-no-wrap'>Tipo</th>
                          <th className='text-center whitespace-no-wrap'>
                            ACCIONES
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sociales.map((item, i) => (
                          <tr className='intro-x' key={i}>
                            <td>{item.url}</td>
                            <td>{item.tipo}</td>
                            <td className='table-report__action w-56'>
                              <div className='flex justify-center items-center text-theme-3'>
                                <a
                                  className='flex items-center mr-3'
                                  href='#!'
                                  onClick={() => editarLinkSocial(item)}
                                >
                                  <Icon.CheckSquare className='w-4 h-4 mr-1' />
                                  Editar
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                        {sociales.length === 0 && (
                          <tr>
                            <td colSpan='3' className='text-center'>
                              No se han registrado redes sociales aún.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {sociales.length > 1 && (
                  <div className='grid grid-cols-12 gap-4 row-gap-5'>
                    <div className='intro-y col-span-12 sm:col-span-12'>
                      <div className='flex flex-col w-full bg-theme-26 p-2 rounded-sm mt-1'>
                        <p className='font-medium text-sm'>
                          IMPORTANTE: Debes enviarnos solicitudes desde las
                          redes sociales registradas, esto para validar tu
                          identidad y aprobar el préstamo.
                        </p>
                      </div>

                      <div className='flex flex-col w-full bg-theme-26 p-2 rounded-sm mt-1'>
                        <p className='font-medium text-sm mt-1'>
                          A continuación listado de redes sociales de
                          ChamoPresta.com, presiona en cada una para visitarlas
                          y enviar las solicitudes de amistad y seguimiento.
                        </p>
                      </div>
                      <div className='flex flex-col w-full p-3 box mt-2 overflow-auto lg:overflow-visible'>
                        {redesSociales.map((item, k) => (
                          <div
                            className='flex justify-between h-full p-2 items-center'
                            key={k}
                          >
                            <p className='font-medium text-sm'>
                              <b className='flex'>
                                {item.tipo === 'facebook' && (
                                  <Icon.Facebook className='text-theme-3 w-5 h-5 mr-2' />
                                )}
                                {item.tipo === 'instagram' && (
                                  <Icon.Instagram className='text-theme-6 w-5 h-5 mr-2 items-center' />
                                )}
                                <a
                                  href={item.url}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  Cuenta de {item.tipo} : {item.url}
                                </a>
                              </b>{' '}
                            </p>
                            <div>
                              <a
                                className='flex button w-auto text-white bg-theme-3 ocultar'
                                href={item.url}
                                target='_blank'
                                rel='noopener noreferrer'
                                onClick={(e) => enviarSolicitud(k)}
                              >
                                Ir al link
                                {item.tipo === 'facebook' && (
                                  <Icon.Facebook className='text-theme-2 w-4 h-4 ml-2' />
                                )}
                                {item.tipo === 'instagram' && (
                                  <Icon.Instagram className='text-theme-6 w-4 h-4 ml-2' />
                                )}
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* ************MODAL************  */}
        {showModal && (
          <Modal
            title='Video Informativo'
            close={close}
            customBtn={true}
            textcustomBtn='Cerrar'
            size='xl'
            save={() => {
              setShowModal(false);
            }}
          >
            {video && (
              <ReactPlayer
                url={require('../../../videos/tutorialInstagram.mp4')}
                width='100%'
                controls
                playing
              />
            )}
          </Modal>
        )}
        {showModalFacebook && (
          <Modal
            title='Video Informativo'
            close={close}
            customBtn={true}
            textcustomBtn='Cerrar'
            size='xl'
            save={() => {
              setShowModalFacebook(false);
            }}
          >
            {video && (
              <ReactPlayer
                url={require('../../../videos/tutorialFacebook.mp4')}
                width='100%'
                controls
                playing
              />
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

export default DatosGenerales;
