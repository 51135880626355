/* eslint-disable no-useless-computed-key */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import $ from 'jquery';
import feather from 'feather-icons';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Selfi from '../../../statics/images/upload_selphi.png';
import Document from '../../../statics/images/document.png';
import Comprobante from '../../../statics/images/comprobante.png';
import Perfil from '../../../statics/images/selfie_prest-1.jpeg';

import { app } from '../../../config/firebaseConfig';
import { custom } from '../../../helpers/alertas';
import { LOADING, ENDLOADING, GETCHAT } from '../../../redux/types';
import { addLog } from '../../../redux/actions/sesionAction';
import { createChat } from '../../../redux/actions/userAction';
import {
  getRequerimientosUsuarios,
  getRequerimientoPrestamo,
  addChat,
  sendMsg,
} from '../../../redux/actions/gestorActions';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../../config/api';

const CargaRequerimientos = ({ prestamo }) => {
  const dispatch = useDispatch();
  let { UserId } = useParams();
  let idPrestamo = prestamo.id;
  const fileSelfieRef = useRef(null);
  const fileSelfiePrestRef = useRef(null);
  const fileDocumentRef = useRef(null);
  const fileComprobanteRef = useRef(null);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const requerimientosUsuarios = useSelector(
    (state) => state.gestor.requerimientosUsuarios
  );
  const requerimientoPrestamo = useSelector(
    (state) => state.gestor.requerimientoPrestamo
  );
  const chat = useSelector((state) => state.gestor.chat);

  //========== USE-STATES ==========//
  const [administrativos, setAdministrativos] = useState([]);

  useEffect(() => {
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 });
      $('.tooltip').each(function () {
        let options = {
          delay: 0,
          theme: 'tooltipster-borderless',
          multiple: true,
        };
        if ($(this).data('event') === 'on-click') {
          options.trigger = 'click';
        }
        if ($(this).data('theme') === 'light') {
          options.theme = 'tooltipster-shadow';
        }
        if ($(this).data('side') !== undefined) {
          options.side = $(this).data('side');
        }
        $(this).tooltipster(options);
      });
    })($);
    dispatch(getRequerimientosUsuarios(UserId));
    dispatch(getRequerimientoPrestamo(idPrestamo));
  }, []);

  useEffect(() => {
    const leerChats = () => {
      app
        .firestore()
        .collection('chat')
        .where('idPrestamo', '==', `${idPrestamo}`)
        .onSnapshot(manejarSnapshotChat);
    };
    leerChats();
    userAdministrativos();
  }, []);

  function manejarSnapshotChat(snapshot) {
    const cuentas = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      dispatch({ type: GETCHAT, payload: obj });
      return obj;
    });
  }

  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Gestor'])
      // .where('rol', '==', 'SuperAdmin')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
      });
  };

  //========== Funciones de Envio Correo y Mensaje ==========//
  // const enviarNotificacion = async (data) => {
  //   return await axiosInstance.post(`/notifications/sendmail`, data);
  // };
  // const enviarMesaje = (mensaje, rol) => {
  //   let mensajesArray = [];
  //   if (chat) {
  //     let newChat = {
  //       ...chat,
  //       ['fechaActualizacion']: moment(new Date()).format(
  //         'YYYY-MM-DD HH:mm:ss'
  //       ),
  //       ['idChat']: chat.id,
  //     };
  //     mensajesArray = [...chat.mensajes];
  //     mensajesArray.push({
  //       fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  //       rol,
  //       texto: mensaje,
  //     });
  //     dispatch(sendMsg(newChat, mensajesArray));
  //   } else {
  //     let chat = {
  //       fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  //       fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  //       idChat: '',
  //       idPrestamo: idPrestamo,
  //       mensajes: [
  //         {
  //           fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  //           rol,
  //           texto: mensaje,
  //         },
  //       ],
  //     };
  //     dispatch(addChat(chat));
  //   }
  // };

  //========== Funciones de Carga de imagenes y requerimientos ==========//
  const openExplorerSelfie = (e) => {
    fileSelfieRef.current.click();
  };
  const openExplorerSelfiePrest = (e) => {
    fileSelfiePrestRef.current.click();
  };
  const openExplorerDocumento = (e) => {
    fileDocumentRef.current.click();
    dispatch(
      addLog({
        accion: `${'ha Subido su cedula de identidad '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const openExplorerComprobante = (e) => {
    fileComprobanteRef.current.click();
  };
  const loadSelfie = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    console.log('File', file);
    if (requerimientosUsuarios.length > 0) {
      if (requerimientosUsuarios[0].selfieCedula === '') {
        upload(file, 'selfieCedula');
      } else {
        let filename = getImageName(requerimientosUsuarios[0].selfieCedula);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'selfieCedula');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'selfieCedula');
    }
  };
  const loadDocument = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientosUsuarios.length > 0) {
      if (requerimientosUsuarios[0].documento === '') {
        upload(file, 'documento');
      } else {
        let filename = getImageName(requerimientosUsuarios[0].documento);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'documento');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'documento');
    }
  };
  const loadComprobante = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientosUsuarios.length > 0) {
      if (requerimientosUsuarios[0].comprobanteTrabajo === '') {
        upload(file, 'comprobanteTrabajo');
      } else {
        let filename = getImageName(
          requerimientosUsuarios[0].comprobanteTrabajo
        );
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'comprobanteTrabajo');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'comprobanteTrabajo');
    }
  };
  const loadSelfiePrest = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientoPrestamo.length > 0) {
      let filename = getImageName(requerimientoPrestamo[0].selfiePrestamo);
      // Delete the file si existe
      const storageRef = app.storage().ref(`${filename}`);
      storageRef
        .delete()
        .then(() => {
          uploadPrestamo(file);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      uploadPrestamo(file);
    }
  };

  const upload = (file, property) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          if (requerimientosUsuarios.length > 0) {
            let data = {
              [property]: url,
              idRequerimiento: requerimientosUsuarios[0].id,
            };
            app
              .firestore()
              .collection('requerimiento')
              .doc(`${requerimientosUsuarios[0].id}`)
              .update(data)
              .then(() => {
                dispatch(getRequerimientosUsuarios(UserId));
                dispatch({ type: ENDLOADING });
              });
          } else {
            app
              .firestore()
              .collection('requerimiento')
              .add({
                comprobanteTrabajo:
                  property === 'comprobanteTrabajo' ? url : '',
                documento: property === 'documento' ? url : '',
                fechaValidacionComprobanteTrabajo: null,
                fechaValidacionDocumento: null,
                fechaValidacionSelfieCedula: null,
                //fechaValidacionSelfiePrestamo: null,
                idRequerimiento: '',
                idUsuario: app.auth().currentUser.uid,
                selfieCedula: property === 'selfieCedula' ? url : '',
                //selfiePrestamo: "",
                validadoComprobanteTrabajo: '',
                validadoDocumento: '',
                validadoGestorComprobanteTrabajo: null,
                validadoGestorDocumento: null,
                validadoGestorSelfieCedula: null,
                //validadoGestorSelfiePrestamo: null,
                validadoSelfieCedula: '',
                //validadoSelfiePrestamo: "",
              })
              .then(() => {
                dispatch(getRequerimientosUsuarios(UserId));
                dispatch({ type: ENDLOADING });
              });
          }
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const uploadPrestamo = (file) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      const textoEnviar = `
        <p>El usuario: ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}</p>
        <p>Email: ${prestamo.usuario.email}</p>

        <p>Ha subido el último requerimiento para la solicitud de su préstamo.</p> 
      `;
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          if (requerimientoPrestamo.length > 0) {
            let data = {
              selfiePrestamo: url,
              idRequerimiento: requerimientoPrestamo[0].id,
            };
            app
              .firestore()
              .collection('requerimientoPrestamo')
              .doc(`${requerimientoPrestamo[0].id}`)
              .update(data)
              .then(() => {
                dispatch(getRequerimientoPrestamo(prestamo.id));
                dispatch({ type: ENDLOADING });
                // enviarMesaje(textoEnviar, 'Usuario');
              });
          } else {
            app
              .firestore()
              .collection('requerimientoPrestamo')
              .add({
                idPrestamo: prestamo.id,
                idRequerimiento: '',
                idUsuario: prestamo.idUsuario,
                selfiePrestamo: url,
                validadoGestorSelfiePrestamo: null,
                validadoSelfiePrestamo: '',
              })
              .then(() => {
                dispatch(getRequerimientoPrestamo(prestamo.id));
                dispatch({ type: ENDLOADING });
                // enviarMesaje(textoEnviar, 'Usuario');
              });
          }
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  const getImageName = (url) => {
    let primera = url.split('/o/')[1];
    return primera.split('?')[0];
  };

  const next = () => {
    console.log('proximo');
    if (requerimientosUsuarios.length > 0) {
      if (
        requerimientosUsuarios[0].selfieCedula === '' ||
        requerimientosUsuarios[0].documento === ''
      ) {
        custom('Faltan Imagénes por Ingresar', 'error');
      } else {
        // buscar si existe chat antes de crearlo
        if (chat && chat.idPrestamo) {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamo.id}`)
            .update({
              estado: 'pendiente',
              fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
              fechaSolicitud: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              indexWizard: 4,
            });
          dispatch(createChat(prestamo.id));
          dispatch({ type: 'WIZARD', payload: 4 });
        } else {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamo.id}`)
            .update({
              estado: 'pendiente',
              fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
              fechaSolicitud: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            });
          dispatch(createChat(prestamo.id));
          dispatch({ type: 'WIZARD', payload: 4 });

          //ENVIAR CORREO A LOS ADMINISTRADORES //
          const textoCorreoEnviar = `
            <p>El usuario _ Email : ${prestamo.usuario.email}
            <b>Formalizó la solicitud de un préstamo</b>
            </p>
            <h4>Datos </h4>
            <p>Nombres :${
              prestamo.usuario.nombres + ' ' + prestamo.usuario.apellidos
            }</p>
            <p>País :${prestamo.usuario.pais}</p>
            <p>Correo :${prestamo.usuario.email}</p>
            <p>Monto :${prestamo.montoPrestado}$</p>
            <p>Monto a pagar :${prestamo.montoCompromisoPago}$</p>
            <p>Fecha pago :${prestamo.fechaCompromisoPago}</p>

            <h4>Laboral </h4>
            <p>Area de trabajo :${
              prestamo.usuario.areaTrabajo === ''
                ? 'Sin registro'
                : prestamo.usuario.areaTrabajo
            }</p>
            <p>Empresa :${
              prestamo.usuario.nombreEmpresa === ''
                ? 'Sin registro'
                : prestamo.usuario.nombreEmpresa
            }</p>
            <p>Actividad :${
              prestamo.usuario.descripcionActividad === ''
                ? 'Sin registro'
                : prestamo.usuario.descripcionActividad
            }</p>
            <p>Salario :${
              prestamo.usuario.ingresoMensualUSD === ''
                ? 'Sin registro'
                : prestamo.usuario.ingresoMensualUSD
            }</p>
            <p>Comprobate trabajo :${
              requerimientosUsuarios[0].comprobanteTrabajo === ''
                ? 'No enviado'
                : requerimientosUsuarios[0].comprobanteTrabajo
            }</p>
          `;
          const emailAdminis = {
            asunto: 'Préstamo Solicitado ',
            email: administrativos.map((admin) => admin.email),
            motivo: `<span>${textoCorreoEnviar}</span>`,
          };
          // enviarNotificacion(emailAdminis);
        }
      }
    } else {
      custom('Faltan datos por llenar', 'error');
    }
  };

  const back = (params) => {
    console.log('atrasssss');
  };

  return (
    <div className='grid grid-cols-12 gap-4 row-gap-5 mb-8'>
      <div className='intro-y col-span-12 sm:col-span-12'>
        <h2 className='font-medium text-gray-900 text-center text-lg'>
          Cargar imagenes y requerimientos del solicitante
        </h2>
      </div>
      <div className='intro-y col-span-12 sm:col-span-12'>
        <h5 className='flex text-base'>
          <DoneAllIcon color='primary' /> ._ Imagen selfie sosteniendo su cédula
          de identidad
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileSelfieRef}
            onChange={loadSelfie}
          />
          <a
            href='javascript:;'
            className='tooltip button inline-block bg-theme-5 text-blue-800 rounded-lg ml-3 -mt-1'
            title='Subir Selfie!'
            onClick={openExplorerSelfie}
          >
            <span className='flex items-center justify-center'>
              <i data-feather='folder-plus' className='w-5 h-5'></i>
            </span>
          </a>
        </h5>
        {requerimientosUsuarios.length > 0 && (
          <>
            <img
              alt=''
              className='border rounded'
              style={{ width: 250, height: 250 }}
              src={
                requerimientosUsuarios[0].selfieCedula !== ''
                  ? requerimientosUsuarios[0].selfieCedula
                  : Selfi
              }
            />
            <div className='flex box w-64 text-sm font-bold text-blue-800 mt-2'>
              <CheckCircleOutlineIcon fontSize='small' color='primary' />
              <p className='ml-1'> Status :</p>
              <label for='' className='font-medium text-gray-900 ml-2'>
                {requerimientosUsuarios[0].validadoSelfieCedula !== ''
                  ? requerimientosUsuarios[0].validadoSelfieCedula
                  : 'Sin evaluar'}
              </label>
            </div>
          </>
        )}
        {requerimientosUsuarios.length === 0 && (
          <img
            alt=''
            className='border rounded'
            style={{ width: 250, height: 250 }}
            src={Selfi}
          />
        )}
      </div>
      <div className='intro-y col-span-12 sm:col-span-12'>
        <h5 className='flex text-base'>
          <DoneAllIcon color='primary' /> ._ Imagen de cédula de identidad
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileDocumentRef}
            onChange={loadDocument}
          />
          <a
            href='javascript:;'
            className='tooltip button inline-block bg-theme-5 text-blue-800 rounded-lg ml-3 -mt-1'
            title='Subir cédula de identidad!'
            onClick={openExplorerDocumento}
          >
            <span className='flex items-center justify-center'>
              <i data-feather='folder-plus' className='w-5 h-5'></i>
            </span>
          </a>
        </h5>
        {requerimientosUsuarios.length > 0 && (
          <>
            <img
              alt=''
              className='border rounded'
              style={{ width: 250, height: 250 }}
              src={
                requerimientosUsuarios[0].documento !== ''
                  ? requerimientosUsuarios[0].documento
                  : Document
              }
            />
            <div className='flex box w-64 text-sm font-bold text-blue-800 mt-2'>
              <CheckCircleOutlineIcon fontSize='small' color='primary' />
              <p className='ml-1'> Status :</p>
              <label for='' className='font-medium text-gray-900 ml-2'>
                {requerimientosUsuarios[0].validadoDocumento !== ''
                  ? requerimientosUsuarios[0].validadoDocumento
                  : 'Sin evaluar'}
              </label>
            </div>
          </>
        )}
        {requerimientosUsuarios.length === 0 && (
          <img
            alt=''
            className='border rounded'
            style={{ width: 250, height: 250 }}
            src={Document}
          />
        )}
      </div>
      <div className='intro-y col-span-12 sm:col-span-12'>
        <h5 className='flex text-base'>
          <DoneAllIcon color='primary' /> ._ Comprobante de trabajo o foto de
          emprendimiento
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileComprobanteRef}
            onChange={loadComprobante}
          />
          <a
            href='javascript:;'
            className='tooltip button inline-block bg-theme-5 text-blue-800 rounded-lg ml-3 -mt-1'
            title='Subir comprobante de trabajo!'
            onClick={openExplorerComprobante}
          >
            <span className='flex items-center justify-center'>
              <i data-feather='folder-plus' className='w-5 h-5'></i>
            </span>
          </a>
        </h5>
        {requerimientosUsuarios.length > 0 && (
          <>
            <img
              alt=''
              className='border rounded'
              style={{ width: 250, height: 250 }}
              src={
                requerimientosUsuarios[0].comprobanteTrabajo !== ''
                  ? requerimientosUsuarios[0].comprobanteTrabajo
                  : Comprobante
              }
            />
            <div className='flex box w-64 text-sm font-bold text-blue-800 mt-2'>
              <CheckCircleOutlineIcon fontSize='small' color='primary' />
              <p className='ml-1'> Status :</p>
              <label for='' className='font-medium text-gray-900 ml-2'>
                {requerimientosUsuarios[0].validadoComprobanteTrabajo !== ''
                  ? requerimientosUsuarios[0].validadoComprobanteTrabajo
                  : 'Sin evaluar'}
              </label>
            </div>
          </>
        )}
        {requerimientosUsuarios.length === 0 && (
          <img
            alt=''
            className='border rounded'
            style={{ width: 250, height: 250 }}
            src={Comprobante}
          />
        )}
      </div>
      {!chat === false && (
        // <div className='intro-y px-4 sm:px-8 pt-6 border-t border-gray-200'>
        <div className='intro-y col-span-12 flex items-center justify-end sm:justify-end'>
          <button
            className='button w-24 justify-center block bg-gray-200 text-gray-600'
            onClick={back}
          >
            Atras
          </button>

          <button
            className='button w-24 justify-center block bg-theme-1 text-white ml-2'
            onClick={next}
          >
            Siguiente
          </button>
        </div>
        // {/* </div> */}
      )}
      {/* No ha subido la imagen*/}
      {requerimientoPrestamo.length === 0 && chat !== true && (
        <div className='intro-y col-span-12 sm:col-span-12 box bg-theme-5 ml-3'>
          <h2 className='font-medium text-gray-700 text-sm my-4 pl-4'>
            Para Cargar la imagen del préstamo el Gestor debe aprobar las
            imagenes de los requisitos... del "Préstamo por... ${' '}
            {prestamo.montoPrestado} dólares y monto a pagar ${' '}
            {prestamo.montoCompromisoPago} dólares en fecha{' '}
            {prestamo.fechaCompromisoPago}"
          </h2>
        </div>
      )}
      {prestamo.estado !== 'iniciado' && (
        <div className='intro-y col-span-12 sm:col-span-12'>
          <h5 className='flex text-base'>
            <DoneAllIcon color='primary' /> ._ Solicitud de Préstamo
            <input
              type='file'
              style={{ display: 'none' }}
              ref={fileSelfiePrestRef}
              onChange={loadSelfiePrest}
            />
            <a
              href='javascript:;'
              className='tooltip button inline-block bg-theme-5 text-blue-800 rounded-lg ml-5 -mt-1'
              title='Subir comprobante de trabajo!'
              // onClick={openExplorerSelfiePrest}
            >
              <span className='flex items-center justify-center'>
                <i data-feather='folder-plus' className='w-5 h-5'></i>
              </span>
            </a>
          </h5>
          {requerimientoPrestamo.length > 0 && (
            <>
              <img
                alt=''
                className='border rounded mt-1'
                style={{ width: 250, height: 250 }}
                src={
                  requerimientoPrestamo[0].selfiePrestamo !== ''
                    ? requerimientoPrestamo[0].selfiePrestamo
                    : Perfil
                }
              />
              <div className='flex box w-64 text-sm font-bold text-blue-800 mt-2'>
                <CheckCircleOutlineIcon fontSize='small' color='primary' />
                <p className='ml-1'> Status :</p>
                <label for='' className='font-medium text-gray-900 ml-2'>
                  {requerimientoPrestamo[0].validadoSelfiePrestamo === ''
                    ? 'Sin evaluar'
                    : requerimientoPrestamo[0].validadoSelfiePrestamo}
                </label>
              </div>
            </>
          )}
          {requerimientoPrestamo.length === 0 && (
            <img
              className='border rounded'
              alt=''
              style={{ width: 250, height: 250 }}
              src={Perfil}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CargaRequerimientos;
