import React, {useState} from "react";
import Banner from "./Banner";
import PasosProceso from "./PasosProceso";
import Testimonios from "./Testimonios";
import Footer from "./Footer";
import { BrowserRouter as Router } from "react-router-dom";

const Landing = () => {
  const [video, setVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openVideo = () => {
    setVideo(true);
    setShowModal(true);
  };

  const AbrirMenuResponsive = () => {
    const menu = document.querySelector("#menu");
    menu.classList.toggle("activar");
  };

  const close = () => {
    setShowModal(false);
  };
  return (
    <Router>
      <div className="page-index flex flex-col w-full" >
        <Banner />
        <PasosProceso />
        <Testimonios />
        <Footer />
      </div>
    </Router>
  );
};

export default Landing;
