import React from 'react';

const PasosProceso = () => {
  //========== USE-STATE ==========//
  const steps = [
    {
      img: '/user-edit.svg',
      title: 'Registro',
      description:
        'Regístrate rápidamente en nuestro sistema con tu correo electrónico.',
    },
    {
      img: '/clipboard-text.svg',
      title: 'Ingresa tus datos',
      description:
        'Ingresa cuidadosamente los datos personales que te solicitemos.',
    },
    {
      img: '/shield-tick.svg',
      title: 'Verificación',
      description:
        'Evaluaremos la información para aprobar o denegar tu préstamo.',
    },
    {
      img: '/dolar02.svg',
      title: 'Recepción',
      description:
        'Si cumples el perfil buscado recibe tu micropréstamo y págalo a tiempo.',
    },
  ];

  return (
    <>
      <div
        className="container h-64 mt-24 custom-pasos"
        style={{ height: '517px' }}
        id="proceso"
      >
        <div className="container-custom">
          <div className="texto flex-1">
            <div className="grid grid-cols-12 gap-4 row-gap-5">
              <div className="relative col-span-12 sm:col-span-12">
                <h2 className="text-center text-gray-650 font-montserrat font-bold tracking-widest2 text-lg">
                  CUATRO SENCILLOS PASOS
                </h2>
                <h1 className="flex justify-center text-gray-750 font-montserrat font-extrabold text-5xl tracking-tighter mb-24 mt-5">
                  Fácil, rápido y sencillo
                </h1>
                <img
                  className="mt-10 absolute right-0 top-0"
                  alt=""
                  src="/Group 4.svg"
                />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="grid grid-cols-12 gap-4 row-gap-5">
              <div className="col-span-12 sm:col-span-3">
                <div className="flex w-full">
                  <div className="flex mt-2">
                    <div className="text-center w-full mx-8 p-3">
                      <img
                        className="m-auto"
                        src={steps[0].img}
                        alt="logo"
                      />
                      <h2 className="text-xl text-gray-750 font-montserrat font-semibold mt-5">
                        {steps[0].title}
                      </h2>
                      <p className="text-base text-gray-650 font-montserrat mt-5">
                        {steps[0].description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <div className="relative flex w-full">
                  <div className="flex mt-2">
                    <div className="text-center w-full mx-8 p-3 z-10 bg-white rounded-card">
                      <img
                        className="m-auto"
                        src={steps[1].img}
                        alt="logo"
                      />
                      <h2 className="text-xl text-gray-750 font-montserrat font-semibold mt-5">
                        {steps[1].title}
                      </h2>
                      <p className="text-base text-gray-650 font-montserrat mt-5">
                        {steps[1].description}
                      </p>
                    </div>
                    <img
                      className="absolute bottom-0 -mb-8"
                      alt="rectaguloVerde"
                      src="/Rectangle 157.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <div className="flex w-full">
                  <div className="flex mt-2">
                    <div className="text-center w-full mx-8 p-3">
                      <img
                        className="m-auto"
                        src={steps[2].img}
                        alt="logo"
                      />
                      <h2 className="text-xl text-gray-750 font-montserrat font-semibold mt-5">
                        {steps[2].title}
                      </h2>
                      <p className="text-base text-gray-650 font-montserrat mt-5">
                        {steps[2].description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <div className="flex w-full">
                  <div className="flex mt-2">
                    <div className="text-center w-full mx-8 p-3">
                      <img
                        className="m-auto"
                        src={steps[3].img}
                        alt="logo"
                      />
                      <h2 className="text-xl text-gray-750 font-montserrat font-semibold mt-5">
                        {steps[3].title}
                      </h2>
                      <p className="text-base text-gray-650 font-montserrat mt-5">
                        {steps[3].description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasosProceso;
