import React, { useEffect } from "react";
import $ from "jquery";
import feather from "feather-icons";
import Modal from "../../Modal";
import { Save } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getListGestores } from "../../../redux/actions/adminAction";
import { Pie } from "react-chartjs-2";

export default function Dashboard() {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const ListGestores = useSelector((state) => state.admin.listGestores);

  //========== USE-EFFECT ==========//
  useEffect(() => {
    (function ($) {
      "use strict";
      feather.replace({ "stroke-width": 1.5 });
    })($);

    dispatch(getListGestores());
  }, []);

  //========== GRAFICO ==========//
  const buildData = () => {
    let arrayLabels = [];
    let arrayValues = [];

    ListGestores.forEach((element) => {
      arrayLabels.push(element.nombres + " " + element.apellidos);
      arrayValues.push(element.prestamos);
    });

    return {
      labels: arrayLabels,
      datasets: [
        {
          label: "# of Gestores",
          data: arrayValues,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const close = () => {
    setShowModal(false);
  };
  const cancel = () => {
    setShowModal(false);
  };
  const save = () => {
    alert("click boton save");
  };

  return (
    <>
      {/* <div className="text-center mt-5">
        <a
          href="javascript:;"
          className="button inline-block bg-theme-1 text-white"
          onClick={() => setShowModal(true)}
        >
          Show Modal
        </a>
      </div> */}
      {showModal && (
        <Modal title="Ejemplo Modal" close={close} save={save} cancel={cancel}>
          <div className="col-span-12 sm:col-span-6">
            <label>From</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="example@gmail.com"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>To</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="example@gmail.com"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Subject</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="Important Meeting"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Has the Words</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="Job, Work, Documentation"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Doesn't Have</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="Job, Work, Documentation"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Size</label>
            <select className="input w-full border mt-2 flex-1">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div>
        </Modal>
      )}

      <div className="intro-y flex items-center justify-between h-10">
        <h2 className="text-lg font-medium ml-8 mt-2">
          Préstamos por Gestores
        </h2>
        <h2 className="text-lg font-medium text-theme-10 truncate mr-5">
          {/* {theViewReport} */}
        </h2>
      </div>
      <hr />

      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">
          <div className="col-span-6 lg:col-span-6 mt-8 box p-5 flex">
            <Pie data={buildData()} />
          </div>
        </div>
      </div>
    </>
  );
}
