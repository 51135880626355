import React from "react";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const BreadCrumb = () => {
  const viewSelected = useSelector((state) => state.menu.view);

  return (
    <div className="-intro-x breadcrumb mr-auto hidden sm:flex">
      <span className="">Aplicación</span>
      <ChevronRightIcon />
      <span className="breadcrumb--active">{viewSelected}</span>
    </div>
  );
};

export default BreadCrumb;
