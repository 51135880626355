import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';

import * as Icon from 'react-feather';
import Slider from 'react-slick';
import moment from 'moment';
import { app } from '../../../config/firebaseConfig';
import { axiosInstance } from '../../../config/api';
import Modal from '../../Modal';
import Chat from '../../chat/Chat';
import { LOADING, ENDLOADING } from '../../../redux/types';
import { confirm, success, custom } from '../../../helpers/alertas';

import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';
//========== Import Acordion MaterialUI ==========//
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import ChatIcon from '@material-ui/icons/Chat';

import { MODALABONO, GETABONOS } from '../../../redux/types';
import {
  modalAgregarPago,
  saveAgregarPagos,
  getPagosRealizados,
  getAbonosRealizados,
  updateAbonoPrestamo,
  updatePagoCompletado,
  getPrestamo,
  sendMsg,
  getChat,
} from '../../../redux/actions/superAdminActions';
import { addLog } from '../../../redux/actions/sesionAction';
import {
  updateDatosPrestamoUser,
  misAbonos,
} from '../../../redux/actions/userAction';
import { aprobarPrestamo } from '../../../redux/actions/gestorActions';

const hoy = moment(new Date()).format('DD/MM/YYYY');

const Detalle = () => {
  let { prestamoid } = useParams();
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const prestamo = useSelector((state) => state.superadmin.prestamo);
  const pagosRealizados = useSelector(
    (state) => state.superadmin.pagosRealizados
  );
  const abonosRealizados = useSelector(
    (state) => state.superadmin.abonosRealizados
  );
  const modalPagos = useSelector((state) => state.superadmin.modalPagos);
  const chat = useSelector((state) => state.gestor.chat);
  const misabonos = useSelector((state) => state.user.misabonos);
  const modalAbono = useSelector((state) => state.user.modalAbono);
  const indexWizard = useSelector((state) => state.user.indexWizard);

  //========== USE-STATES ==========//
  const [pagos, setPagos] = useState({
    banco: '',
    cedula: '',
    nombreBanco: '',
    numeroCuenta: '',
    titular: '',
    tipoPagoVenezuela: '',
    monto: '',
    montoBs: '',
    concepto: '',
    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    tipo: 'egreso',
    categoria: 'Prestamo',
    idPrestamo: '',
    idTransaccion: '',
    recibo: '',
  });
  const [verDetallePagos, setVerDetallePagos] = useState(false);
  const [detallePago, setDetallePago] = useState({
    banco: '',
    cedula: '',
    nombreBanco: '',
    numeroCuenta: '',
    titular: '',
    tipoPagoVenezuela: '',
    monto: '',
    montoBs: '',
    concepto: '',
    fecha: '',
    categoria: '',
    tipo: '',
    idPrestamo: '',
    idTransaccion: '',
    recibo: '',
  });
  const [tasaDolarHoy, setTasaDolarHoy] = useState(1);
  const [abono, setAbono] = useState(null);
  const [showDealle, setShowDetalle] = useState(false);
  const [fileName, setFilename] = useState('');
  const [refFile, setRefFile] = useState(null);
  const [days, setDays] = useState([]);
  const [cantidad, setCantidad] = useState(0);
  const [pago, setPago] = useState({
    idTransaccion: '',
    nombres: '',
    apellidos: '',
    via: 'transferencias',
    monto: '',
    concepto: '',
    fecha: moment(new Date()).format('YYYY-MM-DD'),
    voucher: '',
    confirmado: false,
  });
  const [montoPagar, setMontoPagar] = useState('todo');
  const [disabled, setDisabled] = useState(true);
  const [quedoDebiendo, setQuedoDebiendo] = useState(false);
  const [administrativos, setAdministrativos] = useState([]);
  const [usuarioPrestamo, setUsuarioPrestamo] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  let confir = [];
  confir.push(misabonos.map((abono) => abono.confirmado)[0]);
  let abonoConfirmado = confir[0];

  useEffect(() => {
    (function ($) {
      //cambiar de tabs
      $('body').on('click', 'a[data-toggle="tab"]', function (key, el) {
        $(this)
          .closest('.nav-tabs')
          .find('a[data-toggle="tab"]')
          .removeClass('active');
        $(this).addClass('active');
        let elementId = $(this).attr('data-target');
        $(elementId)
          .closest('.tab-content')
          .find('.tab-content__pane')
          .removeClass('active');
        $(elementId).addClass('active');
      });
      // Daterangepicker
      $('.datepicker').each(function () {
        let options = {
          singleDatePicker: true,
          showDropdowns: true,
          minYear: 1901,
          maxYear: parseInt(moment().format('YYYY'), 10),
        };
        if ($(this).data('daterange')) {
          options.singleDatePicker = false;
        }
        if ($(this).data('timepicker')) {
          options.timePicker = true;
          options.locale = {
            format: 'M/DD hh:mm A',
          };
        }
        $(this).daterangepicker(options);
      });
    })($);
  }, []);

  useEffect(() => {
    dispatch(getChat(prestamoid));
    dispatch(getPrestamo(prestamoid));
    dispatch(getPagosRealizados(prestamoid));
    dispatch(getAbonosRealizados(prestamoid));
    dispatch(misAbonos(prestamoid));
    getDolarValue();
    userAdministrativos();
    userPrestamo(prestamo);
  }, []);

  const userPrestamo = (prestamo) => {
    app
      .firestore()
      .collection('usuarios')
      .where('idUsuario', '==', `${prestamo.idUsuario}`)
      .get()
      .then((data) => {
        let objUser = {};
        data.forEach((doc) => (objUser = doc.data()));
        setUsuarioPrestamo(objUser);
        setTimeout(() => {
          // console.log(prestamoActual)
        }, 1000);
      });
  };
  const getDolarValue = async () => {
    try {
      let response = await axiosInstance.get(`/monitor`);
      const { data } = response;
      let dataBien = data.resp.replaceAll('.', '');
      dataBien = dataBien.replace(',', '.');
      setTasaDolarHoy(parseFloat(dataBien));
    } catch (err) {
      console.log(err, 'error');
    }
  };
  const diff = () => {
    const diferencia = restaFechas(
      prestamo.fechaInicio,
      prestamo.fechaCompromisoPago
    );
    armarDays(diferencia);
    setCantidad(prestamo.montoPrestado);
  };

  //========== Funciones Modal Agregar Pagos ==========//
  const agregarPagos = () => {
    setPagos({
      banco: prestamo.datosEnvio.banco,
      cedula: prestamo.datosEnvio.cedula,
      nombreBanco: prestamo.datosEnvio.nombreBanco,
      numeroCuenta: prestamo.datosEnvio.numeroCuenta,
      titular: prestamo.datosEnvio.titular,
      tipoPagoVenezuela: prestamo.tipoPagoVenezuela,
      monto: '',
      montoBs: '',
      concepto: '',
      fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      tipo: 'egreso',
      categoria: 'Prestamo',
      idPrestamo: '',
      idTransaccion: '',
      recibo: '',
    });
    dispatch(modalAgregarPago(true));
    dispatch(
      addLog({
        accion: `${'ha llenado los datos de pago '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const changeAgregarPagos = (e) => {
    const { name, value } = e.target;
    setPagos({ ...pagos, [name]: value });
  };
  const cancelAgregarPagos = () => {
    if (pagos.recibo) {
      refFile
        .delete()
        .then(() => {
          setRefFile(null);
          setFilename('');
        })
        .catch((err) => console.log(err));
    }
    dispatch(modalAgregarPago(false));
  };
  const guardarAgregarPagos = async () => {
    if (
      pagos.banco === '' ||
      pagos.cedula === '' ||
      pagos.nombreBanco === '' ||
      // pagos.numeroCuenta === "" ||
      pagos.titular === '' ||
      pagos.tipoPagoVenezuela === '' ||
      pagos.monto === '' ||
      pagos.montoBs === '' ||
      pagos.concepto === '' ||
      pagos.fecha === '' ||
      pagos.tipo === '' ||
      pagos.idTransaccion === '' ||
      pagos.categoria === '' ||
      pagos.recibo === ''
    ) {
      custom('Debe llenar todos los dato', 'error');
    } else {
      let egresoPrestamo = {
        nombres: prestamo.usuario.nombres,
        apellidos: prestamo.usuario.apellidos,
        monto: parseFloat(pagos.monto),
        tipo: 'egreso',
        categoria: 'Prestamo',
        concepto: pagos.concepto,
        idPrestamo: prestamoid,
      };
      let data = {
        banco: pagos.banco,
        cedula: pagos.cedula,
        nombreBanco: pagos.nombreBanco,
        numeroCuenta: pagos.numeroCuenta,
        titular: pagos.titular,
        tipoPagoVenezuela: pagos.tipoPagoVenezuela,
        concepto: pagos.concepto,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        monto: parseFloat(pagos.monto),
        montoBs: parseFloat(pagos.montoBs),
        categoria: pagos.categoria,
        tipo: pagos.tipo,
        idPrestamo: prestamoid,
        idTransaccion: pagos.idTransaccion,
        recibo: pagos.recibo,
      };
      dispatch(saveAgregarPagos(data, egresoPrestamo));
      let mensajesPorEnviar = [];
      let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
      let para = `${prestamo.usuario.email}`;
      const datosCuentaPago = () => {
        if (prestamo.tipoPagoVenezuela === 'Cuenta Bancaria') {
          return ` ${prestamo.datosEnvio.numeroCuenta}`;
        } else {
          return ` - Telefono Pago : ${prestamo.datosEnvio.telefono}`;
        }
      };
      const dataEnviar = {
        asunto: 'Transferencia de Fondos',
        email: para,
        motivo: `<p>Estimado/a, <b>${usuario}</b>...
          <b>Se le informa que el monto ${prestamo.montoPrestado} $,
          solicitado en su préstamo ha sido transferido a su cuenta con éxito.
          Los datos de su tranferencia para su verificación son:</b></p>

          <p>- Titular : ${prestamo.datosEnvio.titular}</p>
          <p>- Banco : ${prestamo.datosEnvio.nombreBanco}</p>
          <p>- Cuenta: ${datosCuentaPago()}</p>
          <p>- Cédula Tilular : ${prestamo.datosEnvio.cedula}</p>
          <p>- Monto : ${prestamo.montoPrestado} $ </p>
          <p>- Monto en Bs...: ${pagos.montoBs} Bs </p>
          <p>- Número Comprobante : ${pagos.idTransaccion}</p>
          `,
      };
      enviarNotificacion(dataEnviar);
      mensajesPorEnviar.push({
        texto: `<p>Se transfirió a su cuenta el monto del préstamo solicitado 
        ${
          prestamo.montoPrestado
        } $, con éxito... Los datos de su transferencia para su verificación son :</p></br>
        <p>- Titular : ${prestamo.datosEnvio.titular}</p>
        <p>- Banco : ${prestamo.datosEnvio.nombreBanco}</p> 
        <p>- Cuenta:  ${datosCuentaPago()}</p>
        <p>- Cédula: ${prestamo.datosEnvio.cedula}</p>
        <p>- Monto : ${prestamo.montoPrestado} $ </p>
        <p>- Monto en Bs: ${pagos.montoBs} Bs</p>
        <p>- Número comprobante : ${pagos.idTransaccion}</p>`,
        rol: 'Sistema',
      });
      mensajesPorEnviar.push({ texto: pagos.recibo, rol: 'Sistema' });
      enviarVariosMensajes(mensajesPorEnviar.reverse());

      await app
        .firestore()
        .collection('prestamos')
        .doc(`${prestamoid}`)
        .update({ indexWizard: 5 });
      dispatch({ type: 'WIZARD', payload: 5 });

      dispatch(
        addLog({
          accion: `${'Se ha Generado un Pago: De Tipo '} ${
            pagos.tipo
          } ${'Al Titular '} ${pagos.titular}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
    }
  };

  //========== Funciones Modal Ver Detalles de Pagos ==========//
  const VerDetallePago = (detallePago) => {
    setDetallePago({
      banco: detallePago.banco,
      cedula: detallePago.cedula,
      nombreBanco: detallePago.nombreBanco,
      numeroCuenta: detallePago.numeroCuenta,
      titular: detallePago.titular,
      tipoPagoVenezuela: detallePago.tipoPagoVenezuela,
      monto: detallePago.monto,
      montoBs: detallePago.montoBs,
      concepto: detallePago.concepto,
      fecha: detallePago.fecha,
      categoria: detallePago.categoria,
      tipo: detallePago.tipo,
      idPrestamo: detallePago.idPrestamo,
      idTransaccion: detallePago.idTransaccion,
      recibo: detallePago.recibo,
    });

    setVerDetallePagos(true);
  };
  const closeVerDetallePagos = () => {
    setVerDetallePagos(false);
  };
  const cancelVerDetallePagos = () => {
    setVerDetallePagos(false);
  };
  const calcularInteresesPorAbonos = () => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    abonosRealizados.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamo.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamo.montoPrestado,
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  //========== Confirmar Abonos ==========//
  const confirmarAbonos = async (prestamoid, abono, idAbono) => {
    const resp = await confirm('¿Seguro Desea Confirmar el Abono?', 'question');
    const abonos = () => {
      let totalAbono = 0;
      if (abonosRealizados.idAbono === idAbono) {
        totalAbono = prestamo.montoRecibido + abono.monto;
      }
      return totalAbono;
    };
    if (resp) {
      let ingresoAbono = {
        nombres: abono.nombres,
        apellidos: abono.apellidos,
        monto: abono.monto,
        tipo: 'ingreso',
        categoria: 'Pago-Abono Recibido',
        concepto: abono.concepto,
        idTransaccion: abono.idTransaccion,
        idPrestamo: prestamoid,
        fecha: abono.fecha,
      };
      dispatch(
        updateAbonoPrestamo(prestamoid, abono.id, ingresoAbono, abonos())
      );
      success('Operación realizada exitosamente.', 'success');
    }
    const actualizarXcobrar = () => {
      let montoXcobrar = calcularInteresesPorAbonos()['interesHoy'];
      let pagado = null;
      if (montoXcobrar === 0) {
        return (pagado = true);
      } else {
        return (pagado = false);
      }
    };
    dispatch(updatePagoCompletado(prestamoid, { pagado: actualizarXcobrar() }));
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    const data = {
      asunto: 'Confirmación de Pago',
      email: para,
      motivo: `<p>Estimado/a, <b>${usuario}</b>...</p>
        <p><b>Se le informa que el abono realizado a su préstamo ha sido confirmado con Éxito.</b></p>
        <p><b>Los datos de su Abono son:
        Nombre : ${abono.nombres}
        Monto : ${abono.monto}
        Referencia : ${abono.idTransaccion}
        Fecha : ${abono.fecha}...
        </b></p>`,
    };
    enviarNotificacion(data);
    enviarMesaje(
      `<span><p>Se le informa que el abono realizado a su préstamo ha sido confirmado con Exito. Los datos son :</p><br/>
        <p>- Nombre : ${abono.nombres},</p> 
        <p>- Monto : ${abono.monto}, </p>
        <p>- Referencia : ${abono.idTransaccion},</p>
        <p>- Fecha : ${abono.fecha}...</p></span>`,
      'Sistema'
    );
    dispatch(
      addLog({
        accion: `${'Se Ha Confirmado un Abono: Por $ '} ${
          abono.monto
        } ${'Por el Titular '} ${abono.nombres}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };

  //========== Funciones Estado de Cuenta Prestamo ==========//
  const totalAbonos = () => {
    let totalAbono = 0;
    abonosRealizados.forEach((element) => {
      if (element.confirmado === true) {
        totalAbono = totalAbono + element.monto;
      }
    });
    return totalAbono;
  };
  const finalizarProceso = async () => {
    const resp = await confirm(
      '¿Seguro Desea Finalizar El Proceso del Prestamo?',
      'question'
    );
    const totalRecibido = parseFloat(totalAbonos().toFixed(2));
    if (resp) {
      // let diasTranscurridos = diferenciaFechas(prestamo.fechaCompromisoPago);
      let pagoCompleto = {
        idGestor: myUser.idUsuario,
        pagado: true,
        estado: 'finalizado',
        fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
        fechaPago: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        montoRealRecibido: totalRecibido,
        ganancia: parseFloat(
          (prestamo.montoCompromisoPago - prestamo.montoPrestado).toFixed(2)
        ),
        gananciaTeorica: parseFloat(
          (prestamo.montoCompromisoPago - prestamo.montoPrestado).toFixed(2)
        ),
        gananciaReal: parseFloat(
          (totalRecibido - prestamo.montoRealPrestado).toFixed(2)
        ),
      };
      dispatch(updatePagoCompletado(prestamoid, pagoCompleto));
      enviarMesaje(
        `<span>Su préstamo fue cerrado exitosamente, puede solicitar nuevos préstamos.</span>`,
        'Sistema'
      );
      dispatch(
        addLog({
          accion: `${'Se ha Completado el Pago'} ${'Del Préstamo de: '} ${
            prestamo.usuario.nombres
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
    }
  };
  // const finalizarProceso = async () => {
  //   const resp = await confirm(
  //     '¿Seguro Desea Finalizar El Proceso del Prestamo?',
  //     'question'
  //   );
  //   const totalRecibido = parseFloat(totalAbonos().toFixed(2));
  //   if (resp) {
  //     let pagoFinalizado = {
  //       idGestor: myUser.idUsuario,
  //       pagado: true,
  //       estado: 'finalizado',
  //       fechaPago: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  //       montoRealRecibido: totalRecibido,
  //       ganancia: parseFloat(
  //         (prestamo.montoCompromisoPago - prestamo.montoPrestado).toFixed(2)
  //       ),
  //       gananciaTeorica: parseFloat(
  //         (prestamo.montoCompromisoPago - prestamo.montoPrestado).toFixed(2)
  //       ),
  //       gananciaReal: parseFloat(
  //         (totalRecibido - prestamo.montoRealPrestado).toFixed(2)
  //       ),
  //       habilitadoNuevoPrestamo: false,
  //     };
  //     dispatch(updatePagoCompletado(prestamoid, pagoFinalizado));
  //     enviarMesaje(`<span>Su préstamo fue cerrado.</span>`, 'Sistema');
  //     dispatch(
  //       addLog({
  //         accion: `${'Se Ha Finalizado el Proceso del Préstamo'} ${'Del Préstamo de: '} ${
  //           prestamo.usuario.nombres
  //         }`,
  //         fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  //         usuario: myUser.email,
  //       })
  //     );
  //   }
  // };

  //========== Funciones de Envio Correo y Mensaje ==========//
  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };
  const enviarMesaje = (mensaje, rol) => {
    let mensajesArray = [];
    let newChat = {
      ...chat,
      ['fechaActualizacion']: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    };
    mensajesArray = [...chat.mensajes];
    mensajesArray.push({
      fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      rol,
      texto: mensaje,
    });
    dispatch(sendMsg(newChat, mensajesArray));
  };
  const enviarVariosMensajes = (mensajes, rol) => {
    let mensajesViejos = [];
    let mensajesNuevos = [];
    let newChat = {
      ...chat,
      ['fechaActualizacion']: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    };
    mensajesViejos = [...chat.mensajes];
    mensajes.forEach((items) => {
      mensajesNuevos.push({
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        rol: items.rol,
        texto: items.texto,
      });
    });
    let mensajesTodos = mensajesViejos.concat(mensajesNuevos);
    dispatch(sendMsg(newChat, mensajesTodos));
  };

  //========== Contenido Acordion ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      fontSize: theme.typography.pxToRem(14),
      width: '100%',
    },
  }));
  const classes = useStyles();
  const montoHoy = () => {
    if (prestamo && prestamo.usuario) {
      let dias = calCulardiasTranscurridos(prestamo.fechaInicio);
      let monto = calculateInteres(
        prestamo.montoPrestado,
        dias,
        parseFloat(
          prestamo.usuario.habilitadoNuevoPrestamo === 'No'
            ? prestamo.tasaInteresCompuesto
            : prestamo.usuario.nuevaTasaInteres
        )
      );
      return parseFloat(monto);
    } else {
      return 0;
    }
  };
  const verDetalle = (item) => {
    setAbono(item);
    setShowDetalle(true);
  };
  const loadFile = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    upload(file);
  };
  const loadFileAbono = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    uploadAbono(file);
  };
  const openExplorer = (e) => {
    fileRef.current.click();
    enviarMesaje(
      `<span><p>El préstamo fue concretado, desde el día de hoy aguardamos por la devolución del mismo con sus correspondientes intereses para permitirle acceder a nuevos préstamos con límites superiores.</p><br/>

      <p>Recordar que los intereses seguirán acumulándose diariamente en caso de algún retraso y si se produce un incumplimiento le comunicaremos las medidas por correo electrónico.</p><br/>

      <p>Chamopresta.com, se reserva el derecho a tomar cualquier medida para la recuperación de sus fondos, deseamos que no sea su caso, para que tenga una experiencia totalmente positiva.</p>
      </span>`,
      'Sistema'
    );
  };

  const upload = (file) => {
    setFilename(file.name);
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(`pagos/${prestamoid}`);
      setRefFile(storageRef);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          // let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          app
            .storage()
            .ref(`pagos/${prestamoid}`)
            .getDownloadURL()
            .then(async (url) => {
              setPagos({ ...pagos, ['recibo']: url });
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const uploadAbono = (file) => {
    setFilename(file.name);
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app
        .storage()
        .ref(`abonos/${prestamo.id}-${misabonos.length + 1}`);
      setRefFile(storageRef);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          // let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          app
            .storage()
            .ref(`abonos/${prestamo.id}-${misabonos.length + 1}`)
            .getDownloadURL()
            .then(async (url) => {
              setPago({ ...pago, ['voucher']: url });
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const bgMontoPendiente = (value) => {
    if (calcularInteresesPorAbonos()['interesHoy'] === 0) {
      return 'font-medium bg-theme-18 box p-1 mr-2';
    } else {
      return 'font-medium bg-theme-34 box p-1 mr-2';
    }
  };
  const removeRecibo = () => {
    setFilename('');
    setPagos({ ...pagos, ['recibo']: '' });
    refFile.delete().then(() => {
      setRefFile(null);
    });
  };

  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const calculateInteresByDia = (dia) => {
    let totalApagar = (
      cantidad *
      Math.pow(
        1 +
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          ) /
            100,
        dia
      )
    ).toFixed(2);
    return totalApagar;
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    setDays(array.reverse());
  };

  //========== Funciones Reportar Pago ==========//
  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Bloqueado'])
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
        setTimeout(() => {
          // console.log(prestamoActual)
        }, 1000);
      });
  };
  const interesesReportePago = (abonosRecibido) => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    let arrayRecorrer = [];
    if (abonosRecibido) {
      arrayRecorrer = abonosRecibido;
    } else {
      arrayRecorrer = misabonos;
    }
    arrayRecorrer.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamo.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamo.montoPrestado,
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };
  const totalRestante = () => {
    let total = 0;
    misabonos.forEach((fila) => {
      total += fila.monto;
    });
    if (misabonos.length > 0) {
      return (montoHoy() - total).toFixed(2);
    }
    return montoHoy().toFixed(2);
  };
  const totalRestanteAbonos = () => {
    let total = 0;
    //total = (calcularInteresesPorAbonos()['interesHoy'] - totalAbonadoAbonos());
    total = interesesReportePago()['interesHoy'];
    return total;
  };
  const HandleChange = (e) => {
    const { name, value } = e.target;
    setPago({ ...pago, [name]: value });
  };
  const abonar = () => {
    setPago({
      ...pago,
      ['nombres']: prestamo.usuario.nombres,
      ['apellidos']: prestamo.usuario.apellidos,
      ['monto']:
        misabonos.length === 0 ? totalRestante() : totalRestanteAbonos(),
    });
    dispatch({ type: MODALABONO, payload: true });
    dispatch(
      addLog({
        accion: `${'El Usuario: '} ${
          prestamo.usuario.email
        } ${','} ${'ha Reportado un Abono de Su Prestamo'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const cancelAbono = () => {
    if (refFile) {
      refFile
        .delete()
        .then(() => {
          setRefFile(null);
          setFilename('');
        })
        .catch((err) => console.log(err));
    }
    dispatch({ type: MODALABONO, payload: false });
  };
  const guardarAbono = () => {
    if (
      pago.nombres === '' ||
      pago.apellidos === '' ||
      pago.via === '' ||
      pago.monto === '' ||
      pago.concepto === '' ||
      pago.fecha === '' ||
      pago.idTransaccion === '' ||
      pago.voucher === ''
    ) {
      custom('Debe llenar todos los datos', 'error');
    } else {
      if (misabonos.length === 0) {
        //si no tiene abonos
        if (parseFloat(pago.monto) > parseFloat(totalRestante())) {
          //verifica si se ingresa un monto mayor a la deuda sin abonos
          custom('El monto ingresado es mayor a la deuda total.', 'warning');
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        } else {
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        }
      } else {
        // si tiene abonos
        if (parseFloat(pago.monto) > parseFloat(totalRestanteAbonos())) {
          //verifica si se ingresa un monto mayor a la deuda con abonos
          custom('El monto ingresado es mayor a la deuda total.', 'warning');
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        } else {
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        }
      }
      dispatch(
        aprobarPrestamo(prestamo.id, {
          estado: 'Abonado',
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
        })
      );
    }
  };
  const agregarAbono = async (pagoAbono, prestamo) => {
    let mensajesPorEnviar = [];
    try {
      await app.firestore().collection('abonos').add(pagoAbono);
      const abonos = await app
        .firestore()
        .collection('abonos')
        .where('idPrestamo', '==', `${pagoAbono.idPrestamo}`)
        .orderBy('fecha', 'asc')
        .get();
      let array = [];
      let objeto = {};
      abonos.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETABONOS, payload: array });
      dispatch(getAbonosRealizados(prestamoid));
      const debe = interesesReportePago(array)['interesHoy'];
      let fechaDebePagar = moment(prestamo.fechaCompromisoPago).format(
        'DD/MM/YYYY'
      );
      if (hoy >= fechaDebePagar) {
        if (debe > 0) {
          setQuedoDebiendo(true);
        }
      } else {
        console.log('todo bien');
      }
      let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
      const data = {
        asunto: 'Notificacion Reporte de Pago ó Abono',
        email: administrativos.map((admin) => admin.email),
        motivo: `
        <p><b>Se Ha reportado un Pago/Abono.</b></p>
        <p>Usuario: <b>${usuario}</b></p>
        <p>Correo: <b>${prestamo.usuario.email}</b></p>
        <p>Pais: <b>${prestamo.usuario.pais}</b></p>
        <p>Prestamo: <b>${prestamo.id}</b></p>
        <p>Monto Solicitado: <b>${prestamo.montoPrestado}</b></p>
        <p>Fecha de Pago: <b>${pagoAbono.fecha}</b></p>
        <p>Monto Pagado: <b>${pagoAbono.monto}</b></p>
        <p>Número Comprobante: <b>${pagoAbono.idTransaccion}</b></p>
        <p>Voucher Comprobante: <b>${pagoAbono.voucher}</b></p>
        <p>Deuda restante: <b>${debe}</b></p>
      `,
      };
      enviarNotificacion(data);
      mensajesPorEnviar.push({
        texto: `El usuario, ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}, reporta un abono de ${pagoAbono.monto} $ con el número de comprobante ${pagoAbono.idTransaccion}.
      Por favor aguarde la confirmación de la recepción del pago para la actualización del estado de su deuda. Manténgase alerta en su correo electrónico o whatsapp.`,
        rol: 'usuario',
      });
      mensajesPorEnviar.push({ texto: pagoAbono.voucher, rol: 'Usuario' });
      if (debe === 0) {
        mensajesPorEnviar.push({
          texto: `GRACIAS por pagar la totalidad de tu deuda, desde Chamopresta.com estamos muy contentos por haber logrado cerrar este préstamo con éxito, gracias ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}.`,
          rol: 'Sistema',
        });
      }
      enviarVariosMensajes(mensajesPorEnviar.reverse());
      dispatch({ type: GETABONOS, payload: array });
      dispatch({ type: MODALABONO, payload: false });
    } catch (err) {
      custom('Error al intentar agregar el Abono', 'error');
    }
  };
  const changeMontoType = (e) => {
    let value = e.target.value;
    setMontoPagar(value);
    if (value === 'todo') {
      setDisabled(true);
      setPago({
        ...pago,
        ['monto']:
          misabonos.length === 0 ? totalRestante() : totalRestanteAbonos(),
      });
    } else {
      setPago({
        ...pago,
        ['monto']: '',
      });
      setDisabled(false);
    }
  };
  const openExplorerPago = (e, item) => {
    setAbono(item);
    fileRef.current.click();
  };
  const HandleChangeEvalu = (e) => {
    const { name, value } = e.target;
    setUsuarioPrestamo({ ...usuarioPrestamo, [name]: value });
  };
  const viewAlert = (show) => {
    setShowAlert(show);
  };

  const guardarEvaluacion = (e) => {
    e.preventDefault();
    let data = { ...usuarioPrestamo };
    let dataPrestamo = { ...prestamo, id: prestamoid, indexWizard: 1 };
    dispatch(updateDatosPrestamoUser(data, dataPrestamo, false));
    viewAlert(true);
    setTimeout(() => {
      viewAlert(false);
    }, 2500);
  };

  return (
    <>
      <h1 className='font-medium text-center text-base mt-4 bg-theme-26 box p-2'>
        Detalle de Préstamo
      </h1>
      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-5'>
        <div className='col-span-12'>
          <div className='intro-y box lg:mt-2'>
            <div className={classes.root}>
              {/* //===== Datos del usuario =====// */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon color='primary' />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography className={classes.heading}>
                    <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                      <DoneAllIcon color='primary' /> ._ Datos del Usuarios
                    </label>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {prestamo.usuario && (
                    <div className='grid grid-cols-12 gap-4 row-gap-5 w-full mb-3 mt-2 p-2 bg-theme-2 box overflow-auto lg:overflow-visible'>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Nombres:
                        </label>
                        <span>
                          {prestamo.usuario.nombres} {''}
                          {prestamo.usuario.apellidos}
                        </span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Cédula:
                        </label>
                        <span>{prestamo.usuario.numeroCedula}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Email:
                        </label>
                        <span>{prestamo.usuario.email}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Pasaporte:
                        </label>
                        <span>{prestamo.usuario.numeroPasaporte}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Sexo:
                        </label>
                        <span>{prestamo.usuario.sexo}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Fecha de nacimiento:
                        </label>
                        <span>{prestamo.usuario.fechaNacimiento}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          País:
                        </label>
                        <span>{prestamo.usuario.pais}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Localidad:
                        </label>
                        <span>{prestamo.usuario.localidad}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Whatsapp:
                        </label>
                        <span>{prestamo.usuario.whatsapp}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Otro número de contacto:
                        </label>
                        <span>{prestamo.usuario.telefonoOpcional}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Usuario telegram:
                        </label>
                        <span>{prestamo.usuario.telegram}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Trabaja:
                        </label>
                        <span>{prestamo.usuario.trabaja}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Área de trabajo:
                        </label>
                        <span>{prestamo.usuario.areaTrabajo}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Nombre Empresa:
                        </label>
                        <span>{prestamo.usuario.nombreEmpresa}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Ingreso mensual:
                        </label>
                        <span>{prestamo.usuario.ingresoMensualUSD} $$</span>
                      </div>
                      <div className='col-span-8 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Metodo Pago:
                        </label>
                        <span>{prestamo.usuario.metodosPagos}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Fecha registro:
                        </label>
                        <span>{prestamo.usuario.fechaRegistro}</span>
                      </div>
                      <div className='col-span-12 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Descripón actividad:
                        </label>
                        <span>{prestamo.usuario.descripcionActividad}</span>
                      </div>
                      <div className='col-span-12 text-center mb-2'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          ID Usuario:
                        </label>
                        <span>{prestamo.usuario.idUsuario}</span>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/* //========== Datos Prestamo ==========// */}
      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Datos de Préstamo <AddToPhotosIcon color='primary' fontSize='small' />
      </h2>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Datos del Préstamo
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='flex p-1 flex-col bg-theme-2 box w-full mb-3 overflow-auto lg:overflow-visible'>
              {prestamo && prestamo.usuario && (
                <div className='grid grid-cols-12 gap-4 row-gap-5'>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Estado:
                    </label>
                    <span>{prestamo.estado}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Pagado:
                    </label>
                    <span>{prestamo.pagado === true ? 'Si' : 'No'}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto solicitado:
                    </label>
                    <span>{prestamo.montoPrestado} USD</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto a Pagar:
                    </label>
                    <span>{prestamo.montoCompromisoPago} USD</span>
                  </div>
                  {calCulardiasTranscurridos(prestamo.fechaInicio) > 0 && (
                    <div className='col-span-4 ml-10'>
                      <label className='font-medium p-1 bg-theme-26 box mr-1'>
                        Monto deuda restante :
                      </label>
                      {abonosRealizados.length > 0 ? (
                        <span>
                          {calcularInteresesPorAbonos()['interesHoy']}
                        </span>
                      ) : (
                        <span>{montoHoy()} USD</span>
                      )}
                    </div>
                  )}
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de inicio:
                    </label>
                    <span>{prestamo.fechaInicio}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Solicitado el:
                    </label>
                    <span>{prestamo.fechaSolicitud}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de pago:
                    </label>
                    <span>{prestamo.fechaCompromisoPago}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Interes:
                    </label>
                    <span>
                      {prestamo.usuario.habilitadoNuevoPrestamo === 'No'
                        ? prestamo.tasaInteresCompuesto
                        : prestamo.usuario.nuevaTasaInteres}{' '}
                      %
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Tasa dolar:
                    </label>
                    <span>{tasaDolarHoy}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Total en Bf:
                    </label>
                    <span>
                      {(prestamo.montoPrestado * tasaDolarHoy).toFixed(2)}
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Habilitado:
                    </label>
                    <span>{prestamo.usuario.habilitadoNuevoPrestamo}</span>
                  </div>
                  <div className='col-span-12 text-center mb-2'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      ID Prestamo:
                    </label>
                    <span>{prestamoid}</span>
                  </div>
                </div>
              )}
              {prestamo.fechaInicioDespues && (
                <>
                  <h3 className='font-medium p-2 bg-theme-23 box text-center mt-5'>
                    Préstamo con Deuda Restante
                  </h3>
                  <div className='grid grid-cols-12 gap-4 row-gap-5 bg-theme-23 box mb-5 p-2'>
                    <div className='col-span-4 p-1 bg-theme-26 box'>
                      <label className='font-medium ml-2 mr-1'>
                        Monto deuda restante:
                      </label>
                      <span>
                        {calcularInteresesPorAbonos()['interesHoy']} $
                      </span>
                    </div>
                    <div className='col-span-4 ml-10 p-1 bg-theme-26 box'>
                      <label className='font-medium ml-2 mr-1'>
                        Fecha inicio:
                      </label>
                      <span>{prestamo.fechaInicioDespues} </span>
                    </div>
                    <div className='col-span-4 ml-10 p-1 bg-theme-26 box'>
                      <label className='font-medium ml-2 mr-1'>
                        Nueva fecha de pago:
                      </label>
                      <span>{prestamo.fechaCompromisoPagoDespues} </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        {/* //===== Evaluacion del Usuaio/Préstamo =====// */}
        {prestamo.estado === 'finalizado' && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Evaluación Usuario / Préstamo
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='intro-y box col-span-12 lg:col-span-12 border-b bg-theme-5 box mb-10 w-full overflow-auto lg:overflow-visible'>
              <div className='flex p-3 border-b text-base'>
                <h2 className='font-medium bg-theme-26 box p-2 w-full'>
                  Parametros a evaluar
                </h2>
              </div>
              <form onSubmit={guardarEvaluacion}>
                <div className='intro-y col-span-12 sm:col-span-6 flex ml-8'>
                  <label className='font-medium bg-theme-2 box p-1'>
                    Habilitado Nuevo Préstamo :
                  </label>
                  <div className='font-medium flex sm:flex-row ml-3'>
                    <div className='items-center text-gray-700'>
                      <input
                        type='radio'
                        className='input border border-blue-300 mr-2 mt-1'
                        id='habilitadoSi'
                        name='habilitadoNuevoPrestamo'
                        value='Si'
                        checked={
                          usuarioPrestamo.habilitadoNuevoPrestamo === 'Si'
                        }
                        onChange={HandleChangeEvalu}
                      />
                      <label htmlFor='habilitadoSi'>Si</label>
                    </div>
                    <div className='items-center text-gray-700'>
                      <input
                        type='radio'
                        className='input border border-blue-300 ml-3 mr-2 mt-1'
                        id='habilitadoNo'
                        name='habilitadoNuevoPrestamo'
                        value='No'
                        checked={
                          usuarioPrestamo.habilitadoNuevoPrestamo === 'No'
                        }
                        onChange={HandleChangeEvalu}
                      />
                      <label htmlFor='habilitadoNo'>No</label>
                    </div>
                  </div>
                </div>
                <div className='intro-y col-span-12 sm:col-span-6 flex ml-8 mt-5'>
                  <label className='font-medium bg-theme-2 box p-1'>
                    Monto a Prestar :
                  </label>
                  <input
                    type='number'
                    className='input input--sm border rounded-lg w-64 ml-5'
                    required
                    min='0'
                    name='nuevoLimitePrestamo'
                    placeholder='Nuevo monto limite a prestar'
                    value={usuarioPrestamo.nuevoLimitePrestamo}
                    onChange={HandleChangeEvalu}
                  />
                </div>
                <div className='intro-y col-span-12 sm:col-span-6 flex ml-8 mt-5'>
                  <label className='font-medium bg-theme-2 box p-1'>
                    Tasa de Interes Compuesto % :
                  </label>
                  <input
                    type='number'
                    className='input input--sm border rounded-lg w-64 ml-5'
                    required
                    min='0'
                    step='.01'
                    name='nuevaTasaInteres'
                    placeholder='Nueva tasa de interes'
                    value={usuarioPrestamo.nuevaTasaInteres}
                    onChange={HandleChangeEvalu}
                  />
                </div>

                {/* Boton Evaluacion Prestramo */}
                <div className='cursor-pointer w-full text-center my-6'>
                  <button
                    className='flex box text-white bg-theme-3 p-2 items-center w-full justify-center'
                    // onClick={() => guardarEvaluacion()}
                  >
                    <Icon.CheckSquare className='w-4 h-4 mr-2' />
                    Guardar evaluación
                  </button>
                </div>
                {showAlert && (
                  <div className='col-span-12 mb-3'>
                    <div className='rounded-md flex items-center justify-center px-5 py-3 bg-theme-9 text-white'>
                      Datos Guardados Exitosamente
                    </div>
                  </div>
                )}
              </form>
            </div>
          </AccordionDetails>
        </Accordion>
        )}
      </div>

      {/* //========== Datos Bancarios ==========// */}
      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Datos de Banco <AddToPhotosIcon color='primary' fontSize='small' />
      </h2>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Datos Bancarios
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='flex p-1 flex-col bg-theme-2 box w-full mb-3 overflow-auto lg:overflow-visible'>
              {prestamo.datosEnvio && (
                <div className='grid grid-cols-12 gap-4 row-gap-5'>
                  <div className='col-span-5 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Nombre banco:
                    </label>
                    <span>{prestamo.datosEnvio.nombreBanco}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      País:
                    </label>
                    <span>{prestamo.datosEnvio.pais}</span>
                  </div>
                  <div className='col-span-3 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Telefono:
                    </label>
                    <span>
                      {prestamo.usuario.codigoPais} {prestamo.usuario.whatsapp}`
                    </span>
                  </div>
                  {prestamo.tipoPagoVenezuela === 'Cuenta Bancaria' ? (
                    <div className='col-span-5 ml-10'>
                      <label className='font-medium p-1 bg-theme-26 box mr-1'>
                        Número de cuenta:
                      </label>
                      <span>{prestamo.datosEnvio.numeroCuenta}</span>
                    </div>
                  ) : (
                    <div className='col-span-4 ml-10'>
                      <label className='font-medium p-1 bg-theme-26 box mr-1'>
                        Teléfono pago-movil:
                      </label>
                      <span>{prestamo.datosEnvio.telefono}</span>
                    </div>
                  )}
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Titular:
                    </label>
                    <span> {prestamo.datosEnvio.titular}</span>
                  </div>
                  <div className='col-span-3 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Cédula:
                    </label>
                    <span>{prestamo.datosEnvio.cedula}</span>
                  </div>
                  <div className='col-span-5 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto a transferir:
                    </label>
                    <span>
                      {prestamo.montoPrestado}
                      {''} USD
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Tipo de pago:
                    </label>
                    <span>{prestamo.tipoPagoVenezuela}</span>
                  </div>{' '}
                  <div className='col-span-3 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Tipo:
                    </label>
                    <span>{prestamo.datosEnvio.tipoCuenta}</span>
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Datos Pagos y Abonos{' '}
        <AddToPhotosIcon color='primary' fontSize='small' />
      </h2>

      {/* //========== Datos del Pago ==========// */}
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Pagos Realizados
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {pagosRealizados.length > 0 &&
              pagosRealizados.map((pago, i) => (
                <div
                  className='grid grid-cols-12 gap-4 row-gap-5 w-full mb-3 mt-2 p-2 bg-theme-2 box'
                  key={i + '-div'}
                >
                  <div className='col-span-12 md:col-span-5 ml-1'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Banco:
                    </label>
                    <span>{pago.nombreBanco}</span>
                  </div>
                  <div className='col-span-12 md:col-span-3 ml-1'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Titular:
                    </label>
                    <span>{pago.titular}</span>
                  </div>
                  <div className='col-span-12 md:col-span-4 ml-1'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Cédula:
                    </label>
                    <span>{pago.cedula}</span>
                  </div>
                  {prestamo.tipoPagoVenezuela === 'Cuenta Bancaria' ? (
                    <div className='col-span-12 md:col-span-5 ml-1'>
                      <label className='font-medium p-1 bg-theme-26 box mr-1'>
                        Numero de cuenta:
                      </label>
                      <span>{pago.numeroCuenta}</span>
                    </div>
                  ) : (
                    <div className='col-span-12 md:col-span-5 ml-1'>
                      <label className='font-medium p-1 bg-theme-26 box  mr-1'>
                        Teléfono pago-movil:
                      </label>
                      <span>{prestamo.datosEnvio.telefono}</span>
                    </div>
                  )}
                  <div className='col-span-12 md:col-span-3 ml-1'>
                    <label className='font-medium bg-theme-26 box p-1 mr-1'>
                      Monto:
                    </label>
                    <span>{pago.monto} $</span>
                  </div>
                  <div className='col-span-12 flex md:col-span-4 ml-1'>
                    <label className='font-medium bg-theme-26 box p-1'>
                      Detalles pago:
                    </label>
                    <button
                      className='flex items-center text-theme-12 font-medium bg-theme-5 box p-1 ml-2'
                      onClick={() => VerDetallePago(pago)}
                    >
                      <Icon.Search className='w-5 h-5 mr-2 text-theme-3' />
                      Ver Pago
                    </button>
                  </div>
                </div>
              ))}
          </AccordionDetails>

          {/* Boton Modal Agregar Pagos */}
          {pagosRealizados.length <= 0 && (
            <div className='flex justify-center cursor-pointer mb-5'>
              <div className='box p-3 bg-theme-3 text-white'>
                <button className='flex items-center' onClick={agregarPagos}>
                  <Icon.CreditCard className='w-4 h-4 mr-2' />
                  Agregar Pagos
                  <Icon.DollarSign className='w-4 h-4 ml-2' />
                </button>
              </div>
            </div>
          )}
        </Accordion>

        {/* //========== Datos Abonos del Prestamo ==========// */}
        <Accordion defaultExpanded={abonoConfirmado === false}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Abonos Realizados
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='intro-y col-span-12 mb-3 bg-theme-2 box p-2 w-full overflow-auto lg:overflow-visible'>
              <table className='table-report w-full p-3'>
                <thead>
                  <tr className='box bg-theme-26 p-1'>
                    <th className='whitespace-no-wrap text-center p-3'>
                      TITULAR
                    </th>
                    <th className='whitespace-no-wrap text-center'>FECHA</th>
                    <th className='whitespace-no-wrap text-center'>
                      REFERENCIA
                    </th>
                    <th className='whitespace-no-wrap text-center'>MONTO</th>
                    <th className='whitespace-no-wrap text-center'>DETALLE</th>
                    <th className='whitespace-no-wrap text-center'>ACCION</th>
                  </tr>
                </thead>
                <tbody>
                  {abonosRealizados.length > 0 &&
                    abonosRealizados.map((abono, idUsuario) => (
                      <tr className='intro-x text-center' key={idUsuario}>
                        <td className='p-3'>
                          {abono.nombres} {abono.apellidos}
                        </td>
                        <td>{abono.fecha}</td>
                        <td>{abono.idTransaccion}</td>
                        <td>{abono.monto} $</td>
                        <td>
                          <a
                            className='font-medium text-theme-12 ml-3 flex items-center'
                            href='#!'
                            onClick={(e) => {
                              e.preventDefault();
                              verDetalle(abono);
                            }}
                          >
                            <Icon.Search className='w-4 h-4 ml-2 mr-1' />
                            Ver Abono
                          </a>
                        </td>
                        <td>
                          {' '}
                          {abono.confirmado !== true && (
                            <div className='flex justify-center items-center font-medium  text-theme-3'>
                              <Link
                                className='flex items-center text-theme-3'
                                onClick={() =>
                                  confirmarAbonos(prestamoid, abono)
                                }
                              >
                                <Icon.Settings className='w-4 h-4 mr-1' />
                                Confirmar
                                <Icon.CheckSquare className='w-3 h-3 ml-1' />
                              </Link>
                            </div>
                          )}
                          {abono.confirmado === true && (
                            <div className='flex justify-center items-center font-medium  text-theme-3 ml-4 bg-theme-30 box p-1'>
                              <label className='flex items-center'>
                                <Icon.Check className='w-3 h-3 mr-1' />
                                Confirmado
                                <Icon.Check className='w-3 h-3 ml-1' />
                              </label>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </AccordionDetails>
          {/* Boton Modal Reporta Pagos */}
          {prestamo.estado === 'prestado' && (
            <div className='flex justify-center cursor-pointer mb-5'>
              <div className='box p-3 bg-theme-3 text-white'>
                <button className='flex items-center' onClick={abonar}>
                  <Icon.CreditCard className='w-4 h-4 mr-2' />
                  Reportar Pagos
                  <Icon.DollarSign className='w-4 h-4 ml-2' />
                </button>
              </div>
            </div>
          )}
        </Accordion>

        {/* //===== Estado de cuenta del prestamo =====// */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Estado de Cuenta del Préstamo
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='intro-y box col-span-12 lg:col-span-12 border-b bg-theme-5 box mb-10 w-full overflow-auto lg:overflow-visible'>
              <div className='flex p-3 border-b text-base'>
                <h2 className='font-medium bg-theme-26 box p-2 w-full'>
                  Estado de Cuenta
                </h2>
              </div>
              {/* Boton Completar Pago */}
              {/* {calcularInteresesPorAbonos()['interesHoy'] === 0 &&
                prestamo.estado !== 'completado' && (
                  <div className='cursor-pointer w-full text-center mb-3'>
                    <button
                      className='flex box text-white bg-theme-3 p-2 items-center w-full justify-center'
                      onClick={() => completarPago()}
                    >
                      <Icon.CheckSquare className='w-4 h-4 mr-2' />
                      Completar Pago
                      <Icon.Check className='w-3 h-3 ml-2' />
                    </button>
                  </div>
                )} */}
              <div className='font-medium items-center ml-10 mb-5'>
                <div className='items-center mb-3'>
                  <label className='font-medium bg-theme-27 box p-1 mr-2'>
                    Monto Préstado :
                  </label>
                  <span>{prestamo.montoPrestado} $</span>
                </div>
                <div className='items-center mb-3'>
                  <label className='font-medium bg-theme-25 box p-1 mr-2'>
                    Monto Compromiso a Pagar :
                  </label>
                  <span>{prestamo.montoCompromisoPago} $</span>
                </div>
                <div className='items-center mb-3'>
                  <label className='font-medium bg-theme-36 box p-1 mr-2'>
                    Último Abono :
                  </label>
                  {/*<span> {totalAbonos(abonosRealizados)} $</span>*/}
                  <span>
                    {' '}
                    {abonosRealizados.length !== 0
                      ? calcularInteresesPorAbonos()['ultiAbono']
                      : '0'}{' '}
                    $
                  </span>
                </div>
                <div className='items-center mb-3'>
                  <label className={bgMontoPendiente()}>
                    Monto Pendiente :
                  </label>
                  <span>
                    {abonosRealizados.length !== 0
                      ? calcularInteresesPorAbonos()['interesHoy']
                      : montoHoy()}{' '}
                    $
                  </span>
                </div>
              </div>
              {prestamo.fechaInicioDespues && (
                <div className='font-medium text-right mb-5'>
                  <h2 className='font-medium text-center text-sm my-3 bg-theme-27 box p-1'>
                    Préstamo con Deuda Despues de Fecha Compromiso de Pago
                  </h2>
                  <div className='mb-3 mr-12'>
                    <label className='bg-theme-15 box p-1 mr-4'>
                      Nuevo monto pendiente :
                    </label>
                    <span>
                      {abonosRealizados.length !== 0
                        ? calcularInteresesPorAbonos()['interesHoy']
                        : montoHoy()}{' '}
                      $
                    </span>
                  </div>
                  <div className='mb-3 mr-10'>
                    <label className='bg-theme-25 box p-1 mr-2'>
                      Nueva fecha inicio :
                    </label>
                    <span>{prestamo.fechaInicioDespues}</span>
                  </div>
                  <div className='mb-3 mr-10'>
                    <label className='bg-theme-14 box p-1 mr-2'>
                      Nueva fecha pago :
                    </label>
                    <span>{prestamo.fechaCompromisoPagoDespues}</span>
                  </div>
                </div>
              )}

              {/* Distribución de intereses */}
              <h2 className='font-medium bg-theme-27 box p-1 mb-2'>
                <div className='flex cursor-pointer justify-center w-full'>
                  Distribución de los interéses por día
                  <button
                    className='flex box text-white bg-theme-3 px-3 items-center w-32 justify-center ml-12'
                    onClick={() => diff()}
                  >
                    <Icon.Search className='text-white mr-3 w-4' />
                    Ver
                  </button>
                </div>
              </h2>
              <div
                className='col-span-12 sm:col-span-12 flex justify-center items-center mt-8 mb-2 lg:overflow-visible'
                style={{ flexDirection: 'column' }}
              >
                <div className='carousel'>
                  <Slider {...settings} className='w-full'>
                    {days.map((day, i) => {
                      return (
                        <div
                          className='box px-2 py-2 mb-3 flex items-center zoom-in bg-theme-26'
                          key={i}
                        >
                          <div>
                            <div className='font-medium'>
                              {`${formatFecha(
                                prestamo.usuario.fechaInicio,
                                day
                              )}:`}
                            </div>
                            <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                              {`${calculateInteresByDia(day)}$`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <button
                  className='button w-full xl:w-32 text-white bg-theme-1 xl:mr-3 p-2 mt-3 flex items-center justify-center'
                  // onClick={exportar}
                >
                  <Icon.FileText className='text-white mr-2 ml-1 w-4' />
                  Exportar
                </button>
              </div>

              {/* Boton Finalizar Proceso */}
              {abonosRealizados.length >= 1 &&
                prestamo.estado === 'prestado' && (
                  <div className='cursor-pointer w-full text-center my-8'>
                    <button
                      className='flex box text-white bg-theme-3 p-2 items-center w-full justify-center'
                      onClick={() => finalizarProceso()}
                    >
                      <Icon.CheckSquare className='w-4 h-4 mr-2' />
                      Finalizar Proceso del Préstamo
                    </button>
                  </div>
                )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Chat <ChatIcon color='primary' fontSize='small' />
      </h2>
      <Chat
        chat={chat}
        gestor={true}
        prestamo={prestamo}
        rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
      />

      {/*// ===== Modal Agregar Ingresos Contables =====// */}
      {modalPagos && (
        <Modal
          title='Agregar Pagos Realizados'
          size='xl'
          close={cancelAgregarPagos}
          save={() => guardarAgregarPagos()}
          cancel={cancelAgregarPagos}
        >
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Código banco :
            </label>
            <input
              type='text'
              name='banco'
              placeholder=' Codigo de Banco'
              autoFocus={true}
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.banco}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Nombre banco :
            </label>
            <input
              type='text'
              name='nombreBanco'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Nombre de Banco'
              value={pagos.nombreBanco}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          {pagos.tipoPagoVenezuela !== 'Pago Movil' && (
            <div className='col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>
                Número de cuenta :
              </label>
              <input
                type='text'
                name='numeroCuenta'
                placeholder=' Numero de Cedula Titular'
                className='w-full rounded-full border input p-1 mt-2'
                value={pagos.numeroCuenta}
                onChange={changeAgregarPagos}
                onKeyUp={changeAgregarPagos}
              />
            </div>
          )}
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo de pago :
            </label>
            <input
              type='text'
              name='tipoPagoVenezuela'
              placeholder=' Tipo Pago Venezuela'
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.tipoPagoVenezuela}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Cédula titular :
            </label>
            <input
              type='text'
              name='cedula'
              placeholder=' Cedula del Titular'
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.cedula}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              placeholder=' Titular de Cuenta'
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.titular}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Monto transferido :
            </label>
            <input
              type='text'
              name='monto'
              placeholder=' Monto Transferido'
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.monto}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Monto equivalente : (Bs, $ )
            </label>
            <input
              type='number'
              name='montoBs'
              placeholder=' Monto en moneda transferido'
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.montoBs}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número comprobante :
            </label>
            <input
              type='text'
              name='idTransaccion'
              placeholder=' Número Comprobante/Referencia'
              className='w-full rounded-full border input p-1 mt-2'
              value={pagos.idTransaccion}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
            <input
              type='text'
              name='fecha'
              className='flex w-full rounded-full border input p-1 mt-2'
              value={pagos.fecha}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
            />
          </div>
          <div className='col-span-12 sm:col-span-4 mt-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Monto solicitado por el usuario :
            </label>
            <label className='font-medium mt-2 ml-2'>
              {prestamo.montoPrestado},00 $
            </label>
          </div>
          <div className='flex col-span-12 sm:col-span-12 justify-center mt-3'>
            <label className='font-medium bg-theme-26 box px-5 py-1'>
              Comprobante :
            </label>
            <button
              className='button flex text-white font-medium bg-theme-1 p-1 mx-5'
              onClick={openExplorer}
            >
              <Icon.Upload className='w-5 h-5 mx-2 pointer' /> Adjuntar Recibo
            </button>
            {fileName !== '' && (
              <div className='w-auto flex bg-theme-2 box mt-1 p-1'>
                <label>{fileName}</label>
                <Icon.X
                  className='w-5 h-5 ml-3 pointer box bg-theme-5'
                  onClick={removeRecibo}
                />
              </div>
            )}
            <input
              type='file'
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={loadFile}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Concepto :
            </label>
            <textarea
              type='text'
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Concepto del movimiento... '
              name='concepto'
              value={pagos.concepto}
              onChange={changeAgregarPagos}
              onKeyUp={changeAgregarPagos}
              rows='2'
            />
          </div>
        </Modal>
      )}
      {/*// ===== Modal Ver Detalles de Pagos =====// */}
      {verDetallePagos && (
        <Modal
          title='Ver Detalles Pagos Realizados'
          close={closeVerDetallePagos}
          cancel={cancelVerDetallePagos}
          customBtn={true}
          textcustomBtn='Aceptar'
          save={() => {
            setVerDetallePagos(false);
          }}
          size='xl'
        >
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Código :
            </label>
            <span>{detallePago.banco}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Nombre :
            </label>
            <span>{detallePago.nombreBanco}</span>
          </div>
          {prestamo.tipoPagoVenezuela === 'Cuenta Bancaria' ? (
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1 ml-1'>
                Número de cuenta :
              </label>
              <span>{detallePago.numeroCuenta}</span>
            </div>
          ) : (
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1 ml-1'>
                Teléfono pago-movil :
              </label>
              <span>{prestamo.datosEnvio.telefono}</span>
            </div>
          )}
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Número comprobante :
            </label>
            <span>{detallePago.idTransaccion}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Monto :
            </label>
            <span>{detallePago.monto}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Monto en bolívares :
            </label>
            <span>{detallePago.montoBs} Bs</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Titular :
            </label>
            <span>{detallePago.titular}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Cédula titular :
            </label>
            <span>{detallePago.cedula}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Tipo de pago :
            </label>
            <span>{detallePago.tipoPagoVenezuela}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Fecha :
            </label>
            <span>{detallePago.fecha}</span>
          </div>
          <div className='col-span-12 sm:col-span-6 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Concepto :
            </label>
            <span>{detallePago.concepto}</span>
          </div>
          <div className='col-span-12 sm:col-span-11 ml-32'>
            {detallePago.recibo === '' && (
              <p className='font-medium bg-theme-5 box p-1 text-center'>
                No se ha adjuntado el recibo
              </p>
            )}
            {detallePago.recibo !== '' && (
              <img
                alt=''
                src={detallePago.recibo}
                style={{ width: '100%', height: 'auto' }}
              />
            )}
          </div>
        </Modal>
      )}
      {showDealle === true && (
        <Modal
          title='Detalles de Abono'
          close={() => setShowDetalle(false)}
          save={() => setShowDetalle(false)}
          cancel={() => setShowDetalle(false)}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Titular :
            </label>
            <label className='font-medium'>
              {abono.nombres} {abono.apellidos}
            </label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Fecha :
            </label>
            <label className='font-medium'>
              {moment(abono.fecha).format('YYYY-MM-DD')}
            </label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Referencia :
            </label>
            <label className='font-medium'>{abono.idTransaccion}</label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Monto :
            </label>
            <label className='font-medium'>{abono.monto}</label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Concepto :
            </label>
            <label className='font-medium'>{abono.concepto}</label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Confirmado :
            </label>
            <label className='font-medium'>
              {abono.confirmado ? 'Si' : 'No'}
            </label>
          </div>
          <div className='col-span-12 sm:col-span-11 ml-32'>
            {abono.voucher === '' && (
              <p className='font-medium bg-theme-5 box p-1 text-center'>
                No se ha adjuntado el recibo
              </p>
            )}
            {abono.voucher !== '' && (
              <img
                alt={abono.concepto}
                src={abono.voucher}
                style={{ width: '100%', height: 'auto' }}
                className='w-full mt-3'
              />
            )}
          </div>
        </Modal>
      )}
      {modalAbono && (
        <Modal
          title='Abonar Pago'
          close={cancelAbono}
          save={guardarAbono}
          cancel={cancelAbono}
          size='xl'
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='mb-2 font-medium text-xl'>
              Total deuda :{' '}
              {misabonos.length === 0 ? totalRestante() : totalRestanteAbonos()}
            </label>
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              n° de Transacción :
            </label>
            <input
              type='text'
              name='idTransaccion'
              placeholder=' N° de operacion'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.idTransaccion}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
            <input
              type='date'
              name='fecha'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.fecha}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <div className='flex items-center'>
              <label className='font-medium bg-theme-26 box p-1 mr-2'>
                Monto :
              </label>
              <input
                type='checkbox'
                className='input border'
                id='todo'
                name='monto[]'
                value='todo'
                onChange={changeMontoType}
                checked={montoPagar === 'todo'}
              />
              <label
                className='cursor-pointer select-none ml-1 mr-3'
                htmlFor='todo'
              >
                Todo :
              </label>
              <input
                type='checkbox'
                className='input border mr-2'
                id='abono'
                name='monto[]'
                value='abono'
                onChange={changeMontoType}
                checked={montoPagar === 'abono'}
              />
              <label
                className='cursor-pointer select-none ml-1'
                htmlFor='abono'
              >
                Abono :
              </label>
            </div>
            <input
              type='text'
              name='monto'
              placeholder=' Monto de la operación'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.monto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              disabled={disabled}
            />
          </div>
          <div className='flex col-span-12 sm:col-span-6 mt-8 justify-between'>
            <label className='font-medium bg-theme-26 box p-2 ml-8'>
              Adjuntar recibo :
            </label>
            <div className='flex'>
              <button
                className='button font-medium text-theme-5 bg-theme-1 rounded-full flex mr-8'
                onClick={(e) => openExplorerPago(e, abono)}
              >
                <Icon.FilePlus className='w-5 h-5 mr-2 pointer' />
                Subir Archivo
              </button>
            </div>
          </div>
          <div className='intro-y col-span-12 sm:col-span-12 bg-theme-2 box'>
            {fileName !== '' && (
              <div className='w-auto flex ml-2 justify-center p-1'>
                <label>{fileName}</label>
                <Icon.X
                  className='w-5 h-5 ml-3 pointer box bg-theme-5'
                  onClick={removeRecibo}
                />
              </div>
            )}
            <input
              type='file'
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={loadFileAbono}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Comentario :
            </label>
            <textarea
              type='text'
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Concepto del abono... '
              name='concepto'
              value={pago.concepto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              rows='2'
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Detalle;
