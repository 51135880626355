import {
  REDESCHAMOPRESTA, LISTADOACTIVO
} from "../types";

const initialState = {
  redessocialeschamopresta: [],
  listadoActivo: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REDESCHAMOPRESTA:
      return {
        ...state,
        redessocialeschamopresta: action.payload,
      };
    case LISTADOACTIVO:
      return {
        ...state,
        listadoActivo: action.payload,
      }
    default:
      return state;
  }
}