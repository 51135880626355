import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import $ from "jquery";
import feather from "feather-icons";
import * as Icon from "react-feather";
import Modal from "../../Modal";

import {
  getListPrestamosGestor,
  getPrestamosGestorPendiente,
  getPrestamosGestorProceso,
  getPrestamosGestorAprobado,
  getPrestamosGestorAcobrar,
  getPrestamosGestorRechazado,
} from "../../../redux/actions/gestorActions";

export default function Dashboard() {
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const prestamosGestor = useSelector(
    (state) => state.gestor.listPrestamosGestor
  );
  const prestamosGestorPendiente = useSelector(
    (state) => state.gestor.prestamosGestorPendiente
  );
  const prestamosGestorProceso = useSelector(
    (state) => state.gestor.prestamosGestorProceso
  );
  const prestamoAprobadoGestor = useSelector(
    (state) => state.gestor.prestamosGestorAprobado
  );
  const prestamoGestorAcobrar = useSelector(
    (state) => state.gestor.prestamosGestorAcobrar
  );
  const prestamosGestorRechazado = useSelector(
    (state) => state.gestor.prestamosGestorRechazado
  );
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    (function ($) {
      feather.replace({ "stroke-width": 1.5 });
    })($);
    dispatch(getListPrestamosGestor());
    dispatch(getPrestamosGestorAprobado());
    dispatch(getPrestamosGestorAcobrar());
    dispatch(getPrestamosGestorProceso());
    dispatch(getPrestamosGestorPendiente());
    dispatch(getPrestamosGestorRechazado());
  }, []);

  const close = () => {
    setShowModal(false);
  };
  const cancel = () => {
    setShowModal(false);
  };
  const save = () => {
    alert("click boton save");
  };

  return (
    <>
      {/* Boton Modal */}
      {/* <div className="text-center mt-5">
        <a
          href="javascript:;"
          className="button inline-block bg-theme-1 text-white"
          onClick={() => setShowModal(true)}
        >
          Show Modal
        </a>
      </div> */}

      <div className="col-span-12 mt-1">
        <div className="intro-y flex items-center justify-between h-10">
          <h2 className="text-lg font-medium ml-8">Reporte General Gestor</h2>
          <h2 className="text-lg font-medium text-theme-10 truncate mr-5">
            {/* {theViewReport} */}
          </h2>
        </div>
        <hr />

        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-12 sm:col-span-4 intro-y">
            <div
              className="report-box zoom-in"
              // onClick={handleClickFacebook}
            >
              <div className="box p-5">
                <div className="flex items-center">
                  {/* <i
                    data-feather="facebook"
                    className="report-box__icon text-theme-10"
                  ></i> */}
                  <Icon.DollarSign className="w-6 h-6 text-theme-10" />
                  <span className="text-theme-10 ml-3 font-medium">
                    Préstamos
                  </span>
                  <div className="ml-auto">
                    <div
                      className="report-box__indicator bg-theme-10 tooltip cursor-pointer"
                      title="Facebook"
                    >
                      {/* {fbPercent} % */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {prestamosGestor.length}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Préstamos
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {/* {fbPagesDone} */}
                    </div>
                    {/* <div className="text-base text-gray-600 mt-1">
                      Realizadas
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 intro-y">
            <div
              className="report-box zoom-in"
              //  onClick={handleClickWebs}
            >
              <div className="box p-5">
                <div className="flex items-center">
                  <Icon.HelpCircle className="w-6 h-6 text-theme-36" />
                  <span className="text-theme-36 ml-3 font-medium">
                    Pendientes por Gestionar
                  </span>
                  <div className="ml-auto">
                    <div
                      className="report-box__indicator bg-theme-36 tooltip cursor-pointer"
                      title="Webs"
                    >
                      {/* {wPercent} % */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {prestamosGestorPendiente.length}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Pendientes
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {/* {wPagesDone} */}
                    </div>
                    {/* <div className="text-base text-gray-600 mt-1">
                      Realizadas
                    </div> */}
                    {/* <div className="text-base text-gray-600 mt-1">{pagesErrorsLength} marcadas como error</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 intro-y">
            <div
              className="report-box zoom-in"
              //  onClick={handleClickWebs}
            >
              <div className="box p-5">
                <div className="flex items-center">
                  <Icon.Activity className="w-6 h-6 text-theme-11" />
                  <span className="text-theme-11 ml-3 font-medium">
                    En Gestión
                  </span>
                  <div className="ml-auto">
                    <div
                      className="report-box__indicator bg-theme-11 tooltip cursor-pointer"
                      title="Webs"
                    >
                      {/* {wPercent} % */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {prestamosGestorProceso.length}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Gestionando
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {/* {wPagesDone} */}
                    </div>
                    {/* <div className="text-base text-gray-600 mt-1">
                      Realizadas
                    </div> */}
                    {/* <div className="text-base text-gray-600 mt-1">{pagesErrorsLength} marcadas como error</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 intro-y">
            <div
              className="report-box zoom-in"
              //  onClick={handleClickWebs}
            >
              <div className="box p-5">
                <div className="flex items-center">
                  <Icon.ThumbsUp className="w-6 h-6 text-theme-9" />
                  <span className="text-theme-9 ml-3 font-medium">
                    Aprobados
                  </span>
                  <div className="ml-auto">
                    <div
                      className="report-box__indicator bg-theme-9 tooltip cursor-pointer"
                      title="Webs"
                    >
                      {/* {wPercent} % */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {prestamoAprobadoGestor.length}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Aprobados
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {/* {wPagesDone} */}
                    </div>
                    {/* <div className="text-base text-gray-600 mt-1">
                      Realizadas
                    </div> */}
                    {/* <div className="text-base text-gray-600 mt-1">{pagesErrorsLength} marcadas como error</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 intro-y">
            <div
              className="report-box zoom-in"
              //  onClick={handleClickWebs}
            >
              <div className="box p-5">
                <div className="flex items-center">
                  <Icon.AlertTriangle className="w-6 h-6 text-theme-12" />
                  <span className="text-theme-12 ml-3 font-medium">
                    Por Cobrar
                  </span>
                  <div className="ml-auto">
                    <div
                      className="report-box__indicator bg-theme-12 tooltip cursor-pointer"
                      title="Webs"
                    >
                      {/* {wPercent} % */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {prestamoGestorAcobrar.length}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total por Cobrar
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {/* {wPagesDone} */}
                    </div>
                    {/* <div className="text-base text-gray-600 mt-1">
                      Realizadas
                    </div> */}
                    {/* <div className="text-base text-gray-600 mt-1">{pagesErrorsLength} marcadas como error</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 intro-y">
            <div
              className="report-box zoom-in"
              //  onClick={handleClickWebs}
            >
              <div className="box p-5">
                <div className="flex items-center">
                  <Icon.ThumbsDown className="w-6 h-6 text-theme-6" />
                  <span className="text-theme-6 ml-3 font-medium">
                    Rechazados
                  </span>
                  <div className="ml-auto">
                    <div
                      className="report-box__indicator bg-theme-6 tooltip cursor-pointer"
                      title="Webs"
                    >
                      {/* {wPercent} % */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {prestamosGestorRechazado.length}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Rechazados
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {/* {wPagesDone} */}
                    </div>
                    {/* <div className="text-base text-gray-600 mt-1">
                      Realizadas
                    </div> */}
                    {/* <div className="text-base text-gray-600 mt-1">{pagesErrorsLength} marcadas como error</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <Modal title="Ejemplo Modal" close={close} save={save} cancel={cancel}>
          <div className="col-span-12 sm:col-span-6">
            <label>From</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="example@gmail.com"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>To</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="example@gmail.com"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Subject</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="Important Meeting"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Has the Words</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="Job, Work, Documentation"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Doesn't Have</label>
            <input
              type="text"
              className="input w-full border mt-2 flex-1"
              placeholder="Job, Work, Documentation"
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label>Size</label>
            <select className="input w-full border mt-2 flex-1">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div>
        </Modal>
      )}
    </>
  );
}
