import React, { useEffect } from 'react';
import { app } from '../../../config/firebaseConfig';
import { useSelector, useDispatch } from 'react-redux';

import feather from 'feather-icons';
import moment from 'moment';
import * as Icon from 'react-feather';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { getMisPrestamos } from '../../../redux/actions/userAction';
import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';

const MisPrestamos = () => {
  const dispatch = useDispatch();
  const hoy = moment(new Date()).format('YYYY-MM-DD');

  //========== USE-SELECTORS ==========//
  const misprestamos = useSelector((state) => state.user.misprestamos);
  const abonosRealizados = useSelector(
    (state) => state.superadmin.abonosRealizados
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getMisPrestamos());
    listenAbonos();
  }, []);

  const colorBadge = (estado) => {
    switch (estado) {
      case 'iniciado':
        return 'badge bg-theme-36';
      case 'rechazado':
        return 'badge bg-theme-6';
      case 'pendiente':
        return 'badge  bg-theme-12';
      case 'preAprobado':
        return 'badge  bg-orange-500';
      case 'aprobado':
        return 'badge  bg-theme-9';
      default:
        return 'badge bg-theme-1';
    }
  };

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'montoPrestado',
      headerName: 'SOLICITADO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 132,
      renderCell: (params) => (
        <span className='m-auto'>{params.row.montoPrestado} $</span>
      ),
    },
    {
      field: 'fechaInicio',
      headerName: 'FECHA INICIO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 150,
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'FECHA COMPROMISO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 195,
      valueGetter: (params) => {
        return params.row.fechaCompromisoPagoDespues
          ? params.row.fechaCompromisoPagoDespues
          : params.row.fechaCompromisoPago;
      },
    },
    {
      field: 'montoCompromisoPago',
      headerName: 'COMPROMISO PAGO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 205,
      renderCell: (params) => (
        <span className='m-auto'>{params.row.montoCompromisoPago} $</span>
      ),
    },
    {
      field: 'estado',
      headerName: 'ESTADO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 132,
      cellClassName: 'unset-line',
      renderCell: (params) => (
        <div className={colorBadge(params.row.estado)}>
          <Icon.Tag className='w-4 h-4 mr-2' />
          {params.row.estado}
        </div>
      ),
    },
    {
      field: 'pagado',
      headerName: 'PAGADO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 110,
      renderCell: (params) => (
        <span className='m-auto'>
          {params.row.pagado === true ? 'Si' : 'No'}
        </span>
      ),
    },
    {
      field: 'deudaaldia',
      headerName: 'DEUDA ACTUAL',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 165,
      renderCell: (params) => (
        <div className='m-auto'>
          {abonosRealizados.length !== 0
            ? calcularInteresesPorAbonos()['interesHoy']
            : montoHoy()}{' '}
          $
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      field: 'misprestamos',
      headerName: 'SOLICITUDES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 1088,
      renderCell: (misprestamos) => (
        <span className='m-auto'>
          {misprestamos.length === 0
            ? ''
            : 'Usuario no ha realizado ninguna solicitud o no tiene ningún préstamo'}
        </span>
      ),
    },
  ];
  const rows1 = [
    {
      id: '',
      username: '',
      age: '',
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  const listenAbonos = () => {
    let query = app.firestore().collection('abonos');
    query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          /*dispatch(misAbonos(prestamoActual.id));
          dispatch(getChat(prestamoActual.id));*/
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };
  const montoHoy = (prestamoActual) => {
    if (prestamoActual) {
      let dias = calCulardiasTranscurridos(prestamoActual.fechaInicio);
      let monto = calculateInteres(
        prestamoActual.montoPrestado,
        dias,
        parseFloat(prestamoActual.tasaInteresCompuesto)
      );
      return parseFloat(monto);
    } else {
      return 0;
    }
  };
  // const calcularInteresesPorAbonos = (abonosRecibido, prestamoActual) => {
  //   let deudas = [];
  //   let fechas = [];
  //   let valorRetornar = {};
  //   let dif = 0;
  //   let resta = 0;
  //   let interes = 0;
  //   let proximoInteres = 0;
  //   let arrayRecorrer = [];
  //   arrayRecorrer = abonosRecibido;
  //   arrayRecorrer.forEach((item, key) => {
  //     if (key === 0) {
  //       dif = restaFechas(prestamoActual.fechaInicio, item.fecha);
  //       interes = calculateInteres(
  //         prestamoActual.montoPrestado,
  //         dif,
  //         parseFloat(prestamoActual.tasaInteresCompuesto)
  //       );
  //       resta = parseFloat(interes) - item.monto;
  //       deudas.push(resta);
  //       fechas.push(item.fecha);

  //       let diff2 = calCulardiasTranscurridos(item.fecha);
  //       proximoInteres = calculateInteres(
  //         resta,
  //         diff2,
  //         parseFloat(prestamoActual.tasaInteresCompuesto)
  //       );

  //       valorRetornar = {
  //         deuda: resta,
  //         interesHoy: parseFloat(proximoInteres),
  //         ultiAbono: item.monto,
  //         interes,
  //       };
  //     } else {
  //       dif = calCulardiasTranscurridos(item.fecha);
  //       dif = restaFechas(fechas[key - 1], item.fecha);
  //       interes = calculateInteres(
  //         deudas[key - 1],
  //         dif,
  //         parseFloat(prestamoActual.tasaInteresCompuesto)
  //       );
  //       resta = parseFloat(interes) - item.monto;
  //       deudas.push(resta);
  //       fechas.push(item.fecha);
  //       let diff2 = calCulardiasTranscurridos(item.fecha);
  //       proximoInteres = calculateInteres(
  //         resta,
  //         diff2,
  //         parseFloat(prestamoActual.tasaInteresCompuesto)
  //       );
  //       valorRetornar = {
  //         deuda: resta,
  //         interesHoy: parseFloat(proximoInteres),
  //         ultiAbono: item.monto,
  //         interes,
  //       };
  //     }
  //   });
  //   return valorRetornar;
  // };

  const calcularInteresesPorAbonos = () => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    abonosRealizados.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(misprestamos.fechaInicio, item.fecha);
        interes = calculateInteres(
          misprestamos.montoPrestado,
          dif,
          parseFloat(misprestamos.tasaInteresCompuesto)
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(misprestamos.tasaInteresCompuesto)
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(misprestamos.tasaInteresCompuesto)
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(misprestamos.tasaInteresCompuesto)
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  return (
    <div>
      <div className='flex border-b mt-3 mb-2'>
        <h2 className='text-lg font-medium ml-8'>Historial de Préstamos</h2>
      </div>
      <hr />

      <div className='grid grid-cols-12 gap-3'>
        <div className='col-span-12 overflow-auto lg:overflow-visible'>
          <div style={{ height: 500, width: '100%' }} className={classes.root}>
            <DataGrid
              rows={misprestamos.length === 0 ? rows1 : misprestamos}
              columns={misprestamos.length === 0 ? columns1 : columns}
              pageSize={7}
              components={{
                pagination: CustomPagination,
              }}
              localeText={{
                columnMenuLabel: 'Menu',
                columnMenuShowColumns: 'Show columns',
                columnMenuFilter: 'Filtrar',
                columnMenuSortAsc: 'Ordenar Ascendente',
                columnMenuSortDesc: 'Ordenar Descendente',
                columnMenuHideColumn: '',
                columnMenuUnsort: '',
                footerRowSelected: (count) => count !== 1,
                //   ? `${count.toLocaleString()} Cambiando Rol`
                //   : `${count.toLocaleString()} Cambiando Rol`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MisPrestamos;
