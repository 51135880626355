/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';

import Select from 'react-select';
import moment from 'moment';
import * as Icon from 'react-feather';
import { app } from '../../../config/firebaseConfig';

import { getUsuario } from '../../../redux/actions/superAdminActions';
import {
  deleteCuentaBancaria,
  modalCuenta,
  modalCuentaEcu,
  modalCuentaCol,
  modalCuentaPan,
  modalMetodoPago,
  saveCuentaBancaria,
  updateCuentaBancaria,
  changeSelected,
  savePagoMovil,
  deletePgoMovil,
  ModalPago,
  updatePgoMovil,
  generatePrestamo,
  getPrestamoUser,
  getPagosMoviles,
} from '../../../redux/actions/userAction';
import {
  GETCUENTASBANCARIAS,
  GETPAGOSMOVILES,
  GET_CONFIGURACION,
} from '../../../redux/types';
import Slider from 'react-slick/lib/slider';

//========== Import Acordion MaterialUI ==========//
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import listadoBancos from '../../../config/listadoBancos';
import listadoBancosEcu from '../../../config/listadoBancosEcu';
import listadoBancosPan from '../../../config/listadoBancosPan';
import listadoBancosCol from '../../../config/listadoBancosCol';
import metodosPagos from '../../../config/metodosPagos';
import CardCuentasBancarias from '../../user/prestamos/CardCuentasBancarias';
import Modal from '../../Modal';
import { custom } from '../../../helpers/alertas';
import DatosUsuario from './DatosUsuario';
import CargaRequerimientos from './CargaRequerimientos';

const hoy = moment(new Date()).format('YYYY-MM-DD');

const GenerarPrestamo = () => {
  let { UserId } = useParams();
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const new_date = moment(new Date());
  const mindate = new_date.add(4, 'days').format('YYYY-MM-DD');
  const ref = useRef(null);

  //========== USE-SELECTORS ==========//
  const usuario = useSelector((state) => state.superadmin.usuario);
  const indexWizard = useSelector((state) => state.user.indexWizard);
  const prestamoActual = useSelector((state) => state.user.prestamoActual);
  const selectedCuenta = useSelector((state) => state.user.selectedCuenta);
  const modalCuentaBanacaria = useSelector(
    (state) => state.user.modalCuentaBanacaria
  );
  const modalCuentaBcoEcu = useSelector(
    (state) => state.user.modalCuentaBcoEcu
  );
  const modalCuentaBcoCol = useSelector(
    (state) => state.user.modalCuentaBcoCol
  );
  const modalCuentaBcoPan = useSelector(
    (state) => state.user.modalCuentaBcoPan
  );
  const modalPagoMovil = useSelector((state) => state.user.modalPagoMovil);
  const modalMetodosPagos = useSelector(
    (state) => state.user.modalMetodosPagos
  );

  //========== USE-STATES ==========//
  const [cantidad, setCantidad] = useState(5);
  const [fechaInic, setFechaInic] = useState(hoy);
  const [fecha, setFecha] = useState(hoy);
  const [days, setDays] = useState([]);
  const [totalPagar, setTotalPagar] = useState(0);
  const [tasaDolarHoy, setTasaDolarHoy] = useState(0);
  const [diasPagar, setDiasPagar] = useState(0); //
  const [montoBS, setMontoBS] = useState(); //
  const [cuentasBancarias, setCuentasBancarias] = useState([]);
  const [pagoMovil, setPagoMovil] = useState([]);

  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: 100,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });
  const [expanded, setExpanded] = useState(false);
  const [generales, setGenerales] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    fechaNacimiento: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: 'Venezuela-Transferencia',
    nombres: '',
    nombreEmpresa: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    sexo: 'M',
    telefonoOpcional: '',
    telegram: '',
    trabaja: 'No',
    whatsapp: '',
  });
  const [ctabancaria, setCtabancaria] = useState({
    banco: '',
    cedula: '',
    idUsuario: UserId,
    fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
    nombreBanco: '',
    numeroCuenta: '',
    pais: 'Venezuela',
    telefono: '',
    tipoCuenta: '',
    titular: '',
  });
  const [pgoMovil, setPgoMovil] = useState({
    banco: '',
    cedula: '',
    idUsuario: UserId,
    fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
    nombreBanco: '',
    numeroCuenta: '',
    pais: 'Venezuela',
    telefono: '',
    titular: '',
  });
  // const [bancosMetPagos, setBancosMetPagos] = useState({
  //   metodosPagos: "",
  // });
  const [editar, setEditar] = useState(false);
  const [editPgoMovil, setEditPgoMovil] = useState(false);
  const [defaultValue, setDefaultValue] = useState({
    value: '',
    label: '',
  });

  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getUsuario(UserId));
    getConfiguracion();
    obtenerCuentasBancarias(UserId);
    obtenerPagosMovil(UserId);
    dispatch(getPagosMoviles());
    dispatch(getPrestamoUser(UserId));
  }, []);

  //=== Mostrar listado de bancos ===//
  let arrayOptions = [];
  arrayOptions = listadoBancos.listado.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState(arrayOptions);

  //=== Mostrar listado de bancos Ecuador ===//
  let arrayOptionsEcu = [];
  arrayOptionsEcu = listadoBancosEcu.bancosEcuador.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionEcu, setOptionEcu] = useState(arrayOptionsEcu);

  //=== Mostrar listado de bancos Colombia ===//
  let arrayOptionsCol = [];
  arrayOptionsCol = listadoBancosCol.bancosColombia.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionCol, setOptionCol] = useState(arrayOptionsCol);

  //=== Mostrar listado de bancos Panama ===//
  let arrayOptionsPan = [];
  arrayOptionsPan = listadoBancosPan.bancosPanama.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionPan, setOptionPan] = useState(arrayOptionsPan);

  // bancosPanama.sort((a, b) => a.nombre.localeCompare(b.nombre));

  // Mostrar métodos de pago
  let arrayMetodos = [];
  arrayMetodos = metodosPagos.metodosPago.map((item) => {
    return { value: item.codigo, label: item.metodo };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionMetodos, setOptionMetodos] = useState(arrayMetodos);
  const [optionTipo, setOptionTipo] = useState([
    { value: 'Ahorro', label: 'Cuenta Ahorro' },
    { value: 'Corriente', label: 'Cuenta Corriente' },
  ]);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
          dispatch({ type: GET_CONFIGURACION, payload: doc.data() });
          calculateInteresTasa(5, 2, doc.data().tasaInteresCompuesto);
        }
      });
  };
  const obtenerCuentasBancarias = (idUsuario) => {
    app
      .firestore()
      .collection('cuentasBancarias')
      .where('idUsuario', '==', `${idUsuario}`)
      .get()
      .then((data) => {
        let array = [];
        let cta = {};
        data.forEach((doc) => {
          cta = doc.data();
          cta.id = doc.id;
          array.push(cta);
        });
        dispatch({ type: GETCUENTASBANCARIAS, payload: array });
        setCuentasBancarias(array);
      });
  };
  const obtenerPagosMovil = (idUsuario) => {
    app
      .firestore()
      .collection('pagosMovil')
      .where('idUsuario', '==', `${idUsuario}`)
      .get()
      .then((data) => {
        let array = [];
        let cta = {};
        data.forEach((doc) => {
          cta = doc.data();
          cta.id = doc.id;
          array.push(cta);
        });
        dispatch({ type: GETPAGOSMOVILES, payload: array });
        setPagoMovil(array);
      });
  };
  const calculateInteresTasa = (cant, dias, tasa) => {
    let totalApagar = (
      cant * Math.pow(1 + parseFloat(tasa) / 100, dias)
    ).toFixed(2);
    setTotalPagar(totalApagar);
  };
  const calculateInteres = (cant, dias) => {
    let totalApagar = (
      cant *
      Math.pow(
        1 +
          parseFloat(
            usuario.habilitadoNuevoPrestamo === 'No'
              ? config.tasaInteresCompuesto
              : usuario.nuevaTasaInteres
          ) /
            100,
        dias
      )
    ).toFixed(2);
    setTotalPagar(totalApagar);
  };

  //========== Metodos préstamo ==========//
  const changeMonto = (e) => {
    const value = e.target.value;
    setCantidad(value);
    let diff = getDirerence(fecha);
    setDiasPagar(diff);
    calculateInteres(value, diff);
    calculateBS();
  };
  const changeFechaInic = (e) => {
    const value = e.target.value;
    setFechaInic(value);
  };
  const changeFecha = (e) => {
    const value = e.target.value;
    setFecha(value);
    let diff = getDirerence(value);
    setDiasPagar(diff);
    calculateInteres(cantidad, diff);
    calculateBS();
  };
  const getDirerence = (fechaValue) => {
    var NowMoment = moment(new Date()).format('YYYY-MM-DD');
    var fecha1 = moment(NowMoment);
    var fecha2 = moment(fechaValue);
    let diff = fecha2.diff(fecha1, 'days');
    armarDays(diff);
    return diff;
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    setDays(array.reverse());
  };
  const calculateBS = () => {
    let cambio = cantidad * tasaDolarHoy;
    setMontoBS(cambio);
  };
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const calculateInteresByDia = (dia) => {
    let totalApagar = (
      cantidad *
      Math.pow(
        1 +
          parseFloat(
            usuario.habilitadoNuevoPrestamo === 'No'
              ? config.tasaInteresCompuesto
              : usuario.nuevaTasaInteres
          ) /
            100,
        dia
      )
    ).toFixed(2);
    return totalApagar;
  };

  //========== Contenido Acordion ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      fontSize: theme.typography.pxToRem(14),
      width: '100%',
    },
  }));
  const classes = useStyles();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //========== Funciones y metodos Cuentas ==========//
  const addCuenta = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: UserId,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Venezuela',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuenta(true));
  };
  const addCuentaEcu = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: UserId,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Ecuador',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuentaEcu(true));
  };
  const addCuentaPan = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: UserId,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Panama',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuentaPan(true));
  };
  const addCuentaCol = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: UserId,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Colombia',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuentaCol(true));
  };
  const addPagoMovil = () => {
    setEditPgoMovil(false);
    setPgoMovil({
      banco: '',
      cedula: '',
      idUsuario: UserId,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Venezuela',
      telefono: '',
      titular: '',
    });
    dispatch(ModalPago(true));
  };

  //========== Funciones Modal Cuentas ==========//
  const changeBanco = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['numeroCuenta']: e.value,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeTipo = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['tipoCuenta']: e.value,
    });
  };
  const changeMetodos = (e) => {
    setDefaultValue(e.label);
    const label = e.label;
    setGenerales({
      ...generales,
      ['metodosPagos']: label,
    });
  };
  const changeBancoEcu = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeBancoCol = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeValueCuentas = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'banco') {
      let index = e.target.selectedIndex;
      setCtabancaria({
        ...ctabancaria,
        [name]: value,
        ['nombreBanco']: e.target.options[index].text,
        // ["numeroCuenta"]: value
      });
    } else {
      setCtabancaria({ ...ctabancaria, [name]: value });
    }
  };
  const close = () => {
    dispatch(modalCuenta(false));
  };
  const cancel = () => {
    dispatch(modalCuenta(false));
  };
  const guardarCuenta = () => {
    if (
      // ctabancaria.numeroCuenta.length !== 20 ||
      ctabancaria.nombreBanco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      }
      // eslint-disable-next-line no-unused-expressions
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const editarCuenta = (item) => {
    setEditar(true);
    setCtabancaria(item);
    setDefaultValue(item.nombreBanco);
    switch (item.pais) {
      case 'Venezuela':
        return dispatch(modalCuenta(true));
      case 'Ecuador':
        return dispatch(modalCuentaEcu(true));
      case 'Panama':
        return dispatch(modalCuentaPan(true));
      default:
        return dispatch(modalCuenta(true));
    }
  };
  const eliminarCuenta = (item) => {
    dispatch(deleteCuentaBancaria(item));
    obtenerCuentasBancarias(UserId);
  };
  const seleccionarCuenta = (item) => {
    if (selectedCuenta) {
      if (selectedCuenta.id === item.id) {
        dispatch(changeSelected(null));
      } else {
        dispatch(changeSelected(item));
      }
    } else {
      dispatch(changeSelected(item));
    }
  };

  //========== Funciones Modal Cuenta Ecuador ==========//
  const guardarCuentaEcu = () => {
    if (
      ctabancaria.banco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      }
    }
    dispatch(modalCuentaEcu(false));
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const closeCtaEcu = () => {
    dispatch(modalCuentaEcu(false));
  };
  const cancelCtaEcu = () => {
    dispatch(modalCuentaEcu(false));
  };

  //========== Funciones Modal Cuenta Colombia ==========//
  const guardarCuentaCol = () => {
    if (
      ctabancaria.banco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      }
    }
    dispatch(modalCuentaCol(false));
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const closeCtaCol = () => {
    dispatch(modalCuentaCol(false));
  };
  const cancelCtaCol = () => {
    dispatch(modalCuentaCol(false));
  };

  //========== Funciones Modal Cuenta Panama ==========//
  const guardarCuentaPan = () => {
    if (
      ctabancaria.banco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
        obtenerCuentasBancarias(UserId);
      }
    }
    dispatch(modalCuentaPan(false));
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const closeCtaPan = () => {
    dispatch(modalCuentaPan(false));
  };
  const cancelCtaPan = () => {
    dispatch(modalCuentaPan(false));
  };

  //===== Pago movil =====//
  const guardarPagoMovil = () => {
    if (
      pgoMovil.banco === '' ||
      pgoMovil.cedula === '' ||
      pgoMovil.telefono === '' ||
      pgoMovil.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editPgoMovil) {
        dispatch(updatePgoMovil(pgoMovil));
        obtenerPagosMovil(UserId);
      } else {
        dispatch(savePagoMovil(pgoMovil));
        obtenerPagosMovil(UserId);
      }
      // eslint-disable-next-line no-unused-expressions
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const editarPgoMovil = (item) => {
    setEditPgoMovil(true);
    setPgoMovil(item);
    dispatch(ModalPago(true));
  };
  const eliminarPgoMovil = (item) => {
    dispatch(deletePgoMovil(item));
    obtenerPagosMovil(UserId);
  };
  const seleccionarPgoMovil = (item) => {
    if (selectedCuenta) {
      if (selectedCuenta.id === item.id) {
        dispatch(changeSelected(null));
      } else {
        dispatch(changeSelected(item));
      }
    } else {
      dispatch(changeSelected(item));
    }
  };
  const changePagoMovil = (e) => {
    setDefaultValue(e);
    setPgoMovil({
      ...pgoMovil,
      ['numeroCuenta']: e.value,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeValuePagoMovil = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'banco') {
      let index = e.target.selectedIndex;
      setPgoMovil({
        ...pgoMovil,
        [name]: value,
        ['nombreBanco']: e.target.options[index].text,
      });
    } else {
      setPgoMovil({ ...pgoMovil, [name]: value });
    }
  };
  const closePagoMovil = () => {
    dispatch(ModalPago(false));
  };
  const cancelPagoMovil = () => {
    dispatch(ModalPago(false));
  };

  //========== Funciones Modal MetodoPago ==========//
  const closeModalMetodoPago = () => {
    dispatch(modalMetodoPago(false));
  };
  const cancelModalMetodoPago = () => {
    dispatch(modalMetodoPago(false));
  };

  const guardarMetodoPago = () => {
    // let label = bancosMetPagos;
    // let dataUpdate = { ...generales, ["metodosPagos"]: label };
    // setGenerales(dataUpdate);
    // dispatch({
    //   type: DATOSGENERELES,
    //   payload: { ...generales, ["metodosPagos"]: label },
    // });
    // console.log("Data", dataUpdate);
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(modalMetodoPago(false));
  };

  const next = () => {
    if (selectedCuenta) {
      let dataEnviar = {
        aprobado: false,
        calificacion: 0,
        captura: '',
        comentarios: '',
        fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
        fechaCompromisoPago: fecha,
        fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
        fechaPago: null,
        fechaSolicitud: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        ganancia: 0,
        gananciaReal: 0,
        gananciaTeorica: 0,
        idGestor: null,
        idUsuario: UserId,
        montoCompromisoPago: parseFloat(totalPagar),
        montoPrestado: parseFloat(cantidad),
        montoRealPrestado: 0,
        montoRealRecibido: 0,
        montoRecibido: 0,
        pagado: false,
        tasaInteresCompuesto: config.tasaInteresCompuesto,
        tipoPagoVenezuela:
          selectedCuenta.numeroCuenta === '' ? 'Pago Movil' : 'Cuenta Bancaria',
        idcuentaVenezuela: selectedCuenta.id,
        estado: 'iniciado',
        indexWizard: 2,
        usuario: usuario,
        datosEnvio: selectedCuenta,
        tab: 'tabpersonales',
        // habilitadoNuevoPrestamo: false,
        pagoEnviado: false,
        gestor: null,
      };
      dispatch(generatePrestamo(dataEnviar));
    } else {
      custom(
        'Debe seleccionar una Cuenta Bancaria o un Pago Movil para continuar',
        'error'
      );
    }
  };

  return (
    <>
      <div className='flex flex-col sm:flex-row items-center p-5 border-b border-gray-200'>
        <h3 className='font-medium text-xl m-auto'>
          Generar préstamo para el usuario
        </h3>
      </div>
      <hr />

      <div className='grid grid-cols-12 gap-6 text-xs text-gray-700 my-2'>
        <div className='col-span-12 sm:col-span-6 xl:col-span-3 intro-y mt-1'>
          <div className='flex zoom-in p-1'>
            <label className='flex font-medium'>
              <Icon.UserCheck className='w-5 h-5 mr-2 text-blue-800' />
            </label>
            <span className=''>
              {usuario.nombres} {usuario.apellidos}
            </span>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 xl:col-span-3 intro-y mt-1'>
          <div className='flex zoom-in p-1'>
            <label className='font-medium'>
              <Icon.Mail className='w-5 h-5 mr-2 text-blue-800' />{' '}
            </label>
            <span className=''>{usuario.email}</span>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-1'>
          <div className='flex zoom-in p-1'>
            <label className='font-medium'>
              <Icon.PhoneCall className='w-5 h-5 mr-2 text-blue-800' />
            </label>
            <span className=''>
              {usuario.codigoPais}
              {' _ '}
              {usuario.whatsapp}
            </span>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-1'>
          <div className='flex xl:justify-center zoom-in p-1'>
            <label className='font-medium'>
              <Icon.MapPin className='w-5 h-5 mr-2 text-blue-800' />
            </label>
            <span className=''>{usuario.pais}</span>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-1'>
          <div className='flex zoom-in p-1'>
            <label className='font-medium'>
              <Icon.SkipForward className='w-5 h-5 mr-2 text-blue-800' />
            </label>
            <span className=''>
              {usuario.habilitadoNuevoPrestamo === 'Si' ? 'Si' : 'No'}
            </span>
          </div>
        </div>
        {prestamoActual && prestamoActual !== '' && (
          <>
            <div className='col-span-12 sm:col-span-6 xl:col-span-6 intro-y -mt-3'>
              <div className='flex zoom-in p-1 justify-center'>
                <label className='flex font-medium text-blue-800'>
                  <Icon.Grid className='w-4 h-4 mr-2 text-blue-800' />
                  ID Usuario :
                </label>
                <span className='ml-1'>{prestamoActual.idUsuario}</span>
              </div>
            </div>
            <div className='col-span-12 sm:col-span-6 xl:col-span-6 intro-y -mt-3'>
              <div className='flex zoom-in p-1 justify-center'>
                <label className='flex font-medium text-blue-800'>
                  <Icon.Hash className='w-4 h-4 mr-2 text-blue-800' />
                  ID Prestamo :
                </label>
                <span className='ml-1'>{prestamoActual.id}</span>
              </div>
            </div>
          </>
        )}
      </div>
      <hr />

      {indexWizard === 1 && (
        // config.fondo > 0 &&
        // (!prestamoActual || !prestamoActual.pagado) && (
        <div className='intro-y col-span-12 lg:col-span-12'>
          <div className='intro-y box'>
            <div className='md:px-24  p-8' id='input'>
              <div className='preview'>
                <form>
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-7'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Monto Solicitado : $
                      </label>
                      <input
                        type='number'
                        name='cantidad'
                        className='w-full rounded-lg border input p-1 mt-3'
                        placeholder='Indique monto'
                        max={
                          usuario.habilitadoNuevoPrestamo === 'No'
                            ? config.montoInicialMaximoPrestamo
                            : usuario.nuevoLimitePrestamo
                        }
                        value={cantidad}
                        onChange={changeMonto}
                        onInput={changeMonto}
                      />
                    </div>
                    <div className='col-span-5'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Tasa de rentabilidad : %
                      </label>
                      <input
                        type='number'
                        name='tasaInteresCompuesto'
                        className='w-full rounded-lg border input p-1 mt-3'
                        placeholder='Porcentaje aplicado'
                        min='0'
                        step='.01'
                        value={config.tasaInteresCompuesto}
                        // onChange={changeTasa}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Fecha inicio :
                      </label>
                      <input
                        type='date'
                        name='fechaInic'
                        className='w-full rounded-lg border input p-1 mt-3'
                        placeholder='Fecha inicial'
                        value={fechaInic}
                        onChange={changeFechaInic}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Fecha compromiso de pago :
                      </label>
                      <input
                        type='date'
                        name='fecha'
                        className='w-full rounded-lg border input p-1 mt-3'
                        placeholder='Fecha fin'
                        min={mindate}
                        value={fecha}
                        onChange={changeFecha}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Monto Total a Cancelar en USD :
                      </label>
                      <input
                        type='number'
                        name='totalPagar'
                        value={totalPagar}
                        disabled={true}
                        className='w-full rounded-lg border input p-1 mt-3'
                      />
                    </div>
                    {days.length > 0 && (
                      <>
                        <div
                          className='col-span-12 sm:col-span-12 flex justify-center items-center'
                          style={{ flexDirection: 'column' }}
                        >
                          <h2 className='font-medium bg-theme-2 box p-2 mb-2'>
                            Distribución de interes
                          </h2>
                          <Slider {...settings} className='w-full'>
                            {days.map((day, i) => {
                              return (
                                <div className='carousel' key={i}>
                                  <div className='box p-2 mb-3 flex items-center zoom-in bg-theme-26'>
                                    <div>
                                      <div className='font-medium'>
                                        {`${formatFecha(fechaInic, day)}`}
                                      </div>
                                      <div className='py-1 px-2 rounded-md text-xs text-white bg-theme-9 cursor-pointer font-medium text-center'>
                                        {`${calculateInteresByDia(day)}$`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                          <button
                            className='button text-white bg-theme-1 mt-3 flex items-center'
                            // onClick={exportar}
                          >
                            <Icon.FileText className='text-white mr-2 w-4' />
                            Exportar
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>

              {/* SELECION DE BANCO Y CUENTAS */}
              <div className='intro-y col-span-12 sm:col-span-12 mt-10 box bg-theme-5'>
                <div className='border-b border-t'>
                  <h2 className='font-medium text-sm mr-auto my-2 ml-3'>
                    <DoneAllIcon color='primary' /> ._ Seleccionar Banco
                  </h2>
                </div>
              </div>

              <h2 className='font-bold text-base text-center my-3 bg-theme-26 box p-2 w-325 mx-auto border-b-2'>
                Datos bancos por país
                {/* <AddToPhotosIcon color="primary" fontSize="small" /> */}
              </h2>
              <div className='grid grid-cols-12 gap-4 row-gap-5 mb-6'>
                <div className='intro-y col-span-12 sm:col-span-6'>
                  <div className={classes.root}>
                    {/*===== Bancos Venezuela =====*/}
                    <Accordion
                      expanded={expanded === 'panel1'}
                      onChange={handleChange('panel1')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color='primary' />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography className={classes.heading}>
                          <label className='font-medium text-sm px-2 py-1 pr-16 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200 max-w-full'>
                            <AccountBalanceIcon
                              color='primary'
                              fontSize='small'
                            />{' '}
                            ._ Bancos Venezuela
                          </label>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='flex flex-col'>
                          {/* TRANSFERENCIA */}
                          <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                            <div className='flex justify-between w-full pr-2'>
                              <h2 className='font-medium text-sm mx-3'>
                                Transferencia Bancaria
                              </h2>
                              <button onClick={addCuenta}>
                                <Icon.Plus
                                  className='text-theme-10 tooltip'
                                  title='Registrar'
                                />
                              </button>
                            </div>
                          </div>

                          {/* PAGO MOVIL */}
                          <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box ml-5'>
                            <div className='flex justify-between w-full pr-2'>
                              <h2 className='font-medium text-sm mx-3'>
                                Pago movil
                              </h2>
                              <button onClick={addPagoMovil}>
                                <Icon.Plus
                                  className='text-theme-10 tooltip'
                                  title='Registrar'
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/*===== Bancos Panama =====*/}
                    <Accordion
                      expanded={expanded === 'panel3'}
                      onChange={handleChange('panel3')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color='primary' />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography className={classes.heading}>
                          <label className='font-medium text-sm px-2 py-1 pr-20 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                            <AccountBalanceIcon
                              color='primary'
                              fontSize='small'
                            />{' '}
                            ._ Bancos Panamá
                          </label>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* TRANSFERENCIA */}
                        <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                          <div className='flex justify-between w-full pr-2'>
                            <h2 className='font-medium text-sm mx-3'>
                              Transferencia Bancaria
                            </h2>
                            <button onClick={addCuentaPan}>
                              <Icon.Plus
                                className='text-theme-10 tooltip'
                                title='Registrar'
                              />
                            </button>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>

                <div className='intro-y col-span-12 sm:col-span-6'>
                  <div className={classes.root}>
                    {/*===== Bancos Colombia =====*/}
                    <Accordion
                      expanded={expanded === 'panel6'}
                      onChange={handleChange('panel6')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color='primary' />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography className={classes.heading}>
                          <label className='font-medium text-sm px-2 py-1 pr-16 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                            <AccountBalanceIcon
                              color='primary'
                              fontSize='small'
                            />{' '}
                            ._ Bancos Colombia
                          </label>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* TRANSFERENCIA */}
                        <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                          <div className='flex justify-between w-full pr-2'>
                            <h2 className='font-medium text-sm mx-3'>
                              Transferencia Bancaria
                            </h2>
                            <button onClick={addCuentaCol}>
                              <Icon.Plus
                                className='text-theme-10 tooltip'
                                title='Registrar'
                              />
                            </button>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/*===== Bancos Ecuador =====*/}
                    <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={handleChange('panel2')}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon color='primary' />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography className={classes.heading}>
                          <label className='font-medium text-sm px-2 py-1 pr-20 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                            <AccountBalanceIcon
                              color='primary'
                              fontSize='small'
                            />{' '}
                            ._ Bancos Ecuador
                          </label>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* TRANSFERENCIA */}
                        <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                          <div className='flex justify-between w-full pr-2'>
                            <h2 className='font-medium text-sm mx-3'>
                              Transferencia Bancaria
                            </h2>
                            <button onClick={addCuentaEcu}>
                              <Icon.Plus
                                className='text-theme-10 tooltip'
                                title='Registrar'
                              />
                            </button>
                          </div>
                          {/* <div className="grid grid-cols-12 gap-4 row-gap-3 mt-2">
                        {cuentas.map((item, i) => (
                          <div
                            className="col-span-12 sm:col-span-6"
                            key={"cuenta" + i}
                          >
                            <CardCuentasBancarias
                              item={item}
                              tipo="cuenta"
                              editar={(e) => editarCuenta(item)}
                              eliminar={(e) => eliminarCuenta(item)}
                              seleccionar={(e) => seleccionarCuenta(item)}
                            />
                          </div>
                        ))}
                      </div> */}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>

              <div className='intro-y col-span-12 sm:col-span-12 mt-10 box bg-theme-5'>
                <div className='border-b border-t'>
                  <h2 className='font-medium text-sm mr-auto my-2 ml-3'>
                    <DoneAllIcon color='primary' /> ._ Seleccionar la cuenta
                    donde recibir el préstamo
                  </h2>
                </div>
              </div>

              {/* Tarjeta Cuentas y Pago Movil Registrada */}
              <div
                ref={ref}
                className='grid grid-cols-12 gap-4 row-gap-3 my-10'
              >
                {cuentasBancarias.map((item, i) => (
                  <div
                    id='cuentas'
                    className='col-span-12 sm:col-span-3'
                    key={'cuenta' + i}
                  >
                    <CardCuentasBancarias
                      item={item}
                      tipo='cuenta'
                      editar={(e) => editarCuenta(item)}
                      eliminar={(e) => eliminarCuenta(item)}
                      seleccionar={(e) => seleccionarCuenta(item)}
                    />
                  </div>
                ))}
                {pagoMovil.map((item, i) => (
                  <div
                    className='col-span-12 sm:col-span-3 mx-3'
                    key={'pago' + i}
                  >
                    <CardCuentasBancarias
                      item={item}
                      tipo='pago movil'
                      editar={(e) => editarPgoMovil(item)}
                      eliminar={(e) => eliminarPgoMovil(item)}
                      seleccionar={(e) => seleccionarPgoMovil(item)}
                    />
                  </div>
                ))}
              </div>
              {selectedCuenta && (
                <div className='grid grid-cols-12 gap-4 row-gap-3 mb-6 mt-2'>
                  <div className='intro-y col-span-12 sm:col-span-12'>
                    <div className='flex justify-center items-center flex-col'>
                      <h2 className='font-medium text-base mb-2 bg-theme-26 box p-2'>
                        Datos Para
                        {selectedCuenta.numeroCuenta === ''
                          ? ' _ el Pago Móvil'
                          : ' _ la Transferencia Bancaria'}
                      </h2>
                      <p className='text-base'>
                        {selectedCuenta.numeroCuenta === ''
                          ? 'Banco: '
                          : 'N° de Cuenta: '}
                        {selectedCuenta.numeroCuenta === ''
                          ? selectedCuenta.banco
                          : selectedCuenta.numeroCuenta}
                      </p>
                      <p className='text-base'>
                        Cédula: {selectedCuenta.cedula}
                      </p>
                      <p className='text-base'>
                        Titular: {selectedCuenta.titular}
                      </p>
                      <p className='text-base'>
                        Tipo: {selectedCuenta.tipoCuenta}
                      </p>
                      <p className='text-base'>País: {selectedCuenta.pais}</p>
                    </div>
                  </div>
                  {/* {generales.metodosPagos === 'Venezuela-Transferencia' && (
                  <div className='intro-y col-span-12 sm:col-span-12 mt-2'>
                    <div className='flex justify-center items-center flex-col'>
                      <h2 className='font-medium text-yellow-600 text-base mb-2 box bg-yellow-200 p-1'>
                        Ahora seleccione en donde usted desea devolver su
                        préstamo.
                      </h2>
                    </div>
                  </div>
                )} */}
                </div>
              )}
            </div>
          </div>

          <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
            <div className='intro-y col-span-12 flex items-center justify-center sm:justify-end'>
              {/* {indexWizard !== 1 && (
                <button
                  className='button w-24 justify-center block bg-gray-200 text-gray-600'
                  onClick={back}
                >
                  Atras
                </button>
              )} */}

              <button
                className='button w-24 justify-center block bg-theme-1 text-white ml-2'
                onClick={next}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      )}

      {indexWizard === 2 && (
        <div className='px-5 sm:px-4 md:px-8 pt-8 border-t border-gray-200'>
          <DatosUsuario />
        </div>
      )}

      {indexWizard === 3 && (
        <div className='px-5 sm:px-4 md:px-8 pt-8 border-t border-gray-200'>
          <CargaRequerimientos prestamo={prestamoActual} />
        </div>
      )}

      {/* {prestamoActual && prestamoActual.indexWizard !== 5 && (
        <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
          <div className='intro-y col-span-12 flex items-center justify-center sm:justify-end'>
            {indexWizard !== 1 && (
              <button
                className='button w-24 justify-center block bg-gray-200 text-gray-600'
                onClick={back}
              >
                Atras
              </button>
            )}

            <button
              className='button w-24 justify-center block bg-theme-1 text-white ml-2'
              onClick={next}
            >
              Siguiente
            </button>
          </div>
        </div> */}
      {/* )} */}

      {/* Modal Venezuela */}
      {modalCuentaBanacaria && (
        <Modal
          title={editar ? 'Editar Cuenta' : 'Registrar Cuenta'}
          close={close}
          save={guardarCuenta}
          cancel={cancel}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={option}
              onChange={changeBanco}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              maxLength='20'
              value={ctabancaria.numeroCuenta}
              placeholder='00000000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              maxLength='8'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {modalPagoMovil && (
        <Modal
          title='Registrar Pago Movil'
          close={closePagoMovil}
          save={guardarPagoMovil}
          cancel={cancelPagoMovil}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='banco'
              autoFocus={true}
              placeholder={
                editar ? pgoMovil.nombreBanco : 'Seleccione el banco'
              }
              options={option}
              onChange={changePagoMovil}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              Teléfono :
            </label>
            <input
              type='text'
              name='telefono'
              maxLength='12'
              value={pgoMovil.telefono}
              placeholder=' 0000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValuePagoMovil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              maxLength='8'
              value={pgoMovil.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValuePagoMovil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={pgoMovil.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValuePagoMovil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </Modal>
      )}
      {modalCuentaBcoEcu && (
        <Modal
          title={editar ? 'Editar Cuenta Ecuador' : 'Registrar Cuenta Ecuador'}
          close={closeCtaEcu}
          save={guardarCuentaEcu}
          cancel={cancelCtaEcu}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={optionEcu}
              onChange={changeBancoEcu}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              value={ctabancaria.numeroCuenta}
              placeholder='0000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {modalCuentaBcoCol && (
        <Modal
          title={
            editar ? 'Editar Cuenta Colombia' : 'Registrar Cuenta Colombia'
          }
          close={closeCtaCol}
          save={guardarCuentaCol}
          cancel={cancelCtaCol}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={optionCol}
              onChange={changeBancoCol}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              value={ctabancaria.numeroCuenta}
              placeholder='0000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {modalCuentaBcoPan && (
        <Modal
          title={editar ? 'Editar Cuenta Panama' : 'Registrar Cuenta Panama'}
          close={closeCtaPan}
          save={guardarCuentaPan}
          cancel={cancelCtaPan}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={optionPan}
              onChange={changeBancoEcu}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              value={ctabancaria.numeroCuenta}
              placeholder='0000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {/*===== Modal pagos =====*/}
      {modalMetodosPagos && (
        <Modal
          title={'Seleccionar su método de pago'}
          close={closeModalMetodoPago}
          save={guardarMetodoPago}
          cancel={cancelModalMetodoPago}
        >
          <div className='intro-y col-span-12 sm:col-span-12 w-full'>
            <label className='font-medium bg-theme-26 box p-1 mb-2'>
              Métodos disponibles :
            </label>
            <Select
              className='mt-2'
              name='metodosPagos'
              placeholder='Haz clic aquí para seleccionar donde quieres devolver tu préstamo'
              autoFocus={true}
              options={optionMetodos}
              onChange={changeMetodos}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default GenerarPrestamo;
