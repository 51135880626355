import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { app } from '../../../config/firebaseConfig';
import moment from 'moment';
import * as Icon from 'react-feather';
import Modal from '../../Modal';

import { custom } from '../../../helpers/alertas';
import url from '../../../config/url';
import { axiosInstance } from '../../../config/api';
import Slider from 'react-slick';

import { misAbonos, sendMsg, getChat } from '../../../redux/actions/userAction';
import {
  MODALABONO,
  LOADING,
  ENDLOADING,
  GETABONOS,
  PRESTAMOACTUAL,
} from '../../../redux/types';
import {
  diferenciaFechas,
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
  calCulardiasTranscurridosCompleto,
} from '../../../helpers/generales';
import { addLog } from '../../../redux/actions/sesionAction';
import { aprobarPrestamo } from '../../../redux/actions/gestorActions';

const hoy = moment(new Date()).format('DD/MM/YYYY');
const hoyBien = moment(new Date()).format('YYYY-MM-DD');

const Abonos = () => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const new_date = moment(new Date());
  const minDateProximo = new_date.add(1, 'days').format('YYYY-MM-DD');

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const misabonos = useSelector((state) => state.user.misabonos);
  const prestamoActual = useSelector((state) => state.user.prestamoActual);
  const modalAbono = useSelector((state) => state.user.modalAbono);
  const configuracionGlobal = useSelector(
    (state) => state.user.configuracionGlobal
  );
  const chat = useSelector((state) => state.gestor.chat);

  //========== USE-STATE ==========//
  const [proximoCompromisoPago, setProximoCompromisoPago] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [showDealle, setShowDetalle] = useState(false);
  const [pago, setPagos] = useState({
    idTransaccion: '',
    nombres: '',
    apellidos: '',
    via: 'transferencias',
    monto: '',
    concepto: '',
    fecha: moment(new Date()).format('YYYY-MM-DD'),
    voucher: '',
    confirmado: false,
  });
  const [abono, setAbono] = useState(null);
  const [montoPagar, setMontoPagar] = useState('todo');
  const [disabled, setDisabled] = useState(true);
  const [quedoDebiendo, setQuedoDebiendo] = useState(false);
  const [modalDatosPago, setModalDatosPago] = useState(false);
  const [cuentasMostrar, setCuentasMostrar] = useState([]);
  const [fileName, setFilename] = useState('');
  const [refFile, setRefFile] = useState(null);
  const [administrativos, setAdministrativos] = useState([]);

  useEffect(() => {
    dispatch(misAbonos(prestamoActual.id));
    listenAbonos();
    listenPrestamo();
    listenPagos();
    userAdministrativos();
  }, []);

  const listenPrestamo = () => {
    app
      .firestore()
      .collection('prestamos')
      .doc(prestamoActual.id)
      .onSnapshot((doc) => {
        let obj = doc.data();
        obj.id = doc.id;
        dispatch({ type: PRESTAMOACTUAL, payload: obj });
        dispatch(getChat(prestamoActual.id));
      });
  };
  const listenAbonos = () => {
    let query = app.firestore().collection('abonos');
    query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          dispatch(misAbonos(prestamoActual.id));
          dispatch(getChat(prestamoActual.id));
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };
  const listenPagos = () => {
    let query = app.firestore().collection('pagos');
    query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          dispatch(getChat(prestamoActual.id));
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };
  const HandleChange = (e) => {
    const { name, value } = e.target;
    setPagos({ ...pago, [name]: value });
  };
  const abonar = () => {
    setPagos({
      ...pago,
      ['nombres']: prestamoActual.usuario.nombres,
      ['apellidos']: prestamoActual.usuario.apellidos,
      ['monto']:
        misabonos.length === 0 ? totalRestante() : totalRestanteAbonos(),
    });
    dispatch({ type: MODALABONO, payload: true });
    dispatch(
      addLog({
        accion: `${'El Usuario: '} ${
          prestamoActual.usuario.email
        } ${','} ${'ha Reportado un Abono de Su Prestamo'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const cancelAbono = () => {
    if (refFile) {
      refFile
        .delete()
        .then(() => {
          setRefFile(null);
          setFilename('');
        })
        .catch((err) => console.log(err));
    }
    dispatch({ type: MODALABONO, payload: false });
  };
  const guardarAbono = () => {
    if (
      pago.nombres === '' ||
      pago.apellidos === '' ||
      pago.via === '' ||
      pago.monto === '' ||
      pago.concepto === '' ||
      pago.fecha === '' ||
      pago.idTransaccion === '' ||
      pago.voucher === ''
    ) {
      custom('Debe llenar todos los datos', 'error');
    } else {
      if (misabonos.length === 0) {
        //si no tiene abonos
        if (parseFloat(pago.monto) > parseFloat(totalRestante())) {
          //verifica si se ingresa un monto mayor a la deuda sin abonos
          custom('El monto ingresado es mayor a la deuda total.', 'warning');
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoActual.id,
            idUsuario: prestamoActual.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamoActual);
        } else {
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoActual.id,
            idUsuario: prestamoActual.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamoActual);
        }
      } else {
        // si tiene abonos
        if (parseFloat(pago.monto) > parseFloat(totalRestanteAbonos())) {
          //verifica si se ingresa un monto mayor a la deuda con abonos
          custom('El monto ingresado es mayor a la deuda total.', 'warning');
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoActual.id,
            idUsuario: prestamoActual.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamoActual);
        } else {
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: prestamoActual.id,
            idUsuario: prestamoActual.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamoActual);
        }
      }
      dispatch(
        aprobarPrestamo(prestamoActual.id, {
          estado: 'Abonado',
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
        })
      );
    }
  };
  const agregarAbono = async (pagoAbono, prestamo) => {
    let mensajesPorEnviar = [];
    try {
      await app.firestore().collection('abonos').add(pagoAbono);
      const abonos = await app
        .firestore()
        .collection('abonos')
        .where('idPrestamo', '==', `${pagoAbono.idPrestamo}`)
        .orderBy('fecha', 'asc')
        .get();
      let array = [];
      let objeto = {};
      abonos.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETABONOS, payload: array });
      const debe = calcularInteresesPorAbonos(array)['interesHoy'];
      let fechaDebePagar = moment(prestamoActual.fechaCompromisoPago).format(
        'DD/MM/YYYY'
      );
      if (hoy >= fechaDebePagar) {
        if (debe > 0) {
          setQuedoDebiendo(true);
        }
      } else {
        console.log('todo bien');
      }
      let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
      const data = {
        asunto: 'Notificacion Reporte de Pago ó Abono',
        email: administrativos.map((admin) => admin.email),
        motivo: `
          <p><b>Se Ha reportado un Pago/Abono.</b></p> 
          <p>Usuario: <b>${usuario}</b></p> 
          <p>Correo: <b>${prestamo.usuario.email}</b></p>
          <p>Pais: <b>${prestamo.usuario.pais}</b></p> 
          <p>Prestamo: <b>${prestamo.id}</b></p> 
          <p>Monto Solicitado: <b>${prestamo.montoPrestado}</b></p>
          <p>Fecha de Pago: <b>${pagoAbono.fecha}</b></p> 
          <p>Monto Pagado: <b>${pagoAbono.monto}</b></p>
          <p>Número Comprobante: <b>${pagoAbono.idTransaccion}</b></p>
          <p>Voucher Comprobante: <b>${pagoAbono.voucher}</b></p>
          <p>Deuda restante: <b>${debe}</b></p> 
        `,
      };
      enviarNotificacion(data);
      mensajesPorEnviar.push({
        texto: `El usuario, ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}, reporta un abono de ${pagoAbono.monto} $ con el número de comprobante ${pagoAbono.idTransaccion}.
        Por favor aguarde la confirmación de la recepción del pago para la actualización del estado de su deuda. Manténgase alerta en su correo electrónico o whatsapp.`,
        rol: 'usuario',
      });
      mensajesPorEnviar.push({ texto: pagoAbono.voucher, rol: 'Usuario' });
      if (debe === 0) {
        mensajesPorEnviar.push({
          texto: `GRACIAS por pagar la totalidad de tu deuda, desde Chamopresta.com estamos muy contentos por haber logrado cerrar este préstamo con éxito, gracias ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}.`,
          rol: 'Sistema',
        });
      }
      enviarVariosMensajes(mensajesPorEnviar.reverse());
      dispatch({ type: MODALABONO, payload: false });
    } catch (err) {
      custom('Error al intentar agregar el Abono', 'error');
    }
  };
  const changeMontoType = (e) => {
    let value = e.target.value;
    setMontoPagar(value);
    if (value === 'todo') {
      setDisabled(true);
      setPagos({
        ...pago,
        ['monto']:
          misabonos.length === 0 ? totalRestante() : totalRestanteAbonos(),
      });
    } else {
      setPagos({
        ...pago,
        ['monto']: '',
      });
      setDisabled(false);
    }
  };

  const verDetalle = (item) => {
    setAbono(item);
    setShowDetalle(true);
  };
  const loadFile = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    upload(file);
  };
  const openExplorer = (e, item) => {
    setAbono(item);
    fileRef.current.click();
  };
  const upload = (file) => {
    setFilename(file.name);
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app
        .storage()
        .ref(`abonos/${prestamoActual.id}-${misabonos.length + 1}`);
      setRefFile(storageRef);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          // let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          app
            .storage()
            .ref(`abonos/${prestamoActual.id}-${misabonos.length + 1}`)
            .getDownloadURL()
            .then(async (url) => {
              setPagos({ ...pago, ['voucher']: url });
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };

  //========== Funciones de Envio Correo y Mensaje ==========//
  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Bloqueado'])
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
        setTimeout(() => {
          // console.log(prestamoActual)
        }, 1000);
      });
  };
  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };
  const enviarMesaje = (mensaje, rol) => {
    let mensajesArray = [];
    mensajesArray = [...chat.mensajes];
    mensajesArray.push({
      fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      rol,
      texto: mensaje,
    });
    dispatch(sendMsg(chat, mensajesArray));
  };
  const enviarVariosMensajes = (mensajes, rol) => {
    let mensajesViejos = [];
    let mensajesNuevos = [];
    mensajesViejos = [...chat.mensajes];
    mensajes.forEach((items) => {
      mensajesNuevos.push({
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        rol: items.rol,
        texto: items.texto,
      });
    });
    let mensajesTodos = mensajesViejos.concat(mensajesNuevos);
    dispatch(sendMsg(chat, mensajesTodos));
  };

  const solicitarDatosAdmin = () => {
    let usuario = `${prestamoActual.usuario.nombres} ${prestamoActual.usuario.apellidos}`;
    let para = 'info@chamopresta.com';
    const data = {
      asunto: 'Solicitud de Datos Bancarios',
      email: para,
      motivo: `
      <h2>Solicitud de datos bancarios PAIS : ${prestamoActual.usuario.pais}</h2>
        <p>Estimado/a, Administrador...</p>
        <p>Se le informa que el USUARIO <b>${usuario}</b> esta haciendo la solicitud de los datos <b>Bancarios para Realizar su Pago</b>.</p>
        <p><b>Los datos del usuario para la respuesta son:
        Nombre: ${prestamoActual.usuario.nombres}
        Correo: ${prestamoActual.usuario.email}
        </b></p>`,
    };
    enviarNotificacion(data);
    enviarMesaje(
      `<span><p>El usuario ${usuario} esta haciendo la solicitud de los datos Bancarios para Realizar su pago o abono. Los datos para la transferencia o deposito en taquilla son:</p><br/>
        <p>Nombre: ${prestamoActual.usuario.nombres}</p>
        <p>Correo: ${prestamoActual.usuario.email}</p>
        <p>Pais: ${prestamoActual.usuario.pais}</p></span>`,
      'Usuario'
    );
    // success("Solicitud de Datos realizada exitosamente.", "success");
    dispatch(
      addLog({
        accion: `${'El Usuarios: '} ${
          prestamoActual.usuario.email
        } ${','} ${'ha Solicitados los Datos Para el Pago'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const solicitarDatos = () => {
    const pais = myUser.pais;
    app
      .firestore()
      .collection('cuentasInternacionales')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => {
          array.push(doc.data());
        });
        if (array.length > 0) {
          const filter = array.filter(
            (el) => el.pais.toLowerCase().indexOf(pais.toLowerCase()) !== -1
          );
          if (filter.length === 0) {
            solicitarDatosAdmin();
          }
          setCuentasMostrar(filter);
        } else {
          solicitarDatosAdmin();
        }
        setModalDatosPago(true);
      });
  };

  /*===== Metodos para calculo de Abonos e intereses =====*/
  let diasTranscurridos = diferenciaFechas(prestamoActual.fechaInicio);
  let diaActual = () => {
    if (configuracionGlobal) {
      let dias = configuracionGlobal.diasMinimoPago;
      return dias;
    }
  };
  const totalAbonado = () => {
    let total = 0;
    misabonos.forEach((fila) => {
      total += fila.monto;
    });
    return total;
  };
  const fechaUltimoAbono = () => {
    let fecha = '';
    misabonos.forEach((fila) => {
      fecha = fila.fecha;
    });
    return fecha;
  };
  const totalRestante = () => {
    let total = 0;
    misabonos.forEach((fila) => {
      total += fila.monto;
    });
    if (misabonos.length > 0) {
      return (montoHoy() - total).toFixed(2);
    }
    return montoHoy().toFixed(2);
  };
  const totalRestanteAbonos = () => {
    let total = 0;
    //total = (calcularInteresesPorAbonos()['interesHoy'] - totalAbonadoAbonos());
    total = calcularInteresesPorAbonos()['interesHoy'];
    return total;
  };
  const montoHoy = () => {
    if (prestamoActual) {
      let dias = calCulardiasTranscurridos(prestamoActual.fechaInicio);
      let monto = calculateInteres(
        prestamoActual.montoPrestado,
        dias,
        parseFloat(
          myUser.habilitadoNuevoPrestamo === 'No'
            ? prestamoActual.tasaInteresCompuesto
            : myUser.nuevaTasaInteres
        )
      );
      return parseFloat(monto);
    } else {
      return 0;
    }
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    return array.reverse();
  };
  const interesDiarioTabla = (deuda, dia, tasaInteresCompuesto) => {
    let totalApagar = (
      deuda * Math.pow(1 + parseFloat(tasaInteresCompuesto) / 100, dia)
    ).toFixed(2);
    return totalApagar;
  };
  const interesDiarioTablaAbono = (deuda, dia, tasaInteresCompuesto) => {
    let totalApagar = (
      deuda * Math.pow(1 + parseFloat(tasaInteresCompuesto) / 100, dia)
    ).toFixed(2);
    return totalApagar;
  };
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const bgDeudaActual = (value) => {
    if (calcularInteresesPorAbonos()['interesHoy'] === 0) {
      return 'font-medium bg-theme-18 box p-1';
    } else {
      return 'font-medium bg-theme-8 box p-1';
    }
  };
  const calcularInteresesPorAbonos = (abonosRecibido) => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    let arrayRecorrer = [];
    if (abonosRecibido) {
      arrayRecorrer = abonosRecibido;
    } else {
      arrayRecorrer = misabonos;
    }
    arrayRecorrer.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamoActual.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamoActual.montoPrestado,
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamoActual.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamoActual.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamoActual.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamoActual.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };
  const resaltar = (fecha) => {
    if (fecha === hoy) {
      return 'resaltar';
    } else {
      return '';
    }
  };
  const fechaActualPrestamo = () => {
    if (hoyBien > prestamoActual.fechaCompromisoPago) {
      return hoyBien;
    } else {
      return prestamoActual.fechaCompromisoPago;
    }
  };
  const changeProximaFechaPago = (e) => {
    const value = e.target.value;
    setProximoCompromisoPago(value);
  };
  const generarSubprestamo = () => {
    const data = {
      fechaInicioDespues: hoyBien,
      fechaCompromisoPagoDespues: proximoCompromisoPago,
      montoPrestadoDespues: calcularInteresesPorAbonos()['interesHoy'],
    };
    if (proximoCompromisoPago <= hoyBien) {
      custom('Debe seleccionar una fecha mayor al dia de hoy', 'error');
    } else {
      app
        .firestore()
        .collection('prestamos')
        .doc(`${prestamoActual.id}`)
        .update(data)
        .then((resp) => {
          setQuedoDebiendo(false);
          custom('Datos actualizados exitosamente', 'success');
          const updatePrestamo = Object.assign(prestamoActual, data);

          let usuario = `${prestamoActual.usuario.nombres} ${prestamoActual.usuario.apellidos}`;
          let para = ['info@chamopresta.com', prestamoActual.gestor.email];
          const dataCorreo = {
            asunto: 'Notificación Deuda Postergada',
            email: para,
            motivo: `<p><b>El Usuario : ${usuario},</b> ha postergado la deuda de su prestamo...</p>
            <p>los nuevos valores establecidos por el usuario son :</p>
            <p>Deuda restante postergada, <b>Monto : ${prestamoActual.montoPrestadoDespues}, Su nueva fecha de inicio es : ${prestamoActual.fechaInicioDespues}, y la nueva fecha de compromiso de pago es : ${prestamoActual.fechaCompromisoPagoDespues}</b></p>`,
          };
          enviarNotificacion(dataCorreo);
          dispatch({ type: PRESTAMOACTUAL, payload: updatePrestamo });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const removeRecibo = () => {
    setFilename('');
    setPagos({ ...pago, ['voucher']: '' });
    refFile.delete().then(() => {
      setRefFile(null);
    });
  };

  return (
    <>
      {prestamoActual && (
        <div className='grid grid-cols-12 gap-4 row-gap-5 mb-4 w-full'>
          <div className='col-span-12 sm:col-span-12 px-4 sm:px-8 pb-8'>
            {prestamoActual.pagoEnviado === false && (
              <>
                <div className='flex flex-col mb-4'>
                  <h1 className='text-lg text-theme-1 leading-none mt-2'>
                    Su préstamo aún no se ha depositado en los datos indicados,
                    se le notificará cuando se realice el pago, si los datos son
                    incorrectos, puede comunicarse por medio del chat.
                  </h1>
                </div>
              </>
            )}
            {configuracionGlobal.diasMinimoPago &&
              diasTranscurridos < diaActual() &&
              prestamoActual.pagoEnviado === true && (
                <div className='flex flex-col mb-4'>
                  <h1 className='text-lg text-theme-1 leading-none mt-2'>
                    Ya se ha realizado el envío de dinero a los datos
                    registrados, en el chat podrá ver el comprobante.
                  </h1>
                </div>
              )}
            {configuracionGlobal.diasMinimoPago &&
              diasTranscurridos < diaActual() && (
                <div>
                  <h1 className='text-lg leading-none text-theme-9 mb-4'>
                    Se requiere de mínimo{' '}
                    <span>{configuracionGlobal.diasMinimoPago}</span> días para
                    realizar abonos sobre su deuda, es decir podrá abonarnos o
                    pagar la totalidad de su deuda desde el{' '}
                    {formatFecha(
                      moment(prestamoActual.fechaInicio).format('YYYY-MM-DD'),
                      parseInt(configuracionGlobal.diasMinimoPago, 10)
                    )}
                  </h1>
                </div>
              )}
            {prestamoActual.pagoEnviado === true &&
              diasTranscurridos >= diaActual() &&
              prestamoActual.estado !== 'finalizado' && (
                <>
                  <div className='grid grid-cols-12 mt-4 gap-4 row-gap-5 mb-4'>
                    <div className='col-span-12 sm:col-span-6'>
                      <button
                        className='button w-full bg-theme-3 text-white'
                        onClick={() => solicitarDatos()}
                      >
                        Solicitar Datos Para el Pago
                      </button>
                    </div>
                    <div className='col-span-12 sm:col-span-6'>
                      <button
                        className='button w-full bg-theme-3 text-white'
                        onClick={abonar}
                      >
                        Reportar Pago
                      </button>
                    </div>
                  </div>
                </>
              )}
            <div className='intro-y col-span-12 overflow-auto w-full '>
              {misabonos.length > 0 &&
                prestamoActual.estado !== 'finalizado' && (
                  <table className='table table-report mt-2'>
                    <thead className='bg-theme-26'>
                      <tr>
                        <th className='whitespace-no-wrap'>Titular</th>
                        <th className='whitespace-no-wrap'>Monto</th>
                        <th className='whitespace-no-wrap'>Fecha</th>
                        <th className='whitespace-no-wrap'>Concepto</th>
                        <th className='whitespace-no-wrap'>Confirmado</th>
                        <th className='whitespace-no-wrap'>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {misabonos.map((abono, i) => (
                        <tr key={i}>
                          <td className='whitespace-no-wrap'>
                            {abono.nombres} {abono.apellidos}
                          </td>
                          <td className='whitespace-no-wrap'>{abono.monto}</td>
                          <td className='whitespace-no-wrap'>
                            {moment(abono.fecha).format('YYYY-MM-DD')}
                          </td>
                          <td className='whitespace-no-wrap'>
                            {abono.concepto}
                          </td>
                          <td className='whitespace-no-wrap'>
                            <div className='flex justify-center'>
                              <span className='font-medium bg-theme-2 box p-1'>
                                {abono.confirmado === true ? 'Si' : 'No'}
                              </span>
                            </div>
                          </td>
                          <td className='whitespace-no-wrap'>
                            <a
                              className='font-medium text-theme-12 flex'
                              href='#!'
                              onClick={(e) => {
                                e.preventDefault();
                                verDetalle(abono);
                              }}
                            >
                              <Icon.Search className='w-4 h-4 ml-1 mr-1' />
                              Ver Abono
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
            </div>

            <div className='intro-y col-span-12 w-full'>
              <table className='table table-report mt-2'>
                {prestamoActual && misabonos.length === 0 && (
                  <tfoot className='bg-theme-26'>
                    <tr>
                      <th colSpan='6' className='th-p'>
                        <div className='col-span-12 flex sm:flex-col md:flex-row justify-between flex-wrap'>
                          <div>
                            <label className='font-medium bg-theme-27 box p-1'>
                              Monto:
                            </label>
                            <label className='font-medium p-1 mr-1'>
                              {prestamoActual.montoPrestado} $
                            </label>
                          </div>
                          <div>
                            <label className='font-medium bg-theme-23 box p-1'>
                              Monto a Pagar:
                            </label>
                            <label className='font-medium p-1 mr-1'>
                              {prestamoActual.montoCompromisoPago} $
                            </label>
                          </div>
                          <div>
                            <label className='font-medium bg-theme-25 box p-1'>
                              Fecha Pago:
                            </label>
                            <label className='font-medium p-1 mr-1'>
                              {prestamoActual.fechaCompromisoPago}
                            </label>
                          </div>
                          <div>
                            <label className={bgDeudaActual()}>
                              Deuda Actual:
                            </label>
                            <label className='font-medium p-1 mr-1'>
                              {montoHoy().toFixed(2)} $
                            </label>
                          </div>
                          <div>
                            <label className='font-medium bg-theme-9 box p-1'>
                              Abonado:
                            </label>
                            <label className='font-medium p-1 mr-1'>
                              {totalAbonado()} $
                            </label>
                          </div>
                          <div>
                            <label className='font-medium bg-theme-36 box p-1'>
                              Resta:
                            </label>
                            <label className='font-medium p-1 mr-1'>
                              {totalRestante()} $
                            </label>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan='6' className='th-p'>
                        <h5 className='font-medium bg-theme-8 box p-1 w-56 text-center'>
                          Visualización de deuda diaria
                        </h5>
                        {calCulardiasTranscurridosCompleto(
                          prestamoActual.fechaInicio,
                          fechaActualPrestamo()
                        ) > 0 && (
                          <div
                            className='col-span-12 sm:col-span-12 flex justify-center items-center'
                            style={{ flexDirection: 'column' }}
                          >
                            <h2 className='mb-2'>
                              Así estará distribuido el interés por día
                            </h2>
                            <div className='carousel'>
                              {/*<Slider {...settings} className="w-full"> */}
                              {armarDays(
                                calCulardiasTranscurridosCompleto(
                                  prestamoActual.fechaInicio,
                                  fechaActualPrestamo()
                                )
                              ).map((day, i) => {
                                return (
                                  <div
                                    key={i}
                                    className={[
                                      'box',
                                      'px-2',
                                      'py-2',
                                      'mb-3',
                                      'zoom-in',
                                      'bg-theme-26',
                                      resaltar(
                                        formatFecha(
                                          moment(
                                            prestamoActual.fechaInicio
                                          ).format('YYYY-MM-DD'),
                                          day
                                        )
                                      ),
                                    ].join(' ')}
                                  >
                                    <div>
                                      <div className='font-medium'>
                                        {`${formatFecha(
                                          moment(
                                            prestamoActual.fechaInicio
                                          ).format('YYYY-MM-DD'),
                                          day
                                        )}:`}
                                      </div>
                                      <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                                        {`${interesDiarioTabla(
                                          prestamoActual.montoPrestado,
                                          day,
                                          parseFloat(
                                            myUser.habilitadoNuevoPrestamo ===
                                              'No'
                                              ? prestamoActual.tasaInteresCompuesto
                                              : myUser.nuevaTasaInteres
                                          )
                                        )}$`}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              {/* </Slider> */}
                            </div>
                          </div>
                        )}
                      </th>
                    </tr>
                  </tfoot>
                )}
                {prestamoActual &&
                  misabonos.length > 0 &&
                  !prestamoActual.fechaInicioDespues &&
                  prestamoActual.estado !== 'finalizado' && (
                    <tfoot className='bg-theme-26'>
                      <tr>
                        <th colSpan='6' className='th-p'>
                          <div className='col-span-12 flex flex-col md:flex-row justify-between'>
                            <div>
                              <label className='font-medium bg-theme-27 box p-1'>
                                Monto:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.montoPrestado}$
                              </label>
                            </div>
                            <div>
                              <label className='font-medium bg-theme-23 box p-1'>
                                Monto a Pagar:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.montoCompromisoPago}$
                              </label>
                            </div>
                            <div>
                              <label className='font-medium bg-theme-25 box p-1'>
                                Fecha Pago:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.fechaCompromisoPago}
                              </label>
                            </div>
                            <div>
                              <label className={bgDeudaActual()}>
                                Deuda Actual:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {calcularInteresesPorAbonos()['interesHoy']}$
                              </label>
                            </div>
                            <div>
                              <label className='font-medium bg-theme-8 box p-1'>
                                Ultimo Abono:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {moment(fechaUltimoAbono()).format(
                                  'YYYY-MM-DD'
                                )}
                              </label>
                            </div>
                          </div>
                        </th>
                      </tr>

                      {calCulardiasTranscurridos(fechaUltimoAbono()) > 0 && (
                        <tr>
                          <th colSpan='6' className='th-p'>
                            <div
                              className='col-span-12 sm:col-span-12 flex justify-center items-center flex-wrap'
                              style={{ flexDirection: 'column' }}
                            >
                              <h2 className='mb-2'>
                                Así estará distribuido el interes por día
                                despues de su ultimo abono.
                              </h2>
                              <div className='carousel'>
                                {armarDays(
                                  calCulardiasTranscurridosCompleto(
                                    fechaUltimoAbono(),
                                    prestamoActual.fechaCompromiso
                                  )
                                ).map((day, i) => {
                                  return (
                                    <div
                                      className='box px-2 py-2 mb-3 flex items-center zoom-in bg-theme-26'
                                      key={i}
                                    >
                                      <div>
                                        <div className='font-medium'>
                                          {`${formatFecha(
                                            moment(fechaUltimoAbono()).format(
                                              'YYYY-MM-DD'
                                            ),
                                            day
                                          )}:`}
                                        </div>
                                        <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                                          {`${interesDiarioTablaAbono(
                                            calcularInteresesPorAbonos()[
                                              'deuda'
                                            ],
                                            day,
                                            parseFloat(
                                              myUser.habilitadoNuevoPrestamo ===
                                                'No'
                                                ? prestamoActual.tasaInteresCompuesto
                                                : myUser.nuevaTasaInteres
                                            )
                                          )}$`}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </th>
                        </tr>
                      )}
                    </tfoot>
                  )}
                {prestamoActual &&
                  misabonos.length > 0 &&
                  prestamoActual.fechaInicioDespues && (
                    <tfoot className='bg-theme-26'>
                      <tr>
                        <th colSpan='6' className='th-p'>
                          <div className='col-span-12 flex flex-col md:flex-row justify-between flex-wrap'>
                            <div>
                              <label className='font-medium bg-theme-27 box p-1'>
                                Monto:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.montoPrestado}$
                              </label>
                            </div>
                            <div>
                              <label className='font-medium bg-theme-23 box p-1'>
                                Monto a Pagar:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.montoCompromisoPago}$
                              </label>
                            </div>
                            <div>
                              <label className='font-medium bg-theme-25 box p-1'>
                                Fecha Pago:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.fechaCompromisoPagoDespues}
                              </label>
                            </div>
                            <div>
                              <label className={bgDeudaActual()}>
                                Deuda Actual:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {prestamoActual.estado === 'finalizado'
                                  ? 0
                                  : calcularInteresesPorAbonos()['interesHoy']}
                                $
                              </label>
                            </div>
                            <div>
                              <label className='font-medium bg-theme-8 box p-1'>
                                Ultimo Abono:
                              </label>
                              <label className='font-medium p-1 mr-1'>
                                {moment(fechaUltimoAbono()).format(
                                  'YYYY-MM-DD'
                                )}
                              </label>
                            </div>
                          </div>
                        </th>
                      </tr>

                      {/*calCulardiasTranscurridos(fechaUltimoAbono()) > 0 && ( */}
                      <tr>
                        <th colSpan='6' className='th-p'>
                          <div
                            className='col-span-12 sm:col-span-12 flex justify-center items-center'
                            style={{ flexDirection: 'column' }}
                          >
                            <h2 className='mb-2'>
                              Así estará distribuido el interes por día despues
                              de su ultimo abono.
                            </h2>
                            <div className='carousel'>
                              {armarDays(
                                calCulardiasTranscurridosCompleto(
                                  prestamoActual.fechaInicioDespues,
                                  prestamoActual.fechaCompromisoPagoDespues
                                )
                              ).map((day, i) => {
                                return (
                                  <div
                                    className='box px-2 py-2 mb-3 flex items-center zoom-in bg-theme-26'
                                    key={i}
                                  >
                                    <div>
                                      <div className='font-medium'>
                                        {`${formatFecha(
                                          moment(
                                            prestamoActual.fechaInicioDespues
                                          ).format('YYYY-MM-DD'),
                                          day
                                        )}:`}
                                      </div>
                                      <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                                        {`${interesDiarioTablaAbono(
                                          calcularInteresesPorAbonos()['deuda'],
                                          day,
                                          parseFloat(
                                            myUser.habilitadoNuevoPrestamo ===
                                              'No'
                                              ? prestamoActual.tasaInteresCompuesto
                                              : myUser.nuevaTasaInteres
                                          )
                                        )}$`}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </th>
                      </tr>
                      {/*})*/}
                    </tfoot>
                  )}
              </table>
            </div>
          </div>
        </div>
      )}

      {modalAbono && (
        <Modal
          title='Abonar Pago'
          close={cancelAbono}
          save={guardarAbono}
          cancel={cancelAbono}
          size='xl'
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='mb-2 font-medium text-xl'>
              Total deuda :{' '}
              {misabonos.length === 0 ? totalRestante() : totalRestanteAbonos()}
            </label>
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              n° de Transacción :
            </label>
            <input
              type='text'
              name='idTransaccion'
              placeholder=' N° de operacion'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.idTransaccion}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
            <input
              type='date'
              name='fecha'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.fecha}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <div className='flex items-center'>
              <label className='font-medium bg-theme-26 box p-1 mr-2'>
                Monto :
              </label>
              <input
                type='checkbox'
                className='input border'
                id='todo'
                name='monto[]'
                value='todo'
                onChange={changeMontoType}
                checked={montoPagar === 'todo'}
              />
              <label
                className='cursor-pointer select-none ml-1 mr-3'
                htmlFor='todo'
              >
                Todo :
              </label>
              <input
                type='checkbox'
                className='input border mr-2'
                id='abono'
                name='monto[]'
                value='abono'
                onChange={changeMontoType}
                checked={montoPagar === 'abono'}
              />
              <label
                className='cursor-pointer select-none ml-1'
                htmlFor='abono'
              >
                Abono :
              </label>
            </div>
            <input
              type='text'
              name='monto'
              placeholder=' Monto de la operación'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.monto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              disabled={disabled}
            />
          </div>
          <div className='flex col-span-12 sm:col-span-6 mt-8 justify-between'>
            <label className='font-medium bg-theme-26 box p-2 ml-8'>
              Adjuntar recibo:
            </label>
            <div className='flex'>
              <button
                className='button font-medium text-theme-5 bg-theme-1 rounded-full flex mr-8'
                onClick={(e) => openExplorer(e, abono)}
              >
                <Icon.FilePlus className='w-5 h-5 mr-2 pointer' />
                Subir Archivo
              </button>
            </div>
          </div>
          <div className='intro-y col-span-12 sm:col-span-12 bg-theme-2 box'>
            {fileName !== '' && (
              <div className='w-auto flex ml-2 justify-center p-1'>
                <label>{fileName}</label>
                <Icon.X
                  className='w-5 h-5 ml-3 pointer box bg-theme-5'
                  onClick={removeRecibo}
                />
              </div>
            )}
            <input
              type='file'
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={loadFile}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Comentario :
            </label>
            <textarea
              type='text'
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Concepto del abono... '
              name='concepto'
              value={pago.concepto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              rows='2'
            />
          </div>
        </Modal>
      )}

      {quedoDebiendo === true && (
        <Modal
          title='Nuevas Condiciones Del Préstamo'
          close={() => {}}
          save={generarSubprestamo}
          cancel={() => () => {}}
          customBtn={true}
          textcustomBtn='Aceptar'
          size='xl'
        >
          <div className='col-span-12 sm:col-span-12 p-2'>
            <h2 className='font-medium bg-theme-12 box p-1 mr-1 text-center'>
              Estimado usuario, ha pasado la fecha en que se comprometió a pagar
              el préstamo, podría indicarnos nuevamente, en que fecha se
              compromete a pagar la deuda restante.
            </h2>
          </div>
          <div className='col-span-12 sm:col-span-4 p-2 flex flex-col'>
            <label className='font-medium bg-theme-26 box p-1 mr-1 mb-2'>
              Deuda Actual Restante :
            </label>
            <span className='font-medium'>
              {calcularInteresesPorAbonos()['interesHoy']}$
            </span>
          </div>
          <div className='col-span-12 sm:col-span-4 p-2 flex flex-col'>
            <label className='font-medium bg-theme-26 box p-1 mr-1 mb-2'>
              Nueva Fecha de inicio:
            </label>
            <label className='font-medium'>{hoyBien}</label>
          </div>
          <div className='col-span-12 sm:col-span-4 p-2 flex flex-col'>
            <label className='font-medium bg-theme-26 box p-1 mr-1 mb-2'>
              Próxima Fecha de pago :
            </label>
            <input
              type='date'
              name='proximoCompromisoPago'
              value={proximoCompromisoPago}
              className='input input--sm w-56 border rounded-full'
              onChange={changeProximaFechaPago}
              min={minDateProximo}
            />
          </div>
          <div className='col-span-12 sm:col-span-12 p-2'>
            {calCulardiasTranscurridosCompleto(hoyBien, proximoCompromisoPago) >
              0 && (
              <div
                className='col-span-12 sm:col-span-12 flex justify-center items-center'
                style={{ flexDirection: 'column' }}
              >
                <h2 className='mb-2'>
                  Así quedará distribuido el interés por día despúes de su
                  último abono.
                </h2>
                <div className='carousel'>
                  <Slider {...settings} className='w-full'>
                    {armarDays(
                      calCulardiasTranscurridosCompleto(
                        hoyBien,
                        proximoCompromisoPago
                      )
                    ).map((day, i) => {
                      return (
                        <div
                          className='box px-2 py-2 mb-3 flex items-center zoom-in bg-theme-26'
                          key={i}
                        >
                          <div>
                            <div className='font-medium'>
                              {`${formatFecha(
                                moment(hoyBien).format('YYYY-MM-DD'),
                                day
                              )}:`}
                            </div>
                            <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                              {`${interesDiarioTablaAbono(
                                calcularInteresesPorAbonos()['deuda'],
                                day,
                                parseFloat(
                                  myUser.habilitadoNuevoPrestamo === 'No'
                                    ? prestamoActual.tasaInteresCompuesto
                                    : myUser.nuevaTasaInteres
                                )
                              )}$`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}

      {showDealle === true && (
        <Modal
          title='Detalles de Abono'
          close={() => setShowDetalle(false)}
          save={() => setShowDetalle(false)}
          cancel={() => setShowDetalle(false)}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Titular :
            </label>
            <label className='font-medium'>
              {abono.nombres} {abono.apellidos}
            </label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Fecha :
            </label>
            <label className='font-medium'>
              {moment(abono.fecha).format('YYYY-MM-DD')}
            </label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Referencia :
            </label>
            <label className='font-medium'>{abono.idTransaccion}</label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Monto :
            </label>
            <label className='font-medium'>{abono.monto}</label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Concepto :
            </label>
            <label className='font-medium'>{abono.concepto}</label>
          </div>
          <div className='col-span-12 sm:col-span-6 p-2'>
            <label className='font-medium bg-theme-26 box p-1 mr-1'>
              Confirmado :
            </label>
            <label className='font-medium'>
              {abono.confirmado ? 'Si' : 'No'}
            </label>
          </div>
          <div className='col-span-12 sm:col-span-11 ml-32'>
            {abono.voucher === '' && (
              <p className='font-medium bg-theme-6 box p-1 text-center text-theme-5'>
                No se ha adjuntado el recibo
              </p>
            )}
            {abono.voucher !== '' && (
              <img
                alt={abono.via}
                src={abono.voucher}
                style={{ width: '100%', height: 'auto' }}
                className='w-full mt-3'
              />
            )}
          </div>
        </Modal>
      )}

      {modalDatosPago === true && (
        <Modal
          title='Cuentas bancarias según el pais'
          close={() => setModalDatosPago(false)}
          save={() => setModalDatosPago(false)}
          cancel={() => setModalDatosPago(false)}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          {cuentasMostrar.map((cuenta, indice) => (
            <div
              className='mb-6 col-span-6 xs:col-span-12 flex flex-col'
              key={indice}
            >
              <div className='flex mb-1'>
                <label className='font-medium bg-theme-26 box p-1 mr-1'>
                  País :
                </label>
                <label className='font-medium p-1 '>{cuenta.pais}</label>
              </div>
              <div className='flex mb-1'>
                <label className='font-medium bg-theme-26 box p-1 mr-1'>
                  Banco :
                </label>
                <label className='font-medium p-1 '>{cuenta.banco}</label>
              </div>
              <div className='flex mb-1'>
                <label className='font-medium bg-theme-26 box p-1 mr-1'>
                  N° :
                </label>
                <label className='font-medium p-1 '>{cuenta.codigo}</label>
              </div>
              <div className='flex mb-1'>
                <label className='font-medium bg-theme-26 box p-1 mr-1'>
                  Email :
                </label>
                <label className='font-medium p-1 '>{cuenta.email}</label>
              </div>
              <div className='flex mb-1'>
                <label className='font-medium bg-theme-26 box p-1 mr-1'>
                  Tipo :
                </label>
                <label className='font-medium p-1 '>{cuenta.tipo}</label>
              </div>
            </div>
          ))}

          {cuentasMostrar.length === 0 && (
            <div className='col-span-12 sm:col-span-12 p-2 mb-12'>
              <p className='font-medium bg-theme-26 box p-2 mr-1'>
                "Estimado usuario, se ha enviado una solicitud de los datos
                bancarios a la administración, se les hará llegar en los
                próximos minutos mediante el chat para que pueda proceder a
                abonar o pagar la totalidad."
              </p>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default Abonos;
