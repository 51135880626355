import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import persistState from "redux-localstorage";

//reducers
import menuReducer from "./reducers/menuReducers";
import sesionReducer from "./reducers/sesionReducers";
import userReducer from "./reducers/userReducers";
import gestorReducers from "./reducers/gestorReducers";
import adminReducer from "./reducers/adminReducers";
import superAdminReducer from "./reducers/superAdminReducer";
import configuracionReducer from "./reducers/configuracionReducer";
// import encuestaReducer from './reducers/encuestaReducers';
// import postReducer from './reducers/postReducers';
// import dataReducer from './reducers/dataReducers';
// import uiReducer from './reducers/uiReducers';

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  menu: menuReducer,
  sesion: sesionReducer,
  user: userReducer,
  gestor: gestorReducers,
  admin: adminReducer,
  superadmin: superAdminReducer,
  configuracion: configuracionReducer
});

//------------------------- Para desarrollo -----------------//
// var getComposeEnhancers = () => {
//     if (window.navigator.userAgent.includes('Chrome')) {
//       return compose(
//         applyMiddleware(thunk),

//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//       );
//     }
//     return compose(applyMiddleware(thunk) );
//   };

//   var store = createStore(reducers, initialState, getComposeEnhancers() );

//------------------ Para Produccion y mobiles --------------------//
const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...middleware),
    persistState("myUser")
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;
