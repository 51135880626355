import React from 'react'
import {app} from '../config/firebaseConfig'
import { Route,Redirect } from "react-router-dom";

function ProtectedRoute({ children, ...rest}) {
  const isAuthenticated = app.auth().currentUser ? true : false;
  return( 
    <Route {...rest} render={() => {
      return isAuthenticated ? children : <Redirect to={{ pathname: '/login' }} />
    }} />
  )
}

export default ProtectedRoute