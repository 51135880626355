import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Recaptcha from 'react-recaptcha';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import { app } from '../../config/firebaseConfig';

import Ilustracion from '../../statics/images/logo-chamopresta-PNG.png';

import {
  modalTerminoCondicion,
  modalPolitica,
} from '../../redux/actions/sesionAction';

const ForgotPassword = (props) => {
  const dispatch = useDispatch();

  document.body.classList.add('app');
  document.body.classList.add('login');

  //========== USE-STATES ==========//
  const [forgotData, setforgotData] = useState({ email: '' });
  const [errorForgot, setErrorForgot] = useState({
    color: '',
    message: '',
    view: false,
  });
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: -1,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });
  const [captchaForgot, setcaptchaForgot] = useState(false);

  //========== USE-SELECTORS ==========//
  const modalTerminoCondiciones = useSelector(
    (state) => state.sesion.modalTerminoCondiciones
  );
  const modalPoliticasPrivacidad = useSelector(
    (state) => state.sesion.modalPoliticasPrivacidad
  );

  useEffect(() => {
    console.log('usuario actual', app.auth().currentUser);
    getConfiguracion();
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
        } else {
          setConfig({
            diasMinimoPago: '',
            fondo: -1,
            montoInicialMaximoPrestamo: '',
            montoMaximoPrestamo: '',
            politicas: '',
            porcentajeIncremento: '',
            requisitos: '',
            tasaDolar: '',
            tasaInteresCompuesto: '',
            terminos: '',
          });
        }
      });
  };

  const onChangeForgot = (e) => {
    const { name, value } = e.target;
    setforgotData({ ...forgotData, [name]: value });
  };

  const verifyrecaptcha = (response) => {
    if (response) {
      setcaptchaForgot(true);
    }
  };

  const recaptchaLoaded = () => {
    // console.log('capcha loaded successfull');
  };

  const forgotRest = async (e) => {
    e.preventDefault();
    if (forgotData.email === '') {
      showMessage('bg-theme-9', 'Debe completar todos los campos *', true);
    } else if (!captchaForgot) {
      showMessage(
        'bg-theme-9',
        'Demuestra que eres Humano valida el captcha *',
        true
      );
    } else {
      showMessage('', '', false);

      const email = forgotData.email.trim();
      await app
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
          showMessage(
            'bg-theme-9',
            'Correo enviado con exito a su cuenta',
            true
          );
          setforgotData({ email: '' });
          //se envio en link
        })
        .catch(function (err) {
          let msg = '';
          switch (err.code) {
            case 'auth/invalid-email':
              msg = '*Formato de correo invalido.';
              break;
            case 'auth/user-disabled':
              msg = '*La cuenta de correo ingresada ha sido desactivada.';
              break;
            case 'auth/user-not-found':
              msg = '*Correo no registrado';
              break;
            default:
              msg = '*Correo no registrado.';
              break;
          }
          showMessage('bg-theme-6', msg, true);
          //no se envio en link
        });
    }
  };
  const showMessage = (color, message, view) => {
    setErrorForgot({ color, message, view });
  };

  //========== Funciones Modal Terminos ==========//
  const closeModalTerminos = () => {
    dispatch(modalTerminoCondicion(false));
  };
  const cancelModalTerminos = () => {
    dispatch(modalTerminoCondicion(false));
  };
  const modalTerminos = (params) => {
    dispatch(modalTerminoCondicion(true));
  };

  //========== Funciones Politicas y Privacidad ==========//
  const closeModalPoliticas = () => {
    dispatch(modalPolitica(false));
  };
  const cancelModalPoliticas = () => {
    dispatch(modalPolitica(false));
  };
  const modalPoliticas = (params) => {
    dispatch(modalPolitica(true));
  };

  return (
    <>
      <div className="container sm:px-10">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="xl:flex flex-col min-h-screen my-auto mx-auto w-2/5 sm:w-2/4 lg:w-2/5 xl:w-auto ocultar">
            <img
              alt="Midone Tailwind HTML Admin Template"
              className="-intro-x w-3\/4 -ml-20"
              src={Ilustracion}
            />
            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
              ¡Préstamos sin garantías!
              <br />
              Fácil y rápida aprobación…
            </div>
            <div className="-intro-x mt-5 text-lg text-white">
              Dirigido a venezolanos en el exterior.
            </div>
          </div>
          {/* Responsive */}
          <div className="lg:hidden ml-4">
            <img
              alt="Midone Tailwind HTML Admin Template"
              className="-intro-x w-3\/4 -ml-5"
              src={Ilustracion}
            />
          </div>
          {/* Responsive Fin */}

          <div className="h-screen flex py-5 custom-auth">
            <div className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 className="intro-x font-bold text-xl xl:text-xl text-center xl:text-left">
                Recuperar la contraseña
              </h2>
              <form
                action=""
                onSubmit={forgotRest}
              >
                <div className="intro-x mt-4">
                  <label className="">Escribe tu correo</label>
                  <input
                    type="text"
                    name="email"
                    autoFocus={true}
                    className="intro-x login__input input rounded-full border border-gray-300 block mt-1"
                    placeholder=" Correo electrónico"
                    value={forgotData.email}
                    onChange={onChangeForgot}
                  />
                </div>
                {errorForgot.view && (
                  <div className="intro-x mt-2">
                    <div
                      className={[
                        'rounded-md',
                        'px-5',
                        'py-4',
                        'mb-2',
                        'text-white',
                        `${errorForgot.color}`,
                      ].join(' ')}
                    >
                      {errorForgot.message}
                    </div>
                  </div>
                )}
                <div className="intro-x mt-5 xl:mt-5 text-center xl:text-center">
                  <div className="mt-5">
                    <Recaptcha
                      sitekey="6LfdezoaAAAAAMR-vF2KYhgIPNgCeo40ZKTshnIt"
                      render="explicit"
                      hl="es"
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyrecaptcha}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                  <button className="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 mt-5">
                    Recuperar
                  </button>
                </div>
                <div className="intro-x flex text-gray-700 text-xs sm:text-sm mt-5">
                  <div className="flex items-center mr-auto">
                    <Link to="/login"> Inicia sesión</Link>
                  </div>
                  <div className="flex items-center">
                    <Link to="/register"> Registrate</Link>
                  </div>
                </div>
                <div className="intro-x mt-10 xl:mt-6 text-gray-700 text-center xl:text-center">
                  Al ingresar y registrarse acepta nuestros
                  <br />
                  <a
                    className="text-theme-1 font-medium"
                    href="#!"
                    onClick={(e) => modalTerminos()}
                  >
                    Terminos y Condiciones
                  </a>{' '}
                  &{' '}
                  <a
                    className="text-theme-1 font-medium"
                    href="#!"
                    onClick={(e) => modalPoliticas()}
                  >
                    Politica de Privacidad
                  </a>
                  <p>de ChamoPresta.com</p>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*===== Modal Terminos Condiciones =====*/}
        {modalTerminoCondiciones && (
          <Modal
            title="Nuestros Terminos y Condiciones"
            close={closeModalTerminos}
            cancel={cancelModalTerminos}
            save={() => dispatch(modalTerminoCondicion(false))}
            customBtn={true}
            textcustomBtn="Aceptar"
          >
            <div className="intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh">
              <p className="my-1 whitespace-pre-wrap">{config.terminos}</p>
            </div>
          </Modal>
        )}
        {/*===== Modal Politica y Privacidad =====*/}
        {modalPoliticasPrivacidad && (
          <Modal
            title="Nuestras Politica de Privacidad"
            close={closeModalPoliticas}
            cancel={cancelModalPoliticas}
            save={() => dispatch(modalPolitica(false))}
            customBtn={true}
            textcustomBtn="Aceptar"
          >
            <div className="intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh">
              <p className="my-1 whitespace-pre-wrap">{config.politicas}</p>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
