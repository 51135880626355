import React, { useEffect } from 'react';
import $ from 'jquery';
import feather from 'feather-icons';

import { useSelector } from 'react-redux';

export default function CardCuentasBancarias(props) {
  const selectedCuenta = useSelector((state) => state.user.selectedCuenta);

  useEffect(() => {
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 });
      $('.tooltip').each(function () {
        let options = {
          delay: 0,
          theme: 'tooltipster-borderless',
          multiple: true,
        };
        if ($(this).data('event') === 'on-click') {
          options.trigger = 'click';
        }
        if ($(this).data('theme') === 'light') {
          options.theme = 'tooltipster-shadow';
        }
        if ($(this).data('side') !== undefined) {
          options.side = $(this).data('side');
        }
        $(this).tooltipster(options);
      });
    })($);
  }, []);

  return (
    <div className='report-box zoom-in' onClick={props.seleccionar}>
      <div
        className={`box p-5 ${
          selectedCuenta && selectedCuenta.id === props.item.id
            ? 'selected'
            : ''
        }`}
      >
        <div className='flex'>
          <i data-feather='user' className='text-theme-10'></i>
          <div className='ml-auto flex'>
            <div className='rounded-full' onClick={props.editar}>
              <i
                data-feather='edit-2'
                className='text-theme-10 tooltip mr-1'
                title='Editar'
              ></i>
            </div>
            <div className='rounded-full' onClick={props.eliminar}>
              <i
                data-feather='trash-2'
                className='text-theme-6 tooltip'
                title='Eliminar'
              ></i>
            </div>
          </div>
        </div>

        <div className='text-xl font-bold leading-8'>{props.item.titular}</div>
        <div className='text-xl font-bold leading-8'>{props.item.cedula}</div>
        {props.tipo === 'cuenta' && (
          <div className='text-base text-gray-600 mt-1'>
            {props.item.numeroCuenta}
          </div>
        )}
        {props.tipo !== 'cuenta' && (
          <div className='text-base text-gray-600 mt-1'>{props.item.banco}</div>
        )}
        <div className='text-base font-semibold text-gray-800 mt-1'>
          {props.item.tipoCuenta}
        </div>
        <div className='flex justify-center font-semibold text-sm text-gray-800 mt-3'>
          <i
            data-feather='globe'
            className='text-theme-9 w-4 h-4 tooltip mr-1'
            title='Pais'
          ></i>
          {props.item.pais}
          <i
            data-feather='map-pin'
            className='text-theme-10 w-3 h-3 tooltip ml-2 mt-1'
            title='Ubicación'
          ></i>
        </div>
      </div>
    </div>
  );
}
