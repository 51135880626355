import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import feather from "feather-icons";
//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import {
  getUsers,
  getRegistroActividad,
} from "../../../redux/actions/superAdminActions";

const RegistroActividades = () => {
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const registroActividad = useSelector(
    (state) => state.superadmin.registroActividad
  );

  useEffect(() => {
    (function () {
      "use strict";
      feather.replace({ "stroke-width": 1.5 });
    })();
    dispatch(getUsers());
    dispatch(getRegistroActividad());
  }, []);

  //========== Contenido Tabla Registro Actividades ==========//
  const columns = [
    {
      field: "usuario",
      headerName: "USUARIO",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 300,
      valueGetter: (params) => {
        return `${"Usuario : "} ${params.row.usuario} `;
      },
    },
    {
      field: "accion",
      headerName: "ACCION",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      width: 560,
    },
    {
      field: "fecha",
      headerName: "FECHA",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      width: 202,
    },
  ];

  //========== Funciones y Metodos de la Tabla de Registros ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .super-app-theme--header": {
        backgroundColor: "rgba(232, 238, 255, 0.9)",
      },
    },
  }));
  const classes = useStyles();

  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }
  return (
    <>
      <div>
        <div className="flex border-b mt-3 mb-2">
          <h2 className="text-lg font-medium ml-8">Actividades de Usuarios</h2>
        </div>
        <div className="col-span-12 box bg-theme-2 p-1">
          <div className="col-span-12 overflow-auto lg:overflow-visible">
            <div
              style={{ height: 690, width: "100%" }}
              className={classes.root}
            >
              <DataGrid
                rows={registroActividad}
                columns={columns}
                pageSize={100}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: "Menu",
                  columnMenuShowColumns: "Show columns",
                  columnMenuFilter: "Filtrar",
                  columnMenuSortAsc: "Ordenar Ascendente",
                  columnMenuSortDesc: "Ordenar Descendente",
                  columnMenuHideColumn: "",
                  columnMenuUnsort: "",
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistroActividades;
