import moment from 'moment';

export function isURL(str) {
  /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);*/

  var RegExp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  if (RegExp.test(str)) {
    return true;
  } else {
    return false;
  }
}

export function restaFechas(f1, f2) {
  let fecha1 = new Date(f1);
  let fecha2 = new Date(f2);
  let milisegundosDia = 24 * 60 * 60 * 1000;
  let milisegundosTranscurridos = Math.abs(fecha2.getTime() - fecha1.getTime());
  var dias = Math.round(milisegundosTranscurridos / milisegundosDia);
  return dias;
}

export function diferenciaFechas(fecha) {
  const fechaFormat = moment(fecha);
  const hoy = moment();
  var dias = hoy.diff(fechaFormat, 'days');
  return dias;
}

export function playNotification() {
  let sound = document.getElementById('beep');
  sound.play();
}

export const calculateInteres = (deuda, dias, tasaInteresCompuesto) => {
  let totalApagar = (
    deuda * Math.pow(1 + parseFloat(tasaInteresCompuesto) / 100, dias)
  ).toFixed(2);
  return totalApagar;
};

export function calculateInteresByDia(deuda, dia, tasaInteresCompuesto) {
  let totalApagar = (
    deuda * Math.pow(1 + parseFloat(tasaInteresCompuesto) / 100, dia)
  ).toFixed(2);
  return totalApagar;
}

export function calCulardiasTranscurridos(fecha) {
  const fechaFormat = moment(fecha);
  const hoy = moment();
  var dias = hoy.diff(fechaFormat, 'days');
  return dias;
}

export function isMail(email) {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return false;
  }
  return true;
}

export function calCulardiasTranscurridosCompleto(fecha, fechafin) {
  const fechaFormat = moment(fecha);
  const hoy = moment(fechafin);
  var dias = hoy.diff(fechaFormat, 'days');
  return dias;
}
