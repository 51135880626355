import firebase from 'firebase/app'
import "firebase/auth";
import 'firebase/firebase-firestore';
import 'firebase/database';
import 'firebase/firebase-storage';
const { REACT_APP_MODE } = process.env;

/*const firebaseConfig  = { //configuracion de app firebase cunta de produccion
    apiKey: "AIzaSyCEyNYX3J7cih61saMRifpEhykDvFf3eQc",
    authDomain: "chamopresta.firebaseapp.com",
    databaseURL: "https://chamopresta.firebaseio.com",
    projectId: "chamopresta",
    storageBucket: "chamopresta.appspot.com",
    messagingSenderId: "809950472506",
    appId: "1:809950472506:web:1678c1a5e2994e57804803",
    measurementId: "G-Q8ECDWZP62"
};*/

//PROD
const firebaseConfig = {
    apiKey: "AIzaSyB4LBAm-jm68pppgxGiPdde7zpJRZMFPBk",
    authDomain: "chamopresta-8bd4d.firebaseapp.com",
    projectId: "chamopresta-8bd4d",
    storageBucket: "chamopresta-8bd4d.appspot.com",
    messagingSenderId: "476944756445",
    appId: "1:476944756445:web:6b039f56a0d97673b23534",
    measurementId: "G-8GC9KN23C3"
};

//QA
const firebaseConfigQA = {
    apiKey: "AIzaSyDAC0FIPcv61DSJOsrGnVRDKY5j-MITNzY",
    authDomain: "chamoprestaqa.firebaseapp.com",
    projectId: "chamoprestaqa",
    storageBucket: "chamoprestaqa.appspot.com",
    messagingSenderId: "1153521534",
    appId: "1:1153521534:web:b0176d1a3c2f095ae2c52f",
    measurementId: "G-T6ZLQSBHW9"
}

let app;

//inicializar Firebase
if(REACT_APP_MODE === 'development') {
    app = firebase.initializeApp(firebaseConfigQA);
} else {
    app = firebase.initializeApp(firebaseConfig);
}

//instanciar servicios de autenticacion (Facebook, Google y Github)
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();

const createGeoPoint = (latitude, longitude) => {
    return new firebase.firestore.GeoPoint(latitude, longitude);
}


/*Ejemplo de como usar auth, database, firestore,  storage, 
    app.auth().logout();
    app.database.ref('')
    app.firestore().collection('');
    app.storage.ref('');
*/
//Exportacion de las insntancias creadas
export {
    app,
    googleAuthProvider,
    facebookAuthProvider,
    githubAuthProvider,
    createGeoPoint,
}