/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';
import feather from 'feather-icons';
import * as Icon from 'react-feather';
import moment from 'moment';

import { app } from '../../../config/firebaseConfig';
import Perfil from '../../../statics/images/selfie_prest-1.jpeg';
import selfieDoc from '../../../statics/images/upload_selphi.png';
import Comprobante from '../../../statics/images/comprobante.png';
import Document from '../../../statics/images/document.png';

import Chat from '../../chat/Chat';
import Modal from '../../Modal';
import { addLog } from '../../../redux/actions/sesionAction';
import { success, confirm, custom } from '../../../helpers/alertas';

import { axiosInstance } from '../../../config/api';
import url from '../../../config/url';
import CSVReader from 'react-csv-reader';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import {
  LOADING,
  ENDLOADING,
  GET_PRESTAMO,
  GET_REQUERIMIENTOSUSUARIOS,
  GET_REQUERIMIENTOPRESTAMO,
  GETCHAT,
  GET_LINKSOCIALES,
  MODALABONO,
  GETABONOS,
  GETCUENTASBANCARIAS,
  DATOSGENERELES,
} from '../../../redux/types';

import {
  isURL,
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';

//========== Import Acordion MaterialUI ==========//
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ChatIcon from '@material-ui/icons/Chat';
import DeleteSweep from '@material-ui/icons/DeleteSweep';

import {
  getPrestamo,
  getLinkSociales,
  getRequerimientosUsuarios,
  getRequerimientoPrestamo,
  aprobarPrestamo,
  rechazarPrestamo,
  getContactoSociales,
  modalEditarContacto,
  modalCrearContacto,
  modalEnvioCorreoMensaje,
  modalCorreoRechazoSolicitud,
  updateContactoSociales,
  deleteContactoSociales,
  deleteSociales,
  saveCrearContactoSociales,
  sendMsg,
  addChat,
  getAbonosRealizados,
} from '../../../redux/actions/gestorActions';
import {
  getSociales,
  updateDatosPrestamo,
} from '../../../redux/actions/userAction';

const hoy = moment(new Date()).format('DD/MM/YYYY');

const GestionarPrestamo = () => {
  let { userid } = useParams();
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const fileSelfieRef = useRef(null);
  const fileSelfiePresRef = useRef(null);
  const fileDocumentRef = useRef(null);
  const fileComprobanteRef = useRef(null);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const chat = useSelector((state) => state.gestor.chat);
  const prestamo = useSelector((state) => state.gestor.prestamo);
  const linkSociales = useSelector((state) => state.gestor.linkSociales);
  const contactosSociales = useSelector(
    (state) => state.gestor.contactosSociales
  );
  const requerimientosUsuarios = useSelector(
    (state) => state.gestor.requerimientosUsuarios
  );
  const requerimientoPrestamo = useSelector(
    (state) => state.gestor.requerimientoPrestamo
  );
  const modalEditarContactoSociales = useSelector(
    (state) => state.gestor.modalEditarContactoSociales
  );
  const modalCrearContactoSociales = useSelector(
    (state) => state.gestor.modalCrearContactoSociales
  );
  const modalEnvioCorreoMensajeRechazo = useSelector(
    (state) => state.gestor.modalEnvioCorreoMensajeRechazo
  );
  const modalEnvioCorreoRechazoSolicitud = useSelector(
    (state) => state.gestor.modalEnvioCorreoRechazoSolicitud
  );
  const abonosRealizados = useSelector(
    (state) => state.gestor.abonosRealizados
  );
  const modalAbono = useSelector((state) => state.user.modalAbono);

  //========== USE-STATES ==========//
  const [prestamoAct, setPrestamoAct] = useState({});
  const [Contactos, setContactos] = useState({
    nombres: '',
    parentesco: '',
    url: '',
    tipo: '',
    idUsuario: '',
  });
  const [envioCorreoMensaje, setEnvioCorreoMensaje] = useState({
    mensajeCorreo: '',
    idUsuario: '',
  });
  const [editarContactos, setEditarContactos] = useState(false);
  const [pago, setPago] = useState({
    idTransaccion: '',
    nombres: '',
    apellidos: '',
    via: 'transferencias',
    monto: '',
    concepto: '',
    fecha: moment(new Date()).format('YYYY-MM-DD'),
    voucher: '',
    confirmado: false,
  });
  const [social, setSocial] = useState({
    fechaValidacion: null,
    gestor: null,
    id: '',
    idGestor: null,
    idSocial: '',
    idUsuario: app.auth().currentUser.uid,
    solicitudConfirmada: false,
    solicitudEnviada: false,
    tipo: '',
    url: '',
    validado: '',
  });
  const [editarSocial, setEditarSocial] = useState(false);
  const misabonos = useSelector((state) => state.user.misabonos);
  const [montoPagar, setMontoPagar] = useState('todo');
  const [disabled, setDisabled] = useState(true);
  const [quedoDebiendo, setQuedoDebiendo] = useState(false);
  const [refFile, setRefFile] = useState(null);
  const [fileName, setFilename] = useState('');
  const [abono, setAbono] = useState(null);
  const [administrativos, setAdministrativos] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [tasaDolarHoy, setTasaDolarHoy] = useState(1);

  useEffect(() => {
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 }); //Feather icons
      //==========Tooltipster==========//
      $('.tooltip').each(function () {
        let options = { delay: 0, theme: 'tooltipster-borderless' };
        if ($(this).data('event') === 'on-click') {
          options.trigger = 'click';
        }
        if ($(this).data('theme') === 'light') {
          options.theme = 'tooltipster-shadow';
        }
        if ($(this).data('side') !== undefined) {
          options.side = $(this).data('side');
        }
        $(this).tooltipster(options);
      });
    })($);
  }, []);

  useEffect(() => {
    const obtenerPrestamo = () => {
      app
        .firestore()
        .collection('prestamos')
        .doc(userid)
        .get()
        .then((doc) => {
          let obj = doc.data();
          obj.id = doc.id;
          dispatch({ type: GET_PRESTAMO, payload: obj });
          leerChats();
          leerRequerimientosPrestamo();
          leerLinkSociales(doc.data().idUsuario);
          leerRequerimientosUsuario(doc.data().idUsuario);
          dispatch(getContactoSociales(doc.data().idUsuario));
          dispatch(getAbonosRealizados(userid));
          setPrestamoAct(obj);
        });
    };
    const leerChats = () => {
      app
        .firestore()
        .collection('chat')
        .where('idPrestamo', '==', `${userid}`)
        .onSnapshot(manejarSnapshotChat);
    };
    const leerRequerimientosUsuario = (idUsuario) => {
      app
        .firestore()
        .collection('requerimiento')
        .where('idUsuario', '==', `${idUsuario}`)
        .onSnapshot(manejarSnapshotRequerimientosUsuario);
    };
    const leerRequerimientosPrestamo = () => {
      app
        .firestore()
        .collection('requerimientoPrestamo')
        .where('idPrestamo', '==', `${userid}`)
        .onSnapshot(manejarSnapshotRequerimientosPrestamo);
    };
    const leerLinkSociales = (idUsuario) => {
      app
        .firestore()
        .collection('sociales')
        .where('idUsuario', '==', `${idUsuario}`)
        .onSnapshot(manejarSnapshotLinkSociales);
    };
    obtenerPrestamo();
    userAdministrativos();
    getDolarValue();
    dispatch(getSociales());
  }, []);

  function manejarSnapshotChat(snapshot) {
    const cuentas = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      dispatch({ type: GETCHAT, payload: obj });
      return obj;
    });
  }
  function manejarSnapshotRequerimientosUsuario(snapshot) {
    let requerimientosUsuarios = [];
    snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      requerimientosUsuarios.push(obj);
    });
    dispatch({
      type: GET_REQUERIMIENTOSUSUARIOS,
      payload: requerimientosUsuarios,
    });
  }
  function manejarSnapshotRequerimientosPrestamo(snapshot) {
    let requerimientosPrestamo = [];
    snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      requerimientosPrestamo.push(obj);
    });
    dispatch({
      type: GET_REQUERIMIENTOPRESTAMO,
      payload: requerimientosPrestamo,
    });
  }
  function manejarSnapshotLinkSociales(snapshot) {
    let links = [];
    snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      links.push(obj);
    });
    dispatch({ type: GET_LINKSOCIALES, payload: links });
  }
  const getDolarValue = async () => {
    try {
      let response = await axiosInstance.get(`/monitor`);
      const { data } = response;
      let dataBien = data.resp.replaceAll('.', '');
      dataBien = dataBien.replace(',', '.');
      setTasaDolarHoy(parseFloat(dataBien));
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const aprobar = (event) => {
    if (validarLinks() === 'rechazado' || requerimientosUsuarios[0].validado) {
      custom('El usuario no tiene todos su datos validados', 'error');
    } else {
      dispatch(
        aprobarPrestamo(prestamo.id, {
          estado: 'preAprobado',
          fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
        })
      );
      dispatch(
        addLog({
          accion: `${'Prestamo Aprobado de: '} ${prestamo.usuario.nombres} ${
            prestamo.usuario.apellidos
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
      let para = `${prestamo.usuario.email}`;
      const datosCuentaPago = () => {
        if (prestamo.tipoPagoVenezuela === 'Cuenta Bancaria') {
          return `${prestamo.datosEnvio.numeroCuenta}`;
        } else {
          return ` Teléfono Pago : ${prestamo.datosEnvio.telefono}`;
        }
      };
      const data = {
        asunto: 'Pre-Aprobación de Préstamo',
        email: para,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,<br />
          Felicidades, le informamos que su préstamo ha sido PRE-APROBADO con éxito. Nos podría   confirmar que aún se encuentra interesado en recibirlo y que los datos de pago son :</p><br/>
          <p>Titular: ${prestamo.datosEnvio.titular}</p>
          <p>Banco: ${prestamo.datosEnvio.nombreBanco}</p>
          <p>Cuenta: ${datosCuentaPago()}</p>
          <p>Monto: ${prestamo.montoPrestado} $ .</p><br/>
          <div>
            <p>Si aún se encuentra interesado, le resta un último paso, subir una selfie con la   solicitud formal del préstamo, ingrese a chamopresta.com para obtener la orientación final.</p>
            <p><b>Saludos.</b></p>
          </div>`,
      };
      enviarNotificacion(data, 'Sistema');
      enviarMesaje(
        `<p>Su Préstamo ha sido Pre-aprobado...Nos podría confirmar que aún se encuentra interesado en recibirlo y que los datos de pago son :</p><br/>
        <p>-Titular: ${prestamo.datosEnvio.titular}.</p>
        <p>-Banco: ${prestamo.datosEnvio.nombreBanco}.</p>
        <p>-Cuenta: ${datosCuentaPago()}.</p>
        <p>-Monto Préstado: ${prestamo.montoPrestado} $ .</p><br/>
        <p>Aguardamos su confirmación por este chat.</p>
        <p>Proceda a realizar el envío de la selfie con la solicitud formal del préstamo...</p>`,
        'Sistema'
      );
    }
    custom('El préstamo ha sido Pre-Aprobado', 'success');
  };
  const aprobarSelfiePrestamo = (event) => {
    dispatch(
      aprobarPrestamo(prestamo.id, {
        aprobado: true,
        estado: 'aprobado',
        fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
        fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
      })
    );
    dispatch(
      addLog({
        accion: `${'La selfie del Préstamo de: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Aprobado'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    const data = {
      asunto: 'Préstamo Aprobado',
      email: para,
      motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p>Felicidades le informamos que su préstamo ha sido <b>Completado con Éxito.</b></p>
        <p>En instante se procederá a efectuar la transferencia en la cuenta suministrada por usted.</p>
        `,
    };
    const emalSuperadmin = {
      asunto: 'Aprobación por un Gestor',
      email: 'info@chamopresta.com',
      motivo: `<p>El gestor/a, <b>${prestamo.gestor.nombres}  ${
        prestamo.gestor.apellidos
      }</b>, 
          Ha Aprobado un préstamo al usuario <b>${prestamo.usuario.nombres}  ${
        prestamo.usuario.apellidos
      }.</b></p> 
          Presione click en el siguiente <a href="${
            'https://chamopresta.com/prestamos/' + prestamo.id
          }">link</a> para realizar el préstamo 
        `,
    };
    enviarNotificacion(emalSuperadmin);
    enviarNotificacion(data);
    enviarMesaje(
      `<span>
      <p>Su préstamo ha sido aprobado, se hará la transferencia y se le enviará el comprobante por esta vía.</p><br/>
      <p>Recuerde que el seguimiento de cobranza del préstamo se hará por vía de correo electrónico y por redes sociales.</p><br/>
      <p>Por favor, si no le llega correo a su bandeja principal revise la carpeta de spam y marque las notificaciones de ChamoPresta.com como correo válido.</p></span>`,
      'Sistema'
    );
    custom('El préstamo ha sido Aprobado', 'success');
  };
  const rechazar = (event) => {
    dispatch(
      rechazarPrestamo(prestamo.id, { aprobado: false, estado: 'rechazado' })
    );
    dispatch(
      addLog({
        accion: `${'El Prestamo de: '} ${prestamo.usuario.nombres} ${
          prestamo.usuario.apellidos
        } ${'Ha Sido Rechazado'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    const data = {
      asunto: 'Verificación / Correción',
      email: para,
      motivo: `<p>Estimado/a, <b>${usuario}</b>,</p> 
        <p><b>Estimado Usuario le informamos que ${envioCorreoMensaje.mensajeCorreo}.</b></p>
        <p>Verifique y corrija los requisitos comentados para proceder a una nueva evaluación.</p>
        `,
    };
    enviarNotificacion(data);
    enviarMesaje(envioCorreoMensaje.mensajeCorreo, 'Sistema');
    dispatch(modalEnvioCorreoMensaje(false));
  };
  const rechazarSelfiePrestamo = (event) => {
    dispatch(
      addLog({
        accion: `${'La selfie del Préstamo de: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    const data = {
      asunto: 'Notificación sobre requisitos de préstamo',
      email: para,
      motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>Estimado Usuario le informamos que su Selfie con la Solicitud está rechazada.</b></p>
        <p>Verifique y corrija los requisitos comentados para proceder a una nueva evaluación.</p>
        `,
    };
    enviarNotificacion(data);
    enviarMesaje(
      `<span><p>Le informamos que su selfie de solicitud presenta algun problema...</p><br/>
      <p>Debe corregirla para seguir con su proceso.</p></span>`,
      'Sistema'
    );
    custom('El préstamo ha sido Rechazado', 'success');
  };
  const validarLinks = () => {
    let rechazados = [];
    let aprobados = [];
    let vacios = [];
    linkSociales.forEach((row) => {
      if (row.validado === 'rechazado') {
        rechazados.push(row.validado);
      } else if (row.validado === 'aprobado') {
        aprobados.push(row.validado);
      } else {
        vacios.push(row.validado);
      }
    });
    if (aprobados.length === linkSociales.length) {
      // console.log("caso1 validarLinks");
      return 'aprobado';
    } else if (vacios.length === linkSociales.length) {
      // console.log("caso2 validarLinks");
      return 'no revisado';
    } else {
      // console.log("caso3 validarLinks");
      return 'rechazado';
    }
  };
  const validarImagenes = () => {
    if (
      // requerimientosUsuarios[0].validadoComprobanteTrabajo === '' &&
      requerimientosUsuarios[0].validadoDocumento === '' &&
      requerimientosUsuarios[0].validadoSelfieCedula === ''
    ) {
      return 'no revisado';
    } else {
      if (
        // requerimientosUsuarios[0].validadoComprobanteTrabajo === 'aprobado' &&
        requerimientosUsuarios[0].validadoDocumento === 'aprobado' &&
        requerimientosUsuarios[0].validadoSelfieCedula === 'aprobado'
      ) {
        return 'aprobado';
      } else {
        return 'rechazado';
      }
    }
  };
  const montoHoy = () => {
    if (prestamo && prestamo.usuario) {
      let dias = calCulardiasTranscurridos(prestamo.fechaInicio);
      let monto = calculateInteres(
        prestamo.montoPrestado,
        dias,
        parseFloat(
          prestamo.usuario.habilitadoNuevoPrestamo === 'No'
            ? prestamo.tasaInteresCompuesto
            : prestamo.usuario.nuevaTasaInteres
        )
      );
      return parseFloat(monto);
    } else {
      return 0;
    }
  };

  //========== Contenido Acordion ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      fontSize: theme.typography.pxToRem(14),
      width: '100%',
    },
  }));
  const classes = useStyles();

  //========== Funciones y Metodos de Verificacion Links ==========//
  const verificarLink = (event, item) => {
    if (event.target.value === 'aprobado') {
      app
        .firestore()
        .collection('sociales')
        .doc(`${item.id}`)
        .update({
          fechaValidacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idGestor: app.auth().currentUser.uid,
          idSocial: item.id,
          validado: 'aprobado',
          gestor: myUser,
        })
        .then(() => {
          dispatch(getLinkSociales(item.idUsuario));

          dispatch(
            addLog({
              accion: `${'El Link: '} ${item.url} ${'del Prestamo de: '} ${
                prestamo.usuario.nombres
              } ${'Ha Sido Aprobado'}`,
              fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              usuario: myUser.email,
            })
          );
        });
      app
        .firestore()
        .collection('prestamos')
        .doc(`${userid}`)
        .update({
          idGestor: myUser.idUsuario,
          gestor: myUser,
        })
        .then(() => {
          dispatch(getPrestamo(userid));
        });
    } else {
      app
        .firestore()
        .collection('sociales')
        .doc(`${item.id}`)
        .update({
          fechaValidacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idGestor: app.auth().currentUser.uid,
          idSocial: item.id,
          validado: 'rechazado',
          gestor: myUser,
        })
        .then(() => {
          dispatch(getLinkSociales(item.idUsuario));

          dispatch(
            addLog({
              accion: `${'El Link: '} ${item.url} ${'del Prestamo de: '} ${
                prestamo.usuario.nombres
              } ${'Ha Sido Rechazo'}`,
              fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              usuario: myUser.email,
            })
          );
        });
      app
        .firestore()
        .collection('prestamos')
        .doc(`${userid}`)
        .update({
          idGestor: myUser.idUsuario,
          gestor: myUser,
        })
        .then(() => {
          dispatch(getPrestamo(userid));
        });
    }
  };

  //========== Funciones y Metodos de Solicitud Amistad ==========//
  const solicitudAmistad = (event, item) => {
    if (event.target.value === 'aprobado') {
      app
        .firestore()
        .collection('sociales')
        .doc(`${item.id}`)
        .update({
          solicitudEnviada: true,
          solicitudConfirmada: true,
        })
        .then(() => {
          dispatch(getLinkSociales(item.idUsuario));

          dispatch(
            addLog({
              accion: `${'Solicitud Amistad Confirmada: '} ${item.url} `,
              fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              usuario: myUser.email,
            })
          );
          let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
          let para = `${prestamo.usuario.email}`;
          let redSocial = buildType(item.url);
          const data = {
            asunto: 'Solicitud de Amistad/Seguimiento',
            email: para,
            motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
            <p><b>Le informamos que desde chamopresta.com te hemos enviado una ${
              redSocial === 'facebook'
                ? 'solicitud de amistad'
                : 'solicitud de seguimiento'
            }
            en ${redSocial} a la cuenta que nos suministraste."</b></p>
            <p>Por favor, acéptanos para poder validar tú identidad y así seguir evaluando tu préstamo.</p>
        `,
          };
          enviarNotificacion(data);
          enviarMesaje(
            `<p>Estimado/a, <b>${usuario}</b></p>
            <p><b>Le informamos que desde chamopresta.com te hemos enviado una ${
              redSocial === 'facebook'
                ? 'solicitud de amistad'
                : 'solicitud de seguimiento'
            }
            en ${redSocial} a la cuenta que nos suministraste."</b></p>
            <p>Por favor, acéptanos para poder validar tú identidad y así seguir evaluando tu préstamo.</p>`,
            'Sistema'
          );
        });
    }
  };

  //========== Funciones y Metodos de Verificacion Imagenes ==========//
  const openExplorerSelfie = (e) => {
    fileSelfieRef.current.click();
  };
  const openExplorerDocumento = (e) => {
    fileDocumentRef.current.click();
  };
  const openExplorerComprobante = (e) => {
    fileComprobanteRef.current.click();
  };
  const openExplorerSelfiePrestamo = (e) => {
    fileSelfiePresRef.current.click();
  };
  const loadSelfieSolicitud = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientoPrestamo.length > 0) {
      let filename = getImageName(requerimientoPrestamo[0].selfiePrestamo);
      // Delete the file si existe
      const storageRef = app.storage().ref(`${filename}`);
      storageRef
        .delete()
        .then(() => {
          uploadPrestamo(file);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      uploadPrestamo(file);
    }
  };
  const loadSelfie = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientosUsuarios.length > 0) {
      if (requerimientosUsuarios[0].selfieCedula === '') {
        upload(file, 'selfieCedula');
      } else {
        let filename = getImageName(requerimientosUsuarios[0].selfieCedula);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'selfieCedula');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'selfieCedula');
    }
  };
  const loadDocument = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientosUsuarios.length > 0) {
      if (requerimientosUsuarios[0].documento === '') {
        upload(file, 'documento');
      } else {
        let filename = getImageName(requerimientosUsuarios[0].documento);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'documento');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'documento');
    }
  };
  const loadComprobante = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientosUsuarios.length > 0) {
      if (requerimientosUsuarios[0].comprobanteTrabajo === '') {
        upload(file, 'comprobanteTrabajo');
      } else {
        let filename = getImageName(
          requerimientosUsuarios[0].comprobanteTrabajo
        );
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'comprobanteTrabajo');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'comprobanteTrabajo');
    }
  };
  const upload = (file, property) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          if (requerimientosUsuarios.length > 0) {
            let data = {
              [property]: url,
              idRequerimiento: requerimientosUsuarios[0].id,
            };
            app
              .firestore()
              .collection('requerimiento')
              .doc(`${requerimientosUsuarios[0].id}`)
              .update(data)
              .then(() => {
                dispatch(getRequerimientosUsuarios(prestamo.idUsuario));
                dispatch({ type: ENDLOADING });
              });
          } else {
            app
              .firestore()
              .collection('requerimiento')
              .add({
                comprobanteTrabajo:
                  property === 'comprobanteTrabajo' ? url : '',
                documento: property === 'documento' ? url : '',
                fechaValidacionComprobanteTrabajo: null,
                fechaValidacionDocumento: null,
                fechaValidacionSelfieCedula: null,
                //fechaValidacionSelfiePrestamo: null,
                idRequerimiento: '',
                idUsuario: prestamo.idUsuario,
                selfieCedula: property === 'selfieCedula' ? url : '',
                //selfiePrestamo: "",
                validadoComprobanteTrabajo: '',
                validadoDocumento: '',
                validadoGestorComprobanteTrabajo: null,
                validadoGestorDocumento: null,
                validadoGestorSelfieCedula: null,
                //validadoGestorSelfiePrestamo: null,
                validadoSelfieCedula: '',
                //validadoSelfiePrestamo: "",
              })
              .then(() => {
                dispatch(getRequerimientosUsuarios(prestamo.idUsuario));
                dispatch({ type: ENDLOADING });
              });
          }
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const uploadPrestamo = (file) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      const textoEnviar = `
        <p>El usuario: ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}</p>
        <p>Email: ${prestamo.usuario.email}</p>

        <p>Ha subido el último requerimiento para la solicitud de su préstamo.</p> 
      `;
      const para = administrativos.map((admin) => admin.email);
      const emailGestor = {
        asunto: 'Requerimientos',
        email: para,
        motivo: `<span>${textoEnviar}</span>`,
      };
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          if (requerimientoPrestamo.length > 0) {
            let data = {
              selfiePrestamo: url,
              idRequerimiento: requerimientoPrestamo[0].id,
            };
            app
              .firestore()
              .collection('requerimientoPrestamo')
              .doc(`${requerimientoPrestamo[0].id}`)
              .update(data)
              .then(() => {
                dispatch(getRequerimientoPrestamo(prestamo.id));
                dispatch({ type: ENDLOADING });
                enviarNotificacion(emailGestor);
                enviarMesaje(textoEnviar, 'Usuario');
              });
          } else {
            app
              .firestore()
              .collection('requerimientoPrestamo')
              .add({
                idPrestamo: prestamo.id,
                idRequerimiento: '',
                idUsuario: prestamo.idUsuario,
                selfiePrestamo: url,
                validadoGestorSelfiePrestamo: null,
                validadoSelfiePrestamo: '',
              })
              .then(() => {
                dispatch(getRequerimientoPrestamo(prestamo.id));
                dispatch({ type: ENDLOADING });
                enviarNotificacion(emailGestor);
                enviarMesaje(textoEnviar, 'Usuario');
              });
          }
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const uploadAbono = (file) => {
    setFilename(file.name);
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app
        .storage()
        .ref(`abonos/${prestamo.id}-${misabonos.length + 1}`);
      setRefFile(storageRef);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          // let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          app
            .storage()
            .ref(`abonos/${prestamo.id}-${misabonos.length + 1}`)
            .getDownloadURL()
            .then(async (url) => {
              setPago({ ...pago, ['voucher']: url });
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };
  const getImageName = (url) => {
    let primera = url.split('/o/')[1];
    return primera.split('?')[0];
  };
  const verificarImagenesDocumento = (event) => {
    const { value } = event.target;
    let arrayRequerimiento = [...requerimientosUsuarios];
    arrayRequerimiento[0].validadoDocumento = value;
    arrayRequerimiento[0].validadoGestorDocumento = myUser;
    dispatch({
      type: GET_REQUERIMIENTOSUSUARIOS,
      payload: arrayRequerimiento,
    });
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    let mensajeNotificacion = {};
    let mensajeChat = '';
    let registroAprobar = {};
    let mensajeCustom = '';
    let tipo = '';
    let bloqueado = false;
    if (value === 'aprobado') {
      mensajeChat =
        'Le informamos que su Documento de Identidad ha sido aprobado.';
      registroAprobar = {
        accion: `${'El Documento: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Aprobado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'Documento de Identidad aprobado';
      tipo = 'success';
    } else if (value === 'Imagen No Nitida') {
      mensajeNotificacion = {
        asunto: 'Notificación sobre requisitos de préstamo',
        email: `${para}`,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>Su Documento de Identidad ha sido Rechazado, por presentar una imagen poco nítida para su compresion y lectura. Por favor mejorar la imagen para seguir con el proceso.</b></p>
        `,
      };
      mensajeChat =
        'Le informamos que su Documento de Identidad ha sido Rechazado por estar muy difusa o poca nítida. Por favor mejorar la imagen para seguir con el proceso.';
      registroAprobar = {
        accion: `${'El Documento: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'Documento de Identidad rechazado';
      tipo = 'warning';
    } else if (value === 'No Coinciden los Datos') {
      mensajeNotificacion = {
        asunto: 'Notificación sobre requisitos de préstamo',
        email: `${para}`,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>Su Documento de Identidad ha sido Rechazado, por presentar inconsistencias con los demás datos suministrados por usted en el formulario. deberá ingresar documento valido para seguir con el proceso.</b></p>
        `,
      };
      mensajeChat =
        'Le informamos que su Documento de Identidad ha sido Rechazado por presentar inconsistencias con los demás datos suministrados por usted en el formulario. debera ingresar documento valido para seguir con el proceso.';
      registroAprobar = {
        accion: `${'El Documento: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'Documento de Identidad rechazado';
      tipo = 'warning';
    } else if (value === 'Intento de Estafa') {
      bloqueado = true;
      if (bloqueado) {
        const respuestaConfirm = confirm(
          'Seguro que Desea Bloquear el Usuario por intento de estafa.',
          'question'
        );
        respuestaConfirm.then((resp) => {
          if (resp) {
            app
              .firestore()
              .collection('usuarios')
              .doc(`${prestamo.idUsuario}`)
              .update({ rol: 'Bloqueado' });
          }
          mensajeNotificacion = {
            asunto: 'Notificación sobre requisitos de préstamo',
            email: `${para}`,
            motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
          <p><b>Su Documento de Identidad ha sido Rechazado, por presentar  problema serios de fiabilidad con los datos suministrados por usted. Por este intento de Estafa tomaremos medidas de bloqueo y su IP y ubicacion seran almacenadas.</b></p>
          `,
          };
          mensajeChat =
            'Le informamos que su Documento de Identidad ha sido Rechazado por presentar problema serios de fiabilidad con los datos suministrados por usted. Por este intento de Estafa tomaremos medidas de bloqueo y su IP y ubicacion seran almacenadas.';
        });
      }
      registroAprobar = {
        accion: `${'El Documento: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
    }

    app
      .firestore()
      .collection('requerimiento')
      .doc(`${requerimientosUsuarios[0].id}`)
      .update({
        fechaValidacionDocumento: moment(new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        idGestor: app.auth().currentUser.uid,
        idRequerimiento: requerimientosUsuarios[0].idRequerimiento,
        validadoDocumento: event.target.value,
        validadoGestorDocumento: myUser,
      })
      .then(() => {
        dispatch(
          getRequerimientosUsuarios(requerimientosUsuarios[0].idUsuario)
        );
      });
    app
      .firestore()
      .collection('prestamos')
      .doc(`${userid}`)
      .update({
        idGestor: myUser.idUsuario,
        gestor: myUser,
      })
      .then(() => {
        dispatch(getPrestamo(userid));
      });
    enviarNotificacion(mensajeNotificacion);
    enviarMesaje(mensajeChat, 'Sistema');
    custom(mensajeCustom, tipo);
    dispatch(addLog(registroAprobar));
  };
  const verificarImagenesComprobanteTrabajo = (event) => {
    const { value } = event.target;
    let arrayRequerimiento = [...requerimientosUsuarios];
    arrayRequerimiento[0].validadoComprobanteTrabajo = value;
    arrayRequerimiento[0].validadoGestorComprobanteTrabajo = myUser;
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    let mensajeNotificacion = {};
    let mensajeChat = '';
    let registroAprobar = {};
    let mensajeCustom = '';
    let tipo = '';
    let bloqueado = false;
    dispatch({
      type: GET_REQUERIMIENTOSUSUARIOS,
      payload: arrayRequerimiento,
    });
    if (value === 'aprobado') {
      mensajeChat =
        'Le informamos que su Comprobante de Trabajo ha sido aprobado.';
      registroAprobar = {
        accion: `${'El ComprobanteTrabajo: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Aprobado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'Comprobante de Trabajo aprobado';
      tipo = 'success';
    } else if (value === 'Imagen No Nitida') {
      mensajeNotificacion = {
        asunto: 'Notificación sobre requisitos de préstamo',
        email: `${para}`,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>Su Comprobante de Trabajo ha sido Rechazado, por presentar una imagen poco nítidad para su compresion y lectura. Por favor mejorar la imagen para seguir con el proceso.</b></p>
        `,
      };
      mensajeChat =
        'Le informamos que su Comprobante de Trabajo ha sido Rechazado por estar muy difusa o poca nítida. Por favor mejorar la imagen para seguir con el proceso.';
      registroAprobar = {
        accion: `${'El ComprobanteTrabajo: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'Comprobante de Trabajo rechazado';
      tipo = 'warning';
    } else if (value === 'No Coinciden los Datos') {
      mensajeNotificacion = {
        asunto: 'Notificación sobre requisitos de préstamo',
        email: `${para}`,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>Su Comprobante de Trabajo ha sido Rechazado, por presentar inconsistencias con los demás datos suministrados por usted en el formulario. deberá ingresar documento valido para seguir con el proceso.</b></p>
        `,
      };
      mensajeChat =
        'Le informamos que su Comprobante de Trabajo ha sido Rechazado por presentar inconsistencias con los demás datos suministrados por usted en el formulario. debera ingresar documento valido para seguir con el proceso.';
      registroAprobar = {
        accion: `${'El ComprobanteTrabajo: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'Comprobante de Trabajo rechazado';
      tipo = 'warning';
    } else if (value === 'Intento de Estafa') {
      bloqueado = true;
      if (bloqueado) {
        const respuestaConfirm = confirm(
          'Seguro que Desea Bloquear el Usuario por intento de estafa.',
          'question'
        );
        respuestaConfirm.then((resp) => {
          if (resp) {
            app
              .firestore()
              .collection('usuarios')
              .doc(`${prestamo.idUsuario}`)
              .update({ rol: 'Bloqueado' });
          }
          mensajeNotificacion = {
            asunto: 'Notificación sobre requisitos de préstamo',
            email: `${para}`,
            motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>Su Comprobante de Trabajo ha sido Rechazado, por presentar problema serios de fiabilidad con los datos suministrados por usted. Por este intento de Estafa tomaremos medidas de bloqueo y su IP y ubicacion seran almacenadas.</b></p>
        `,
          };
          mensajeChat =
            'Le informamos que su Comprobante de Trabajo ha sido Rechazado por presentar problema serios de fiabilidad con los datos suministrados por usted. Por este intento de Estafa tomaremos medidas de bloqueo y su IP y ubicacion seran almacenadas.';
        });
      }
      registroAprobar = {
        accion: `${'El ComprobanteTrabajo: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazado.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
    }
    app
      .firestore()
      .collection('requerimiento')
      .doc(`${requerimientosUsuarios[0].id}`)
      .update({
        fechaValidacionComprobanteTrabajo: moment(new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        idRequerimiento: requerimientosUsuarios[0].idRequerimiento,
        validadoComprobanteTrabajo: event.target.value,
        validadoGestorComprobanteTrabajo: myUser,
      })
      .then(() => {
        dispatch(
          getRequerimientosUsuarios(requerimientosUsuarios[0].idUsuario)
        );
      });
    app
      .firestore()
      .collection('prestamos')
      .doc(`${userid}`)
      .update({
        idGestor: myUser.idUsuario,
        gestor: myUser,
      })
      .then(() => {
        dispatch(getPrestamo(userid));
      });
    enviarNotificacion(mensajeNotificacion);
    enviarMesaje(mensajeChat, 'Sistema');
    custom(mensajeCustom, tipo);
    dispatch(addLog(registroAprobar));
  };
  const verificarImagenesSelfieCedula = (event) => {
    const { value } = event.target;
    let arrayRequerimiento = [...requerimientosUsuarios];
    arrayRequerimiento[0].validadoSelfieCedula = value;
    arrayRequerimiento[0].validadoGestorSelfieCedula = myUser;
    dispatch({
      type: GET_REQUERIMIENTOSUSUARIOS,
      payload: arrayRequerimiento,
    });
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    let mensajeNotificacion = {};
    let mensajeChat = '';
    let registroAprobar = {};
    let mensajeCustom = '';
    let tipo = '';
    let bloqueado = false;
    if (value === 'aprobado') {
      mensajeChat =
        'Le informamos que La Selfie con su Cedula ha sido aprobada.';
      registroAprobar = {
        accion: `${'La SelfieCedula: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Aprobada.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'La Selfie con su Cedula aprobada';
      tipo = 'success';
    } else if (value === 'Imagen No Nitida') {
      mensajeNotificacion = {
        asunto: 'Notificación sobre requisitos de préstamo',
        email: `${para}`,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>La Selfie con su Cedula ha sido Rechazada, por presentar una imagen poco nítidad para su compresion y lectura. Por favor mejorar la imagen para seguir con el proceso.</b></p>
        `,
      };
      mensajeChat =
        'Le informamos que La Selfie con su Cedula ha sido Rechazada por estar muy difusa o poca nítida. Por favor mejorar la imagen para seguir con el proceso.';
      registroAprobar = {
        accion: `${'La SelfieCedula: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazada.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'La Selfie con su Cedula fue rechazada';
      tipo = 'warning';
    } else if (value === 'No Coinciden los Datos') {
      mensajeNotificacion = {
        asunto: 'Notificación sobre requisitos de préstamo',
        email: `${para}`,
        motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>La Selfie con su Cedula ha sido Rechazada, por presentar inconsistencias con los demás datos suministrados por usted en el formulario. deberá ingresar documento valido para seguir con el proceso.</b></p>
        `,
      };
      mensajeChat =
        'Le informamos que su Comprobante de Trabajo ha sido Rechazada por presentar inconsistencias con los demás datos suministrados por usted en el formulario. debera ingresar documento valido para seguir con el proceso.';
      registroAprobar = {
        accion: `${'La SelfieCedula: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazada.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
      mensajeCustom = 'La Selfie con su Cedula fue rechazada';
      tipo = 'warning';
    } else if (value === 'Intento de Estafa') {
      bloqueado = true;
      if (bloqueado) {
        const respuestaConfirm = confirm(
          'Seguro que Desea Bloquear el Usuario por intento de estafa.',
          'question'
        );
        respuestaConfirm.then((resp) => {
          if (resp) {
            app
              .firestore()
              .collection('usuarios')
              .doc(`${prestamo.idUsuario}`)
              .update({ rol: 'Bloqueado' });
          }
          mensajeNotificacion = {
            asunto: 'Notificación sobre requisitos de préstamo',
            email: `${para}`,
            motivo: `<p>Estimado/a, <b>${usuario}</b>,</p>
        <p><b>La Selfie con su Cedula ha sido Rechazada, por presentar problema serios de fiabilidad con los datos suministrados por usted. Por este intento de Estafa tomaremos medidas de bloqueo y su IP y ubicacion seran almacenadas.</b></p>
        `,
          };
          mensajeChat =
            'Le informamos que La Selfie con su Cedula ha sido Rechazada por presentar problema serios de fiabilidad con los datos suministrados por usted. Por este intento de Estafa tomaremos medidas de bloqueo y su IP y ubicacion seran almacenadas.';
        });
      }
      registroAprobar = {
        accion: `${'La SelfieCedula: '} ${
          requerimientosUsuarios[0].documento
        } , ${'del Prestamo del usuario: '} ${
          prestamo.usuario.nombres
        } ${'Ha Sido Rechazada.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      };
    }
    app
      .firestore()
      .collection('requerimiento')
      .doc(`${requerimientosUsuarios[0].id}`)
      .update({
        fechaValidacionSelfieCedula: moment(new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        idRequerimiento: requerimientosUsuarios[0].idRequerimiento,
        validadoSelfieCedula: event.target.value,
        validadoGestorSelfieCedula: myUser,
      })
      .then(() => {
        dispatch(
          getRequerimientosUsuarios(requerimientosUsuarios[0].idUsuario)
        );
      });
    app
      .firestore()
      .collection('prestamos')
      .doc(`${userid}`)
      .update({
        idGestor: myUser.idUsuario,
        gestor: myUser,
      })
      .then(() => {
        dispatch(getPrestamo(userid));
      });
    enviarNotificacion(mensajeNotificacion);
    enviarMesaje(mensajeChat, 'Sistema');
    custom(mensajeCustom, tipo);
    dispatch(addLog(registroAprobar));
  };
  const verificarImagenesPrestamo = (event) => {
    let arrayRequerimiento = [...requerimientoPrestamo];
    arrayRequerimiento[0].validadoSelfiePrestamo = event.target.value;
    dispatch({
      type: GET_REQUERIMIENTOPRESTAMO,
      payload: requerimientoPrestamo,
    });
    app
      .firestore()
      .collection('requerimientoPrestamo')
      .doc(`${requerimientoPrestamo[0].id}`)
      .update({
        fechaValidacionSelfiePrestamo: moment(new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        idRequerimiento: requerimientoPrestamo[0].idRequerimiento,
        validadoSelfiePrestamo: event.target.value,
        validadoGestorSelfiePrestamo: myUser,
      })
      .then(() => {
        dispatch(getRequerimientoPrestamo(requerimientoPrestamo[0].idPrestamo));
      });
    app
      .firestore()
      .collection('prestamos')
      .doc(`${userid}`)
      .update({
        fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
        idGestor: myUser.idUsuario,
        gestor: myUser,
      })
      .then(() => {
        dispatch(getPrestamo(userid));
      });
    if (event.target.value === 'aprobado') {
      dispatch(
        addLog({
          accion: `${'La SelfiePrestamo: '} ${
            requerimientoPrestamo[0].documento
          } ${'del Prestamo de: '} ${
            prestamo.usuario.nombres
          } ${'Ha Sido Aprobabo'}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
    } else {
      dispatch(
        addLog({
          accion: `${'La SelfiePrestamo: '} ${
            requerimientoPrestamo[0].documento
          } ${'del Prestamo de: '} ${
            prestamo.usuario.nombres
          } ${'Ha Sido Rechazado'}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
    }
  };

  //========== Contenido Tabla Contactos Sociales ==========//
  const columns = [
    {
      field: 'nombres',
      headerName: 'NOMBRES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 160,
      renderCell: (params) => (
        <span className='m-auto'>{params.row.nombres}</span>
      ),
    },
    {
      field: 'parentesco',
      headerName: 'PARENTESCO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 170,
      renderCell: (params) => (
        <span className='m-auto'>{params.row.parentesco}</span>
      ),
    },
    {
      field: 'url',
      headerName: 'URL',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 250,
    },
    {
      field: 'tipo',
      headerName: 'TIPO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 170,
      renderCell: (params) => <span className='m-auto'>{params.row.tipo}</span>,
    },
    {
      field: 'admin',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 210,
      renderCell: (params, row, idUsuario) => (
        <div className='flex justify-center items-center font-medium'>
          <a
            className='flex items-center mr-3 text-theme-1'
            href='javascript:void(0);'
            onClick={(e) => editContactoSociales(params.row)}
          >
            <Icon.Edit className='w-4 h-4 mr-1' />
            Editar
          </a>
          <a
            className='flex items-center text-theme-6'
            href='javascript:void(0);'
            tipo='contacto'
            key={'contacto' + idUsuario}
            data-toggle='modal'
            data-target='#delete-confirmation-modal'
            onClick={(e) => eliminarContactoSociales(params.row)}
          >
            <Icon.Trash2 className='w-4 h-4 mr-1 ml-3' />
            Borrar
          </a>
        </div>
      ),
    },
  ];

  //========== Funciones y Metodos de la Tabla Conctactos Sociales ==========//
  const useStyless = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classess = useStyless();
  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  //========== Funciones y Metodos de CSV Cargar contactos sociales ==========//
  const toParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };
  const handleError = (e) => {
    console.log(e);
  };
  const readCSV = (e) => {
    const fileinput = document.getElementById('fileempresas');
    fileinput.click();
  };
  const buildType = (str) => {
    if (str.includes('facebook') || str.includes('fb')) {
      return 'facebook';
    } else if (str.includes('instagram')) {
      return 'instagram';
    } else {
      return '';
    }
  };
  const handleForce = async (data, fileInfo) => {
    dispatch({ type: LOADING });
    let errorsCount = 0; //contar errores
    for (let index = 0; index < data.length; index++) {
      const value = data[index]; //objeto que continen la fila del csc recordar que tinen varias propiedades
      if (value.nombres === '' || value.url === '') {
        console.log('las carga de los datos esta incompleta');
        console.log(value);
        errorsCount++;
      } else {
        //armar el objeto como se tinene que guardar
        const esta = contactosSociales.filter(
          (fila) => fila.url.trim() === value.url.trim()
        );
        // console.log('filter',  esta)
        if (esta.length > 0) {
          errorsCount++;
          // console.log('si esta')
        } else {
          // console.log('no esta')
          try {
            await app
              .firestore()
              .collection('contactosSociales')
              .add({
                idUsuario: prestamo.usuario.idUsuario,
                nombres: value.nombres,
                parentesco: '',
                tipo: buildType(value.url),
                url: value.url,
              }); //creo un usuario
          } catch (err) {
            errorsCount++;
          }
        }
      }
    }
    // setLoading(true); deberias ocultar el loading esto es un ejemplo nosotros lo hacemos mediante un dispatch
    if (errorsCount === 0) {
      console.log('no hubieron errores');
    } else {
      console.log(`hubieron ${errorsCount} erroes`);
    }
    dispatch(getContactoSociales(prestamo.idUsuario));
    dispatch({ type: ENDLOADING });
  };

  //========== Funciones Modal Crear Contactos ==========//
  const nuevoContactoSocial = () => {
    setContactos({
      nombres: '',
      parentesco: '',
      url: '',
      tipo: buildType(Contactos.url),
      idUsuario: prestamo.idUsuario,
    });
    dispatch(modalCrearContacto(true));
  };
  const changeValueCrearContactos = (e) => {
    const { name, value } = e.target;
    setContactos({ ...Contactos, [name]: value });
  };
  const closeCrearContacto = () => {
    dispatch(modalCrearContacto(false));
  };
  const cancelCrearContacto = () => {
    dispatch(modalCrearContacto(false));
  };
  const guardarCrearContactos = () => {
    if (Contactos.nombres === '' || Contactos.url === '') {
      custom('Debe llenar todos los datos', 'error');
    } else if (!isURL(Contactos.url)) {
      custom('Ingresa una url valida', 'error');
    } else {
      let dataEnviar = { ...Contactos, ['tipo']: buildType(Contactos.url) };

      dispatch(saveCrearContactoSociales(dataEnviar, prestamo.idUsuario));
    }
    dispatch(modalCrearContacto(false));
    success('Contacto Creado Exitosamente.', 'success');
    dispatch(
      addLog({
        accion: `${'Creado Nuevo Contacto: '} ${
          Contactos.url
        } ${'del Prestamo de: '} ${prestamo.usuario.nombres} `,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };

  //========== Funciones Modal Editar Contactos ==========//
  const changeValueEditarContactos = (e) => {
    const { name, value } = e.target;
    setContactos({ ...Contactos, [name]: value });
  };
  const closeEditarContactos = () => {
    dispatch(modalEditarContacto(false));
  };
  const cancelEditarContactos = () => {
    dispatch(modalEditarContacto(false));
  };
  const guardarEditarContactos = () => {
    if (Contactos.nombres === '' || Contactos.url === '') {
      custom('Debe llenar todos los datos', 'error');
    } else if (!isURL(Contactos.url)) {
      custom('Ingresa una url valida', 'error');
    } else {
      dispatch(updateContactoSociales(Contactos));
    }
    dispatch(modalEditarContacto(false));
    success('Contacto Actualizado Exitosamente.', 'success');
    dispatch(
      addLog({
        accion: `${'Editado Contacto Social: '} ${Contactos.url}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const editContactoSociales = (item) => {
    setEditarContactos(true);
    setContactos(item);
    dispatch(modalEditarContacto(true));
  };
  const eliminarContactoSociales = async (item) => {
    const resp = await confirm(
      'Seguro que Desea Eliminar el Contacto.',
      'question'
    );
    if (resp) {
      dispatch(deleteContactoSociales(item));
      dispatch(
        addLog({
          accion: `${'Eliminado Contacto Social: '} ${Contactos.url}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      success('Contacto Eliminado Exitosamente.', 'success');
    }
  };

  //========== Funciones de Envio Correo y Mensaje ==========//
  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };
  const enviarMesaje = (mensaje, rol) => {
    let mensajesArray = [];
    if (chat) {
      let newChat = {
        ...chat,
        ['fechaActualizacion']: moment(new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        ['idChat']: chat.id,
      };
      mensajesArray = [...chat.mensajes];
      mensajesArray.push({
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        rol,
        texto: mensaje,
      });
      dispatch(sendMsg(newChat, mensajesArray));
    } else {
      let chat = {
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        idChat: '',
        idPrestamo: userid,
        mensajes: [
          {
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            rol,
            texto: mensaje,
          },
        ],
      };
      dispatch(addChat(chat));
    }
  };
  const enviarVariosMensajes = (mensajes, rol) => {
    let mensajesViejos = [];
    let mensajesNuevos = [];
    let newChat = {
      ...chat,
      ['fechaActualizacion']: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    };
    mensajesViejos = [...chat.mensajes];
    mensajes.forEach((items) => {
      mensajesNuevos.push({
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        rol: items.rol,
        texto: items.texto,
      });
    });
    let mensajesTodos = mensajesViejos.concat(mensajesNuevos);
    dispatch(sendMsg(newChat, mensajesTodos));
  };

  //========== Funciones Modal Envio Correo Mensaje Rechazo ==========//
  const envioCorreoMensajeRechazo = () => {
    setEnvioCorreoMensaje({
      mensajeCorreo: '',
      idUsuario: prestamo.idUsuario,
    });
    dispatch(modalEnvioCorreoMensaje(true));
  };
  const changeValueEnvioCorreoMensaje = (e) => {
    const { name, value } = e.target;
    setEnvioCorreoMensaje({ ...envioCorreoMensaje, [name]: value });
  };
  const closeEnvioCorreoMensaje = () => {
    dispatch(modalEnvioCorreoMensaje(false));
  };
  const cancelEnvioCorreoMensaje = () => {
    dispatch(modalEnvioCorreoMensaje(false));
  };
  const calcularInteresesPorAbonos = () => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    abonosRealizados.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamo.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamo.montoPrestado,
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  //========== Funciones de Rechazar Solicitud Préstamo ==========//
  const rechazarSolicitudPrestamo = (event) => {
    dispatch(
      rechazarPrestamo(prestamo.id, { aprobado: false, estado: 'rechazado' })
    );
    dispatch(
      addLog({
        accion: `${'El Prestamo de: '} ${prestamo.usuario.nombres} ${
          prestamo.usuario.apellidos
        } ${'Ha Sido Rechazado'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
    let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
    let para = `${prestamo.usuario.email}`;
    const data = {
      asunto: 'Préstamo no aprobado',
      email: para,
      motivo: `<p>Estimado/a, <b>${usuario}</b>,</p> 
        <p><b>${envioCorreoMensaje.mensajeCorreo}.</b></p>
        `,
    };
    enviarNotificacion(data);
    enviarMesaje(
      `PRESTAMO NO APROBADO : ${envioCorreoMensaje.mensajeCorreo}`,
      'Sistema'
    );
    dispatch(modalCorreoRechazoSolicitud(false));
  };
  const envioCorreoRechazoSolicitud = () => {
    setEnvioCorreoMensaje({
      mensajeCorreo: '',
      idUsuario: prestamo.idUsuario,
    });
    dispatch(modalCorreoRechazoSolicitud(true));
  };
  const closeEnvioCorreoRechazoSolicitud = () => {
    dispatch(modalCorreoRechazoSolicitud(false));
  };
  const cancelEnvioCorreoRechazoSolicitud = () => {
    dispatch(modalCorreoRechazoSolicitud(false));
  };
  const rechazarSolicitud = async () => {
    const resp = await confirm(
      '¿Seguro desea rechazar el préstamo?',
      'question'
    );
    if (resp) {
      envioCorreoRechazoSolicitud();
      dispatch(
        addLog({
          accion: `${'Se ha Rechazado Solicitud del Préstamo'} ${
            prestamo.usuario.nombres
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
    }
  };

  //========== Funciones Reportar Pago ==========//
  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Bloqueado'])
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
        setTimeout(() => {
          // console.log(prestamoActual)
        }, 1000);
      });
  };
  const interesesReportePago = (abonosRecibido) => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    let arrayRecorrer = [];
    if (abonosRecibido) {
      arrayRecorrer = abonosRecibido;
    } else {
      arrayRecorrer = misabonos;
    }
    arrayRecorrer.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamo.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamo.montoPrestado,
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            prestamo.usuario.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : prestamo.usuario.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };
  const totalRestante = () => {
    let total = 0;
    misabonos.forEach((fila) => {
      total += fila.monto;
    });
    if (misabonos.length > 0) {
      return (montoHoy() - total).toFixed(2);
    }
    return montoHoy().toFixed(2);
  };
  const totalRestanteAbonos = () => {
    let total = 0;
    //total = (calcularInteresesPorAbonos()['interesHoy'] - totalAbonadoAbonos());
    total = calcularInteresesPorAbonos()['interesHoy'];
    return total;
  };
  const HandleChange = (e) => {
    const { name, value } = e.target;
    setPago({ ...pago, [name]: value });
  };
  const abonar = () => {
    setPago({
      ...pago,
      ['nombres']: prestamo.usuario.nombres,
      ['apellidos']: prestamo.usuario.apellidos,
      ['monto']:
        misabonos.length === 0 ? totalRestante() : totalRestanteAbonos(),
    });
    dispatch({ type: MODALABONO, payload: true });
    dispatch(
      addLog({
        accion: `${'El Usuario: '} ${
          prestamo.usuario.email
        } ${','} ${'ha Reportado un Abono de Su Prestamo'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const cancelAbono = () => {
    if (refFile) {
      refFile
        .delete()
        .then(() => {
          setRefFile(null);
          setFilename('');
        })
        .catch((err) => console.log(err));
    }
    dispatch({ type: MODALABONO, payload: false });
  };
  const guardarAbono = () => {
    if (
      pago.nombres === '' ||
      pago.apellidos === '' ||
      pago.via === '' ||
      pago.monto === '' ||
      pago.concepto === '' ||
      pago.fecha === '' ||
      pago.idTransaccion === '' ||
      pago.voucher === ''
    ) {
      custom('Debe llenar todos los datos', 'error');
    } else {
      if (misabonos.length === 0) {
        //si no tiene abonos
        if (parseFloat(pago.monto) > parseFloat(totalRestante())) {
          //verifica si se ingresa un monto mayor a la deuda sin abonos
          custom('El monto ingresado es mayor a la deuda total.', 'warning');
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: userid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        } else {
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: userid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        }
      } else {
        // si tiene abonos
        if (parseFloat(pago.monto) > parseFloat(totalRestanteAbonos())) {
          //verifica si se ingresa un monto mayor a la deuda con abonos
          custom('El monto ingresado es mayor a la deuda total.', 'warning');
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: userid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        } else {
          let data = {
            nombres: pago.nombres,
            apellidos: pago.apellidos,
            fecha: moment(pago.fecha).format('YYYY-MM-DD'),
            idPrestamo: userid,
            idUsuario: prestamo.idUsuario,
            via: pago.via,
            concepto: pago.concepto,
            voucher: pago.voucher,
            monto: parseFloat(pago.monto),
            idTransaccion: pago.idTransaccion,
            confirmado: pago.confirmado,
          };
          agregarAbono(data, prestamo);
        }
      }
      dispatch(
        aprobarPrestamo(prestamo.id, {
          estado: 'Abonado',
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
        })
      );
    }
  };
  const agregarAbono = async (pagoAbono, prestamo) => {
    let mensajesPorEnviar = [];
    try {
      await app.firestore().collection('abonos').add(pagoAbono);
      const abonos = await app
        .firestore()
        .collection('abonos')
        .where('idPrestamo', '==', `${pagoAbono.idPrestamo}`)
        .orderBy('fecha', 'asc')
        .get();
      let array = [];
      let objeto = {};
      abonos.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETABONOS, payload: array });
      dispatch(getAbonosRealizados(userid));
      const debe = interesesReportePago(array)['interesHoy'];
      let fechaDebePagar = moment(prestamo.fechaCompromisoPago).format(
        'DD/MM/YYYY'
      );
      if (hoy >= fechaDebePagar) {
        if (debe > 0) {
          setQuedoDebiendo(true);
        }
      } else {
        console.log('todo bien');
      }
      let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;

      //ENVIAR CORREO A LOS ADMINISTRADORES //
      const data = {
        asunto: 'Notificacion Reporte de Pago ó Abono',
        email: administrativos.map((admin) => admin.email),
        motivo: `
        <p><b>Se Ha reportado un Pago/Abono.</b></p>
        <p>Usuario: <b>${usuario}</b></p>
        <p>Correo: <b>${prestamo.usuario.email}</b></p>
        <p>Pais: <b>${prestamo.usuario.pais}</b></p>
        <p>Prestamo: <b>${prestamo.id}</b></p>
        <p>Monto Solicitado: <b>${prestamo.montoPrestado}</b></p>
        <p>Fecha de Pago: <b>${pagoAbono.fecha}</b></p>
        <p>Monto Pagado: <b>${pagoAbono.monto}</b></p>
        <p>Número Comprobante: <b>${pagoAbono.idTransaccion}</b></p>
        <p>Voucher Comprobante: <b>${pagoAbono.voucher}</b></p>
        <p>Deuda restante: <b>${debe}</b></p>
      `,
      };
      enviarNotificacion(data);

      mensajesPorEnviar.push({
        texto: `El usuario, ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}, reporta un abono de ${pagoAbono.monto} $ con el número de comprobante ${pagoAbono.idTransaccion}.
      Por favor aguarde la confirmación de la recepción del pago para la actualización del estado de su deuda. Manténgase alerta en su correo electrónico o whatsapp.`,
        rol: 'usuario',
      });
      mensajesPorEnviar.push({ texto: pagoAbono.voucher, rol: 'Usuario' });
      if (debe === 0) {
        mensajesPorEnviar.push({
          texto: `GRACIAS por pagar la totalidad de tu deuda, desde Chamopresta.com estamos muy contentos por haber logrado cerrar este préstamo con éxito, gracias ${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}.`,
          rol: 'Sistema',
        });
      }
      enviarVariosMensajes(mensajesPorEnviar.reverse());
      dispatch({ type: MODALABONO, payload: false });
    } catch (err) {
      custom('Error al intentar agregar el Abono', 'error');
    }
  };
  const changeMontoType = (e) => {
    let value = e.target.value;
    setMontoPagar(value);
    if (value === 'todo') {
      setDisabled(true);
      setPago({
        ...pago,
        ['monto']:
          misabonos.length === 0 ? totalRestante() : totalRestanteAbonos(),
      });
    } else {
      setPago({
        ...pago,
        ['monto']: '',
      });
      setDisabled(false);
    }
  };
  const openExplorerPago = (e, item) => {
    setAbono(item);
    fileRef.current.click();
  };
  const removeRecibo = () => {
    setFilename('');
    setPago({ ...pago, ['recibo']: '' });
    refFile.delete().then(() => {
      setRefFile(null);
    });
  };
  const loadFileAbono = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    uploadAbono(file);
  };
  const viewAlert = (show) => {
    setShowAlert(show);
  };

  const descargarImagen = () => {
    console.log('Desacrgandoooo');
  };
  const HandleChangeAct = (e) => {
    const { name, value } = e.target;
    setPrestamoAct({ ...prestamoAct, [name]: value });
  };
  const guardarActualizacion = (e) => {
    e.preventDefault();
    //==== Nuevo monto a pagar ====//
    let dif = 0;
    let interes = 0;
    dif = restaFechas(prestamoAct.fechaInicio, prestamoAct.fechaCompromisoPago);
    interes = calculateInteres(
      prestamoAct.montoPrestado,
      dif,
      parseFloat(
        prestamoAct.usuario.habilitadoNuevoPrestamo === 'No'
          ? prestamo.tasaInteresCompuesto
          : prestamoAct.tasaInteresCompuesto
      )
    );
    let data = {
      ...prestamoAct.usuario,
      nuevaTasaInteres: prestamoAct.tasaInteresCompuesto,
    };
    let dataPrestamo = {
      ...prestamoAct,
      montoCompromisoPago: interes,
      usuario: data,
    };
    viewAlert(true);
    dispatch({ type: GET_PRESTAMO, payload: dataPrestamo });
    dispatch(updateDatosPrestamo(dataPrestamo, false));
    setTimeout(() => {
      viewAlert(false);
    }, 2500);
  };

  //===== Funciones Editar Redes =====//
  const changeSocial = (e) => {
    const { name, value } = e.target;
    setSocial({ ...social, [name]: value });
    // dispatch({ type: DATOSGENERELES, payload: generales });
  };
  const addSocial = () => {
    let data = social;
    data.tipo = buildType(data.url);
    app
      .firestore()
      .collection('sociales')
      .add(data)
      .then((resp) => {
        dispatch(getSociales());
        setSocial({
          fechaValidacion: null,
          gestor: null,
          id: '',
          idGestor: null,
          idSocial: '',
          idUsuario: app.auth().currentUser.uid,
          solicitudConfirmada: false,
          solicitudEnviada: false,
          tipo: '',
          url: '',
          validado: '',
        });
        setEditarSocial(false);
      });
  };
  const updateSocial = () => {
    let data = social;
    data.tipo = buildType(data.url);
    app
      .firestore()
      .collection('sociales')
      .doc(`${social.id}`)
      .update(data)
      .then((resp) => {
        dispatch(getSociales());
        setSocial({
          fechaValidacion: null,
          gestor: null,
          id: '',
          idGestor: null,
          idSocial: '',
          idUsuario: app.auth().currentUser.uid,
          solicitudConfirmada: false,
          solicitudEnviada: false,
          tipo: '',
          url: '',
          validado: '',
        });
        setEditarSocial(false);
      });
  };
  const editarLinkSocial = (item) => {
    setSocial(item);
    setEditarSocial(true);
  };
  const cancel = () => {
    setSocial({
      fechaValidacion: null,
      idGestor: null,
      id: '',
      idSocial: '',
      idUsuario: app.auth().currentUser.uid,
      tipo: '',
      url: '',
      validado: '',
    });
    setEditarSocial(false);
  };
  const editarRedSocial = (e) => {
    e.preventDefault();
    if (editarSocial) {
      if (social.url === '') {
        custom('Debes llenar el campo url', 'error');
      } else {
        updateSocial();
      }
    }
  };
  const eliminarRedes = async (e) => {
    e.preventDefault();
    const resp = await confirm(
      'Seguro que Desea Eliminar la Red Social.',
      'question'
    );
    if (resp) {
      dispatch(deleteSociales(social));
      dispatch(
        addLog({
          accion: `${'Eliminada Red Social: '} ${Contactos.url}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      success('Red Social Eliminada Exitosamente.', 'success');
    }
    setTimeout(() => {
      cancel();
    }, 1800);
  };
  const guardarRedes = (e) => {
    e.preventDefault();
    if (social.url === '') {
      custom('Debes llenar el campo url', 'error');
    } else {
      addSocial();
    }
  };

  return (
    <>
      <h1 className='font-medium text-center text-base mt-4 bg-theme-26 box p-2 border-b-4 mx-auto'>
        Detalle de Prestamo
      </h1>
      <div className='intro-x box bg-theme-27 border-b p-1 w-64 text-center mt-2 ml-auto'>
        <i data-feather='user' className='w-4 h-4 absolute ml-2'></i>
        {prestamo.usuario && (
          <span className='ml-1'>
            {prestamo.usuario.nombres} {prestamo.usuario.apellidos}
          </span>
        )}
      </div>
      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-5'>
        <div className='col-span-12'>
          <div className='intro-y box lg:mt-2'>
            <div className={classes.root}>
              {/*===== Datos del usuario =====*/}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon color='primary' />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography className={classes.heading}>
                    <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                      <DoneAllIcon color='primary' /> ._ Datos del Usuarios
                    </label>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {prestamo.usuario && (
                    <div className='grid grid-cols-12 gap-4 row-gap-5 w-full mb-3 mt-2 p-2 bg-theme-2 box overflow-auto lg:overflow-visible'>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Nombres:
                        </label>
                        <span>
                          {prestamo.usuario.nombres} {''}
                          {prestamo.usuario.apellidos}
                        </span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Cédula:
                        </label>
                        <span>{prestamo.usuario.numeroCedula}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Email:
                        </label>
                        <span>{prestamo.usuario.email}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Pasaporte:
                        </label>
                        <span>{prestamo.usuario.numeroPasaporte}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Sexo:
                        </label>
                        <span>{prestamo.usuario.sexo}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Fecha de nacimiento:
                        </label>
                        <span>{prestamo.usuario.fechaNacimiento}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          País:
                        </label>
                        <span>{prestamo.usuario.pais}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Localidad:
                        </label>
                        <span>{prestamo.usuario.localidad}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Whatsapp:
                        </label>
                        <span>{prestamo.usuario.whatsapp}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Otro número de contacto:
                        </label>
                        <span>{prestamo.usuario.telefonoOpcional}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Usuario telegram:
                        </label>
                        <span>{prestamo.usuario.telegram}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Trabaja:
                        </label>
                        <span>{prestamo.usuario.trabaja}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Area de trabajo:
                        </label>
                        <span>{prestamo.usuario.areaTrabajo}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Nombre Empresa:
                        </label>
                        <span>{prestamo.usuario.nombreEmpresa}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Ingreso mensual:
                        </label>
                        <span>{prestamo.usuario.ingresoMensualUSD} $$</span>
                      </div>
                      <div className='col-span-8 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Metodo Pago:
                        </label>
                        <span>{prestamo.usuario.metodosPagos}</span>
                      </div>
                      <div className='col-span-4 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Fecha registro:
                        </label>
                        <span>{prestamo.usuario.fechaRegistro}</span>
                      </div>
                      <div className='col-span-12 ml-10'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          Descripción actividad:
                        </label>
                        <span>{prestamo.usuario.descripcionActividad}</span>
                      </div>
                      <div className='col-span-12 text-center mb-2'>
                        <label className='font-medium p-1 bg-theme-26 box mr-1'>
                          ID Usuario:
                        </label>
                        <span>{prestamo.usuario.idUsuario}</span>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              {/*===== Datos Bancarios =====*/}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon color='primary' />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography className={classes.heading}>
                    <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                      <DoneAllIcon color='primary' /> ._ Datos Bancarios del
                      Usuarios
                    </label>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='flex p-1 flex-col bg-theme-2 box w-full mb-3 overflow-auto lg:overflow-visible'>
                    {prestamo.datosEnvio && (
                      <div className='grid grid-cols-12 gap-4 row-gap-5'>
                        <div className='col-span-5 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Nombre banco:
                          </label>
                          <span>{prestamo.datosEnvio.nombreBanco}</span>
                        </div>
                        <div className='col-span-4 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            País:
                          </label>
                          <span>{prestamo.datosEnvio.pais}</span>
                        </div>
                        <div className='col-span-3 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Telefono:
                          </label>
                          <span>
                            {prestamo.usuario.codigoPais}{' '}
                            {prestamo.usuario.whatsapp}`
                          </span>
                        </div>
                        {prestamo.tipoPagoVenezuela === 'Cuenta Bancaria' ? (
                          <div className='col-span-5 ml-10'>
                            <label className='font-medium p-1 bg-theme-26 box mr-1'>
                              Número de cuenta:
                            </label>
                            <span>{prestamo.datosEnvio.numeroCuenta}</span>
                          </div>
                        ) : (
                          <div className='col-span-4 ml-10'>
                            <label className='font-medium p-1 bg-theme-26 box mr-1'>
                              Teléfono pago-movil:
                            </label>
                            <span>{prestamo.datosEnvio.telefono}</span>
                          </div>
                        )}
                        <div className='col-span-4 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Titular:
                          </label>
                          <span> {prestamo.datosEnvio.titular}</span>
                        </div>
                        <div className='col-span-3 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Cédula:
                          </label>
                          <span>{prestamo.datosEnvio.cedula}</span>
                        </div>
                        <div className='col-span-5 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Monto a transferir:
                          </label>
                          <span>
                            {prestamo.montoPrestado}
                            {''} USD
                          </span>
                        </div>
                        <div className='col-span-4 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Tipo de pago:
                          </label>
                          <span>{prestamo.tipoPagoVenezuela}</span>
                        </div>{' '}
                        <div className='col-span-3 ml-10'>
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Tipo:
                          </label>
                          <span>{prestamo.datosEnvio.tipoCuenta}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      {/*===== Datos de Requerimientos =====*/}
      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Datos de Imagenes <AddToPhotosIcon color='primary' fontSize='small' />
      </h2>
      <div className={classes.root}>
        {/*===== Card Verificacion Imagenes =====*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Imagenes a Validar
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='grid grid-cols-12 gap-4 row-gap-5 mb-3 mt-2 p-2 sm:col-span-8 xxl:col-span-9  bg-theme-2 box w-full overflow-auto lg:overflow-visible'>
              <div className='intro-y col-span-12 sm:col-span-6'>
                <div className='text-center'>
                  <label className='box bg-theme-26 p-2'>
                    <span>Imagen de Cédula / Pasaporte</span>
                    <buton
                      className='tooltip button inline-block m-auto rounded-lg'
                      title='Subir cédula usuario'
                      onClick={openExplorerDocumento}
                    >
                      <i
                        data-feather='folder-plus'
                        className='w-5 h-5 text-theme-1 stroke-2 -mb-3'
                      ></i>
                    </buton>
                  </label>
                  {requerimientosUsuarios.length > 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='documento identidad'
                      style={{ width: 300, height: 200 }}
                      src={
                        requerimientosUsuarios[0].documento !== ''
                          ? requerimientosUsuarios[0].documento
                          : Document
                      }
                    />
                  )}
                  {requerimientosUsuarios.length === 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='documento identidad'
                      style={{ width: 300, height: 200 }}
                      src={Document}
                    />
                  )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={fileDocumentRef}
                    onChange={loadDocument}
                  />
                </div>
                {requerimientosUsuarios.length > 0 && (
                  <div className='flex mt-5 mb-5 text-center ml-20'>
                    <div className='intro-y col-span-12 sm:col-span-5 w-auto'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Aprobar / Rechazar
                        <CloudDownloadIcon
                          className='ml-5 hover:text-green-700 pointer'
                          color='primary'
                          sx={{ fontSize: 18 }}
                          onClick={descargarImagen}
                        />
                      </label>
                      <select
                        type='text'
                        name='validadoDocumento'
                        className='w-full rounded-full border intro-x input input--sm border mt-2'
                        aria-label='Default select example'
                        value={requerimientosUsuarios[0].validadoDocumento}
                        onChange={(e) => {
                          verificarImagenesDocumento(e);
                        }}
                      >
                        <option value='' disabled='disabled'>
                          Seleccione
                        </option>
                        <option value='aprobado'> Aprobar Imagen </option>
                        <option value='Imagen No Nitida'>
                          Imagen No Nítida
                        </option>
                        <option value='No Coinciden los Datos'>
                          No Coinciden los Datos
                        </option>
                        <option value='Intento de Estafa'>
                          Intento de Estafa
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <div className='intro-y col-span-12 sm:col-span-6 mb-10'>
                <div className='text-center'>
                  <label className='box bg-theme-26 p-2'>
                    <span>Imagen Constancia Trabajo</span>
                    <buton
                      className='tooltip button inline-block m-auto rounded-lg'
                      title='Subir constancia'
                      onClick={openExplorerComprobante}
                    >
                      <i
                        data-feather='folder-plus'
                        className='w-5 h-5 text-theme-1 stroke-2 -mb-3'
                      ></i>
                    </buton>
                  </label>
                  {requerimientosUsuarios.length > 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='comprobanteTrabajo'
                      style={{ width: 300, height: 200 }}
                      src={
                        requerimientosUsuarios[0].comprobanteTrabajo !== ''
                          ? requerimientosUsuarios[0].comprobanteTrabajo
                          : Comprobante
                      }
                    />
                  )}
                  {requerimientosUsuarios.length === 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='comprobanteTrabajo'
                      style={{ width: 300, height: 200 }}
                      src={Comprobante}
                    />
                  )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={fileComprobanteRef}
                    onChange={loadComprobante}
                  />
                </div>
                {requerimientosUsuarios.length > 0 &&
                  requerimientosUsuarios[0].comprobanteTrabajo !== '' && (
                    <div className='flex mt-5 mb-5 text-center ml-20'>
                      <div className='intro-y col-span-12 sm:col-span-5 w-auto'>
                        <label className='font-medium bg-theme-26 box p-1'>
                          Aprobar / Rechazar
                          <CloudDownloadIcon
                            className='ml-5 hover:text-green-700 pointer'
                            color='primary'
                            sx={{ fontSize: 18 }}
                            onClick={descargarImagen}
                          />
                        </label>
                        <select
                          type='text'
                          name='validadoComprobanteTrabajo'
                          className='w-full rounded-full border intro-x input input--sm  border mt-2'
                          aria-label='Default select example'
                          value={
                            requerimientosUsuarios[0].validadoComprobanteTrabajo
                          }
                          onChange={verificarImagenesComprobanteTrabajo}
                        >
                          <option value='' disabled='disabled'>
                            {' '}
                            Seleccione
                          </option>
                          <option value='aprobado'> Aprobar Imagen </option>
                          <option value='Imagen No Nitida'>
                            Imagen No Nítida
                          </option>
                          <option value='No Coinciden los Datos'>
                            No Coinciden los Datos
                          </option>
                          <option value='Intento de Estafa'>
                            Intento de Estafa
                          </option>
                        </select>
                      </div>
                    </div>
                  )}
              </div>

              <div className='intro-y col-span-12 sm:col-span-6'>
                <div className='text-center'>
                  <label className='box bg-theme-26 p-2'>
                    <span>Imagen Selfie / Con Cédula</span>
                    <buton
                      className='tooltip button inline-block m-auto rounded-lg'
                      title='Subir selfie cédula'
                      onClick={openExplorerSelfie}
                    >
                      <i
                        data-feather='folder-plus'
                        className='w-5 h-5 text-theme-1 stroke-2 -mb-3'
                      ></i>
                    </buton>
                  </label>
                  {requerimientosUsuarios.length > 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='selfieCedula'
                      style={{ width: 300, height: 200 }}
                      src={
                        requerimientosUsuarios[0].selfieCedula !== ''
                          ? requerimientosUsuarios[0].selfieCedula
                          : selfieDoc
                      }
                    />
                  )}
                  {requerimientosUsuarios.length === 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='selfieCedula'
                      style={{ width: 300, height: 200 }}
                      src={selfieDoc}
                    />
                  )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={fileSelfieRef}
                    onChange={loadSelfie}
                  />
                </div>
                {requerimientosUsuarios.length > 0 && (
                  <div className='flex mt-5 mb-5 text-center ml-20'>
                    <div className='intro-y col-span-12 sm:col-span-5 w-auto'>
                      <label className='font-medium bg-theme-26 box p-1'>
                        Aprobar / Rechazar
                        <CloudDownloadIcon
                          className='ml-5 hover:text-green-700 pointer'
                          color='primary'
                          sx={{ fontSize: 18 }}
                          onClick={descargarImagen}
                        />
                      </label>
                      <select
                        type='text'
                        name='validadoSelfieCedula'
                        className='w-full rounded-full border intro-x input input--sm  border mt-2'
                        aria-label='Default select example'
                        value={requerimientosUsuarios[0].validadoSelfieCedula}
                        onChange={verificarImagenesSelfieCedula}
                      >
                        <option value='' disabled='disabled'>
                          {' '}
                          Seleccione
                        </option>
                        <option value='aprobado'> Aprobar Imagen </option>
                        <option value='Imagen No Nitida'>
                          Imagen No Nítida
                        </option>
                        <option value='No Coinciden los Datos'>
                          No Coinciden los Datos
                        </option>
                        <option value='Intento de Estafa'>
                          Intento de Estafa
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <div className='intro-y col-span-12 sm:col-span-6 mb-10'>
                <div className='text-center'>
                  <label className='box bg-theme-26 p-2'>
                    <span> Imagen Selfie / Con Solicitud</span>
                    <buton
                      className='tooltip button inline-block m-auto rounded-lg'
                      title='Subir selfie'
                      onClick={openExplorerSelfiePrestamo}
                    >
                      <i
                        data-feather='folder-plus'
                        className='w-5 h-5 text-theme-1 stroke-2 -mb-3'
                      ></i>
                    </buton>
                  </label>
                  {requerimientoPrestamo.length > 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='selfiePrestamo'
                      style={{ width: 300, height: 200 }}
                      src={
                        requerimientoPrestamo[0].selfiePrestamo !== ''
                          ? requerimientoPrestamo[0].selfiePrestamo
                          : Perfil
                      }
                    />
                  )}
                  {requerimientoPrestamo.length === 0 && (
                    <img
                      className='border m-auto rounded mt-4'
                      alt='selfiePrestamo'
                      style={{ width: 300, height: 200 }}
                      src={Perfil}
                    />
                  )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={fileSelfiePresRef}
                    onChange={loadSelfieSolicitud}
                  />
                </div>
                {requerimientoPrestamo.length > 0 && (
                  <div className='flex mt-5 mb-5 text-center ml-40'>
                    <div className='flex text-gray-700 mr-2 items-center'>
                      <input
                        name='validadoSelfiePrestamo[]'
                        type='checkbox'
                        value='aprobado'
                        className='input border mr-2'
                        id='validadoSelfiePrestamoS'
                        checked={
                          requerimientoPrestamo[0].validadoSelfiePrestamo ===
                          'aprobado'
                        }
                        onClick={aprobarSelfiePrestamo}
                        onChange={verificarImagenesPrestamo}
                      />
                      <label
                        className='flex font-medium cursor-pointer select-none items-center'
                        htmlFor='validadoSelfiePrestamoS'
                      >
                        Aprobar
                        <Icon.CheckSquare className='w-3 h-3 ml-1 text-theme-3' />
                      </label>
                    </div>
                    <div className='flex text-gray-700 mr-2 items-center'>
                      <input
                        name='validadoSelfiePrestamo[]'
                        type='checkbox'
                        value='rechazado'
                        className='input border mr-2'
                        id='validadoSelfiePrestamoN'
                        checked={
                          requerimientoPrestamo[0].validadoSelfiePrestamo ===
                          'rechazado'
                        }
                        onClick={rechazarSelfiePrestamo}
                        onChange={verificarImagenesPrestamo}
                      />
                      <label
                        className='flex font-medium cursor-pointer select-none items-center'
                        htmlFor='validadoSelfiePrestamoN'
                      >
                        Rechazar
                        <Icon.XSquare className='w-3 h-3 ml-1 text-theme-6' />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/*===== Card Verificar Redes Sociales =====*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Verificacion Redes Sociales
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='intro-y col-span-12 mb-3 bg-theme-2 box p-3 w-full overflow-auto lg:overflow-visible'>
              <div className='font-medium box bg-theme-5 text-center mt-2 w-full p-1'>
                <label>Titular Redes :</label>
                {prestamo.usuario && (
                  <span className='ml-1'>
                    {prestamo.usuario.nombres} {prestamo.usuario.apellidos}
                  </span>
                )}
              </div>
              <table className='table-report w-full p-3'>
                <thead>
                  <tr className='box bg-theme-26'>
                    <th className='whitespace-no-wrap text-center text-center p-3'>
                      TIPO
                    </th>
                    <th className='whitespace-no-wrap text-center text-center'>
                      URL
                    </th>
                    <th className='whitespace-no-wrap text-center text-center'>
                      VALIDADO
                    </th>
                    <th className='whitespace-no-wrap text-center text-center'>
                      VER LINK
                    </th>
                    <th className='whitespace-no-wrap text-center'>
                      VERIFICAR
                    </th>
                    <th className='whitespace-no-wrap text-center'>
                      SOLICITUD AMISTAD
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {linkSociales.map((link, idUsuario) => (
                    <tr className='intro-x p-3' key={idUsuario}>
                      <td className='p-3'>{link.tipo}</td>
                      <td>{link.url}</td>
                      <td className='text-center'>
                        {link.validado === 'aprobado' ? 'Si' : 'No'}
                      </td>
                      <td className='table-report__action w-48'>
                        <div className='flex justify-center'>
                          <div className='flex justify-center font-medium items-center'>
                            <a
                              className='flex items-center mr-3 text-theme-1'
                              href={link.url}
                              target='_blank'
                            >
                              <Icon.Search className='w-4 h-4 mr-1' />
                              Ver
                            </a>
                          </div>
                          <div className='flex justify-center font-medium items-center'>
                            <buton
                              className='flex items-center mr-3 text-theme-11 pointer'
                              onClick={() => editarLinkSocial(link)}
                            >
                              <Icon.Settings className='w-4 h-4 mr-1' />
                              Configurar
                            </buton>
                          </div>
                        </div>
                      </td>
                      <td className='table-report__action'>
                        <div className='font-medium ml-6'>
                          <div className='flex mr-2 items-center'>
                            <input
                              name='validado[]'
                              type='checkbox'
                              value='aprobado'
                              className='input border mr-2'
                              id='validadO'
                              checked={link.validado === 'aprobado'}
                              onChange={(e) => {
                                verificarLink(e, link);
                              }}
                            />
                            <label
                              className='flex cursor-pointer select-none items-center'
                              htmlFor='validadO'
                            >
                              Aprobar
                              <Icon.CheckSquare className='w-3 h-3 ml-1 text-theme-3' />
                            </label>
                            <input
                              name='validado[]'
                              type='checkbox'
                              value='rechazado'
                              className='input border ml-8 mr-2'
                              id='validadO'
                              checked={link.validado === 'rechazado'}
                              onChange={(e) => {
                                verificarLink(e, link);
                              }}
                            />
                            <label
                              className='flex cursor-pointer select-none items-center'
                              htmlFor='validadO'
                            >
                              Rechazar
                              <Icon.XSquare className='w-3 h-3 ml-1 text-theme-6' />
                            </label>
                          </div>
                        </div>
                      </td>
                      <td className='table-report__action'>
                        <div className='font-medium ml-6'>
                          <div className='flex mr-2 items-center'>
                            <input
                              name='validado[]'
                              type='checkbox'
                              value='aprobado'
                              className='input border mr-2'
                              id='validadO'
                              checked={link.solicitudConfirmada === true}
                              onChange={(e) => {
                                solicitudAmistad(e, link);
                              }}
                            />
                            <label
                              className='flex cursor-pointer select-none items-center'
                              htmlFor='validadO'
                            >
                              Enviada
                              <Icon.Send className='w-3 h-3 ml-1 text-theme-3' />
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {editarSocial && (
                <form className='mt-6'>
                  <h1 className='mb-5 text-center font-medium'>
                    Editar las URLs de su facebook e instagram.
                  </h1>
                  <div className='grid grid-cols-12 gap-4 row-gap-5'>
                    <div className='intro-y col-span-12 sm:col-span-6'>
                      <input
                        type='text'
                        name='url'
                        value={social.url}
                        className='w-full rounded-full border intro-x input input--sm border mt-1'
                        // placeholder={placeHolderSocial()}
                        onChange={changeSocial}
                      />
                    </div>
                    <div
                      className='intro-y col-span-12 sm:col-span-6 text-center mb-5'
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-center',
                      }}
                    >
                      <div className='intro-y col-span-12 sm:col-span-6 flex justify-center mt-1'>
                        <button
                          className='flex button w-auto text-white bg-theme-1 xl:mr-5 px-3 items-center'
                          onClick={guardarRedes}
                        >
                          <Icon.Plus className='w-4 h-4 mr-1' />
                          Guardar
                        </button>
                        <button
                          className='flex button w-auto text-white bg-theme-9 xl:mr-5 px-3 items-center'
                          onClick={editarRedSocial}
                        >
                          <Icon.Edit className='w-4 h-4 mr-1' />
                          Editar
                        </button>
                        <button
                          className='flex button w-auto text-white bg-theme-6 xl:mr-5 px-3 items-center'
                          onClick={eliminarRedes}
                        >
                          <Icon.Delete className='w-4 h-4 mr-1' />
                          Eliminar
                        </button>
                        <button
                          className='flex button w-auto text-white bg-theme-36 xl:mr-5 px-3 items-center'
                          onClick={cancel}
                        >
                          <Icon.XSquare className='w-4 h-4 mr-1' />
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        {/*===== Validaciones Redes Sociales =====*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Datos de Validación Imagenes
                y Redes
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='grid grid-cols-12 gap-6 row-gap-6 mb-3 w-full'>
              <div className='col-span-12 md:col-span-6'>
                <div className='intro-y box lg:mt-3'>
                  <div className='flex p-3 border-b text-base'>
                    <h2 className='font-medium mr-auto ml-8'>
                      - Validación de Redes Sociales
                    </h2>
                  </div>
                  <hr />
                  <div className='grid grid-cols-12 gap-4 row-gap-5 w-full flex p-3 flex-col bg-theme-2 box'>
                    {linkSociales.map((link, idUsuario) => (
                      <div className='col-span-12' key={idUsuario}>
                        <div className='flex flex-col justify-center'>
                          <label className='font-medium ml-2 bg-theme-23 box p-1'>
                            {link.url}
                          </label>

                          {link.validado === 'aprobado' && (
                            <label className='font-medium bg-theme-26 box p-1 ml-3 mt-1'>
                              {link.validado === 'aprobado'
                                ? 'Validado por:'
                                : 'No Validado'}
                            </label>
                          )}
                          {link.validado === 'aprobado' && (
                            <div className='flex flex-col justify-center ml-3'>
                              <label className='font-medium ml-3'>
                                {link.gestor.nombres} {link.gestor.apellidos}
                              </label>
                              <label className='font-medium ml-3'>
                                El:{' '}
                                {moment(link.fechaValidacion).format(
                                  'DD-MM-YYYY'
                                )}
                              </label>
                            </div>
                          )}
                          {link.validado === 'rechazado' && (
                            <div className='flex flex-col justify-center ml-3 mt-1'>
                              <b className=' bg-theme-6 box p-1'>
                                Rechazado por:
                              </b>
                              <label className='font-medium ml-3'>
                                {link.gestor.nombres} {link.gestor.apellidos}
                              </label>
                              <label className='font-medium ml-3'>
                                El:{' '}
                                {moment(link.fechaValidacion).format(
                                  'DD-MM-YYYY'
                                )}
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                    {linkSociales.length === 0 && (
                      <div className='col-span-10'>
                        <div className='flex flex-col justify-center'>
                          <label className='font-medium ml-2 bg-theme-36 box p-1'>
                            El usuario no ha cargado Links Sociales.
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/*===== Validaciones Requerimientos =====*/}
              <div className='col-span-12 md:col-span-6'>
                <div className='intro-y box lg:mt-3'>
                  <div className='flex p-3 border-b text-base'>
                    <h2 className='font-medium mr-auto ml-8'>
                      - Validación de Requerimientos
                    </h2>
                  </div>
                  <hr />
                  {requerimientosUsuarios.length > 0 && (
                    <div className='grid grid-cols-12 gap-4 row-gap-5 w-full flex p-3 flex-col bg-theme-2 box'>
                      <div className='col-span-12'>
                        {requerimientosUsuarios[0].validadoSelfieCedula !==
                          '' && (
                          <div className='flex flex-col justify-center'>
                            <label className='font-medium ml-2 bg-theme-23 box p-1'>
                              Selfi Sosteniendo Documento de Identificación
                            </label>
                            {requerimientosUsuarios[0].validadoSelfieCedula ===
                              'aprobado' && (
                              <div className='flex flex-col justify-center ml-3'>
                                <label className='font-medium bg-theme-26 box p-1 mt-1'>
                                  Esta{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoSelfieCedula
                                  }{' '}
                                  por:
                                </label>
                                <label className='font-medium ml-3'>
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorSelfieCedula.nombres
                                  }{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorSelfieCedula.apellidos
                                  }
                                </label>
                                <label className='font-medium ml-3'>
                                  El:{' '}
                                  {moment(
                                    requerimientosUsuarios[0]
                                      .fechaValidacionSelfieCedula
                                  ).format('DD-MM-YYYY')}
                                </label>
                              </div>
                            )}
                            {requerimientosUsuarios[0].validadoSelfieCedula ===
                              'rechazado' && (
                              <div className='flex flex-col justify-center ml-3'>
                                <label className='font-medium bg-theme-6 box p-1 mt-1 bg-theme-6 box'>
                                  Esta{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoSelfieCedula
                                  }{' '}
                                  por:
                                </label>
                                <label className='font-medium ml-3'>
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorSelfieCedula.nombres
                                  }{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorSelfieCedula.apellidos
                                  }
                                </label>
                                <label className='font-medium ml-3'>
                                  El:{' '}
                                  {moment(
                                    requerimientosUsuarios[0]
                                      .fechaValidacionSelfieCedula
                                  ).format('DD-MM-YYYY')}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                        {requerimientosUsuarios[0].validadoSelfieCedula ===
                          '' && (
                          <label className='font-medium ml-3 bg-theme-17 box p-2'>
                            Selfi con documento de identificación no ha sido
                            revisada por ningun gestor.
                          </label>
                        )}
                      </div>
                      <div className='col-span-12'>
                        {requerimientosUsuarios[0].validadoDocumento !== '' && (
                          <div className='flex flex-col justify-center'>
                            <label className='font-medium ml-2 bg-theme-23 box p-1'>
                              Documento de Identificación
                            </label>

                            {requerimientosUsuarios[0].validadoDocumento ===
                              'aprobado' && (
                              <div className='flex flex-col justify-center ml-3'>
                                <label className='font-medium bg-theme-26 box p-1 mt-1'>
                                  Esta{' '}
                                  {requerimientosUsuarios[0].validadoDocumento}{' '}
                                  por:
                                </label>
                                <label className='font-medium ml-3'>
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorDocumento.nombres
                                  }{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorDocumento.apellidos
                                  }
                                </label>
                                <label className='font-medium ml-3'>
                                  El:{' '}
                                  {moment(
                                    requerimientosUsuarios[0]
                                      .fechaValidacionDocumento
                                  ).format('DD-MM-YYYY')}
                                </label>
                              </div>
                            )}
                            {requerimientosUsuarios[0].validadoDocumento ===
                              'rechazado' && (
                              <div className='flex flex-col justify-center ml-3'>
                                <label className='font-medium bg-theme-6 box p-1 mt-1'>
                                  Esta{' '}
                                  {requerimientosUsuarios[0].validadoDocumento}{' '}
                                  por:
                                </label>
                                <label className='font-medium ml-3'>
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorDocumento.nombres
                                  }{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorDocumento.apellidos
                                  }
                                </label>
                                <label className='font-medium ml-3'>
                                  El:{' '}
                                  {moment(
                                    requerimientosUsuarios[0]
                                      .fechaValidacionDocumento
                                  ).format('DD-MM-YYYY')}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                        {requerimientosUsuarios[0].validadoDocumento === '' && (
                          <label className='font-medium ml-3 bg-theme-17 box p-2'>
                            Documento de identificación no ha sido revisado por
                            ningun gestor.
                          </label>
                        )}
                      </div>
                      <div className='col-span-12'>
                        {requerimientosUsuarios[0]
                          .validadoComprobanteTrabajo !== '' && (
                          <div className='flex flex-col justify-center'>
                            <label className='font-medium ml-2 bg-theme-23 box p-1'>
                              Comprobante de Trabajo
                            </label>
                            {requerimientosUsuarios[0]
                              .validadoComprobanteTrabajo === 'aprobado' && (
                              <div className='flex flex-col justify-center ml-3'>
                                <label className='font-medium bg-theme-26 box p-1 mt-1'>
                                  Esta{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoComprobanteTrabajo
                                  }{' '}
                                  por:
                                </label>
                                <label className='font-medium ml-3'>
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorComprobanteTrabajo.nombres
                                  }{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorComprobanteTrabajo
                                      .apellidos
                                  }
                                </label>
                                <label className='font-medium ml-3'>
                                  El:{' '}
                                  {moment(
                                    requerimientosUsuarios[0]
                                      .fechaValidacionComprobanteTrabajo
                                  ).format('DD-MM-YYYY')}
                                </label>
                              </div>
                            )}
                            {requerimientosUsuarios[0]
                              .validadoComprobanteTrabajo === 'rechazado' && (
                              <div className='flex flex-col justify-center ml-3'>
                                <label className='font-medium bg-theme-6 box p-1 mt-1'>
                                  Esta{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoComprobanteTrabajo
                                  }{' '}
                                  por:
                                </label>
                                <label className='font-medium ml-3'>
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorComprobanteTrabajo.nombres
                                  }{' '}
                                  {
                                    requerimientosUsuarios[0]
                                      .validadoGestorComprobanteTrabajo
                                      .apellidos
                                  }
                                </label>
                                <label className='font-medium ml-3'>
                                  El:{' '}
                                  {moment(
                                    requerimientosUsuarios[0]
                                      .fechaValidacionComprobanteTrabajo
                                  ).format('DD-MM-YYYY')}
                                </label>
                              </div>
                            )}
                          </div>
                        )}
                        {requerimientosUsuarios[0]
                          .validadoComprobanteTrabajo === '' && (
                          <label className='font-medium ml-3 bg-theme-17 box p-2'>
                            Comprobante de trabajo no ha sido revisado por
                            ningun gestor.
                          </label>
                        )}
                      </div>
                      {requerimientoPrestamo.length === 0 && (
                        <div className='col-span-12'>
                          <label className='font-medium ml-3 bg-theme-36 box p-2'>
                            Selfie con prestamo no ha sido subida por el
                            usuario.
                          </label>
                        </div>
                      )}
                      {requerimientoPrestamo.length > 0 &&
                        requerimientoPrestamo[0]
                          .validadoGestorSelfiePrestamo !== null && (
                          <div className='col-span-12'>
                            {requerimientoPrestamo[0].validadoSelfiePrestamo !==
                              '' && (
                              <div className='flex flex-col justify-center'>
                                <label className='font-medium ml-2 bg-theme-23 box p-1'>
                                  Selfie Sosteniendo Documento de Prestamo
                                </label>
                                {requerimientoPrestamo[0]
                                  .validadoSelfiePrestamo === 'aprobado' && (
                                  <div className='flex flex-col justify-center ml-3'>
                                    <label className='font-medium bg-theme-26 box p-1 mt-1'>
                                      Esta{' '}
                                      {
                                        requerimientoPrestamo[0]
                                          .validadoSelfiePrestamo
                                      }{' '}
                                      por:
                                    </label>
                                    <label className='font-medium ml-3'>
                                      {
                                        requerimientoPrestamo[0]
                                          .validadoGestorSelfiePrestamo.nombres
                                      }{' '}
                                      {
                                        requerimientoPrestamo[0]
                                          .validadoGestorSelfiePrestamo
                                          .apellidos
                                      }
                                    </label>
                                    <label className='font-medium ml-3'>
                                      El:{' '}
                                      {moment(
                                        requerimientoPrestamo[0]
                                          .fechaValidacionSelfiePrestamo
                                      ).format('DD-MM-YYYY')}
                                    </label>
                                  </div>
                                )}
                                {requerimientoPrestamo[0]
                                  .validadoSelfiePrestamo === 'rechazado' && (
                                  <div className='flex flex-col justify-center ml-3'>
                                    <label className='font-medium bg-theme-6 box p-1 mt-1'>
                                      Esta{' '}
                                      {
                                        requerimientoPrestamo[0]
                                          .validadoSelfiePrestamo
                                      }{' '}
                                      por:
                                    </label>
                                    <label className='font-medium ml-3'>
                                      {
                                        requerimientoPrestamo[0]
                                          .validadoGestorSelfiePrestamo.nombres
                                      }{' '}
                                      {
                                        requerimientoPrestamo[0]
                                          .validadoGestorSelfiePrestamo
                                          .apellidos
                                      }
                                    </label>
                                    <label className='font-medium ml-3'>
                                      El:{' '}
                                      {moment(
                                        requerimientoPrestamo[0]
                                          .fechaValidacionSelfiePrestamo
                                      ).format('DD-MM-YYYY')}
                                    </label>
                                  </div>
                                )}
                              </div>
                            )}
                            {requerimientoPrestamo[0].validadoSelfiePrestamo ===
                              '' && (
                              <label className='font-medium ml-3 bg-theme-17 box p-2'>
                                Selfie con prestamo no ha sido revisado por
                                ningun gestor.
                              </label>
                            )}
                          </div>
                        )}
                    </div>
                  )}
                  {requerimientosUsuarios.length === 0 &&
                    requerimientoPrestamo.length === 0 && (
                      <div className='grid grid-cols-12 gap-4 row-gap-5 w-full flex p-3 flex-col bg-theme-2'>
                        <div className='col-span-12'>
                          <div className='flex flex-col justify-center'>
                            <label className='font-medium ml-2 bg-theme-36 box p-1'>
                              El usuario no ha cargado Imagenes.
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {prestamo.usuario && requerimientosUsuarios.length > 0 && (
                <>
                  {validarImagenes() === 'no revisado' && (
                    <div className='flex flex-col justify-center items-center col-span-12 md:col-span-12 mt-3'>
                      <label className='font-medium mb-2'>
                        El usuario de este prestamo tiene requerimientos sin
                        revisar puede
                      </label>
                      <Link
                        className='button w-auto mr-1 bg-theme-1 text-white'
                        to={{
                          pathname:
                            '/gestor/validarusuarios/' + prestamo.idUsuario,
                          state: { usuario: prestamo.usuario },
                        }}
                      >
                        ir a Validar datos del usuario
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      {/*========== Datos del Prestamo ==========*/}
      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Datos de Préstamo <AddToPhotosIcon color='primary' fontSize='small' />
      </h2>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Datos del Préstamo
              </label>
            </Typography>
          </AccordionSummary>
          {/*===== Datos del Prestamo =====*/}
          <AccordionDetails>
            <div className='flex p-1 flex-col bg-theme-2 box w-full mb-3 overflow-auto lg:overflow-visible'>
              {prestamo && prestamo.usuario && (
                <div className='grid grid-cols-12 gap-4 row-gap-5'>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Estado:
                    </label>
                    <span>{prestamo.estado}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Pagado:
                    </label>
                    <span>{prestamo.pagado === true ? 'Si' : 'No'}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto solicitado:
                    </label>
                    <span>{prestamo.montoPrestado} USD</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto a pagar:
                    </label>
                    <span>{prestamo.montoCompromisoPago} USD</span>
                  </div>
                  {calCulardiasTranscurridos(prestamo.fechaInicio) > 0 && (
                    <div className='col-span-4 ml-10'>
                      <label className='font-medium p-1 bg-theme-26 box mr-1'>
                        Monto deuda restante :
                      </label>
                      {abonosRealizados.length > 0 ? (
                        <span>
                          {calcularInteresesPorAbonos()['interesHoy']}
                        </span>
                      ) : (
                        <span>{montoHoy()} USD</span>
                      )}
                    </div>
                  )}
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de inicio:
                    </label>
                    <span>{prestamo.fechaInicio}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Solicitado el:
                    </label>
                    <span>{prestamo.fechaSolicitud}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de pago:
                    </label>
                    <span>{prestamo.fechaCompromisoPago}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Interes:
                    </label>
                    <span>
                      {prestamo.usuario.habilitadoNuevoPrestamo === 'No'
                        ? prestamo.tasaInteresCompuesto
                        : prestamo.usuario.nuevaTasaInteres}{' '}
                      %
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Tasa dolar:
                    </label>
                    <span>{tasaDolarHoy}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Total en Bf:
                    </label>
                    <span>
                      {(prestamo.montoPrestado * tasaDolarHoy).toFixed(2)}
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Habilitado:
                    </label>
                    <span>{prestamo.usuario.habilitadoNuevoPrestamo}</span>
                  </div>
                  <div className='col-span-12 text-center mb-2'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      ID Prestamo:
                    </label>
                    <span>{userid}</span>
                  </div>
                </div>
              )}
              {prestamo.fechaInicioDespues && (
                <>
                  <h3 className='font-medium p-2 bg-theme-23 box text-center mt-5'>
                    Prestamo con deuda restante
                  </h3>
                  <div className='grid grid-cols-12 gap-4 row-gap-5 bg-theme-23 box mb-5 p-2'>
                    <div className='col-span-4 p-1 bg-theme-26 box'>
                      <label className='font-medium ml-2 mr-1'>
                        Monto deuda restante :
                      </label>
                      <span>
                        {calcularInteresesPorAbonos()['interesHoy']} $
                      </span>
                    </div>
                    <div className='col-span-4 ml-10 p-1 bg-theme-26 box'>
                      <label className='font-medium ml-2 mr-1'>
                        Fecha inicio :
                      </label>
                      <span>{prestamo.fechaInicioDespues} </span>
                    </div>

                    <div className='col-span-4 ml-10 p-1 bg-theme-26 box'>
                      <label className='font-medium ml-2 mr-1'>
                        Nueva fecha de pago :
                      </label>
                      <span>{prestamo.fechaCompromisoPagoDespues} </span>
                    </div>
                  </div>
                </>
              )}
              {requerimientosUsuarios.length > 0 &&
                prestamo.aprobado === false &&
                linkSociales[0].solicitudConfirmada === true && (
                  <>
                    <div className='flex p-3 justify-center mt-8'>
                      {validarImagenes() === 'aprobado' &&
                        validarLinks() === 'aprobado' &&
                        prestamo.estado === 'pendiente' && (
                          <button
                            className='flex button w-40 bg-theme-3 text-white items-center'
                            onClick={aprobar}
                          >
                            <Icon.CheckSquare className='w-4 h-4 mr-2 ml-2' />
                            Pre-Aprobar
                          </button>
                        )}
                      {validarImagenes() === 'no revisado' &&
                        validarLinks() === 'no revisado' && (
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            Las imagenes y los links del usuario no han sido
                            revisados.
                          </label>
                        )}
                      {/*===== condicionales para el rechazo =====*/}
                      {validarImagenes() === 'rechazado' &&
                        validarLinks() === 'rechazado' && (
                          <button
                            className='flex button w-32 bg-theme-6 text-white items-center'
                            onClick={envioCorreoMensajeRechazo}
                          >
                            <Icon.XSquare className='w-4 h-4 mr-2 ml-2' />
                            Rechazar
                          </button>
                        )}
                      {validarImagenes() === 'rechazado' &&
                        validarLinks() === 'no revisado' && (
                          <button
                            className='flex button w-32 bg-theme-6 text-white items-center'
                            onClick={envioCorreoMensajeRechazo}
                          >
                            <Icon.XSquare className='w-4 h-4 mr-2 ml-2' />
                            Rechazar
                          </button>
                        )}
                      {validarImagenes() === 'no revisado' &&
                        validarLinks() === 'rechazado' && (
                          <button
                            className='flex button w-32 bg-theme-6 text-white items-center'
                            onClick={envioCorreoMensajeRechazo}
                          >
                            <Icon.XSquare className='w-4 h-4 mr-2 ml-2' />
                            Rechazar
                          </button>
                        )}
                      {validarImagenes() === 'rechazado' &&
                        validarLinks() === 'aprobado' && (
                          <button
                            className='flex button w-32 bg-theme-6 text-white items-center'
                            onClick={envioCorreoMensajeRechazo}
                          >
                            <Icon.XSquare className='w-4 h-4 mr-2 ml-2' />
                            Rechazar
                          </button>
                        )}
                      {validarImagenes() === 'aprobado' &&
                        validarLinks() === 'rechazado' && (
                          <button
                            className='flex button w-32 bg-theme-6 text-white items-center'
                            onClick={envioCorreoMensajeRechazo}
                          >
                            <Icon.XSquare className='w-4 h-4 mr-2 ml-2' />
                            Rechazar
                          </button>
                        )}
                      {validarImagenes() === 'no revisado' &&
                        validarLinks() === 'aprobado' && (
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            No le han revisado las imagenes al usuario.
                          </label>
                        )}
                      {validarImagenes() === 'aprobado' &&
                        validarLinks() === 'no revisado' && (
                          <label className='font-medium p-1 bg-theme-26 box mr-1'>
                            No le han revisado links sociales al usuario.
                          </label>
                        )}
                    </div>
                  </>
                )}
              {/* Boton Modal Reporta Pagos */}
              {prestamo.estado === 'prestado' && (
                <div className='flex justify-end cursor-pointer mb-5 mr-5'>
                  <div className='box p-2 bg-theme-3 text-white'>
                    <button className='flex' onClick={abonar}>
                      <Icon.CreditCard className='w-4 h-4 mr-2 mt-px' />
                      Reportar Pagos
                      <Icon.DollarSign className='w-4 h-4 ml-2 mt-px' />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        {/*===== Actualizacion del Préstamo =====*/}
        {prestamo.estado !== 'finalizado' && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon color='primary' />}
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography className={classes.heading}>
                <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                  <DoneAllIcon color='primary' /> ._ Actualizar Préstamo
                </label>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='intro-y box col-span-12 lg:col-span-12 border-b bg-theme-5 box mb-10 w-full overflow-auto lg:overflow-visible'>
                <div className='flex p-3 border-b text-base'>
                  <h2 className='font-medium bg-theme-26 box p-2 w-full'>
                    Parametros a Actualizar
                  </h2>
                </div>
                <form onSubmit={guardarActualizacion}>
                  <div className='intro-y col-span-12 sm:col-span-6 flex ml-8 mt-5'>
                    <label className='font-medium bg-theme-2 box p-1'>
                      Fecha Inicio :
                    </label>
                    <input
                      type='date'
                      className='input input--sm border rounded-lg w-64 ml-5'
                      required
                      min='0'
                      name='fechaInicio'
                      placeholder='Ingresar fecha inicial'
                      value={prestamoAct.fechaInicio}
                      onChange={HandleChangeAct}
                    />
                  </div>
                  <div className='intro-y col-span-12 sm:col-span-6 flex ml-8 mt-5'>
                    <label className='font-medium bg-theme-2 box p-1'>
                      Fecha Fin :
                    </label>
                    <input
                      type='date'
                      className='input input--sm border rounded-lg w-64 ml-5'
                      required
                      min='0'
                      name='fechaCompromisoPago'
                      placeholder='Ingresar fecha final'
                      value={prestamoAct.fechaCompromisoPago}
                      onChange={HandleChangeAct}
                    />
                  </div>
                  <div className='intro-y col-span-12 sm:col-span-6 flex ml-8 mt-5'>
                    <label className='font-medium bg-theme-2 box p-1'>
                      Monto Préstamo :
                    </label>
                    <input
                      type='number'
                      className='input input--sm border rounded-lg w-64 ml-5'
                      required
                      min='0'
                      name='montoPrestado'
                      placeholder='Ingresar monto'
                      value={prestamoAct.montoPrestado}
                      onChange={HandleChangeAct}
                    />
                  </div>
                  <div className='intro-y col-span-12 sm:col-span-6 flex ml-8 mt-5'>
                    <label className='font-medium bg-theme-2 box p-1'>
                      Tasa de Interes Compuesto % :
                    </label>
                    <input
                      type='number'
                      className='input input--sm border rounded-lg w-64 ml-5'
                      required
                      min='0'
                      step='.01'
                      name='tasaInteresCompuesto'
                      placeholder='Nueva tasa de interes'
                      value={prestamoAct.tasaInteresCompuesto}
                      onChange={HandleChangeAct}
                    />
                  </div>

                  {/* Boton Evaluacion Prestramo */}
                  <div className='cursor-pointer w-full text-center my-6'>
                    <button
                      className='flex box text-white bg-theme-3 p-2 items-center w-full justify-center'
                      // onClick={() => guardarActualizacion}
                    >
                      <Icon.CheckSquare className='w-4 h-4 mr-2' />
                      Guardar actualización
                    </button>
                  </div>
                  {showAlert && (
                    <div className='col-span-12 mb-3'>
                      <div className='rounded-md flex items-center justify-center px-5 py-3 bg-theme-9 text-white'>
                        Datos Actualizados Exitosamente
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {/*===== Contenido Contactos =====*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon color='primary' />}
            aria-controls='panel2a-content'
            id='panel2a-header'
          >
            <Typography className={classes.heading}>
              <label className='font-medium text-base p-2 border-b-2 bg-theme-26 box ml-8'>
                <DoneAllIcon color='primary' /> ._ Contactos Sociales
              </label>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className='intro-y col-span-12 mb-3 bg-theme-2 box p-2 w-full'>
              <div className='col-span-12 overflow-auto lg:overflow-visible'>
                <div
                  style={{ height: 380, width: '100%' }}
                  className={classess.root}
                >
                  <DataGrid
                    rows={contactosSociales}
                    columns={columns}
                    pageSize={5}
                    components={{
                      pagination: CustomPagination,
                    }}
                    localeText={{
                      columnMenuLabel: 'Menu',
                      columnMenuShowColumns: 'Show columns',
                      columnMenuFilter: 'Filtrar',
                      columnMenuSortAsc: 'Ordenar Ascendente',
                      columnMenuSortDesc: 'Ordenar Descendente',
                      columnMenuHideColumn: '',
                      columnMenuUnsort: '',
                      footerRowSelected: (count) => count !== 1,
                      //   ? `${count.toLocaleString()} Cambiando Rol`
                      //   : `${count.toLocaleString()} Cambiando Rol`,
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>

          {/* Boton Modal Crear Contacto */}
          <div className='flex justify-center cursor-pointer mb-5'>
            <div className='box p-3 bg-theme-3 text-white w-48'>
              <button
                className='flex items-center'
                onClick={nuevoContactoSocial}
              >
                <Icon.FilePlus className='w-4 h-4 mr-1 ml-2' />
                Crear Nuevo Contacto
              </button>
            </div>
            {/* Boton Cargar CSV Contacto Sociales */}
            <div className='box p-3 bg-theme-3 text-white ml-8'>
              <button className='flex items-center' onClick={readCSV}>
                <Icon.Download className='w-4 h-4 mr-1 ml-2' />
                Cargar Contacto CSV
              </button>
            </div>
            <CSVReader
              inputStyle={{ display: 'none' }}
              inputId='fileempresas'
              cssClass='react-csv-input'
              label=''
              onFileLoaded={handleForce}
              onError={handleError}
              parserOptions={toParseOptions}
            />
          </div>
        </Accordion>
      </div>

      {prestamo.estado === 'pendiente' && (
        <div className='cursor-pointer w-full text-center my-4'>
          <button
            className='flex box border-2 border-red-300 font-semibold bg-theme-31 hover:text-red-700 hover:bg-theme-36 p-2 my-5 items-center w-full justify-center'
            onClick={() => rechazarSolicitud()}
          >
            <Icon.XSquare className='w-5 h-5 mr-2' />
            Rechazar préstamo
            <DeleteSweep
              className='ml-2 text-gray-700 hover:text-red-700'
              fontSize='small'
            />
          </button>
        </div>
      )}
      <h2 className='font-medium text-center mt-4 bg-theme-26 box p-1 w-56 mx-auto mb-2 border-b-2'>
        Chat <ChatIcon color='primary' fontSize='small' />
      </h2>
      <Chat
        chat={chat}
        gestor={true}
        prestamo={prestamo}
        rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
      />

      {/*===== Modal Crear Contacto =====*/}
      {modalCrearContactoSociales && (
        <Modal
          title='Crear contactos sociales'
          close={closeCrearContacto}
          save={guardarCrearContactos}
          cancel={cancelCrearContacto}
        >
          <div className='intro-y col-span-12'>
            <p className='font-semibold mb-2'>
              Complete todos los campos del formulario.
            </p>
            <hr />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Nombres :</label>
            <input
              type='text'
              name='nombres'
              className='w-full rounded-full border input p-1 mt-2'
              autoFocus={true}
              placeholder=' Nombre'
              value={Contactos.nombres}
              onChange={changeValueCrearContactos}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              Parentesco :
            </label>
            <input
              type='text'
              name='parentesco'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Parentesco'
              value={Contactos.parentesco}
              onChange={changeValueCrearContactos}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>URL :</label>
            <input
              type='text'
              name='url'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Url de la red social'
              value={Contactos.url}
              onChange={changeValueCrearContactos}
            />
          </div>
        </Modal>
      )}
      {/*===== Modal Editar Contacto =====*/}
      {modalEditarContactoSociales && (
        <Modal
          title='Editar contactos sociales'
          close={closeEditarContactos}
          save={guardarEditarContactos}
          cancel={cancelEditarContactos}
        >
          <div className='intro-y col-span-12'>
            <p className='font-semibold mb-2'>
              Complete todos los campos del formulario
            </p>
            <hr />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Nombres :</label>
            <input
              type='text'
              name='nombres'
              className='w-full rounded-full border input p-1 mt-2'
              autoFocus={true}
              placeholder=' Nombres'
              value={Contactos.nombres}
              onChange={changeValueEditarContactos}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              Parentesco :
            </label>
            <input
              type='text'
              name='parentesco'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Parentesco'
              value={Contactos.parentesco}
              onChange={changeValueEditarContactos}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>URL :</label>
            <input
              type='text'
              name='url'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Url de la red social'
              value={Contactos.url}
              onChange={changeValueEditarContactos}
            />
          </div>
        </Modal>
      )}
      {/*===== Modal Envio Correo Mensaje Rechazo=====*/}
      {modalEnvioCorreoMensajeRechazo && (
        <Modal
          title='Enviar correo y mensaje al chat'
          close={closeEnvioCorreoMensaje}
          cancel={cancelEnvioCorreoMensaje}
          customBtn={true}
          textcustomBtn='Enviar'
          save={() => {
            rechazar(false);
          }}
        >
          <div className='intro-y col-span-12'>
            <p className='font-semibold mb-2'>
              Indique el motivo por el cual se rechazo el prestamo.
            </p>
            <hr />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Texto del mensaje :
            </label>
            <textarea
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Agregar motivo de rechazo...'
              name='mensajeCorreo'
              value={envioCorreoMensaje.mensajeCorreo}
              onChange={changeValueEnvioCorreoMensaje}
              rows='5'
            />
          </div>
        </Modal>
      )}
      {/*===== Modal Envio Correo Rechazo Solicitud=====*/}
      {modalEnvioCorreoRechazoSolicitud && (
        <Modal
          title='Mensaje rechazo a correo y chat'
          close={closeEnvioCorreoRechazoSolicitud}
          cancel={cancelEnvioCorreoRechazoSolicitud}
          customBtn={true}
          textcustomBtn='Enviar'
          save={() => {
            rechazarSolicitudPrestamo(false);
          }}
        >
          <div className='intro-y col-span-12'>
            <p className='font-semibold mb-2'>
              Indique el motivo por el cual se rechazo el prestamo.
            </p>
            <hr />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Texto del mensaje :
            </label>
            <textarea
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Agregar motivo de rechazo...'
              name='mensajeCorreo'
              value={envioCorreoMensaje.mensajeCorreo}
              onChange={changeValueEnvioCorreoMensaje}
              rows='5'
            />
          </div>
        </Modal>
      )}
      {modalAbono && (
        <Modal
          title='Abonar Pago'
          close={cancelAbono}
          save={guardarAbono}
          cancel={cancelAbono}
          size='xl'
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='mb-2 font-medium text-xl'>
              Total deuda :{' '}
              {misabonos.length === 0 ? totalRestante() : totalRestanteAbonos()}
            </label>
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              n° de Transacción :
            </label>
            <input
              type='text'
              name='idTransaccion'
              placeholder=' N° de operacion'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.idTransaccion}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
            <input
              type='date'
              name='fecha'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.fecha}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <div className='flex items-center'>
              <label className='font-medium bg-theme-26 box p-1 mr-2'>
                Monto :
              </label>
              <input
                type='checkbox'
                className='input border'
                id='todo'
                name='monto[]'
                value='todo'
                onChange={changeMontoType}
                checked={montoPagar === 'todo'}
              />
              <label
                className='cursor-pointer select-none ml-1 mr-3'
                htmlFor='todo'
              >
                Todo :
              </label>
              <input
                type='checkbox'
                className='input border mr-2'
                id='abono'
                name='monto[]'
                value='abono'
                onChange={changeMontoType}
                checked={montoPagar === 'abono'}
              />
              <label
                className='cursor-pointer select-none ml-1'
                htmlFor='abono'
              >
                Abono :
              </label>
            </div>
            <input
              type='text'
              name='monto'
              placeholder=' Monto de la operación'
              className='w-full rounded-full border input p-1 mt-2'
              value={pago.monto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              disabled={disabled}
            />
          </div>
          <div className='flex col-span-12 sm:col-span-6 mt-8 justify-between'>
            <label className='font-medium bg-theme-26 box p-2 ml-8'>
              Adjuntar recibo:
            </label>
            <div className='flex'>
              <button
                className='button font-medium text-theme-5 bg-theme-1 rounded-full flex mr-8'
                onClick={(e) => openExplorerPago(e, abono)}
              >
                <Icon.FilePlus className='w-5 h-5 mr-2 pointer' />
                Subir Archivo
              </button>
            </div>
          </div>
          <div className='intro-y col-span-12 sm:col-span-12 bg-theme-2 box'>
            {fileName !== '' && (
              <div className='w-auto flex ml-2 justify-center p-1'>
                <label>{fileName}</label>
                <Icon.X
                  className='w-5 h-5 ml-3 pointer box bg-theme-5'
                  onClick={removeRecibo}
                />
              </div>
            )}
            <input
              type='file'
              style={{ display: 'none' }}
              ref={fileRef}
              onChange={loadFileAbono}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Comentario :
            </label>
            <textarea
              type='text'
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Concepto del abono... '
              name='concepto'
              value={pago.concepto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              rows='2'
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default GestionarPrestamo;
