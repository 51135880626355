import {
  GET_USERS,
  GET_HABILITADO,
  GET_INGRESOS,
  GET_EGRESOS,
  MODAL_INGRESOSCONTABLES,
  MODAL_EGRESOSCONTABLES,
  MODAL_EDITARMOVIMIENTOSCONTABLES,
  GET_LISTPRESTAMOSAPROBADOS,
  MODAL_PAGOS,
  GET_PAGOSREALIZADOS,
  GET_ABONOSREALIZADOS,
  GET_PRESTAMO,
  GET_USUARIO,
  GET_NOTIFICACIONES,
  MODAL_NOTIFICACIONES,
  GET_LISTPRESTAMOS,
  MODAL_EDITARNOTIFICACIONES,
  GETCHAT,
  GET_RESGISTROACTIVIDAD,
  GET_LISTSINFONDOS,
} from '../types';

import { app } from '../../config/firebaseConfig';
import moment from 'moment';
import { success } from '../../helpers/alertas';

export const getUsers = () => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .orderBy('fechaRegistro', 'desc')
    .get()
    .then((data) => {
      let allUsers = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        allUsers.push(obj);
      });
      dispatch({
        type: GET_USERS,
        payload: allUsers,
      });
    });
};

export const getUsuario = (id) => async (dispatch) => {
  await app
    .firestore()
    .collection('usuarios')
    .doc(id)
    .get()
    .then((doc) => {
      dispatch({ type: GET_USUARIO, payload: doc.data() });
    });
};

export const getUsersHabilitado = () => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .orderBy('habilitadoNuevoPrestamo', 'desc')
    .where('rol', '==', 'Usuario')
    .get()
    .then((data) => {
      let allUsersHabilitado = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        allUsersHabilitado.push(obj);
      });
      dispatch({
        type: GET_HABILITADO,
        payload: allUsersHabilitado,
      });
    });
};

export const getIngresos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('ingresos')
    .get()
    .then(async (data) => {
      let ingresosContables = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        ingresosContables.push(obj);
      });

      dispatch({
        type: GET_INGRESOS,
        payload: ingresosContables,
      });
    });
};

export const getEgresos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('egresos')
    .get()
    .then(async (data) => {
      let egresosContables = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        egresosContables.push(obj);
      });

      dispatch({
        type: GET_EGRESOS,
        payload: egresosContables,
      });
    });
};

//========== Listado de Prestamos Aprobados ==========//
export const getListPrestamos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    // .orderBy('fechaSolicitud', 'desc')
    .orderBy('fechaActualizacion', 'desc')
    // .where("estado", "==", "prestado")
    .get()
    .then((data) => {
      let listPrestamosAprobados = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listPrestamosAprobados.push(obj);
      });
      dispatch({
        type: GET_LISTPRESTAMOSAPROBADOS,
        payload: listPrestamosAprobados,
      });
    });
};

//========== Modal Agregar Ingresos Contables ==========//
export const modalAgregarIngresosContable = (value) => (dispatch) => {
  dispatch({ type: MODAL_INGRESOSCONTABLES, payload: value });
};

export const getIngresosContable = () => async (dispatch) => {
  await app
    .firestore()
    .collection('ingresos')
    .get()
    .then(async (data) => {
      let ingresosContables = [];
      let ingreso = {};

      data.forEach((doc) => {
        ingreso = doc.data();
        ingreso.id = doc.id;
        ingresosContables.push(ingreso);
      });
      dispatch({
        type: GET_INGRESOS,
        payload: ingresosContables,
      });
    });
};

export const saveAgregarIngresosContable =
  (movimientoContable) => (dispatch) => {
    app
      .firestore()
      .collection('ingresos')
      .add(movimientoContable)
      .then((resp) => {
        dispatch(getIngresosContable());
        dispatch({ type: MODAL_INGRESOSCONTABLES, payload: false });
      });
  };

//========== Modal Agregar Egresos Contables ==========//
export const modalAgregarEgresosContable = (value) => (dispatch) => {
  dispatch({ type: MODAL_EGRESOSCONTABLES, payload: value });
};

export const getEgresosContable = () => async (dispatch) => {
  await app
    .firestore()
    .collection('egresos')
    .get()
    .then(async (data) => {
      let egresosContables = [];
      let egreso = {};

      data.forEach((doc) => {
        egreso = doc.data();
        egreso.id = doc.id;
        egresosContables.push(egreso);
      });
      dispatch({
        type: GET_EGRESOS,
        payload: egresosContables,
      });
    });
};

export const saveAgregarEgresosContable =
  (movimientoContable) => (dispatch) => {
    app
      .firestore()
      .collection('egresos')
      .add(movimientoContable)
      .then((resp) => {
        dispatch(getEgresosContable());
        dispatch({ type: MODAL_EGRESOSCONTABLES, payload: false });
      });
  };

//========== Modal Editar mensajes ==========//
export const modalEditarMovimientoContable = (value) => (dispatch) => {
  dispatch({ type: MODAL_EDITARMOVIMIENTOSCONTABLES, payload: value });
};

export const updateEditarMovimientoContable =
  (movimientoContable, idRegistro) => (dispatch) => {
    if (movimientoContable.tipo === 'ingreso') {
      app
        .firestore()
        .collection('ingresos')
        .doc(`${idRegistro}`)
        .update(movimientoContable)
        .then((resp) => {
          dispatch(getIngresosContable());
          dispatch({ type: MODAL_EDITARMOVIMIENTOSCONTABLES, payload: false });
        });
    } else {
      app
        .firestore()
        .collection('egresos')
        .doc(`${idRegistro}`)
        .update(movimientoContable)
        .then((resp) => {
          dispatch(getEgresosContable());
          dispatch({ type: MODAL_EDITARMOVIMIENTOSCONTABLES, payload: false });
        });
    }
  };

export const deleteMovimientoContable = (movimientoContable) => (dispatch) => {
  if (movimientoContable.tipo === 'ingreso') {
    app
      .firestore()
      .collection('ingresos')
      .doc(`${movimientoContable.id}`)
      .delete()
      .then((resp) => {
        dispatch(getIngresosContable());
      });
  } else {
    app
      .firestore()
      .collection('egresos')
      .doc(`${movimientoContable.id}`)
      .delete()
      .then((resp) => {
        dispatch(getEgresosContable());
      });
  }
};

//========== Modal Agregar Pagos ==========//
export const modalAgregarPago = (value) => (dispatch) => {
  dispatch({ type: MODAL_PAGOS, payload: value });
};

export const saveAgregarPagos =
  (pagos, egresoPrestamo, prestamoid) => (dispatch) => {
    app
      .firestore()
      .collection('pagos')
      .add(pagos)
      .then((resp) => {
        app
          .firestore()
          .collection('egresos')
          .add({
            nombres: egresoPrestamo.nombres,
            apellidos: egresoPrestamo.apellidos,
            categoria: egresoPrestamo.categoria,
            concepto: egresoPrestamo.concepto,
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            monto: parseFloat(egresoPrestamo.monto),
            tipo: egresoPrestamo.tipo,
            idPrestamo: egresoPrestamo.idPrestamo,
            idPago: resp.id,
          });

        app
          .firestore()
          .collection('prestamos')
          .doc(`${pagos.idPrestamo}`)
          .update({
            montoRealPrestado: egresoPrestamo.monto,
            pagoEnviado: true,
            estado: 'prestado',
          })
          .then((resp) => {
            dispatch(getPrestamo(pagos.idPrestamo));
          });
        dispatch(getPagosRealizados(pagos.idPrestamo));
        dispatch({ type: MODAL_PAGOS, payload: false });
      });
  };

//========== Pagos Realizados ==========//
export const getPagosRealizados = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('pagos')
    .where('idPrestamo', '==', idPrestamo)
    .get()
    .then(async (data) => {
      let pagosRealizados = [];
      let pago = {};

      data.forEach((doc) => {
        pago = doc.data();
        pago.id = doc.id;
        pagosRealizados.push(pago);
      });

      dispatch({
        type: GET_PAGOSREALIZADOS,
        payload: pagosRealizados,
      });
    });
};

export const getAbonosRealizados = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('abonos')
    .where('idPrestamo', '==', idPrestamo)
    .orderBy('fecha', 'asc')
    .get()
    .then(async (data) => {
      let abonosRealizados = [];
      let abono = {};

      data.forEach((doc) => {
        abono = doc.data();
        abono.id = doc.id;
        abonosRealizados.push(abono);
      });

      dispatch({
        type: GET_ABONOSREALIZADOS,
        payload: abonosRealizados,
      });
    });
};

//========== Confirmacion Abonos Realizados ==========//
export const updateAbonoPrestamo =
  (idPrestamo, idAbono, ingreso, abonos) => (dispatch) => {
    app
      .firestore()
      .collection('abonos')
      .doc(`${idAbono}`)
      .update({ confirmado: true })
      .then((resp) => {
        app.firestore().collection('ingresos').add({
          nombres: ingreso.nombres,
          apellidos: ingreso.apellidos,
          monto: ingreso.monto,
          tipo: 'ingreso',
          categoria: 'PagoRecibido',
          concepto: 'AbonoPrestamo',
          idTransaccion: ingreso.idTransaccion,
          idPrestamo: idPrestamo,
          fecha: ingreso.fecha,
        });

        app
          .firestore()
          .collection('prestamos')
          .doc(`${idPrestamo}`)
          .update({ montoRecibido: abonos })
          .then((resp) => {
            dispatch(getPrestamo(idPrestamo));
          });

        dispatch(getAbonosRealizados(idPrestamo));
      });
  };

//========== Pagos Conpletados ==========//
export const getPrestamo = (id) => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .doc(id)
    .get()
    .then((doc) => {
      dispatch({ type: GET_PRESTAMO, payload: doc.data() });
    });
};

export const updatePagoCompletado =
  (idPrestamo, pagoCompleto) => (dispatch) => {
    app
      .firestore()
      .collection('prestamos')
      .doc(`${idPrestamo}`)
      .update(pagoCompleto)
      .then((resp) => {
        success('Operación realizada exitosamente.', 'success');
        dispatch(getPrestamo(idPrestamo));
      });
  };

export const getNotificaciones = () => async (dispatch) => {
  await app
    .firestore()
    .collection('notificaciones')
    .get()
    .then(async (data) => {
      let notificaciones = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        notificaciones.push(obj);
      });

      dispatch({
        type: GET_NOTIFICACIONES,
        payload: notificaciones,
      });
    });
};

export const registrarNotificacion = (notificacion) => (dispatch) => {
  app
    .firestore()
    .collection('notificaciones')
    .add(notificacion)
    .then((resp) => {
      dispatch(getNotificaciones());
      dispatch({ type: MODAL_NOTIFICACIONES, payload: false });
    });
};

//========== Modal Editar Notificaciones ==========//
export const modalEditarNotificacion = (value) => (dispatch) => {
  dispatch({ type: MODAL_EDITARNOTIFICACIONES, payload: value });
};

export const updateEditarNotificacion = (notificacion) => (dispatch) => {
  app
    .firestore()
    .collection('notificaciones')
    .doc(`${notificacion.idNotificaciones}`)
    .update(notificacion)
    .then((resp) => {
      dispatch(getNotificaciones());
      dispatch({ type: MODAL_EDITARNOTIFICACIONES, payload: false });
    });
};

export const deleteNotificacion = (notificacion) => (dispatch) => {
  app
    .firestore()
    .collection('notificaciones')
    .doc(`${notificacion.id}`)
    .delete()
    .then((resp) => {
      dispatch(getNotificaciones());
    });
};

export const getListadoPrestamos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .get()
    .then((data) => {
      let listadoPrestamos = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listadoPrestamos.push(obj);
      });
      dispatch({
        type: GET_LISTPRESTAMOS,
        payload: listadoPrestamos,
      });
    });
};

export const sendMsg = (chat, mensajes) => (dispatch) => {
  let newChat = {};
  newChat = {
    id: chat.id,
    fecha: chat.fecha,
    idChat: chat.idChat,
    idPrestamo: chat.idPrestamo,
    mensajes,
  };
  dispatch({ type: GETCHAT, payload: newChat });
  app
    .firestore()
    .collection('chat')
    .doc(`${chat.id}`)
    .update({ mensajes })
    .then((resp) => {
      //dispatch(getChat(chat.idPrestamo))
    });
};

export const getChat = (idPrestamo) => (dispatch) => {
  app
    .firestore()
    .collection('chat')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .get()
    .then((data) => {
      let array = [];
      let objeto = {};
      data.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETCHAT, payload: array[0] });
    });
};

//========== Registro Actividades ==========//
export const getRegistroActividad = () => (dispatch) => {
  app
    .firestore()
    .collection('log')
    .orderBy('fecha', 'desc')
    .get()
    .then((data) => {
      let registroActividad = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        registroActividad.push(obj);
      });
      dispatch({
        type: GET_RESGISTROACTIVIDAD,
        payload: registroActividad,
      });
    });
};

//========== Listado Usuarios Sin Fondos ==========//
export const getListSinFondos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('logsinfondos')
    .get()
    .then((data) => {
      let listUsuariosSinFondos = [];
      let obj = {};
      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listUsuariosSinFondos.push(obj);
      });
      dispatch({
        type: GET_LISTSINFONDOS,
        payload: listUsuariosSinFondos,
      });
    });
};
