import {
  SETCUENTABANCARIA,
  SETPAGOMOVIL,
  GETPAGOSMOVILES,
  GETCUENTASBANCARIAS,
  DELETECUENTABANCARIA,
  DELETEPAGOMOVIL,
  MODALCUENTA,
  MODALCUENTAECU,
  MODALCUENTACOL,
  MODALCUENTAPAN,
  MODALPAGOMOVIL,
  SELECTEDCUENTA,
  WIZARD,
  PRESTAMOSUSUARIOS,
  PRESTAMOACTUAL,
  DATOSGENERELES,
  GETSOCIALES,
  GETSOCIALESUSER,
  REQUERIMIENTOS,
  REQUERIMIENTOPRESTAMO,
  GETCHAT,
  GETCHATADMIN,
  GETMISPRESTAMOS,
  MODALABONO,
  GETABONOS,
  GET_CONFIGURACION,
  REDESVISITADAS,
  MODAL_EDITARPERFIL,
  GET_USERS,
  MODAL_METODOPAGO,
} from '../types';

const initialState = {
  cuentasBancarias: [],
  pagoMoviles: [],
  modalPagoMovil: false,
  modalCuentaBanacaria: false,
  modalCuentaBcoEcu: false,
  modalCuentaBcoCol: false,
  modalCuentaBcoPan: false,
  selectedCuenta: null,
  indexWizard: 1,
  prestamosUsuario: [],
  datosGenerales: null,
  linksociales: [],
  linksocialesUser: [],
  prestamoActual: null,
  requerimientos: [],
  requerimientoPrestamo: [],
  chat: {},
  chatAdmin: {},
  misprestamos: [],
  modalAbono: false,
  misabonos: [],
  configuracionGlobal: [],
  redesvisitadas: [],
  modalEditarPerfiles: false,
  allUsers: [],
  modalMetodosPagos: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETPAGOSMOVILES:
      return {
        ...state,
        pagoMoviles: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case SETPAGOMOVIL:
      state.pagoMoviles.push(action.payload);
      return {
        ...state,
      };
    case DELETEPAGOMOVIL:
      state.pagoMoviles.splice(0, action.payload);
      return {
        ...state,
      };
    case GETCUENTASBANCARIAS:
      return {
        ...state,
        cuentasBancarias: action.payload,
      };
    case SETCUENTABANCARIA:
      state.cuentasBancarias.push(action.payload);
      return {
        ...state,
      };
    case DELETECUENTABANCARIA:
      state.cuentasBancarias.splice(0, action.payload);
      return {
        ...state,
      };
    case MODALCUENTA:
      return {
        ...state,
        modalCuentaBanacaria: action.payload,
      };
    case MODALCUENTAECU:
      return {
        ...state,
        modalCuentaBcoEcu: action.payload,
      };
    case MODALCUENTACOL:
      return {
        ...state,
        modalCuentaBcoCol: action.payload,
      };
    case MODALCUENTAPAN:
      return {
        ...state,
        modalCuentaBcoPan: action.payload,
      };
    case MODALPAGOMOVIL:
      return {
        ...state,
        modalPagoMovil: action.payload,
      };
    case MODAL_METODOPAGO:
      return {
        ...state,
        modalMetodosPagos: action.payload,
      };
    case SELECTEDCUENTA:
      return {
        ...state,
        selectedCuenta: action.payload,
      };
    case WIZARD:
      return {
        ...state,
        indexWizard: action.payload,
      };
    case PRESTAMOSUSUARIOS:
      return {
        ...state,
        prestamosUsuario: action.payload,
      };
    case DATOSGENERELES:
      return {
        ...state,
        datosGenerales: action.payload,
      };
    case GETSOCIALES:
      return {
        ...state,
        linksociales: action.payload,
      };
    case GETSOCIALESUSER:
      return {
        ...state,
        linksocialesUser: action.payload,
      };
    case REQUERIMIENTOS:
      return {
        ...state,
        requerimientos: action.payload,
      };
    case REQUERIMIENTOPRESTAMO:
      return {
        ...state,
        requerimientoPrestamo: action.payload,
      };
    case PRESTAMOACTUAL:
      return {
        ...state,
        prestamoActual: action.payload,
      };
    case GETCHAT:
      return {
        ...state,
        chat: action.payload,
      };
    case GETCHATADMIN:
      return {
        ...state,
        chatAdmin: action.payload,
      };
    case GETMISPRESTAMOS:
      return {
        ...state,
        misprestamos: action.payload,
      };
    case MODALABONO:
      return {
        ...state,
        modalAbono: action.payload,
      };
    case GETABONOS:
      return {
        ...state,
        misabonos: action.payload,
      };
    case GET_CONFIGURACION:
      return {
        ...state,
        configuracionGlobal: action.payload,
      };
    case REDESVISITADAS:
      return {
        ...state,
        redesvisitadas: action.payload,
      };
    case MODAL_EDITARPERFIL:
      return {
        ...state,
        modalEditarPerfiles: action.payload,
      };
    default:
      return state;
  }
}
