import React, { useState } from "react";
import { useDispatch } from "react-redux";

//MUI Stuff
import Dialog from "@material-ui/core/Dialog";
// import { DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

import { updateCode } from "../../redux/actions/sesionAction";

export default function ModalAddCode(props) {
  const dispatch = useDispatch();
  const { open, setModalCode, code, email } = props;
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");
  const [codeState, setCodeState] = useState(code);
  const [segure, setSegure] = useState(true);

  const handleClose = () => {
    setModalCode(false);
    setDone(false);
  };
  const handleGuardar = () => {
    const bitcoinCode = codeState.trim();
    if (bitcoinCode.length === 0) {
      setError("Por favor ingrese su codigo de bitcoin correctamente");
    } else if (bitcoinCode.length < 26 || bitcoinCode.length > 35) {
      setError("Su codigo de bitcoin no cumple con el formato estandar");
    } else if (bitcoinCode.charAt(0) !== "3" && bitcoinCode.charAt(0) !== "1") {
      setError("Su codigo de bitcoin no cumple con el formato estandar");
    } else {
      setError("");
      setCodeState(bitcoinCode);
      setDone(true);
      setSegure(true);
      document.getElementById("myCode").setAttribute("disabled", true);
      dispatch(updateCode(email, codeState.trim()));
    }
  };

  const handleChangeCode = (e) => {
    setCodeState(e.target.value);
  };

  const handleEditCode = () => {
    setSegure(false);
    document.getElementById("myCode").removeAttribute("disabled");
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      {/* <div class="modal__content modal__content--lg p-10 text-center"> This is totally awesome large modal! </div> */}

      <div id="warning-modal-preview">
        <div className="modal__content modal__content--lg p-10 w-full ">
          <h2 class="text-3xl text-theme-1 font-medium leading-none mb-8">
            Código de recepción
          </h2>
          <form>
            <div className="relative mt-2">
              <input
                type="text"
                id="myCode"
                className="input pr-12 w-full border col-span-4"
                value={codeState}
                onChange={handleChangeCode}
                placeholder="Ingrese su codigo de recepcion"
                disabled
              />
              {segure ? (
                <button
                  type="button"
                  onClick={handleEditCode}
                  className="bg-theme-1 text-white absolute top-0 right-0 rounded-r w-20 h-full flex items-center justify-center"
                >
                  Modificar
                </button>
              ) : null}
            </div>
            <span style={{ color: "red" }}>{error}</span>
            {done ? (
              <span className="text-theme-10">Código guardado con éxito.</span>
            ) : null}
          </form>
          <div className="px-5 text-center mt-8">
            <button
              type="button"
              className="button w-24 bg-theme-30 mr-2"
              onClick={handleClose}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="button w-24 bg-theme-1 text-white mr-2"
              onClick={handleGuardar}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>

      {/* <DialogTitle id="alert-dialog-title">
        Código de recepción
      </DialogTitle>
      <DialogContent>
          <form>
              <div class="relative mt-2"> 
                  <input type="text" id="myCode" class="input pr-12 w-full border col-span-4" value={codeState} onChange={handleChangeCode} placeholder="Ingrese su codigo de recepcion" disabled/>
                  {segure ? <button type="button" onClick={handleEditCode} className="bg-theme-1 text-white absolute top-0 right-0 rounded-r w-20 h-full flex items-center justify-center">Modificar</button> : null}
              </div>
              <span style={{color: 'red'}}>{error}</span>
                {done ? <span className="text-theme-10">Código guardado con éxito.</span> : null}
          </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cerrar
        </Button>
        <Button onClick={handleGuardar} color="primary">
            Guardar
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}
