import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { app } from '../../config/firebaseConfig';
import * as Icon from 'react-feather';
import countrys from '../../config/pais';
import { axiosInstance } from '../../config/api';
import Loading from '../../components/Loading';
import { DATOSGENERELES, GET_MYUSER } from '../../redux/types';
import { addLog } from '../../redux/actions/sesionAction';
import { custom } from '../../helpers/alertas';
import { updateDatosUser } from '../../redux/actions/userAction';

const { REACT_APP_API } = process.env;

const Verificacion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  document.body.classList.remove('login');
  document.body.classList.add('app');

  //Mostrar listado de paises
  let arrayOptions = [];
  arrayOptions = countrys.listado.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  const [option, setOption] = useState(arrayOptions);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);

  //========== USE-STATES ==========//
  const [verificado, setVerificado] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [loading, setLoading] = useState(true);
  const [generales, setGenerales] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    fechaNacimiento: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: 'Venezuela - Transferencia: Cuenta Bancaria BDV',
    nombres: '',
    nombreEmpresa: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    sexo: 'M',
    telefonoOpcional: '',
    telegram: '',
    trabaja: 'No',
    whatsapp: '',
  });
  const [defaultValue, setDefaultValue] = useState({
    value: generales.pais,
    label: generales.pais,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [submit, setSubmit] = useState(false);

  const handleVerifyEmail = (auth, actionCode, continueUrl, lang) => {
    if (verificado === false) {
      auth
        .applyActionCode(actionCode)
        .then(function (resp) {
          setMensaje('Verificación de correo electrónico exitosa…');
          setVerificado(true);
        })
        .catch(function (error) {
          console.log('error', error);
          setMensaje('Error el enlace ha expirado, o ya ha sido utilizado.');
          setVerificado(false);
        });
    }
  };

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  useEffect(() => {
    /*if(app.auth().currentUser){
      handleVerifyEmail(app.auth(), getParameterByName('oobCode'), '', 'es')
    } else {
      setMensaje('Error el enlace no es valido.')
      setVerificado(false)
    }*/
    const code = getParameterByName('Code');
    axiosInstance
      .post(`/user/confirm_email`, { hash: code })
      .then((resp) => {
        if (resp.data.success) {
          setVerificado(true);
          setMensaje(
            'Verificación de correo electrónico exitosa… Complete los datos solicitados para continuar…'
          );
        } else {
          setVerificado(false);
          setMensaje('Error el enlace no es valido.');
        }
        setLoading(false);
      })
      .catch((e) => {
        setVerificado(false);
        setMensaje('Error al intentar validadr el correo electrónico.');
        setLoading(false);
      });
  }, []);

  const bgColor = () => {
    if (verificado) {
      return 'bg-theme-9';
    } else {
      return 'bg-theme-6';
    }
  };

  const actividadVerify = () => {
    dispatch(
      addLog({
        accion: `${'El Usuario: '} ${
          myUser.email
        } ${'Ha verificado su correo exitosamente.'}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };

  //========== Funciones Metodos formulario ==========//
  const viewAlert = (show) => {
    setShowAlert(show);
  };
  const guardarData = (e) => {
    e.preventDefault();
    if (
      generales.nombres === '' ||
      generales.apellidos === '' ||
      generales.pais === '' ||
      generales.whatsapp === ''
    ) {
      custom('Faltan datos por llenar para poder iniciar sesión', 'error');
    } else {
      try {
        setSubmit(true);
        setTimeout(() => {
          let data = { ...generales };
          dispatch(updateDatosUser(data));
          const userUpdtae = Object.assign(myUser, data);
          dispatch({ type: GET_MYUSER, payload: userUpdtae });
          viewAlert(true);
          setSubmit(false);
          viewAlert(false);
        }, 2000);
      } catch (error) {
        console.log('Error', error);
      }
    }
  };
  const changeGenerales = (e) => {
    const { name, value } = e.target;
    let dataUpdate = { ...generales, [name]: value };
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };
  const changeValuePais = (e) => {
    setDefaultValue(e.label);
    const value = e.value;
    const label = e.label;
    setGenerales({
      ...generales,
      ['pais']: label,
      ['codigoPais']: value,
    });
    dispatch({ type: DATOSGENERELES, payload: generales });
  };

  return loading ? (
    <Loading />
  ) : (
    <div className='grid grid-cols-12 gap-4 row-gap-5 mb-8 mt-20'>
      <div className='col-span-12 md:col-span-3'></div>
      <div className='col-span-12 md:col-span-6'>
        <div className='box'>
          <div className='flex p-3 border-b text-base items-center justify-center'>
            <h2 className='font-medium'>Verificación de correo electrónico</h2>
          </div>
          <div className='intro-y px-6 mt-4' id='data_personal'>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <div className='text-base font-medium text-gray-900'>
                Complete los datos para poder Iniciar Sesión.
              </div>
              <div className='text-sm text-center font-medium text-gray-700 my-2'>
                La aprobación del micropréstamo dependerá de la veracidad de los
                datos que se solicitan para evaluar su perfil, evite suministrar
                información errada.
              </div>
            </div>
            <form action='' className='mt-10' onSubmit={guardarData}>
              <div className='grid grid-cols-12 gap-4 row-gap-5'>
                <div className='intro-y col-span-12 sm:col-span-6'>
                  <label className='font-medium bg-theme-26 box p-1'>
                    Nombres completos :
                  </label>
                  <input
                    type='text'
                    name='nombres'
                    value={generales.nombres}
                    className='w-full rounded-full border intro-x input input--sm border mt-2'
                    autoFocus={true}
                    placeholder=' Nombres Completos'
                    onChange={changeGenerales}
                    onKeyPress={(event) => {
                      if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className='intro-y col-span-12 sm:col-span-6'>
                  <label className='font-medium bg-theme-26 box p-1'>
                    Apellidos completos :
                  </label>
                  <input
                    type='text'
                    name='apellidos'
                    value={generales.apellidos}
                    className=' w-full rounded-full border intro-x input input--sm border mt-2'
                    placeholder='Apellidos Completos'
                    onChange={changeGenerales}
                    onKeyPress={(event) => {
                      if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className='intro-y col-span-12 sm:col-span-6'>
                  <label className='font-medium bg-theme-26 box p-1'>
                    País :
                  </label>
                  <Select
                    className='mt-1'
                    name='pais'
                    defaultValue={defaultValue}
                    options={option}
                    onChange={changeValuePais}
                  />
                </div>
                <div className='intro-y col-span-12 sm:col-span-6'>
                  <label className='font-medium bg-theme-26 box p-1'>
                    Whatsapp :
                  </label>
                  <div className='flex'>
                    <select
                      className='w-20 mr-2 rounded-full border intro-x input input--sm border mt-2'
                      name='codigoPais'
                      value={generales.codigoPais}
                      onChange={changeGenerales}
                    >
                      <option value=''>00</option>
                      {countrys.listado.map((item, k) => (
                        <option value={item.codigo} key={k}>
                          {item.codigo}
                        </option>
                      ))}
                    </select>
                    <input
                      type='text'
                      name='whatsapp'
                      value={generales.whatsapp}
                      className='w-full rounded-full border intro-x input input--sm border mt-2'
                      placeholder='Numero de Whatsapp'
                      onChange={changeGenerales}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Boton Guardar */}
              {myUser.nombres === '' && (
                <div className='cursor-pointer w-full text-center my-8'>
                  <button
                    type='submit'
                    className='flex box text-white bg-theme-3 p-2 items-center w-full justify-center'
                  >
                    {!submit && (
                      <span className='flex indicator-label'>
                        <Icon.CheckSquare className='w-4 h-4 mr-2' />
                        <span>Guardar</span>
                      </span>
                    )}
                    {submit && (
                      <span className='indicator-label'>
                        Cargando... _
                        <span className='spinner-border spinner-border-sm  align-middle ml-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              )}
              {showAlert && (
                <div className='col-span-12 mt-8'>
                  <div className='rounded-md flex items-center justify-center px-5 py-3 bg-theme-9 text-white'>
                    Datos Guardados Exitosamente
                  </div>
                </div>
              )}
            </form>
          </div>
          {mensaje !== '' && myUser.nombres === '' && (
            <div className='flex p-8 flex-col'>
              <div
                className={[
                  'rounded-md',
                  'px-5',
                  'py-4',
                  'mb-2',
                  'text-white',
                  bgColor(),
                ].join(' ')}
              >
                {mensaje}
              </div>
            </div>
          )}
          {myUser.nombres !== '' && (
            <div className='flex items-center p-8 mt-5 justify-center'>
              <a
                className='button bg-theme-1 text-white w-56 mr-2'
                href='/login'
                onClick={actividadVerify}
              >
                Haz clic aquí para iniciar sesión
              </a>
            </div>
          )}
        </div>
      </div>
      <div className='col-span-12 md:col-span-3'></div>
    </div>
  );
};

export default Verificacion;
