import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { app } from '../../../config/firebaseConfig';
import moment from 'moment';
import Modal from '../../Modal';
import ChatSuper from '../../chat/Chat';
import ChatAdmin from '../../chat/ChatAdmin';
import InicioChat from './InicioChat';
import Profile13 from '../../../statics/images/profile-13.jpg';
import * as Icon from 'react-feather';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Divider from '@material-ui/core/Divider';

import {
  GETCHAT,
  LOADING,
  ENDLOADING,
  GETCHATADMIN,
} from '../../../redux/types';
import { getListPrestamos } from '../../../redux/actions/gestorActions';

const SuperChat = (props) => {
  const dispatch = useDispatch();

  //========== USE-STATE ==========//
  const [listUserChat, setListUserChat] = useState([]);
  const [chatSeleccionado, setChatSeleccionado] = useState(null);
  const [listadoPrestamos, setListadoPrestamos] = useState([]);
  const [prestamo, setPrestamo] = useState(null);
  const [filterByUser, setFilterByUser] = useState('');
  const [listadoFiltrado, setListadoFiltrado] = useState([]);
  const [listadoAdmin, setListadoAdmin] = useState([]);
  const [detallesUsuario, setDetallesUsuario] = useState(null);
  const [misPrestamos, setMisPrestamos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [toggleState, setToggleState] = useState(1);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const chat = useSelector((state) => state.user.chat);
  const chatAdmin = useSelector((state) => state.user.chatAdmin);
  const prestamos = useSelector((state) => state.gestor.listPrestamos);

  //========== USE-Effect ==========//
  useEffect(() => {
    dispatch(getListPrestamos());
    listUserChatSuper();
    listUserChatAdmin();
    let query = app.firestore().collection('chat');

    query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'modified') {
            if (chatSeleccionado && chatSeleccionado.id) {
              getChat(chatSeleccionado.id);
              getChatAdmin(chatAdmin.id);
              listUserChatSuper();
              listUserChatAdmin();
            } else {
              listUserChatSuper();
              listUserChatAdmin();
            }
          }
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  }, [chatSeleccionado]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const listUserChatSuper = async () => {
    await app
      .firestore()
      .collection('prestamos')
      .where('pagado', '==', false)
      .orderBy('fechaInicio', 'desc')
      .get()
      .then(async (data) => {
        let listPrestamos = [];
        let obj = {};
        let listChat = [];
        let objChat = {};
        data.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          listPrestamos.push(obj);
        });
        setListadoPrestamos(listPrestamos);
        for (let i = 0; i < listPrestamos.length; i++) {
          const ptmo = listPrestamos[i];
          const resp = await app
            .firestore()
            .collection('chat')
            .where('idPrestamo', '==', ptmo.id)
            .get();
          resp.forEach((chat) => {
            objChat = chat.data();
            objChat.id = chat.id;
            objChat.idUsuario = ptmo.usuario.idUsuario;
            objChat.rol = ptmo.usuario.rol;
            objChat.usuario = `${ptmo.usuario.nombres} ${ptmo.usuario.apellidos}`;
            objChat.urlImagen = ptmo.usuario.urlImagen;
            listChat.push(objChat);
          });
        }
        //ordenar aqui
        listChat.sort(sortByDate);
        setListUserChat(listChat);
        setListadoFiltrado(listChat);
      });
  };

  const listUserChatAdmin = async () => {
    await app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Bloqueado'])
      .get()
      .then(async (data) => {
        let listAdmin = [];
        let obj = {};
        data.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          obj.rol = doc.data().rol;
          obj.usuario = `${doc.data().nombres} ${doc.data().apellidos}`;
          listAdmin.push(obj);
        });
        const filter = listAdmin.filter((item) => {
          return item.idUsuario !== myUser.idUsuario;
        });
        setListadoAdmin(filter);
      });
  };

  const sortByDate = (a, b) => {
    let dateA = new Date(a.fechaActualizacion).getTime();
    let dateB = new Date(b.fechaActualizacion).getTime();
    return dateA < dateB ? 1 : -1;
  };

  const selectedChat = (e, chat) => {
    e.preventDefault();
    const ptmo = listadoPrestamos.find((fila) => fila.id === chat.idPrestamo);
    setPrestamo(ptmo);
    setChatSeleccionado(chat);
    getChat(chat.id);
  };

  const selectedChatAdmin = (e, usuario) => {
    e.preventDefault();
    setChatSeleccionado(usuario);
    getChatAdmin(usuario.idUsuario);
  };

  const getChat = (idChat) => {
    dispatch({ type: LOADING });
    app
      .firestore()
      .collection('chat')
      .doc(`${idChat}`)
      .get()
      .then((doc) => {
        let objeto = {};
        objeto = doc.data();
        objeto.id = doc.id;
        dispatch({ type: GETCHAT, payload: objeto });
        dispatch({ type: ENDLOADING });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ENDLOADING });
      });
  };

  const getChatAdmin = async (idUsuario) => {
    dispatch({ type: LOADING });
    await app
      .firestore()
      .collection('chatAdmin')
      .where('idUsuario', '==', `${idUsuario}`)
      .get()
      .then(async (data) => {
        let chatsList = [];
        data.forEach((doc) => {
          let chatobj = {};
          chatobj = doc.data();
          chatobj.id = doc.id;
          chatsList.push(chatobj);
        });

        if (chatsList.length > 0) {
          dispatch({ type: GETCHATADMIN, payload: chatsList[0] });
          dispatch({ type: ENDLOADING });
        } else {
          let chatNew = {
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            fechaActualizacion: moment(new Date()).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            idChatAdmin: '',
            idUsuario: idUsuario,
            mensajes: [],
          };
          await app
            .firestore()
            .collection('chatAdmin')
            .add(chatNew)
            .then(async (resp) => {
              let resultado = await app
                .firestore()
                .collection('chatAdmin')
                .doc(resp.id)
                .get();
              let chatInser = {
                ...resultado.data(),
                id: resultado.id,
                idChatAdmin: resultado.id,
              };
              dispatch({ type: GETCHATADMIN, payload: chatInser });
              dispatch({ type: ENDLOADING });
            });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ENDLOADING });
      });
  };

  const handleChangeUsuario = (e) => {
    setFilterByUser(e.target.value);
    buscarUsuario(e.target.value);
  };

  const buscarUsuario = (value) => {
    if (value === '') {
      setListadoFiltrado(listUserChat);
    } else {
      let resulBusqueda = listUserChat.filter((elem) => {
        if (elem.usuario.toLowerCase().includes(value.toLowerCase())) {
          return elem;
        }
      });
      setListadoFiltrado(resulBusqueda);
    }
  };

  const clearInput = () => {
    setListadoFiltrado([]);
    setFilterByUser('');
    setListadoFiltrado(listUserChat);
  };

  //========== Funciones y Metodos Modal Usuarios ==========//
  const getPrestamosByUsers = async (idUsuario) => {
    await app
      .firestore()
      .collection('prestamos')
      .where('idUsuario', '==', idUsuario)
      .get()
      .then((data) => {
        let listPrestamos = [];
        let obj = {};
        data.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          listPrestamos.push(obj);
        });
        setMisPrestamos(listPrestamos);
      });
  };

  const VerDatosUsuarios = () => {
    let results = prestamos.find(
      (item) => item.usuario.idUsuario === chatSeleccionado.idUsuario
    );
    setDetallesUsuario(results.usuario);
    getPrestamosByUsers(chatSeleccionado.idUsuario);
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
  };

  const cancel = () => {
    setShowModal(false);
  };

  //========== Contenido Tabla Modal Mis Prestamos ==========//
  const columns = [
    {
      field: 'montoPrestado',
      headerName: 'Prestado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 115,
    },
    {
      field: 'fechaInicio',
      headerName: 'Fecha/Inicio',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 135,
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'Fecha/Pago',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 130,
    },
    {
      field: 'montoRealRecibido',
      headerName: 'Monto/Pago',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 135,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 110,
    },
    {
      field: 'pagado',
      headerName: 'Pagado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 105,
      valueGetter: (params) => {
        return `${params.row.pagado === true ? 'Si' : 'No'}`;
      },
    },
    {
      field: 'habilitadoNuevoPrestamo',
      headerName: 'Habilitado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 120,
      valueGetter: (params) => {
        return `${params.row.usuario.habilitadoNuevoPrestamo}`;
      },
    },
  ];

  //========== Funciones y Metodos de la Tabla de Mis Prestamos ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();

  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  return (
    <>
      <div className='body-Chat' style={{ height: 'calc(100vh - 130px )' }}>
        <div className='grid grid-cols-12 flex w-full'>
          <div className='col-span-4 box'>
            <div className='col-span-4 bg-theme-2 box'>
              <div className='flex border-b mb-1 justify-center bg-theme-5 box p-2'>
                <button
                  className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
                  onClick={() => toggleTab(1)}
                >
                  Usuarios
                </button>
                <button
                  className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
                  onClick={() => toggleTab(2)}
                >
                  Administrativos
                </button>

                {/* <Icon.Users className="w-4 h-4 mr-5 mt-1 text-theme-3" />
                <h3 className="text-lg font-medium">Usuarios</h3>
                <Icon.List className="w-4 h-4 ml-5 mt-1 text-theme-3" /> */}
              </div>
              <div className='relative text-gray-700 mb-1 p-1 bg-theme-5 box'>
                <div className='searchIcon'>
                  {listadoFiltrado.length === 0 ? (
                    <span>
                      <Icon.XCircle
                        className='flex w-6 h-6 text-center mt-1 ml-1 text-theme-36 absolute'
                        onClick={clearInput}
                      />
                    </span>
                  ) : (
                    <Icon.Search className='flex w-6 h-6 text-center mt-1 ml-1 text-theme-13 absolute' />
                  )}
                  {/* {custom("Usuario no encontrado", "warning")} */}
                </div>
                <input
                  type='text'
                  className='input bg-gray-200 placeholder-theme-13 ml-10 mr-3 pr-24'
                  placeholder='Buscar usuarios ...'
                  value={filterByUser}
                  onChange={handleChangeUsuario}
                />
              </div>
              <div className='content-tabs'>
                <div
                  className={
                    toggleState === 1 ? 'content1  active-content' : 'content1'
                  }
                >
                  <Divider />
                  {listadoFiltrado.map((el) => (
                    <ul key={el.id} className='font-medium p-2'>
                      <li className='flex items-center ml-2'>
                        <div className='flex items-center mr-3 my-1'>
                          <div className='w-8 h-8 sm:w-8 sm:h-8 flex-none image-fit relative'>
                            <img
                              alt='Avatar'
                              className='rounded-full'
                              src={el.urlImagen ? el.urlImagen : Profile13}
                            />
                          </div>
                        </div>
                        <a
                          className='flex items-center mr-3 text-theme-1'
                          href='/'
                          onClick={(e) => selectedChat(e, el)}
                        >
                          <span>{el.usuario}</span>
                        </a>
                      </li>
                      <Divider />
                    </ul>
                  ))}
                </div>
                <div
                  className={
                    toggleState === 2 ? 'content1  active-content' : 'content1'
                  }
                >
                  <Divider />
                  {listadoAdmin.map((el) => (
                    <ul key={el.id} className='font-medium p-2'>
                      <li className='flex items-center ml-2'>
                        <div className='flex items-center mr-3 my-1'>
                          <div className='w-8 h-8 sm:w-8 sm:h-8 flex-none image-fit relative'>
                            <img
                              alt='Avatar'
                              className='rounded-full'
                              src={el.urlImagen ? el.urlImagen : Profile13}
                            />
                          </div>
                        </div>
                        <a
                          className='flex items-center mr-3 text-theme-1'
                          href='/'
                          onClick={(e) => selectedChatAdmin(e, el)}
                        >
                          <span>
                            {`${el.nombres} ${el.apellidos}`}
                            <p className='ml-2 text-gray-500'>{el.rol}</p>
                          </span>
                        </a>
                      </li>
                      <Divider />
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className='col-span-8 box'
            style={{ height: 'calc(100vh - 130px )' }}
          >
            <div
              className={
                toggleState === 1 ? 'content1  active-content' : 'content1'
              }
            >
              {chat.id ? (
                <div>
                  <ChatSuper
                    chat={chat}
                    prestamo={prestamo}
                    gestor={true}
                    rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
                    hfull={true}
                  />
                </div>
              ) : (
                <div>
                  <InicioChat />
                </div>
              )}
            </div>
            <div
              className={
                toggleState === 2 ? 'content1  active-content' : 'content1'
              }
            >
              {chatAdmin.id ? (
                <div>
                  <ChatAdmin
                    chat={chatAdmin}
                    rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
                    hfull={true}
                  />
                </div>
              ) : (
                <div>
                  <InicioChat />
                </div>
              )}
              {chatSeleccionado && (
                <div className='flex justify-start bg-theme-27 box px-3 py-1 my-1'>
                  <Icon.FastForward className='w-4 h-4 mx-3 mt-1 text-theme-3' />
                  <img
                    alt='Avatar'
                    className='rounded-full w-6 h-6 sm:w-6 sm:h-6 flex-none image-fit relative mr-3'
                    src={
                      chatSeleccionado.urlImagen
                        ? chatSeleccionado.urlImagen
                        : Profile13
                    }
                  />
                  <span>{chatSeleccionado.usuario}</span>
                  <Icon.Search className='w-4 h-4 mx-2 mt-1 text-theme-12' />
                  <span>
                    <Link
                      htmlFor=''
                      to=''
                      className='pointer font-medium border border-theme-12 rounded-md px-2 bg-yellow-300'
                      onClick={() => VerDatosUsuarios()}
                    >
                      Ver Historial
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {chatSeleccionado && (
          <div className='flex justify-start bg-theme-27 box px-3 py-1 my-1'>
            <Icon.FastForward className='w-4 h-4 mx-3 mt-1 text-theme-3' />
            <img
              alt='Avatar'
              className='rounded-full w-6 h-6 sm:w-6 sm:h-6 flex-none image-fit relative mr-3'
              src={
                chatSeleccionado.urlImagen
                  ? chatSeleccionado.urlImagen
                  : Profile13
              }
            />
            <span>{chatSeleccionado.usuario}</span>
            {chatSeleccionado.rol === 'Usuario' && (
              <>
                <Icon.Search className='w-4 h-4 mx-2 mt-1 text-theme-12' />
                <span>
                  <Link
                    htmlFor=''
                    to=''
                    className='pointer font-medium border border-theme-12 rounded-md px-2 bg-yellow-300'
                    onClick={() => VerDatosUsuarios()}
                  >
                    Ver Historial
                  </Link>
                </span>
              </>
            )}
          </div>
        )}
      </div>

      {/* Contenido Modal */}
      {showModal && (
        <Modal
          title='Datos Usuario'
          close={close}
          cancel={cancel}
          customBtn={true}
          textcustomBtn='Aceptar'
          save={() => {
            setShowModal(false);
          }}
          size='xl'
        >
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Nombres :
            </label>
            <span>{detallesUsuario.nombres}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Apellidos :
            </label>
            <span>{detallesUsuario.apellidos}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Correo :
            </label>
            <span>{detallesUsuario.email}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Cédula :
            </label>
            <span>{detallesUsuario.numeroCedula}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Pasaporte :
            </label>
            <span>{detallesUsuario.numeroPasaporte}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Fecha de nacimiento :
            </label>
            <span>{detallesUsuario.fechaNacimiemnto}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Sexo :
            </label>
            <span>{detallesUsuario.sexo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              País :
            </label>
            <span>{detallesUsuario.pais}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Localidad :
            </label>
            <span>{detallesUsuario.localidad}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Whatsapp activo :
            </label>
            <span>{detallesUsuario.whatsapp}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Otro número de contacto :
            </label>
            <span>{detallesUsuario.telefonoOpcional}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Usuario telegram :
            </label>
            <span>{detallesUsuario.telegram}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Trabaja :
            </label>
            <span>{detallesUsuario.trabaja}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Area de trabajo :
            </label>
            <span>{detallesUsuario.areaTrabajo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Ingreso mensual :
            </label>
            <span>{detallesUsuario.ingresoMensualUSD}</span>
            <span className='input-group-text ml-1'>(USD)</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Fecha registro :
            </label>
            <span>{detallesUsuario.fechaRegistro}</span>
          </div>
          <div className='col-span-12 box bg-theme-2 p-1'>
            <div className='flex items-center mt-2 mb-1 col-span-12 sm:col-span-4'>
              <h3 className='font-medium m-auto p-1 bg-theme-26 box w-full'>
                Prestamos solicitados :
              </h3>
            </div>
            <div className='col-span-12 overflow-auto lg:overflow-visible'>
              <div
                style={{ height: 260, width: '100%' }}
                className={classes.root}
              >
                <DataGrid
                  rows={misPrestamos}
                  columns={columns}
                  pageSize={3}
                  autoHeight={true}
                  components={{
                    pagination: CustomPagination,
                  }}
                  localeText={{
                    columnMenuLabel: 'Menu',
                    columnMenuShowColumns: 'Show columns',
                    columnMenuFilter: 'Filtrar',
                    columnMenuSortAsc: 'Ordenar Ascendente',
                    columnMenuSortDesc: 'Ordenar Descendente',
                    columnMenuHideColumn: '',
                    columnMenuUnsort: '',
                    footerRowSelected: (count) => count !== 1,
                    //   ? `${count.toLocaleString()} Cambiando Rol`
                    //   : `${count.toLocaleString()} Cambiando Rol`,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SuperChat;
