import React, { useEffect, useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { app } from '../../config/firebaseConfig';
import Ilustracion from '../../statics/images/logo-chamopresta-PNG.png';

export const ResetPassword = () => {
  document.body.classList.add('app');
  document.body.classList.add('login');

  //========== USE-STATES ==========//
  const [formData, setFormadata] = useState({
    password: '',
    repeatPassword: '',
  });
  const [errorForgot, setErrorForgot] = useState({
    color: '',
    message: '',
    view: false,
  });
  const [captchaForgot, setcaptchaForgot] = useState(false);
  const [oobCode, setOobCode] = useState('');

  useEffect(() => {
    setOobCode(getParameterByName('oobCode'));
    // handleResetPassword(app.auth(), getParameterByName('oobCode'))
  }, []);

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setErrorForgot({ color: '', message: '', view: false });
    setFormadata({ ...formData, [name]: value });
  };

  const verifyrecaptcha = (response) => {
    if (response) {
      setcaptchaForgot(true);
    }
  };

  const recaptchaLoaded = () => {
    // console.log('capcha loaded successfull');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaForgot) {
      setErrorForgot({
        color: 'bg-theme-6',
        message: 'Demuestra que eres Humano valida el captcha *',
        view: true,
      });
    } else if (formData.password === '' || formData.repeatPassword === '') {
      setErrorForgot({
        color: 'bg-theme-6',
        message: 'Debe introducir todos los datos',
        view: true,
      });
    } else if (formData.password !== formData.repeatPassword) {
      setErrorForgot({
        color: 'bg-theme-6',
        message: 'Las contraseñas no coinciden',
        view: true,
      });
    } else if (formData.password.length < 6) {
      setErrorForgot({
        color: 'bg-theme-6',
        message: 'La contraseña debe tener al menos 6 caracteres',
        view: true,
      });
    } else {
      handleResetPassword(app.auth(), oobCode);
    }
  };

  function handleResetPassword(auth, actionCode) {
    app
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        var newPassword = formData.password.trim();
        auth
          .confirmPasswordReset(actionCode, newPassword)
          .then((resp) => {
            setFormadata({ password: '', repeatPassword: '' });
            setErrorForgot({
              color: 'bg-theme-9',
              message: 'Contraseña actualizada correctamente',
              view: true,
            });
          })
          .catch((error) => {
            setErrorForgot({
              color: 'bg-theme-6',
              message: 'Error al actualizar la contraseña',
              view: true,
            });
          });
      })
      .catch((error) => {
        setErrorForgot({
          color: 'bg-theme-6',
          message: 'Codigo es invalidao, ó ha caducado',
          view: true,
        });
      });
  }

  return (
    <>
      <div className="container sm:px-10">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="hidden xl:flex flex-col min-h-screen">
            <div className="my-auto">
              <img
                alt="Midone Tailwind HTML Admin Template"
                className="-intro-x w-3\/4 -ml-20"
                src={Ilustracion}
              />
              <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                ¡Préstamos sin garantías!
                <br />
                Fácil y rápida aprobación…
              </div>
              <div className="-intro-x mt-5 text-lg text-white">
                Dirigido a venezolanos en el exterior.
              </div>
            </div>
          </div>
          <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
            <div className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 className="intro-x font-bold text-xl xl:text-xl text-center xl:text-left">
                Cambia tu contraseña
              </h2>
              <form
                action=""
                onSubmit={handleSubmit}
              >
                <div className="intro-x mt-4">
                  <label className="">Nueva contraseña</label>
                  <input
                    type="password"
                    name="password"
                    className="intro-x login__input input rounded-full border border-gray-300 block mt-1"
                    placeholder="Contraseña"
                    value={formData.password}
                    onChange={onChange}
                  />
                </div>
                <div className="intro-x mt-2">
                  <label className="">Repetir contraseña</label>
                  <input
                    type="password"
                    name="repeatPassword"
                    className="intro-x login__input input rounded-full border border-gray-300 block mt-1"
                    placeholder="Repetir contraseña"
                    value={formData.repeatPassword}
                    onChange={onChange}
                  />
                </div>
                {errorForgot.view && (
                  <div className="intro-x mt-2">
                    <div
                      className={[
                        'rounded-md',
                        'px-5',
                        'py-4',
                        'mb-2',
                        'text-white',
                        `${errorForgot.color}`,
                      ].join(' ')}
                    >
                      {errorForgot.message}
                    </div>
                  </div>
                )}
                <div className="intro-x mt-5 xl:mt-5 text-center xl:text-center">
                  <div className="mt-5">
                    <Recaptcha
                      sitekey="6LfdezoaAAAAAMR-vF2KYhgIPNgCeo40ZKTshnIt"
                      render="explicit"
                      hl="es"
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyrecaptcha}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                  <button className="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 mt-5">
                    Aceptar
                  </button>
                  <br />
                </div>
                <div className="intro-x flex text-gray-700 text-xs sm:text-sm mt-5">
                  <div className="flex items-center mr-auto">
                    <a href="/login"> Inicia sesión</a>
                  </div>
                  <div className="flex items-center">
                    <a href="/register"> Registrate</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
