import {
  GET_MYUSER,
  UPDATE_CODE,
  ENDLOADING,
  MODAL_TERMINOCONDICONES,
  MODAL_INFORMACION,
  MODAL_INFORMACIONINICIAL,
  MODAL_POLITICAPRIVACIDAD,
  MODAL_VERIFYCORREO,
  MODAL_REQUISITOS,
} from "../types";

import firebase from "firebase";
import { app } from "../../config/firebaseConfig";

export const updateCode = (email, code) => (dispatch) => {
  firebase
    .firestore()
    .doc(`users/${email}`)
    .update({ code: code })
    .then(() => {
      dispatch({
        type: UPDATE_CODE,
        payload: code,
      });
    });
};

export const setUser = (uid) => (dispatch) => {
  app
    .firestore()
    .collection("usuarios")
    .doc(`${uid}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let obj = doc.data();
        obj.id = doc.id;
        dispatch({
          type: GET_MYUSER,
          payload: obj,
        });
        dispatch({ type: ENDLOADING });
      }
    });
};

export const addLog = (data) => (dispatch) => {
  app.firestore().collection("log").add(data);
};

//========== Modal Terminos y Politicas ==========//
export const modalTerminoCondicion = (value) => (dispatch) => {
  dispatch({ type: MODAL_TERMINOCONDICONES, payload: value });
};

export const modalInformacionServicio = (value) => (dispatch) => {
  dispatch({ type: MODAL_INFORMACION, payload: value });
};

export const modalInformacionInicio = (value) => (dispatch) => {
  dispatch({ type: MODAL_INFORMACIONINICIAL, payload: value });
};

export const modalPolitica = (value) => (dispatch) => {
  dispatch({ type: MODAL_POLITICAPRIVACIDAD, payload: value });
};

export const modalVerify = (value) => (dispatch) => {
  dispatch({ type: MODAL_VERIFYCORREO, payload: value });
};

export const modalRequisito = (value) => (dispatch) => {
  dispatch({ type: MODAL_REQUISITOS, payload: value });
};
