import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { app } from '../../config/firebaseConfig';
import moment from 'moment';
import $ from 'jquery';

import { sendMsg, getChat } from '../../redux/actions/userAction';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Icon from 'react-feather';
import { LOADING, ENDLOADING } from '../../redux/types';
import { custom } from '../../helpers/alertas';
import ReactHtmlParser from 'react-html-parser';

import { getMensajesPredefinidos } from '../../redux/actions/adminAction';
import Profile13 from '../../statics/images/profile-13.jpg';

import 'moment/min/locales';
moment.locale('es');

const Chat = (props) => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  //========== USE-STATE ==========//
  const [msg, setMgs] = useState('');
  const [fileName, setFileName] = useState('');
  const [fbStoreRef, setFbStoreRef] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const mensajespredefinidos = useSelector(
    (state) => state.admin.mensajespredefinidos
  );

  useEffect(() => {
    dispatch(getMensajesPredefinidos());
  }, []);

  const mensajePosition = (rol) => {
    if (myUser.rol === 'Usuario') {
      if (rol === 'Sistema') {
        return 'float-left';
      } else {
        return 'float-right ';
      }
    } else {
      if (rol === 'Sistema') {
        return 'float-right ';
      } else {
        return 'float-left';
      }
    }
  };

  const mensajeColor = (rol) => {
    if (rol === 'Sistema') {
      return 'bg-theme-32 text-white rounded-l-md';
    } else {
      return 'bg-gray-200 text-gray-700 rounded-r-md';
    }
  };

  const dateColor = (rol) => {
    if (rol === 'Sistema') {
      return 'text-theme-25';
    } else {
      return 'text-gray-600';
    }
  };

  const handleClickDropDown = (target) => {
    let dropdown = $(target).closest('.dropdown');
    console.log('dropdown', dropdown);
    if (
      !$(dropdown).length ||
      ($(target).closest('.dropdown-toggle').length &&
        $(dropdown).find('.dropdown-box').first().hasClass('show'))
    ) {
      $('.dropdown-box').removeClass('show');
    } else {
      $('.dropdown-box').removeClass('show');
      $(dropdown).find('.dropdown-box').first().addClass('show');
    }
  };

  const send = () => {
    if (msg !== '') {
      let mensajesArray = [];
      if (props.chat) {
        //si existe el chat
        mensajesArray = [...props.chat.mensajes];
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          rol: props.rol,
          texto: msg,
        });
        dispatch(sendMsg(props.chat, mensajesArray));
        setTimeout(() => {
          setMgs('');
        }, 100);
      } else {
        //si no existe lo crea
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          rol: props.rol,
          texto: msg,
        });
        let chatNew = {
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idChat: '',
          idPrestamo: props.prestamo.id,
          mensajes: mensajesArray,
        };
        app
          .firestore()
          .collection('chat')
          .add(chatNew)
          .then((resp) => {
            setMgs('');
            dispatch(getChat(props.prestamo.id));
          });
      }
    }
  };

  const sendAdjuntado = (textMessage) => {
    if (textMessage !== '') {
      let mensajesArray = [];
      if (props.chat) {
        //si existe el chat
        mensajesArray = [...props.chat.mensajes];
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          rol: props.rol,
          texto: textMessage,
        });
        dispatch(sendMsg(props.chat, mensajesArray));
        setTimeout(() => {
          setMgs('');
        }, 100);
      } else {
        //si no existe lo crea
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          rol: props.rol,
          texto: textMessage,
        });
        let chatNew = {
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idChat: '',
          idPrestamo: props.prestamo.id,
          mensajes: mensajesArray,
        };
        app
          .firestore()
          .collection('chat')
          .add(chatNew)
          .then((resp) => {
            setMgs('');
            dispatch(getChat(props.prestamo.id));
          });
      }
    }
  };

  const selecMsj = (msj) => {
    let el = document.getElementById('icon-mensaje');
    setMgs(msj.textoMensaje);
    handleClickDropDown(el);
  };

  const sortByDate = (a, b) => {
    var dateA = new Date(a.fecha).getTime();
    var dateB = new Date(b.fecha).getTime();
    return dateA > dateB ? 1 : +1;
  };

  const isImage = (texto) => {
    const idxDot = texto.lastIndexOf('.') + 1;
    const extFile = texto.substr(idxDot, texto.length).toLowerCase();
    if (
      extFile.includes('jpg') ||
      extFile.includes('jpeg') ||
      extFile.includes('png') ||
      extFile.includes('gif') ||
      extFile.includes('svg') ||
      extFile.includes('bmp') ||
      extFile.includes('tiff') ||
      texto.includes('?alt=media')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const openExplorer = (e) => {
    const letra = fileRef.current.click();
    console.log(letra);
  };

  const closeExplorer = (e) => {
    setIsUpload(false);
    setSelectedImage();
    setFileName('');
  };

  const loadFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedImage(file);
      setIsUpload(true);
      setFileName(file.name);
    }
  };

  const sendVisualizer = (e) => {
    upload(selectedImage);
  };

  const upload = (file) => {
    dispatch({ type: LOADING });
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;

    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app
        .storage()
        .ref(`chat/prestamo/${props.prestamo.id}/${imageFileName}`);
      setFbStoreRef(storageRef);
      const task = storageRef.put(file);

      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
          dispatch({ type: ENDLOADING });
        },
        () => {
          storageRef
            .getDownloadURL()
            .then((response) => {
              sendAdjuntado(response);
              setIsUpload(false);
              setFileName('');
              setFbStoreRef(null);
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log('Error al subir el archivo');
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  let mensajes = [];
  if (props.chat && props.chat.mensajes) {
    mensajes = props.chat.mensajes.sort(sortByDate);
  }

  return (
    <div className="intro-y chat grid grid-cols-12 gap-5 w-full h-full">
      <div className="intro-y col-span-12 lg:col-span-12 xxl:col-span-12 relative">
        {isUpload && (
          <div
            className="visualizer intro-y box bg-gray-200"
            style={
              props.hfull ? { height: 'calc(100vh - 130px )' } : { height: 470 }
            }
          >
            <div className="visualizer-header">
              <Icon.X
                className="w-6 h-6 mr-4 pointer absolute right-0"
                onClick={closeExplorer}
              />
              {fileName}
            </div>
            <div className="visualizer-body ">
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="avatar-preview"
              />
            </div>
            <div className="visualizer-footer">
              <button
                className="w-14 h-14 block bg-theme-1 text-white rounded-full flex-none flex items-center justify-center"
                onClick={sendVisualizer}
              >
                <Icon.Send className="w-6 h-6" />
              </button>
            </div>
          </div>
        )}
        <div
          className="chat__box box"
          style={
            props.hfull ? { height: 'calc(100vh - 130px )' } : { height: 470 }
          }
        >
          <div className="h-full flex flex-col">
            {/* Header*/}
            <div className="flex flex-col sm:flex-row border-b border-gray-200 px-5 py-4">
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-10 sm:h-10 flex-none image-fit relative">
                  <img
                    alt="*Imagen Usuario"
                    className="rounded-full"
                    src={myUser.urlImagen ? myUser.urlImagen : Profile13}
                  />
                </div>
                <div className="ml-3 mr-auto">
                  <div className="font-medium text-base">
                    {myUser.nombres} {myUser.apellidos}
                  </div>
                  <div className="text-gray-600 text-xs sm:text-sm font-medium">
                    <span className="mx-1">•</span> Online - , estas usando el
                    chat
                  </div>
                </div>
              </div>
              <div className="flex items-center sm:ml-auto mt-5 sm:mt-0 border-t sm:border-0 border-gray-200 pt-3 sm:pt-0 -mx-5 sm:mx-0 px-5 sm:px-0">
                {/*<a href="javascript:;" className="w-5 h-5 text-gray-500">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-search w-5 h-5"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>{" "}
                </a> */}
                {/*<a href="javascript:;" className="w-5 h-5 text-gray-500 ml-5">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user-plus w-5 h-5"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <line x1="20" y1="8" x2="20" y2="14"></line>
                    <line x1="23" y1="11" x2="17" y2="11"></line>
                  </svg>{" "}
                </a> */}
                {/* <div className="dropdown relative ml-auto sm:ml-3">
                  <a
                    href="javascript:;"
                    className="dropdown-toggle w-5 h-5 text-gray-500"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-more-vertical w-5 h-5"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="12" cy="5" r="1"></circle>
                      <circle cx="12" cy="19" r="1"></circle>
                    </svg>{" "}
                  </a>
                  <div className="dropdown-box mt-6 absolute w-40 top-0 right-0 z-20">
                    <div className="dropdown-box__content box p-2">
                      <a
                        href=""
                        className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-share-2 w-4 h-4 mr-2"
                        >
                          <circle cx="18" cy="5" r="3"></circle>
                          <circle cx="6" cy="12" r="3"></circle>
                          <circle cx="18" cy="19" r="3"></circle>
                          <line
                            x1="8.59"
                            y1="13.51"
                            x2="15.42"
                            y2="17.49"
                          ></line>
                          <line
                            x1="15.41"
                            y1="6.51"
                            x2="8.59"
                            y2="10.49"
                          ></line>
                        </svg>
                        Share Contact
                      </a>
                      <a
                        href=""
                        className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-settings w-4 h-4 mr-2"
                        >
                          <circle cx="12" cy="12" r="3"></circle>
                          <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                        Settings
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* Fin del header*/}
            {/* Content*/}
            {/*<div className="overflow-y-auto px-5 pt-5 flex-1"> */}
            <PerfectScrollbar className="overflow-y-auto px-5 pt-5 flex-1">
              {props.chat &&
                props.chat.mensajes &&
                props.chat.mensajes.reverse().map((item, i) => (
                  <div key={i}>
                    <div
                      className={[
                        'chat__box__text-box',
                        'flex items-end',
                        'mb-4',
                        mensajePosition(item.rol),
                      ].join(' ')}
                    >
                      {item.idUsuario !== app.auth().currentUser.uid && (
                        <div className="sm:block dropdown relative mr-3 my-auto">
                          <a
                            href="javascript:;"
                            className="dropdown-toggle w-4 h-4 text-gray-500"
                            onClick={handleClickDropDown}
                          >
                            <i
                              data-feather="more-vertical"
                              className="w-4 h-4"
                            ></i>
                            <i
                              data-feather="bar-chart-2"
                              className="w-8 h-8 text-white transform -rotate-90"
                            ></i>
                          </a>
                          <div className="dropdown-box mt-6 absolute w-40 top-0 right-0 z-20">
                            <div className="dropdown-box__content box p-2">
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="corner-up-left"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Reply
                              </a>
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="trash"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={[
                          'px-4',
                          'py-3',
                          'rounded-t-md',
                          mensajeColor(item.rol),
                        ].join(' ')}
                      >
                        {isImage(item.texto) ? (
                          <a
                            href={item.texto}
                            target="_blank"
                          >
                            <img
                              src={item.texto}
                              alt="imagen"
                              style={{ width: 150, height: 'auto' }}
                            />
                          </a>
                        ) : (
                          <div>{ReactHtmlParser(item.texto)}</div>
                        )}
                        <div
                          className={[
                            'mt-1',
                            'text-xs',
                            dateColor(item.rol),
                          ].join(' ')}
                        >
                          {moment(item.fecha).fromNow()}
                        </div>
                      </div>
                      {item.idUsuario === app.auth().currentUser.uid && (
                        <div className="sm:block dropdown relative ml-3 my-auto">
                          <a
                            href="javascript:;"
                            className="dropdown-toggle w-4 h-4 text-gray-500"
                          >
                            <i
                              data-feather="more-vertical"
                              className="w-4 h-4"
                            ></i>
                          </a>
                          <div className="dropdown-box mt-6 absolute w-40 top-0 right-0 z-20">
                            <div className="dropdown-box__content box p-2">
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="corner-up-left"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Reply
                              </a>
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="trash"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="clear-both"></div>
                  </div>
                ))}
              {/*</div>*/}
            </PerfectScrollbar>
            {/*Fin del Content*/}
            {/* Footer seccion para escribir mensaje */}
            <div className="pt-4 pb-10 sm:py-4 flex items-center border border-gray-400 box mt-3">
              <textarea
                className="chat__box__input input w-full h-16 resize-none border-transparent px-5 py-3 focus:shadow-none bg-theme-2 ml-2"
                rows="1"
                placeholder=" Escribe tu mensaje..."
                value={msg}
                onChange={(e) => setMgs(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    send();
                  }
                }}
              ></textarea>
              <div className="flex absolute sm:static left-0 bottom-0 ml-5 sm:ml-0 mb-5 sm:mb-0">
                <input
                  id="fileUpload"
                  type="file"
                  className="mt-20 left-0 absolute opacity-0"
                  ref={fileRef}
                  onChange={loadFile}
                />
                <div className="w-4 h-4 sm:w-5 sm:h-5 relative text-gray-500 mr-3 sm:mr-5 pointer">
                  <Icon.Paperclip
                    className="w-full h-full ml-2 pointer"
                    onClick={openExplorer}
                  />
                </div>
                {props.gestor && (
                  <div className="dropdown relative mr-3 sm:mr-5">
                    <a
                      href="javascript:;"
                      className="dropdown-toggle w-4 h-4 sm:w-5 sm:h-5 block text-gray-500"
                      onClick={(e) => handleClickDropDown(e.target)}
                    >
                      <Icon.MessageSquare
                        className="w-full h-full"
                        id="icon-mensaje"
                      />
                    </a>
                    <div className="chat-dropdown dropdown-box absolute w-40 top-0 left-0 sm:left-auto sm:right-0 z-20">
                      <div className="dropdown-box__content">
                        <div className="chat-dropdown__box box flex flex-col pb-3 -mt-2">
                          {/*<div className="px-3 pt-3">
                            <div className="relative text-gray-700">
                              <input type="text" className="input w-full bg-gray-200 pr-10 placeholder-theme-13" placeholder="Buscar Mensaje..." />
                              <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i> 
                            </div>
                          </div>*/}
                          <div className="inbox box mt-5">
                            <div className="p-5 flex flex-col-reverse sm:flex-row text-gray-600 border-b border-gray-200">
                              <div className="flex items-center justify-center w-full">
                                <label>Seleccione un mensaje</label>
                              </div>
                            </div>
                            <div className="overflow-y-scroll sm:overflow-y-scroll h-64">
                              {mensajespredefinidos.map((msj, index) => (
                                <div
                                  className="intro-y"
                                  key={index}
                                  onClick={() => selecMsj(msj)}
                                >
                                  <div className="inbox__item inbox__item--active inline-block sm:block text-gray-700 bg-gray-100 border-b border-gray-200">
                                    <div className="flex px-5 py-3">
                                      <div className="w-56 flex-none flex items-center mr-2">
                                        <div className="inbox__item--sender truncate ml-3">
                                          {msj.nombreMensaje}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button
                className="w-8 h-8 sm:w-10 sm:h-10 block bg-theme-1 text-white rounded-full flex-none flex items-center justify-center mr-5"
                onClick={send}
              >
                <Icon.Send className="w-4 h-4" />
              </button>
            </div>
            {/* Fin del Footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
