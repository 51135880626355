import React, { useState, useEffect } from 'react';

import Modal from '../../../components/Modal';
import * as Icon from 'react-feather';
import { custom } from '../../../helpers/alertas';
import { app } from '../../../config/firebaseConfig';

const AgregarPropiedades = () => {
  //========== USE-STATES ==========//
  const [modalCuenta, setModalCuenta] = useState(false);
  const [modelo, setModelo] = useState({
    campo: '',
    valor: '',
  });
  const [user, setUser] = useState([]);
  const [userPrestamos, setUserPrestamos] = useState([]);
  const [cuentasBancarias, setCuentasBancarias] = useState([]);

  useEffect(() => {
    const getUser = () => {
      app.firestore().collection('usuarios').onSnapshot(manejarSnapshot);
    };
    const getPrestamo = () => {
      app
        .firestore()
        .collection('prestamos')
        .onSnapshot(manejarSnapshotPrestamos);
    };
    const obtenerCuentasBancarias = () => {
      app
        .firestore()
        .collection('cuentasBancarias')
        .onSnapshot(manejarSnapshotCuentas);
    };
    getUser();
    getPrestamo();
    obtenerCuentasBancarias();
  }, []);

  function manejarSnapshot(snapshot) {
    const usuarios = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      return obj;
    });
    setUser(usuarios);
  }
  function manejarSnapshotPrestamos(snapshot) {
    const prestamos = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      return obj;
    });
    setUserPrestamos(prestamos);
  }
  function manejarSnapshotCuentas(snapshot) {
    const cuentasBancarias = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      return obj;
    });
    setCuentasBancarias(cuentasBancarias);
  }

  //========== Modal Agregar Propiedades ==========//
  const añadirPropiedad = () => {
    setModalCuenta(true);
  };
  const guardarPropiedad = async () => {
    if (
      modelo.campo === ''
      // || modelo.valor === ''
    ) {
      custom('Debe llenar todos los campos obligatorios *', 'error');
    } else {
      // cuentasBancarias.forEach((doc) => {
      //   try {
      // console.log('id', doc.id);
      // console.log('idUsuario', doc.idUsuario);
      // if (doc.id === doc.idUsuario) {
      // app
      //   .firestore()
      //   .collection('cuentasBancarias')
      //   .doc(doc.id)
      //   .update({ [modelo.campo]: modelo.valor });
      // }
      //     custom('Datos actualizados correctamente', 'success');
      //     setModalCuenta(false);
      //   } catch (error) {
      //     custom('Ocurrio un error intente nuevamente', 'error');
      //   }
      // });

      userPrestamos.forEach((doc) => {
        try {
          if (doc.idUsuario === doc.usuario.idUsuario) {
            let dataUpdate = Object.assign({
              ...doc,
              [modelo.campo]: doc.fechaInicio,
            });
            app
              .firestore()
              .collection('prestamos')
              .doc(doc.id)
              .update(dataUpdate);
          }
          custom('Datos actualizados correctamente', 'success');
          setModalCuenta(false);
        } catch (error) {
          custom('Ocurrio un error intente nuevamente', 'error');
        }
      });
    }
  };
  const changeCollection = (e) => {
    const { name, value } = e.target;
    setModelo({ ...modelo, [name]: value });
  };

  return (
    <>
      <div className='grid grid-cols-12 gap-6 mt-5'>
        <div className='col-span-12 lg:col-span-12'>
          <div className='box overflow-auto lg:overflow-visible'>
            <div className='flex flex-col sm:flex-row items-center p-5 border-b border-gray-200'>
              <h2 className='font-medium text-base mr-auto'>
                Para Agregar Nuevas Propiedades
              </h2>
              <div className='w-full sm:w-auto flex mt-4 sm:mt-0'>
                <button
                  className='flex button text-white bg-theme-1 shadow-md mr-2'
                  onClick={añadirPropiedad}
                >
                  <Icon.Plus className='w-4 h-4 mr-2' />
                  Registrar Nueva Propiedad
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalCuenta && (
        <Modal
          title='Agregar Nuevas Propiedades'
          close={(e) => setModalCuenta(false)}
          save={guardarPropiedad}
          cancel={(e) => setModalCuenta(false)}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Campo : *</label>
            <input
              type='text'
              name='campo'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='indique el campo'
              onChange={changeCollection}
            />
          </div>

          {/* <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Valor : *</label>
            <input
              type='text'
              // type='number'
              // min='0'
              // step='.01'
              name='valor'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='indique valor del campo'
              onChange={changeCollection}
            />
          </div> */}
        </Modal>
      )}
    </>
  );
};

export default AgregarPropiedades;
