import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { app } from '../../../config/firebaseConfig';
import moment from 'moment';

import { LISTADOACTIVO } from '../../../redux/types';
import Modal from '../../../components/Modal';
import Sinfondo from '../../../statics/images/sin-fondo.png';

const SinFondos = () => {
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);

  //========== USE-STATES ==========//
  const [prestamos, setPrestamos] = useState([]);
  const [listado, setListado] = useState(null);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    getLog();
    // getPrestamos(user.uid);
  }, []);

  const getPrestamos = (uid) => {
    app
      .firestore()
      .collection('prestamos')
      .where('idUsuario', '==', `${uid}`)
      .orderBy('fechaInicio', 'desc')
      .get()
      .then((data) => {
        let result = [];
        data.forEach((doc) => {
          result.push(doc.data());
        });
        setPrestamos(result);
      });
  };

  const addListado = async () => {
    const dataAdd = {
      fechaInicio: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      fechaFin: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      usuarios: [myUser.email],
      activo: true,
    };
    await app.firestore().collection('logsinfondos').add(dataAdd);
  };

  const editListado = async (list) => {
    const userEmail = app.auth().currentUser.email;
    const filtrado = list.usuarios.filter((row) => row === userEmail);
    if (filtrado.length === 0) {
      let array = list.usuarios;
      array.push(userEmail);
      let ahora = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      const datUpdate = { usuarios: array, fechaFin: ahora };
      await app
        .firestore()
        .collection('logsinfondos')
        .doc(`${list.id}`)
        .update(datUpdate);
    }
  };

  const getLog = () => {
    app
      .firestore()
      .collection('logsinfondos')
      .where('activo', '==', true)
      .get()
      .then((data) => {
        let result = [];
        data.forEach((doc) => {
          let obj = { id: doc.id, ...doc.data() };
          result.push(obj);
        });
        if (result.length > 0) {
          setListado(result[0]);
          dispatch({ type: LISTADOACTIVO, payload: result[0] });
          editListado(result[0]);
        } else {
          addListado();
          setListado(null);
          dispatch({ type: LISTADOACTIVO, payload: null });
        }
      });
  };

  return (
    <>
      {showModal && (
        <Modal size="xl">
          <>
            {/* <div className=""> */}
            <div className="grid grid-cols-12 gap-4 row-gap-5 rounded-xl">
              <div className="col-span-12 sm:col-span-7 flex-col">
                <div className="pl-16">
                  <h1 className="text-gray-750 text-6xl font-extrabold leading-11 tracking-tighter mt-20">
                    ¡OH NO!
                  </h1>
                  <p className="font-montserrat text-gray-650 font-medium text-lg mt-3">
                    Debido a la gran demanda por nuestro servicio nos hemos
                    quedado sin fondos disponibles para hacer mas préstamos. ¡Te
                    enviaremos un correo electrónico apenas contemos con nuevos
                    fondos disponibles!
                  </p>
                  <div className="py-10 mb-8">
                    <a
                      href="/login"
                      className="text-white font-montserrat font-bold text-lg px-8 bg-boton-volver h-60 rounded-t-md rounded-b-md py-4"
                    >
                      Cerrar sesión
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-span-12 sm:col-span-5 flex items-center justify-center mt-32">
                <img
                  className=""
                  src="/Ilustracion_NoFondos.svg"
                  alt="imagen"
                />
              </div>
            </div>
            {/* </div> */}
          </>
        </Modal>
      )}
    </>
  );
};

export default SinFondos;
