import React, { useState } from "react";

import * as Icon from "react-feather";
import ModalExit from "../../ModalExit";

const Bloqueado = () => {
  const [modalExit, setModalexit] = useState(false);

  const onClose = () => {
    setModalexit(false);
  };

  const handleLogout = () => {
    setModalexit(true);
  };

  return (
    <>
      <div className="bg-theme-23 box text-center p-32">
        <Icon.XOctagon className="w-20 h-20 text-theme-6 m-auto" />
        <h1 className="text-5xl text-theme-6 font-extrabold my-5">
          Usuario Bloqueado
        </h1>
        <p className="font-bold text-lg my-5">
          Usted ha sido bloqueado por intento de estafa, desde este momento
          tendrá prohibido el ingreso al sistema, su IP y su Dirección serán
          reportada. estaremos analizando su caso para establecer una serie de
          medidas a tomar en su contra.
        </p>

        <button
          className="text-2xl text-theme-36 font-extrabold my-5 border-red-500"
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
        >
          <Icon.LogOut className="w-12 h-12 m-auto" />
          Salir
        </button>
        <ModalExit open={modalExit} onClose={onClose} />
      </div>
    </>
  );
};

export default Bloqueado;
