import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { app } from '../../../config/firebaseConfig';
import feather from 'feather-icons';
import * as Icon from 'react-feather';
import moment from 'moment';
import Modal from '../../Modal';
import { confirm, success, custom } from '../../../helpers/alertas';
//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import {
  getIngresos,
  getEgresos,
  modalAgregarIngresosContable,
  modalAgregarEgresosContable,
  modalEditarMovimientoContable,
  updateEditarMovimientoContable,
  saveAgregarIngresosContable,
  saveAgregarEgresosContable,
  deleteMovimientoContable,
} from '../../../redux/actions/superAdminActions';
import { addLog } from '../../../redux/actions/sesionAction';
import { ExportToCsv } from 'export-to-csv';
const hoy = moment(new Date()).format('DD/MM/YYYY');
const optionsExportData = {
  filename: `Datos_Prestamos_${hoy}`,
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: `Datos_Prestamos_${hoy}`,
  useTextFile: false,
  useBom: true,
  // useKeysAsHeaders: true,
  headers: [
    'Usuario',
    'Inicial',
    'Devuelto',
    'Ganancia',
    'Porcentaje',
    'Fecha Inicio',
    'Fecha Final',
    'Email',
    'Pais',
    'Telefono',
  ],
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const Contabilidad = () => {
  const dispatch = useDispatch();

  //========== USE-STATES ==========//
  const [movimientoContable, setMovimientoContable] = useState({
    categoria: '',
    concepto: '',
    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    monto: '',
    tipo: '',
  });
  const [editarMovimientosContables, setEditarMovimientosContables] = useState({
    categoria: '',
    concepto: '',
    fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    monto: '',
    tipo: '',
  });
  const [registroSeleccionado, setRegistroSeleccionado] = useState('');
  const [dataPrestamo, setDataPrestamo] = useState([]);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const ingresos = useSelector((state) => state.superadmin.ingresosContables);
  const egresos = useSelector((state) => state.superadmin.egresosContables);

  let mvimientos = ingresos.concat(egresos);
  let movimientosContables = mvimientos.sort((a, b) => {
    let dateA = new Date(a.fecha).getTime();
    let dateB = new Date(b.fecha).getTime();
    return dateA < dateB ? 1 : -1;
  });

  const modalIngresosContables = useSelector(
    (state) => state.superadmin.modalIngresosContables
  );
  const modalEgresosContables = useSelector(
    (state) => state.superadmin.modalEgresosContables
  );
  const modalEditarMovimientosContables = useSelector(
    (state) => state.superadmin.modalEditarMovimientosContables
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getIngresos());
    dispatch(getEgresos());
    listDataPrestamos();
  }, []);

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'fecha',
      headerName: 'FECHA',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 180,
    },
    {
      field: 'categoria',
      headerName: 'CATEGORIA',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 185,
    },
    {
      field: 'concepto',
      headerName: 'CONCEPTO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 260,
    },
    {
      field: 'monto',
      headerName: 'DEBE',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell-debe',
      headerAlign: 'center',
      type: 'number',
      width: 100,
      valueGetter: (params) => {
        return params.row.tipo === 'egreso' ? 0 : params.row.monto;
      },
    },
    {
      field: 'haber',
      headerName: 'HABER',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell-haber',
      headerAlign: 'center',
      type: 'number',
      width: 100,
      valueGetter: (params) => {
        return params.row.tipo === 'ingreso' ? 0 : params.row.monto;
      },
    },
    {
      field: 'saldo',
      headerName: 'SALDO',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell-saldo',
      headerAlign: 'center',
      type: 'number',
      width: 110,
      valueGetter: (params) => {
        return acumulate(params.rowIndex);
      },
    },
    {
      field: 'admin',
      headerName: 'ACCION',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 138,
      renderCell: (params) => (
        <div className='flex col-span-12 sm:col-span-6 text-theme-1 font-medium items-center ml-5'>
          <a
            className='flex items-center text-theme-1'
            href='javascript:;'
            onClick={(e) => editarMovimientoContable(params.row)}
          >
            <Icon.Edit className='w-5 h-5 mr-1' />
          </a>

          <a
            className='flex items-center ml-3 text-theme-6'
            href='javascript:;'
            onClick={(e) => elimiarMovimientoContable(params.row)}
          >
            <Icon.Trash2 className='w-5 h-5 mr-2' />
          </a>
        </div>
      ),
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 1)',
      },
      '& .super-app-theme--cell-debe': {
        backgroundColor: 'rgba(198, 212, 253, .4)',
        color: '#1a3e72',
        // fontWeight: "600",
      },
      '& .super-app-theme--cell-haber': {
        backgroundColor: 'rgba(209, 141, 150, 0.4)',
        color: '#1a3e72',
        // fontWeight: "600",
      },
      '& .super-app-theme--cell-saldo': {
        backgroundColor: 'rgba(81, 127, 164, 0.4)',
        color: '#1a3e72',
        fontWeight: '600',
      },
    },
  }));
  const classes = useStyles();

  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }
  const acumulate = (indice) => {
    let result = 0;
    let final = movimientosContables.length - 1;
    for (let i = final; i >= indice; i--) {
      if (movimientosContables[i].tipo === 'ingreso') {
        result = result + movimientosContables[i].monto;
      } else {
        result = result - movimientosContables[i].monto;
      }
    }
    return result;
  };
  const totalDebe = () => {
    let debe = 0;
    ingresos.forEach((element) => {
      debe = debe + element.monto;
    });
    return debe;
  };
  const totalHaber = () => {
    let haber = 0;
    egresos.forEach((element) => {
      haber = haber + element.monto;
    });
    return haber;
  };
  const totalSaldo = () => {
    let saldo = totalDebe() - totalHaber();
    return saldo;
  };

  //========== Funciones Modal Ingresos Contables ==========//
  const agregarIngresosContable = () => {
    setMovimientoContable({
      categoria: '',
      concepto: '',
      fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      monto: '',
      tipo: 'ingreso',
    });
    dispatch(modalAgregarIngresosContable(true));
  };
  const changeAgregarMovimientoContable = (e) => {
    const { name, value } = e.target;
    setMovimientoContable({ ...movimientoContable, [name]: value });
  };
  const closeAgregarIngresoContable = () => {
    dispatch(modalAgregarIngresosContable(false));
  };
  const cancelAgregarIngresoContable = () => {
    dispatch(modalAgregarIngresosContable(false));
  };
  const guardarAgregarIngresoContable = () => {
    if (
      movimientoContable.categoria === '' ||
      movimientoContable.concepto === '' ||
      movimientoContable.fecha === '' ||
      movimientoContable.monto === '' ||
      movimientoContable.tipo === ''
    ) {
      custom('Debe llenar todos los datos del Ingreso', 'warning');
    } else {
      dispatch(
        saveAgregarIngresosContable({
          categoria: movimientoContable.categoria,
          concepto: movimientoContable.concepto,
          fecha: movimientoContable.fecha,
          monto: parseFloat(movimientoContable.monto),
          tipo: movimientoContable.tipo,
        })
      );
      dispatch(
        addLog({
          accion: `${'Ha realizado el Movimiento: De tipo, '} ${
            movimientoContable.tipo
          } ${'En la Categoria, '} ${movimientoContable.categoria}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      dispatch(modalAgregarIngresosContable(false));
    }
  };

  //========== Funciones Modal Egresos Contables ==========//
  const agregarEgresosContable = () => {
    setMovimientoContable({
      categoria: '',
      concepto: '',
      fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      monto: '',
      tipo: 'egreso',
    });
    dispatch(modalAgregarEgresosContable(true));
  };
  const closeAgregarEgresoContable = () => {
    dispatch(modalAgregarEgresosContable(false));
  };
  const cancelAgregarEgresoContable = () => {
    dispatch(modalAgregarEgresosContable(false));
  };
  const guardarAgregarEgresoContable = () => {
    if (
      movimientoContable.categoria === '' ||
      movimientoContable.concepto === '' ||
      movimientoContable.fecha === '' ||
      movimientoContable.monto === '' ||
      movimientoContable.tipo === ''
    ) {
      custom('Debe llenar todos los datos del Egreso', 'warning');
    } else {
      dispatch(
        saveAgregarEgresosContable({
          categoria: movimientoContable.categoria,
          concepto: movimientoContable.concepto,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          monto: parseFloat(movimientoContable.monto),
          tipo: movimientoContable.tipo,
        })
      );
      dispatch(
        addLog({
          accion: `${'Ha realizado el Movimiento: De tipo, '}  ${
            movimientoContable.tipo
          } ${'En la Categoria, '} ${movimientoContable.categoria}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      dispatch(modalAgregarEgresosContable(false));
    }
  };

  //========== Funciones Modal Editar Movimientos Contables ==========//
  const closeEditarMovimientoContable = () => {
    dispatch(modalEditarMovimientoContable(false));
  };
  const cancelEditarMovimientoContable = () => {
    dispatch(modalEditarMovimientoContable(false));
  };
  const guardarEditarMovimientoContable = () => {
    if (
      movimientoContable.categoria === '' ||
      movimientoContable.concepto === '' ||
      movimientoContable.fecha === '' ||
      movimientoContable.monto === '' ||
      movimientoContable.tipo === ''
    ) {
      custom('Debe llenar todos los datos', 'warning');
    } else {
      dispatch(
        updateEditarMovimientoContable(
          {
            categoria: movimientoContable.categoria,
            concepto: movimientoContable.concepto,
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            monto: parseFloat(movimientoContable.monto),
            tipo: movimientoContable.tipo,
          },
          registroSeleccionado
        )
      );
      dispatch(
        addLog({
          accion: `${'Se Ha Editado Un Registro:  De tipo, '} ${
            movimientoContable.tipo
          } ${'En la Categoria, '} ${movimientoContable.categoria}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      dispatch(modalEditarMovimientoContable(false));
    }
  };
  const editarMovimientoContable = (params) => {
    setRegistroSeleccionado(params.id);
    setEditarMovimientosContables(true);
    setMovimientoContable(params);
    dispatch(modalEditarMovimientoContable(true));
  };
  const elimiarMovimientoContable = async (params) => {
    const resp = await confirm('¿Desea Eliminar el Movimiento?', 'question');
    if (resp) {
      dispatch(deleteMovimientoContable(params, movimientoContable.idRegistro));
      dispatch(modalEditarMovimientoContable(false));
      dispatch(
        addLog({
          accion: `${'Se Ha Eliminado Un Registro: De tipo'} ${
            params.tipo
          } ${'En la Categoria, '} ${params.categoria}`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      success('Registro Eliminado Exitosamente.', 'success');
    }
  };

  //========== Funciones Exportar Data Prestamo ==========//
  // const colorBadge = (estado) => {
  //   switch (estado) {
  //     case 'iniciado':
  //       return 'badge bg-theme-36';
  //     case 'rechazado':
  //       return 'badge bg-theme-6';
  //     case 'pendiente':
  //       return 'badge  bg-theme-12';
  //     case 'preAprobado':
  //       return 'badge  bg-orange-500';
  //     case 'aprobado':
  //       return 'badge  bg-theme-9';
  //     default:
  //       return 'badge bg-theme-1';
  //   }
  // };
  // console.log('Mes', dataPrestamo.fechaInicio);
  // const mesPrestamo = (fecha) => {
  //   switch (fecha) {
  //     case `${dataPrestamo.includes('-01-')}`:
  //       return 'Enero';
  //     case '-02-':
  //       return 'Febrero';
  //     case '-03-':
  //       return 'Marzo';
  //     case '-04-':
  //       return 'Abril';
  //     case '-05-':
  //       return 'Mayo';
  //     case '-06-':
  //       return 'Junio';
  //     case '-07-':
  //       return 'Julio';
  //     case '-08-':
  //       return 'Agosto';
  //     case '-09-':
  //       return 'Septiembre';
  //     case '-10-':
  //       return 'Octubre';
  //     case '-11-':
  //       return 'Noviembre';
  //     case '-12-':
  //       return 'Diciembre';
  //     default:
  //       return 'Dic';
  //   }
  // };
  // console.log('Mes', mesPrestamo());
  const listDataPrestamos = () => {
    app
      .firestore()
      .collection('prestamos')
      .where('estado', '==', 'finalizado')
      .where('pagado', '==', true)
      .orderBy('fechaInicio', 'asc')
      .get()
      .then(async (data) => {
        let array = [];
        let ptamo = {};
        data.forEach((doc) => {
          ptamo = doc.data();
          ptamo.id = doc.id;
          array.push(ptamo);
        }); //hasta aqui obtengo los prestamos
        setDataPrestamo(array);
      });
  };
  const exportarDataPrestamos = () => {
    const csvExporter = new ExportToCsv(optionsExportData);
    const dataExportar = dataPrestamo.map((ptmo) => {
      const porcentaje =
        (ptmo.montoRealRecibido / ptmo.montoPrestado).toFixed(2) * 100 - 100;
      const capitalizarPrimeraLetraa = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };
      return {
        usuario:
          capitalizarPrimeraLetraa(ptmo.usuario.nombres) +
          ' ' +
          capitalizarPrimeraLetraa(ptmo.usuario.apellidos),
        inicial: ptmo.montoPrestado,
        devuelto: ptmo.montoRealRecibido,
        ganancia: ptmo.gananciaReal,
        porcentaje: porcentaje.toFixed(2),
        fechaInicio: ptmo.fechaInicio,
        fechaFinal: ptmo.fechaCompromisoPago,
        email: ptmo.usuario.email.trim(),
        pais: ptmo.usuario.pais,
        telefono: ptmo.usuario.codigoPais + ' ' + ptmo.usuario.whatsapp,
      };
    });
    csvExporter.generateCsv(dataExportar);
  };

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>
            Contabilidad Estado de Cuenta
          </h2>
        </div>
        <hr />
        <div className='grid grid-cols-12 gap-3'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 600, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={movimientosContables}
                columns={columns}
                pageSize={50}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
            <div className='flex items-center justify-between mt-4'>
              {/* Boton Modal Agregar Ingresos*/}
              <div className='flex'>
                <div className='cursor-pointer mr-3'>
                  <button
                    className='flex box text-white bg-theme-3 p-2 items-center'
                    onClick={agregarIngresosContable}
                  >
                    <Icon.FilePlus className='w-4 h-4 mr-1' />
                    Agregar Ingresos
                  </button>
                </div>
                {/* Boton Modal Agregar Egresos*/}
                <div className='cursor-pointer'>
                  <button
                    className='flex box text-white bg-theme-36 p-2 items-center'
                    onClick={agregarEgresosContable}
                  >
                    <Icon.FileMinus className='w-4 h-4 mr-1' />
                    Agregar Egresos
                  </button>
                </div>
              </div>

              <div>
                <div className='cursor-pointer mr-3'>
                  <button
                    className='flex box text-white bg-theme-9 p-2 items-center'
                    onClick={exportarDataPrestamos}
                  >
                    <Icon.Upload className='w-4 h-4 mr-1' />
                    Exportar Data Préstramo
                  </button>
                </div>
              </div>
            </div>
            <div className='font-medium text-right mr-5 bg-theme-27 box p-1 mt-3'>
              <label>TOTALES : </label>
              <span className='mx-2 bg-theme-25 box p-1'>
                {totalDebe().toFixed(2)} ,$$
              </span>
              <span className='mx-2 bg-theme-36 box p-1'>
                {totalHaber().toFixed(2)} ,$$
              </span>
              <span className='mx-2 bg-theme-34 box p-1'>
                {totalSaldo().toFixed(2)} ,$$
              </span>
            </div>
          </div>
        </div>

        {/*===== Modal Agregar Ingresos Contables =====*/}
        {modalIngresosContables && (
          <Modal
            title='Agregar Ingresos Contables'
            close={closeAgregarIngresoContable}
            save={guardarAgregarIngresoContable}
            cancel={cancelAgregarIngresoContable}
          >
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>
                Categoria :
              </label>
              <select
                type='text'
                name='categoria'
                className='form-select w-full rounded-full border input p-1 mt-2'
                autoFocus={true}
                value={movimientoContable.categoria}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
              >
                <option value=''> Seleccione</option>
                <option value='PagoRecibido'> Pago Recibido</option>
                <option value='ApreciacionBTC'> Apreciacion BTC</option>
                <option value='Financiamiento'> Financiamiento</option>
                <option value='Otros'> Otros</option>
              </select>
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>Monto :</label>
              <input
                type='number'
                name='monto'
                className='w-full rounded-full border input p-1 mt-2'
                placeholder=' Monto'
                value={movimientoContable.monto}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
              <input
                type='text'
                name='fecha'
                className='w-full rounded-full border input p-1 mt-2'
                value={movimientoContable.fecha}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>
                Concepto :
              </label>
              <textarea
                type='text'
                data-feature='basic'
                className='summernote bg-theme-2 input w-full rounded-md mt-2'
                placeholder=' Concepto del movimiento...'
                name='concepto'
                value={movimientoContable.concepto}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
                rows='2'
              />
            </div>
          </Modal>
        )}
        {/*===== Modal Agregar Egresos Contables =====*/}
        {modalEgresosContables && (
          <Modal
            title='Agregar Egresos Contables'
            close={closeAgregarEgresoContable}
            save={guardarAgregarEgresoContable}
            cancel={cancelAgregarEgresoContable}
          >
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>
                Categoria :
              </label>
              <select
                type='text'
                name='categoria'
                className='form-select w-full rounded-full border input p-1 mt-2'
                autoFocus={true}
                value={movimientoContable.categoria}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
              >
                <option value=''> Seleccione</option>
                <option value='Prestamo'> Prestamo</option>
                <option value='PagosInternos'> Pagos Internos</option>
                <option value='Retiros'> Retiros</option>
                <option value='DepreciacionBTC'> Depreciacion BTC</option>
                <option value='Publicidad'> Publicidad</option>
                <option value='Otros'> Otros</option>
              </select>
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>Monto :</label>
              <input
                type='number'
                name='monto'
                className='w-full rounded-full border input p-1 mt-2'
                placeholder=' Monto'
                value={movimientoContable.monto}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
              <input
                type='text'
                name='fecha'
                className='w-full rounded-full border input p-1 mt-2'
                value={movimientoContable.fecha}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>
                Concepto :
              </label>
              <textarea
                type='text'
                data-feature='basic'
                className='summernote bg-theme-2 input w-full rounded-md mt-2'
                placeholder=' Concepto del movimiento... '
                name='concepto'
                value={movimientoContable.concepto}
                onChange={changeAgregarMovimientoContable}
                onKeyUp={changeAgregarMovimientoContable}
                rows='2'
              />
            </div>
          </Modal>
        )}
        {/*===== Modal Editar Movimientos Contables =====*/}
        {modalEditarMovimientosContables && (
          <Modal
            title='Editar Movimientos Contables'
            close={closeEditarMovimientoContable}
            save={guardarEditarMovimientoContable}
            cancel={cancelEditarMovimientoContable}
          >
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>
                Categoria :
              </label>
              <select
                type='text'
                name='categoria'
                className='form-select w-full rounded-full border input p-1 mt-2'
                autoFocus={true}
                value={movimientoContable.categoria}
                onChange={changeAgregarMovimientoContable}
              >
                <option value='Prestamo'> Prestamo</option>
                <option value='PagosInternos'> Pagos Internos</option>
                <option value='Retiros'> Retiros</option>
                <option value='DepreciacionBTC'> Depreciacion BTC</option>
                <option value='Publicidad'> Publicidad</option>
                <option value='Otros'> Otros</option>
              </select>
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>Monto :</label>
              <input
                type='number'
                name='monto'
                className='w-full rounded-full border input p-1 mt-2'
                placeholder=' Monto'
                value={movimientoContable.monto}
                onChange={changeAgregarMovimientoContable}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>Fecha :</label>
              <input
                type='text'
                name='fecha'
                className='w-full rounded-full border input p-1 mt-2'
                value={movimientoContable.fecha}
                onChange={changeAgregarMovimientoContable}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <label className='font-medium bg-theme-26 box p-1'>
                Concepto :
              </label>
              <textarea
                type='text'
                data-feature='basic'
                className='summernote bg-theme-2 input w-full rounded-md mt-2'
                placeholder=' Concepto del movimiento... '
                name='concepto'
                value={movimientoContable.concepto}
                onChange={changeAgregarMovimientoContable}
                rows='2'
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default Contabilidad;
