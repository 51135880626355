import {
  ALL_PAGES,
  ALL_ELEMENTS,
  GET_USERS,
  GET_CONFIGURATION,
  SET_CONFIGURATION,
  SET_EMAILEMPRESA,
  SET_WHATSAPP,
  SET_CONTACT,
  SET_FACEBOOKPRICE,
  SET_WEBSPRICE,
  SET_ALLCHANGES,
  SET_WEBSTEXT,
  SET_FBTEXT,
  SET_STATUS,
  LOADING,
  ENDLOADING,
  ENDUPLOAD,
  CLOSE_MODAL_ADMIN,
  TEST,
  VIEW_REPORT,
  DELETE_USER,
  SET_INTERES,
  GET_MENSAJESPREDEFINIDOS,
  MODAL_AGREGARMENSAJES,
  MODAL_EDITARMENSAJES,
  GETCHAT,
  GET_PRESTAMO,
  GET_LINKSOCIALES,
  GET_REQUERIMIENTOSUSUARIOS,
  GET_REQUERIMIENTOPRESTAMO,
  GET_LISTPRESTAMOS,
  GET_ABONOSREALIZADOS,
  LISTADOACTIVO,
  GET_LISTGESTORES,
  GET_GESTORPRESTAMO,
} from '../types';

import firebase from 'firebase';
import moment from 'moment';
import { app } from '../../config/firebaseConfig';
import { isConsole } from 'react-device-detect';
import { RecentActorsOutlined } from '@material-ui/icons';

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_ADMIN,
  });
};

export const viewReport = (value) => (dispatch) => {
  dispatch({
    type: VIEW_REPORT,
    payload: value,
  });
};

export const test = (category, action, user) => (dispatch) => {
  dispatch({ type: LOADING });
  const newUser = null;
  const trabajo = {
    status: false,
    user: null,
    doneAt: '',
  };
  if (category !== 'Elements') {
    firebase
      .firestore()
      .collection('pages')
      .where('user', '==', user)
      .where('categoria', '==', category)
      .get()
      .then((data) => {
        data.forEach((doc) => {
          if (action === 'reset') {
            firebase
              .firestore()
              .doc(`pages/${doc.id}`)
              .update({ user: newUser, trabajo: trabajo });
          } else {
            firebase.firestore().doc(`pages/${doc.id}`).delete();
          }
        });
        return;
      })
      .then(() => {
        dispatch({
          type: TEST,
        });
        dispatch({
          type: ENDLOADING,
        });
      });
  } else {
    firebase
      .firestore()
      .collection('elements')
      .where('user', '==', user)
      .get()
      .then((data) => {
        data.forEach((doc) => {
          if (action === 'reset') {
            firebase
              .firestore()
              .doc(`elements/${doc.id}`)
              .update({ user: newUser, trabajo: trabajo });
          } else {
            firebase.firestore().doc(`elements/${doc.id}`).delete();
          }
        });
        return;
      })
      .then(() => {
        dispatch({
          type: TEST,
        });
        dispatch({
          type: ENDLOADING,
        });
      });
  }
};

export const getElements = () => async (dispatch) => {
  let elements = [];
  let elementsDone = [];
  let elementsBad = [];
  await firebase
    .firestore()
    .collection('elements')
    .get()
    .then((data) => {
      data.forEach((doc) => {
        if (doc.exists) {
          let doneAt;
          if (typeof doc.data().trabajo.doneAt === 'string') {
            doneAt = doc.data().trabajo.doneAt;
          } else {
            doneAt = moment(doc.data().trabajo.doneAt.toDate()).format();
          }
          elements.push({
            id: doc.id,
            empresa: doc.data().empresa,
            pais: doc.data().pais,
          });
          if (doc.data().trabajo.status === true) {
            if (doc.data().trabajo.error === false) {
              elementsDone.push({
                id: doc.id,
                pais: doc.data().empresa,
                empresa: doc.data().pais,
                user: doc.data().user,
                doneAt: doneAt,
                url: doc.data().trabajo.siteURL,
                email: doc.data().trabajo.email,
              });
            } else {
              elementsBad.push({
                id: doc.id,
                pais: doc.data().empresa,
                empresa: doc.data().pais,
                user: doc.data().user,
                doneAt: doneAt,
              });
            }
          }
        }
      });
    })
    .then(() => {
      dispatch({
        type: ALL_ELEMENTS,
        elements: elements,
        elementsDone: elementsDone,
        elementsBad: elementsBad,
      });
    });
};

export const getPages = () => async (dispatch) => {
  let facebookPages = [];
  let websPages = [];
  let facebookPagesDone = [];
  let websPagesDone = [];
  let allPages = [];
  let pagesErrors = [];
  await firebase
    .firestore()
    .collection('pages')
    .get()
    .then((data) => {
      data.forEach((doc) => {
        if (doc.exists) {
          allPages.push({
            id: doc.id,
            url: doc.data().url,
          });
          if (doc.data().categoria === 'Facebook') {
            facebookPages.push({
              id: doc.id,
              url: doc.data().url,
              pais: doc.data().pais,
              empresa: doc.data().empresa,
            });
            if (doc.data().trabajo.status === true) {
              facebookPagesDone.push({
                id: doc.id,
                url: doc.data().url,
                pais: doc.data().pais,
                empresa: doc.data().empresa,
                user: doc.data().user,
                doneAt: doc.data().trabajo.doneAt,
                image: doc.data().trabajo.imageWorked,
                userName: doc.data().trabajo.user,
              });
            }
          } else {
            websPages.push({
              id: doc.id,
              url: doc.data().url,
              pais: doc.data().pais,
              empresa: doc.data().empresa,
            });
            if (
              doc.data().trabajo.status === true &&
              doc.data().trabajo.error === false
            ) {
              websPagesDone.push({
                id: doc.id,
                url: doc.data().url,
                pais: doc.data().pais,
                empresa: doc.data().empresa,
                user: doc.data().user,
                doneAt: doc.data().trabajo.doneAt,
                image: doc.data().trabajo.imageWorked,
                userName: doc.data().trabajo.user,
              });
            }
            if (
              doc.data().trabajo.status === true &&
              doc.data().trabajo.error === true
            ) {
              pagesErrors.push({
                id: doc.id,
                url: doc.data().url,
                pais: doc.data().pais,
                empresa: doc.data().empresa,
                user: doc.data().user,
                doneAt: doc.data().trabajo.doneAt,
                image: doc.data().trabajo.imageWorked,
                userName: doc.data().trabajo.user,
              });
            }
          }
        }
      });
    })
    .then(() => {
      dispatch({
        type: ALL_PAGES,
        fbPages: facebookPages.length,
        fbPagesDone: facebookPagesDone,
        wPages: websPages.length,
        wPagesDone: websPagesDone,
        allPages: allPages,
        pagesErrors: pagesErrors,
      });
      dispatch({ type: ENDLOADING });
    });
};

export const updateElements = (file, allElements) => (dispatch) => {
  dispatch({ type: LOADING });
  let archivo = file;
  if (!archivo) {
    return;
  }
  let lector = new FileReader();
  lector.onload = function (e) {
    let contenido = e.target.result;
    processData(contenido);
  };
  lector.readAsText(archivo);

  var allDone = [];
  var allExist = [];
  let allBad = 0;
  let index = 0;

  async function processData(allText) {
    let allTextLines = allText.split(/\r\n|\n/);

    allTextLines.map(async (line) => {
      let element = line.split(',');
      let longElement = `${element[0].toLowerCase()}${element[1].toLowerCase()}`;
      let key = longElement
        .replace(/[ .()&/*]/g, '')
        .replace(/[ñ]/g, 'n')
        .replace(/[á]/g, 'a')
        .replace(/[é]/g, 'e')
        .replace(/[í]/g, 'i')
        .replace(/[ó]/g, 'o')
        .replace(/[ú]/g, 'u')
        .substring(0, 30);
      // console.log(key);

      let createdAt = new Date().toISOString(),
        uploadedBy = firebase.auth().currentUser.email,
        empresa = element[0],
        pais = element[1],
        user = null,
        trabajo = {
          status: false,
          user: null,
          doneAt: '',
        };
      if (key !== 'empresapais') {
        try {
          await firebase
            .firestore()
            .doc(`elements/${key}`)
            .set({ uploadedBy, createdAt, empresa, pais, user, trabajo })
            .then(() => {
              index++;
              finish(allTextLines);
            });
        } catch (error) {
          allExist.push({
            number: 1,
          });
          console.log(
            allExist.length +
              ' -REPETIDO-  empresa ' +
              empresa +
              ' pais ' +
              pais
          );
          index++;
          finish(allTextLines);
        }
      } else {
        index++;
        finish(allTextLines);
      }
      //firebase.firestore().doc(`elements/${key}`).set({ createdAt, empresa, pais, user, trabajo })

      // if(element[0] !== "" && element.length === 2){
      //   let indexElements = allElements.findIndex(elementMap => `${elementMap.empresa}${elementMap.pais}` === longElement);
      //   console.log(`${element[0]}${element[1]}`)
      //   if(indexElements === -1 && (`${element[0]}${element[1]}`) !== 'empresa pais' && (`${element[0]}${element[1]}`) !== 'empresapais'){
      //     const newElement = {
      //       createdAt: new Date().toISOString(),
      //       empresa: element[0],
      //       pais: element[1],
      //       user: null,
      //       trabajo: {
      //         status: false,
      //         user: null,
      //         doneAt: ""
      //       }
      //     }
      //     firebase.firestore().collection('elements').add(newElement).then(() => {
      //       index++
      //       allDone.push({
      //         number: 1
      //       })
      //       console.log('Guardando')
      //       finish(allTextLines)
      //     })
      //   }else{
      //     allExist.push({
      //       number: 1
      //     })
      //     index++
      //     finish(allTextLines)
      //   }
      // }else{
      //   allBad++
      //   index++
      //   finish(allTextLines)
      // }
    });
  }

  function finish(allTextLines) {
    if (index === allTextLines.length) {
      console.log('Finalizado');
      dispatch({ type: ENDLOADING });
      // dispatch({
      //   type: ENDUPLOAD,
      //   done: allDone.length,
      //   exist: allExist.length,
      //   bad: allBad,
      //   all: index,
      //   categoria: "elementos"
      // })
    }
  }
};

export const updateDocument = (categoria, file, allPages) => (dispatch) => {
  dispatch({ type: LOADING });
  let archivo = file;
  if (!archivo) {
    return;
  }
  let lector = new FileReader();
  lector.onload = function (e) {
    let contenido = e.target.result;
    processData(contenido);
  };
  lector.readAsText(archivo);

  var allDone = [];
  var allExist = [];
  let allBad = 0;
  let index = 0;

  async function processData(allText) {
    let allTextLines = allText.split(/\r\n|\n/);

    allTextLines.map((line) => {
      let page = line.split(',');

      if (page[0] !== '' && page.length === 3) {
        let indexPages = allPages.findIndex(
          (pageMap) => pageMap.url === page[0]
        );
        console.log(indexPages);
        if (indexPages === -1) {
          const newPage = {
            categoria: categoria,
            createdAt: new Date().toISOString(),
            url: page[0],
            empresa: page[1],
            pais: page[2],
            user: null,
            trabajo: {
              status: false,
              user: null,
              doneAt: '',
            },
          };
          firebase
            .firestore()
            .collection('pages')
            .add(newPage)
            .then(() => {
              index++;
              allDone.push({
                number: 1,
              });
              console.log('Guardando');
              finish(allTextLines);
            });
        } else {
          allExist.push({
            number: 1,
          });
          index++;
          finish(allTextLines);
        }
      } else {
        allBad++;
        index++;
        finish(allTextLines);
      }
    });
  }
  function finish(allTextLines) {
    console.log(index);
    if (index === allTextLines.length) {
      console.log('Finalizado');
      dispatch({ type: ENDLOADING });
      dispatch({
        type: ENDUPLOAD,
        done: allDone.length,
        exist: allExist.length,
        bad: allBad,
        all: index,
        categoria: categoria,
      });
    }
  }
};

export const getUsers = () => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .orderBy('fechaRegistro', 'desc')
    .get()
    .then((data) => {
      let allUsers = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        allUsers.push(obj);
      });
      dispatch({
        type: GET_USERS,
        payload: allUsers,
      });
    });
};

export const setStatus = (id, status, rol) => (dispatch) => {
  firebase.firestore().doc(`/users/${id}`).update({ status, rol });
  dispatch({
    type: SET_STATUS,
    status: status,
    rol: rol,
    id: id,
  });
  // getUsers();
};

export const getConfiguration = () => (dispatch) => {
  app
    .firestore()
    .collection('configuracion')
    .doc('general')
    .get()
    .then((doc) => {
      let allConfig;
      if (doc.exists) {
        allConfig = doc.data();
      } else {
        allConfig = {};
      }
      dispatch({
        type: GET_CONFIGURATION,
        payload: allConfig,
      });
    });
};

export const setConfiguration = (config) => (dispatch) => {
  app.firestore().collection('configuracion').doc(`general`).update(config);
  dispatch({ type: SET_CONFIGURATION, payload: config });
  if (config.fondo > 0) {
    dispatch(disabledListado());
  }
};

export const disabledListado = () => (dispatch) => {
  app
    .firestore()
    .collection('logsinfondos')
    .where('activo', '==', true)
    .get()
    .then((data) => {
      let result = [];
      data.forEach((doc) => {
        let obj = { id: doc.id, ...doc.data() };
        result.push(obj);
      });
      if (result.length > 0) {
        const listado = result[0];
        dispatch({ type: LISTADOACTIVO, payload: listado });
        app
          .firestore()
          .collection('logsinfondos')
          .doc(`${listado.id}`)
          .update({ activo: false });
      } else {
        dispatch({ type: LISTADOACTIVO, payload: null });
      }
    });
};

export const updateEmailEmpresa = (email) => (dispatch) => {
  firebase.firestore().doc('configuration/general').update({ email: email });
  dispatch({
    type: SET_EMAILEMPRESA,
    payload: email,
  });
};

export const updateContact = (contact) => (dispatch) => {
  firebase
    .firestore()
    .doc('configuration/general')
    .update({ contact: contact });
  dispatch({
    type: SET_CONTACT,
    payload: contact,
  });
};

export const updateWhatsapp = (whatsapp) => (dispatch) => {
  firebase
    .firestore()
    .doc('configuration/general')
    .update({ whatsapp: whatsapp });
  dispatch({
    type: SET_WHATSAPP,
    payload: whatsapp,
  });
};

export const updateFacebookPrice = (facebookPrice) => (dispatch) => {
  firebase
    .firestore()
    .doc('configuration/general')
    .update({ facebookPrice: facebookPrice });
  dispatch({
    type: SET_FACEBOOKPRICE,
    payload: facebookPrice,
  });
};

export const updateWebsPrice = (websPrice) => (dispatch) => {
  firebase
    .firestore()
    .doc('configuration/general')
    .update({ websPrice: websPrice });
  dispatch({
    type: SET_WEBSPRICE,
    payload: websPrice,
  });
};

export const updateTextWebs = (text) => (dispatch) => {
  firebase.firestore().doc('configuration/general').update({ textWebs: text });
  dispatch({
    type: SET_WEBSTEXT,
    payload: text,
  });
};

export const updateTextFb = (text) => (dispatch) => {
  firebase
    .firestore()
    .doc('configuration/general')
    .update({ textFacebook: text });
  dispatch({
    type: SET_FBTEXT,
    payload: text,
  });
};

export const updateAllChanges =
  (
    email,
    whatsapp,
    contact,
    facebookPrice,
    websPrice,
    textFacebook,
    textWebs,
    elementsPrice
  ) =>
  (dispatch) => {
    const allData = {
      email,
      whatsapp,
      contact,
      facebookPrice,
      websPrice,
      textFacebook,
      textWebs,
      elementsPrice,
    };
    firebase.firestore().doc('configuration/general').update({
      email,
      whatsapp,
      contact,
      facebookPrice,
      websPrice,
      textFacebook,
      textWebs,
      elementsPrice,
    });
    dispatch({
      type: SET_ALLCHANGES,
      payload: allData,
    });
  };

export const deleteUser = (userEmail) => (dispatch) => {
  firebase
    .firestore()
    .doc(`users/${userEmail}`)
    .delete()
    .then(() => {
      console.log('Eliminado de firebase, a borrar del redux');
      dispatch({
        type: DELETE_USER,
        payload: userEmail,
      });
    });
};

export const changeInteres = (value) => (dispatch) => {
  console.log('value', value);
  dispatch({
    type: SET_INTERES,
    payload: value,
  });
};

//========== Modal Agregar mensajes ==========//
export const modalAgregarMensaje = (value) => (dispatch) => {
  dispatch({ type: MODAL_AGREGARMENSAJES, payload: value });
};

export const getMensajesPredefinidos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('mensajespredefinidos')
    .get()
    .then(async (data) => {
      let mensajespredefinidos = [];
      let mensaje = {};

      data.forEach((doc) => {
        mensaje = doc.data();
        mensaje.id = doc.id;
        mensajespredefinidos.push(mensaje);
      });

      dispatch({
        type: GET_MENSAJESPREDEFINIDOS,
        payload: mensajespredefinidos,
      });
    });
};

export const saveAgregarMensajes = (mensaje, userid) => (dispatch) => {
  app
    .firestore()
    .collection('mensajespredefinidos')
    .add(mensaje)
    .then((resp) => {
      dispatch(getMensajesPredefinidos());
      dispatch({ type: MODAL_AGREGARMENSAJES, payload: false });
    });
};

//========== Modal Editar mensajes ==========//
export const modalEditarMensaje = (value) => (dispatch) => {
  dispatch({ type: MODAL_EDITARMENSAJES, payload: value });
};

export const updateEditarMensaje = (mensaje) => (dispatch) => {
  app
    .firestore()
    .collection('mensajespredefinidos')
    .doc(`${mensaje.idMensajePredefinido}`)
    .update(mensaje)
    .then((resp) => {
      dispatch(getMensajesPredefinidos());
      dispatch({ type: MODAL_EDITARMENSAJES, payload: false });
    });
};

export const deleteMensaje = (mensaje) => (dispatch) => {
  app
    .firestore()
    .collection('mensajespredefinidos')
    .doc(`${mensaje.id}`)
    .delete()
    .then((resp) => {
      dispatch(getMensajesPredefinidos());
    });
};

export const deleteUsuario = (user) => (dispatch) => {
  console.log('USER', user);
  app
    .firestore()
    .collection('usuarios')
    .doc(`${user.id}`)
    .delete()
    .then((resp) => {
      dispatch(getUsers());
    });
};

//========== Chat ==========//
export const getChat = (idPrestamo) => (dispatch) => {
  app
    .firestore()
    .collection('chat')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .get()
    .then((data) => {
      let array = [];
      let objeto = {};
      data.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETCHAT, payload: array[0] });
    });
};

//========== Prestamos ==========//
export const getPrestamo = (id) => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    // .orderBy('fechaSolicitud', 'desc')
    .doc(id)
    .get()
    .then((doc) => {
      dispatch({ type: GET_PRESTAMO, payload: doc.data() });
      dispatch(getLinkSociales(doc.data().idUsuario));
      dispatch(getRequerimientosUsuarios(doc.data().idUsuario));
    });
};

//========== Listado de Prestamos ==========//
export const getListPrestamos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let listPrestamos = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listPrestamos.push(obj);
      });
      dispatch({
        type: GET_LISTPRESTAMOS,
        payload: listPrestamos,
      });
    });
};

//========== Listado de Gestores ==========//
export const getListGestores = () => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .where('rol', '==', 'Gestor')
    .get()
    .then(async (data) => {
      let listGestores = [];
      let obj = {};
      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listGestores.push(obj);
      });
      let prestado = {};
      let array = [];
      let arrayFinal = [];
      for (let i = 0; i < listGestores.length; i++) {
        const result = await app
          .firestore()
          .collection('prestamos')
          .where('idGestor', '==', `${listGestores[i].id}`)
          .get();
        let arrayPrestado = [];
        result.forEach((prest) => {
          prestado = prest.data();
          prestado.id = prest.id;
          arrayPrestado.push(prestado);
        });
        const arrayConcat = {
          ...listGestores[i],
          prestamos: arrayPrestado.length,
        };
        arrayFinal.push(arrayConcat);
      }
      dispatch({ type: GET_LISTGESTORES, payload: arrayFinal });
    });
};

//========== Links Sociales ==========//
export const getLinkSociales = (idUsuario) => async (dispatch) => {
  await app
    .firestore()
    .collection('sociales')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then(async (data) => {
      let linkSociales = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        linkSociales.push(obj);
      });

      dispatch({
        type: GET_LINKSOCIALES,
        payload: linkSociales,
      });
    });
};

//========== Requerimientos ==========//
export const getRequerimientosUsuarios = (idUsuario) => async (dispatch) => {
  await app
    .firestore()
    .collection('requerimiento')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then(async (data) => {
      let requerimientosUsuarios = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        requerimientosUsuarios.push(obj);
      });

      dispatch({
        type: GET_REQUERIMIENTOSUSUARIOS,
        payload: requerimientosUsuarios,
      });
    });
};

export const getRequerimientoPrestamo = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('requerimientoPrestamo')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .get()
    .then(async (data) => {
      let requerimientoPrestamo = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        requerimientoPrestamo.push(obj);
      });

      dispatch({
        type: GET_REQUERIMIENTOPRESTAMO,
        payload: requerimientoPrestamo,
      });
    });
};

export const getAbonosRealizados = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('abonos')
    .where('idPrestamo', '==', idPrestamo)
    .orderBy('fecha', 'asc')
    .get()
    .then(async (data) => {
      let abonosRealizados = [];
      let abono = {};

      data.forEach((doc) => {
        abono = doc.data();
        abono.id = doc.id;
        abonosRealizados.push(abono);
      });

      dispatch({
        type: GET_ABONOSREALIZADOS,
        payload: abonosRealizados,
      });
    });
};
