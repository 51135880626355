/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';

import * as Icon from 'react-feather';
import { app } from '../../../config/firebaseConfig';

import {
  DATOSGENERELES,
  GET_MYUSER,
  GET_USUARIO,
  PRESTAMOACTUAL,
} from '../../../redux/types';
import {
  getSocialesUser,
  getPrestamoUser,
  updateDatosPrestamoUser,
} from '../../../redux/actions/userAction';

import { custom } from '../../../helpers/alertas';
import countrys from '../../../config/pais';
import { getUsuario } from '../../../redux/actions/superAdminActions';

const DatosUsuario = () => {
  const dispatch = useDispatch();
  let { UserId } = useParams();

  //========== USE-SELECTORS ==========//
  const usuario = useSelector((state) => state.superadmin.usuario);
  const datosGenerales = useSelector((state) => state.user.datosGenerales);
  const sociales = useSelector((state) => state.user.linksocialesUser);
  const prestamoActual = useSelector((state) => state.user.prestamoActual);

  //========== USE-STATES ==========//
  const [area, setArea] = useState([]);
  const [editarSocial, setEditarSocial] = useState(false);
  const [social, setSocial] = useState({
    fechaValidacion: null,
    gestor: null,
    id: '',
    idGestor: null,
    idSocial: '',
    idUsuario: UserId,
    solicitudConfirmada: false,
    solicitudEnviada: false,
    tipo: '',
    url: '',
    validado: '',
  });
  const [generales, setGenerales] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    fechaNacimiento: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: 'Venezuela - Transferencia: Cuenta Bancaria BDV',
    nombres: '',
    nombreEmpresa: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    sexo: 'M',
    telefonoOpcional: '',
    telegram: '',
    trabaja: 'No',
    whatsapp: '',
  });

  useEffect(() => {
    // getGeoInfo();
    getDatosGenerales();
    dispatch(getUsuario(UserId));
    dispatch(getSocialesUser(UserId));
    dispatch(getPrestamoUser(UserId));
    // getRedesChamopresta();
    getDatos();
  }, []);

  //===== Metodo para llamar area trabajo=====//
  const getDatos = async () => {
    await app
      .firestore()
      .collection('areaTrabajo')
      .orderBy('area', 'asc')
      .onSnapshot((query) => {
        const listado = [];
        query.forEach((doc) => {
          listado.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setArea(listado);
      });
  };
  const getDatosGenerales = (e) => {
    app
      .firestore()
      .collection('usuarios')
      .doc(`${UserId}`)
      .get()
      .then((doc) => {
        let data;
        if (doc.exists) {
          data = {
            apellidos: doc.data().apellidos,
            areaTrabajo: doc.data().areaTrabajo,
            codigoPais:
              generales.codigoPais !== ''
                ? generales.codigoPais
                : doc.data().codigoPais,
            descripcionActividad: doc.data().descripcionActividad,
            fechaNacimiento: doc.data().fechaNacimiento,
            ingresoMensualUSD: doc.data().ingresoMensualUSD,
            localidad: doc.data().localidad,
            nombres: doc.data().nombres,
            numeroCedula: doc.data().numeroCedula,
            nombreEmpresa: doc.data().nombreEmpresa,
            numeroPasaporte: doc.data().numeroPasaporte,
            pais: generales.pais !== '' ? generales.pais : doc.data().pais,
            sexo: doc.data().sexo,
            telefonoOpcional: doc.data().telefonoOpcional,
            telegram: doc.data().telegram,
            trabaja: doc.data().trabaja,
            whatsapp: doc.data().whatsapp,
          };
          setGenerales(data);
          dispatch({ type: DATOSGENERELES, payload: data });
        } else {
          data = null;
          dispatch({ type: DATOSGENERELES, payload: data });
        }
      });
  };
  const changeGenerales = (e) => {
    const { name, value } = e.target;
    let dataUpdate = { ...generales, [name]: value };
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };

  //===== Funciones y Metodos Personales =====//
  const changeSexo = (e) => {
    const { value } = e.target;
    let dataUpdate = { ...generales, ['sexo']: value };
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };

  //===== Funciones y Metodos Ubicación =====//
  const changePais = (e) => {
    const { value } = e.target;
    let filter = countrys.listado.filter((fila) => fila.nombre === value);
    setGenerales({
      ...generales,
      ['pais']: value,
      ['codigoPais']: filter[0].codigo,
    });
    dispatch({ type: DATOSGENERELES, payload: generales });
  };

  //===== Funciones y Metodos Laborales =====//
  const ChangeTrabaja = (e) => {
    const { value } = e.target;
    let dataUpdate;
    if (value === 'No') {
      dataUpdate = {
        ...generales,
        ['trabaja']: value,
        ['areaTrabajo']: '',
        ['nombreEmpresa']: '',
        ['descripcionActividad']: '',
        ['ingresoMensualUSD']: '',
      };
    } else {
      dataUpdate = { ...generales, ['trabaja']: value };
    }
    dispatch({ type: DATOSGENERELES, payload: dataUpdate });
    setGenerales(dataUpdate);
  };
  const disableAreaTrabajo = () => {
    if (generales.trabaja === 'Si') {
      return false;
    }
    return true;
  };
  const disableSalario = () => {
    if (generales.trabaja === 'Si') {
      return false;
    }
    return true;
  };

  //===== Funciones y Metodos Redes Sociales =====//
  let placeHolderSocial = () => {
    if (sociales.length === 0) {
      return 'Ejemplo: https://www.facebook.com/alguien ó https://www.instagram.com/alguien';
    } else if (sociales.length === 2) {
      return 'Redes sociales cargadas correctamente';
    } else {
      return sociales.map((ele) =>
        ele.tipo === 'facebook'
          ? `Siguiente: https://www.instagram.com/alguien`
          : 'Siguiente: https://www.facebook.com/alguien'
      );
    }
  };
  const buildType = (str) => {
    if (str.includes('facebook') || str.includes('fb')) {
      return 'facebook';
    } else if (str.includes('instagram')) {
      return 'instagram';
    } else {
      return '';
    }
  };
  const changeSocial = (e) => {
    const { name, value } = e.target;
    setSocial({ ...social, [name]: value });
    dispatch({ type: DATOSGENERELES, payload: generales });
  };
  const editarLinkSocial = (item) => {
    setSocial(item);
    setEditarSocial(true);
  };
  const cancel = () => {
    setSocial({
      fechaValidacion: null,
      idGestor: null,
      id: '',
      idSocial: '',
      idUsuario: UserId,
      tipo: '',
      url: '',
      validado: '',
    });
    setEditarSocial(false);
  };
  const addSocial = () => {
    let data = social;
    data.tipo = buildType(data.url);
    app
      .firestore()
      .collection('sociales')
      .add(data)
      .then((resp) => {
        dispatch(getSocialesUser(UserId));
        setSocial({
          fechaValidacion: null,
          gestor: null,
          id: '',
          idGestor: null,
          idSocial: '',
          idUsuario: UserId,
          solicitudConfirmada: false,
          solicitudEnviada: false,
          tipo: '',
          url: '',
          validado: '',
        });
        setEditarSocial(false);
      });
  };
  const guardarSocial = (e) => {
    e.preventDefault();
    if (editarSocial) {
      if (social.url === '') {
        custom('Debes llenar el campo url', 'error');
      } else {
        updateSocial();
      }
    } else {
      if (social.url === '') {
        custom('Debes llenar el campo url', 'error');
      } else {
        addSocial();
      }
    }
  };
  const updateSocial = () => {
    let data = social;
    data.tipo = buildType(data.url);
    app
      .firestore()
      .collection('sociales')
      .doc(`${social.id}`)
      .update(data)
      .then((resp) => {
        dispatch(getSocialesUser(UserId));
        setSocial({
          fechaValidacion: null,
          gestor: null,
          id: '',
          idGestor: null,
          idSocial: '',
          idUsuario: UserId,
          solicitudConfirmada: false,
          solicitudEnviada: false,
          tipo: '',
          url: '',
          validado: '',
        });
        setEditarSocial(false);
      });
  };

  const validarTrabajo = () => {
    if (generales.trabaja === 'No') {
      return true;
    } else {
      if (
        generales.areaTrabajo === '' ||
        generales.nombreEmpresa === '' ||
        generales.descripcionActividad === '' ||
        generales.ingresoMensualUSD === ''
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const next = () => {
    if (
      generales.apellidos === '' ||
      generales.nombres === '' ||
      generales.fechaNacimiento === '' ||
      generales.sexo === '' ||
      generales.numeroCedula === '' ||
      generales.pais === '' ||
      generales.localidad === '' ||
      generales.whatsapp === '' ||
      generales.trabaja === ''
    ) {
      custom('Faltan datos por llenar', 'error');
    } else if (!validarTrabajo()) {
      custom('Faltan datos por llenarr', 'error');
    } else {
      let obj1 = Object.assign({}, prestamoActual, {
        tab: 'tabsociales',
      });
      const userUpdate = Object.assign(usuario, generales);

      // dispatch({ type: PRESTAMOACTUAL, payload: obj1 });
      dispatch(updateDatosPrestamoUser(datosGenerales, obj1, true));
      // dispatch({ type: GET_MYUSER, payload: userUpdate });
    }
  };

  const back = () => {
    console.log('Atras funcionando');
    // switch (indexWizard) {
    //   case 1:
    //     break;
    //   case 2:
    //     switch (prestamoActual.tab) {
    //       case 'tabpersonales':
    //         app
    //           .firestore()
    //           .collection('prestamos')
    //           .doc(`${prestamoActual.id}`)
    //           .update({ indexWizard: 2 });
    //         dispatch({ type: 'WIZARD', payload: 2 });
    //         break;
    //       case 'tabubicacion':
    //         app
    //           .firestore()
    //           .collection('prestamos')
    //           .doc(`${prestamoActual.id}`)
    //           .update({ tab: 'tabpersonales' });
    //         let obj2 = Object.assign({}, prestamoActual, {
    //           tab: 'tabpersonales',
    //         });
    //         dispatch({ type: PRESTAMOACTUAL, payload: obj2 });
    //         dispatch(
    //           updateDatosGenereales(datosGenerales, prestamoActual, false)
    //         );
    //         break;
    //       case 'tablaborales':
    //         app
    //           .firestore()
    //           .collection('prestamos')
    //           .doc(`${prestamoActual.id}`)
    //           .update({ tab: 'tabubicacion' });
    //         let obj3 = Object.assign({}, prestamoActual, {
    //           tab: 'tabubicacion',
    //         });
    //         dispatch({ type: PRESTAMOACTUAL, payload: obj3 });
    //         dispatch(
    //           updateDatosGenereales(datosGenerales, prestamoActual, false)
    //         );
    //         break;
    //       case 'tabsociales':
    //         app
    //           .firestore()
    //           .collection('prestamos')
    //           .doc(`${prestamoActual.id}`)
    //           .update({ tab: 'tablaborales' });
    //         let obj4 = Object.assign({}, prestamoActual, {
    //           tab: 'tablaborales',
    //         });
    //         dispatch({ type: PRESTAMOACTUAL, payload: obj4 });
    //         dispatch(
    //           updateDatosGenereales(datosGenerales, prestamoActual, false)
    //         );
    //         break;
    //       default:
    //         break;
    //     }
    //     break;
    //   case 3:
    //     app
    //       .firestore()
    //       .collection('prestamos')
    //       .doc(`${prestamoActual.id}`)
    //       .update({ indexWizard: 2 });
    //     dispatch({ type: 'WIZARD', payload: 2 });
    //     break;
    //   case 4:
    //     app
    //       .firestore()
    //       .collection('prestamos')
    //       .doc(`${prestamoActual.id}`)
    //       .update({ indexWizard: 3 });
    //     dispatch({ type: 'WIZARD', payload: 3 });
    //     break;
    //   case 5:
    //     break;
    //   default:
    //     break;
    // }
  };

  return (
    <>
      <div className='intro-y' id='data_personal'>
        <form action='' className='mb-8'>
          {/* Datos Personales */}
          <div className='grid grid-cols-12 gap-4 row-gap-5'>
            <div className='intro-y col-span-12 sm:col-span-12 mb-3'>
              <div className='flex font-medium text-gray-700 bg-theme-5 box mb-1 p-1'>
                <Icon.FileText className='w-5 h-5 mx-2 text-gray-700' />
                Datos Personales
              </div>
              <hr />
            </div>
            <div className='intro-y col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>
                Nombres completos :
              </label>
              <input
                type='text'
                name='nombres'
                value={generales.nombres}
                className='w-full rounded-lg border intro-x input input--sm border mt-2'
                autoFocus={true}
                placeholder=' Nombres Completos'
                onChange={changeGenerales}
                onKeyPress={(event) => {
                  if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>
                Apellidos completos :
              </label>
              <input
                type='text'
                name='apellidos'
                value={generales.apellidos}
                className=' w-full rounded-lg border intro-x input input--sm border mt-2'
                placeholder='Apellidos Completos'
                onChange={changeGenerales}
                onKeyPress={(event) => {
                  if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>
                Cédula de identidad Venezolana :
              </label>
              <input
                type='text'
                name='numeroCedula'
                value={generales.numeroCedula}
                className='w-full rounded-lg border intro-x input input--sm border mt-2'
                placeholder='Numero de Cedula'
                onChange={changeGenerales}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>
                Pasaporte (Opcional) :
              </label>
              <input
                type='text'
                name='numeroPasaporte'
                value={generales.numeroPasaporte}
                className='w-full rounded-lg border intro-x input input--sm border mt-2'
                placeholder='Numero de pasaporte'
                onChange={changeGenerales}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>
                Fecha de nacimiento :
              </label>
              <input
                type='date'
                name='fechaNacimiento'
                value={generales.fechaNacimiento}
                className='input input--sm w-56 border block rounded-lg mt-2'
                onChange={changeGenerales}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-4'>
              <label className='font-medium bg-theme-26 box p-1'>Sexo :</label>
              <div className='flex flex-col sm:flex-row mt-2 mb-2'>
                <div className='flex items-center text-gray-700 mr-2'>
                  <div className='flex items-center text-gray-700 mt-2'>
                    <input
                      type='radio'
                      className='input border mr-2'
                      id='mas'
                      name='sexo'
                      value='M'
                      checked={generales.sexo === 'M'}
                      onChange={changeSexo}
                    />
                    <label htmlFor='mas'>M</label>
                  </div>
                  <div className='flex items-center text-gray-700 mt-2'>
                    <input
                      type='radio'
                      className='input border ml-2 mr-2'
                      id='fem'
                      name='sexo'
                      value='F'
                      checked={generales.sexo === 'F'}
                      onChange={changeSexo}
                    />
                    <label htmlFor='fem'>F</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Datos Ubicacion */}
          <div className='grid grid-cols-12 gap-4 row-gap-5 mt-10'>
            <div className='intro-y col-span-12 sm:col-span-12 mb-3'>
              <div className='flex font-medium text-gray-700 bg-theme-5 box mb-1 p-1'>
                <Icon.Map className='w-5 h-5 mx-2 text-gray-700' />
                Datos Ubicación
              </div>
              <hr />
            </div>
            <div className='intro-y col-span-12 sm:col-span-6'>
              <label className='font-medium bg-theme-26 box p-1'>País :</label>
              <select
                className='w-full rounded-lg border intro-x input input--sm border mt-2'
                name='pais'
                value={generales.pais}
                onChange={changePais}
              >
                <option className='bg-gray-100' value=''>
                  Seleccione país ...
                </option>
                {countrys.listado.map((item, k) => (
                  <option className='bg-gray-100' value={item.nombre} key={k}>
                    {item.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className='intro-y col-span-12 sm:col-span-6'>
              <label className='font-medium bg-theme-26 box p-1'>
                Localidad :
              </label>
              <input
                type='text'
                name='localidad'
                value={generales.localidad}
                className=' w-full rounded-lg border intro-x input input--sm border mt-2'
                placeholder='Provincia-Estado...'
                onChange={changeGenerales}
                onKeyPress={(event) => {
                  if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-6'>
              <label className='font-medium bg-theme-26 box p-1'>
                Whatsapp :
              </label>
              <div className='flex'>
                <select
                  className='w-20 mr-2 rounded-lg border intro-x input input--sm border mt-2'
                  name='codigoPais'
                  value={generales.codigoPais}
                  onChange={changeGenerales}
                >
                  <option value=''>00</option>
                  {countrys.listado.map((item, k) => (
                    <option value={item.codigo} key={k}>
                      {item.codigo}
                    </option>
                  ))}
                </select>
                <input
                  type='text'
                  name='whatsapp'
                  value={generales.whatsapp}
                  className='w-full rounded-lg border intro-x input input--sm border mt-2'
                  placeholder='Numero de Whatsapp'
                  onChange={changeGenerales}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className='intro-y col-span-12 sm:col-span-6'>
              <label className='font-medium bg-theme-26 box p-1'>
                Número contacto : (Adicional)
              </label>
              <input
                type='text'
                name='telefonoOpcional'
                value={generales.telefonoOpcional}
                className='w-full rounded-lg border intro-x input input--sm border mt-2'
                placeholder='Otro número donde pueda ser contactado'
                onChange={changeGenerales}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          {/* DatosLaborales */}
          <div className='grid grid-cols-12 gap-4 row-gap-5 mt-10'>
            <div className='intro-y col-span-12 sm:col-span-12 mb-3'>
              <div className='flex font-medium text-gray-700 bg-theme-5 box mb-1 p-1'>
                <Icon.Tool className='w-5 h-5 mx-2 text-gray-700' />
                Datos Laborales
              </div>
              <hr />
            </div>
            <div className='intro-y col-span-12 sm:col-span-2'>
              <label className='font-medium bg-theme-26 box p-1'>
                Trabaja :
              </label>
              <div className='flex flex-col sm:flex-row mt-2 mb-2'>
                <div className='flex items-center text-gray-700 mt-2'>
                  <input
                    type='radio'
                    className='input border mr-2'
                    id='trabajaSi'
                    name='trabaja'
                    value='Si'
                    checked={generales.trabaja === 'Si'}
                    onChange={ChangeTrabaja}
                  />
                  <label htmlFor='trabajaSi'>Si</label>
                </div>
                <div className='flex items-center text-gray-700 mt-2'>
                  <input
                    type='radio'
                    className='input border ml-2 mr-2'
                    id='trabajaNo'
                    name='trabaja'
                    value='No'
                    checked={generales.trabaja === 'No'}
                    onChange={ChangeTrabaja}
                  />
                  <label htmlFor='trabajaNo'>No</label>
                </div>
              </div>
            </div>
            <div className='intro-y col-span-12 sm:col-span-5'>
              <label className='font-medium bg-theme-26 box p-1'>
                Área de trabajo :
              </label>
              <select
                type='text'
                className='w-full rounded-lg border intro-x input input--sm  border mt-2'
                aria-label='Default select example'
                name='areaTrabajo'
                value={generales.areaTrabajo}
                disabled={disableAreaTrabajo()}
                onChange={changeGenerales}
              >
                <option value='{true}'>Seleccione...</option>
                {area.map((elemento, i) => (
                  <option key={i} className='flex justify-center'>
                    {elemento.area}
                  </option>
                ))}
              </select>
            </div>
            <div className='intro-y col-span-12 sm:col-span-5'>
              <label className='font-medium bg-theme-26 box p-1'>
                Nombre de empresa o emprendimiento :
              </label>
              <input
                type='text'
                className=' w-full rounded-lg border intro-x input input--sm border mt-2'
                name='nombreEmpresa'
                value={generales.nombreEmpresa}
                placeholder='Nombre de la empresa...'
                onChange={changeGenerales}
                disabled={disableAreaTrabajo()}
                onKeyPress={(event) => {
                  if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className='intro-y col-span-12 sm:col-span-6'>
              <label className='font-medium bg-theme-26 box p-1'>
                Ingreso mensual promedio dólares :
              </label>
              <input
                type='number'
                className=' w-full rounded-lg border intro-x input input--sm border mt-2'
                name='ingresoMensualUSD'
                value={generales.ingresoMensualUSD}
                placeholder='Ingreso en dólares...'
                onChange={changeGenerales}
                disabled={disableSalario()}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div className='col-span-12 sm:col-span-6'>
              <label className='font-medium bg-theme-26 box p-1'>
                Descripción de las actividades :
              </label>
              <textarea
                rows='1'
                cols='40'
                wrap='hard'
                type='text'
                className='input input--sm w-full border mt-2 rounded-lg'
                name='descripcionActividad'
                value={generales.descripcionActividad}
                onChange={changeGenerales}
                disabled={disableAreaTrabajo()}
              ></textarea>
            </div>
          </div>
        </form>

        {/* Datos RedesSociales */}
        {/* {prestamoActual.tab === 'tabsociales' && ( */}
        <>
          <div className='grid grid-cols-12 gap-4 row-gap-5 mt-10'>
            <div className='intro-y col-span-12 sm:col-span-12 mb-3'>
              <div className='flex font-medium text-gray-700 bg-theme-5 box mb-1 p-1'>
                <Icon.Cast className='w-5 h-5 mx-2 text-gray-700' />
                Datos Redes Sociales
              </div>
              <hr />
            </div>
            <div className='intro-y col-span-12 sm:col-span-9 mt-3'>
              <label className='mb-4'>
                Indique las URLs de facebook e instagram para validar la
                identidad.
              </label>
              <input
                type='text'
                name='url'
                value={social.url}
                className='w-full rounded-lg border intro-x input input--sm border mt-1'
                placeholder={placeHolderSocial()}
                onChange={changeSocial}
              />
            </div>
            <div
              className='intro-y col-span-12 sm:col-span-3 text-center mb-5'
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <div className='flex justify-center'>
                <button
                  className='flex w-auto text-white bg-theme-1 xl:mr-3 px-2 py-1 box items-center'
                  onClick={guardarSocial}
                >
                  <Icon.Save className='w-4 h-4 mr-1' />
                  Guardar
                </button>
                {editarSocial && (
                  <button
                    className='flex w-auto text-white bg-theme-6 xl:mr-3 px-2 py-1 box items-center'
                    onClick={cancel}
                  >
                    <Icon.XSquare className='w-4 h-4 mr-1' />
                    Cancelar
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-12 gap-4 row-gap-5 overflow-auto lg:overflow-visible mb-10'>
            <div className='intro-y col-span-12 sm:col-span-12'>
              <table className='table table-report mt-2 bg-theme-2 box'>
                <thead className='bg-theme-26'>
                  <tr>
                    <th className='whitespace-no-wrap'>URL</th>
                    <th className='whitespace-no-wrap'>TIPO</th>
                    <th className='text-center whitespace-no-wrap'>ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  {sociales.map((item, i) => (
                    <tr className='intro-x' key={i}>
                      <td>{item.url}</td>
                      <td>{item.tipo}</td>
                      <td className='table-report__action w-56'>
                        <div className='flex justify-center items-center text-theme-3'>
                          <a
                            className='flex items-center mr-3'
                            href='#!'
                            onClick={() => editarLinkSocial(item)}
                          >
                            <Icon.CheckSquare className='w-4 h-4 mr-1' />
                            Editar
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {sociales.length === 0 && (
                    <tr>
                      <td colSpan='3' className='text-center'>
                        No se han registrado redes sociales aún.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
        {/* // )} */}

        {/* {prestamoActual.tab === 'tabpersonales' && ( */}
        <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
          <div className='intro-y col-span-12 flex items-center justify-center sm:justify-end'>
            <button
              className='button w-24 justify-center block bg-gray-200 text-gray-600'
              onClick={back}
            >
              Atras
            </button>
            {sociales.length > 1 && (
              <button
                className='button w-24 justify-center block bg-theme-1 text-white ml-2'
                onClick={next}
              >
                Siguiente
              </button>
            )}
          </div>
        </div>
        {/* // )} */}
      </div>
    </>
  );
};

export default DatosUsuario;
