export default {
  bancosColombia: [
    { codigo: '001', nombre: 'Banco BBVA' },
    { codigo: '002', nombre: 'BANCAMIA' },
    { codigo: '003', nombre: 'Banco AGRARIO' },
    { codigo: '004', nombre: 'Banco AV VILLAS' },
    { codigo: '005', nombre: 'Banco CAJA SOCIAL' },
    { codigo: '006', nombre: 'Banco CITIBANK' },
    { codigo: '007', nombre: 'Banco COOPERATIVO' },
    { codigo: '008', nombre: 'Banco CREDIFINANCIERA SA' },
    { codigo: '009', nombre: 'DAVIPLATA' },
    { codigo: '010', nombre: 'Banco de BOGOTÁ' },
    { codigo: '011', nombre: 'Banco de OCCIDENTE' },
    { codigo: '012', nombre: 'Banco FALABELLA' },
    { codigo: '013', nombre: 'Banco FINANDINA' },
    { codigo: '014', nombre: 'Banco GNB SUDAMERIS' },
    { codigo: '014', nombre: 'Banco ITAÚ' },
    { codigo: '015', nombre: 'Banco J.P. MORGAN COLOMBIA S.A' },
    { codigo: '016', nombre: 'Banco MUNDO MUJER S.A.' },
    { codigo: '017', nombre: 'Banco PICHINCHA' },
    { codigo: '018', nombre: 'Banco POPULAR' },
    { codigo: '019', nombre: 'Banco PROCREDIT' },
    { codigo: '020', nombre: 'Banco SANTANDER' },
    { codigo: '021', nombre: 'Banco SERFINANZA S.A.' },
    { codigo: '022', nombre: 'Banco TEQUENDAMA' },
    { codigo: '023', nombre: 'Banco WWB' },
    { codigo: '024', nombre: 'BANCOLDEX' },
    { codigo: '025', nombre: 'BANCOLOMBIA' },
    { codigo: '026', nombre: 'Banco BANCOMPARTIR' },
    { codigo: '027', nombre: 'Banco CBANCOOMEVA' },
    { codigo: '028', nombre: 'Banco COLTEFINANCIERA S.A.' },
    { codigo: '029', nombre: 'CONFIAR COOPERATIVA FINANCIERA' },
    { codigo: '030', nombre: 'COOFIANTIOQUIA' },
    { codigo: '031', nombre: 'COOFINEP COOPERATIVA FINANCIERA' },
    { codigo: '032', nombre: 'COTRAFA COOPERATIVA FINANCIERA' },
    { codigo: '033', nombre: 'FINANCIERA JURISCOOP' },
    { codigo: '034', nombre: 'GIROS Y FINANZAS CF' },
    { codigo: '035', nombre: 'IRIS' },
    { codigo: '036', nombre: 'LULO BANK S.A.' },
    { codigo: '037', nombre: 'MOVii' },
    { codigo: '038', nombre: 'SCOTIABANK COLPATRIA' },
    { codigo: '039', nombre: 'SERVIFINANSA S.A.' },
    { codigo: '040', nombre: 'RAPPIPAY' },
    { codigo: '041', nombre: 'NEQUI' },
    { codigo: '042', nombre: 'BBVA CONSUMER CARD' },
  ],
};
