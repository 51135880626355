import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { axiosInstance } from '../../config/api';

import Modal from '../Modal';
import moment from 'moment';
import { app, createGeoPoint } from '../../config/firebaseConfig';
import Ilustracion from '../../statics/images/logo-chamopresta-PNG.png';
import { custom } from '../../helpers/alertas';

import { modalPolitica, addLog } from '../../redux/actions/sesionAction';

const Login = (props) => {
  const dispatch = useDispatch();
  document.body.classList.remove('app');
  document.body.classList.add('login');

  //========== USE-STATES ==========//
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [errorLogin, setErrorLogin] = useState({
    color: '',
    message: '',
    view: false,
  });
  const [captchaLogin, setCaptchaLogin] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [correoVerify, setCorreoVerify] = useState({
    email: '',
  });
  const [administrativos, setAdministrativos] = useState([]);
  const [prestamo, setPrestamo] = useState([]);
  const [prestamoFinalizado, setPrestamoFinalizado] = useState([]);

  //========== USE-SELECTORS ==========//
  const modalVerifyCorreo = useSelector(
    (state) => state.sesion.modalPoliticasPrivacidad
  );
  let recaptchaInstance;

  useEffect(() => {
    obtenerPrestamo();
    userAdministrativos();
    obtenerPrestamoFinalizados();
  }, []);

  const obtenerPrestamo = () => {
    app
      .firestore()
      .collection('prestamos')
      .where('estado', 'not-in', ['aprobado', 'finalizado'])
      // .orderBy('fechaSolicitud', 'desc')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setPrestamo(array);
      });
  };
  const obtenerPrestamoFinalizados = () => {
    app
      .firestore()
      .collection('prestamos')
      .where('estado', '==', 'finalizado')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setPrestamoFinalizado(array);
      });
  };
  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Gestor', 'Bloqueado'])
      // .where('rol', '==', 'SuperAdmin')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
      });
  };

  //========== Funciones y Metodos Asociados al Login ==========//
  const onChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const verifyrecaptcha = (response) => {
    if (response) {
      setCaptchaLogin(true);
    }
  };
  const login = async (e) => {
    e.preventDefault();
    console.log(recaptchaInstance.current);
    if (loginData.email === '' || loginData.password === '') {
      showMessage('bg-theme-6', 'Debe completar todos los campos *', true);
    } else if (!captchaLogin) {
      showMessage(
        'bg-theme-6',
        'Demuestra que eres Humano valida el captcha *',
        true
      );
    } else {
      showMessage('', '', false);
      const email = loginData.email.trim();
      const password = loginData.password.trim();
      setSubmit(true);
      try {
        let { user } = await app
          .auth()
          .signInWithEmailAndPassword(email, password);
        if (user.emailVerified === false) {
          showMessage(
            'bg-theme-6',
            'Usuario no verificado, haga clic en el link de verificación que llegó a su correo electrónico.',
            true
          );
          /*user
            .sendEmailVerification()
            .then(function () {
              console.log('email send')
            })
            .catch(function (error) {
              console.log('no se envio el email')
            })*/
          await axiosInstance.post(`/user/sendLinkEmailVerify`, {
            uid: user.uid,
            email: user.email,
          });
        } else {
          document.body.classList.remove('login');
          props.history.replace('/inicio');
          let userPrestamo = prestamo.filter(
            (ptmo) => ptmo.usuario.email === loginData.email
          );
          let userPtamo = userPrestamo[0];
          // Enviar Correo
          const textoCorreoEnviar = `
            <p>El usuario: ${userPtamo.usuario.nombres} ${userPtamo.usuario.apellidos}, Ha iniciado sesion.</p>
            <p>Correo: ${userPtamo.usuario.email} </p>
            <p>WhatsApp: ${userPtamo.usuario.whatsapp}</p>
            <p>Pais: ${userPtamo.usuario.pais}</p>
            <p>Préstamo: ${userPtamo.estado}</p>
            <p>Gracias.</p>
          `;
          const emailUserVerify = {
            asunto: 'Usuario logueado sin préstamo Activo',
            email: administrativos.map((admin) => admin.email),
            motivo: `<span>${textoCorreoEnviar}</span>`,
          };

          enviarNotificacion(emailUserVerify);

          // Notificacion Finalizados
          let userPrestamoFinalizado = prestamoFinalizado.filter(
            (ptmo) => ptmo.usuario.email === userPtamo.usuario.email
          );
          let userPtmoFinalizado = userPrestamoFinalizado[0];
          const textoCorreoFinalizado = `
            <p>El usuario: ${userPtmoFinalizado.usuario.nombres} ${userPtmoFinalizado.usuario.apellidos}, Ha iniciado sesion.</p>
            <p>Correo: ${userPtmoFinalizado.usuario.email} </p>
            <p>WhatsApp: ${userPtmoFinalizado.usuario.whatsapp}</p>
            <p>Pais: ${userPtmoFinalizado.usuario.pais}</p>
            <p>Préstamo: ${userPtmoFinalizado.estado}</p>
            <p>Habilitado: ${userPtmoFinalizado.usuario.habilitadoNuevoPrestamo}</p>
            <p>Gracias.</p>
          `;
          const emailUserFinalizado = {
            asunto: 'Usuario logueado con prétamo Finalizado',
            email: administrativos.map((admin) => admin.email),
            motivo: `<span>${textoCorreoFinalizado}</span>`,
          };
          enviarNotificacion(emailUserFinalizado);

          dispatch(
            addLog({
              accion: `${'El Usuario: '} ${
                loginData.email
              } ${'A Iniciado Sesion.'} `,
              fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              usuario: loginData.email,
            })
          );
        }
        setSubmit(false);
      } catch (err) {
        console.log('error', err);
        let msg = '';
        switch (err.code) {
          case 'auth/wrong-password':
            msg = '*Contraseña incorrecta.';
            break;
          case 'auth/invalid-email':
            msg = '*Formato de email invalido.';
            break;
          case 'auth/user-disabled':
            msg = '*La cuenta de correo ingresada ha sido desactivada.';
            break;
          case 'auth/user-not-found':
            msg = '*Usuario no registrado';
            break;
          default:
            msg = '*Usuario y/o Contraseña invalida.';
            break;
        }
        showMessage('bg-theme-6', msg, true);
        setSubmit(false);
      }
    }
  };
  const showMessage = (color, message, view) => {
    setErrorLogin({ color, message, view });
  };

  //========== Funciones y Metodos Comunes ==========//
  const recaptchaLoaded = () => {
    // console.log('capcha loaded successfull');
  };

  //========== Funciones Verificar Correo ==========//
  const closeModalVerify = () => {
    dispatch(modalPolitica(false));
  };
  const cancelModalVerify = () => {
    dispatch(modalPolitica(false));
  };
  const modalVerifyEmail = (params) => {
    dispatch(modalPolitica(true));
  };
  const changeCorreoVerify = (e) => {
    const { name, value } = e.target;
    setCorreoVerify({ ...correoVerify, [name]: value });
  };
  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };

  const verificarCorreo = async () => {
    if (correoVerify.email === '') {
      custom('Debe llenar todos los campos obligatorios *', 'error');
    } else {
      await app
        .firestore()
        .collection('usuarios')
        .where('email', '==', correoVerify.email)
        .get()
        .then((data) => {
          console.log('DATA', data);
          if (data.empty === true) {
            custom(
              'Este correo no ha sido registrado en nuestro sistema, por favor verifique que este escrito correctamente.',
              'error'
            );
          } else {
            let User;
            let obj = {};
            data.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              User = obj.id;
            });
            app
              .firestore()
              .collection('emailVerify')
              .where('uid', '==', User)
              .get()
              .then((data) => {
                let linkVerify;
                let verify = {};
                data.forEach((doc) => {
                  verify = doc.data();
                  verify.id = doc.id;
                  linkVerify = verify.id;
                });

                // Enviar Correo
                const verificationLink =
                  'https://chamopresta.com/verificacion?mode=verifyEmail&Code=' +
                  linkVerify;
                const textoCorreoEnviar = `
                  <p>Hola, verifique su correo en el siguiente link.</p>
                  <a href="${verificationLink}">${verificationLink}</a>
                  <p>Si no solicitaste la verificación de esta dirección, ignora este correo electrónico.</p>
                  <p>Gracias.</p>
                `;
                const emailUserVerify = {
                  asunto: 'Verificación Link Registro - ChamoPresta.Com',
                  email: correoVerify.email,
                  motivo: `<span>${textoCorreoEnviar}</span>`,
                };
                enviarNotificacion(emailUserVerify);

                dispatch(modalPolitica(false));
                showMessage(
                  'bg-theme-9',
                  'Se ha reenviado un link de verificación a su correo electrónico *',
                  true
                );
                setTimeout(() => {
                  showMessage('', '', false);
                }, 10000);
              });
          }
        });
    }
  };

  return (
    <div>
      <div className='container sm:px-10'>
        <div className='block xl:grid grid-cols-2 gap-4'>
          <div className='xl:flex flex-col min-h-screen my-auto mx-auto w-2/5 sm:w-2/4 lg:w-2/5 xl:w-auto ocultar'>
            <a href='/'>
              <img
                alt='Midone Tailwind HTML Admin Template'
                className='-intro-x w-3\/4 -ml-20'
                src={Ilustracion}
              />
            </a>
            <div className='-intro-x text-white font-medium sm:text-4xl text-2xl leading-tight mt-10'>
              ¡Préstamos sin garantías!
              <br />
              Fácil y rápida aprobación…
            </div>
            <div className='-intro-x mt-6 sm:text-lg text-white'>
              Dirigido a venezolanos en el exterior.
            </div>
          </div>
          {/* Responsive */}
          <div className='lg:hidden ml-4'>
            <a href='/'>
              <img
                alt='Midone Tailwind HTML Admin Template'
                className='-intro-x w-3\/4 -ml-5'
                src={Ilustracion}
              />
            </a>
            <div className='text-white font-medium sm:text-4xl text-2xl leading-tight mt-1'>
              ¡Préstamos sin garantías!
              <br />
              Fácil y rápida aprobación…
            </div>
            <div className='mt-6 sm:text-lg text-white'>
              Dirigido a venezolanos en el exterior.
            </div>
          </div>
          {/* Responsive Fin */}

          <div className='h-screen flex py-5 custom-auth'>
            <div className='my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-y10 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto'>
              <h2 className='intro-x font-bold text-xl xl:text-xl text-center xl:text-left'>
                Inicia sesión
              </h2>
              <form onSubmit={login}>
                <div className='intro-x mt-2'>
                  <input
                    type='text'
                    name='email'
                    placeholder=' Corrreo electónico'
                    className='intro-x login__input input rounded-full border border-gray-300 block'
                    value={loginData.email}
                    onChange={onChangeLogin}
                  />
                  <input
                    type='password'
                    name='password'
                    placeholder=' Contraseña'
                    className='intro-x login__input input rounded-full border border-gray-300 block mt-4'
                    value={loginData.password}
                    onChange={onChangeLogin}
                  />
                </div>
                {errorLogin.view && (
                  <div className='intro-x mt-3'>
                    <div
                      className={[
                        'rounded-md',
                        'px-5',
                        'py-4',
                        'mb-2',
                        'text-white',
                        `${errorLogin.color}`,
                      ].join(' ')}
                    >
                      {errorLogin.message}
                    </div>
                  </div>
                )}
                <div className='intro-x mt-5 xl:mt-5 text-center xl:text-center'>
                  <div className='mt-3'>
                    <Recaptcha
                      ref={(e) => (recaptchaInstance = e)}
                      sitekey='6LfdezoaAAAAAMR-vF2KYhgIPNgCeo40ZKTshnIt'
                      render='explicit'
                      hl='es'
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyrecaptcha}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                </div>
                <div className='intro-x mt-3 xl:mt-3'>
                  <button className='button w-full xl:w-32 text-white bg-theme-1 xl:mr-3 mt-3'>
                    {!submit && (
                      <span className='indicator-label'>Entrar </span>
                    )}
                    {submit && (
                      <span className='indicator-label'>
                        Cargando... _
                        <span className='spinner-border spinner-border-sm  align-middle ml-1'></span>
                      </span>
                    )}
                  </button>
                </div>
                <div className='intro-x mt-12 xl:mt-6 text-gray-700'>
                  <span className='text-gray-700 font-semibold mt-2 mr-2'>
                    Si no tienes cuenta
                  </span>
                  <Link to='/register'>
                    <span className='font-semibold text-theme-1 xl:mt-0 xs:w-full'>
                      Registrate aquí
                    </span>
                  </Link>
                </div>
                <div className='intro-x mt-4 xl:mt-3 text-gray-700'>
                  <span className='text-gray-700 font-semibold mr-2'>
                    Sí te llego el correo de verificación en blanco
                  </span>
                  <a
                    className='font-semibold text-theme-1 xl:mt-0 xs:w-full'
                    href='#!'
                    onClick={(e) => modalVerifyEmail()}
                  >
                    Haz clic aquí
                  </a>
                </div>
                <div className='intro-x mt-3 xl:mt-3 text-gray-700'>
                  <span className='text-gray-700 font-semibold'>¿Dudas?</span>
                  <a
                    className='text-theme-1 font-medium mx-1'
                    href='https://www.youtube.com/watch?v=OBfSUvfcZ-o'
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target='_blank'
                  >
                    Haz clic aquí
                  </a>
                  para ver nuestro <b>videotutorial</b>
                  <br />
                  de cómo solicitar un préstamo.
                </div>
                <div className='intro-x text-center xl:text-center text-gray-700 text-xs sm:text-sm mt-5'>
                  {/* <div className="flex items-center mr-auto">
                    <input
                      type="checkbox"
                      className="input border mr-2"
                      id="remember-me"
                    />
                    <label
                      className="cursor-pointer select-none"
                      htmlFor="remember-me"
                    >
                      Recuérdame
                    </label>
                  </div> */}
                  <Link to='/forgotpassword'>Olvidaste tu Contraseña?</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*===== Modal Verificar correo =====*/}
      {modalVerifyCorreo && (
        <Modal
          title='Verificar correo electrónico'
          close={closeModalVerify}
          cancel={cancelModalVerify}
          save={verificarCorreo}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          <div className='intro-y col-span-12'>
            <p className='font-semibold mb-2'>
              Indique el correo electrónico al que le llego el mensaje en
              blanco.
            </p>
            <hr />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Correo :</label>
            <input
              type='email'
              name='email'
              placeholder='Correo electrónico'
              className='intro-x login__input input rounded-full border border-gray-300 block mt-4'
              onChange={changeCorreoVerify}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Login;
