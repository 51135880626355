import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Icon from 'react-feather';
import feather from 'feather-icons';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import {
  getUsers,
  getUsersHabilitado,
} from '../../../redux/actions/superAdminActions';
import { Link } from 'react-router-dom';

const UsuariosPrestamo = () => {
  const dispatch = useDispatch();

  //========== USE-STATE ==========//
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  //========== USE-SELECTORS ==========//
  const usuarios = useSelector((state) => state.superadmin.allUsers);
  const usuariosHabilitado = useSelector(
    (state) => state.superadmin.allUsersHabilitado
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getUsers());
    dispatch(getUsersHabilitado());
    // dispatch(getListPrestamos());
    // getListUserLog();
    // getListEmailVerify();
  }, []);

  //===== Contenido Tabla Prestamos =====//
  const columns = [
    {
      field: 'nombre',
      headerName: 'NOMBRES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 225,
      valueGetter: (params) => {
        return `${params.row.nombres} ${params.row.apellidos}`;
      },
    },
    {
      field: 'email',
      headerName: 'CORREO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 255,
    },
    {
      field: 'pais',
      headerName: 'PAIS',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 122,
    },
    {
      field: 'whatsapp',
      headerName: 'TELEFONO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 155,
    },
    {
      field: 'habilitado',
      headerName: 'HABILITADO ',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 140,
      // valueGetter: (params) => {
      //   return `${params.row.habilitadoNuevoPrestamo === 'Si' ? 'Si' : 'No'}`;
      // },
      renderCell: (params) => (
        <span className='m-auto'>
          {params.row.habilitadoNuevoPrestamo === 'Si' ? 'Si' : 'No'}
        </span>
      ),
    },
    {
      field: 'usuarios',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 160,
      renderCell: (params) => (
        <div className='flex justify-center font-medium items-center w-full'>
          <Link
            className='flex items-center mr-3 text-theme-1'
            to={{
              pathname: '/prestamorapido/' + params.row.id,
            }}
          >
            <Icon.Settings className='w-4 h-4 mr-1' />
            Gestionar
          </Link>
        </div>
      ),
    },
  ];

  //===== Funciones y Metodos de la Tabla de Mis Prestamos =====//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    setPageCount(pagination.pageCount);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.page);
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>Usuarios Habilitados</h2>
        </div>
        <div className='col-span-12 box bg-theme-2 p-1'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 620, width: '100%' }}
              // className={classes.root}
            >
              <DataGrid
                rows={usuariosHabilitado}
                columns={columns}
                pageSize={100}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
          </div>
        </div>
        <div className='flex justify-between content-center'>
          <div className='font-bold mt-3'>
            {pageCount >= currentPage &&
              `Mostrando ${currentPage} - ${
                pageSize >= usuariosHabilitado.length
                  ? usuariosHabilitado.length
                  : pageSize
              } de ${usuariosHabilitado.length} Usuarios`}
          </div>
          <div className='bg-theme-23 box p-2 text-center font-semibold mt-3'>
            <label>
              Usuarios Habilitados :
              <span className='rounded-lg bg-theme-3 text-white py-1 px-3 ml-1'>
                {usuariosHabilitado.length}
              </span>
            </label>
          </div>
        </div>
        {/* <div className='flex mt-5'> */}
        {/* Boton Exportar*/}
        <div className='cursor-pointer'>
          <button
            className='flex box text-white bg-theme-3 p-2'
            // onClick={exportar}
          >
            {/* <Icon.Upload className='w-4 h-4 mr-1' /> */}
            <p>Exportar Usuarios</p>
          </button>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default UsuariosPrestamo;
