import React from "react";
import chat from "../../../statics/images/chat-online.png";

const InicioChat = () => {
  return (
    <>
      <div
        className="bg-theme-2 box text-center p-10"
        style={{ height: "calc(100vh - 130px )" }}
      >
        <img
          alt="Chat"
          className="-intro-x m-auto rounded-full mt-16"
          src={chat}
        />
        <h2 className="text-2xl text-gray-600 font-extrabold my-8">
          Inicie el Chat
          <br />
          Selecccionando un Usuario
        </h2>
      </div>
    </>
  );
};

export default InicioChat;
