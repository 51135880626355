export default {
  metodosPago: [
    { codigo: '001', metodo: 'Venezuela-Transferencia: Cuenta Bancaria BDV' },
    { codigo: '002', metodo: 'Venezuela-Pago móvil' },
    { codigo: '004', metodo: 'Panamá-Transferencia: Banesco Panamá' },
    { codigo: '005', metodo: 'Ecuador-Transferencia: Banco Pichincha' },
    { codigo: '006', metodo: 'Ecuador-Transferencia: Produbanco' },
    { codigo: '006', metodo: 'Ecuador-Transferencia: Banco Guayaquil' },
    { codigo: '007', metodo: 'Colombia-Transferencia: BanColombia' },
    // { codigo: "007", metodo: "Criptomonedas - Usdt red TCR" },
    // { codigo: "008", metodo: "Criptomonedas - Bitcoin" },
    // { codigo: "009", metodo: "Binance" },
    { codigo: '009', metodo: '' },
  ],
};
