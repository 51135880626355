import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../../config/api';
import url from '../../../config/url';
import feather from 'feather-icons';
import * as Icon from 'react-feather';
import moment from 'moment';

import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';
import { success } from '../../../helpers/alertas';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Tooltip from '@material-ui/core/Tooltip';

import { getListPrestamosPorCobrar } from '../../../redux/actions/gestorActions';

import { addLog } from '../../../redux/actions/sesionAction';

export const Cobranza = () => {
  const dispatch = useDispatch();
  const [filterBy, setFilterBy] = useState('Todos');

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const listPrestamosAprobados = useSelector(
    (state) => state.gestor.listPrestamosAprobados
  );
  const filterprestamos = useSelector((state) => state.gestor.filterprestamos);
  const abonosRealizados = useSelector(
    (state) => state.gestor.abonosRealizados
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getListPrestamosPorCobrar());
  }, []);

  const colorBadge = (fechaCompromisoPago) => {
    let NowMoment = moment(new Date()).format('YYYY-MM-DD');
    let fecha1 = moment(NowMoment);
    let fecha2 = moment(fechaCompromisoPago);
    let diff = fecha2.diff(fecha1, 'days');

    switch (fechaCompromisoPago !== diff) {
      case diff <= 0:
        return 'badge bg-theme-6';
      case diff === 1:
        return 'badge bg-theme-36';
      case diff === 2:
        return 'badge  bg-theme-12';
      case diff >= 3:
        return 'badge  bg-theme-9';
      default:
        return 'badge bg-theme-1';
    }
  };

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'usuario',
      headerName: 'USUARIO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 180,
      valueGetter: (params) => {
        return `${params.row.usuario.nombres} ${params.row.usuario.apellidos}`;
      },
    },
    {
      field: 'montoCompromisoPago',
      headerName: 'MONTO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 105,
      renderCell: (params) => (
        <span className='m-auto'>{params.row.montoCompromisoPago}</span>
      ),
    },
    {
      field: 'fechaInicio',
      headerName: 'FECHA DE INICIO',
      description: 'Fecha Inicio del Prestamo ó Inicio de Deuda Restante',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 165,
      renderCell: (params) => {
        if (params.row.fechaInicioDespues) {
          return (
            <Tooltip
              title={
                'Fecha de inicio original: ' + params.row.fechaInicio.toString()
              }
              placement='top-start'
            >
              <span className='flex items-center'>
                {params.row.fechaInicioDespues}
                <Icon.Calendar
                  className='text-theme-10 tooltip ml-2 w-4 h-4'
                  data-theme='light'
                />
              </span>
            </Tooltip>
          );
        } else {
          return <span>{params.row.fechaInicio}</span>;
        }
      },
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'FECHA DE PAGO',
      description:
        'Fecha Compromiso del Pago Prestamo ó Compromiso de Pago Deuda Restante',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 160,
      renderCell: (params) => {
        if (params.row.fechaInicioDespues) {
          return (
            <Tooltip
              title={
                'Fecha de Compromiso Pago original: ' +
                params.row.fechaCompromisoPago.toString()
              }
              placement='top-start'
            >
              <span className='flex items-center'>
                {params.row.fechaCompromisoPagoDespues}
                <Icon.Calendar
                  className='text-theme-10 tooltip ml-2 w-4 h-4'
                  data-theme='light'
                />
              </span>
            </Tooltip>
          );
        } else {
          return <span>{params.row.fechaCompromisoPago}</span>;
        }
      },
    },
    {
      field: 'estado',
      headerName: 'VENCE',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 125,
      cellClassName: 'unset-line',
      renderCell: (params) => (
        <div className={colorBadge(params.row.fechaCompromisoPago)}>
          <Icon.Calendar className='w-4 h-4 mr-1' />
          {params.row.fechaCompromisoPago}
        </div>
      ),
    },
    {
      field: 'gestor',
      headerName: 'GESTOR',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 178,
      valueGetter: (params) => {
        if (params.row.gestor) {
          return `${params.row.gestor.nombres} ${params.row.gestor.apellidos}`;
        }
        return ``;
      },
    },
    {
      field: 'aprobado',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 160,
      renderCell: (params) => (
        <div className='flex font-medium justify-center items-center w-full'>
          <Link
            className='flex items-center mr-3 text-theme-1'
            to={{
              pathname: '/cobranzas/' + params.row.id,
              state: { prestamo: params.row },
            }}
          >
            <Icon.Settings className='w-4 h-4 mr-1' />
            Gestionar
            <Icon.FileText className='w-3 h-3 ml-2' />
          </Link>
        </div>
      ),
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();

  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  //========== Funciones y Metodos Seleccion de Prestamos ==========//
  const seleccionarPrestamos = (e) => {
    let seleccionVencimiento = e.target.value;
    setFilterBy(seleccionVencimiento);
    let PorVencer = [];
    let Vencidos = [];
    if (seleccionVencimiento === 'Vencidos') {
      let date = moment(new Date()).format('YYYY-MM-DD');
      listPrestamosAprobados.forEach((element) => {
        let dia = element.fechaCompromisoPago;
        if (date > dia && element.pagado !== true) {
          Vencidos.push(element);
        }
      });
      dispatch({ type: 'GET_FILTERPRESTAMOS', payload: Vencidos });
    } else if (seleccionVencimiento === 'PorVencer') {
      let date = moment(new Date()).format('YYYY-MM-DD');
      listPrestamosAprobados.forEach((element) => {
        let dia = element.fechaCompromisoPago;
        if (date <= dia && element.pagado !== true) {
          PorVencer.push(element);
        }
      });
      dispatch({ type: 'GET_FILTERPRESTAMOS', payload: PorVencer });
    } else {
      dispatch({
        type: 'GET_FILTERPRESTAMOS',
        payload: listPrestamosAprobados,
      });
    }
  };

  const enviarNotificacionCobro = async () => {
    try {
      let response = await axiosInstance.post(`/notifications/cobranza`, {
        prestamos: filterprestamos,
        tipo: filterBy,
      });
      success('Cobranzas enviadas exitosamente.', 'success');
    } catch (err) {
      success(err, 'error');
    }
    dispatch(
      addLog({
        accion: `${'Se Ha Enviado Nueva Notificacíon De Cobro a:'} ${
          myUser.nombres
        } ${myUser.apellidos}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };

  const calcularInteresesPorAbonos = () => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    abonosRealizados.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(filterprestamos.fechaInicio, item.fecha);
        interes = calculateInteres(
          filterprestamos.montoPrestado,
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? filterprestamos.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);

        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? filterprestamos.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );

        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? filterprestamos.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? filterprestamos.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>
            Listado de Préstamos Por Cobrar
          </h2>
        </div>
        <hr />

        <div className='grid grid-cols-12 gap-3'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 450, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={filterprestamos}
                columns={columns}
                pageSize={50}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
            <div className='flex border-b bg-theme-23 box mt-3 mb-2'>
              <h5 className='font-medium ml-12 p-2'>
                Seleccionar Prestamos Para Notificacion de Cobro
              </h5>
            </div>
            <hr />

            <div className='flex intro-y bg-theme-23 box col-span-12 sm:col-span-6 mt-2 p-1'>
              <label className='font-medium ml-5 w-64 items-center mt-1 p-1'>
                Estado de Vencimiento :
              </label>
              <select
                type='text'
                name='tipo'
                className='form-select rounded-full w-48 border input p-1 ml-3 items-center'
                aria-label='Default select example'
                value={filterBy}
                onChange={seleccionarPrestamos}
              >
                <option value='Todos'> Todos</option>
                <option value='PorVencer'> Por Vencer</option>
                <option value='Vencidos'> Vencidos</option>
              </select>
              <button
                className='bg-theme-3 text-white box w-full text-center ml-12 mr-5'
                onClick={enviarNotificacionCobro}
              >
                Enviar Notificacion de Cobro
              </button>
            </div>
            <div className='font-medium text-right mr-5 bg-theme-27 box p-2 mt-5'>
              <label>Dias Para Vencimiento: </label>
              <span className='mx-2 bg-theme-9 box p-1'>3 ó + Días</span>
              <span className='mx-2 bg-theme-12 box p-1'>2 Días</span>
              <span className='mx-2 bg-theme-36 box p-1'>1 Día</span>
              <span className='mx-2 bg-theme-6 box p-1'>Vencido</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
