import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import feather from 'feather-icons';

const Testimonios = () => {
  const [tarjetaActual, setTarjetaActual] = useState(0);
  const [tarjetaActual1, setTarjetaActual1] = useState(2);
  const [siguiente, setSiguiente] = useState(null);

  const [texts, setTexts] = useState([
    {
      id: 1,
      text: 'Necesitaba enviarle un dinero urgente a mi mamá, ingrese en esta página a ver si era verdad y me prestaron, llegó un pago móvil a la cuenta que di, agradecido.',
      autor: 'Daniel Pérez',
      city: 'Quito',
      country: 'Ecuador',
      img: '/testimonio-1.jpg',
    },
    {
      id: 2,
      text: 'No sabía que estaba disponible este servicio, me acaban de ayudar en un momento que lo necesitaba, prometo devolverles el préstamo con sus intereses a tiempo.',
      autor: 'Mafer Rodriguez',
      city: 'Guayaquil',
      country: 'Ecuador',
      img: '/testimonio-2.jpg',
    },
    {
      id: 3,
      text: 'Tengo una hija en venezuela y necesitaba mandarle para un antibiótico pero no me han pagado por eso pedí un préstamo, son bastantes datos pero a la final valió la pena.',
      autor: 'Miguel Zamora',
      city: 'Machala',
      country: 'Ecuador',
      img: '/testimonio-3.jpg',
    },
  ]);

  const cantidadTarjetas = texts.length;
  const cantidadTarjetas1 = texts.length;

  useEffect(() => {
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 });
    })($);
  }, []);

  const previous = () => {
    setSiguiente(false);
    const current =
      tarjetaActual === 0 ? cantidadTarjetas - 1 : tarjetaActual - 1;
    const current1 =
      tarjetaActual1 === 0 ? cantidadTarjetas1 - 1 : tarjetaActual1 - 1;
    setTarjetaActual(current);
    setTarjetaActual1(current1);
  };

  const next = () => {
    setSiguiente(true);
    const current =
      tarjetaActual === cantidadTarjetas - 1 ? 0 : tarjetaActual + 1;
    const current1 =
      tarjetaActual1 === cantidadTarjetas1 - 1 ? 0 : tarjetaActual1 + 1;
    setTarjetaActual(current);
    setTarjetaActual1(current1);
  };

  return (
    <>
      <div
        className="container-custom custom-testimonio mt-24"
        id="testimonio"
      >
        <div className="texto grid grid-cols-12">
          <div className="col-span-12 sm:col-span-6">
            <h5 className="font-montserrat uppercase text-gray-650 font-bold text-lg tracking-widest2 mt-2">
              TESTIMONIOS
            </h5>
            <h3 className="text-gray-750 font-montserrat font-extrabold text-5xl tracking-tighter mb-12">
              Descubre lo que dicen sobre nosotros
            </h3>
          </div>
          <div className="col-span-12 sm:col-span-6">
            <div className="grid grid-cols-12">
              <div className="relative col-span-8 sm:col-span-9">
                {texts.map((tarjeta, index) => (
                  <div key={index}>
                    <div
                      className={
                        tarjetaActual === index
                          ? `${'slide'} ${'active'}`
                          : 'slide'
                      }
                    >
                      {tarjetaActual === index && (
                        <div>
                          <img
                            className="rounded-full"
                            width="68"
                            height="68"
                            src={tarjeta.img}
                            alt="imagen"
                          />
                          <div
                            id={'card' + tarjetaActual}
                            style={{ color: '#5E7382' }}
                            className={
                              siguiente
                                ? `absolute card custom-box w-full p-4 ml-6 -intro-y`
                                : 'absolute card custom-box w-full p-4 ml-6 intro-y'
                            }
                          >
                            <p
                              className="font-montserrat mb-6"
                              style={{ fontSize: 16 }}
                            >
                              {tarjeta.text}
                            </p>
                            <span className="font-semibold text-lg not-italic">
                              {tarjeta.autor}
                            </span>
                            <br />
                            <span className="font-medium text-sm not-italic">
                              {tarjeta.city}, {tarjeta.country}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      {texts.map((card, index) => (
                        <div key={index}>
                          {tarjetaActual1 === index && (
                            <div>
                              <div
                                className="absolute top-0 ml-16 mt-40 z-index card w-full p-4 "
                                style={{
                                  color: '#5E7382',
                                }}
                              >
                                <p
                                  className="font-montserrat"
                                  style={{ fontSize: 16 }}
                                >
                                  {card.text}
                                </p>
                                <span className="text-gray-650 text-lg">
                                  {' '}
                                  {card.autor}
                                </span>
                                <br />
                                <span className="font-medium text-sm not-italic">
                                  {card.city}, {card.country}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-span-1 sm:col-span-1"></div>
              <div className="col-span-3 sm:col-span-2 mt-40 ml-8">
                <div
                  className="flex flex-col justify-between items-center"
                  style={{ height: 72 }}
                >
                  <button
                    id="flechaUp"
                    className="btn btn-outline-secondary w-auto "
                    onClick={previous}
                  >
                    <i data-feather="chevron-up"></i>
                  </button>

                  <button
                    id="flechaDown"
                    className="btn btn-outline-secondary w-auto"
                    onClick={next}
                  >
                    <i data-feather="chevron-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonios;
