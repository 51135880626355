import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { app } from '../../../config/firebaseConfig';
import feather from 'feather-icons';
import * as Icon from 'react-feather';
import Modal from '../../Modal';
import moment from 'moment';

import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';

import { getPrestamos } from '../../../redux/actions/userAction';
//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Tooltip from '@material-ui/core/Tooltip';
import { axiosInstance } from '../../../config/api';
import Slider from 'react-slick';

const CuentasPorCobrar = () => {
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const abonosRealizados = useSelector(
    (state) => state.superadmin.abonosRealizados
  );
  const configuracionGlobal = useSelector(
    (state) => state.user.configuracionGlobal
  );

  //========== USE-STATE ==========//
  const [listPorCobrar, setListPorCobrar] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [misPrestamos, setMisPrestamos] = useState([]);
  const [detallesUsuario, setDetallesUsuario] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    email: '',
    fechaNacimiemnto: '',
    fechaRegistro: '',
    geo: '',
    habilitadoNuevoPrestamo: '',
    idUsuario: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: '',
    nombreEmpresa: '',
    nombres: '',
    nuevaTasaInteres: '',
    nuevoLimitePrestamo: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    rol: '',
    sexo: '',
    telefonoOpcional: '',
    telegram: '',
    trabaja: '',
    whatsapp: '',
  });
  const [tasaDolarHoy, setTasaDolarHoy] = useState(1);
  const [days, setDays] = useState([]);
  const [cantidad, setCantidad] = useState(0);

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    getDolarValue();
    ListPrestamosPorCobrar();
    dispatch(getPrestamos());
  }, []);

  const colorBadge = (fechaCompromisoPago) => {
    let NowMoment = moment(new Date()).format('YYYY-MM-DD');
    let fecha1 = moment(NowMoment);
    let fecha2 = moment(fechaCompromisoPago);
    let diff = fecha2.diff(fecha1, 'days');
    switch (fechaCompromisoPago !== diff) {
      case diff <= 0:
        return 'badge bg-theme-6';
      case diff === 1:
        return 'badge bg-theme-36';
      case diff === 2:
        return 'badge  bg-theme-12';
      case diff >= 3:
        return 'badge  bg-theme-9';
      default:
        return 'badge bg-theme-1';
    }
  };
  const montoHoy = (prestamoSelect) => {
    if (prestamoSelect) {
      let dias = calCulardiasTranscurridos(prestamoSelect.fechaInicio);
      let monto = calculateInteres(
        prestamoSelect.montoPrestado,
        dias,
        parseFloat(prestamoSelect.tasaInteresCompuesto)
      );
      return parseFloat(monto);
    } else {
      return 0;
    }
  };
  const getDolarValue = async () => {
    try {
      let response = await axiosInstance.get(`/monitor`);
      const { data } = response;
      let dataBien = data.resp.replaceAll('.', '');
      dataBien = dataBien.replace(',', '.');
      setTasaDolarHoy(parseFloat(dataBien));
    } catch (err) {
      console.log(err, 'error');
    }
  };

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'usuario',
      headerName: 'USUARIO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 220,
      valueGetter: (params) => {
        return `${params.row.usuario.nombres} ${params.row.usuario.apellidos}`;
      },
    },
    {
      field: 'montoCompromisoPago',
      headerName: 'DEUDA ACTUAL',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 160,
      valueGetter: (params) => {
        if (params.row.pagoEnviado === true) {
          return params.row.abonos.length > 0
            ? calcularInteresesPorAbonos(params.row.abonos, params.row)[
                'interesHoy'
              ]
            : montoHoy(params.row);
        }
      },
    },
    {
      field: 'fechaInicio',
      headerName: 'FECHA DE INICIO',
      description: 'Fecha Inicio del Prestamo ó Inicio de Deuda Restante',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 170,
      renderCell: (params) => {
        if (params.row.fechaInicioDespues) {
          return (
            <Tooltip
              title={
                'Fecha de inicio original: ' + params.row.fechaInicio.toString()
              }
              placement='top-start'
            >
              <span className='flex items-center'>
                {params.row.fechaInicioDespues}
                <Icon.Calendar
                  className='text-theme-10 tooltip ml-2 w-4 h-4'
                  data-theme='light'
                />
              </span>
            </Tooltip>
          );
        } else {
          return <span>{params.row.fechaInicio}</span>;
        }
      },
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'FECHA DE PAGO',
      description:
        'Fecha Compromiso del Pago Prestamo ó Compromiso de Pago Deuda Restante',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 170,
      renderCell: (params) => {
        if (params.row.fechaInicioDespues) {
          return (
            <Tooltip
              title={
                'Fecha de Compromiso Pago original: ' +
                params.row.fechaCompromisoPago.toString()
              }
              placement='top-start'
            >
              <span className='flex items-center'>
                {params.row.fechaCompromisoPagoDespues}
                <Icon.Calendar
                  className='text-theme-10 tooltip ml-2 w-4 h-4'
                  data-theme='light'
                />
              </span>
            </Tooltip>
          );
        } else {
          return <span>{params.row.fechaCompromisoPago}</span>;
        }
      },
    },
    {
      field: 'estado',
      headerName: 'VENCE',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 157,
      cellClassName: 'unset-line',
      renderCell: (params) => (
        <div className={colorBadge(params.row.fechaCompromisoPago)}>
          <Icon.Calendar className='w-4 h-4 mr-1' />
          {params.row.fechaCompromisoPago}
        </div>
      ),
    },
    {
      field: 'acciones',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 195,
      renderCell: (params) => (
        <div className='flex justify-center items-center'>
          <a
            className='flex items-center mr-3 text-theme-12'
            href='javascript:;'
            onClick={() => VerDatosUsuarios(params.row.usuario)}
          >
            <Icon.Search className='w-5 h-5 mr-2 ml-5' />
            Ver Datos
            <Icon.FileText className='w-3 h-3 ml-2' />
          </a>
        </div>
      ),
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }
  const calcularInteresesPorAbonos = (abonosRecibido, prestamoSelect) => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    let arrayRecorrer = [];
    arrayRecorrer = abonosRecibido;
    arrayRecorrer.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamoSelect.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamoSelect.montoPrestado,
          dif,
          parseFloat(prestamoSelect.tasaInteresCompuesto)
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(prestamoSelect.tasaInteresCompuesto)
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(prestamoSelect.tasaInteresCompuesto)
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(prestamoSelect.tasaInteresCompuesto)
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };
  const ListPrestamosPorCobrar = () => {
    let arrayFinal = [];
    app
      .firestore()
      .collection('prestamos')
      .where('estado', '==', 'prestado')
      .where('pagado', '==', false)
      .orderBy('fechaInicio', 'desc')
      .get()
      .then(async (data) => {
        let array = [];
        let ptamo = {};
        data.forEach((doc) => {
          ptamo = doc.data();
          ptamo.id = doc.id;
          array.push(ptamo);
        }); //hasta aqui obtengo los prestamos
        //recorro los prestamos y concateno los abonos a cada prestamo
        let abono = {};
        for (let i = 0; i < array.length; i++) {
          let arrayAbonos = [];
          // recorro cada prestamo
          //busco los abonos de cada prestamo iterable
          const result = await app
            .firestore()
            .collection('abonos')
            .where('idPrestamo', '==', `${array[i].id}`)
            .orderBy('fecha', 'asc')
            .get();
          result.forEach((abo) => {
            abono = abo.data();
            abono.id = abo.id;
            arrayAbonos.push(abono);
          });
          const arrayConcat = { ...array[i], abonos: arrayAbonos };
          arrayFinal.push(arrayConcat);
        }
        setListPorCobrar(arrayFinal);
      });
  };

  //========== Funciones y Metodos Modal Prestamos ==========//
  const VerDatosUsuarios = (detallesUsuario) => {
    setDetallesUsuario({
      apellidos: detallesUsuario.apellidos,
      areaTrabajo: detallesUsuario.areaTrabajo,
      email: detallesUsuario.email,
      idUsuario: detallesUsuario.idUsuario,
      ingresoMensualUSD: detallesUsuario.ingresoMensualUSD,
      localidad: detallesUsuario.localidad,
      nombres: detallesUsuario.nombres,
      numeroCedula: detallesUsuario.numeroCedula,
      pais: detallesUsuario.pais,
      telefonoOpcional: detallesUsuario.telefonoOpcional,
      telegram: detallesUsuario.telegram,
      trabaja: detallesUsuario.trabaja,
      whatsapp: detallesUsuario.whatsapp,
    });
    let results = listPorCobrar.filter(
      (item) => item.usuario.idUsuario === detallesUsuario.id
    );
    setMisPrestamos(results[0]);
    let selecionado = results[0];
    const diferencia = restaFechas(
      selecionado.fechaInicio,
      selecionado.fechaCompromisoPago
    );
    armarDays(diferencia);
    setCantidad(selecionado.montoPrestado);
    setShowModal(true);
  };
  const close = () => {
    setShowModal(false);
  };
  const cancel = () => {
    setShowModal(false);
  };
  const totalXCobrar = () => {
    let cobrar = 0;
    listPorCobrar.forEach((element) => {
      if (element.pagoEnviado === true) {
        if (element.abonos.length > 0) {
          cobrar =
            cobrar +
            calcularInteresesPorAbonos(element.abonos, element)['interesHoy'];
        } else {
          cobrar = cobrar + montoHoy(element);
        }
      }
    });
    return cobrar;
  };
  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const calculateInteresByDia = (dia) => {
    let totalApagar = (
      cantidad *
      Math.pow(
        1 + parseFloat(configuracionGlobal.tasaInteresCompuesto) / 100,
        dia
      )
    ).toFixed(2);
    return totalApagar;
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    setDays(array.reverse());
  };

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>Cuentas Por Cobrar</h2>
        </div>
        <hr />
        <div className='grid grid-cols-12 gap-3'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 590, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={listPorCobrar}
                columns={columns}
                pageSize={50}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
          </div>
        </div>
        <div className='font-medium text-left mr-5 bg-theme-23 box p-2 mt-2 ml-3'>
          <label>TOTALES POR COBRAR : </label>
          <span className='mx-2 bg-theme-25 box p-1'>
            {totalXCobrar().toFixed(2)}, $$
          </span>
        </div>
        <div className='font-medium text-right mr-5 bg-theme-27 box p-2 mt-5'>
          <label>Dias Para Vencimiento: </label>
          <span className='mx-2 bg-theme-9 box p-1'>3 ó + Días</span>
          <span className='mx-2 bg-theme-12 box p-1'>2 Días</span>
          <span className='mx-2 bg-theme-36 box p-1'>1 Día</span>
          <span className='mx-2 bg-theme-6 box p-1'>Vencido</span>
        </div>
      </div>

      {/* Contenido Modal */}
      {showModal && (
        <Modal
          title='Datos Usuario'
          close={close}
          cancel={cancel}
          customBtn={true}
          textcustomBtn='Aceptar'
          save={() => {
            setShowModal(false);
          }}
          size='xl'
        >
          <div className='grip col-span-12 sm:col-span-12 mb-1'>
            <h3 className='box text-lg font-medium text-center bg-theme-23 mb-1'>
              Datos del Usuario
            </h3>
            <hr />
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Nombres :
            </label>
            <span>{detallesUsuario.nombres}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Apellidos :
            </label>
            <span>{detallesUsuario.apellidos}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Correo :
            </label>
            <span>{detallesUsuario.email}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Cédula :
            </label>
            <span>{detallesUsuario.numeroCedula}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              País :
            </label>
            <span>{detallesUsuario.pais}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Localidad :
            </label>
            <span>{detallesUsuario.localidad}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Whatsapp activo :
            </label>
            <span>{detallesUsuario.whatsapp}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Otro número de contacto :
            </label>
            <span>{detallesUsuario.telefonoOpcional}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Usuario telegram :
            </label>
            <span>{detallesUsuario.telegram}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Trabaja :
            </label>
            <span>{detallesUsuario.trabaja}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Area de trabajo :
            </label>
            <span>{detallesUsuario.areaTrabajo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Ingreso mensual :
            </label>
            <span>{detallesUsuario.ingresoMensualUSD}</span>
            <span className='input-group-text ml-1'>(USD)</span>
          </div>

          {/* Prestamo */}
          <>
            <div className='grip col-span-12 sm:col-span-12 mb-1'>
              <h3 className='box text-lg font-medium text-center bg-theme-23 mb-1'>
                Datos del prestamo
              </h3>
              <hr />
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Estado:
              </label>
              <span>{misPrestamos.estado}</span>
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Pagado:
              </label>
              <span>{misPrestamos.pagado === true ? 'Si' : 'No'}</span>
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Monto solicitado:
              </label>
              <span>{misPrestamos.montoPrestado} USD</span>
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Monto a pagar:
              </label>
              <span>{misPrestamos.montoCompromisoPago} USD</span>
            </div>
            {calCulardiasTranscurridos(misPrestamos.fechaInicio) > 0 && (
              <div className='col-span-12 sm:col-span-4 mb-1'>
                <label className='font-medium p-1 bg-theme-26 box mr-1'>
                  Monto deuda restante :
                </label>
                {abonosRealizados.length > 0 ? (
                  <span>{calcularInteresesPorAbonos()['interesHoy']}</span>
                ) : (
                  <span>{montoHoy()} USD</span>
                )}
              </div>
            )}
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Solicitado el:
              </label>
              <span>{misPrestamos.fechaSolicitud}</span>
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Fecha de pago:
              </label>
              <span>{misPrestamos.fechaCompromisoPago}</span>
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Tasa dolar:
              </label>
              <span>{tasaDolarHoy}</span>
            </div>
            <div className='col-span-12 sm:col-span-4 mb-1'>
              <label className='font-medium p-1 bg-theme-26 box mr-1'>
                Total en Bf:
              </label>
              <span>
                {(misPrestamos.montoPrestado * tasaDolarHoy).toFixed(2)}
              </span>
            </div>

            {days.length > 0 && (
              <div
                className='col-span-12 sm:col-span-12 flex justify-center items-center my-2 overflow-auto lg:overflow-visible'
                style={{ flexDirection: 'column' }}
              >
                <h2 className='font-medium bg-theme-2 box p-1 mb-2'>
                  Distribución de los interéses por día
                </h2>
                <div className='carousel'>
                  <Slider {...settings} className='w-full'>
                    {days.map((day, i) => {
                      return (
                        <div
                          className='box px-2 py-2 mb-3 flex items-center zoom-in bg-theme-26'
                          key={i}
                        >
                          <div>
                            <div className='font-medium'>
                              {`${formatFecha(
                                misPrestamos?.fechaInicio,
                                day
                              )}:`}
                            </div>
                            <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                              {`${calculateInteresByDia(day)}$`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <button
                  className='button w-full xl:w-32 text-white bg-theme-1 xl:mr-3 p-2 mt-3 flex items-center justify-center'
                  // onClick={exportar}
                >
                  <Icon.FileText className='text-white mr-2 ml-1 w-4' />
                  Exportar
                </button>
              </div>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default CuentasPorCobrar;
