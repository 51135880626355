import {
  GET_USERS,
  GET_HABILITADO,
  GET_INGRESOS,
  GET_EGRESOS,
  MODAL_INGRESOSCONTABLES,
  MODAL_EGRESOSCONTABLES,
  MODAL_EDITARMOVIMIENTOSCONTABLES,
  GET_LISTPRESTAMOSAPROBADOS,
  GET_LISTPRESTAMOS,
  MODAL_PAGOS,
  GET_PAGOSREALIZADOS,
  GET_ABONOSREALIZADOS,
  GET_PRESTAMO,
  GET_USUARIO,
  GET_NOTIFICACIONES,
  MODAL_NOTIFICACIONES,
  MODAL_EDITARNOTIFICACIONES,
  GETCHAT,
  GET_RESGISTROACTIVIDAD,
  GET_LISTSINFONDOS,
} from '../types';

const initialState = {
  allUsers: [],
  allUsersHabilitado: [],
  ingresosContables: [],
  egresosContables: [],
  modalIngresosContables: false,
  modalEgresosContables: false,
  modalEditarMovimientosContables: false,
  modalPagos: false,
  listPrestamosAprobados: [],
  listadoPrestamos: [],
  pagosRealizados: [],
  abonosRealizados: [],
  prestamo: {},
  usuario: {},
  listadoNotificaciones: [],
  modalNotificaciones: false,
  modalEditarNotificaciones: false,
  chat: {},
  registroActividad: [],
  listUsuariosSinFondos: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_HABILITADO:
      return {
        ...state,
        allUsersHabilitado: action.payload,
      };
    case GET_INGRESOS:
      return {
        ...state,
        ingresosContables: action.payload,
      };
    case GET_EGRESOS:
      return {
        ...state,
        egresosContables: action.payload,
      };
    case MODAL_INGRESOSCONTABLES:
      return {
        ...state,
        modalIngresosContables: action.payload,
      };
    case MODAL_EGRESOSCONTABLES:
      return {
        ...state,
        modalEgresosContables: action.payload,
      };
    case MODAL_EDITARMOVIMIENTOSCONTABLES:
      return {
        ...state,
        modalEditarMovimientosContables: action.payload,
      };
    case GET_LISTPRESTAMOSAPROBADOS:
      return {
        ...state,
        listPrestamosAprobados: action.payload,
      };
    case GET_LISTPRESTAMOS:
      return {
        ...state,
        listadoPrestamos: action.payload,
      };
    case MODAL_PAGOS:
      return {
        ...state,
        modalPagos: action.payload,
      };
    case GET_PAGOSREALIZADOS:
      return {
        ...state,
        pagosRealizados: action.payload,
      };
    case GET_ABONOSREALIZADOS:
      return {
        ...state,
        abonosRealizados: action.payload,
      };
    case GET_PRESTAMO:
      return {
        ...state,
        prestamo: action.payload,
      };
    case GET_USUARIO:
      return {
        ...state,
        usuario: action.payload,
      };
    case GET_NOTIFICACIONES:
      return {
        ...state,
        listadoNotificaciones: action.payload,
      };
    case MODAL_NOTIFICACIONES:
      return {
        ...state,
        modalNotificaciones: action.payload,
      };
    case MODAL_EDITARNOTIFICACIONES:
      return {
        ...state,
        modalEditarNotificaciones: action.payload,
      };
    case GETCHAT:
      return {
        ...state,
        chat: action.payload,
      };
    case GET_RESGISTROACTIVIDAD:
      return {
        ...state,
        registroActividad: action.payload,
      };
    case GET_LISTSINFONDOS:
      return {
        ...state,
        listUsuariosSinFondos: action.payload,
      };
    default:
      return state;
  }
}
