import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './statics/css/app.css';
import './statics/css/loading.css';
import { app } from './config/firebaseConfig';

import { useDispatch } from 'react-redux';
import { setUser } from './redux/actions/sesionAction';

import IndexTotal from './components/IndexTotal';
import Loading from './components/Loading';
import ProtectedRoute from './routers/ProtectedRoute';
import ProtectedIndex from './routers/ProtectedIndex';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import Verify from './components/auth/Verify';
import ResetPassword from './components/auth/ResetPassword';
import NotFound from './components/top/NotFound';
import Landing from './components/landing';
import Register from './components/auth/Register';

function Main() {
  const dispatch = useDispatch();
  const [login, setLogin] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      if (user) {
        setLogin(true);
        dispatch(setUser(user.uid));
      } else {
        setLogin(false);
        dispatch(setUser(null));
      }
    });
  }, [dispatch]);

  if (login === null) return <Loading />;

  return (
    <Router>
      <Switch>
        {/*autenticacion*/}
        <Route path='/login' component={Login}></Route>
        <Route exact path='/register' component={Register}></Route>
        <Route exact path='/forgotpassword' component={ForgotPassword}></Route>
        <Route exact path='/verificacion' component={Verify}></Route>
        <Route exact path='/resetpassword' component={ResetPassword}></Route>
        {/*<Route path="/" component={landing}></Route> */}
        <ProtectedRoute path='/prestamos/:prestamoid'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/prestamos'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/prestamorapido/:UserId'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/prestamorapido'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/dashboard'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/perfil'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/superchat'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/cobranzas/:userid'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/cobranzas'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/validarprestamos/:userid'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/validarprestamos'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/usuarios'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/configuracion'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/calculadora'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/mensajes'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/contabilidad'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/notificaciones'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/sinfondos'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/inicio'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/historial/:id'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/historial'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/cuentasporcobrar'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedRoute path='/registros'>
          <IndexTotal />
        </ProtectedRoute>
        <ProtectedIndex path='/'>
          <Landing />
        </ProtectedIndex>
        <Route path='*' exact={true} component={NotFound}></Route>
      </Switch>
    </Router>
  );
}

export default Main;
