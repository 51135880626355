import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { app } from '../../config/firebaseConfig';
import * as Icon from 'react-feather';
import ReactHtmlParser from 'react-html-parser';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import $ from 'jquery';

import { custom } from '../../helpers/alertas';
import { LOADING, ENDLOADING } from '../../redux/types';
import { sendMsgAdmin, getChatAdmin } from '../../redux/actions/userAction';

import Profile13 from '../../statics/images/profile-13.jpg';

import 'moment/min/locales';
moment.locale('es');

const ChatAdmin = (props) => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  //========== USE-STATE ==========//
  const [msg, setMgs] = useState('');
  const [fileName, setFileName] = useState('');
  const [fbStoreRef, setFbStoreRef] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);

  useEffect(() => {}, []);

  const mensajePosition = (rol) => {
    if (rol === 'Sistema') {
      return 'float-right ';
    } else {
      return 'float-left';
    }
  };

  const mensajeColor = (rol) => {
    if (rol === 'Sistema') {
      return 'bg-theme-32 text-white rounded-l-md';
    } else {
      return 'bg-gray-200 text-gray-700 rounded-r-md';
    }
  };

  const dateColor = (rol) => {
    if (rol === 'Sistema') {
      return 'text-theme-25';
    } else {
      return 'text-gray-600';
    }
  };

  const handleClickDropDown = (target) => {
    let dropdown = $(target).closest('.dropdown');
    console.log('dropdown', dropdown);
    if (
      !$(dropdown).length ||
      ($(target).closest('.dropdown-toggle').length &&
        $(dropdown).find('.dropdown-box').first().hasClass('show'))
    ) {
      $('.dropdown-box').removeClass('show');
    } else {
      $('.dropdown-box').removeClass('show');
      $(dropdown).find('.dropdown-box').first().addClass('show');
    }
  };

  const send = () => {
    console.log('rol', props.rol);
    if (msg !== '') {
      let mensajesArray = [];
      if (props.chat) {
        //si existe el chat
        mensajesArray = [...props.chat.mensajes];
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idUsuario: myUser.id,
          rol: props.rol,
          texto: msg,
        });
        dispatch(sendMsgAdmin(props.chat, mensajesArray));
        setTimeout(() => {
          setMgs('');
        }, 100);
      } else {
        //si no existe lo crea
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idUsuario: myUser.id,
          rol: props.rol,
          texto: msg,
        });
        let chatNew = {
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idChatAdmin: '',
          idUsuario: myUser.id,
          mensajes: mensajesArray,
        };
        app
          .firestore()
          .collection('chatAdmin')
          .add(chatNew)
          .then((resp) => {
            setMgs('');
            dispatch(getChatAdmin(props.chat.id));
          });
      }
    }
  };

  const sendAdjuntado = (textMessage) => {
    if (textMessage !== '') {
      let mensajesArray = [];
      if (props.chat) {
        //si existe el chat
        mensajesArray = [...props.chat.mensajes];
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idUsuario: myUser.id,
          rol: props.rol,
          texto: textMessage,
        });
        dispatch(sendMsgAdmin(props.chat, mensajesArray));
        setTimeout(() => {
          setMgs('');
        }, 100);
      } else {
        //si no existe lo crea
        mensajesArray.push({
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idUsuario: myUser.id,
          rol: props.rol,
          texto: textMessage,
        });
        let chatNew = {
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          idChatAdmin: '',
          idUsuario: myUser.id,
          mensajes: mensajesArray,
        };
        app
          .firestore()
          .collection('chatAdmin')
          .add(chatNew)
          .then((resp) => {
            setMgs('');
            dispatch(getChatAdmin(props.chat.id));
          });
      }
    }
  };

  const selecMsj = (msj) => {
    let el = document.getElementById('icon-mensaje');
    setMgs(msj.textoMensaje);
    handleClickDropDown(el);
  };

  const sortByDate = (a, b) => {
    var dateA = new Date(a.fecha).getTime();
    var dateB = new Date(b.fecha).getTime();
    return dateA > dateB ? 1 : +1;
  };

  const isImage = (texto) => {
    const idxDot = texto.lastIndexOf('.') + 1;
    const extFile = texto.substr(idxDot, texto.length).toLowerCase();
    if (
      extFile.includes('jpg') ||
      extFile.includes('jpeg') ||
      extFile.includes('png') ||
      extFile.includes('gif') ||
      extFile.includes('svg') ||
      extFile.includes('bmp') ||
      extFile.includes('tiff') ||
      texto.includes('?alt=media')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const openExplorer = (e) => {
    const letra = fileRef.current.click();
    console.log(letra);
  };

  const closeExplorer = (e) => {
    setIsUpload(false);
    setSelectedImage();
    setFileName('');
  };

  const loadFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedImage(file);
      setIsUpload(true);
      setFileName(file.name);
    }
  };

  const sendVisualizer = (e) => {
    upload(selectedImage);
  };

  const upload = (file) => {
    dispatch({ type: LOADING });
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;

    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app
        .storage()
        .ref(`chat/prestamo/${props.chat.id}/${imageFileName}`);
      setFbStoreRef(storageRef);
      const task = storageRef.put(file);

      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
          dispatch({ type: ENDLOADING });
        },
        () => {
          storageRef
            .getDownloadURL()
            .then((response) => {
              sendAdjuntado(response);
              setIsUpload(false);
              setFileName('');
              setFbStoreRef(null);
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log('Error al subir el archivo');
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  let mensajes = [];
  if (props.chat && props.chat.mensajes) {
    mensajes = props.chat.mensajes.sort(sortByDate);
  }

  return (
    <div className="intro-y chat grid grid-cols-12 gap-5 w-full h-full">
      <div className="intro-y col-span-12 lg:col-span-12 xxl:col-span-12 relative">
        {isUpload && (
          <div
            className="visualizer intro-y box bg-gray-200"
            style={
              props.hfull ? { height: 'calc(100vh - 130px )' } : { height: 470 }
            }
          >
            <div className="visualizer-header">
              <Icon.X
                className="w-6 h-6 mr-4 pointer absolute right-0"
                onClick={closeExplorer}
              />
              {fileName}
            </div>
            <div className="visualizer-body ">
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="imagen-preview"
              />
            </div>
            <div className="visualizer-footer">
              <button
                className="w-14 h-14 block bg-theme-1 text-white rounded-full flex-none flex items-center justify-center"
                onClick={sendVisualizer}
              >
                <Icon.Send className="w-6 h-6" />
              </button>
            </div>
          </div>
        )}
        <div
          className="chat__box box"
          style={
            props.hfull ? { height: 'calc(100vh - 130px )' } : { height: 470 }
          }
        >
          <div className="h-full flex flex-col">
            {/* Header*/}
            <div className="flex flex-col sm:flex-row border-b border-gray-200 px-5 py-4">
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-10 sm:h-10 flex-none image-fit relative">
                  <img
                    alt="Avatar Usuario"
                    className="rounded-full"
                    src={myUser.urlImagen ? myUser.urlImagen : Profile13}
                  />
                </div>
                <div className="ml-3 mr-auto">
                  <div className="font-medium text-base">
                    {myUser.nombres} {myUser.apellidos}
                  </div>
                  <div className="text-gray-600 text-xs sm:text-sm font-medium">
                    <span className="mx-1">•</span> Online - , estas usando el
                    chat
                  </div>
                </div>
              </div>
            </div>
            <PerfectScrollbar className="overflow-y-auto px-5 pt-5 flex-1">
              {props.chat &&
                props.chat.mensajes &&
                props.chat.mensajes.reverse().map((item, i) => (
                  <div key={i}>
                    <div
                      className={[
                        'chat__box__text-box',
                        'flex items-end',
                        'mb-4',
                        mensajePosition(item.rol),
                      ].join(' ')}
                    >
                      {item.idUsuario !== app.auth().currentUser.uid && (
                        <div className="sm:block dropdown relative mr-3 my-auto">
                          <a
                            href="javascript:;"
                            className="dropdown-toggle w-4 h-4 text-gray-500"
                            onClick={handleClickDropDown}
                          >
                            <i
                              data-feather="more-vertical"
                              className="w-4 h-4"
                            ></i>
                            <i
                              data-feather="bar-chart-2"
                              className="w-8 h-8 text-white transform -rotate-90"
                            ></i>
                          </a>
                          <div className="dropdown-box mt-6 absolute w-40 top-0 right-0 z-20">
                            <div className="dropdown-box__content box p-2">
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="corner-up-left"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Reply
                              </a>
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="trash"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={[
                          'px-4',
                          'py-3',
                          'rounded-t-md',
                          mensajeColor(item.rol),
                        ].join(' ')}
                      >
                        {isImage(item.texto) ? (
                          <a
                            href={item.texto}
                            target="_blank"
                          >
                            <img
                              src={item.texto}
                              alt="imagen"
                              style={{ width: 150, height: 'auto' }}
                            />
                          </a>
                        ) : (
                          <div>{ReactHtmlParser(item.texto)}</div>
                        )}
                        <div
                          className={[
                            'mt-1',
                            'text-xs',
                            dateColor(item.rol),
                          ].join(' ')}
                        >
                          {moment(item.fecha).fromNow()}
                        </div>
                      </div>
                      {item.idUsuario === app.auth().currentUser.uid && (
                        <div className="sm:block dropdown relative ml-3 my-auto">
                          <a
                            href="javascript:;"
                            className="dropdown-toggle w-4 h-4 text-gray-500"
                          >
                            <i
                              data-feather="more-vertical"
                              className="w-4 h-4"
                            ></i>
                          </a>
                          <div className="dropdown-box mt-6 absolute w-40 top-0 right-0 z-20">
                            <div className="dropdown-box__content box p-2">
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="corner-up-left"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Reply
                              </a>
                              <a
                                href="#!"
                                className="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                              >
                                <i
                                  data-feather="trash"
                                  className="w-4 h-4 mr-2"
                                ></i>
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="clear-both"></div>
                  </div>
                ))}
            </PerfectScrollbar>
            {/*Fin del Content*/}
            {/* Footer seccion para escribir mensaje */}
            <div className="pt-4 pb-10 sm:py-4 flex items-center border border-gray-400 box mt-3">
              <textarea
                className="chat__box__input input w-full h-16 resize-none border-transparent px-5 py-3 focus:shadow-none bg-theme-2 ml-2"
                rows="1"
                placeholder=" Escribe tu mensaje..."
                value={msg}
                onChange={(e) => setMgs(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    send();
                  }
                }}
              ></textarea>
              <div className="flex absolute sm:static left-0 bottom-0 ml-5 sm:ml-0 mb-5 sm:mb-0">
                <input
                  id="fileUpload"
                  type="file"
                  className="mt-20 left-0 absolute opacity-0"
                  ref={fileRef}
                  onChange={loadFile}
                />
                <div className="w-4 h-4 sm:w-5 sm:h-5 relative text-gray-500 mr-3 sm:mr-5 pointer">
                  <Icon.Paperclip
                    className="w-full h-full ml-2 pointer"
                    onClick={openExplorer}
                  />
                </div>
              </div>
              <button
                className="w-8 h-8 sm:w-10 sm:h-10 block bg-theme-1 text-white rounded-full flex-none flex items-center justify-center mr-5"
                onClick={send}
              >
                <Icon.Send className="w-4 h-4" />
              </button>
            </div>
            {/* Fin del Footer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatAdmin;
