import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';
import feather from 'feather-icons';
import moment from 'moment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Chat from '../../chat/Chat';

import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';

import {
  getChat,
  getPrestamo,
  getLinkSociales,
  getRequerimientosUsuarios,
  getRequerimientoPrestamo,
  getAbonosRealizados,
} from '../../../redux/actions/adminAction';

export const Detalle = () => {
  let { prestamoid } = useParams();
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const chat = useSelector((state) => state.admin.chat);
  const prestamo = useSelector((state) => state.admin.prestamo);
  const linkSociales = useSelector((state) => state.admin.linkSociales);
  const abonosRealizados = useSelector((state) => state.admin.abonosRealizados);
  const requerimientosUsuarios = useSelector(
    (state) => state.admin.requerimientosUsuarios
  );
  const requerimientoPrestamo = useSelector(
    (state) => state.admin.requerimientoPrestamo
  );

  useEffect(() => {
    (function ($) {
      //cambiar de tabs
      $('body').on('click', 'a[data-toggle="tab"]', function (key, el) {
        $(this)
          .closest('.nav-tabs')
          .find('a[data-toggle="tab"]')
          .removeClass('active');
        $(this).addClass('active');
        let elementId = $(this).attr('data-target');
        $(elementId)
          .closest('.tab-content')
          .find('.tab-content__pane')
          .removeClass('active');
        $(elementId).addClass('active');
      });

      // Daterangepicker
      $('.datepicker').each(function () {
        let options = {
          singleDatePicker: true,
          showDropdowns: true,
          minYear: 1901,
          maxYear: parseInt(moment().format('YYYY'), 10),
        };
        if ($(this).data('daterange')) {
          options.singleDatePicker = false;
        }
        if ($(this).data('timepicker')) {
          options.timePicker = true;
          options.locale = {
            format: 'M/DD hh:mm A',
          };
        }
        $(this).daterangepicker(options);
      });
    })($);
  }, []);

  useEffect(() => {
    dispatch(getChat(prestamoid));
    dispatch(getLinkSociales(prestamoid));
    dispatch(getRequerimientosUsuarios(prestamoid));
    dispatch(getRequerimientoPrestamo(prestamoid));
    dispatch(getPrestamo(prestamoid));
    dispatch(getAbonosRealizados(prestamoid));
  }, []);

  //========== funciones y metodos de verificacion imagenes ==========//
  useEffect(() => {
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 });
      // Tooltipster
      $('.tooltip').each(function () {
        let options = {
          delay: 0,
          theme: 'tooltipster-borderless',
        };
        if ($(this).data('event') === 'on-click') {
          options.trigger = 'click';
        }
        if ($(this).data('theme') === 'light') {
          options.theme = 'tooltipster-shadow';
        }
        if ($(this).data('side') !== undefined) {
          options.side = $(this).data('side');
        }
        $(this).tooltipster(options);
      });
    })($);
  }, []);

  const calcularInteresesPorAbonos = () => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    abonosRealizados.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamo.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamo.montoPrestado,
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);

        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );

        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  return (
    <>
      <h1 className='font-medium text-center text-base mt-4 bg-theme-26 box p-2'>
        Detalle de Préstamo
      </h1>

      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-5'>
        <div className='col-span-12'>
          <div className='intro-y box lg:mt-5'>
            <div className='flex p-3 border-b text-base'>
              <h2 className='font-medium mr-auto ml-8'>
                {' '}
                <DoneAllIcon color='primary' /> ._ Datos del Usuarios
              </h2>
            </div>
            <hr />
            <div className='flex p-3 bg-theme-2 box overflow-auto lg:overflow-visible'>
              {prestamo.usuario && (
                <div className='grid grid-cols-12 gap-4 row-gap-5 w-full'>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Nombres:
                    </label>
                    <span>
                      {prestamo.usuario.nombres} {''}
                      {prestamo.usuario.apellidos}
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Cédula:
                    </label>
                    <span>{prestamo.usuario.numeroCedula}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Email:
                    </label>
                    <span>{prestamo.usuario.email}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Pasaporte:
                    </label>
                    <span>{prestamo.usuario.numeroPasaporte}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Sexo:
                    </label>
                    <span>{prestamo.usuario.sexo}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de nacimiento:
                    </label>
                    <span>{prestamo.usuario.fechaNacimiento}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      País:
                    </label>
                    <span>{prestamo.usuario.pais}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Localidad:
                    </label>
                    <span>{prestamo.usuario.localidad}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Whatsapp:
                    </label>
                    <span>{prestamo.usuario.whatsapp}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Otro número de contacto:
                    </label>
                    <span>{prestamo.usuario.telefonoOpcional}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Usuario telegram:
                    </label>
                    <span>{prestamo.usuario.telegram}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Trabaja:
                    </label>
                    <span>{prestamo.usuario.trabaja}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Área de trabajo:
                    </label>
                    <span>{prestamo.usuario.areaTrabajo}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Nombre Empresa:
                    </label>
                    <span>{prestamo.usuario.nombreEmpresa}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Ingreso mensual:
                    </label>
                    <span>{prestamo.usuario.ingresoMensualUSD} $$</span>
                  </div>
                  <div className='col-span-8 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Metodo Pago:
                    </label>
                    <span>{prestamo.usuario.metodosPagos}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha registro:
                    </label>
                    <span>{prestamo.usuario.fechaRegistro}</span>
                  </div>
                  <div className='col-span-12 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Descripón actividad:
                    </label>
                    <span>{prestamo.usuario.descripcionActividad}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*===== Validaciones Redes Sociales =====*/}
      <div className='grid grid-cols-12 gap-4 row-gap-5 w-full'>
        <div className='col-span-12 md:col-span-6'>
          <div className='intro-y box lg:mt-5'>
            <div className='flex p-3 border-b text-base'>
              <h2 className='font-medium mr-auto ml-8'>
                <DoneOutlineIcon color='primary' /> ._ Validación de Redes
                Sociales
              </h2>
            </div>
            <hr />
            <div className='grid grid-cols-12 gap-4 row-gap-5 w-full flex p-3 flex-col bg-theme-2 box'>
              {linkSociales.map((link, idUsuario) => (
                <div className='col-span-12' key={idUsuario}>
                  <div className='flex flex-col justify-center'>
                    <label className='font-medium ml-2 bg-theme-23 box p-1'>
                      {link.url}
                    </label>
                    {link.validado === 'aprobado' && (
                      <label className='font-medium bg-theme-26 box p-1 ml-3 mt-1'>
                        {link.validado === 'aprobado'
                          ? 'Validado por:'
                          : 'No Validado'}
                      </label>
                    )}
                    {link.validado === 'aprobado' && (
                      <div className='flex flex-col justify-center ml-3'>
                        <label className='font-medium ml-3'>
                          {link.gestor.nombres} {link.gestor.apellidos}
                        </label>
                        <label className='font-medium ml-3'>
                          El:{' '}
                          {moment(link.fechaValidacion).format('DD-MM-YYYY')}
                        </label>
                      </div>
                    )}
                    {link.validado === 'rechazado' && (
                      <div className='flex flex-col justify-center ml-3 mt-1'>
                        <b className=' bg-theme-6 box p-1'>Rechazado por:</b>
                        <label className='font-medium ml-3'>
                          {link.gestor.nombres} {link.gestor.apellidos}
                        </label>
                        <label className='font-medium ml-3'>
                          El:{' '}
                          {moment(link.fechaValidacion).format('DD-MM-YYYY')}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {linkSociales.length === 0 && (
                <div className='col-span-10'>
                  <div className='flex flex-col justify-center'>
                    <label className='font-medium ml-2 bg-theme-36 box p-1'>
                      El usuario no ha cargado Links Sociales.
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*===== Validaciones Requerimientos =====*/}
        <div className='col-span-12 md:col-span-6'>
          <div className='intro-y box lg:mt-5'>
            <div className='flex p-3 border-b text-base'>
              <h2 className='font-medium mr-auto ml-8'>
                <DoneOutlineIcon color='primary' /> ._ Validación de
                Requerimientos
              </h2>
            </div>
            <hr />
            {requerimientosUsuarios.length > 0 && (
              <div className='grid grid-cols-12 gap-4 row-gap-5 w-full flex p-3 flex-col bg-theme-2 box'>
                <div className='col-span-12'>
                  {requerimientosUsuarios[0].validadoSelfieCedula !== '' && (
                    <div className='flex flex-col justify-center'>
                      <label className='font-medium ml-2 bg-theme-23 box p-1'>
                        Selfie Sosteniendo Documento de Identificación
                      </label>
                      {requerimientosUsuarios[0].validadoSelfieCedula ===
                        'aprobado' && (
                        <div className='flex flex-col justify-center ml-3'>
                          <label className='font-medium bg-theme-26 box p-1 mt-1'>
                            Esta{' '}
                            {requerimientosUsuarios[0].validadoSelfieCedula}{' '}
                            por:
                          </label>
                          <label className='font-medium ml-3'>
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorSelfieCedula.nombres
                            }{' '}
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorSelfieCedula.apellidos
                            }
                          </label>
                          <label className='font-medium ml-3'>
                            El:{' '}
                            {moment(
                              requerimientosUsuarios[0]
                                .fechaValidacionSelfieCedula
                            ).format('DD-MM-YYYY')}
                          </label>
                        </div>
                      )}
                      {requerimientosUsuarios[0].validadoSelfieCedula ===
                        'rechazado' && (
                        <div className='flex flex-col justify-center ml-3'>
                          <label className='font-medium bg-theme-6 box p-1 mt-1 bg-theme-6 box'>
                            Esta{' '}
                            {requerimientosUsuarios[0].validadoSelfieCedula}{' '}
                            por:
                          </label>
                          <label className='font-medium ml-3'>
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorSelfieCedula.nombres
                            }{' '}
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorSelfieCedula.apellidos
                            }
                          </label>
                          <label className='font-medium ml-3'>
                            El:{' '}
                            {moment(
                              requerimientosUsuarios[0]
                                .fechaValidacionSelfieCedula
                            ).format('DD-MM-YYYY')}
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                  {requerimientosUsuarios[0].validadoSelfieCedula === '' && (
                    <label className='font-medium ml-3 bg-theme-17 box p-2'>
                      Selfie con documento de identificación no ha sido revisada
                      por ningún gestor.
                    </label>
                  )}
                </div>
                <div className='col-span-12'>
                  {requerimientosUsuarios[0].validadoDocumento !== '' && (
                    <div className='flex flex-col justify-center'>
                      <label className='font-medium ml-2 bg-theme-23 box p-1'>
                        Documento de Identificación
                      </label>
                      {requerimientosUsuarios[0].validadoDocumento ===
                        'aprobado' && (
                        <div className='flex flex-col justify-center ml-3'>
                          <label className='font-medium bg-theme-26 box p-1 mt-1'>
                            Esta {requerimientosUsuarios[0].validadoDocumento}{' '}
                            por:
                          </label>
                          <label className='font-medium ml-3'>
                            {
                              requerimientosUsuarios[0].validadoGestorDocumento
                                .nombres
                            }{' '}
                            {
                              requerimientosUsuarios[0].validadoGestorDocumento
                                .apellidos
                            }
                          </label>
                          <label className='font-medium ml-3'>
                            El:{' '}
                            {moment(
                              requerimientosUsuarios[0].fechaValidacionDocumento
                            ).format('DD-MM-YYYY')}
                          </label>
                        </div>
                      )}
                      {requerimientosUsuarios[0].validadoDocumento ===
                        'rechazado' && (
                        <div className='flex flex-col justify-center ml-3'>
                          <label className='font-medium bg-theme-6 box p-1 mt-1'>
                            Esta {requerimientosUsuarios[0].validadoDocumento}{' '}
                            por:
                          </label>
                          <label className='font-medium ml-3'>
                            {
                              requerimientosUsuarios[0].validadoGestorDocumento
                                .nombres
                            }{' '}
                            {
                              requerimientosUsuarios[0].validadoGestorDocumento
                                .apellidos
                            }
                          </label>
                          <label className='font-medium ml-3'>
                            El:{' '}
                            {moment(
                              requerimientosUsuarios[0].fechaValidacionDocumento
                            ).format('DD-MM-YYYY')}
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                  {requerimientosUsuarios[0].validadoDocumento === '' && (
                    <label className='font-medium ml-3 bg-theme-17 box p-2'>
                      Documento de identificación no ha sido revisado por ningún
                      gestor.
                    </label>
                  )}
                </div>
                <div className='col-span-12'>
                  {requerimientosUsuarios[0].validadoComprobanteTrabajo !==
                    '' && (
                    <div className='flex flex-col justify-center'>
                      <label className='font-medium ml-2 bg-theme-23 box p-1'>
                        Comprobante de Trabajo
                      </label>
                      {requerimientosUsuarios[0].validadoComprobanteTrabajo ===
                        'aprobado' && (
                        <div className='flex flex-col justify-center ml-3'>
                          <label className='font-medium bg-theme-26 box p-1 mt-1'>
                            Esta{' '}
                            {
                              requerimientosUsuarios[0]
                                .validadoComprobanteTrabajo
                            }{' '}
                            por:
                          </label>
                          <label className='font-medium ml-3'>
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorComprobanteTrabajo.nombres
                            }{' '}
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorComprobanteTrabajo.apellidos
                            }
                          </label>
                          <label className='font-medium ml-3'>
                            El:{' '}
                            {moment(
                              requerimientosUsuarios[0]
                                .fechaValidacionComprobanteTrabajo
                            ).format('DD-MM-YYYY')}
                          </label>
                        </div>
                      )}
                      {requerimientosUsuarios[0].validadoComprobanteTrabajo ===
                        'rechazado' && (
                        <div className='flex flex-col justify-center ml-3'>
                          <label className='font-medium bg-theme-6 box p-1 mt-1'>
                            Esta{' '}
                            {
                              requerimientosUsuarios[0]
                                .validadoComprobanteTrabajo
                            }{' '}
                            por:
                          </label>
                          <label className='font-medium ml-3'>
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorComprobanteTrabajo.nombres
                            }{' '}
                            {
                              requerimientosUsuarios[0]
                                .validadoGestorComprobanteTrabajo.apellidos
                            }
                          </label>
                          <label className='font-medium ml-3'>
                            El:{' '}
                            {moment(
                              requerimientosUsuarios[0]
                                .fechaValidacionComprobanteTrabajo
                            ).format('DD-MM-YYYY')}
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                  {requerimientosUsuarios[0].validadoComprobanteTrabajo ===
                    '' && (
                    <label className='font-medium ml-3 bg-theme-17 box p-2'>
                      Comprobante de trabajo no ha sido revisado por ningún
                      gestor.
                    </label>
                  )}
                </div>
                {requerimientoPrestamo.length === 0 && (
                  <div className='col-span-12'>
                    <label className='font-medium ml-3 bg-theme-36 box p-2'>
                      Selfie con préstamo no ha sido subida por el usuario.
                    </label>
                  </div>
                )}
                {requerimientoPrestamo.length > 0 && (
                  <div className='col-span-12'>
                    {requerimientoPrestamo[0].validadoSelfiePrestamo !== '' && (
                      <div className='flex flex-col justify-center'>
                        <label className='font-medium ml-2 bg-theme-23 box p-1'>
                          Selfie Sosteniendo Documento de Préstamo
                        </label>
                        {requerimientoPrestamo[0].validadoSelfiePrestamo ===
                          'aprobado' && (
                          <div className='flex flex-col justify-center ml-3'>
                            <label className='font-medium bg-theme-26 box p-1 mt-1'>
                              Esta{' '}
                              {requerimientoPrestamo[0].validadoSelfiePrestamo}{' '}
                              por:
                            </label>
                            <label className='font-medium ml-3'>
                              {
                                requerimientoPrestamo[0]
                                  .validadoGestorSelfiePrestamo.nombres
                              }{' '}
                              {
                                requerimientoPrestamo[0]
                                  .validadoGestorSelfiePrestamo.apellidos
                              }
                            </label>
                            <label className='font-medium ml-3'>
                              El:{' '}
                              {moment(
                                requerimientoPrestamo[0]
                                  .fechaValidacionSelfiePrestamo
                              ).format('DD-MM-YYYY')}
                            </label>
                          </div>
                        )}
                        {requerimientoPrestamo[0].validadoSelfiePrestamo ===
                          'rechazado' && (
                          <div className='flex flex-col justify-center ml-3'>
                            <label className='font-medium bg-theme-6 box p-1 mt-1'>
                              Esta{' '}
                              {requerimientoPrestamo[0].validadoSelfiePrestamo}{' '}
                              por:
                            </label>
                            <label className='font-medium ml-3'>
                              {
                                requerimientoPrestamo[0]
                                  .validadoGestorSelfiePrestamo.nombres
                              }{' '}
                              {
                                requerimientoPrestamo[0]
                                  .validadoGestorSelfiePrestamo.apellidos
                              }
                            </label>
                            <label className='font-medium ml-3'>
                              El:{' '}
                              {moment(
                                requerimientoPrestamo[0]
                                  .fechaValidacionSelfiePrestamo
                              ).format('DD-MM-YYYY')}
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                    {requerimientoPrestamo[0].validadoSelfiePrestamo === '' && (
                      <label className='font-medium ml-3 bg-theme-17 box p-2'>
                        Selfie con préstamo no ha sido revisado por ningún
                        gestor.
                      </label>
                    )}
                  </div>
                )}
              </div>
            )}
            {requerimientosUsuarios.length === 0 &&
              requerimientoPrestamo.length === 0 && (
                <div className='grid grid-cols-12 gap-4 row-gap-5 w-full flex p-3 flex-col bg-theme-2'>
                  <div className='col-span-12'>
                    <div className='flex flex-col justify-center'>
                      <label className='font-medium ml-2 bg-theme-36 box p-1'>
                        El usuario no ha cargado imagenes.
                      </label>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      {/*========== Datos del Prestamo ==========*/}
      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-10'>
        <div className='col-span-12 '>
          <div className='intro-y box lg:mt-5'>
            <div className='flex p-3 border-b text-base'>
              <h2 className='font-medium mr-auto ml-8'>
                <DoneAllIcon color='primary' /> ._ Datos Préstamo
              </h2>
            </div>
            <hr />
            <div className='flex p-3 bg-theme-2 box overflow-auto lg:overflow-visible'>
              {prestamo && (
                <div className='grid grid-cols-12 gap-4 row-gap-5 w-full'>
                  <div className='col-span-4'>
                    <label className='font-medium p-1 bg-theme-26 box ml-2 mr-1'>
                      Estado:
                    </label>
                    <span>{prestamo.estado}</span>
                  </div>
                  <div className='col-span-4 ml-16'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Aprobado:
                    </label>
                    <span>{prestamo.aprobado === true ? 'Si' : 'No'}</span>
                  </div>
                  <div className='col-span-4 ml-16'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Pagado:
                    </label>
                    <span>{prestamo.pagado === true ? 'Si' : 'No'}</span>
                  </div>
                  <div className='col-span-4'>
                    <label className='font-medium p-1 bg-theme-26 box ml-2 mr-1'>
                      Monto solicitado:
                    </label>
                    <span>{prestamo.montoPrestado} USD</span>
                  </div>
                  <div className='col-span-4 ml-16'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto a pagar:
                    </label>
                    <span>{prestamo.montoCompromisoPago} USD</span>
                  </div>
                  <div className='col-span-4 ml-16'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de pago:
                    </label>
                    <span>{prestamo.fechaCompromisoPago}</span>
                  </div>
                  <div className='col-span-4'>
                    <label className='font-medium p-1 bg-theme-26 box ml-2 mr-1'>
                      Solicitado el:
                    </label>
                    <span>{prestamo.fechaSolicitud}</span>
                  </div>
                  <div className='col-span-4 ml-16'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Interes:
                    </label>
                    <span>
                      {myUser.habilitadoNuevoPrestamo === 'No'
                        ? prestamo.tasaInteresCompuesto
                        : myUser.nuevaTasaInteres}{' '}
                      %
                    </span>
                  </div>
                </div>
              )}
            </div>
            {prestamo.fechaInicioDespues && (
              <>
                <h3 className='font-medium p-2 bg-theme-23 box text-center mt-5'>
                  Préstamo con Deuda Restante
                </h3>
                <div className='grid grid-cols-12 gap-4 row-gap-5 bg-theme-23 box mb-5 p-2'>
                  <div className='col-span-4 p-1 bg-theme-26 box'>
                    <label className='font-medium ml-2 mr-1'>
                      Monto deuda restante :
                    </label>
                    <span>{calcularInteresesPorAbonos()['interesHoy']} $</span>
                  </div>
                  <div className='col-span-4 ml-10 p-1 bg-theme-26 box '>
                    <label className='font-medium ml-2 mr-1'>
                      Fecha inicio :
                    </label>
                    <span>{prestamo.fechaInicioDespues} </span>
                  </div>
                  <div className='col-span-4 ml-10 p-1 bg-theme-26 box '>
                    <label className='font-medium ml-2 mr-1'>
                      Nueva fecha de pago :
                    </label>
                    <span>{prestamo.fechaCompromisoPagoDespues} </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Chat
        chat={chat}
        gestor={true}
        rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
      />
    </>
  );
};

export default Detalle;
