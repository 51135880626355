import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { app } from '../../../config/firebaseConfig';
import daterangepicker from 'daterangepicker';
import moment from 'moment';
import $ from 'jquery';
import * as Icon from 'react-feather';
import { Bar } from 'react-chartjs-2';

import {
  getIngresos,
  getEgresos,
} from '../../../redux/actions/superAdminActions';

import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';

export default function Dashboard() {
  const dispatch = useDispatch();

  const hoyBien = moment(new Date()).format('YYYY-MM-DD');

  //========== USE-STATE ==========//
  const [viewDate, setViewDate] = useState(
    `${moment().endOf('week').format('DD-MM-YYYY')}-${moment()
      .endOf('week')
      .format('DD-MM-YYYY')}`
  );

  const [cantidadUsuarios, setCantidadUsuarios] = useState(0);

  const [cantidadPrestamos, setCantidadPrestamos] = useState(0);

  const [cantidadAbonos, setCantidadAbonos] = useState(0);

  const [prestamosTodos, setPrestamosTodos] = useState([]);

  const [arrayPorRegresar, setArrayPorRegresar] = useState([]);

  //========== USE-SELECTOR ==========//
  const myUser = useSelector((state) => state.sesion.myUser);

  const ingresos = useSelector((state) => state.superadmin.ingresosContables);

  const egresos = useSelector((state) => state.superadmin.egresosContables);

  useEffect(() => {
    dispatch(getIngresos());
    dispatch(getEgresos());
    getTodosPrestamos();
  }, []);

  let start = moment().subtract(2, 'month').startOf('month');
  let end = moment();

  useEffect(() => {
    function cb(start, end) {
      const fechaDesde = start.format('YYYY-MM-DD');
      const fechaHasta = end.format('DD-MM-YYYY');
      setViewDate(`${fechaDesde} a ${fechaHasta}`);
      selectState(fechaDesde, fechaHasta);
      selectStatePrestamos(fechaDesde, fechaHasta);
      selectStateAbonos(fechaDesde, fechaHasta);
    }

    $('#customRangeUsers').daterangepicker(
      {
        startDate: start,
        endDate: end,
        showCustomRangeLabel: true,
        showWeekNumbers: true,
        singleDatePicker: false,
        autoApply: true,
        applyButtonClasses: 'button bg-theme-10 text-white ml-auto',
        view: 'weeks',
        ranges: {
          Hoy: [moment(), moment()],
          Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
          'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
          'Esta semana': [moment().startOf('week'), moment().endOf('week')],
          'Semana pasada': [
            moment().subtract(1, 'week').startOf('week'),
            moment().subtract(1, 'week').endOf('week'),
          ],
          'Este mes': [moment().startOf('month'), moment().endOf('month')],
          'Mes pasado': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
        },
      },
      cb
    );
    cb(start, end);
  }, []);

  const selectState = (inicio, fin) => {
    getUsers(inicio, fin);
  };

  const selectStatePrestamos = (inicio, fin) => {
    getPrestamos(inicio, fin);
  };

  const selectStateAbonos = (inicio, fin) => {
    getAbonos(inicio, fin);
  };

  const getTodosPrestamos = () => {
    let arrayFinal = [];
    app
      .firestore()
      .collection('prestamos')
      .orderBy('fechaInicio', 'desc')
      .get()
      .then(async (data) => {
        let array = [];
        let ptamo = {};
        data.forEach((doc) => {
          ptamo = doc.data();
          ptamo.id = doc.id;
          array.push(ptamo);
        }); //hasta aqui obtengo los prestamos
        //recorro los prestamos y concateno los abonos a cada prestamo

        let abono = {};
        for (let i = 0; i < array.length; i++) {
          // recorro cada prestamo
          //busco los abonos de cada prestamo iterable
          let arrayAbonos = [];
          const result = await app
            .firestore()
            .collection('abonos')
            .where('idPrestamo', '==', `${array[i].id}`)
            .orderBy('fecha', 'asc')
            .get();
          result.forEach((abo) => {
            abono = abo.data();
            abono.id = abo.id;
            arrayAbonos.push(abono);
          });
          const arrayConcat = { ...array[i], abonos: arrayAbonos };
          arrayFinal.push(arrayConcat);
        }
        setPrestamosTodos(arrayFinal);
      });
  };

  const getUsers = (desde, hasta) => {
    const fechaDesde = desde + ' 00:00:00';
    const fechaHasta = hasta + ' 23:59:59';
    app
      .firestore()
      .collection('usuarios')
      .where('rol', '==', 'Usuario')
      .where('fechaRegistro', '>=', fechaDesde)
      .where('fechaRegistro', '<=', fechaHasta)
      .orderBy('fechaRegistro', 'desc')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => {
          array.push(doc.data());
        });
        setCantidadUsuarios(array.length);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getPrestamos = (desde, hasta) => {
    app
      .firestore()
      .collection('prestamos')
      .where('fechaInicio', '>=', desde)
      .where('fechaInicio', '<=', hasta)
      .orderBy('fechaInicio', 'desc')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => {
          array.push(doc.data());
        });
        setCantidadPrestamos(array.length);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getAbonos = (desde, hasta) => {
    app
      .firestore()
      .collection('abonos')
      .orderBy('fecha', 'desc')
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => {
          array.push(doc.data());
        });
        setCantidadAbonos(array.length);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const buildData = () => {
    let sumIngreso = 0;
    let sumEgerso = 0;
    let sumPorRegresar = 0;

    ingresos.forEach((ing) => {
      sumIngreso += ing.monto;
    });

    egresos.forEach((egre) => {
      sumEgerso += egre.monto;
    });

    prestamosTodos.forEach((prestamo) => {
      let value = 0;

      if (prestamo.pagoEnviado === true) {
        if (prestamo.abonos.length > 0) {
          value = calcularInteresesPorAbonos(prestamo.abonos, prestamo)[
            'interesHoy'
          ];
        } else {
          value = montoHoy(prestamo);
        }
        sumPorRegresar += value;
      }
    });

    return {
      labels: ['Dinero Prestado', 'Dinero Recibido', 'Dinero por Regresar'],
      datasets: [
        {
          label: '$usd',
          data: [sumEgerso, sumIngreso, sumPorRegresar],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const montoHoy = (prestamoActual) => {
    if (prestamoActual) {
      let dias = calCulardiasTranscurridos(prestamoActual.fechaInicio);
      let monto = calculateInteres(
        prestamoActual.montoPrestado,
        dias,
        parseFloat(prestamoActual.tasaInteresCompuesto)
      );
      return parseFloat(monto);
    } else {
      return 0;
    }
  };

  const calcularInteresesPorAbonos = (abonosRecibido, prestamoActual) => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;

    let arrayRecorrer = [];
    arrayRecorrer = abonosRecibido;

    arrayRecorrer.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamoActual.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamoActual.montoPrestado,
          dif,
          parseFloat(prestamoActual.tasaInteresCompuesto)
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);

        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(prestamoActual.tasaInteresCompuesto)
        );

        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(prestamoActual.tasaInteresCompuesto)
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(prestamoActual.tasaInteresCompuesto)
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">
          <div className="col-span-12 mt-1">
            <div className="intro-y flex items-center h-10">
              <h2 className="text-lg font-medium ml-8 mr-5">Reporte General</h2>

              <div className="flex flex-col xl:flex-row xl:items-center">
                <div className="dropdown flex relative xl:ml-auto mb-1 xl:mt-0 flex align-center items-center">
                  <div
                    id="customRangeUsers"
                    className="datepicker input w-56 border block ml-auto text-theme-1 truncate relative"
                    style={{ cursor: 'pointer' }}
                  >
                    <div>
                      <Icon.Calendar className="w-4 h-4 absolute" />
                      <span className="ml-6"> {viewDate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
                <div className="report-box zoom-in">
                  <div className="box p-5">
                    <div className="flex">
                      <Icon.Users className="w-6 h-6 text-theme-10" />
                      <span className="text-theme-10 ml-3 font-medium">
                        Usuarios
                      </span>
                    </div>
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {cantidadUsuarios}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Usuarios
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
                <div className="report-box zoom-in">
                  <div className="box p-5">
                    <div className="flex">
                      <Icon.ShoppingCart className="w-6 h-6 text-theme-11" />
                      <span className="text-theme-11 ml-3 font-medium">
                        Préstamos
                      </span>
                    </div>
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {cantidadPrestamos}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Prestamos
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
                <div className="report-box zoom-in">
                  <div className="box p-5">
                    <div className="flex">
                      <Icon.DollarSign className="w-6 h-6 text-theme-12" />
                      <span className="text-theme-12 ml-3 font-medium">
                        Abonos
                      </span>
                    </div>
                    <div className="text-3xl font-bold leading-8 mt-6">
                      {cantidadAbonos}
                    </div>
                    <div className="text-base text-gray-600 mt-1">
                      Total Abonos
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-6 mt-8">
          <hr />
          <div className="intro-y block sm:flex items-center h-10 justify-between">
            <h2 className="text-lg font-medium ml-8 mt-2 ">Datos Finacieros</h2>
            {/*<label className="mr-8 text-theme-1 font-medium">
              Seleccionar Rango de fecha
            </label> */}
          </div>
          <hr />

          {/*<div className="flex flex-col xl:flex-row xl:items-center">
            <div className="dropdown flex relative xl:ml-auto mt-5 xl:mt-0 flex align-center items-center">
              <div
                id="customRangePayment"
                className="datepicker input w-56 border block ml-auto text-theme-1 truncate relative"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <Icon.Calendar className="w-4 h-4 absolute" />
                  <span className="ml-5"> {viewDate}</span>
                </div>
              </div>
            </div>
          </div> */}

          <div className="intro-y box p-5 mt-12 sm:mt-5">
            <div className="report-chart">
              <Bar
                data={buildData()}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
