import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icon from 'react-feather';
import Select from 'react-select';
import moment from 'moment';

import { app } from '../../../config/firebaseConfig';
import {
  deleteCuentaBancaria,
  modalCuenta,
  modalCuentaEcu,
  modalCuentaCol,
  modalCuentaPan,
  modalMetodoPago,
  saveCuentaBancaria,
  updateCuentaBancaria,
  changeSelected,
  savePagoMovil,
  deletePgoMovil,
  ModalPago,
  updatePgoMovil,
  updateDatosGenereales,
} from '../../../redux/actions/userAction';
import { DATOSGENERELES } from '../../../redux/types';
import { custom } from '../../../helpers/alertas';
import Modal from '../../Modal';
import listadoBancos from '../../../config/listadoBancos';
import listadoBancosEcu from '../../../config/listadoBancosEcu';
import listadoBancosPan from '../../../config/listadoBancosPan';
import listadoBancosCol from '../../../config/listadoBancosCol';
import metodosPagos from '../../../config/metodosPagos';
import CardCuentasBancarias from '../prestamos/CardCuentasBancarias';

//========== Import Acordion MaterialUI ==========//
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const AccordionBancos = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  //======== USE-SELECTORS ==========//
  const cuentas = useSelector((state) => state.user.cuentasBancarias);
  const listadoPagosMoviles = useSelector((state) => state.user.pagoMoviles);
  const modalCuentaBanacaria = useSelector(
    (state) => state.user.modalCuentaBanacaria
  );
  const modalCuentaBcoEcu = useSelector(
    (state) => state.user.modalCuentaBcoEcu
  );
  const modalCuentaBcoCol = useSelector(
    (state) => state.user.modalCuentaBcoCol
  );
  const modalCuentaBcoPan = useSelector(
    (state) => state.user.modalCuentaBcoPan
  );
  const modalPagoMovil = useSelector((state) => state.user.modalPagoMovil);
  const selectedCuenta = useSelector((state) => state.user.selectedCuenta);
  const datosGenerales = useSelector((state) => state.user.datosGenerales);
  const modalMetodosPagos = useSelector(
    (state) => state.user.modalMetodosPagos
  );

  //========== USE-STATES ==========//
  const [generales, setGenerales] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    fechaNacimiento: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: 'Venezuela-Transferencia',
    nombres: '',
    nombreEmpresa: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    sexo: 'M',
    telefonoOpcional: '',
    telegram: '',
    trabaja: 'No',
    whatsapp: '',
  });
  const [expanded, setExpanded] = useState(false);
  const [ctabancaria, setCtabancaria] = useState({
    banco: '',
    cedula: '',
    idUsuario: app.auth().currentUser.uid,
    fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
    nombreBanco: '',
    numeroCuenta: '',
    pais: 'Venezuela',
    telefono: '',
    tipoCuenta: '',
    titular: '',
  });
  const [pgoMovil, setPgoMovil] = useState({
    banco: '',
    cedula: '',
    idUsuario: app.auth().currentUser.uid,
    fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
    nombreBanco: '',
    numeroCuenta: '',
    pais: 'Venezuela',
    telefono: '',
    titular: '',
  });
  // const [bancosMetPagos, setBancosMetPagos] = useState({
  //   metodosPagos: "",
  // });
  const [editar, setEditar] = useState(false);
  const [editPgoMovil, setEditPgoMovil] = useState(false);

  useEffect(() => {}, []);

  //=== Mostrar listado de bancos ===//
  let arrayOptions = [];
  arrayOptions = listadoBancos.listado.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState(arrayOptions);

  //=== Mostrar listado de bancos Ecuador ===//
  let arrayOptionsEcu = [];
  arrayOptionsEcu = listadoBancosEcu.bancosEcuador.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionEcu, setOptionEcu] = useState(arrayOptionsEcu);

  //=== Mostrar listado de bancos Colombia ===//
  let arrayOptionsCol = [];
  arrayOptionsCol = listadoBancosCol.bancosColombia.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionCol, setOptionCol] = useState(arrayOptionsCol);

  //=== Mostrar listado de bancos Panama ===//
  let arrayOptionsPan = [];
  arrayOptionsPan = listadoBancosPan.bancosPanama.map((item) => {
    return { value: item.codigo, label: item.nombre };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionPan, setOptionPan] = useState(arrayOptionsPan);

  // bancosPanama.sort((a, b) => a.nombre.localeCompare(b.nombre));

  // Mostrar métodos de pago
  let arrayMetodos = [];
  arrayMetodos = metodosPagos.metodosPago.map((item) => {
    return { value: item.codigo, label: item.metodo };
  });
  // eslint-disable-next-line no-unused-vars
  const [optionMetodos, setOptionMetodos] = useState(arrayMetodos);
  const [optionTipo, setOptionTipo] = useState([
    { value: 'Ahorro', label: 'Cuenta Ahorro' },
    { value: 'Corriente', label: 'Cuenta Corriente' },
  ]);

  //Capturar en el input banco seleccionado
  const [defaultValue, setDefaultValue] = useState({
    value: '',
    label: '',
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //========== Contenido Acordion ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      fontSize: theme.typography.pxToRem(14),
      width: '100%',
    },
  }));
  const classes = useStyles();

  //========== Funciones y metodos Cuentas ==========//
  const addCuenta = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: app.auth().currentUser.uid,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Venezuela',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuenta(true));
  };
  const addCuentaEcu = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: app.auth().currentUser.uid,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Ecuador',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuentaEcu(true));
  };
  const addCuentaPan = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: app.auth().currentUser.uid,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Panama',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuentaPan(true));
  };
  const addCuentaCol = () => {
    setEditar(false);
    setCtabancaria({
      banco: '',
      cedula: '',
      idUsuario: app.auth().currentUser.uid,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Colombia',
      telefono: '',
      tipoCuenta: '',
      titular: '',
    });
    dispatch(modalCuentaCol(true));
  };

  //========== Funciones Modal Cuentas ==========//
  const changeBanco = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['numeroCuenta']: e.value,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeTipo = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['tipoCuenta']: e.value,
    });
  };
  const changeMetodos = (e) => {
    setDefaultValue(e.label);
    const label = e.label;
    setGenerales({
      ...generales,
      ['metodosPagos']: label,
    });
  };
  const changeBancoEcu = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeBancoCol = (e) => {
    setDefaultValue(e);
    setCtabancaria({
      ...ctabancaria,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };
  const changeValueCuentas = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'banco') {
      let index = e.target.selectedIndex;
      setCtabancaria({
        ...ctabancaria,
        [name]: value,
        ['nombreBanco']: e.target.options[index].text,
        // ["numeroCuenta"]: value
      });
    } else {
      setCtabancaria({ ...ctabancaria, [name]: value });
    }
  };
  const close = () => {
    dispatch(modalCuenta(false));
  };
  const cancel = () => {
    dispatch(modalCuenta(false));
  };
  const guardarCuenta = () => {
    if (
      // ctabancaria.numeroCuenta.length !== 20 ||
      ctabancaria.nombreBanco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
      }
      // eslint-disable-next-line no-unused-expressions
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const editarCuenta = (item) => {
    setEditar(true);
    setCtabancaria(item);
    setDefaultValue(item.nombreBanco);
    switch (item.pais) {
      case 'Venezuela':
        return dispatch(modalCuenta(true));
      case 'Ecuador':
        return dispatch(modalCuentaEcu(true));
      case 'Panama':
        return dispatch(modalCuentaPan(true));
      default:
        return dispatch(modalCuenta(true));
    }
  };
  const eliminarCuenta = (item) => {
    dispatch(deleteCuentaBancaria(item));
  };
  const seleccionarCuenta = (item) => {
    if (selectedCuenta) {
      if (selectedCuenta.id === item.id) {
        dispatch(changeSelected(null));
      } else {
        dispatch(changeSelected(item));
      }
    } else {
      dispatch(changeSelected(item));
    }
  };

  //===== Pago movil =====//
  const changeValuePagoMovil = (e) => {
    const { name, value } = e.target;
    if (e.target.name === 'banco') {
      let index = e.target.selectedIndex;
      setPgoMovil({
        ...pgoMovil,
        [name]: value,
        ['nombreBanco']: e.target.options[index].text,
      });
    } else {
      setPgoMovil({ ...pgoMovil, [name]: value });
    }
  };
  const closePagoMovil = () => {
    dispatch(ModalPago(false));
  };
  const cancelPagoMovil = () => {
    dispatch(ModalPago(false));
  };
  const guardarPagoMovil = () => {
    if (
      pgoMovil.banco === '' ||
      pgoMovil.cedula === '' ||
      pgoMovil.telefono === '' ||
      pgoMovil.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editPgoMovil) {
        dispatch(updatePgoMovil(pgoMovil));
      } else {
        dispatch(savePagoMovil(pgoMovil));
      }
      // eslint-disable-next-line no-unused-expressions
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const addPagoMovil = () => {
    setEditPgoMovil(false);
    setPgoMovil({
      banco: '',
      cedula: '',
      idUsuario: app.auth().currentUser.uid,
      fechaInclusion: moment(new Date()).format('YYYY-MM-DD'),
      nombreBanco: '',
      numeroCuenta: '',
      pais: 'Venezuela',
      telefono: '',
      titular: '',
    });
    dispatch(ModalPago(true));
  };
  const editarPgoMovil = (item) => {
    setEditPgoMovil(true);
    setPgoMovil(item);
    dispatch(ModalPago(true));
  };
  const eliminarPgoMovil = (item) => {
    dispatch(deletePgoMovil(item));
  };
  const seleccionarPgoMovil = (item) => {
    if (selectedCuenta) {
      if (selectedCuenta.id === item.id) {
        dispatch(changeSelected(null));
      } else {
        dispatch(changeSelected(item));
      }
    } else {
      dispatch(changeSelected(item));
    }
  };
  const changePagoMovil = (e) => {
    setDefaultValue(e);
    console.log('E', e);
    setPgoMovil({
      ...pgoMovil,
      ['numeroCuenta']: e.value,
      ['banco']: e.value,
      ['nombreBanco']: e.label,
    });
  };

  //========== Funciones Modal Cuenta Ecuador ==========//
  const guardarCuentaEcu = () => {
    if (
      ctabancaria.banco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
      }
    }
    dispatch(modalCuentaEcu(false));
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const closeCtaEcu = () => {
    dispatch(modalCuentaEcu(false));
  };
  const cancelCtaEcu = () => {
    dispatch(modalCuentaEcu(false));
  };

  //========== Funciones Modal Cuenta Colombia ==========//
  const guardarCuentaCol = () => {
    if (
      ctabancaria.banco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
      }
    }
    dispatch(modalCuentaCol(false));
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const closeCtaCol = () => {
    dispatch(modalCuentaCol(false));
  };
  const cancelCtaCol = () => {
    dispatch(modalCuentaCol(false));
  };

  //========== Funciones Modal Cuenta Panama ==========//
  const guardarCuentaPan = () => {
    if (
      ctabancaria.banco === '' ||
      ctabancaria.cedula === '' ||
      ctabancaria.titular === ''
    ) {
      custom('Debe ingresar todos los datos correctamente', 'error');
    } else {
      if (editar) {
        dispatch(updateCuentaBancaria(ctabancaria));
      } else {
        dispatch(saveCuentaBancaria(ctabancaria));
      }
    }
    dispatch(modalCuentaPan(false));
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const closeCtaPan = () => {
    dispatch(modalCuentaPan(false));
  };
  const cancelCtaPan = () => {
    dispatch(modalCuentaPan(false));
  };

  //========== Funciones Modal MetodoPago ==========//
  const closeModalMetodoPago = () => {
    dispatch(modalMetodoPago(false));
  };
  const cancelModalMetodoPago = () => {
    dispatch(modalMetodoPago(false));
  };

  const guardarMetodoPago = () => {
    // let label = bancosMetPagos;
    // let dataUpdate = { ...generales, ["metodosPagos"]: label };
    // setGenerales(dataUpdate);
    // dispatch({
    //   type: DATOSGENERELES,
    //   payload: { ...generales, ["metodosPagos"]: label },
    // });
    // console.log("Data", dataUpdate);
    // eslint-disable-next-line no-unused-expressions
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(modalMetodoPago(false));
  };

  return (
    <>
      <h2 className='font-bold text-base text-center my-3 bg-theme-26 box p-2 w-325 mx-auto border-b-2'>
        Datos bancos por país
        {/* <AddToPhotosIcon color="primary" fontSize="small" /> */}
      </h2>
      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-6'>
        <div className='intro-y col-span-12 sm:col-span-6'>
          <div className={classes.root}>
            {/*===== Bancos Venezuela =====*/}
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.heading}>
                  <label className='font-medium text-sm px-2 py-1 pr-16 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200 max-w-full'>
                    <AccountBalanceIcon color='primary' fontSize='small' /> ._
                    Bancos Venezuela
                  </label>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='flex flex-col'>
                  {/* TRANSFERENCIA */}
                  <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                    <div className='flex justify-between w-full pr-2'>
                      <h2 className='font-medium text-sm mx-3'>
                        Transferencia Bancaria
                      </h2>
                      <button onClick={addCuenta}>
                        <Icon.Plus
                          className='text-theme-10 tooltip'
                          title='Registrar'
                        />
                      </button>
                    </div>
                  </div>

                  {/* PAGO MOVIL */}
                  <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box ml-5'>
                    <div className='flex justify-between w-full pr-2'>
                      <h2 className='font-medium text-sm mx-3'>Pago movil</h2>
                      <button onClick={addPagoMovil}>
                        <Icon.Plus
                          className='text-theme-10 tooltip'
                          title='Registrar'
                        />
                      </button>
                    </div>
                    {/* <div className="grid grid-cols-12 gap-4 row-gap-3 mt-2">
                      {listadoPagosMoviles.map((item, i) => (
                        <div
                          className="col-span-12 sm:col-span-6"
                          key={"pago" + i}
                        >
                          <CardCuentasBancarias
                            item={item}
                            tipo="pago movil"
                            editar={(e) => editarPgoMovil(item)}
                            eliminar={(e) => eliminarPgoMovil(item)}
                            seleccionar={(e) => seleccionarPgoMovil(item)}
                          />
                        </div>
                      ))}
                    </div> */}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/*===== Bancos Panama =====*/}
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography className={classes.heading}>
                  <label className='font-medium text-sm px-2 py-1 pr-20 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                    <AccountBalanceIcon color='primary' fontSize='small' /> ._
                    Bancos Panamá
                  </label>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* TRANSFERENCIA */}
                <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                  <div className='flex justify-between w-full pr-2'>
                    <h2 className='font-medium text-sm mx-3'>
                      Transferencia Bancaria
                    </h2>
                    <button onClick={addCuentaPan}>
                      <Icon.Plus
                        className='text-theme-10 tooltip'
                        title='Registrar'
                      />
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {/*===== Bancos Argentina =====*/}
            {/* <Accordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography className={classes.heading}>
                  <label className='font-medium text-sm px-2 py-1 pr-16 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                    <AccountBalanceIcon color='primary' fontSize='small' /> ._
                    Bancos Argentina
                  </label>
                </Typography>
              </AccordionSummary>
              <AccordionDetails> */}
            {/* TRANSFERENCIA */}
            {/* <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                  <div className='flex justify-between w-full pr-2'>
                    <h2 className='font-medium text-sm mx-3'>
                      Transferencia Bancaria
                    </h2>
                    <button
                    //  onClick={addCuenta}
                    >
                      <Icon.Plus
                        className='text-theme-10 tooltip'
                        title='Registrar'
                      />
                    </button>
                  </div>
                </div> */}
            {/* </AccordionDetails> */}
            {/* </Accordion> */}
          </div>
        </div>

        <div className='intro-y col-span-12 sm:col-span-6'>
          <div className={classes.root}>
            {/*===== Bancos Chile =====*/}
            {/* <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.heading}>
                  <label className='font-medium text-sm px-2 py-1 pr-24 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                    <AccountBalanceIcon color='primary' fontSize='small' /> ._
                    Bancos Chile
                  </label>
                </Typography>
              </AccordionSummary>
              <AccordionDetails> */}
            {/* TRANSFERENCIA */}
            {/* <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                  <div className='flex justify-between w-full pr-2'>
                    <h2 className='font-medium text-sm mx-3'>
                      Transferencia Bancaria
                    </h2>
                    <button
                    //  onClick={addCuenta}
                    >
                      <Icon.Plus
                        className='text-theme-10 tooltip'
                        title='Registrar'
                      />
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion> */}

            {/*===== Bancos Colombia =====*/}
            <Accordion
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography className={classes.heading}>
                  <label className='font-medium text-sm px-2 py-1 pr-16 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                    <AccountBalanceIcon color='primary' fontSize='small' /> ._
                    Bancos Colombia
                  </label>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* TRANSFERENCIA */}
                <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                  <div className='flex justify-between w-full pr-2'>
                    <h2 className='font-medium text-sm mx-3'>
                      Transferencia Bancaria
                    </h2>
                    <button onClick={addCuentaCol}>
                      <Icon.Plus
                        className='text-theme-10 tooltip'
                        title='Registrar'
                      />
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {/*===== Bancos Ecuador =====*/}
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography className={classes.heading}>
                  <label className='font-medium text-sm px-2 py-1 pr-20 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                    <AccountBalanceIcon color='primary' fontSize='small' /> ._
                    Bancos Ecuador
                  </label>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* TRANSFERENCIA */}
                <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                  <div className='flex justify-between w-full pr-2'>
                    <h2 className='font-medium text-sm mx-3'>
                      Transferencia Bancaria
                    </h2>
                    <button onClick={addCuentaEcu}>
                      {/*<i data-feather="plus" className="text-theme-10 tooltip" title="Registrar"></i>*/}
                      <Icon.Plus
                        className='text-theme-10 tooltip'
                        title='Registrar'
                      />
                    </button>
                  </div>
                  {/* <div className="grid grid-cols-12 gap-4 row-gap-3 mt-2">
                        {cuentas.map((item, i) => (
                          <div
                            className="col-span-12 sm:col-span-6"
                            key={"cuenta" + i}
                          >
                            <CardCuentasBancarias
                              item={item}
                              tipo="cuenta"
                              editar={(e) => editarCuenta(item)}
                              eliminar={(e) => eliminarCuenta(item)}
                              seleccionar={(e) => seleccionarCuenta(item)}
                            />
                          </div>
                        ))}
                      </div> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>

      {/* Tarjeta Cuentas y Pago Movil Registrada */}
      <div ref={ref} className='grid grid-cols-12 gap-4 row-gap-3 my-10'>
        {cuentas.map((item, i) => (
          <div
            id='cuentas'
            className='col-span-12 sm:col-span-3'
            key={'cuenta' + i}
          >
            <CardCuentasBancarias
              item={item}
              tipo='cuenta'
              editar={(e) => editarCuenta(item)}
              eliminar={(e) => eliminarCuenta(item)}
              seleccionar={(e) => seleccionarCuenta(item)}
            />
          </div>
        ))}
        {listadoPagosMoviles.map((item, i) => (
          <div className='col-span-12 sm:col-span-3 mx-3' key={'pago' + i}>
            <CardCuentasBancarias
              item={item}
              tipo='pago movil'
              editar={(e) => editarPgoMovil(item)}
              eliminar={(e) => eliminarPgoMovil(item)}
              seleccionar={(e) => seleccionarPgoMovil(item)}
            />
          </div>
        ))}
      </div>
      {selectedCuenta && (
        <div className='grid grid-cols-12 gap-4 row-gap-3 mb-6 mt-2'>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <div className='flex justify-center items-center flex-col'>
              <h2 className='font-medium text-base mb-2 bg-theme-2 box p-2'>
                Datos Para
                {selectedCuenta.numeroCuenta === ''
                  ? ' _ el Pago Móvil'
                  : ' _ la Transferencia Bancaria'}
              </h2>
              <p className='text-base'>
                {selectedCuenta.numeroCuenta === ''
                  ? 'Banco: '
                  : 'N° de Cuenta: '}
                {selectedCuenta.numeroCuenta === ''
                  ? selectedCuenta.banco
                  : selectedCuenta.numeroCuenta}
              </p>
              <p className='text-base'>Cédula: {selectedCuenta.cedula}</p>
              <p className='text-base'>Titular: {selectedCuenta.titular}</p>
              <p className='text-base'>Tipo: {selectedCuenta.tipoCuenta}</p>
              <p className='text-base'>País: {selectedCuenta.pais}</p>
            </div>
          </div>
          {generales.metodosPagos === 'Venezuela-Transferencia' && (
            <div className='intro-y col-span-12 sm:col-span-12 mt-2'>
              <div className='flex justify-center items-center flex-col'>
                <h2 className='font-medium text-yellow-600 text-base mb-2 box bg-yellow-200 p-1'>
                  Ahora seleccione en donde usted desea devolver su préstamo.
                </h2>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Donde y Como  Poder Pagar */}
      <div className='intro-y col-span-12 sm:col-span-12 my-4 mt-2'>
        <div className='flex flex-col sm:flex-row items-center border-b'>
          <h2 className='font-medium text-base mr-auto mb-2'>
            ¿Dónde deseas pagar el préstamo? Elegir método de pago.
          </h2>
        </div>
      </div>

      <div className='intro-y col-span-12 sm:col-span-12 mb-6'>
        <div className={classes.root}>
          {/*===== Bancos de Pagos =====*/}
          <Accordion
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color='primary' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.heading}>
                <label className='font-medium text-sm px-2 py-1 pr-16 border-b-2 bg-theme-26 box ml-2 hover:text-blue-700 hover:bg-indigo-200'>
                  <AccountBalanceIcon color='primary' fontSize='small' /> ._
                  Opciones de pago
                </label>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='col-span-12 sm:col-span-12 border border-blue-100 hover:border-blue-400 box mb-2 ml-5'>
                <div className='flex justify-between w-full pr-2'>
                  <h2 className='font-medium text-sm mx-3'>
                    Métodos disponibles
                  </h2>
                  <button onClick={() => dispatch(modalMetodoPago(true))}>
                    <Icon.Plus
                      className='text-theme-10 tooltip'
                      title='Registrar'
                    />
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        {generales.metodosPagos !== 'Venezuela-Transferencia' && (
          <div className='intro-y col-span-12 sm:col-span-12 mt-3'>
            <div className='flex justify-center items-center flex-col'>
              <h2 className='font-medium text-gray-800 text-base my-2'>
                Método de pago seleccionado : {generales.metodosPagos}
              </h2>
            </div>
            <div className='flex justify-center items-center flex-col'>
              <h2 className='font-medium text-green-600 text-base mb-2 box bg-green-100 p-1'>
                Presione siguiente para continuar con su solicitud de préstamo.
              </h2>
            </div>
          </div>
        )}
      </div>

      {/* Modal Venezuela */}
      {modalCuentaBanacaria && (
        <Modal
          title={editar ? 'Editar Cuenta' : 'Registrar Cuenta'}
          close={close}
          save={guardarCuenta}
          cancel={cancel}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={option}
              onChange={changeBanco}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              maxLength='20'
              value={ctabancaria.numeroCuenta}
              placeholder='00000000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              maxLength='8'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {modalPagoMovil && (
        <Modal
          title='Registrar Pago Movil'
          close={closePagoMovil}
          save={guardarPagoMovil}
          cancel={cancelPagoMovil}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='banco'
              autoFocus={true}
              placeholder={
                editar ? pgoMovil.nombreBanco : 'Seleccione el banco'
              }
              options={option}
              onChange={changePagoMovil}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>
              Teléfono :
            </label>
            <input
              type='text'
              name='telefono'
              maxLength='12'
              value={pgoMovil.telefono}
              placeholder=' 0000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValuePagoMovil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              maxLength='8'
              value={pgoMovil.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValuePagoMovil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={pgoMovil.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValuePagoMovil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </Modal>
      )}
      {modalCuentaBcoEcu && (
        <Modal
          title={editar ? 'Editar Cuenta Ecuador' : 'Registrar Cuenta Ecuador'}
          close={closeCtaEcu}
          save={guardarCuentaEcu}
          cancel={cancelCtaEcu}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={optionEcu}
              onChange={changeBancoEcu}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              value={ctabancaria.numeroCuenta}
              placeholder='0000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {modalCuentaBcoCol && (
        <Modal
          title={
            editar ? 'Editar Cuenta Colombia' : 'Registrar Cuenta Colombia'
          }
          close={closeCtaCol}
          save={guardarCuentaCol}
          cancel={cancelCtaCol}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={optionCol}
              onChange={changeBancoCol}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              value={ctabancaria.numeroCuenta}
              placeholder='0000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {modalCuentaBcoPan && (
        <Modal
          title={editar ? 'Editar Cuenta Panama' : 'Registrar Cuenta Panama'}
          close={closeCtaPan}
          save={guardarCuentaPan}
          cancel={cancelCtaPan}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Banco :</label>
            <Select
              className='mt-1'
              name='nombreBanco'
              autoFocus={true}
              placeholder={
                editar ? ctabancaria.nombreBanco : 'Seleccione el banco'
              }
              options={optionPan}
              onChange={changeBancoEcu}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Número de cuenta :
            </label>
            <input
              type='text'
              name='numeroCuenta'
              value={ctabancaria.numeroCuenta}
              placeholder='0000000000000000'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Titular :</label>
            <input
              type='text'
              name='titular'
              value={ctabancaria.titular}
              placeholder=' Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Cédula :</label>
            <input
              type='text'
              name='cedula'
              value={ctabancaria.cedula}
              placeholder=' Cédula de Beneficiario'
              className='w-full rounded-full border intro-x input input--sm border mt-2'
              onChange={changeValueCuentas}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6 mb-8'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo cuenta :
            </label>
            <Select
              className='mt-1'
              name='tipoCuenta'
              placeholder={
                editar ? ctabancaria.tipoCuenta : 'Seleccione tipo cuenta'
              }
              options={optionTipo}
              onChange={changeTipo}
            />
          </div>
        </Modal>
      )}
      {/*===== Modal pagos =====*/}
      {modalMetodosPagos && (
        <Modal
          title={'Seleccionar su método de pago'}
          close={closeModalMetodoPago}
          save={guardarMetodoPago}
          cancel={cancelModalMetodoPago}
        >
          <div className='intro-y col-span-12 sm:col-span-12 w-full'>
            <label className='font-medium bg-theme-26 box p-1 mb-2'>
              Métodos disponibles :
            </label>
            <Select
              className='mt-2'
              name='metodosPagos'
              placeholder='Haz clic aquí para seleccionar donde quieres devolver tu préstamo'
              autoFocus={true}
              options={optionMetodos}
              onChange={changeMetodos}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AccordionBancos;
