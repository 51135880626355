import React, { useState } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";

import ModalExit from "../ModalExit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ModalAddCode from "./ModalAddCode";

import UserImage from "../../statics/images/profile-2.jpg";

const AccountMenu = () => {
  const [modalExit, setModalexit] = useState(false);
  const [modalCode, setModalCode] = useState(false);

  const myUser = useSelector((state) => state.sesion.myUser);

  const onClose = () => {
    setModalexit(false);
  };

  const handleLogout = () => {
    setModalexit(true);
  };

  let rol = myUser.rol;

  const handleClickDropDown = (event) => {
    let dropdown = $(event.target).closest(".dropdown");
    if (
      !$(dropdown).length ||
      ($(event.target).closest(".dropdown-toggle").length &&
        $(dropdown).find(".dropdown-box").first().hasClass("show"))
    ) {
      $(".dropdown-box").removeClass("show");
    } else {
      $(".dropdown-box").removeClass("show");
      $(dropdown).find(".dropdown-box").first().addClass("show");
    }
  };

  return (
    <div className="intro-x dropdown w-8 h-8 relative mr-3 mb-2">
      <ModalExit open={modalExit} onClose={onClose} />
      <ModalAddCode
        open={modalCode}
        setModalCode={setModalCode}
        code={myUser.code}
        email={myUser.email}
      />
      <div
        className="dropdown-toggle w-10 h-10 rounded-full overflow-hidden shadow-lg image-fit zoom-in ml-2"
        id="dropdownMenuButton"
        onClick={handleClickDropDown}
      >
        <img
          alt={myUser.userName}
          src={myUser.urlImagen ? myUser.urlImagen : UserImage}
        />
      </div>

      <div className="dropdown-box mt-10 absolute w-56 top-0 sm:right-0 z-20">
        <div className="dropdown-box__content box bg-theme-38 text-white">
          <div className="p-4 border-b border-theme-40">
            <div className="font-medium">
              {myUser.nombres === ""
                ? myUser.email.split("@")[0]
                : myUser.nombres}
            </div>
            <div className="text-xs text-theme-41">{rol}</div>
          </div>

          <div className="p-2 border-t border-theme-40">
            <div
              className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md cursor-pointer"
              onClick={handleLogout}
            >
              <ExitToAppIcon className="w-4 h-4 mr-2" /> Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMenu;
