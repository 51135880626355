import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { app } from '../../../config/firebaseConfig';

import * as Icon from 'react-feather';
import feather from 'feather-icons';
import Modal from '../../Modal';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { GET_USERS } from '../../../redux/types';
import { addLog } from '../../../redux/actions/sesionAction';
import { getUsers } from '../../../redux/actions/superAdminActions';
import { getListPrestamos } from '../../../redux/actions/gestorActions';
import { success, confirm } from '../../../helpers/alertas';
import { deleteUsuario } from '../../../redux/actions/adminAction';

const optionsExport = {
  filename: 'Datos Usuarios',
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Datos Usuarios sin préstamo',
  useTextFile: false,
  useBom: true,
  // useKeysAsHeaders: true,
  headers: ['Correo', 'Fecha Registro', 'Rol'],
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
const optionsExportInic = {
  filename: 'Datos Usuarios Iniciados',
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Datos Usuarios iniciaron sesion.',
  useTextFile: false,
  useBom: true,
  // useKeysAsHeaders: true,
  headers: ['Correos'],
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
const optionsExportWhat = {
  filename: 'Datos Usuarios Whatsapp',
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Datos Usuarios con whatsapp.',
  useTextFile: false,
  useBom: true,
  // useKeysAsHeaders: true,
  headers: ['Nombres', 'Correos', 'País', 'Localidad', 'Whatsapp', 'Rol'],
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
const optionsExportVerify = {
  filename: 'Datos Usuarios Con Verificación',
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Datos Usuarios verifación.',
  useTextFile: false,
  useBom: true,
  // useKeysAsHeaders: true,
  headers: ['Correos', 'IdUsuario', 'Links'],
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const UsiarioSuperAdmin = () => {
  const dispatch = useDispatch();

  //========== USE-STATE ==========//
  const [showModal, setShowModal] = useState(false);
  const [detallesUsuario, setDetallesUsuario] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    email: '',
    fechaNacimiemnto: '',
    fechaRegistro: '',
    geo: '',
    habilitadoNuevoPrestamo: '',
    idUsuario: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: '',
    nombreEmpresa: '',
    nombres: '',
    nuevaTasaInteres: '',
    nuevoLimitePrestamo: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    rol: '',
    sexo: '',
    telefonoOpcional: '',
    telegram: '',
    trabaja: '',
    whatsapp: '',
  });
  const [misPrestamos, setMisPrestamos] = useState([]);
  const [userLog, setUserLog] = useState([]);
  const [emailverify, setEmailverify] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  //========== USE-SELECTORS ==========//
  const usuarios = useSelector((state) => state.superadmin.allUsers);
  const prestamos = useSelector((state) => state.gestor.listPrestamos);
  const listUsuarios = useSelector((state) => state.superadmin.allUsers);
  const myUser = useSelector((state) => state.sesion.myUser);

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getUsers());
    dispatch(getListPrestamos());
    getListUserLog();
    getListEmailVerify();
  }, []);

  //===== Listado Usuarios Logs =====//
  const getListUserLog = () => {
    app
      .firestore()
      .collection('log')
      .get()
      .then((data) => {
        let listUsuariosLogs = [];
        let obj = {};
        data.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          listUsuariosLogs.push(obj);
        });
        setUserLog(listUsuariosLogs);
      });
  };

  //===== Listado emailVerify =====//
  const getListEmailVerify = () => {
    app
      .firestore()
      .collection('emailVerify')
      .get()
      .then((data) => {
        let listEmailVerify = [];
        let obj = {};
        data.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          listEmailVerify.push(obj);
        });
        setEmailverify(listEmailVerify);
      });
  };

  //===== Funciones y Metodos Cambio ROL =====//
  const changeValueCambiarRol = (e, params) => {
    //truco
    let arrayUsers = [...listUsuarios]; //creo un arreglo y que tenga todos los usuarios
    // a dicho arreglo en la posicion segun la fila en que me encuentre
    arrayUsers[params.rowIndex].rol = e.target.value; // params.rowIndex me trae el incide del select que cambie
    dispatch({ type: GET_USERS, payload: arrayUsers }); //actualizo el estado para que se vea instantaneamente

    app
      .firestore()
      .collection('usuarios')
      .doc(`${params.row.id}`)
      .update({
        //hago el update
        rol: e.target.value,
      })
      .then((res) => {
        dispatch(getUsers());
        dispatch(
          addLog({
            accion: `${'Cambiado el Rol a: '}  ${
              params.row.rol
            } ${', Del Usuario : '} ${params.row.nombres} ${
              params.row.apellidos
            }`,
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            usuario: myUser.email,
          })
        );
      });
  };

  //===== Funciones y Metodos Modal Usuarios =====//
  const VerDatosUsuarios = (detallesUsuario) => {
    setDetallesUsuario({
      apellidos: detallesUsuario.apellidos,
      areaTrabajo: detallesUsuario.areaTrabajo,
      codigoPais: detallesUsuario.codigoPais,
      descripcionActividad: detallesUsuario.descripcionActividad,
      email: detallesUsuario.email,
      fechaNacimiemnto: detallesUsuario.fechaNacimiento,
      fechaRegistro: detallesUsuario.fechaRegistro,
      geo: detallesUsuario.geo,
      idUsuario: detallesUsuario.idUsuario,
      ingresoMensualUSD: detallesUsuario.ingresoMensualUSD,
      localidad: detallesUsuario.localidad,
      metodosPagos: detallesUsuario.metodosPagos,
      nombreEmpresa: detallesUsuario.nombreEmpresa,
      nombres: detallesUsuario.nombres,
      numeroCedula: detallesUsuario.numeroCedula,
      numeroPasaporte: detallesUsuario.numeroPasaporte,
      pais: detallesUsuario.pais,
      rol: detallesUsuario.rol,
      sexo: detallesUsuario.sexo,
      telefonoOpcional: detallesUsuario.telefonoOpcional,
      telegram: detallesUsuario.telegram,
      trabaja: detallesUsuario.trabaja,
      whatsapp: detallesUsuario.whatsapp,
    });
    let results = prestamos.filter(
      (item) => item.usuario.idUsuario === detallesUsuario.id
    );
    setMisPrestamos(results);
    setShowModal(true);
  };
  const elimiarUsuario = async (params) => {
    const resp = await confirm(
      'Seguro que Desea Eliminar el usuario.',
      'question'
    );
    if (resp) {
      dispatch(deleteUsuario(params));
      // dispatch(
      //   addLog({
      //     accion: `${'Se ha elimidado el usuario: De Nombre '} ${
      //       params.nombreMensaje
      //     }`,
      //     fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      //     usuario: myUser.email,
      //   })
      // );
      success('Usuario Eliminado Exitosamente.', 'success');
    }
  };
  const close = () => {
    setShowModal(false);
  };
  const cancel = () => {
    setShowModal(false);
  };

  //===== Contenido Tabla Prestamos =====//
  const columns1 = [
    {
      field: 'nombre',
      headerName: 'NOMBRES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 240,
      valueGetter: (params) => {
        return `${params.row.nombres} ${params.row.apellidos}`;
      },
    },
    {
      field: 'email',
      headerName: 'CORREO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 280,
    },
    {
      field: 'pais',
      headerName: 'PAIS',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 120,
    },
    {
      field: 'rol',
      headerName: 'ROL',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 212,
      renderCell: (params) =>
        params.row.rol !== 'SuperAdmin' ? (
          <div className='flex col-span-12 sm:col-span-6'>
            <Icon.UserCheck className='w-5 h-5 mr-3 ml-3 text-theme-1 mt-2' />
            <select
              type='text'
              name='rol'
              className='rounded-full border border-theme-27 py-2 px-5'
              aria-label='Default select example'
              value={params.value}
              onChange={(e) => changeValueCambiarRol(e, params)}
            >
              <option value='Usuario'> Usuario</option>
              <option value='Gestor'> Gestor</option>
              <option value='Admin'> Admin</option>
              <option value='SuperAdmin'> SuperAdmin</option>
              <option value='Bloqueado'> Bloqueado</option>
            </select>
          </div>
        ) : (
          <div className='relative'>
            <Icon.UserCheck className='absolute w-5 h-5 mr-3 ml-3 text-theme-1 mt-3' />
            <span className='rounded-full border border-theme-27 py-2 px-8 font-medium ml-10 text-theme-3'>
              SuperAdmin
            </span>
          </div>
        ),
    },
    {
      field: 'usuarios',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 212,
      renderCell: (params) => (
        <div className='flex justify-center items-center'>
          <Link
            className='flex items-center mr-3 text-theme-12'
            to='#'
            onClick={() => VerDatosUsuarios(params.row)}
          >
            <Icon.Search className='w-5 h-5 mr-2 ml-2' />
            Ver Datos
          </Link>
          <Link
            className='flex items-center ml-6 text-theme-6'
            to='#'
            onClick={(e) => elimiarUsuario(params.row)}
          >
            <Icon.Trash2 className='w-6 h-5' />
          </Link>
        </div>
      ),
    },
  ];

  //===== Contenido Tabla Modal Mis Prestamos =====//
  const columns = [
    {
      field: 'montoPrestado',
      headerName: 'Prestado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 115,
    },
    {
      field: 'fechaInicio',
      headerName: 'Fecha/Inicio',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 135,
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'Fecha/Pago',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 130,
    },
    {
      field: 'montoRealRecibido',
      headerName: 'Monto/Pago',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 135,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 110,
    },
    {
      field: 'pagado',
      headerName: 'Pagado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 105,
      valueGetter: (params) => {
        return `${params.row.pagado === true ? 'Si' : 'No'}`;
      },
    },
    {
      field: 'habilitadoNuevoPrestamo',
      headerName: 'Habilitado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 120,
      valueGetter: (params) => {
        return `${params.row.usuario.habilitadoNuevoPrestamo}`;
      },
    },
  ];

  //===== Funciones y Metodos de la Tabla de Mis Prestamos =====//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    setPageCount(pagination.pageCount);
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.page);
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  //====== Funciones y Metodos de Exportar ======//
  const exportar = (e) => {
    const csvExporter = new ExportToCsv(optionsExport);
    let filterUsuario = usuarios.filter((items) => items.nombres === '');
    const dataExportar = filterUsuario.map((user) => {
      return {
        email: user.email,
        fechaRegistro: user.fechaRegistro,
        rol: user.rol,
      };
    });
    csvExporter.generateCsv(dataExportar);
  };
  const exportarIniciados = () => {
    const csvExporter = new ExportToCsv(optionsExportInic);
    let emailUser = [];
    let results = userLog.filter((item) => {
      let arrayAccion = item.accion;
      let search = 'A Iniciado Sesion.';
      if (arrayAccion.includes(search)) {
        emailUser.push(item.usuario);
      }
      return emailUser;
    });
    const dataExportar = emailUser.map((user) => {
      return {
        email: user,
      };
    });
    csvExporter.generateCsv(dataExportar);
  };
  const exportarWhatsapp = () => {
    const csvExporter = new ExportToCsv(optionsExportWhat);
    let filterUsuario = usuarios.filter((items) => items.whatsapp !== '');
    const dataExportar = filterUsuario.map((user) => {
      return {
        nombres: user.nombres + ' ' + user.apellidos,
        email: user.email,
        pais: user.pais,
        localidad: user.localidad,
        whatsapp: user.codigoPais + ' ' + user.whatsapp,
        rol: user.rol,
      };
    });
    csvExporter.generateCsv(dataExportar);
  };
  const exportarNoVerify = () => {
    const csvExporter = new ExportToCsv(optionsExportVerify);
    // let correosFinales = [];
    // arrayCorreos.forEach((corr) => {
    //   const result = usuarios.find((user) => user.email === corr.email);
    //   if (result) {
    //     correosFinales.push({
    //       ...corr,
    //       idUsuario: result.idUsuario,
    //     });
    //   }
    // });
    let correosUserLink = [];
    usuarios.forEach((corr) => {
      const result = emailverify.find((user) => user.uid === corr.idUsuario);
      if (result) {
        correosUserLink.push({ ...corr, linkVerify: result.id });
      }
    });
    const dataExportar = correosUserLink.map((user) => {
      return {
        email: user.email,
        idUsuario: user.idUsuario,
        link: user.linkVerify,
      };
    });
    csvExporter.generateCsv(dataExportar);
  };

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>Listado de Usuarios</h2>
        </div>
        <div className='col-span-12 box bg-theme-2 p-1'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 620, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={usuarios}
                columns={columns1}
                pageSize={100}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
          </div>
        </div>
        <div className='flex justify-between content-center'>
          <div className='font-bold mt-3'>
            {pageCount >= currentPage &&
              `Mostrando ${currentPage} - ${
                pageSize >= listUsuarios.length ? listUsuarios.length : pageSize
              } de ${listUsuarios.length} Usuarios`}
          </div>
          <div className='bg-theme-23 box p-2 text-center font-semibold mt-3'>
            <label>
              Usuarios Registrados :
              <span className='rounded-lg bg-theme-3 text-white py-1 px-3 ml-1'>
                {listUsuarios.length}
              </span>
            </label>
          </div>
        </div>
        <div className='flex justify-around mt-5'>
          {/* Boton Exportar*/}
          <div className='cursor-pointer'>
            <button
              className='flex box text-white bg-theme-3 p-2 items-center'
              onClick={exportar}
            >
              <Icon.Upload className='w-4 h-4 mr-1' />
              <p>Exportar Usuarios</p>
            </button>
          </div>
          <div className='cursor-pointer'>
            <button
              className='flex box text-white bg-theme-9 p-2 items-center'
              onClick={exportarIniciados}
            >
              <Icon.Upload className='w-4 h-4 mr-1' />
              <p>Usuarios Iniciados</p>
            </button>
          </div>
          <div className='cursor-pointer'>
            <button
              className='flex box text-white bg-green-800 p-2 items-center'
              onClick={exportarWhatsapp}
            >
              <Icon.List className='w-4 h-4 mr-1' />
              <p>Listado whatsapp</p>
            </button>
          </div>
          <div className='cursor-pointer'>
            <button
              className='flex box text-white bg-theme-11 p-2 items-center'
              onClick={exportarNoVerify}
            >
              <Icon.Upload className='w-4 h-4 mr-1' />
              <p>Usuarios Con Verificación</p>
            </button>
          </div>
        </div>
      </div>

      {/* Contenido Modal */}
      {showModal && (
        <Modal
          title='Datos Usuario'
          close={close}
          cancel={cancel}
          customBtn={true}
          textcustomBtn='Aceptar'
          save={() => {
            setShowModal(false);
          }}
          size='xl'
        >
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Nombres :
            </label>
            <span>{detallesUsuario.nombres}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Apellidos :
            </label>
            <span>{detallesUsuario.apellidos}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Correo :
            </label>
            <span>{detallesUsuario.email}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Cédula :
            </label>
            <span>{detallesUsuario.numeroCedula}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Pasaporte :
            </label>
            <span>{detallesUsuario.numeroPasaporte}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Fecha de nacimiento :
            </label>
            <span>{detallesUsuario.fechaNacimiemnto}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Sexo :
            </label>
            <span>{detallesUsuario.sexo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              País :
            </label>
            <span>{detallesUsuario.pais}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Localidad :
            </label>
            <span>{detallesUsuario.localidad}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Whatsapp activo :
            </label>
            <span>{detallesUsuario.whatsapp}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Otro número de contacto :
            </label>
            <span>{detallesUsuario.telefonoOpcional}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Usuario telegram :
            </label>
            <span>{detallesUsuario.telegram}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Trabaja :
            </label>
            <span>{detallesUsuario.trabaja}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Area de trabajo :
            </label>
            <span>{detallesUsuario.areaTrabajo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Nombre empresa :
            </label>
            <span>{detallesUsuario.nombreEmpresa}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Ingreso mensual :
            </label>
            <span>{detallesUsuario.ingresoMensualUSD}</span>
            <span className='input-group-text ml-1'>(USD)</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box mr-1'>
              Metodo Pago :
            </label>
            <span>{detallesUsuario.metodosPagos}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box mr-1'>
              Fecha registro :
            </label>
            <span>{detallesUsuario.fechaRegistro}</span>
          </div>
          <div className='col-span-12 sm:col-span-12 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box mr-1'>
              Descripón actividad :
            </label>
            <span>{detallesUsuario.descripcionActividad}</span>
          </div>
          <div className='col-span-12 text-center mb-2'>
            <label className='font-medium p-1 bg-theme-26 box mr-1'>
              ID Usuario:
            </label>
            <span>{detallesUsuario.idUsuario}</span>
          </div>

          <div className='col-span-12 box bg-theme-2 p-1'>
            <div className='flex items-center mt-2 mb-1 col-span-12 sm:col-span-4'>
              <h3 className='font-medium m-auto p-1 bg-theme-26 box w-full'>
                Prestamos solicitados :
              </h3>
            </div>
            <div className='col-span-12 overflow-auto lg:overflow-visible'>
              <div
                style={{ height: 260, width: '100%' }}
                className={classes.root}
              >
                <DataGrid
                  rows={misPrestamos}
                  columns={columns}
                  pageSize={3}
                  autoHeight={true}
                  components={{
                    pagination: CustomPagination,
                  }}
                  localeText={{
                    columnMenuLabel: 'Menu',
                    columnMenuShowColumns: 'Show columns',
                    columnMenuFilter: 'Filtrar',
                    columnMenuSortAsc: 'Ordenar Ascendente',
                    columnMenuSortDesc: 'Ordenar Descendente',
                    columnMenuHideColumn: '',
                    columnMenuUnsort: '',
                    footerRowSelected: (count) => count !== 1,
                    //   ? `${count.toLocaleString()} Cambiando Rol`
                    //   : `${count.toLocaleString()} Cambiando Rol`,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UsiarioSuperAdmin;
