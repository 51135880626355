import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Icon from 'react-feather';
import moment from 'moment';
import Chat from '../../chat/Chat';

import {
  calCulardiasTranscurridos,
  calculateInteres,
  restaFechas,
} from '../../../helpers/generales';

import {
  getPrestamo,
  getChat,
  getContactoSociales,
  getAbonosRealizados,
} from '../../../redux/actions/gestorActions';

export const GestionarCobranza = () => {
  let { userid } = useParams();
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const chat = useSelector((state) => state.gestor.chat);
  const prestamo = useSelector((state) => state.gestor.prestamo);
  const contactosSociales = useSelector(
    (state) => state.gestor.contactosSociales
  );
  const abonosRealizados = useSelector(
    (state) => state.gestor.abonosRealizados
  );

  //========== USE-Effect ==========//
  useEffect(() => {
    dispatch(getChat(userid));
    dispatch(getPrestamo(userid));
    dispatch(getContactoSociales(userid));
    dispatch(getAbonosRealizados(userid));
  }, []);

  const colorBadge = (fechaCompromisoPago) => {
    let NowMoment = moment(new Date()).format('YYYY-MM-DD');
    let fecha1 = moment(NowMoment);
    let fecha2 = moment(fechaCompromisoPago);
    let diff = fecha2.diff(fecha1, 'days');

    switch (fechaCompromisoPago !== diff) {
      case diff <= 0:
        return 'badge text-theme-6';
      case diff === 1:
        return 'badge text-theme-36';
      case diff === 2:
        return 'badge  text-theme-12';
      case diff >= 3:
        return 'badge text-theme-9';
      default:
        return 'badge text-theme-1';
    }
  };

  const calcularInteresesPorAbonos = () => {
    let deudas = [];
    let fechas = [];
    let valorRetornar = {};
    let dif = 0;
    let resta = 0;
    let interes = 0;
    let proximoInteres = 0;
    abonosRealizados.forEach((item, key) => {
      if (key === 0) {
        dif = restaFechas(prestamo.fechaInicio, item.fecha);
        interes = calculateInteres(
          prestamo.montoPrestado,
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);

        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );

        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      } else {
        dif = calCulardiasTranscurridos(item.fecha);
        dif = restaFechas(fechas[key - 1], item.fecha);
        interes = calculateInteres(
          deudas[key - 1],
          dif,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        resta = parseFloat(interes) - item.monto;
        deudas.push(resta);
        fechas.push(item.fecha);
        let diff2 = calCulardiasTranscurridos(item.fecha);
        proximoInteres = calculateInteres(
          resta,
          diff2,
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? prestamo.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          )
        );
        valorRetornar = {
          deuda: resta,
          interesHoy: parseFloat(proximoInteres),
          ultiAbono: item.monto,
          interes,
        };
      }
    });
    return valorRetornar;
  };

  return (
    <>
      <h1 className='font-medium text-center text-base mt-4 bg-theme-26 box p-2'>
        Gestionar la Cobranza
      </h1>
      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-5'>
        <div className='col-span-12'>
          <div className='intro-y box lg:mt-5'>
            <div className='flex p-3 border-b text-base'>
              <h2 className='font-medium mr-auto ml-8'>Datos del Usuarios</h2>
              <div className='flex p-1 text-base mr-5 bg-theme-23 box p-1'>
                <Icon.Calendar className='w-4 h-4' />
                <span className='font-medium mr-auto'>
                  <div className={colorBadge(prestamo.fechaCompromisoPago)}>
                    VECIMIENTO : {prestamo.fechaCompromisoPago}
                  </div>
                </span>
                <Icon.Calendar className='w-4 h-4 mr-2' />
              </div>

              {prestamo.fechaInicioDespues && (
                <div className='flex p-1 text-base mr-5 bg-theme-27 box p-1'>
                  <Icon.Calendar className='w-4 h-4' />
                  <span className='font-medium mr-auto'>
                    <div className={colorBadge(prestamo.fechaCompromisoPago)}>
                      NUEVA FECHA VECIMIENTO :{' '}
                      {prestamo.fechaCompromisoPagoDespues}
                    </div>
                  </span>
                  <Icon.Calendar className='w-4 h-4 mr-2' />
                </div>
              )}
            </div>
            <hr />

            {/*===== Datos del usuario =====*/}
            <div className='flex p-3 flex p-3 bg-theme-2 box overflow-auto lg:overflow-visible'>
              {prestamo.usuario && (
                <div className='grid grid-cols-12 gap-4 row-gap-5 w-full'>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Nombres:
                    </label>
                    <span>
                      {prestamo.usuario.nombres} {''}
                      {prestamo.usuario.apellidos}
                    </span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Cédula:
                    </label>
                    <span>{prestamo.usuario.numeroCedula}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Email:
                    </label>
                    <span>{prestamo.usuario.email}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Pasaporte:
                    </label>
                    <span>{prestamo.usuario.numeroPasaporte}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Sexo:
                    </label>
                    <span>{prestamo.usuario.sexo}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de nacimiento:
                    </label>
                    <span>{prestamo.usuario.fechaNacimiento}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      País:
                    </label>
                    <span>{prestamo.usuario.pais}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Localidad:
                    </label>
                    <span>{prestamo.usuario.localidad}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Whatsapp:
                    </label>
                    <span>{prestamo.usuario.whatsapp}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Otro número de contacto:
                    </label>
                    <span>{prestamo.usuario.telefonoOpcional}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Usuario telegram:
                    </label>
                    <span>{prestamo.usuario.telegram}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Trabaja:
                    </label>
                    <span>{prestamo.usuario.trabaja}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Nombre Empresa:
                    </label>
                    <span>{prestamo.usuario.nombreEmpresa}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Área de trabajo:
                    </label>
                    <span>{prestamo.usuario.areaTrabajo}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Ingreso mensual:
                    </label>
                    <span>{prestamo.usuario.ingresoMensualUSD} $$</span>
                  </div>
                  <div className='col-span-8 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Metodo Pago:
                    </label>
                    <span>{prestamo.usuario.metodosPagos}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha registro:
                    </label>
                    <span>{prestamo.usuario.fechaRegistro}</span>
                  </div>
                  <div className='col-span-12 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Descripón actividad:
                    </label>
                    <span>{prestamo.usuario.descripcionActividad}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*========== Datos del Prestamo ==========*/}
      <div className='grid grid-cols-12 gap-4 row-gap-5 mb-10'>
        <div className='col-span-12'>
          <div className='intro-y box lg:mt-5'>
            <div className='flex p-3 border-b text-base'>
              <h2 className='font-medium mr-auto ml-8'>Datos del Prestamo</h2>
            </div>
            <hr />

            <div className='flex p-3 bg-theme-2 box overflow-auto lg:overflow-visible'>
              {prestamo && (
                <div className='grid grid-cols-12 gap-4 row-gap-5 w-full'>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Estado:
                    </label>
                    <span>{prestamo.estado}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Aprobado:
                    </label>
                    <span>{prestamo.aprobado === true ? 'Si' : 'No'}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Pagado:
                    </label>
                    <span>{prestamo.pagado === true ? 'Si' : 'No'}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto solicitado:
                    </label>
                    <span>{prestamo.montoPrestado} USD</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto a pagar:
                    </label>
                    <span>{prestamo.montoCompromisoPago} USD</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Monto deuda restante :
                    </label>
                    <span>{calcularInteresesPorAbonos()['interesHoy']} $</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Fecha de pago:
                    </label>
                    <span>{prestamo.fechaCompromisoPago}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Solicitado el:
                    </label>
                    <span>{prestamo.fechaSolicitud}</span>
                  </div>
                  <div className='col-span-4 ml-10'>
                    <label className='font-medium p-1 bg-theme-26 box mr-1'>
                      Interes:
                    </label>
                    <span>
                      {myUser.habilitadoNuevoPrestamo === 'No'
                        ? prestamo.tasaInteresCompuesto
                        : myUser.nuevaTasaInteres}{' '}
                      %
                    </span>
                  </div>
                </div>
              )}
            </div>
            {prestamo.fechaInicioDespues && (
              <>
                <h3 className='font-medium p-2 bg-theme-23 box text-center mt-5'>
                  Préstamo con deuda restante
                </h3>
                <div className='grid grid-cols-12 gap-4 row-gap-5 bg-theme-23 box mb-5 p-2'>
                  <div className='col-span-4 p-1 bg-theme-26 box'>
                    <label className='font-medium ml-2 mr-1'>
                      Monto deuda restante :
                    </label>
                    <span>{calcularInteresesPorAbonos()['interesHoy']} $</span>
                  </div>
                  <div className='col-span-4 ml-10 p-1 bg-theme-26 box'>
                    <label className='font-medium ml-2 mr-1'>
                      Fecha inicio :
                    </label>
                    <span>{prestamo.fechaInicioDespues} </span>
                  </div>
                  <div className='col-span-4 ml-10 p-1 bg-theme-26 box'>
                    <label className='font-medium ml-2 mr-1'>
                      Nueva fecha de pago :
                    </label>
                    <span>{prestamo.fechaCompromisoPagoDespues} </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/*===== Card Contactos Sociales =====*/}
      <div className='col-span-12 sm:col-span-8 xxl:col-span-9 mb-10'>
        <div className='intro-y box lg:mt-1'>
          <div className='flex p-3 border-b text-base'>
            <h2 className='font-medium mr-auto ml-8'>Contactos Sociales</h2>
          </div>
          <hr />
          {/* Contenido Contactos */}
          <div className='intro-y col-span-12 mb-3 bg-theme-2 box p-2 w-full overflow-auto lg:overflow-visible'>
            <table className='table table-report'>
              <thead>
                <tr className='box bg-theme-26 p-1'>
                  <th className='whitespace-no-wrap text-center'>NOMBRE</th>
                  <th className='whitespace-no-wrap text-center'>PARENTESCO</th>
                  <th className='whitespace-no-wrap text-center'>URL</th>
                  <th className='whitespace-no-wrap text-center'>TIPO</th>
                </tr>
              </thead>
              <tbody>
                {contactosSociales.map((item, idUsuario) => (
                  <tr className='intro-x text-center' key={idUsuario}>
                    <td>{item.nombre}</td>
                    <td>{item.parentesco}</td>
                    <td>{item.url}</td>
                    <td>{item.tipo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Chat
        chat={chat}
        gestor={true}
        prestamo={prestamo}
        rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
      />
    </>
  );
};
