import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import feather from 'feather-icons';
import * as Icon from 'react-feather';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Tooltip from '@material-ui/core/Tooltip';

import { getListPrestamos } from '../../../redux/actions/gestorActions';
// import moment from "moment";
import { calCulardiasTranscurridos } from '../../../helpers/generales';

const ListadoPrestamos = () => {
  const dispatch = useDispatch();

  const prestamos = useSelector((state) => state.gestor.listPrestamos);

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getListPrestamos());
  }, []);

  const colorBadge = (estado) => {
    switch (estado) {
      case 'iniciado':
        return 'badge bg-theme-36';
      case 'rechazado':
        return 'badge bg-theme-6';
      case 'pendiente':
        return 'badge  bg-theme-12';
      case 'preAprobado':
        return 'badge  bg-orange-500';
      case 'aprobado':
        return 'badge  bg-theme-9';
      default:
        return 'badge bg-theme-1';
    }
  };

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'usuario',
      headerName: 'USUARIO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 190,
      valueGetter: (params) => {
        return `${params.row.usuario.nombres} ${params.row.usuario.apellidos}`;
      },
    },
    {
      field: 'montoPrestado',
      headerName: 'MONTO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 110,
      renderCell: (params) => (
        <span className='m-auto'>{params.row.montoPrestado}</span>
      ),
    },
    {
      field: 'fechaInicio',
      headerName: 'F.INICIO',
      description: 'Fecha Inicio del Prestamo ó Inicio de Deuda Restante',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        if (params.row.fechaInicioDespues) {
          return (
            <Tooltip
              title={
                'Fecha de inicio original: ' + params.row.fechaInicio.toString()
              }
              placement='top-start'
            >
              <span className='flex items-center'>
                {params.row.fechaInicioDespues}
                <Icon.Calendar
                  className='text-theme-10 tooltip ml-2 w-4 h-4'
                  data-theme='light'
                />
              </span>
            </Tooltip>
          );
        } else {
          if (params.row.estado === 'pendiente') {
            // === 1; si ha pasado 1 dia
            if (calCulardiasTranscurridos(params.row.fechaInicio) === 1) {
              return (
                <Tooltip
                  title='La solicitud del préstamo ha pasado 1 día'
                  placement='top-start'
                >
                  <span className='text-theme-12'>
                    {params.row.fechaInicio}
                  </span>
                </Tooltip>
              );
              // > 1; si ha pasado mas de un dia
            } else if (calCulardiasTranscurridos(params.row.fechaInicio) > 1) {
              return (
                <Tooltip
                  title='La solicitud del préstamo ha pasado más de 1 día'
                  placement='top-start'
                >
                  <span className='text-theme-6'>{params.row.fechaInicio}</span>
                </Tooltip>
              );
            } else {
              return <span>{params.row.fechaInicio}</span>;
            }
          } else {
            return <span>{params.row.fechaInicio}</span>;
          }
        }
      },
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'F.PAGO',
      description:
        'Fecha Compromiso del Pago Prestamo ó Compromiso de Pago Deuda Restante',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        if (params.row.fechaInicioDespues) {
          return (
            <Tooltip
              title={
                'Fecha de Compromiso Pago original: ' +
                params.row.fechaCompromisoPago.toString()
              }
              placement='top-start'
            >
              <span className='flex items-center'>
                {params.row.fechaCompromisoPagoDespues}
                <Icon.Calendar
                  className='text-theme-10 tooltip ml-2 w-4 h-4'
                  data-theme='light'
                />
              </span>
            </Tooltip>
          );
        } else {
          return <span>{params.row.fechaCompromisoPago}</span>;
        }
      },
    },
    {
      field: 'estado',
      headerName: 'ESTADO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 130,
      cellClassName: 'unset-line',
      renderCell: (params) => (
        <div className={colorBadge(params.row.estado)}>
          <Icon.Tag className='w-4 h-4 mr-1' />
          {params.row.estado}
        </div>
      ),
    },
    {
      field: 'gestor',
      headerName: 'GESTOR',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 170,
      valueGetter: (params) => {
        if (params.row.gestor) {
          return `${params.row.gestor.nombres} ${params.row.gestor.apellidos}`;
        }
        return ``;
      },
    },
    {
      field: 'aprobado',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 180,
      renderCell: (params) => (
        <div className='flex font-medium justify-center items-center w-full'>
          <Link
            className='flex items-center mr-5 text-theme-1'
            to={{
              pathname: '/validarprestamos/' + params.row.id,
            }}
          >
            <Icon.Settings className='w-4 h-4 mr-1' />
            Gestionar
          </Link>
          <span className='box p-1 hover:bg-green-200'>
            <Tooltip
              title={'Correo: ' + params.row.usuario.email}
              placement='top-start'
            >
              <Icon.Mail className='text-green-700 w-4 h-4' />
            </Tooltip>
          </span>
        </div>
      ),
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();

  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>Listado de Prestamos</h2>
        </div>
        <hr />
        <div className='grid grid-cols-12 gap-3'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 450, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={prestamos}
                columns={columns}
                pageSize={50}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListadoPrestamos;
