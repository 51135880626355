import Swal from "sweetalert2";

export const success = (
  message = "Operación realizada exitosamente.",
  tipo = "success"
) => {
  Swal.fire({
    icon: tipo,
    title: "",
    text: message,
    type: "success",
    showCancelButton: false,
    confirmButtonColor: "#1C3FAA",
    confirmButtonText: "Aceptar",
  });
};

export const custom = (message, tipo = "success") => {
  Swal.fire({
    icon: tipo,
    title: "",
    text: message,
    type: tipo,
    showCancelButton: false,
    confirmButtonColor: "#1C3FAA",
    confirmButtonText: "Aceptar",
  });
};

export const confirm = (message, tipo = "success") => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      icon: tipo,
      title: "",
      text: message,
      type: tipo,
      confirmButtonColor: "#1C3FAA",
      confirmButtonText: "Aceptar",
      showCancelButton: true,
      cancelButtonColor: "#d33",
    }).then(({ value }) => {
      resolve(!!value);
    });
  });
};
