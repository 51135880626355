import React, { useEffect, useState, useRef } from "react";
import { app } from "../../../config/firebaseConfig";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as Icon from "react-feather";

import {
  misAbonos,
  getPrestamoOnly,
  agregarAbono,
} from "../../../redux/actions/userAction";
import {
  PRESTAMOACTUAL,
  MODALABONO,
  LOADING,
  ENDLOADING,
} from "../../../redux/types";
import Modal from "../../Modal";
import { custom } from "../../../helpers/alertas";

const Abonos = () => {
  let { id } = useParams();

  let location = useLocation();

  const dispatch = useDispatch();

  //========== USE-STATES ==========//

  const [verDealle, setVerDetalle] = useState(false);

  const [pago, setPagos] = useState({
    idTransaccion: "",
    nombres: "",
    apellidos: "",
    via: "localbitcoin",
    monto: "",
    concepto: "",
    fecha: moment(new Date()).format("YYYY-MM-DD"),
    voucher: "",
    confirmado: false,
  });

  const [abono, setAbono] = useState(null);

  //========== USE-SELECTOR ==========//
  const misabonos = useSelector((state) => state.user.misabonos);

  const prestamoActual = useSelector((state) => state.user.prestamoActual);

  const modalAbono = useSelector((state) => state.user.modalAbono);

  const fileRef = useRef(null);

  useEffect(() => {
    if (location.state.prestamo) {
      dispatch({ type: PRESTAMOACTUAL, payload: location.state.prestamo });
    } else {
      dispatch(getPrestamoOnly(id));
    }
    dispatch(misAbonos(id));
  }, []);

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setPagos({ ...pago, [name]: value });
  };

  const abonar = () => {
    console.log(prestamoActual);
    console.log(pago);
    setPagos({
      ...pago,
      ["nombres"]: prestamoActual.usuario.nombres,
      ["apellidos"]: prestamoActual.usuario.apellidos,
      ["monto"]: totalRestante().toFixed(2),
    });
    dispatch({ type: MODALABONO, payload: true });
  };

  const cancelAbono = () => {
    dispatch({ type: MODALABONO, payload: false });
  };

  const closeAbono = () => {
    dispatch({ type: MODALABONO, payload: false });
  };

  const guardarAbono = () => {
    if (
      pago.nombres === "" ||
      pago.apellidos === "" ||
      pago.via === "" ||
      pago.monto === "" ||
      pago.concepto === "" ||
      pago.fecha === "" ||
      pago.idTransaccion === ""
    ) {
      custom("Debe llenar todos los datos", "error");
    } else {
      let data = {
        nombres: pago.nombres,
        apellidos: pago.apellidos,
        fecha: moment(pago.fecha).format("YYYY-MM-DD HH:mm:ss"),
        idPrestamo: id,
        via: pago.via,
        concepto: pago.concepto,
        voucher: pago.voucher,
        monto: parseFloat(pago.monto),
        idTransaccion: pago.idTransaccion,
        confirmado: pago.confirmado,
      };
      dispatch(agregarAbono(data));
    }
  };

  const totalAbonado = () => {
    let total = 0;
    misabonos.forEach((fila) => {
      total += fila.monto;
    });
    return total;
  };

  const totalRestante = () => {
    let total = 0;
    misabonos.forEach((fila) => {
      total += fila.monto;
    });
    return prestamoActual.montoCompromisoPago - total;
  };

  const verDetalle = (item) => {
    setAbono(item);
    setVerDetalle(true);
  };

  const openExplorer = (e) => {
    fileRef.current.click();
  };

  const loadFile = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    upload(file);
  };

  const upload = (file) => {
    const imageExtension =
      file.name.split(".")[file.name.split(".").length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;

    if (
      imageExtension.toLowerCase() === "jpg" ||
      imageExtension.toLowerCase() === "jpeg" ||
      imageExtension.toLowerCase() === "png" ||
      imageExtension.toLowerCase() === "gif" ||
      imageExtension.toLowerCase() === "svg" ||
      imageExtension.toLowerCase() === "bmp" ||
      imageExtension.toLowerCase() === "tiff"
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);

      task.on(
        "state_changed",
        (snapshot) => {
          console.log("funcionando");
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          let data = {
            voucher: url,
          };
          app
            .firestore()
            .collection("abonos")
            .doc(`${abono.id}`)
            .update(data)
            .then(() => {
              abono.voucher = url;
              dispatch(getPrestamoOnly(id));
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        "Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.",
        "error"
      );
      dispatch({ type: ENDLOADING });
    }
  };

  return (
    <>
      <div className="intro-y box col-span-12 lg:col-span-6">
        <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
          <h2 className="font-medium text-base mr-auto">Abonos</h2>
          <div className="dropdown relative ml-auto sm:hidden">
            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;">
              {" "}
              <i
                data-feather="more-horizontal"
                className="w-5 h-5 text-gray-700"
              ></i>{" "}
            </a>
            <div className="dropdown-box mt-5 absolute w-40 top-0 right-0 z-20">
              <div className="dropdown-box__content box p-2">
                {prestamoActual && (
                  <a
                    href="javascript:;"
                    className="flex items-center p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                  >
                    <Icon.Plus className="w-4 h-4 mr-1" />
                    FilePlus Nuevo Abono
                  </a>
                )}
              </div>
            </div>
          </div>
          {prestamoActual && (
            <button
              className="button border relative flex items-center text-white hidden sm:flex bg-theme-1"
              onClick={abonar}
              disabled={totalRestante() === 0}
            >
              <Icon.Plus className="w-4 h-4 mr-1" />
              Nuevo Abono
            </button>
          )}
        </div>
        <div className="flex mb-5">
          <div className="grid grid-cols-12 flex flex-col w-full">
            {misabonos.length > 0 &&
              misabonos.map((abono, i) => (
                <div
                  className="box col-span-12 lg:col-span-12 border-b"
                  key={i}
                >
                  <div className="p-5">
                    <div className="grid grid-cols-12">
                      <div className="col-span-12 md:col-span-3 font-medium text-gray-700 items-center">
                        <label className="font-medium bg-theme-26 box p-1 mr-1">
                          Titular:{" "}
                        </label>
                        <span>
                          {abono.nombres} {abono.apellidos}
                        </span>
                      </div>

                      <div className="col-span-12 md:col-span-2 font-medium text-gray-700 items-center">
                        <label className="font-medium bg-theme-26 box p-1 mr-1">
                          Via:{" "}
                        </label>
                        <span>{abono.via}</span>
                      </div>

                      <div className="col-span-12 md:col-span-3 font-medium text-gray-700 items-center">
                        <label className="font-medium bg-theme-26 box p-1 mr-1">
                          Referencia:{" "}
                        </label>
                        <span>{abono.idTransaccion}</span>
                      </div>

                      <div className="col-span-12 md:col-span-2 font-medium text-gray-700 items-center">
                        <label className="font-medium bg-theme-26 box p-1 mr-1">
                          Monto:{" "}
                        </label>
                        <span>{abono.monto} $</span>
                      </div>

                      <div className="col-span-12 md:col-span-2 flex items-center font-medium  text-theme-3">
                        <label className="font-medium bg-theme-26 box p-1 mr-1">
                          Confirmado: {abono.confirmado === true ? "Si" : "No"}
                        </label>
                        <a
                          className="flex text-theme-12 ml-2"
                          href="javascript:;"
                          onClick={(e) => verDetalle(abono)}
                        >
                          <Icon.Search className="w-4 h-4 mr-1" />
                          Ver
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {prestamoActual && (
              <div className="box col-span-12 lg:col-span-12 border-b p-5">
                <div className="col-span-12 flex">
                  <div>
                    <label className="font-medium bg-theme-26 box p-1">
                      Monto Prestado:
                    </label>
                    <label className="font-medium p-1 mr-1">
                      {prestamoActual.montoCompromisoPago}$
                    </label>
                  </div>

                  <div>
                    <label className="font-medium bg-theme-9 box p-1">
                      Abonado:
                    </label>
                    <label className="font-medium p-1 mr-1">
                      {totalAbonado()}$
                    </label>
                  </div>

                  <div>
                    <label className="font-medium bg-theme-36 box p-1">
                      Resta:
                    </label>
                    <label className="font-medium p-1 mr-1">
                      {totalRestante().toFixed(2)}$
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {verDealle && (
        <Modal
          title="Detalle"
          close={() => setVerDetalle(false)}
          save={() => setVerDetalle(false)}
          cancel={() => setVerDetalle(false)}
          customBtn={true}
          textcustomBtn="Aceptar"
        >
          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">
              Titular:
            </label>
            <label className="font-medium">
              {abono.nombres} {abono.apellidos}
            </label>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">
              Fecha:
            </label>
            <label className="font-medium">{abono.fecha}</label>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">
              Referencia:
            </label>
            <label className="font-medium">{abono.idTransaccion}</label>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">
              Monto:
            </label>
            <label className="font-medium">{abono.monto}</label>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">Vía:</label>
            <label className="font-medium">{abono.via}</label>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">
              Concepto:
            </label>
            <label className="font-medium">{abono.concepto}</label>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <label className="font-medium bg-theme-26 box p-1 mr-1">
              Confirmado:
            </label>
            <label className="font-medium">
              {abono.confirmado ? "Si" : "No"}
            </label>
          </div>

          <div className="col-span-12 sm:col-span-6"></div>
          {abono.voucher !== "" && (
            <div className="col-span-12 sm:col-span-12">
              <img src={abono.voucher} alt={abono.via} className="w-full" />
            </div>
          )}
          {abono.voucher === "" && (
            <div className="col-span-12 sm:col-span-12 flex justify-center flex-col items-center">
              <label className="font-medium">Subir Capture</label>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileRef}
                onChange={loadFile}
              />
              <a
                href="javascript:;"
                className="tooltip button inline-block bg-theme-1 text-white m-auto rounded-full mt-5"
                title="Subir Selfie!"
                onClick={openExplorer}
              >
                <span className="w-6 h-6 flex items-center justify-center">
                  <Icon.FilePlus className="w-6 h-6" />
                </span>
              </a>
            </div>
          )}
        </Modal>
      )}

      {modalAbono && (
        <Modal
          title="Abonar Pago"
          close={closeAbono}
          save={guardarAbono}
          cancel={cancelAbono}
        >
          <div className="intro-y col-span-12 sm:col-span-6">
            <label className="mb-2">Nombres :</label>
            <input
              type="text"
              name="nombres"
              placeholder=" Nombres"
              autoFocus={true}
              className="w-full rounded-full border input p-1 mt-1"
              value={pago.nombres}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <label className="mb-2">Apellidos :</label>
            <input
              type="text"
              name="apellidos"
              placeholder=" Apellidos"
              className="w-full rounded-full border input p-1 mt-1"
              value={pago.apellidos}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <label className="mb-2">n° de Transacción :</label>
            <input
              type="text"
              name="idTransaccion"
              placeholder=" N° de operacion"
              className="w-full rounded-full border input p-1 mt-1"
              value={pago.idTransaccion}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <label className="mb-2">Via :</label>
            <select
              className="w-full rounded-full border input p-1 mt-1"
              name="via"
              value={pago.via}
              onChange={HandleChange}
            >
              <option value="localbitcoin">localbitcoin</option>
            </select>
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <label className="mb-2">Monto :</label>
            <input
              type="text"
              name="monto"
              placeholder=" Monto de la operación"
              className="w-full rounded-full border input p-1 mt-1"
              value={pago.monto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <label className="mb-2">Fecha :</label>
            <input
              type="date"
              name="fecha"
              className="w-full rounded-full border input p-1 mt-1"
              value={pago.fecha}
              onChange={HandleChange}
              onKeyUp={HandleChange}
            />
          </div>

          <div className="intro-y col-span-12 sm:col-span-12">
            <label className="mb-2">Concepto :</label>
            <textarea
              type="text"
              data-feature="basic"
              className="summernote bg-theme-26 input w-full rounded-md"
              placeholder=" Concepto del abono... "
              name="concepto"
              value={pago.concepto}
              onChange={HandleChange}
              onKeyUp={HandleChange}
              rows="2"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Abonos;
