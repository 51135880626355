import {
  GET_MYUSER,
  LOGOUT,
  LOADING,
  ENDLOADING,
  CLOSENOTIFICACION,
  OPENNOTIFICACION,
  SETNOTIFICACION,
  MODAL_TERMINOCONDICONES,
  MODAL_INFORMACION,
  MODAL_INFORMACIONINICIAL,
  MODAL_POLITICAPRIVACIDAD,
  MODAL_VERIFYCORREO,
  MODAL_REQUISITOS,
} from "../types";

const initialState = {
  myUser: {},
  loading: true,
  access: false,
  showNotificacion: false,
  datanotificacion: { texto: "", tipo: "" },
  modalTerminoCondiciones: false,
  modalInformacionServicios: false,
  modalInformacionInicial: false,
  modalPoliticasPrivacidad: false,
  modalVerifyCorreo: false,
  modalRequisitoPrestamo: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MYUSER:
      return {
        ...state,
        myUser: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        myUser: {},
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case ENDLOADING:
      return {
        ...state,
        loading: false,
        access: true,
      };
    case OPENNOTIFICACION:
      return {
        ...state,
        showNotificacion: true,
      };
    case CLOSENOTIFICACION:
      return {
        ...state,
        showNotificacion: true,
      };
    case SETNOTIFICACION:
      return {
        ...state,
        datanotificacion: action.payload,
      };
    case MODAL_TERMINOCONDICONES:
      return {
        ...state,
        modalTerminoCondiciones: action.payload,
      };
    case MODAL_INFORMACION:
      return {
        ...state,
        modalInformacionServicios: action.payload,
      };
    case MODAL_INFORMACIONINICIAL:
      return {
        ...state,
        modalInformacionInicial: action.payload,
      };
    case MODAL_REQUISITOS:
      return {
        ...state,
        modalRequisitoPrestamo: action.payload,
      };
    case MODAL_POLITICAPRIVACIDAD:
      return {
        ...state,
        modalPoliticasPrivacidad: action.payload,
      };
    case MODAL_VERIFYCORREO:
      return {
        ...state,
        modalVerifyCorreo: action.payload,
      };
    default:
      return state;
  }
}
