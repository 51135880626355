import React, { useState, useEffect } from 'react';

import moment from 'moment';
import * as Icon from 'react-feather';
import { app } from '../../../config/firebaseConfig';
import Slider from 'react-slick';
import jsPDF from 'jspdf';

const hoy = moment(new Date()).format('YYYY-MM-DD');

const Calculadora = () => {
  const new_date = moment(new Date());
  const mindate = new_date.add(4, 'days').format('YYYY-MM-DD');

  //========== USE-STATES ==========//
  const [cantidad, setCantidad] = useState(5);
  const [fecha, setFecha] = useState(hoy);
  const [fechaInic, setFechaInic] = useState(hoy);
  const [days, setDays] = useState([]);
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: -1,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });

  useEffect(() => {
    getConfiguracion();
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
        } else {
          setConfig({
            diasMinimoPago: '',
            fondo: -1,
            informacion: '',
            montoInicialMaximoPrestamo: '',
            montoMaximoPrestamo: '',
            politicas: '',
            porcentajeIncremento: '',
            requisitos: '',
            tasaDolar: '',
            tasaInteresCompuesto: '',
            terminos: '',
          });
        }
      });
  };
  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };

  //========== Metodos calculadora ==========//
  const changeMonto = (e) => {
    const value = e.target.value;
    setCantidad(value);
  };
  const getDirerence = (fechaValue) => {
    // var NowMoment = moment(new Date()).format('YYYY-MM-DD');
    var NowMoment = fechaInic;
    var fecha1 = moment(NowMoment);
    var fecha2 = moment(fechaValue);
    let diff = fecha2.diff(fecha1, 'days');
    armarDays(diff);
    return diff;
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    setDays(array.reverse());
  };
  const changeFecha = (e) => {
    const value = e.target.value;
    setFecha(value);
  };
  const changeFechaInic = (e) => {
    const value = e.target.value;
    setFechaInic(value);
  };
  const changeTasa = (e) => {
    const { name, value } = e.target;
    setConfig({ ...config, [name]: value });
  };
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const calculateInteresByDia = (dia) => {
    let totalApagar = (
      cantidad *
      Math.pow(1 + parseFloat(config.tasaInteresCompuesto) / 100, dia)
    ).toFixed(2);
    return totalApagar;
  };
  const calculaInteres = (value) => {
    value.preventDefault();
    let diff = getDirerence(fecha);
    return diff;
  };
  const calcularInteres = (deuda, dias, tasaInteresCompuesto) => {
    let totalApagar = (
      deuda * Math.pow(1 + parseFloat(tasaInteresCompuesto) / 100, dias)
    ).toFixed(2);
    return totalApagar;
  };
  let ultimo = [];
  ultimo.push(
    calcularInteres(cantidad, days.length, config.tasaInteresCompuesto)
  );

  const exportar = () => {
    const addFooters = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      for (var i = 0; i < pageCount; i++) {
        doc.setTextColor(51, 122, 183);
        doc.text(
          'www.chamopresta.com',
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 20,
          {
            align: 'center',
          }
        );
        /*doc.setTextColor(0,0,0);
        doc.text('Hispanos Soluciones C.A. / contacto: ', 146, doc.internal.pageSize.height - 20, { });
        doc.setTextColor(51,122,183);
        doc.text('info@hispanossoluciones.com', 316, doc.internal.pageSize.height - 20, {});*/
      }
    };
    let doc = new jsPDF('p', 'pt', 'A4', true);
    let title = `Tabla de interés pautado ${moment(fecha).format(
      'DD/MM/YYYY'
    )}`;
    doc.autoTable({
      head: [[title]],
      body: [],
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: 18,
        fillColor: [255, 255, 255],
        textColor: [40, 167, 69],
      },
    });
    let data = [];
    days.map((day, i) => {
      data.push([
        `${formatFecha(moment(new Date()).format('YYYY-MM-DD'), day)}`,
        `${calculateInteresByDia(day)}$`,
      ]);
    });
    doc.autoTable({
      head: [['Fecha', 'Monto']],
      body: data,
      allSectionHooks: true,
      headStyles: {
        fillColor: [28, 63, 170],
      },
    });
    let Subtitle = `Usted al solicitar el préstamo acepta los intereses mostrados y se compromete a pagar en el tiempo pactado: ${moment(
      fecha
    ).format('DD/MM/YYYY')}`;
    doc.autoTable({
      head: [[Subtitle]],
      body: [],
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: 13,
        fillColor: [255, 255, 255],
        textColor: [40, 167, 69],
      },
    });
    addFooters(doc);
    //window.open(doc.output('bloburl'), '_blank');
    doc.save(`${title}.pdf`);
  };

  return (
    <>
      <div className='grid grid-cols-12 gap-6 mt-5'>
        <div className='intro-y col-span-12 lg:col-span-12'>
          <div className='intro-y box'>
            <div className='flex flex-col sm:flex-row items-center p-5 border-b border-gray-200'>
              <h3 className='font-medium text-xl m-auto'>
                Calculadora de interés compuesto
              </h3>
            </div>
            <div className='md:px-24  p-8' id='input'>
              <div className='preview'>
                <form onSubmit={calculaInteres}>
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-12'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Capital inicial : $
                      </label>
                      <input
                        type='number'
                        name='cantidad'
                        className='input input--sm w-full border mt-3 rounded-lg'
                        placeholder='Indique monto'
                        min='0'
                        step='.01'
                        value={cantidad}
                        onChange={changeMonto}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Fecha inicio : *
                      </label>
                      <input
                        type='date'
                        name='fechaInic'
                        className='w-full rounded-lg border input p-1 mt-3'
                        placeholder='Fecha inicial'
                        value={fechaInic}
                        onChange={changeFechaInic}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Fecha final : *
                      </label>
                      <input
                        type='date'
                        name='fecha'
                        className='w-full rounded-lg border input p-1 mt-3'
                        placeholder='Fecha fin'
                        // min={mindate}
                        value={fecha}
                        onChange={changeFecha}
                      />
                    </div>
                    <div className='col-span-12'>
                      <label className='font-medium bg-theme-2 box p-2'>
                        Tasa de rentabilidad : %%
                      </label>
                      <input
                        type='number'
                        name='tasaInteresCompuesto'
                        className='w-full rounded-lg border input p-1 mt-2'
                        placeholder='Porcentaje aplicado'
                        min='0'
                        step='.01'
                        value={config.tasaInteresCompuesto}
                        onChange={changeTasa}
                      />
                    </div>
                    <div className='col-span-12'>
                      <button
                        type='submit'
                        className='flex button bg-green-600 text-white my-5'
                      >
                        <Icon.Cpu className='w-5 h-5 mr-3' />
                        Calcular interés
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {days.length > 0 && (
                <>
                  <div
                    className='col-span-12 sm:col-span-12 flex justify-center items-center'
                    style={{ flexDirection: 'column' }}
                  >
                    <h2 className='font-medium bg-theme-2 box p-2 mb-2'>
                      Distribución de interes
                    </h2>
                    <Slider {...settings} className='w-full'>
                      {days.map((day, i) => {
                        return (
                          <div className='carousel'>
                            <div
                              className='box p-2 mb-3 flex items-center zoom-in bg-theme-26'
                              key={i}
                            >
                              <div>
                                <div className='font-medium'>
                                  {`${formatFecha(fechaInic, day)}`}
                                </div>
                                <div className='py-1 px-2 rounded-md text-xs text-white bg-theme-9 cursor-pointer font-medium text-center'>
                                  {`${calculateInteresByDia(day)}$`}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                  <div className='text-sm font-medium bg-theme-5 box p-3 mt-3'>
                    <p className='text-gray-700'>
                      ¿Usted se compromete a pagar{' '}
                      <span className='text-gray-800'>{ultimo}</span> el día{' '}
                      <span className='text-gray-800'>
                        {moment(fecha).format('DD/MM/YYYY')}
                      </span>{' '}
                      ?
                    </p>
                    <p className='text-gray-700'>
                      Los préstamos se pueden devolver en un plazo mínimo de 4
                      días, en su caso podría pagar su préstamo desde el día
                      <span className='text-gray-800 ml-1'>
                        {moment(mindate).format('DD/MM/YYYY')}
                      </span>
                      .
                    </p>
                    <p className='text-gray-700'>
                      Con esta tabla usted podrá saber en cuanto esta su deuda
                      en cualquier día hasta su fecha de pago.
                    </p>
                  </div>
                  <div class='flex justify-end items-end mr-3'>
                    {' '}
                    <button
                      className='button text-white bg-theme-1 py-2 px-3 mt-8 flex'
                      onClick={exportar}
                    >
                      <Icon.FileText className='text-white mr-2 w-4' />
                      Exportar
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculadora;
