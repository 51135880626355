import React from 'react';
import { useDispatch } from 'react-redux';
import { setView, logout } from '../redux/actions/menuActions';
import { useHistory } from 'react-router-dom';

//MUI Stuff
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';

export default function ModalExit(props) {
  const dispatch = useDispatch();
  const { open, onClose } = props;
  let history = useHistory();

  const handleClose = () => {
    onClose();
  };

  const handleExit = () => {
    dispatch(setView('Dashboard'));
    dispatch(logout());
    history.push('/login');
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">¿Desea cerrar sesión?</DialogTitle>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={handleExit}
          color="primary"
          autoFocus
        >
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
