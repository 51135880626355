/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '../Modal';
import { modalRequisito } from '../../redux/actions/sesionAction';
import { app } from '../../config/firebaseConfig';

const Footer = () => {
  const dispatch = useDispatch();

  //========== USE-STATES ==========//
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: -1,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });

  useEffect(() => {
    getConfiguracion();
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
        } else {
          setConfig({
            diasMinimoPago: '',
            fondo: -1,
            montoInicialMaximoPrestamo: '',
            montoMaximoPrestamo: '',
            politicas: '',
            porcentajeIncremento: '',
            requisitos: '',
            tasaDolar: '',
            tasaInteresCompuesto: '',
            terminos: '',
          });
        }
      });
  };

  //========== Funciones Modal Requisitos ==========//
  const closeModalRequisitos = () => {
    dispatch(modalRequisito(false));
  };
  const cancelModalRequisitos = () => {
    dispatch(modalRequisito(false));
  };
  const modalRequisitos = (params) => {
    dispatch(modalRequisito(true));
  };

  //========== USE-SELECTORS ==========//
  const modalRequisitoPrestamo = useSelector(
    (state) => state.sesion.modalRequisitoPrestamo
  );
  return (
    <>
      <div
        id='contacto'
        className='mt-32'
        style={{
          backgroundColor: '#004296',
        }}
      >
        <div className='w-full'>
          <div className='container-custom'>
            <div className='flex-1'>
              <div className='grid grid-cols-2 gap-2 row-gap-2'>
                <div className='col-span-12 sm:col-span-6'>
                  <div className='p-4 mt-8'>
                    <img className='mb-5' alt='logo' src='/logoBanner.png' />
                    <p className='font-montserrat tracking-footer text-white text-lg'>
                      Recibe préstamos sin otorgar
                      <br />
                      ningún tipo de garantía
                    </p>
                    <div className='intro-x font-montserrat tracking-footer text-white text-lg'>
                      <span>¿Dudas?</span>
                      <a
                        className='font-medium mx-1'
                        href='https://www.youtube.com/watch?v=OBfSUvfcZ-o'
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target='_blank'
                      >
                        Haz clic aquí
                      </a>
                      para ver nuestro <br /> <b>videotutorial</b> de cómo
                      solicitar un préstamo.
                    </div>
                    <span className='font-montserrat tracking-footer text-white text-lg'>
                      <a
                        className='flex'
                        href='#!'
                        onClick={(e) => modalRequisitos()}
                      >
                        <img className='mr-1' src='/check-box.svg' alt='logo' />
                        Requisitos *
                      </a>
                    </span>
                  </div>
                </div>

                <div className='col-span-12 sm:col-span-6 w-64'>
                  <div className='mt-12'>
                    <span className='flex mb-5'>
                      <a
                        className='m-auto'
                        href='https://www.facebook.com/profile.php?id=100070430324522'
                      >
                        <img
                          className='m-auto'
                          src='/facebook.png'
                          alt='logo'
                        />
                      </a>
                      <a
                        className='m-auto'
                        href='https://www.instagram.com/chamopresta/'
                      >
                        <img src='/instagram.png' alt='logo' />
                      </a>
                    </span>
                    <p className='font-montserrat tracking-footer text-white text-lg ml-8'>
                      Visita nuestras redes sociales
                    </p>
                  </div>
                </div>

                <div className='col-span-12 sm:col-span-12 my-10'>
                  <div className='flex justify-center content-center'>
                    <p className='font-montserrat tracking-footer text-white text-lg'>
                      Todos los derechos reservados
                    </p>
                  </div>
                  <p className='italic text-xs text-center text-blue-600'>
                    https://github.com/WilmerFontt-Willo04
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*===== Modal Requisitos =====*/}
        {modalRequisitoPrestamo && (
          <Modal
            title='Requisitos para solicitar préstamo'
            close={closeModalRequisitos}
            cancel={cancelModalRequisitos}
            save={() => dispatch(modalRequisito(false))}
            customBtn={true}
            textcustomBtn='Aceptar'
          >
            <div className='intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh'>
              <p className='my-1 whitespace-pre-wrap'>{config.requisitos}</p>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Footer;
