import React, { useEffect, useState } from "react";
import Vrificacion from "./Verificacion";
import ResetPassword from "./ResetPassword";
import Verificacion from "./Verificacion";

const Verify = () => {
  const [mode, setMode] = useState("");

  useEffect(() => {
    setMode(getParameterByName("mode"));
    console.log(getParameterByName("mode"));
  }, []);

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  return mode === "verifyEmail" ? <Verificacion /> : <ResetPassword />;
};

export default Verify;
