import axios from "axios";
const { REACT_APP_MODE, REACT_APP_API, REACT_APP_APIQA } = process.env;


const API_BASE_URL = REACT_APP_MODE === 'development' ? REACT_APP_APIQA : REACT_APP_API;
console.log('API_BASE_URL', API_BASE_URL);

export const axiosInstance = axios.create({
    baseURL: `${API_BASE_URL}`,
    headers: {'Content-Type': 'application/json'}
});