import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import $ from 'jquery';
import feather from 'feather-icons';

import Selfi from '../../../statics/images/upload_selphi.png';
import Document from '../../../statics/images/document.png';
import Comprobante from '../../../statics/images/comprobante.png';

import { app } from '../../../config/firebaseConfig';
import { getRequrimientos } from '../../../redux/actions/userAction';
import { custom } from '../../../helpers/alertas';
import { LOADING, ENDLOADING } from '../../../redux/types';
import { addLog } from '../../../redux/actions/sesionAction';

const Verificacion = () => {
  const dispatch = useDispatch();
  const fileSelfieRef = useRef(null);
  const fileDocumentRef = useRef(null);
  const fileComprobanteRef = useRef(null);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const requerimientos = useSelector((state) => state.user.requerimientos);

  useEffect(() => {
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 });
      $('.tooltip').each(function () {
        let options = {
          delay: 0,
          theme: 'tooltipster-borderless',
          multiple: true,
        };
        if ($(this).data('event') === 'on-click') {
          options.trigger = 'click';
        }
        if ($(this).data('theme') === 'light') {
          options.theme = 'tooltipster-shadow';
        }
        if ($(this).data('side') !== undefined) {
          options.side = $(this).data('side');
        }
        $(this).tooltipster(options);
      });
    })($);
    dispatch(getRequrimientos());
  }, []);

  const openExplorerSelfie = (e) => {
    fileSelfieRef.current.click();
  };
  const openExplorerDocumento = (e) => {
    fileDocumentRef.current.click();
    dispatch(
      addLog({
        accion: `${'ha Subido su cedula de identidad '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const openExplorerComprobante = (e) => {
    fileComprobanteRef.current.click();
  };
  const loadSelfie = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
        console.log('File', file);
    if (requerimientos.length > 0) {
      if (requerimientos[0].selfieCedula === '') {
        upload(file, 'selfieCedula');
      } else {
        let filename = getImageName(requerimientos[0].selfieCedula);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'selfieCedula');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'selfieCedula');
    }
  };
  const loadDocument = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientos.length > 0) {
      if (requerimientos[0].documento === '') {
        upload(file, 'documento');
      } else {
        let filename = getImageName(requerimientos[0].documento);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'documento');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'documento');
    }
  };
  const loadComprobante = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientos.length > 0) {
      if (requerimientos[0].comprobanteTrabajo === '') {
        upload(file, 'comprobanteTrabajo');
      } else {
        let filename = getImageName(requerimientos[0].comprobanteTrabajo);
        // Delete the file
        const storageRef = app.storage().ref(`${filename}`);
        storageRef
          .delete()
          .then(() => {
            upload(file, 'comprobanteTrabajo');
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    } else {
      upload(file, 'comprobanteTrabajo');
    }
  };

  const upload = (file, property) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          if (requerimientos.length > 0) {
            let data = {
              [property]: url,
              idRequerimiento: requerimientos[0].id,
            };
            app
              .firestore()
              .collection('requerimiento')
              .doc(`${requerimientos[0].id}`)
              .update(data)
              .then(() => {
                dispatch(getRequrimientos());
                dispatch({ type: ENDLOADING });
              });
          } else {
            app
              .firestore()
              .collection('requerimiento')
              .add({
                comprobanteTrabajo:
                  property === 'comprobanteTrabajo' ? url : '',
                documento: property === 'documento' ? url : '',
                fechaValidacionComprobanteTrabajo: null,
                fechaValidacionDocumento: null,
                fechaValidacionSelfieCedula: null,
                //fechaValidacionSelfiePrestamo: null,
                idRequerimiento: '',
                idUsuario: app.auth().currentUser.uid,
                selfieCedula: property === 'selfieCedula' ? url : '',
                //selfiePrestamo: "",
                validadoComprobanteTrabajo: '',
                validadoDocumento: '',
                validadoGestorComprobanteTrabajo: null,
                validadoGestorDocumento: null,
                validadoGestorSelfieCedula: null,
                //validadoGestorSelfiePrestamo: null,
                validadoSelfieCedula: '',
                //validadoSelfiePrestamo: "",
              })
              .then(() => {
                dispatch(getRequrimientos());
                dispatch({ type: ENDLOADING });
              });
          }
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  const getImageName = (url) => {
    let primera = url.split('/o/')[1];
    return primera.split('?')[0];
  };

  return (
    <div className='grid grid-cols-12 gap-4 row-gap-5 mb-8'>
      <div className='intro-y col-span-12 sm:col-span-12'>
        <div className='font-medium text-gray-900 text-center'>
          Te solicitamos imágenes para validar tu identidad, si trabajas y
          tienes un comprobante no dudes en subir una foto.
        </div>
      </div>
      <div className='intro-y col-span-12 sm:col-span-6'>
        <div className='text-center'>
          <h5 className='text-lg'>
            Imagen selfie sosteniendo su cédula de identidad
          </h5>
          {requerimientos.length > 0 && (
            <img
              alt=''
              className='border m-auto rounded'
              style={{ width: 300, height: 200 }}
              src={
                requerimientos[0].selfieCedula !== ''
                  ? requerimientos[0].selfieCedula
                  : Selfi
              }
            />
          )}
          {requerimientos.length === 0 && (
            <img
              alt=''
              className='border m-auto rounded'
              style={{ width: 300, height: 200 }}
              src={Selfi}
            />
          )}
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileSelfieRef}
            onChange={loadSelfie}
          />
          <a
            href='javascript:;'
            className='tooltip button inline-block bg-theme-1 text-white m-auto rounded-full mt-5'
            title='Subir Selfie!'
            onClick={openExplorerSelfie}
          >
            <span className='w-6 h-6 flex items-center justify-center'>
              <i data-feather='folder-plus' className='w-6 h-6'></i>
            </span>
          </a>
        </div>
      </div>
      <div className='intro-y col-span-12 sm:col-span-6'>
        <div className='text-center'>
          <h5 className='text-lg'>Imagen de cédula de identidad</h5>
          {requerimientos.length > 0 && (
            <img
              alt=''
              className='border m-auto rounded'
              style={{ width: 300, height: 200 }}
              src={
                requerimientos[0].documento !== ''
                  ? requerimientos[0].documento
                  : Document
              }
            />
          )}
          {requerimientos.length === 0 && (
            <img
              alt=''
              className='border m-auto rounded'
              style={{ width: 300, height: 200 }}
              src={Document}
            />
          )}
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileDocumentRef}
            onChange={loadDocument}
          />
          <a
            href='javascript:;'
            className='tooltip button inline-block bg-theme-1 text-white m-auto rounded-full mt-5'
            title='Subir cédula de identidad!'
            onClick={openExplorerDocumento}
          >
            <span className='w-6 h-6 flex items-center justify-center'>
              <i data-feather='folder-plus' className='w-6 h-6'></i>
            </span>
          </a>
        </div>
      </div>
      <div className='intro-y col-span-12 sm:col-span-12'>
        <div className='text-center'>
          <h5 className='text-lg'>
            Comprobante de trabajo o foto de emprendimiento (opcional pero
            recomendable)
          </h5>
          {requerimientos.length > 0 && (
            <img
              alt=''
              className='border m-auto rounded'
              style={{ width: 400, height: 300 }}
              src={
                requerimientos[0].comprobanteTrabajo !== ''
                  ? requerimientos[0].comprobanteTrabajo
                  : Comprobante
              }
            />
          )}
          {requerimientos.length === 0 && (
            <img
              alt=''
              className='border m-auto rounded'
              style={{ width: 400, height: 300 }}
              src={Comprobante}
            />
          )}
          <input
            type='file'
            style={{ display: 'none' }}
            ref={fileComprobanteRef}
            onChange={loadComprobante}
          />
          <a
            href='javascript:;'
            className='tooltip button inline-block bg-theme-1 text-white m-auto rounded-full mt-5'
            title='Subir comprobante de trabajo!'
            onClick={openExplorerComprobante}
          >
            <span className='w-6 h-6 flex items-center justify-center'>
              <i data-feather='folder-plus' className='w-6 h-6'></i>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Verificacion;
