export default {
    listado: [
	{nombre: "Afganistan", codigo: "93"},
  {nombre: "Albania", codigo: "335"}, 
	{nombre: "Alemania", codigo: "49"}, 
	{nombre: "Andorra", codigo: "376"}, 
	{nombre: "Angola", codigo: "244"},  
	{nombre: "Antigua y Barbuda", codigo: "268"}, 
	{nombre: "Arabia Saudi", codigo: "966"}, 
	{nombre: "Argelia", codigo: "213"}, 
	{nombre: "Argentina", codigo: "54"}, 
	{nombre: "Armenia", codigo: "374"}, 
	{nombre: "Australia", codigo: "61"}, 
	{nombre: "Austria", codigo: "43"}, 
	{nombre: "Azerbaiyan", codigo: "994"}, 
	{nombre: "Bahamas", codigo: "242"}, 
	{nombre: "Bahrain", codigo: "973"}, 
	{nombre: "Bangladesh", codigo: "880"}, 
	{nombre: "Barbados", codigo: "246"}, 
	{nombre: "Belgica", codigo: "32"}, 
	{nombre: "Belice", codigo: "501"}, 
	{nombre: "Benin", codigo: "229"}, 
	{nombre: "Bermudas", codigo: "441"}, 
	{nombre: "Bielorrusia", codigo: "375"}, 
	{nombre: "Birmania Myanmar", codigo: "95"}, 
	{nombre: "Bolivia", codigo: "591"}, 
	{nombre: "Bosnia y Herzegovina", codigo: "387"}, 
	{nombre: "Botswana", codigo: "267"}, 
	{nombre: "Brasil", codigo: "55"}, 
	{nombre: "Brunei", codigo: "673"}, 
	{nombre: "Bulgaria", codigo: "359"}, 
	{nombre: "Burkina Faso", codigo: "226"}, 
	{nombre: "Burundi", codigo: "257"}, 
	{nombre: "Butan", codigo: "975"}, 
	{nombre: "Cabo Verde", codigo: "238"}, 
	{nombre: "Camboya", codigo: "855"}, 
	{nombre: "Camerun", codigo: "237"}, 
	{nombre: "Canada", codigo: "1"}, 
	{nombre: "Chad", codigo: "235"}, 
	{nombre: "Chile", codigo: "56"}, 
	{nombre: "China", codigo: "86"}, 
	{nombre: "Chipre", codigo: "357"}, 
	{nombre: "Colombia", codigo: "57"}, 
	{nombre: "Comores", codigo: "269"}, 
	{nombre: "Congo", codigo: "242"}, 
	{nombre: "Corea del Norte", codigo: "850"}, 
	{nombre: "Corea del Sur", codigo: "82"}, 
	{nombre: "Costa de Marfil", codigo: "225"}, 
	{nombre: "Costa Rica", codigo: "506"}, 
	{nombre: "Croacia", codigo: "385"}, 
	{nombre: "Cuba", codigo: "53"}, 
	{nombre: "Dinamarca", codigo: "45"}, 
	{nombre: "Dominica", codigo: "767"}, 
	{nombre: "Ecuador", codigo: "593"}, 
	{nombre: "Egipto", codigo: "20"}, 
	{nombre: "El Salvador", codigo: "503"}, 
	{nombre: "El Vaticano", codigo: "379"}, 
	{nombre: "Emiratos arabes Unidos", codigo: "971"}, 
	{nombre: "Eritrea", codigo: "291"}, 
	{nombre: "Eslovaquia", codigo: "421"}, 
	{nombre: "Eslovenia", codigo: "386"}, 
	{nombre: "España", codigo: "34"}, 
	{nombre: "Estados Unidos", codigo: "1"}, 
	{nombre: "Estonia", codigo: "372"}, 
	{nombre: "Etiopia", codigo: "251"}, 
	{nombre: "Filipinas", codigo: "63"}, 
	{nombre: "Finlandia", codigo: "358"}, 
	{nombre: "Fiji", codigo: "679"}, 
	{nombre: "Francia", codigo: "33"}, 
	{nombre: "Gabon", codigo: "241"}, 
	{nombre: "Gambia", codigo: "220"}, 
	{nombre: "Georgia", codigo: "995"}, 
	{nombre: "Ghana", codigo: "233"}, 
	{nombre: "Gibraltar", codigo: "350"}, 
	{nombre: "Granada", codigo: "473"}, 
	{nombre: "Grecia", codigo: "30"}, 
	{nombre: "Guatemala", codigo: "502"}, 
	{nombre: "Guinea", codigo: "224"}, 
	{nombre: "Guinea Ecuatorial", codigo: "240"}, 
	{nombre: "Guinea Bissau", codigo: "245"}, 
	{nombre: "Guyana", codigo: "592"}, 
	{nombre: "Haiti", codigo: "509"}, 
	{nombre: "Honduras", codigo: "504"}, 
	{nombre: "Hungria", codigo: "36"}, 
	{nombre: "India", codigo: "91"}, 
	{nombre: "Indian Ocean", codigo: "246"}, 
	{nombre: "Indonesia", codigo: "62"}, 
	{nombre: "Iran", codigo: "98"}, 
	{nombre: "Iraq", codigo: "964"}, 
	{nombre: "Irlanda", codigo: "353"}, 
	{nombre: "Islandia", codigo: "354"}, 
	{nombre: "Israel", codigo: "972"}, 
	{nombre: "Italia", codigo: "39"}, 
	{nombre: "Jamaica", codigo: "876"}, 
	{nombre: "Japon", codigo: "81"}, 
	{nombre: "Jersey", codigo: "44"}, 
	{nombre: "Jordania", codigo: "962"}, 
	{nombre: "Kazajstan", codigo: "7"}, 
	{nombre: "Kenia", codigo: "254"}, 
	{nombre: "Kirguistan", codigo: "996"}, 
	{nombre: "Kiribati", codigo: "686"}, 
	{nombre: "Kuwait", codigo: "965"}, 
	{nombre: "Laos", codigo: "856"}, 
	{nombre: "Lesoto", codigo: "266"}, 
	{nombre: "Letonia", codigo: "371"}, 
	{nombre: "Libano", codigo: "961"}, 
	{nombre: "Liberia", codigo: "231"}, 
	{nombre: "Libia", codigo: "218"}, 
	{nombre: "Liechtenstein", codigo: "423"}, 
	{nombre: "Lituania", codigo: "370"}, 
	{nombre: "Luxemburgo", codigo: "352"}, 
	{nombre: "Macedonia", codigo: "389"}, 
	{nombre: "Madagascar", codigo: "261"}, 
	{nombre: "Malasia", codigo: "60"}, 
	{nombre: "Malawi", codigo: "265"}, 
	{nombre: "Maldivas", codigo: "960"}, 
	{nombre: "Mali", codigo: "223"}, 
	{nombre: "Malta", codigo: "356"}, 
	{nombre: "Marruecos", codigo: "212"}, 
	{nombre: "Mauricio", codigo: "230"}, 
	{nombre: "Mauritania", codigo: "222"}, 
	{nombre: "Mexico", codigo: "52"}, 
	{nombre: "Micronesia", codigo: "691"}, 
	{nombre: "Moldavia", codigo: "373"}, 
	{nombre: "Monaco", codigo: "377"}, 
	{nombre: "Mongolia", codigo: "971"}, 
	{nombre: "Montserrat", codigo: "664"}, 
	{nombre: "Mozambique", codigo: "258"}, 
	{nombre: "Namibia", codigo: "264"}, 
	{nombre: "Nauru", codigo: "674"}, 
	{nombre: "Nepal", codigo: "977"}, 
	{nombre: "Nicaragua", codigo: "505"}, 
	{nombre: "Niger", codigo: "227"}, 
	{nombre: "Nigeria", codigo: "234"}, 
	{nombre: "Noruega", codigo: "47"}, 
	{nombre: "Nueva Zelanda", codigo: "64"}, 
	{nombre: "Oman", codigo: "969"}, 
	{nombre: "Paises Bajos", codigo: "31"}, 
	{nombre: "Pakistan", codigo: "92"}, 
	{nombre: "Palau", codigo: "680"}, 
	{nombre: "Panama", codigo: "507"}, 
	{nombre: "Papua Nueva Guinea", codigo: "675"}, 
	{nombre: "Paraguay", codigo: "595"}, 
	{nombre: "Peru", codigo: "51"}, 
	{nombre: "Polonia", codigo: "48"}, 
	{nombre: "Portugal", codigo: "351"}, 
	{nombre: "Puerto Rico", codigo: "+1"}, 
	{nombre: "Qatar", codigo: "974"}, 
	{nombre: "Reino Unido", codigo: "44"}, 
	{nombre: "Republica Centroafricana", codigo: "236"}, 
	{nombre: "Republica Checa", codigo: "420"}, 
	{nombre: "Republica Democratica del Congo", codigo: "243"}, 
	{nombre: "Republica Dominicana", codigo: "+1"}, 
	{nombre: "Ruanda", codigo: "250"}, 
	{nombre: "Rumania", codigo: "40"}, 
	{nombre: "Rusia", codigo: "7"}, 
	{nombre: "Sahara Occidental", codigo: "212"}, 
	{nombre: "Samoa", codigo: "685"}, 
	{nombre: "San Cristobal y Nevis", codigo: "869"}, 
	{nombre: "San Marino", codigo: "378"}, 
	{nombre: "San Vicente y las Granadinas", codigo: "784"}, 
	{nombre: "Santa Lucia", codigo: "758"}, 
	{nombre: "Santo Tome y Principe", codigo: "239"}, 
	{nombre: "Senegal", codigo: "221"}, 
	{nombre: "Seychelles", codigo: "248"}, 
	{nombre: "Sierra Leona", codigo: "232"}, 
	{nombre: "Singapur", codigo: "65"}, 
	{nombre: "Siria", codigo: "963"}, 
	{nombre: "Somalia", codigo: "252"}, 
	{nombre: "Sri Lanka", codigo: "94"}, 
	{nombre: "Swazilandia", codigo: "268"}, 
	{nombre: "Sudafrica", codigo: "27"}, 
	{nombre: "Sudan", codigo: "249"}, 
	{nombre: "Suecia", codigo: "46"}, 
	{nombre: "Suiza", codigo: "41"}, 
	{nombre: "Surinam", codigo: "597"}, 
	{nombre: "Tailandia", codigo: "66"}, 
	{nombre: "Taiwan", codigo: "886"}, 
	{nombre: "Tanzania", codigo: "255"}, 
	{nombre: "Tayikistan", codigo: "992"}, 
	{nombre: "Togo", codigo: "228"}, 
	{nombre: "Tokelau", codigo: "690"}, 
	{nombre: "Tonga", codigo: "676"}, 
	{nombre: "Trinidad y Tobago", codigo: "868"}, 
	{nombre: "Tunez", codigo: "216"}, 
	{nombre: "Turkmekistan", codigo: "993"}, 
	{nombre: "Turquia", codigo: "90"}, 
	{nombre: "Tuvalu", codigo: "688"}, 
	{nombre: "Ucrania", codigo: "380"}, 
	{nombre: "Uganda", codigo: "256"}, 
	{nombre: "Uruguay", codigo: "598"}, 
	{nombre: "Uzbekistan", codigo: "998"}, 
	{nombre: "Vanuatu", codigo: "678"}, 
	{nombre: "Venezuela", codigo: "58"}, 
	{nombre: "Vietnam", codigo: "84"}, 
	{nombre: "Yemen", codigo: "967"}, 
	{nombre: "Djibouti", codigo: "253"}, 
	{nombre: "Zambia", codigo: "260"}, 
	{nombre: "Zimbabue", codigo: "263"} 
    ]
}