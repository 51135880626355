export default {
  bancosEcuador: [
    { codigo: '001', nombre: 'Banco Pichincha' },
    { codigo: '002', nombre: 'Banco del Pacífico' },
    { codigo: '003', nombre: 'Banco Guayaquil' },
    { codigo: '004', nombre: 'Banco Internacional' },
    { codigo: '005', nombre: 'Banco de Machala' },
    { codigo: '006', nombre: 'Banco Bolivariano' },
    { codigo: '007', nombre: 'Banco ProCredit' },
    { codigo: '008', nombre: 'Banco Amazonas' },
    { codigo: '009', nombre: 'Banco Diners Club del Ecuador' },
    { codigo: '010', nombre: 'Banco Solidario' },
    { codigo: '011', nombre: 'Banco de Loja' },
    { codigo: '012', nombre: 'Banco del Austro' },
    { codigo: '013', nombre: 'Banco Produbanco' },
    { codigo: '014', nombre: 'Banco Comercial de Manabí' },
    { codigo: '015', nombre: 'Banco Finca' },
    { codigo: '016', nombre: 'Banco Promerica' },
    { codigo: '017', nombre: 'Banco de Guayaquil' },
    { codigo: '018', nombre: 'Banco Capital' },
    { codigo: '019', nombre: 'Banco Comercial del Azuay' },
    { codigo: '020', nombre: 'Banco Fassil' },
    { codigo: '021', nombre: 'Banco Cofiec' },
  ],
};
