import React, { useState, useEffect } from 'react';

import * as Icon from 'react-feather';
import Modal from '../../../components/Modal';
import ModalEliminar from '../../../components/ModalEliminar';

import { custom } from '../../../helpers/alertas';
import { app } from '../../../config/firebaseConfig';

const AreasDeTrabajo = () => {
  const [listadoArea, setListadoArea] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [idEditar, setIdEditar] = useState('');
  const [valor, setValor] = useState({
    item: '',
    area: '',
  });

  //========== capturar lo q se escribe ==========//
  const handleChangeRedes = (e) => {
    const { name, value } = e.target;
    setValor({ ...valor, [name]: value });
  };

  //========== abrir modal ==========//
  const abrirModalInsertar = () => {
    setValor({
      item: [],
      area: '',
    });
    setModalInsertar(true);
  };

  //========== Funcion Editar y Eliminar ==========//
  const iconoE = (elemento, caso) => {
    setIdEditar(elemento.id);
    setValor(elemento);
    caso === 'Editar' ? setModalEditar(true) : setModalEliminar(true);
  };

  //========== Funcion para Editar ==========//
  const editar = async () => {
    try {
      await app
        .firestore()
        .collection('areaTrabajo')
        .doc(idEditar)
        .update(valor)
        .then((resp) => {
          setModalEditar(false);
        });
      custom('Área de Trabajo Actualizada Correctamente', 'success');
    } catch (error) {
      custom('Ocurrio un error intente nuevamente', 'error');
    }
  };

  //========== Funcion para Eliminar con Confirmacion ==========//
  const confirmarEliminar = () => {
    try {
      app
        .firestore()
        .collection('areaTrabajo')
        .doc(idEditar)
        .delete()
        .then((resp) => {
          setModalEliminar(false);
        });
      custom('Área de Trabajo Eliminada Correctamente', 'success');
    } catch (error) {
      custom('Ocurrio un error intente nuevamente', 'error');
    }
  };

  const insertar = () => {
    let valorInsertar = valor;
    valorInsertar.item = listadoArea.length + 1;

    guardarDatos(valorInsertar);
  };

  const guardarDatos = async (valorInsertar) => {
    try {
      await app
        .firestore()
        .collection('areaTrabajo')
        .add(valorInsertar)
        .then((resp) => {
          setModalInsertar(false);
        });
      custom('Área de Trabajo Registrada Correctamente', 'success');
    } catch (error) {
      custom('Ocurrió un error intente nuevamente', 'error');
    }
  };

  const getDatos = async () => {
    await app
      .firestore()
      .collection('areaTrabajo')
      .orderBy('item')
      .onSnapshot((query) => {
        const listado = [];
        query.forEach((doc) => {
          // console.log(doc.data())
          listado.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        // console.log(listado)
        setListadoArea(listado);
      });
  };

  useEffect(() => {
    getDatos();
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="col-span-12 lg:col-span-12">
          <div className="box overflow-auto lg:overflow-visible">
            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200">
              <h2 className="font-medium text-base mr-auto">
                Áreas de Trabajo
              </h2>
              <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                <button
                  className="flex button text-white bg-theme-1 shadow-md mr-2"
                  // onClick={añadirRedSocial}
                  onClick={() => abrirModalInsertar()}
                >
                  <Icon.Edit3 className="w-4 h-4 mr-2" />
                  Registrar Área de Trabajo
                </button>
              </div>
            </div>
            <div
              className="p-5"
              id="input"
            >
              <div className="preview">
                {listadoArea.length === 0 && (
                  <div className="flex justify-center">
                    No se han Registrado Areas de Trabajo
                  </div>
                )}

                {listadoArea.length > 0 && (
                  <table className="table table-report sm:mt-2">
                    <thead>
                      <tr className="box bg-theme-26 p-1">
                        <th className="whitespace-no-wrap text-center">
                          Profesión / Ocupación
                        </th>
                        <th className="whitespace text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listadoArea.map((elemento, i) => (
                        <tr key={i}>
                          <td className="flex justify-center">
                            {elemento.area}
                          </td>
                          <td>
                            <div className="flex justify-center">
                              <a
                                className="flex items-center mr-3 text-theme-1"
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  iconoE(elemento, 'Editar');
                                }}
                              >
                                <Icon.Edit className="w-5 h-5" />
                              </a>
                              <a
                                className="flex items-center text-theme-6"
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  iconoE(elemento, 'Eliminar');
                                }}
                              >
                                <Icon.Trash2 className="w-5 h-5" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalInsertar && (
        <Modal
          title="Agregar Area de Trabajo"
          close={(e) => setModalInsertar(false)}
          save={insertar}
          cancel={(e) => setModalInsertar(false)}
        >
          <div className="intro-y col-span-12 sm:col-span-12">
            <label className="font-medium bg-theme-26 box p-1">
              Profesión / Ocupación: *
            </label>
            <input
              type="text"
              name="area"
              className="w-full rounded-full border input p-1 mt-2"
              placeholder="Ingrese Profesión / Ocupación"
              value={listadoArea.valor}
              onChange={handleChangeRedes}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </Modal>
      )}

      {modalEditar && (
        <Modal
          title="Editar Profesión / Ocupación"
          close={(e) => setModalEditar(false)}
          save={editar}
          cancel={(e) => setModalEditar(false)}
        >
          <div className="intro-y col-span-12 sm:col-span-12">
            <label className="font-medium bg-theme-26 box p-1">
              Profesión / Ocupación: *
            </label>
            <input
              type="text"
              name="area"
              className="w-full rounded-full border input p-1 mt-2"
              placeholder="Editar Profesión / Ocupación"
              value={valor && valor.area}
              onChange={handleChangeRedes}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </Modal>
      )}

      {modalEliminar && (
        <ModalEliminar
          title="Eliminar Profesión / Ocupación"
          close={(e) => setModalEliminar(false)}
          save={confirmarEliminar}
          cancel={(e) => setModalEliminar(false)}
        >
          <div className="intro-y col-span-12 sm:col-span-12">
            <h1 className="font-medium bg-theme-26 box p-1 text-center">
              ¿ Está seguro de eliminar la Profesión u Ocupación de{' '}
              {valor && valor.area} ?
            </h1>
          </div>
        </ModalEliminar>
      )}
    </>
  );
};

export default AreasDeTrabajo;
