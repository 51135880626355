/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Modal from '../ModalVideo';
import { HashLink as Link } from 'react-router-hash-link';

const Banner = () => {
  //========== USE-STATE ==========//
  const [video, setVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openVideo = () => {
    setVideo(true);
    setShowModal(true);
  };

  const AbrirMenuResponsive = () => {
    const menu = document.querySelector('#menu');
    menu.classList.toggle('activar');
  };

  const close = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className='w-full relative'>
        <img className='w-full h-auto' src='/decore.png' alt='imagen' />
        <div className='flex w-full absolute top-0 left-0'>
          <div className='container-custom'>
            <div className='mainmenu flex items-center'>
              <div className='grid grid-cols-12 gap-4 row-gap-5'>
                <div className='relative col-span-4 sm:col-span-3'>
                  <img className='' src='/logoBanner.png' alt='imagen' />
                </div>
                <div
                  id='menu'
                  className='items relative col-span-4 sm:col-span-8 flex items-center  justify-end'
                >
                  <Link
                    smooth
                    to='#proceso'
                    className='activo font-montserrat text-white font-medium text-base mx-8'
                    activeClassName='activo'
                  >
                    Proceso
                  </Link>
                  <Link
                    smooth
                    to='#testimonio'
                    className='activo font-montserrat text-white font-medium text-base mx-8'
                  >
                    Testimonios
                  </Link>
                  <Link
                    smooth
                    to='#contacto'
                    className='activo font-montserrat text-white font-medium text-base mx-8'
                  >
                    Contacto
                  </Link>
                  <a
                    href='/login'
                    className='font-montserrat bg-gray-300 rounded-t-md rounded-b-md p-0 w-32 h-8 text-blue-800 font-bold text-base boton-entrar flex items-center justify-center ml-8 mt-3 lg:hidden'
                  >
                    Inicia Sesión
                  </a>
                  <a
                    href='/register'
                    className='font-montserrat bg-gray-300 rounded-t-md rounded-b-md p-0 w-32 h-8 text-blue-800 font-bold text-base boton-entrar flex items-center justify-center ml-8 mt-3 lg:hidden'
                  >
                    Registrate
                  </a>
                </div>
                <div className='items flex items-center relative col-span-4 sm:col-span-1'>
                  <a
                    href='/login'
                    className='font-montserrat bg-white rounded-t-md rounded-b-md p-0 w-24 h-10 text-blue-800 font-bold text-base boton-entrar flex items-center justify-center'
                  >
                    Entrar
                  </a>
                </div>
              </div>
              <div className='block lg:hidden'>
                <button
                  className=' flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white'
                  onClick={AbrirMenuResponsive}
                >
                  <svg
                    className='fill-current h-3 w-3'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <title>Menu</title>
                    <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                  </svg>
                </button>
              </div>
            </div>
            <div className='grid grid-cols-12 gap-4 row-gap-5 mt-24 custom-banner'>
              <div className='col-span-12 sm:col-span-6 flex-col mt-10'>
                <p className='font-montserrat uppercase text-gray-650 font-bold text-lg tracking-widest2'>
                  Tu mejor opción para salir de apuros
                </p>
                <h1 className='text-gray-750 text-6xl font-extrabold leading-11 tracking-tighter text-resp '>
                  <span>Micro</span>
                  <span className=''>
                    préstamos
                    <img
                      className='hidden_raya mx-40'
                      src='/rayaverde.png'
                      alt='raya'
                    />
                  </span>{' '}
                  para venezolanos
                  <br />
                  en el extranjero
                </h1>
                <span className='font-montserrat text-gray-650 font-medium text-lg'>
                  ¿Tienes una urgencia económica? ¿necesitas un préstamo en tu
                  país actual o uno para ayudar a tu familia en Venezuela?,
                  obtén un micropréstamo con ChamoPresta y sin necesidad de
                  poner ningún tipo de garantía.
                </span>
                <div className='grid grid-cols-12 gap-4 row-gap-5 mt-8'>
                  <div className='col-span-12 sm:col-span-7 flex-col'>
                    <button className='bg-marron w-full h-60 rounded-t-md rounded-b-md'>
                      <a
                        href='/register'
                        className='text-white font-montserrat font-bold text-lg '
                      >
                        Obtén tu primer préstamo {'>'}
                      </a>
                    </button>
                  </div>
                  <div className='col-span-12 sm:col-span-5 flex'>
                    <img
                      src='/botonPlay.png'
                      alt='Play'
                      className='cursor-pointer w-60 h-60'
                      onClick={openVideo}
                    />

                    <span
                      href='#'
                      className='font-montserrat font-medium text-lg mt-5'
                    >
                      Reproducir vídeo
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ************MODAL************  */}
        {showModal && (
          <Modal
            title='Video Informativo'
            close={close}
            customBtn={true}
            textcustomBtn='Cerrar'
            size='xl'
            save={() => {
              setShowModal(false);
            }}
          >
            {video && (
              <ReactPlayer
                url={require('../../videos/video02.mp4')}
                width='100%'
                height='auto'
                controls
                playing
              />
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

export default Banner;
