import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import feather from 'feather-icons';
import * as Icon from 'react-feather';
import ModalExit from '../ModalExit';

import logo from '../../statics/images/logo-only.png';

const MobileMenu = () => {
  const myUser = useSelector((state) => state.sesion.myUser);
  const [modalExit, setModalexit] = useState(false);

  const onClose = () => {
    setModalexit(false);
  };

  const handleLogout = () => {
    setModalexit(true);
  };
  useEffect(() => {
    (function ($) {
      feather.replace({
        'stroke-width': 1.5,
      });
      $('#mobile-menu-toggler').on('click', function () {
        if ($('.mobile-menu').find('ul').first().is(':visible')) {
          $('.mobile-menu').find('ul').first().slideUp();
        } else {
          $('.mobile-menu').find('ul').first().slideDown();
        }
      });
      $('.mobile-menu')
        .find('.menu')
        .on('click', function () {
          if ($(this).parent().find('ul').length) {
            if ($(this).parent().find('ul').first().is(':visible')) {
              $(this)
                .find('.menu__sub-icon')
                .removeClass('transform rotate-180');
              $(this)
                .parent()
                .find('ul')
                .first()
                .slideUp({
                  done: function () {
                    $(this).removeClass('menu__sub-open');
                  },
                });
            } else {
              $(this).find('.menu__sub-icon').addClass('transform rotate-180');
              $(this)
                .parent()
                .find('ul')
                .first()
                .slideDown({
                  done: function () {
                    $(this).addClass('menu__sub-open');
                  },
                });
            }
          }
        });
      $('.mobile-menu')
        .find('.clickable')
        .on('click', function () {
          $('.mobile-menu').find('ul').first().slideUp();
        });
    })($);
  }, []);

  return (
    <div className='mobile-menu md:hidden'>
      <div className='mobile-menu-bar'>
        <a href='!#' className='flex mr-auto'>
          <img alt='Hispanos Soluciones' className='w-12' src={logo} />
        </a>
        <button id='mobile-menu-toggler'>
          <i
            data-feather='bar-chart-2'
            className='w-8 h-8 text-white transform -rotate-90'
          ></i>
        </button>
      </div>
      <ul className='border-t border-theme-24 py-5 hidden'>
        {myUser.rol === 'Usuario' && (
          <>
            <li>
              <NavLink
                to='/inicio'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='menu__title'>Inicio</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/historial'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.ShoppingCart />
                </div>
                <div className='menu__title'>Historial</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.User />
                </div>
                <div className='menu__title'>Perfil</div>
              </NavLink>
            </li>
          </>
        )}

        {myUser.rol === 'Gestor' && (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Home />
                </div>
                <div className='menu__title'>Dashboard</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/calculadora'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Cpu />
                </div>
                <div className='menu__title'>Calculadora</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/cobranzas'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.PhoneCall />
                </div>
                <div className='menu__title'>Cobranzas</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.User />
                </div>
                <div className='menu__title'>Perfil</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamorapido'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Trello />
                </div>
                <div className='menu__title'>Préstamos Rapidos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/validarprestamos'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='menu__title'>Validar Usuarios</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/superchat'
                className='menu w-full relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.MessageCircle />
                </div>
                <div className='menu__title'>Super Chat</div>
              </NavLink>
            </li>
          </>
        )}

        {myUser.rol === 'Admin' && (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Home />
                </div>
                <div className='menu__title'>Dashboard</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamos'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='menu__title'>Prestamos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/usuarios'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Users />
                </div>
                <div className='menu__title'>Usuarios</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/mensajes'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.MessageSquare />
                </div>
                <div className='menu__title'>Mensajes Predeterminados</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/configuracion'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Settings />
                </div>
                <div className='menu__title'>Configuracion</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.User />
                </div>
                <div className='menu__title'>Perfil</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/superchat'
                className='menu w-full relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.MessageCircle />
                </div>
                <div className='menu__title'>Super Chat</div>
              </NavLink>
            </li>
          </>
        )}

        {myUser.rol === 'SuperAdmin' && (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Home />
                </div>
                <div className='menu__title'>Dashboard</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/calculadora'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Cpu />
                </div>
                <div className='menu__title'>Calculadora</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/configuracion'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Settings />
                </div>
                <div className='menu__title'>Configuración</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/contabilidad'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.FolderPlus />
                </div>
                <div className='menu__title'>Contabilidad</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/cuentasporcobrar'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.CreditCard />
                </div>
                <div className='menu__title'>Cuentas x Cobrar</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/sinfondos'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.List />
                </div>
                <div className='menu__title'>Listado sin Fondos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/notificaciones'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.AtSign />
                </div>
                <div className='menu__title'>Notificaciones</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.User />
                </div>
                <div className='menu__title'>Perfil</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamos'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='menu__title'>Préstamos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamorapido'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Trello />
                </div>
                <div className='menu__title'>Préstamos Rapidos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/registros'
                className='menu relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Activity />
                </div>
                <div className='menu__title'>Registro Actividad</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/superchat'
                className='menu w-full relative'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.MessageCircle />
                </div>
                <div className='menu__title'>Super Chat</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/usuarios'
                className='menu relative clickable'
                activeClassName='menu--active'
              >
                <div className='menu__icon'>
                  <Icon.Users />
                </div>
                <div className='menu__title'>Usuarios</div>
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            to='#'
            className='menu relative'
            activeClassName='menu--active'
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            <div className='menu__icon'>
              <Icon.LogOut />
            </div>
            <div className='menu__title'>Cerrar Sesión</div>
          </NavLink>
        </li>
      </ul>
      <ModalExit open={modalExit} onClose={onClose} />
    </div>
  );
};
export default MobileMenu;
