import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UserImage from '../../../statics/images/profile-2.jpg';
import { app } from '../../../config/firebaseConfig';
import Select from 'react-select';

import feather from 'feather-icons';
import * as Icon from 'react-feather';
import Modal from '../../Modal';
import moment from 'moment';

import { success, custom } from '../../../helpers/alertas';
import paises from '../../../config/paises';

import { LOADING, ENDLOADING, GET_MYUSER } from '../../../redux/types';

import { addLog } from '../../../redux/actions/sesionAction';

import {
  updateEditarPerfil,
  modalEditarPerfil,
} from '../../../redux/actions/userAction';

const Perfil = () => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  //========== USE-STATES ==========//
  const [perfil, setPerfil] = useState({
    apellidos: '',
    areaTrabajo: '',
    email: '',
    fechaNacimiemnto: '',
    id: 0,
    ingresoMensualUSD: '',
    localidad: '',
    nombreEmpresa: '',
    nombres: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    rol: '',
    sexo: '',
    telefonoOpcional: '',
    telegram: '',
    trabaja: '',
    whatsapp: '',
  });
  const [editarPerfil, setEditarPerfil] = useState({
    apellidos: '',
    areaTrabajo: '',
    email: '',
    fechaNacimiemnto: '',
    id: 0,
    ingresoMensualUSD: '',
    localidad: '',
    nombreEmpresa: '',
    nombres: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    rol: '',
    sexo: '',
    telefonoOpcional: '',
    telegram: '',
    trabaja: '',
    whatsapp: '',
  });
  const [defaultValue, setDefaultValue] = useState({ label: '', value: '' });
  const [area, setArea] = useState([]);

  let arrayOptions = [];
  arrayOptions = paises.listado.map((item) => {
    return { label: item, value: item };
  });
  const [options, setOptions] = useState(arrayOptions);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const modalEditarPerfiles = useSelector(
    (state) => state.user.modalEditarPerfiles
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
  }, []);

  //========== Funciones Modal Editar Perfil ==========//
  const closeEditarPerfil = () => {
    dispatch(modalEditarPerfil(false));
  };
  const cancelEditarPerfil = () => {
    dispatch(modalEditarPerfil(false));
  };
  const changeAgregarPerfil = (e) => {
    const { name, value } = e.target;
    setPerfil({ ...perfil, [name]: value });
  };
  const changeTrabaja = (e) => {
    const { value } = e.target;
    setPerfil({ ...perfil, ['trabaja']: value });
  };
  const changeSexo = (e) => {
    const { value } = e.target;
    setPerfil({ ...perfil, ['sexo']: value });
  };
  const guardarEditarPerfil = () => {
    if (
      perfil.nombres === '' ||
      perfil.apellidos === '' ||
      perfil.sexo === '' ||
      perfil.numeroCedula === '' ||
      perfil.fechaNacimiento === '' ||
      perfil.pais === '' ||
      perfil.localidad === '' ||
      perfil.whatsapp === '' ||
      perfil.telegram === '' ||
      perfil.trabaja === ''
      // perfil.areaTrabajo === '' ||
      // perfil.nombreEmpresa === '' ||
      // perfil.ingresoMensualUSD === ''
    ) {
      custom('Debe llenar todos los campos obligatorios *', 'error');
    } else {
      const data = {
        apellidos: perfil.apellidos,
        areaTrabajo: perfil.areaTrabajo,
        email: perfil.email,
        fechaNacimiento: perfil.fechaNacimiento,
        idUsuario: myUser.idUsuario,
        ingresoMensualUSD: perfil.ingresoMensualUSD,
        localidad: perfil.localidad,
        nombreEmpresa: perfil.nombreEmpresa,
        nombres: perfil.nombres,
        numeroCedula: perfil.numeroCedula,
        numeroPasaporte: perfil.numeroPasaporte,
        pais: perfil.pais,
        rol: myUser.rol,
        sexo: perfil.sexo,
        telefonoOpcional: perfil.telefonoOpcional,
        telegram: perfil.telegram,
        trabaja: perfil.trabaja,
        whatsapp: perfil.whatsapp,
      };
      dispatch(updateEditarPerfil(data));
      success('Perfil Editado Exitosamente.', 'success');
      const userUpdtae = Object.assign(myUser, data);
      dispatch({ type: GET_MYUSER, payload: userUpdtae });
      dispatch(
        addLog({
          accion: `${'Se Ha Editado Un Perfil: De Nombre '} ${perfil.nombres} ${
            perfil.apellidos
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );

      dispatch(modalEditarPerfil(false));
    }
  };

  // metodo para llamar area trabajo
  const getDatos = async () => {
    await app
      .firestore()
      .collection('areaTrabajo')
      .orderBy('item')
      .onSnapshot((query) => {
        const listado = [];
        query.forEach((doc) => {
          // console.log(doc.data())
          listado.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        // console.log(listado)
        setArea(listado);
      });
  };

  useEffect(() => {
    getDatos();
  }, []);

  const editarPerfiles = (params) => {
    setDefaultValue({ label: params.pais, value: params.pais });
    setEditarPerfil(true);
    setPerfil(params);
    dispatch(modalEditarPerfil(true));
  };
  const changePais = (e) => {
    setDefaultValue(e);
    setPerfil({
      ...perfil,
      pais: e.value,
    });
  };
  const openExplorer = (e) => {
    fileRef.current.click();
  };
  const loadFile = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    upload(file);
  };
  const upload = (file) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(`avatar/${myUser.idUsuario}`);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
          dispatch({ type: ENDLOADING });
        },
        () => {
          app
            .storage()
            .ref(`avatar/${myUser.idUsuario}`)
            .getDownloadURL()
            .then(async (response) => {
              const data = { urlImagen: response };
              await app
                .firestore()
                .collection('usuarios')
                .doc(`${myUser.idUsuario}`)
                .update(data);
              const userUpdtae = Object.assign(myUser, data);
              dispatch({ type: GET_MYUSER, payload: userUpdtae });
              dispatch({ type: ENDLOADING });
            })
            .catch((err) => {
              console.log('Error al actualizar el avatar');
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  const disableAreaTrabajo = () => {
    if (perfil.trabaja === 'Si') {
      return false;
    }
    return (perfil.areaTrabajo = 'Seleccione');
  };
  const disableSalario = () => {
    if (perfil.trabaja === 'Si') {
      return false;
    }
    return (perfil.ingresoMensualUSD = 'Seleccione');
  };

  return (
    <>
      <div className='intro-y box px-5 pt-5 mt-5'>
        <h1 className='font-medium text-base'>Datos Perfil Usuario</h1>
        <hr />

        <div className='flex flex-col lg:flex-row border-b border-gray-200 pb-5 -mx-5'>
          <div className='flex flex-1 px-5 items-center justify-center lg:justify-start'>
            <div className='w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative'>
              <img
                alt='*Imagen Usuario'
                className='rounded-full'
                src={myUser.urlImagen ? myUser.urlImagen : UserImage}
              />
              <button
                className='absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-3 rounded-full p-2l'
                onClick={openExplorer}
              >
                <i className='w-6 h-6 text-white p-1' data-feather='image'></i>
              </button>
              <input
                type='file'
                style={{ display: 'none' }}
                ref={fileRef}
                onChange={loadFile}
              />
            </div>
            <div className='ml-5'>
              <div className='w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg'>
                {myUser.nombres} {myUser.apellidos}
              </div>
              <hr />
              <div className='text-gray-600 mt-4'>
                * Correo : {myUser.email}
              </div>
              <div className='text-gray-600 mt-3'>
                * Cédula : {myUser.numeroCedula}
              </div>
              <div className='text-gray-600 mt-3'>
                * Pasaporte : {myUser.numeroPasaporte}
              </div>
              <div className='text-gray-600 mt-3'>* Sexo : {myUser.sexo}</div>
              <div className='text-gray-600 mt-3'>
                * Nacio el : {myUser.fechaNacimiento}
              </div>
              <div className='flex col-span-12 sm:col-span-6 bg-theme-5 items-center font-medium mt-10 p-2 box'>
                <a
                  className='flex items-center mr-3 text-theme-1 text-lg'
                  href='javascript:;'
                  onClick={(e) => editarPerfiles(myUser)}
                >
                  <Icon.Edit className='w-6 h-6 mr-2' />
                  Editar Perfil
                </a>
              </div>
            </div>
          </div>
          <div className='flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col px-5 border-l border-r border-gray-200 border-t lg:border-t-0 pt-5 lg:pt-0'>
            <h2 className='font-medium lg:text-left lg:mt-8 mb-4 m-auto bg-theme-26 py-2 px-3 box'>
              Datos de Ubicación
            </h2>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * País : {myUser.pais}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Localidad : {myUser.localidad}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Whatsapp : {myUser.whatsapp}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Otro número de contacto : {myUser.telefonoOpcional}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600'>
              * Usuario telegram : {myUser.telegram}
            </div>
          </div>
          <div className='flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col px-5 border-gray-200 border-t lg:border-t-0 pt-5 lg:pt-0'>
            <h2 className='font-medium lg:text-left lg:mt-8 mb-4 m-auto bg-theme-26 py-2 px-3 box'>
              Datos Laborales
            </h2>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Trabaja : {myUser.trabaja}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Area de trabajo : {myUser.areaTrabajo}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Nombre empresa : {myUser.nombreEmpresa}
            </div>
            <div className='truncate sm:whitespace-normal flex items-center text-gray-600 mb-3'>
              * Salario : {myUser.ingresoMensualUSD} $
            </div>
            <h2 className='font-medium lg:text-left lg:mt-5 mb-4 m-auto bg-theme-26 py-2 px-3 box'>
              Otros Datos
            </h2>
            <div className='text-gray-600 mb-3'>* Rol : {myUser.rol}</div>
            <div className='text-gray-600'>
              * Fecha registro : {myUser.fechaRegistro}
            </div>
          </div>
        </div>
      </div>

      {/*===== Modal Editar Perfil =====*/}
      {modalEditarPerfiles && (
        <Modal
          title='Editar Perfil'
          close={closeEditarPerfil}
          save={guardarEditarPerfil}
          cancel={cancelEditarPerfil}
          size='xl'
        >
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Nombres completos :
            </label>
            <input
              type='text'
              name='nombres'
              className='w-full rounded-full border input p-1 mt-2'
              autoFocus={true}
              placeholder=' Nombres Completos'
              value={perfil.nombres}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Apellidos completos :
            </label>
            <input
              type='text'
              name='apellidos'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Apellidos Completos'
              value={perfil.apellidos}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>*Cedula :</label>
            <input
              type='text'
              name='numeroCedula'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Cedula de identidad'
              value={perfil.numeroCedula}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              Pasaporte (Opcional) :
            </label>
            <input
              type='text'
              name='numeroPasaporte'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Pasaporte'
              value={perfil.numeroPasaporte}
              onChange={changeAgregarPerfil}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>*Sexo :</label>
            <div className='flex'>
              <div className='flex items-center text-gray-700 mr-2 sm:mt-2'>
                <input
                  name='sexo[]'
                  type='checkbox'
                  value='M'
                  className='input border mr-2'
                  id='sexoM'
                  checked={perfil.sexo === 'M'}
                  onChange={changeSexo}
                />
                <label className='cursor-pointer select-none' htmlFor='sexoM'>
                  M
                </label>
              </div>
              <div className='flex items-center text-gray-700 mr-2 sm:mt-2'>
                <input
                  name='sexo[]'
                  type='checkbox'
                  value='F'
                  className='input border mr-2'
                  id='sexoF'
                  checked={perfil.sexo === 'F'}
                  onChange={changeSexo}
                />
                <label className='cursor-pointer select-none' htmlFor='sexoF'>
                  F
                </label>
              </div>
            </div>
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Fecha nacimiento :
            </label>
            <input
              type='date'
              name='fechaNacimiento'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Fecha Nacimiento'
              value={perfil.fechaNacimiento}
              onChange={changeAgregarPerfil}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>*País :</label>
            <Select
              className='mt-1'
              defaultValue={defaultValue}
              options={options}
              onChange={changePais}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Localidad :
            </label>
            <input
              type='text'
              name='localidad'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder=' Localidad, Estado o Provincia'
              value={perfil.localidad}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Whatsapp :
            </label>
            <input
              type='text'
              name='whatsapp'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='Número de Whatsapp'
              value={perfil.whatsapp}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Teléfono de contacto :
            </label>
            <input
              type='text'
              name='telefonoOpcional'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='Telefono de Contacto (opcional)'
              value={perfil.telefonoOpcional}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Usuario telegram :
            </label>
            <input
              type='text'
              name='telegram'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='Usuario telegram'
              value={perfil.telegram}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Trabaja :
            </label>
            <div className='flex'>
              <div className='flex items-center text-gray-700 mr-2 sm:mt-2'>
                <input
                  name='trabaja[]'
                  type='checkbox'
                  value='Si'
                  className='input border mr-2'
                  id='trabajaSi'
                  checked={perfil.trabaja === 'Si'}
                  onChange={changeTrabaja}
                />
                <label
                  className='cursor-pointer select-none'
                  htmlFor='trabajaSi'
                >
                  Si
                </label>
              </div>
              <div className='flex items-center text-gray-700 mr-2 sm:mt-2'>
                <input
                  name='trabaja[]'
                  type='checkbox'
                  value='No'
                  className='input border mr-2'
                  id='trabajaNo'
                  checked={perfil.trabaja === 'No'}
                  onChange={changeTrabaja}
                />
                <label
                  className='cursor-pointer select-none'
                  htmlFor='trabajaNo'
                >
                  No
                </label>
              </div>
            </div>
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Área de trabajo :
            </label>
            <select
              type='text'
              name='areaTrabajo'
              className='w-full rounded-full border intro-x input input--sm  border mt-2'
              aria-label='Default select example'
              value={perfil.areaTrabajo}
              disabled={disableAreaTrabajo()}
              onChange={changeAgregarPerfil}
            >
              {area.map((elemento, i) => (
                <option key={i} className='flex justify-center'>
                  {elemento.area}
                </option>
              ))}
            </select>
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Nombre empresa :
            </label>
            <input
              type='text'
              name='nombreEmpresa'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='Nombre de empresa o emprendimiento'
              value={perfil.nombreEmpresa}
              onChange={changeAgregarPerfil}
              onKeyPress={(event) => {
                if (!/[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-4'>
            <label className='font-medium bg-theme-26 box p-1'>
              *Salario ($ USD) :
            </label>
            <select
              type='text'
              name='ingresoMensualUSD'
              className='w-full rounded-full border intro-x input input--sm  border mt-2'
              aria-label='Default select example'
              value={perfil.ingresoMensualUSD}
              disabled={disableSalario()}
              onChange={changeAgregarPerfil}
            >
              <option value=''> Seleccione</option>
              <option value='0-100'> 0 - 100 USD</option>
              <option value='100-300'> 100 - 300 USD </option>
              <option value='300-500'> 300 - 500 USD</option>
              <option value='500-800'> 500 - 800 USD</option>
              <option value='800-1500'> 800 - 1500 USD</option>
              <option value='1500 o +'>1500 - ó mas USD</option>
            </select>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Perfil;
