import {
  GET_USUARIOSCONPRESTAMO,
  GET_USERS,
  GET_LISTPRESTAMOS,
  GET_PRESTAMO,
  GET_LINKSOCIALES,
  GET_REQUERIMIENTOSUSUARIOS,
  GET_REQUERIMIENTOPRESTAMO,
  GET_CONTACTOSOCIALES,
  MODAL_CREARCONTACTOSOCIALES,
  MODAL_EDITARCONTACTOSOCIALES,
  MODAL_ENVIOCORREOMENSAJERECHAZO,
  MODAL_ENVIOCORREORECHAZOSOLICITUD,
  GET_REDESSOCIALES,
  GET_LISTPRESTAMOSAPROBADOS,
  GETCHAT,
  GET_FILTERPRESTAMOS,
  GET_ABONOSREALIZADOS,
  GET_LISTPRESTAMOSGESTOR,
  GET_PRESTAMOSGESTORAPROBADO,
  GET_PRESTAMOSGESTORACOBRAR,
  GET_PRESTAMOSGESTORPROCESO,
  GET_PRESTAMOSGESTORPENDIENTE,
  GET_PRESTAMOSGESTORRECHAZADO,
} from '../types';

const initialState = {
  usuarioConPrestamo: [],
  allUsers: [],
  listPrestamos: [],
  prestamo: {},
  contactosSociales: [],
  linkSociales: [],
  requerimientosUsuarios: [],
  requerimientoPrestamo: [],
  crearContactosSociales: [],
  redesSociales: [],
  modalEditarContactoSociales: false,
  modalCrearContactoSociales: false,
  modalEnvioCorreoMensajeRechazo: false,
  modalEnvioCorreoRechazoSolicitud: false,
  listPrestamosAprobados: [],
  chat: {},
  filterprestamos: [],
  abonosRealizados: [],
  listPrestamosGestor: [],
  prestamosGestorAprobado: [],
  prestamosGestorAcobrar: [],
  prestamosGestorProceso: [],
  prestamosGestorPendiente: [],
  prestamosGestorRechazado: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USUARIOSCONPRESTAMO:
      return {
        ...state,
        usuarioConPrestamo: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_LISTPRESTAMOS:
      return {
        ...state,
        listPrestamos: action.payload,
      };
    case GET_PRESTAMO:
      return {
        ...state,
        prestamo: action.payload,
      };
    case GET_CONTACTOSOCIALES:
      return {
        ...state,
        contactosSociales: action.payload,
      };
    case GET_LINKSOCIALES:
      return {
        ...state,
        linkSociales: action.payload,
      };
    case GET_REQUERIMIENTOSUSUARIOS:
      return {
        ...state,
        requerimientosUsuarios: action.payload,
      };
    case GET_REQUERIMIENTOPRESTAMO:
      return {
        ...state,
        requerimientoPrestamo: action.payload,
      };
    case GET_REDESSOCIALES:
      return {
        ...state,
        redesSociales: action.payload,
      };
    case MODAL_CREARCONTACTOSOCIALES:
      return {
        ...state,
        modalCrearContactoSociales: action.payload,
      };
    case MODAL_EDITARCONTACTOSOCIALES:
      return {
        ...state,
        modalEditarContactoSociales: action.payload,
      };
    case MODAL_ENVIOCORREOMENSAJERECHAZO:
      return {
        ...state,
        modalEnvioCorreoMensajeRechazo: action.payload,
      };
    case MODAL_ENVIOCORREORECHAZOSOLICITUD:
      return {
        ...state,
        modalEnvioCorreoRechazoSolicitud: action.payload,
      };
    case GET_LISTPRESTAMOSAPROBADOS:
      return {
        ...state,
        listPrestamosAprobados: action.payload,
      };
    case GETCHAT:
      return {
        ...state,
        chat: action.payload,
      };
    case GET_FILTERPRESTAMOS:
      return {
        ...state,
        filterprestamos: action.payload,
      };
    case GET_ABONOSREALIZADOS:
      return {
        ...state,
        abonosRealizados: action.payload,
      };
    case GET_LISTPRESTAMOSGESTOR:
      return {
        ...state,
        listPrestamosGestor: action.payload,
      };
    case GET_PRESTAMOSGESTORAPROBADO:
      return {
        ...state,
        prestamosGestorAprobado: action.payload,
      };
    case GET_PRESTAMOSGESTORACOBRAR:
      return {
        ...state,
        prestamosGestorAcobrar: action.payload,
      };
    case GET_PRESTAMOSGESTORPROCESO:
      return {
        ...state,
        prestamosGestorProceso: action.payload,
      };
    case GET_PRESTAMOSGESTORPENDIENTE:
      return {
        ...state,
        prestamosGestorPendiente: action.payload,
      };
    case GET_PRESTAMOSGESTORRECHAZADO:
      return {
        ...state,
        prestamosGestorRechazado: action.payload,
      };
    default:
      return state;
  }
}
