import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// Actions
import { setView } from '../redux/actions/menuActions';

// Components Layout
import MobileMenu from './top/MobileMenu';
import SideMenu from './top/SideMenu';
import AccountMenu from './top/AccountMenu';
import BreadCrumb from './top/BreadCrumb';
import Loading from './Loading';

// Components
import DashboardSuperAdmin from './superadmin/dashboard/Dashboard';
import DashboardGestor from './gestor/dashboard/Dashboard';
import DashboardAdmin from './admin/dashboard/Dashboard';

import PrestamosSuperAdmin from './superadmin/prestamos/Prestamos';
import PrestamosGestor from './gestor/validarprestamos/ListadoPrestamos';
import PrestamosAdmin from './admin/prestamos/Prestamos';
import PrestamosUser from './user/prestamos/Prestamos';

import Perfil from './user/perfil/Perfil';
import SuperChat from './gestor/superchat/superChat';

import UsuarioSuperAdmin from './superadmin/users/UsuarioSuperAdmin';
import GestionarUsuario from './admin/users/GestionarUsuario';

import Configuration from './admin/configuration/Configuration';

//super Admin
import Contabilidad from './superadmin/contabilidad/Contabilidad';
import Notificaciones from './superadmin/notificaciones/Notificaciones';
import Sinfondos from './superadmin/sinfondos/Sinfondos';
import CuentasPorCobrar from './superadmin/porcobrar/CuentasPorCobrar';
import RegistroActividades from './superadmin/registros/RegistroActividades';
import DetalleSuperAdmin from './superadmin/prestamos/Detalle';
import Calculadora from './superadmin/calculadora/Calculadora';
import GenerarPrestamo from './superadmin/prestamorapido/GenerarPrestamo';
import UsuariosPrestamo from './superadmin/prestamorapido/UsuariosPrestamo';

//Admin
import MensajesPredeterminados from './admin/mensajes/MensajesPredeterminados';
import DetalleAdmin from './admin/prestamos/Detalle';

//gestor
import { Cobranza } from './gestor/cobranzas/Cobranza';
import { GestionarCobranza } from './gestor/cobranzas/GestionarCobranza';
import GestionarPrestamo from './gestor/validarprestamos/GestionarPrestamo';

//usuario
import MisPrestamos from './user/misprestamos/MisPrestamos';
import Abonos from './user/misprestamos/Abonos';
import Bloqueado from './user/prestamos/Bloqueado';

const IndexTotal = ({ item }) => {
  document.body.classList.remove('login');
  document.body.classList.add('app');
  const dispatch = useDispatch();
  const params = useParams();
  const myUser = useSelector((state) => state.sesion.myUser);
  const loading = useSelector((state) => state.sesion.loading);

  const getComponentView = () => {
    let componentView;
    if (myUser.rol === 'Usuario') {
      if (window.location.pathname === '/inicio') {
        dispatch(setView('Préstamo'));
        componentView = <PrestamosUser />;
      } else if (window.location.pathname === '/perfil') {
        dispatch(setView('Perfil'));
        componentView = <Perfil />;
      } else if (window.location.pathname.includes('/historial')) {
        if (params.id) {
          dispatch(setView('Abonos'));
          componentView = <Abonos />;
        } else {
          dispatch(setView('Historial'));
          componentView = <MisPrestamos />;
        }
      } else {
        return <Redirect to='/' />;
      }
    } else if (myUser.rol === 'Gestor') {
      if (window.location.pathname === '/dashboard') {
        dispatch(setView('Dashboard'));
        componentView = <DashboardGestor />;
      } else if (window.location.pathname === '/inicio') {
        dispatch(setView('Dashboard'));
        return <Redirect to='/dashboard' />;
      } else if (window.location.pathname.includes('/validarprestamos')) {
        if (params.userid) {
          dispatch(setView('Validar Gestion Préstamos'));
          componentView = <GestionarPrestamo />;
        } else {
          dispatch(setView('Listado Préstamos Por Validación'));
          componentView = <PrestamosGestor />;
        }
      } else if (window.location.pathname.includes('/cobranzas')) {
        if (params.userid) {
          dispatch(setView('Gestionar Cobranzas'));
          componentView = <GestionarCobranza />;
        } else {
          dispatch(setView('Listado Cobranzas'));
          componentView = <Cobranza />;
        }
      } else if (window.location.pathname === '/calculadora') {
        dispatch(setView('Calculadora'));
        componentView = <Calculadora />;
      } else if (window.location.pathname === '/superchat') {
        dispatch(setView('Super Chat'));
        componentView = <SuperChat />;
      } else if (window.location.pathname === '/perfil') {
        dispatch(setView('Perfil'));
        componentView = <Perfil />;
      } else if (window.location.pathname.includes('/prestamorapido')) {
        if (params.UserId) {
          dispatch(setView('Generar Nuevo Préstamo'));
          componentView = <GenerarPrestamo />;
        } else {
          dispatch(setView('Usuarios'));
          componentView = <UsuariosPrestamo />;
        }
      } else {
        return <Redirect to='/' />;
      }
    } else if (myUser.rol === 'Admin') {
      if (window.location.pathname === '/dashboard') {
        dispatch(setView('Dashboard'));
        componentView = <DashboardAdmin />;
      } else if (window.location.pathname === '/inicio') {
        dispatch(setView('Dashboard'));
        return <Redirect to='/dashboard' />;
      } else if (window.location.pathname.includes('/prestamos')) {
        if (params.prestamoid) {
          dispatch(setView('Detalle de préstamo'));
          componentView = <DetalleAdmin />;
        } else {
          dispatch(setView('Préstamos'));
          componentView = <PrestamosAdmin />;
        }
      } else if (window.location.pathname === '/usuarios') {
        dispatch(setView('Listado Usuarios'));
        componentView = <GestionarUsuario />;
      } else if (window.location.pathname === '/configuracion') {
        dispatch(setView('Configuración'));
        componentView = <Configuration />;
      } else if (window.location.pathname === '/mensajes') {
        dispatch(setView('Mensajes Predeterminados'));
        componentView = <MensajesPredeterminados />;
      } else if (window.location.pathname === '/superchat') {
        dispatch(setView('Super Chat'));
        componentView = <SuperChat />;
      } else if (window.location.pathname === '/perfil') {
        dispatch(setView('Perfil'));
        componentView = <Perfil />;
      } else {
        return <Redirect to='/' />;
      }
    } else if (myUser.rol === 'SuperAdmin') {
      if (window.location.pathname === '/dashboard') {
        dispatch(setView('Dashboard'));
        componentView = <DashboardSuperAdmin />;
      } else if (window.location.pathname === '/inicio') {
        dispatch(setView('Dashboard'));
        return <Redirect to='/dashboard' />;
      } else if (window.location.pathname.includes('/prestamos')) {
        if (params.prestamoid) {
          dispatch(setView('Detalle de préstamo'));
          componentView = <DetalleSuperAdmin />;
        } else {
          dispatch(setView('Préstamos'));
          componentView = <PrestamosSuperAdmin />;
        }
      } else if (window.location.pathname.includes('/prestamorapido')) {
        if (params.UserId) {
          dispatch(setView('Generar Nuevo Préstamo'));
          componentView = <GenerarPrestamo />;
        } else {
          dispatch(setView('Préstamos'));
          componentView = <UsuariosPrestamo />;
        }
      } else if (window.location.pathname === '/contabilidad') {
        dispatch(setView('Estado de Cuenta'));
        componentView = <Contabilidad />;
      } else if (window.location.pathname === '/usuarios') {
        dispatch(setView('Listado de Usuarios'));
        componentView = <UsuarioSuperAdmin />;
      } else if (window.location.pathname === '/cuentasporcobrar') {
        dispatch(setView('Listado de Cuentas Por Cobrar'));
        componentView = <CuentasPorCobrar />;
      } else if (window.location.pathname === '/registros') {
        dispatch(setView('Listado Actividades de Usuarios'));
        componentView = <RegistroActividades />;
      } else if (window.location.pathname === '/notificaciones') {
        dispatch(setView('Gestionar de notificaciones'));
        componentView = <Notificaciones />;
      } else if (window.location.pathname === '/sinfondos') {
        dispatch(setView('Listado de usuarios ingresados sin fondos'));
        componentView = <Sinfondos />;
      } else if (window.location.pathname === '/calculadora') {
        dispatch(setView('Calculadora'));
        componentView = <Calculadora />;
      } else if (window.location.pathname === '/configuracion') {
        dispatch(setView('Configuración'));
        componentView = <Configuration />;
      } else if (window.location.pathname === '/superchat') {
        dispatch(setView('Super Chat'));
        componentView = <SuperChat />;
      } else if (window.location.pathname === '/perfil') {
        dispatch(setView('Perfil'));
        componentView = <Perfil />;
      } else {
        return <Redirect to='/' />;
      }
    }
    return componentView;
  };

  if (!myUser) {
    return <Loading />;
  } else if (myUser.rol === 'Bloqueado') {
    return <Bloqueado />;
  } else {
    return (
      <React.Fragment>
        {loading ? <Loading /> : <></>}
        <MobileMenu />
        <div className='flex'>
          <SideMenu />
          <div className='content'>
            <div className='top-bar'>
              <BreadCrumb />
              <AccountMenu />
            </div>
            <div>{getComponentView()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default IndexTotal;
