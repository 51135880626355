import {
  ALL_PAGES,
  ALL_ELEMENTS,
  GET_USERS,
  GET_CONFIGURATION,
  SET_CONFIGURATION,
  SET_EMAILEMPRESA,
  SET_CONTACT,
  SET_WHATSAPP,
  SET_FACEBOOKPRICE,
  SET_WEBSPRICE,
  SET_ALLCHANGES,
  SET_WEBSTEXT,
  SET_FBTEXT,
  SET_STATUS,
  ENDUPLOAD,
  CLOSE_MODAL_ADMIN,
  TEST,
  VIEW_REPORT,
  DELETE_USER,
  SET_INTERES,
  MODAL_AGREGARMENSAJES,
  MODAL_EDITARMENSAJES,
  GET_MENSAJESPREDEFINIDOS,
  GET_PRESTAMO,
  GET_LINKSOCIALES,
  GET_REQUERIMIENTOSUSUARIOS,
  GET_REQUERIMIENTOPRESTAMO,
  GET_LISTPRESTAMOS,
  GETCHAT,
  GET_LISTGESTORES,
  } from "../types";

const initialState = {
  configuration: {},
  allUsers: [],
  allPages: [],
  pagesErrors: [],
  fbPages: 0,
  fbPagesDone: 0,
  wPages: 0,
  wPagesDone: 0,
  elements: [],
  elementsDone: [],
  elementsBad: [],
  modal: false,
  bad: 0,
  done: 0,
  exist: 0,
  all: 0,
  viewReport: "Webs",
  interes: 0,
  modalAgregarMensajes: false,
  modalEditarMensajes: false,
  mensajespredefinidos: [],
  prestamo: {},
  linkSociales: [],
  requerimientosUsuarios: [],
  requerimientoPrestamo: [],
  listPrestamos: [],
  listGestores: [],
  arrayFinal:[],
  chat: {},
};

export default function (state = initialState, action) {
  let index;
  switch (action.type) {
    case VIEW_REPORT:
      return {
        ...state,
        viewReport: action.payload,
      };
    case TEST:
      return {
        ...state,
      };
    case CLOSE_MODAL_ADMIN:
      return {
        ...state,
        modal: false,
      };
    case ENDUPLOAD:
      if (action.categoria === "Facebook") {
        state.fbPages += action.done;
      } else if (action.categoria === "Webs") {
        state.wPages += action.done;
      } else {
        state.elements += action.done;
      }
      return {
        ...state,
        modal: true,
        bad: action.bad,
        done: action.done,
        exist: action.exist,
        all: action.all,
      };
    case ALL_PAGES:
      return {
        ...state,
        fbPages: action.fbPages,
        fbPagesDone: action.fbPagesDone,
        wPages: action.wPages,
        wPagesDone: action.wPagesDone,
        allPages: action.allPages,
        pagesErrors: action.pagesErrors,
      };
    case ALL_ELEMENTS:
      return {
        ...state,
        elements: action.elements,
        elementsDone: action.elementsDone,
        elementsBad: action.elementsBad,
      };
    case GET_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case SET_STATUS:
      index = state.allUsers.findIndex((user) => user.id === action.id);
      state.allUsers[index].status = action.status;
      state.allUsers[index].rol = action.rol;
      return {
        ...state,
      };
    case DELETE_USER:
      index = state.allUsers.findIndex((user) => user.id === action.payload);
      if (index !== -1) {
        state.allUsers.splice(index, 1);
      }
      return {
        ...state,
      };
    case GET_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload,
      };
    case SET_CONFIGURATION:
      console.log("action.payload", action.payload);
      return {
        ...state,
        configuration: action.payload,
      };
    case SET_EMAILEMPRESA:
      state.configuration.email = action.payload;
      return {
        ...state,
      };
    case SET_CONTACT:
      state.configuration.contact = action.payload;
      return {
        ...state,
      };
    case SET_WHATSAPP:
      state.configuration.whatsapp = action.payload;
      return {
        ...state,
      };
    case SET_FACEBOOKPRICE:
      state.configuration.facebookPrice = action.payload;
      return {
        ...state,
      };
    case SET_WEBSPRICE:
      state.configuration.websPrice = action.payload;
      return {
        ...state,
      };
    case SET_WEBSTEXT:
      state.configuration.textWebs = action.payload;
      return {
        ...state,
      };
    case SET_FBTEXT:
      state.configuration.textFacebook = action.payload;
      return {
        ...state,
      };
    case SET_ALLCHANGES:
      return {
        ...state,
        configuration: action.payload,
      };
    case SET_INTERES:
      return {
        ...state,
        interes: action.payload,
      };
    case MODAL_AGREGARMENSAJES:
      return {
        ...state,
        modalAgregarMensajes: action.payload,
      };
    case MODAL_EDITARMENSAJES:
      return {
        ...state,
        modalEditarMensajes: action.payload,
      };
    case GET_MENSAJESPREDEFINIDOS:
      return {
        ...state,
        mensajespredefinidos: action.payload,
      };
    case GET_PRESTAMO:
      return {
        ...state,
        prestamo: action.payload,
      };
    case GET_LINKSOCIALES:
      return {
        ...state,
        linkSociales: action.payload,
      };
    case GET_REQUERIMIENTOSUSUARIOS:
      return {
        ...state,
        requerimientosUsuarios: action.payload,
      };
    case GET_REQUERIMIENTOPRESTAMO:
      return {
        ...state,
        requerimientoPrestamo: action.payload,
      };

    case GET_LISTPRESTAMOS:
      return {
        ...state,
        listPrestamos: action.payload,
      };
    case GETCHAT:
      return {
        ...state,
        chat: action.payload,
      };

      case GET_LISTGESTORES:      /**/
      return {
        ...state,
        listGestores: action.payload,
      };

        
      
    default:
      return state;
  }
}
