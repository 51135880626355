import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { axiosInstance } from '../../../config/api';
import * as Icon from 'react-feather';

import {
  useDaterangerpicker,
  useIcons,
  useTooltip,
  useTabs,
} from '../../../helpers/jqueryFunctions';

import Datosgenerales from '../datosgenerales/Datosgenerales';
import Verificacion from '../verificacion/Verificacion';
import Revision from '../revision/Revision';
import AccordionBancos from '../accordions/AccordionBanco';
import Modal from '../../Modal';
import Chat from '../../chat/Chat';
import Abonos from '../abonos/Abonos';
import { confirm, custom } from '../../../helpers/alertas';

import { app } from '../../../config/firebaseConfig';
import {
  getCuentasBancarias,
  getPagosMoviles,
  updateDatosGenereales,
  generatePrestamo,
  getPrestamos,
  createChat,
  culminarPrestamo,
  updateComentarios,
  getChat,
} from '../../../redux/actions/userAction';
import {
  modalTerminoCondicion,
  modalInformacionServicio,
  modalInformacionInicio,
  addLog,
} from '../../../redux/actions/sesionAction';
import {
  PRESTAMOACTUAL,
  GET_CONFIGURACION,
  GET_MYUSER,
} from '../../../redux/types';

import Slider from 'react-slick';
import mensajes from '../../../config/mensajes';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import bancos from '../../../config/listadoBancos';
import SinFondos from './SinFondos';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const hoy = moment(new Date()).format('YYYY-MM-DD');

const Prestamos = () => {
  const dispatch = useDispatch();
  const new_date = moment(new Date());
  const mindate = new_date.add(4, 'days').format('YYYY-MM-DD');
  const hoyBien = moment(new Date()).format('YYYY-MM-DD');

  //========== USE-STATES ==========//
  const [cantidad, setCantidad] = useState(5);
  const [fecha, setFecha] = useState(mindate);
  const [montoBS, setMontoBS] = useState();
  const [totalPagar, setTotalPagar] = useState(0);
  const [deacuerdo, setDeacuerdo] = useState(false);
  const [diasPagar, setDiasPagar] = useState(0);
  const [days, setDays] = useState([]);
  const [tasaDolarHoy, setTasaDolarHoy] = useState(0);
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: 100,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });
  const [bancosVenezuela, setBancosVenezuela] = useState([]);
  const [gestores, setGestores] = useState([]);
  const [administrativos, setAdministrativos] = useState([]);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const indexWizard = useSelector((state) => state.user.indexWizard);
  const cuentas = useSelector((state) => state.user.cuentasBancarias);
  const listadoPagosMoviles = useSelector((state) => state.user.pagoMoviles);
  const selectedCuenta = useSelector((state) => state.user.selectedCuenta);
  const prestamoActual = useSelector((state) => state.user.prestamoActual);
  const sociales = useSelector((state) => state.user.linksociales);
  const datosGenerales = useSelector((state) => state.user.datosGenerales);
  const requerimientos = useSelector((state) => state.user.requerimientos);
  const requerimientoPrestamo = useSelector(
    (state) => state.user.requerimientoPrestamo
  );
  const chat = useSelector((state) => state.user.chat);
  const redesvisitadas = useSelector((state) => state.user.redesvisitadas);
  const redessocialeschamopresta = useSelector(
    (state) => state.configuracion.redessocialeschamopresta
  );
  const modalTerminoCondiciones = useSelector(
    (state) => state.sesion.modalTerminoCondiciones
  );
  const modalInformacionServicios = useSelector(
    (state) => state.sesion.modalInformacionServicios
  );
  const modalInformacionInicial = useSelector(
    (state) => state.sesion.modalInformacionInicial
  );
  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };
  useIcons();
  useTooltip();
  useTabs();
  useDaterangerpicker();
  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };

  useEffect(() => {
    dispatch(getPrestamos());
    getDolarValue();
    getConfiguracion();
    getGestores();
    userAdministrativos();
    dispatch(getCuentasBancarias());
    dispatch(getPagosMoviles());
    modalInfoInicio();

    //Tiempo real observar cuando se recive un mensaje por chat
    let query = app.firestore().collection('chat');
    query.onSnapshot(
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'modified') {
            let data = change.doc.data();
            console.log('DATA', data);
            //playNotification();
            if (prestamoActual) {
              dispatch(getChat(prestamoActual.id));
            }
          }
        });
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
    const orderBancos = bancos.listado.sort((a, b) => {
      if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
      if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
      return 0;
    });
    setBancosVenezuela(orderBancos);
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
          dispatch({ type: GET_CONFIGURACION, payload: doc.data() });
          calculateInteresTasa(5, 2, doc.data().tasaInteresCompuesto);
        }
      });
  };
  const getGestores = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', '==', 'Gestor')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setGestores(array);
        setTimeout(() => {
          // console.log(prestamoActual)
        }, 1000);
      });
  };
  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Gestor'])
      // .where('rol', '==', 'SuperAdmin')
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
      });
  };
  const getDolarValue = async () => {
    try {
      let response = await axiosInstance.get(`/monitor`);
      const { data } = response;
      let dataBien = data.resp.replaceAll('.', '');
      dataBien = dataBien.replace(',', '.');
      setTasaDolarHoy(parseFloat(dataBien));
    } catch (err) {
      console.log(err, 'error');
    }
  };
  const changeMonto = (e) => {
    const value = e.target.value;
    setCantidad(value);
    let diff = getDirerence(fecha);
    setDiasPagar(diff);
    calculateInteres(value, diff);
    calculateBS();
  };
  const changeFecha = (e) => {
    const value = e.target.value;
    setFecha(value);
    let diff = getDirerence(value);
    setDiasPagar(diff);
    calculateInteres(cantidad, diff);
    calculateBS();
  };
  const getDirerence = (fechaValue) => {
    var NowMoment = moment(new Date()).format('YYYY-MM-DD');
    var fecha1 = moment(NowMoment);
    var fecha2 = moment(fechaValue);
    let diff = fecha2.diff(fecha1, 'days');
    armarDays(diff);
    return diff;
  };
  const calculateBS = () => {
    let cambio = cantidad * tasaDolarHoy;
    setMontoBS(cambio);
  };
  const calculateInteresTasa = (cant, dias, tasa) => {
    let totalApagar = (
      cant * Math.pow(1 + parseFloat(tasa) / 100, dias)
    ).toFixed(2);
    setTotalPagar(totalApagar);
  };
  const calculateInteres = (cant, dias) => {
    let totalApagar = (
      cant *
      Math.pow(
        1 +
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? config.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          ) /
            100,
        dias
      )
    ).toFixed(2);
    setTotalPagar(totalApagar);
  };
  const calculateInteresByDia = (dia) => {
    let totalApagar = (
      cantidad *
      Math.pow(
        1 +
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? config.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          ) /
            100,
        dia
      )
    ).toFixed(2);
    return totalApagar;
  };
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    setDays(array.reverse());
  };
  const next = () => {
    switch (indexWizard) {
      case 1:
        if (deacuerdo === true && totalPagar) {
          if (selectedCuenta) {
            let dataEnviar = {
              aprobado: false,
              calificacion: 0,
              captura: '',
              comentarios: '',
              fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
              fechaCompromisoPago: fecha,
              fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
              fechaPago: null,
              fechaSolicitud: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              ganancia: 0,
              gananciaReal: 0,
              gananciaTeorica: 0,
              idGestor: null,
              idUsuario: app.auth().currentUser.uid,
              montoCompromisoPago: parseFloat(totalPagar),
              montoPrestado: parseFloat(cantidad),
              montoRealPrestado: 0,
              montoRealRecibido: 0,
              montoRecibido: 0,
              pagado: false,
              tasaInteresCompuesto: config.tasaInteresCompuesto,
              tipoPagoVenezuela:
                selectedCuenta.numeroCuenta === ''
                  ? 'Pago Movil'
                  : 'Cuenta Bancaria',
              idcuentaVenezuela: selectedCuenta.id,
              estado: 'iniciado',
              indexWizard: 2,
              usuario: myUser,
              datosEnvio: selectedCuenta,
              tab: 'tabpersonales',
              // habilitadoNuevoPrestamo: false,
              pagoEnviado: false,
              gestor: null,
            };
            dispatch(generatePrestamo(dataEnviar));
            let para = myUser.email;
            const data = {
              asunto: 'Solicitud de préstamo iniciada',
              email: para,
              motivo: `<h4>Gracias por dar el primer paso.</h4>
                  <p>A continuación te solicitaremos información sobre ti porque necesitamos conocerte para evaluar tu solicitud de préstamo.</p>
                  <p>Sabemos que pueden parecerte muchos pasos, pero todos son necesarios para evaluar tu solicitud.</p>
                  
                  <p><b>¡El que persevera, alcanza!</b></p>`,
            };
            enviarNotificacion(data);
            // const userUpdtae = Object.assign(myUser, datosGenerales);
            // let objI = Object.assign(userUpdtae, prestamoActual, {
            //   tab: 'tabpersonales',
            // });
            // dispatch(updateDatosGenereales(userUpdtae, objI, false));
            // dispatch({ type: GET_MYUSER, payload: userUpdtae });
          } else {
            custom(
              'Debe seleccionar una Cuenta Bancaria o un Pago Movil para continuar',
              'error'
            );
          }
        } else {
          custom(
            'Debes aceptar los términos del préstamo para continuar',
            'error'
          );
        }
        break;
      case 2:
        switch (prestamoActual.tab) {
          case 'tabpersonales':
            if (
              datosGenerales.apellidos === '' ||
              datosGenerales.nombres === '' ||
              datosGenerales.fechaNacimiento === '' ||
              datosGenerales.sexo === '' ||
              datosGenerales.numeroCedula === ''
            ) {
              custom('Faltan datos por llenar', 'error');
            } else if (calculateAge(datosGenerales.fechaNacimiento) === false) {
              custom(
                'Estimado usuario no puedes continuar por ser menor de 22 años',
                'error'
              );
            } else {
              // app.firestore().collection("prestamos").doc(`${prestamoActual.id}`).update({ tab: "tabubicacion" });
              let obj1 = Object.assign({}, prestamoActual, {
                tab: 'tabubicacion',
              });
              dispatch({ type: PRESTAMOACTUAL, payload: obj1 });
              dispatch(updateDatosGenereales(datosGenerales, obj1, false));
              dispatch(
                addLog({
                  accion: `${'ha llenado los datos de personales '}`,
                  fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                  usuario: myUser.email,
                })
              );
              const userUpdtae = Object.assign(myUser, datosGenerales);
              dispatch({ type: GET_MYUSER, payload: userUpdtae });
            }
            break;
          case 'tabubicacion':
            if (
              datosGenerales.pais === '' ||
              datosGenerales.localidad === '' ||
              datosGenerales.whatsapp === ''
            ) {
              custom('Faltan datos por llenar', 'error');
            } else {
              // app.firestore().collection("prestamos").doc(`${prestamoActual.id}`).update({ tab: "tablaborales" });
              let obj2 = Object.assign({}, prestamoActual, {
                tab: 'tablaborales',
              });

              dispatch({ type: PRESTAMOACTUAL, payload: obj2 });
              dispatch(updateDatosGenereales(datosGenerales, obj2, false));
              const userUpdtae = Object.assign(myUser, datosGenerales);
              dispatch({ type: GET_MYUSER, payload: userUpdtae });
            }
            break;
          case 'tablaborales':
            if (datosGenerales.trabaja === '') {
              custom('Faltan datos por llenar', 'error');
            } else if (!validarTrabajo()) {
              custom('Faltan datos por llenar', 'error');
            } else {
              //app.firestore().collection("prestamos").doc(`${prestamoActual.id}`).update({ tab: "tabsociales" });
              let obj3 = Object.assign({}, prestamoActual, {
                tab: 'tabsociales',
              });
              dispatch({ type: PRESTAMOACTUAL, payload: obj3 });
              dispatch(updateDatosGenereales(datosGenerales, obj3, false));
              const userUpdtae = Object.assign(myUser, datosGenerales);
              dispatch({ type: GET_MYUSER, payload: userUpdtae });
            }
            break;
          case 'tabsociales':
            if (sociales.length < 2) {
              custom(
                'Debe ingresar al menos 2 redes sociales para la validación de identidad',
                'error'
              );
            } else {
              if (redessocialeschamopresta.length !== redesvisitadas.length) {
                confirm(
                  'No ha revisado todos los enlaces de las redes sociales para las solicitudes de amistad y seguimiento... Sin esto no será aprobado, ¿seguro que desea continuar?.',
                  'question'
                ).then((resp) => {
                  if (resp) {
                    dispatch(
                      updateDatosGenereales(
                        datosGenerales,
                        prestamoActual,
                        true
                      )
                    );
                  }
                });
              } else {
                dispatch(
                  updateDatosGenereales(datosGenerales, prestamoActual, true)
                );
              }
              let para = myUser.email;
              const data = {
                asunto: 'Solicitud de préstamo en fase final',
                email: para,
                motivo: `<h4>¡Llegaste a la parte final!
                <br />
                Excelente, estamos agradecidos por tu paciencia.</h4>
                  <p>Recuerda una vez hayas subido tus imágenes y presiones siguiente tu préstamo será evaluado inmediatamente si estás realizando este proceso durante el día, si es muy tarde te responderemos al siguiente día... Deberás estar pendiente de tus redes sociales y de tu correo electrónico.</p>

                  <p>Por ende te recomendamos presionar siguiente cuando realmente requieras el préstamo.</p>

                  <p>Evita endeudarte innecesariamente, usa nuestro servicio con responsabilidad e inteligencia.</p>`,
              };
              enviarNotificacion(data);
              //** Pausada Esta Notificación */
              // const notificationData = {
              //   gestores: gestores.map((gestor) => gestor.email),
              //   motivo: `El usuario : ${
              //     datosGenerales.nombres === '' &&
              //     datosGenerales.apellidos === ''
              //       ? '<b>aún no ha ingresado sus nombres</b>'
              //       : datosGenerales.nombres + ' ' + datosGenerales.apellidos
              //   },
              //   de correo electrónico : ${myUser.email} , de número contacto ${
              //     datosGenerales.whatsapp !== ''
              //       ? datosGenerales.whatsapp
              //       : '<b>sin número de contacto</b>'
              //   } y de país : ${
              //     datosGenerales.pais
              //       ? datosGenerales.pais
              //       : '<b>país de residencia no registrado</b>'
              //   } está solicitando un préstamo por ${
              //     prestamoActual.montoPrestado
              //   }$
              //       para pagarlo el ${prestamoActual.fechaCompromisoPago}
              //       <br />
              //       <h4>Datos :</h4>
              //       <p>Nombres :${
              //         datosGenerales.nombres + ' ' + datosGenerales.apellidos
              //       }</p>
              //       <p>País :${datosGenerales.pais}</p>
              //       <p>Correo :${myUser.email}</p>
              //       <p>Facebook :${sociales.map((sociales) =>
              //         sociales.tipo === 'facebook' ? sociales.url : ''
              //       )}</p>
              //       <p>Instagram :${sociales.map((sociales) =>
              //         sociales.tipo === 'instagram' ? sociales.url : ''
              //       )}</p>
              //       <p>Telegram :${datosGenerales.telegram}</p>
              //       <p>Monto :${prestamoActual.montoPrestado}$</p>
              //       <p>Monto a pagar :${prestamoActual.montoCompromisoPago}$</p>
              //       <p>Fecha pago :${prestamoActual.fechaCompromisoPago}</p>`,
              // };
              // sendNotification(notificationData);
            }
            break;
          default:
            break;
        }
        break;
      case 3:
        if (requerimientos.length > 0) {
          if (
            requerimientos[0].selfieCedula === '' ||
            requerimientos[0].documento === ''
          ) {
            custom('Faltan Imagénes por Ingresar', 'error');
          } else {
            // buscar si existe chat antes de crearlo
            if (chat && chat.idPrestamo) {
              app
                .firestore()
                .collection('prestamos')
                .doc(`${prestamoActual.id}`)
                .update({
                  estado: 'pendiente',
                  fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
                  fechaSolicitud: moment(new Date()).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  indexWizard: 4,
                });
              dispatch(createChat(prestamoActual.id));
              dispatch({ type: 'WIZARD', payload: 4 });
            } else {
              app
                .firestore()
                .collection('prestamos')
                .doc(`${prestamoActual.id}`)
                .update({
                  estado: 'pendiente',
                  fechaActualizacion: moment(new Date()).format('YYYY-MM-DD'),
                  fechaSolicitud: moment(new Date()).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                });
              dispatch(createChat(prestamoActual.id));
              dispatch({ type: 'WIZARD', payload: 4 });

              //ENVIAR CORREO A LOS ADMINISTRADORES //
              const textoCorreoEnviar = `
                <p>El usuario _ Email : ${app.auth().currentUser.email}
                <b>Formalizó la solicitud de un préstamo</b>
                </p>
                <h4>Datos </h4>
                <p>Nombres :${
                  datosGenerales.nombres + ' ' + datosGenerales.apellidos
                }</p>
                <p>País :${datosGenerales.pais}</p>
                <p>Correo :${myUser.email}</p>
                <p>Monto :${prestamoActual.montoPrestado}$</p>
                <p>Monto a pagar :${prestamoActual.montoCompromisoPago}$</p>
                <p>Fecha pago :${prestamoActual.fechaCompromisoPago}</p>
                <h4>Redes sociales </h4>
                <p>Facebook :${sociales.map((sociales) =>
                  sociales.tipo === 'facebook' ? sociales.url : ''
                )}</p>
                  <p>Instagram :${sociales.map((sociales) =>
                    sociales.tipo === 'instagram' ? sociales.url : ''
                  )}</p>
                <p>Telegram :${datosGenerales.telegram}</p>
                <h4>Laboral </h4>
                <p>Area de trabajo :${
                  datosGenerales.areaTrabajo === ''
                    ? 'Sin registro'
                    : datosGenerales.areaTrabajo
                }</p>
                <p>Empresa :${
                  datosGenerales.nombreEmpresa === ''
                    ? 'Sin registro'
                    : datosGenerales.nombreEmpresa
                }</p>
                <p>Actividad :${
                  datosGenerales.descripcionActividad === ''
                    ? 'Sin registro'
                    : datosGenerales.descripcionActividad
                }</p>
                <p>Salario :${
                  datosGenerales.ingresoMensualUSD === ''
                    ? 'Sin registro'
                    : datosGenerales.ingresoMensualUSD
                }</p>
                <p>Comprobate trabajo :${
                  requerimientos[0].comprobanteTrabajo === ''
                    ? 'No enviado'
                    : requerimientos[0].comprobanteTrabajo
                }</p>
              `;
              const emailAdminis = {
                asunto: 'Préstamo Solicitado ',
                email: administrativos.map((admin) => admin.email),
                motivo: `<span>${textoCorreoEnviar}</span>`,
              };
              enviarNotificacion(emailAdminis);
            }
          }
        } else {
          custom('Faltan datos por llenar', 'error');
        }
        break;
      case 4:
        if (
          prestamoActual.estado === 'aprobado' &&
          requerimientoPrestamo[0].validadoSelfiePrestamo === 'aprobado'
        ) {
          dispatch(culminarPrestamo(prestamoActual.id));
        } else {
          custom(
            'Estamos evaluando los datos que ingresaste, por favor espera el tiempo indicado.',
            'error'
          );
        }
        break;
      case 5:
        break;
      default:
        break;
    }
  };
  const validarTrabajo = () => {
    if (datosGenerales.trabaja === 'No') {
      return true;
    } else {
      if (
        datosGenerales.areaTrabajo === '' ||
        datosGenerales.nombreEmpresa === '' ||
        datosGenerales.descripcionActividad === '' ||
        datosGenerales.ingresoMensualUSD === ''
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  const back = () => {
    switch (indexWizard) {
      case 1:
        break;
      case 2:
        switch (prestamoActual.tab) {
          case 'tabpersonales':
            app
              .firestore()
              .collection('prestamos')
              .doc(`${prestamoActual.id}`)
              .update({ indexWizard: 2 });
            dispatch({ type: 'WIZARD', payload: 2 });
            break;
          case 'tabubicacion':
            app
              .firestore()
              .collection('prestamos')
              .doc(`${prestamoActual.id}`)
              .update({ tab: 'tabpersonales' });
            let obj2 = Object.assign({}, prestamoActual, {
              tab: 'tabpersonales',
            });
            dispatch({ type: PRESTAMOACTUAL, payload: obj2 });
            dispatch(
              updateDatosGenereales(datosGenerales, prestamoActual, false)
            );
            break;
          case 'tablaborales':
            app
              .firestore()
              .collection('prestamos')
              .doc(`${prestamoActual.id}`)
              .update({ tab: 'tabubicacion' });
            let obj3 = Object.assign({}, prestamoActual, {
              tab: 'tabubicacion',
            });
            dispatch({ type: PRESTAMOACTUAL, payload: obj3 });
            dispatch(
              updateDatosGenereales(datosGenerales, prestamoActual, false)
            );
            break;
          case 'tabsociales':
            app
              .firestore()
              .collection('prestamos')
              .doc(`${prestamoActual.id}`)
              .update({ tab: 'tablaborales' });
            let obj4 = Object.assign({}, prestamoActual, {
              tab: 'tablaborales',
            });
            dispatch({ type: PRESTAMOACTUAL, payload: obj4 });
            dispatch(
              updateDatosGenereales(datosGenerales, prestamoActual, false)
            );
            break;
          default:
            break;
        }
        break;
      case 3:
        app
          .firestore()
          .collection('prestamos')
          .doc(`${prestamoActual.id}`)
          .update({ indexWizard: 2 });
        dispatch({ type: 'WIZARD', payload: 2 });
        break;
      case 4:
        app
          .firestore()
          .collection('prestamos')
          .doc(`${prestamoActual.id}`)
          .update({ indexWizard: 3 });
        dispatch({ type: 'WIZARD', payload: 3 });
        break;
      case 5:
        break;
      default:
        break;
    }
  };
  const sendNotification = async (notificationData) => {
    let emails = notificationData.gestores;
    emails.push('info@chamopresta.com');
    try {
      let response = await axiosInstance.post(`/notifications/sendMasive`, {
        asunto: 'Préstamo en progreso',
        motivo: notificationData.motivo,
        correos: emails,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const aceptarAcuerdo = (e) => {
    let value = e.target.value;
    setTimeout(() => {
      if (value === 'si') {
        setDeacuerdo(true);
      } else {
        setDeacuerdo(false);
      }
    }, 200);
  };
  const exportar = () => {
    const addFooters = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      for (var i = 0; i < pageCount; i++) {
        doc.setTextColor(51, 122, 183);
        doc.text(
          'www.chamopresta.com',
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 20,
          {
            align: 'center',
          }
        );
        /*doc.setTextColor(0,0,0);
        doc.text('Hispanos Soluciones C.A. / contacto: ', 146, doc.internal.pageSize.height - 20, { });
        doc.setTextColor(51,122,183);
        doc.text('info@hispanossoluciones.com', 316, doc.internal.pageSize.height - 20, {});*/
      }
    };
    let doc = new jsPDF('p', 'pt', 'A4', true);
    let title = `Tabla de interés pautado ${moment(fecha).format(
      'DD/MM/YYYY'
    )}`;
    doc.autoTable({
      head: [[title]],
      body: [],
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: 18,
        fillColor: [255, 255, 255],
        textColor: [40, 167, 69],
      },
    });
    let data = [];
    days.map((day, i) => {
      data.push([
        `${formatFecha(moment(new Date()).format('YYYY-MM-DD'), day)}`,
        `${calculateInteresByDia(day)}$`,
      ]);
    });
    doc.autoTable({
      head: [['Fecha', 'Monto']],
      body: data,
      allSectionHooks: true,
      headStyles: {
        fillColor: [28, 63, 170],
      },
    });
    let Subtitle = `Usted al solicitar el préstamo acepta los intereses mostrados y se compromete a pagar en el tiempo pactado: ${moment(
      fecha
    ).format('DD/MM/YYYY')}`;
    doc.autoTable({
      head: [[Subtitle]],
      body: [],
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: 13,
        fillColor: [255, 255, 255],
        textColor: [40, 167, 69],
      },
    });
    addFooters(doc);
    //window.open(doc.output('bloburl'), '_blank');
    doc.save(`${title}.pdf`);
  };
  const newPrestamo = () => {
    dispatch({ type: 'WIZARD', payload: 1 });
    dispatch({ type: 'PRESTAMOACTUAL', payload: null });
  };
  const changeRating = (newRating, name) => {
    let clonado = Object.assign({}, prestamoActual);
    clonado.calificacion = newRating;
    dispatch({ type: 'PRESTAMOACTUAL', payload: clonado });
    app
      .firestore()
      .collection('prestamos')
      .doc(`${prestamoActual.id}`)
      .update({ calificacion: newRating });
  };
  const onChangeComentario = (e) => {
    let value = e.target.value;
    let obj = Object.assign({}, prestamoActual);
    obj.comentarios = value;
    dispatch({ type: PRESTAMOACTUAL, payload: obj });
  };
  const saveComentario = () => {
    if (prestamoActual.comentarios === '') {
      custom('Debe ingresar su comentario', 'error');
    } else {
      dispatch(updateComentarios(prestamoActual));
      // ENVIAR CORREO A LOS ADMINISTRADORES //
      const textoCorreoEnviar = `
        <p>El usuario : ${prestamoActual.usuario.nombres} ${prestamoActual.usuario.apellidos} , realizo el siguiente comentario : </p>
        <p>...${prestamoActual.comentarios}.</p>
      `;
      const emailAdministrativos = {
        asunto: 'Comentario Usuario',
        email: administrativos.map((admin) => admin.email),
        motivo: `<span>${textoCorreoEnviar}</span>`,
      };
      enviarNotificacion(emailAdministrativos);
    }
  };

  //========== Funciones Modal Terminos ==========//
  const closeModalTerminos = () => {
    dispatch(modalTerminoCondicion(false));
  };
  const cancelModalTerminos = () => {
    dispatch(modalTerminoCondicion(false));
  };
  const modalTerminos = (params) => {
    dispatch(modalTerminoCondicion(true));
  };
  const diferenciasDias = () => {
    if (prestamoActual) {
      let diferencia = 0;
      let vencido = prestamoActual.fechaCompromisoPago;
      let dia = hoy;
      diferencia = vencido >= dia;
      return diferencia;
    } else {
      return false;
    }
  };
  const calculateAge = (fechaN) => {
    const d = fechaN;
    const inDate = new Date(d);
    const year_Ingresada = inDate.getFullYear();
    const fec_actual = new Date();
    const year_Actual = fec_actual.getFullYear();
    const dia_Ingresado = inDate.getDate() + 1;
    const dia_Actual = fec_actual.getDate();
    const mes_Ingresado = inDate.getMonth() + 1;
    const mes_Actual = fec_actual.getMonth() + 1;
    let edad = year_Actual - year_Ingresada;
    if (edad > 22) {
      return true;
    } else if (edad < 22) {
      return false;
    } else {
      if (mes_Ingresado > mes_Actual) {
        return false;
      } else if (mes_Ingresado < mes_Actual) {
        return true;
      } else {
        if (dia_Ingresado >= dia_Actual) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  //========== Funciones Modal Información ==========//
  const closeModalInformacion = () => {
    dispatch(modalInformacionServicio(false));
  };
  const cancelModalInformacion = () => {
    dispatch(modalInformacionServicio(false));
  };
  const modalInformacion = (params) => {
    dispatch(modalInformacionServicio(true));
  };
  const closeModalInformacionInicio = () => {
    dispatch(modalInformacionInicio(false));
  };
  const cancelModalInformacionInicio = () => {
    dispatch(modalInformacionInicio(false));
  };
  const modalInfoInicio = (params) => {
    dispatch(modalInformacionInicio(true));
  };

  return (
    <>
      <div>
        {indexWizard === 1 &&
        config.fondo <= 0 &&
        (!prestamoActual || !prestamoActual.pagado) ? (
          <SinFondos />
        ) : (
          <div className='intro-y box py-8 sm:py-8'>
            {indexWizard !== 5 && (
              <div className='wizard flex flex-col lg:flex-row justify-center px-4 sm:px-20'>
                <div className='intro-x lg:text-center flex items-center lg:block flex-1 z-10'>
                  <button
                    className={`w-10 h-10 rounded-full button ${
                      indexWizard === 1
                        ? 'text-white bg-theme-1'
                        : 'text-gray-600 bg-gray-200'
                    }`}
                  >
                    1
                  </button>
                  <div className='lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto'>
                    Iniciar
                  </div>
                </div>
                <div className='intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10'>
                  <button
                    className={`w-10 h-10 rounded-full button ${
                      indexWizard === 2
                        ? 'text-white bg-theme-1'
                        : 'text-gray-600 bg-gray-200'
                    }`}
                  >
                    2
                  </button>
                  <div className='lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700'>
                    Datos Generales
                  </div>
                </div>
                <div className='intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10'>
                  <button
                    className={`w-10 h-10 rounded-full button ${
                      indexWizard === 3
                        ? 'text-white bg-theme-1'
                        : 'text-gray-600 bg-gray-200'
                    }`}
                  >
                    3
                  </button>
                  <div className='lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700'>
                    Verificación
                  </div>
                </div>
                <div className='intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10'>
                  <button
                    className={`w-10 h-10 rounded-full button ${
                      indexWizard === 4
                        ? 'text-white bg-theme-1'
                        : 'text-gray-600 bg-gray-200'
                    }`}
                  >
                    4
                  </button>
                  <div className='lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700'>
                    En Revisión
                  </div>
                </div>
                {/* <div className="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
              <button
                className={`w-10 h-10 rounded-full button ${
                  indexWizard === 5
                    ? "text-white bg-theme-1"
                    : "text-gray-600 bg-gray-200"
                }`}
              >
                5
              </button>
              <div className="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700">
                Completado
              </div>
              </div>  */}
                <div className='wizard__line hidden lg:block w-2/3 bg-gray-200 absolute mt-5'></div>
              </div>
            )}
            {indexWizard === 1 &&
              config.fondo > 0 &&
              (!prestamoActual || !prestamoActual.pagado) && (
                <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
                  <div className='grid grid-cols-12 gap-4 row-gap-5 mb-6'>
                    <div className='intro-y col-span-12 sm:col-span-12'>
                      <div className='font-medium text-gray-900 text-center mb-2'>
                        Recuerda que el límite del monto máximo a solicitar
                        puede aumentar y la tasa de interés diaria puede
                        disminuir después de demostrar responsabilidad al
                        devolver el primer préstamo...
                      </div>
                      <div className='font-medium text-gray-900 text-center mb-2'>
                        Usted puede ajustar el monto que desea solicitar y su
                        fecha de compromiso de pago (Se requieren mínimo 4 días
                        para regresar el préstamo).
                      </div>
                    </div>

                    <div className='intro-y col-span-12 sm:col-span-4'>
                      <div className='mb-2'>Monto a Solicitar en USD</div>
                      <input
                        type='range'
                        className='w-full'
                        name='cantidad'
                        value={cantidad}
                        min='0'
                        max={
                          myUser.habilitadoNuevoPrestamo === 'No'
                            ? config.montoInicialMaximoPrestamo
                            : myUser.nuevoLimitePrestamo
                        }
                        onChange={changeMonto}
                        onInput={changeMonto}
                      ></input>
                      <output
                        style={{
                          marginTop: '-10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {cantidad}
                      </output>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-4'>
                      <div className='mb-2'>Fecha de Compromiso de Pago</div>
                      <input
                        type='date'
                        name='fecha'
                        value={fecha}
                        min={mindate}
                        onChange={changeFecha}
                        className='input input--sm w-full border block rounded-full'
                      />
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-4'>
                      <div className='mb-2'>Monto Total a Cancelar en USD</div>
                      <div className='flex'>
                        <input
                          type='number'
                          name='totalPagar'
                          value={totalPagar}
                          disabled
                          className='input input--sm w-full border flex-1 rounded-full'
                        />
                        <Icon.Info
                          className='text-theme-10 tooltip ml-2'
                          data-tooltip-content='#montoPagar-content-tooltip'
                          data-theme='light'
                          title={mensajes.montoTotalPagar}
                        />
                      </div>
                      <div className='tooltip-content'>
                        <div
                          id='montoPagar-content-tooltip'
                          className='relative flex items-center py-1'
                        >
                          <div className='ml-4 mr-auto'>
                            <div className='font-medium leading-relaxed'>
                              Aviso
                            </div>
                            <div className='text-gray-600'>
                              {mensajes.montoTotalPagar}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-3 flex'>
                      <div className='font-semibold'>Interés Aplicado</div>
                      <label className='text-base ml-2'>
                        {myUser.habilitadoNuevoPrestamo === 'No'
                          ? config.tasaInteresCompuesto
                          : myUser.nuevaTasaInteres}
                        %
                      </label>
                    </div>
                    <div className='intro-y col-span-12 sm:col-span-12'>
                      <div className='mb-2'>
                        Si pides un préstamo en una cuenta venezolana recibirás
                        bolívares de acuerdo a la tasa de
                        <a
                          href='https://www.instagram.com/monitordollarvzlar/'
                          target='_blank'
                          className='text-theme-1 ml-1 font-medium'
                        >
                          Monitor Dolar.
                        </a>
                        <p>
                          Si pides un préstamo en una cuenta extranjera
                          dolarizada recibirás dólares.
                        </p>
                        <p>
                          Si pides un préstamo en una cuenta extranjera no
                          dolarizada (Ejemplo pesos o soles) recibirás el
                          equivalente a la tasa de cambio de google.
                        </p>
                      </div>
                    </div>
                    {days.length > 0 && (
                      <div
                        className='col-span-12 sm:col-span-12 flex justify-center items-center'
                        style={{ flexDirection: 'column' }}
                      >
                        <h2 className='font-medium bg-theme-2 box p-1 mb-2'>
                          Así estará distribuido el interés por día
                        </h2>

                        {/*<div className="flex justify-center wrap crousel" id="interespordia" ref={divIntereses} >*/}
                        <div className='carousel'>
                          <Slider {...settings} className='w-full'>
                            {days.map((day, i) => {
                              return (
                                <div
                                  className='box p-2 mb-3 flex items-center zoom-in bg-theme-26'
                                  key={i}
                                >
                                  <div>
                                    <div className='font-medium'>
                                      {`${formatFecha(
                                        moment(new Date()).format('YYYY-MM-DD'),
                                        day
                                      )}:`}
                                    </div>
                                    <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                                      {`${calculateInteresByDia(day)}$`}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                        {/*</div>*/}
                        <button
                          className='button w-auto text-center text-white bg-theme-1 p-2 mt-3 flex items-center'
                          onClick={exportar}
                        >
                          <Icon.FileText className='text-white mr-2 w-4' />
                          Exportar
                        </button>
                      </div>
                    )}
                    <div className='intro-y col-span-12 sm:col-span-12 text-center'>
                      <div className='flex flex-col sm:flex-row mt-2 mb-2 justify-center'>
                        <label className='font-medium mr-2'>
                          Estas de acuerdo ? :
                        </label>
                        <div className='flex items-center text-gray-700 mr-2'>
                          <input
                            type='radio'
                            className='input border mr-2'
                            id='optsi'
                            name='opdeacuerdo'
                            value='si'
                            onChange={aceptarAcuerdo}
                          />
                          <label
                            className='cursor-pointer select-none'
                            htmlFor='optsi'
                          >
                            Si
                          </label>
                        </div>
                        <div className='flex items-center text-gray-700 mr-2 mt-2 sm:mt-0'>
                          <input
                            type='radio'
                            className='input border mr-2'
                            id='optno'
                            name='opdeacuerdo'
                            value='no'
                            onChange={aceptarAcuerdo}
                          />
                          <label
                            className='cursor-pointer select-none'
                            htmlFor='optno'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {deacuerdo && (
                    <>
                      <div className='intro-y col-span-12 sm:col-span-12 mb-6'>
                        <div className='flex flex-col sm:flex-row items-center border-b'>
                          <h2 className='font-medium text-base mr-auto mb-2'>
                            ¿Dónde deseas recibir el préstamo?
                          </h2>
                        </div>
                      </div>
                      {cuentas.length === 0 &&
                        listadoPagosMoviles.length === 0 && (
                          <div className='grid grid-cols-12 gap-4 row-gap-3 mb-2'>
                            <div className='intro-y col-span-12 sm:col-span-12'>
                              <div className='flex justify-center items-center flex-col'>
                                <h2 className='font-medium text-base mb-2'>
                                  Puedes agregar una cuenta bancaria o un pago
                                  móvil para recibir el dinero en Venezuela o
                                  una Cuenta Bancaria de otro país.
                                </h2>
                              </div>
                            </div>
                          </div>
                        )}
                      {/* ACORDEON */}
                      <AccordionBancos />
                      {cuentas.length !== 0 ||
                        (listadoPagosMoviles.length !== 0 &&
                          !selectedCuenta && (
                            <div className='grid grid-cols-12 gap-4 row-gap-3 mb-2'>
                              <div className='intro-y col-span-12 sm:col-span-12'>
                                <div className='flex justify-center items-center flex-col'>
                                  <h2 className='font-medium text-base mb-2'>
                                    Puedes seleccionar una Cuenta Bancaria o un
                                    Pago Movil, presionando click encima de la
                                    tarjeta.
                                  </h2>
                                </div>
                              </div>
                            </div>
                          ))}
                      {/* {selectedCuenta && (
                        <div className="grid grid-cols-12 gap-4 row-gap-3 mb-6 mt-2">
                          <div className="intro-y col-span-12 sm:col-span-12">
                            <div className="flex justify-center items-center flex-col">
                              <h2 className="font-medium text-base mb-2 bg-theme-2 box p-2">
                                Datos para
                                {selectedCuenta.numeroCuenta === ""
                                  ? " el Pago Móvil"
                                  : " la Transferencia Bancaria"}
                              </h2>
                              <p className="text-base">
                                {selectedCuenta.numeroCuenta === ""
                                  ? "Banco: "
                                  : "N° de Cuenta: "}
                                {selectedCuenta.numeroCuenta === ""
                                  ? selectedCuenta.banco
                                  : selectedCuenta.numeroCuenta}
                              </p>
                              <p className="text-base">
                                Cédula: {selectedCuenta.cedula}
                              </p>
                              <p className="text-base">
                                Titular: {selectedCuenta.titular}
                              </p>
                              <p className="text-base">
                                País: {selectedCuenta.pais}
                              </p>
                            </div>
                          </div>
                          <div className="intro-y col-span-12 sm:col-span-12 mt-2">
                            <div className="flex justify-center items-center flex-col">
                              <h2 className="font-medium text-yellow-600 text-base mb-2">
                                Presione siguiente para continuar con su
                                solicitud de préstamo.
                              </h2>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </>
                  )}

                  {/*===== Modal Boletin Informativo =====*/}
                  {modalInformacionInicial && (
                    <Modal
                      title='Información sobre nuestro servicio'
                      close={closeModalInformacionInicio}
                      cancel={cancelModalInformacionInicio}
                      save={() => dispatch(modalInformacionInicio(false))}
                      customBtn={true}
                      textcustomBtn='Aceptar'
                    >
                      <div className='intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh'>
                        <p className='my-2 whitespace-pre-wrap'>
                          {config.informacion}
                        </p>
                      </div>
                    </Modal>
                  )}
                </div>
              )}
            {indexWizard === 2 && (
              <div className='px-5 sm:px-4 md:px-8 pt-8 border-t border-gray-200'>
                <Datosgenerales />
              </div>
            )}
            {indexWizard === 3 && (
              <div className='px-5 sm:px-8 pt-8 border-t border-gray-200'>
                <Verificacion />
              </div>
            )}
            {indexWizard === 4 && (
              <div className='px-5 sm:px-8 pt-8 border-t border-gray-200'>
                <Revision siguiente={next} />
                <Chat
                  chat={chat}
                  prestamo={prestamoActual}
                  rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
                />
              </div>
            )}
            {indexWizard === 5 && (
              <div className='flex flex-col'>
                <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
                  <div className='grid grid-cols-12 gap-1 row-gap-1 mb-2 w-full'>
                    <div className='intro-y col-span-12 sm:col-span-12 flex justify-center items-center flex-col'>
                      {hoyBien > prestamoActual.fechaCompromisoPago &&
                        prestamoActual.estado !== 'finalizado' && (
                          <>
                            <h2 className='text-2xl text-theme-3 font-medium leading-none'>
                              Préstamo no pagado a tiempo.
                            </h2>

                            <h1 className='font-medium text-theme-6'>
                              Por favor abone una parte o pague la totalidad del
                              préstamo a brevedad, evite las próximas medidas
                              del departamento de cobranza.
                            </h1>
                          </>
                        )}
                      {hoyBien < prestamoActual.fechaCompromisoPago && (
                        <h2 className='text-2xl text-theme-3 font-medium leading-none mb-5'>
                          Préstamo Completado
                        </h2>
                      )}
                      {prestamoActual.pagado === true &&
                        prestamoActual.estado === 'finalizado' && (
                          <h2 className='text-2xl text-theme-3 font-medium leading-none mb-5'>
                            Préstamo Finalizado
                          </h2>
                        )}
                    </div>
                    {prestamoActual.pagado === true &&
                      myUser.habilitadoNuevoPrestamo === 'No' && (
                        <div className='intro-y col-span-12 sm:col-span-12 flex justify-center items-start flex-col'>
                          <label className='text-base font-medium leading-none mb-1'>
                            ¿Qué le parece ChamoPresta.com? Nos daría un
                            feedback para mejorar nuestro proceso de solicitudes
                            de préstamos.
                          </label>
                          <textarea
                            name='comentarios'
                            value={prestamoActual.comentarios}
                            rows='3'
                            className='border mt-3 w-full rounded-md mb-3 bg-theme-2'
                            placeholder=' Agregar comentario...'
                            onChange={onChangeComentario}
                          />
                          <div className='flex justify-center items-center mb-3'>
                            <button
                              className='flex button w-auto bg-theme-1 text-white items-center'
                              onClick={saveComentario}
                            >
                              <Icon.Save className='w-4 h-4 mr-1' />
                              Guardar Comentario
                            </button>
                          </div>
                        </div>
                      )}
                    <div className='intro-y col-span-12 sm:col-span-12 flex justify-center items-center flex-col'>
                      {/*<h2 className="text-2xl font-medium leading-none mb-5">
                      Calificar Prestamo.
                    </h2>*/}
                      {/*prestamoActual && (
                      <StarRatings
                        rating={prestamoActual.calificacion}
                        starRatedColor="rgb(10,28,70)"
                        starHoverColor="rgb(62,64,97)"
                        changeRating={changeRating}
                        numberOfStars={5}
                        name="rating"
                        starDimension="40px"
                      />
                    )*/}
                      {prestamoActual &&
                        prestamoActual.pagado === true &&
                        myUser.habilitadoNuevoPrestamo === 'Si' && (
                          <div className='w-full bg-theme-2 box p-6'>
                            <h2 className='text-lg m-auto font-medium bg-theme-5 box w-64 pl-2'>
                              <DoneAllIcon color='primary' /> ._ SOLICITAR
                              PRESTAMO
                            </h2>
                            <button
                              className='button block text-base text-white mt-6 bg-theme-3 m-auto'
                              onClick={newPrestamo}
                            >
                              Nuevo Préstamo
                            </button>
                          </div>
                        )}
                    </div>
                    <div className='col-span-12 sm:col-span-12 flex justify-center items-center flex-col mt-2'>
                      {prestamoActual &&
                        prestamoActual.pagado === false &&
                        diferenciasDias() === true && (
                          <h2 className='text-lg font-medium leading-none'>
                            Si paga el préstamo que solicitó a más tardar en la
                            fecha indicada{' '}
                            <span>
                              {moment(
                                prestamoActual.fechaCompromisoPago
                              ).format('DD-MM-YYYY')}
                            </span>{' '}
                            podrá solicitar nuevos préstamos y con aumentos de
                            límite.
                          </h2>
                        )}
                    </div>
                  </div>
                </div>
                <Abonos />
                <h5 className='font-medium mb-4'>
                  {/* A partir de este momento, si desea comunicarse con nosotros deberá usar
        este chat, el correo electrónico se usa solo para el envío de
        notificaciones. */}
                  {prestamoActual.estado === 'pendiente' && (
                    <p className='box bg-theme-11 text-white pl-5 py-1'>
                      Estado : {prestamoActual.estado}, aguarde por la revisión
                      de sus datos...
                    </p>
                  )}
                  {prestamoActual.estado === 'preAprobado' && (
                    <p className='box bg-theme-7 text-white pl-5 py-1'>
                      Estado : {prestamoActual.estado}, por favor cumpla con el
                      último requerimiento...
                    </p>
                  )}
                  {prestamoActual.estado === 'aprobado' && (
                    <p className='box bg-theme-10 text-white pl-5 py-1'>
                      Estado : {prestamoActual.estado}, en breves instantes le
                      serán transferidos los fondos...
                    </p>
                  )}
                  {prestamoActual.estado === 'prestado' && (
                    <p className='box bg-theme-9 text-white pl-5 py-1'>
                      Estado : {prestamoActual.estado}, los fondos fueron
                      transferidos, revisé el chat para obtener más detalles...
                    </p>
                  )}
                </h5>
                <div className='flex px-4 sm:px-8'>
                  <Chat
                    chat={chat}
                    prestamo={prestamoActual}
                    rol={myUser.rol === 'Usuario' ? 'Usuario' : 'Sistema'}
                  />
                </div>
              </div>
            )}
            {prestamoActual && prestamoActual.indexWizard !== 5 && (
              <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
                <div className='intro-y col-span-12 flex items-center justify-center sm:justify-end'>
                  {prestamoActual.indexWizard !== 1 && (
                    <button
                      className='button w-24 justify-center block bg-gray-200 text-gray-600'
                      onClick={back}
                    >
                      Atras
                    </button>
                  )}

                  <button
                    className='button w-24 justify-center block bg-theme-1 text-white ml-2'
                    onClick={next}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            )}
            {!prestamoActual && (
              <div className='px-4 sm:px-8 pt-6 border-t border-gray-200'>
                <div className='intro-y col-span-12 flex items-center justify-center sm:justify-end'>
                  <button
                    className='button w-24 justify-center block bg-gray-200 text-gray-600'
                    onClick={back}
                  >
                    Atras
                  </button>
                  <button
                    className='button w-24 justify-center block bg-theme-1 text-white ml-2'
                    onClick={next}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            )}

            <div className='intro-x mt-10 xl:mt-10 text-center xl:text-center text-gray-700'>
              Si deseas tener más información por favor{' '}
              <a
                className='text-theme-1 font-medium mx-1'
                href='https://www.youtube.com/watch?v=OBfSUvfcZ-o'
                // eslint-disable-next-line react/jsx-no-target-blank
                target='_blank'
              >
                Haz clic aquí
              </a>
              para ver un <b>videotutorial</b> donde se explica cómo hacer la
              solicitud de préstamo.
            </div>
            <div className='intro-x mt-5 xl:mt-5 text-gray-700 text-center xl:text-center font-medium bg-theme-2 box p-1'>
              La aprobación del micropréstamo dependerá de la veracidad y del
              llenado de los datos que se solicitan para evaluar su perfil,
              evite suministrar información errada.
              <br /> * Si tiene dudas, lea los términos y condiciones para
              entender como funcionan los préstamos.
              <br />
              <a
                className='text-theme-1'
                href='#!'
                onClick={(e) => modalTerminos()}
              >
                Términos y Condiciones
              </a>{' '}
              <br />
              <a
                className='text-theme-1'
                href='#!'
                onClick={(e) => modalInformacion()}
              >
                Información General
              </a>{' '}
            </div>

            {/*===== Modal Terminos Condiciones =====*/}
            {modalTerminoCondiciones && (
              <Modal
                title='Nuestros Terminos y Condiciones'
                close={closeModalTerminos}
                cancel={cancelModalTerminos}
                save={() => dispatch(modalTerminoCondicion(false))}
                customBtn={true}
                textcustomBtn='Aceptar'
              >
                <div className='intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh'>
                  <p className='my-2 whitespace-pre-wrap'>{config.terminos}</p>
                </div>
              </Modal>
            )}

            {/*===== Modal Boletin Informativo =====*/}
            {modalInformacionServicios && (
              <Modal
                title='Información sobre nuestro servicio'
                close={closeModalInformacion}
                cancel={cancelModalInformacion}
                save={() => dispatch(modalInformacionServicio(false))}
                customBtn={true}
                textcustomBtn='Aceptar'
              >
                <div className='intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh'>
                  <p className='my-2 whitespace-pre-wrap'>
                    {config.informacion}
                  </p>
                </div>
              </Modal>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Prestamos;
