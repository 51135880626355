import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { app } from '../../../config/firebaseConfig';
import { axiosInstance } from '../../../config/api';
import url from '../../../config/url';
import $ from 'jquery';
import * as Icon from 'react-feather';
import Slider from 'react-slick';
import moment from 'moment';
import jsPDF from 'jspdf';
import { custom } from '../../../helpers/alertas';
import { restaFechas } from '../../../helpers/generales';
import Document from '../../../statics/images/selfie_prest-1.jpeg';

import {
  LOADING,
  PRESTAMOACTUAL,
  GETCHAT,
  REQUERIMIENTOPRESTAMO,
  ENDLOADING,
} from '../../../redux/types';

import {
  getRequerimientoPrestamo,
  getPrestamos,
} from '../../../redux/actions/userAction';

import { addLog } from '../../../redux/actions/sesionAction';
import { sendMsg, addChat } from '../../../redux/actions/gestorActions';

const Revicion = ({ siguiente }) => {
  const dispatch = useDispatch();
  const fileSelfieRef = useRef(null);
  let { userid } = useParams();

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const prestamoActual = useSelector((state) => state.user.prestamoActual);
  const chat = useSelector((state) => state.user.chat);
  const requerimientoPrestamo = useSelector(
    (state) => state.user.requerimientoPrestamo
  );
  const configuracionGlobal = useSelector(
    (state) => state.user.configuracionGlobal
  );

  //========== USE-STATES ==========//
  const [days, setDays] = useState([]);
  const [cantidad, setCantidad] = useState(prestamoActual.montoPrestado);
  const [fecha, setFecha] = useState(prestamoActual.fechaCompromisoPago);
  const new_date = moment(prestamoActual.fechaInicio);
  const [adminis, setAdminis] = useState([]);

  const mindate = new_date
    .add(parseInt(configuracionGlobal.diasMinimoPago, 10), 'days')
    .format('YYYY-MM-DD');

  useEffect(() => {
    (function ($) {
      $('.tooltip').each(function () {
        let options = {
          delay: 0,
          theme: 'tooltipster-borderless',
          multiple: true,
        };
        if ($(this).data('event') === 'on-click') {
          options.trigger = 'click';
        }
        if ($(this).data('theme') === 'light') {
          options.theme = 'tooltipster-shadow';
        }
        if ($(this).data('side') !== undefined) {
          options.side = $(this).data('side');
        }
        $(this).tooltipster(options);
      });
    })($);
    const leerChats = () => {
      app
        .firestore()
        .collection('chat')
        .where('idPrestamo', '==', `${prestamoActual.id}`)
        .onSnapshot(manejarSnapshotChat);
    };
    const leerRequerimientosPrestamo = () => {
      app
        .firestore()
        .collection('requerimientoPrestamo')
        .where('idPrestamo', '==', `${prestamoActual.id}`)
        .onSnapshot(manejarSnapshotRequerimientosPrestamo);
    };
    dispatch(getRequerimientoPrestamo(prestamoActual.id));
    dispatch(getPrestamos(prestamoActual.id));
    updateDataPrestamo();
    userAdminis();
    //getRequerimiento();
    leerRequerimientosPrestamo();
    leerChats();
    const diferencia = restaFechas(
      prestamoActual.fechaInicio,
      prestamoActual.fechaCompromisoPago
    );
    armarDays(diferencia);
  }, []);

  const userAdminis = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Bloqueado'])
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdminis(array);
      });
  };

  const settings = {
    className: 'mr-2',
    dots: false,
    infinite: false,
    speed: 500,
    rows: 3,
    slidesPerRow: 10,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          rows: 4,
          slidesPerRow: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 5,
          slidesPerRow: 6,
        },
      },
      {
        breakpoint: 700,
        settings: {
          rows: 8,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 10,
          slidesPerRow: 3,
        },
      },
    ],
  };
  function manejarSnapshotChat(snapshot) {
    const cuentas = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      dispatch({ type: GETCHAT, payload: obj });
      return obj;
    });
  }
  const formatFecha = (dateFecha, dia) => {
    return moment(dateFecha).add(dia, 'days').format('DD/MM/YYYY');
  };
  const calculateInteresByDia = (dia) => {
    let totalApagar = (
      cantidad *
      Math.pow(
        1 +
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? configuracionGlobal.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          ) /
            100,
        dia
      )
    ).toFixed(2);
    return totalApagar;
  };
  const exportar = () => {
    const addFooters = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont('helvetica', 'italic');
      doc.setFontSize(10);
      for (var i = 0; i < pageCount; i++) {
        doc.setTextColor(51, 122, 183);
        doc.text(
          'www.chamopresta.com',
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 20,
          {
            align: 'center',
          }
        );
      }
    };
    let doc = new jsPDF('p', 'pt', 'A4', true);
    let title = `Tabla de interés pautado ${moment(fecha).format(
      'DD/MM/YYYY'
    )}`;
    doc.autoTable({
      head: [[title]],
      body: [],
      headStyles: {
        fontSize: 18,
        fillColor: [255, 255, 255],
        textColor: [40, 167, 69],
      },
    });

    let data = [];
    days.map((day, i) => {
      data.push([
        `${formatFecha(
          moment(prestamoActual.fechaInicio).format('YYYY-MM-DD'),
          day
        )}`,
        `${calculateInteresByDia(day)}$`,
      ]);
    });
    doc.autoTable({
      head: [['Fecha', 'Monto']],
      body: data,
      allSectionHooks: true,
      headStyles: {
        fillColor: [28, 63, 170],
      },
    });
    let Subtitle = `Usted al solicitar el préstamo acepta los intereses mostrados y se compromete a pagar en el tiempo pactado: ${moment(
      fecha
    ).format('DD/MM/YYYY')}`;
    doc.autoTable({
      head: [[Subtitle]],
      body: [],
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fontSize: 13,
        fillColor: [255, 255, 255],
        textColor: [40, 167, 69],
      },
    });
    addFooters(doc);
    //window.open(doc.output('bloburl'), '_blank');
    doc.save(`interes-compuesto.pdf`);
  };
  const changeFecha = (e) => {
    const value = e.target.value;
    setFecha(value);
    let diff = getDirerence(value);
    const nuevoMontoCompromiso = calculateInteres(cantidad, diff);
    app
      .firestore()
      .collection('prestamos')
      .doc(prestamoActual.id)
      .update({
        fechaCompromisoPago: value,
        montoCompromisoPago: parseFloat(nuevoMontoCompromiso),
      })
      .then(() => {
        dispatch(getPrestamos(prestamoActual.id));
      });
  };
  const calculateInteres = (cant, dias) => {
    let totalApagar = (
      cant *
      Math.pow(
        1 +
          parseFloat(
            myUser.habilitadoNuevoPrestamo === 'No'
              ? configuracionGlobal.tasaInteresCompuesto
              : myUser.nuevaTasaInteres
          ) /
            100,
        dias
      )
    ).toFixed(2);
    return totalApagar;
  };
  const getDirerence = (fechaValue) => {
    var NowMoment = moment(prestamoActual.fechaInicio).format('YYYY-MM-DD');
    var fecha1 = moment(NowMoment);
    var fecha2 = moment(fechaValue);
    let diff = fecha2.diff(fecha1, 'days');
    console.log('diff', diff);
    armarDays(diff);
    return diff;
  };
  const armarDays = (dias) => {
    let array = [];
    for (let i = dias; i >= 1; i--) {
      array.push(i);
    }
    setDays(array.reverse());
  };
  function updateDataPrestamo() {
    app
      .firestore()
      .collection('prestamos')
      .doc(prestamoActual.id)
      .onSnapshot({}, (doc) => {
        let updatePrestamo = {
          id: doc.id,
          ...doc.data(),
        };
        dispatch({ type: PRESTAMOACTUAL, payload: updatePrestamo });
      });
  }

  function manejarSnapshotRequerimientosPrestamo(snapshot) {
    let requerimientosPrestamo = [];
    snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      requerimientosPrestamo.push(obj);
    });
    dispatch({ type: REQUERIMIENTOPRESTAMO, payload: requerimientosPrestamo });
  }

  const loadSelfie = (e) => {
    dispatch({ type: LOADING });
    let file = e.target.files[0];
    if (requerimientoPrestamo.length > 0) {
      let filename = getImageName(requerimientoPrestamo[0].selfiePrestamo);
      // Delete the file si existe
      const storageRef = app.storage().ref(`${filename}`);
      storageRef
        .delete()
        .then(() => {
          upload(file);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      upload(file);
    }
  };

  const upload = (file) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      const textoEnviar = `
        <p>El usuario: ${prestamoActual.usuario.nombres} ${prestamoActual.usuario.apellidos}</p>
        <p>Email: ${prestamoActual.usuario.email}</p>

        <p>Ha subido el último requerimiento para la solicitud de su préstamo.</p> 
      `;
      const para = adminis.map((admin) => admin.email);
      const emailGestor = {
        asunto: 'Requerimientos',
        email: para,
        motivo: `<span>${textoEnviar}</span>`,
      };
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          if (requerimientoPrestamo.length > 0) {
            let data = {
              selfiePrestamo: url,
              idRequerimiento: requerimientoPrestamo[0].id,
            };
            app
              .firestore()
              .collection('requerimientoPrestamo')
              .doc(`${requerimientoPrestamo[0].id}`)
              .update(data)
              .then(() => {
                dispatch(getRequerimientoPrestamo(prestamoActual.id));
                dispatch({ type: ENDLOADING });
                enviarNotificacion(emailGestor);
                enviarMesaje(textoEnviar, 'Usuario');
              });
          } else {
            app
              .firestore()
              .collection('requerimientoPrestamo')
              .add({
                idPrestamo: prestamoActual.id,
                idRequerimiento: '',
                idUsuario: app.auth().currentUser.uid,
                selfiePrestamo: url,
                validadoGestorSelfiePrestamo: null,
                validadoSelfiePrestamo: '',
              })
              .then(() => {
                dispatch(getRequerimientoPrestamo(prestamoActual.id));
                dispatch({ type: ENDLOADING });
                enviarNotificacion(emailGestor);
                enviarMesaje(textoEnviar, 'Usuario');
              });
          }
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };

  const upload2 = (file) => {
    const imageExtension =
      file.name.split('.')[file.name.split('.').length - 1];
    const imageFileName = `${Math.round(
      Math.random() * 100000000000
    )}.${imageExtension}`;
    if (
      imageExtension.toLowerCase() === 'jpg' ||
      imageExtension.toLowerCase() === 'jpeg' ||
      imageExtension.toLowerCase() === 'png' ||
      imageExtension.toLowerCase() === 'gif' ||
      imageExtension.toLowerCase() === 'svg' ||
      imageExtension.toLowerCase() === 'bmp' ||
      imageExtension.toLowerCase() === 'tiff'
    ) {
      const storageRef = app.storage().ref(imageFileName);
      const task = storageRef.put(file);
      task.on(
        'state_changed',
        (snapshot) => {
          console.log('funcionando');
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          let url = `https://firebasestorage.googleapis.com/v0/b/${task.snapshot.metadata.bucket}/o/${task.snapshot.metadata.fullPath}?alt=media`;
          let data = {
            selfiePrestamo: url,
            idRequerimiento: requerimientoPrestamo[0].id,
          };
          app
            .firestore()
            .collection('requerimiento')
            .doc(`${requerimientoPrestamo[0].id}`)
            .update(data)
            .then(() => {
              dispatch(getRequerimientoPrestamo(prestamoActual.id));
              dispatch({ type: ENDLOADING });
            });
        }
      );
    } else {
      custom(
        'Solo puede subir imágenes, en los siguientes formatos jpg, jpeg, png, gif, svg, bmp, tiff.',
        'error'
      );
      dispatch({ type: ENDLOADING });
    }
  };

  const openExplorerSelfie = (e) => {
    fileSelfieRef.current.click();
    enviarMesaje(
      `<span>Su imagen será revisada, tomar en consideración que esta imagen debe ser lo más nítida posible para que el texto sea legible...</span>`,
      'Sistema'
    );
    dispatch(
      addLog({
        accion: `${'ha Completado todos los datos del préstamo '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };

  const getImageName = (url) => {
    let primera = url.split('/o/')[1];
    return primera.split('?')[0];
  };

  //========== Funciones de Envio Mensaje ==========//
  const enviarMesaje = (mensaje, rol) => {
    let mensajesArray = [];
    if (chat) {
      let newChat = {
        ...chat,
        ['fechaActualizacion']: moment(new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        ['idChat']: chat.id,
      };
      mensajesArray = [...chat.mensajes];
      mensajesArray.push({
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        rol,
        texto: mensaje,
      });
      dispatch(sendMsg(newChat, mensajesArray));
    } else {
      let chat = {
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        idChat: '',
        idPrestamo: userid,
        mensajes: [
          {
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            rol,
            texto: mensaje,
          },
        ],
      };
      dispatch(addChat(chat));
    }
  };

  return (
    <>
      {prestamoActual && (
        <div className='flex flex-col sm:flex-row items-center p-4 w-full'>
          {prestamoActual.estado === 'pendiente' && (
            <h2 className='text-2xl text-theme-1 font-medium leading-none mb-4'>
              Gracias por completar los pasos previos, su préstamo se encuentra
              en evaluación.
            </h2>
          )}
          {(prestamoActual.estado === 'preAprobado' ||
            prestamoActual.estado === 'aprobado') && (
            <div className='grid grid-cols-12 gap-4 row-gap-5 mb-6 w-full'>
              <div className='intro-y col-span-12 sm:col-span-12'>
                {requerimientoPrestamo.length === 0 && (
                  <h2 className='text-2xl text-theme-3 font-medium leading-none mb-4'>
                    Felicidades su préstamo ha sido PRE-Aprobado, realiza el
                    último paso para recibir el dinero.
                  </h2>
                )}
                {/* si subio la imagen pero no ha sido revisada */}
                {requerimientoPrestamo.length > 0 &&
                  requerimientoPrestamo[0].validadoSelfiePrestamo === '' && (
                    <h2 className='text-2xl text-theme-3 font-medium leading-none mb-4'>
                      Su préstamo esta PRE-Aprobado, estas a un solo paso.
                    </h2>
                  )}
                {/* si subio la imagen pero esta rechazada la misma */}
                {requerimientoPrestamo.length > 0 &&
                  requerimientoPrestamo[0].validadoSelfiePrestamo ===
                    'rechazado' && (
                    <>
                      <h2 className='text-2xl text-theme-3 text-center font-medium leading-none mb-4'>
                        Préstamo Rechazado.
                      </h2>
                    </>
                  )}
                {/* si subio la imagen, y esta esta aprobada */}
                {requerimientoPrestamo.length > 0 &&
                  requerimientoPrestamo[0].validadoSelfiePrestamo ===
                    'aprobado' && (
                    <h2 className='text-2xl text-theme-3 text-center font-medium leading-none mb-4'>
                      Felicidades, su último requerimiento ha sido verificado,
                      el préstamo ha sido aprobado.
                    </h2>
                  )}
                <div className='text-center'>
                  {/* si subio la imagen, y no se ha revisado */}
                  {requerimientoPrestamo.length > 0 &&
                    requerimientoPrestamo[0].validadoSelfiePrestamo === '' && (
                      <h5 className='font-medium mb-4'>
                        Su imagen será verificada, se comparará esta foto con su
                        documento de identidad, manténgase en línea, está a solo
                        un paso de recibir su préstamo.
                      </h5>
                    )}
                  {/* si subio la imagen pero fue rechazada */}
                  {requerimientoPrestamo.length > 0 &&
                    requerimientoPrestamo[0].validadoSelfiePrestamo ===
                      'rechazado' && (
                      <div className='flex flex-col'>
                        <h5 className='font-medium mb-4'>
                          Su último requerimiento ha sido rechazado, por favor
                          cambie la imagen con las siguientes indicaciones:
                        </h5>
                        <h5 className='font-medium mb-4'>
                          Selfie sosteniendo una Hoja blanca e indicando
                          "Solicito préstamo a Chamo Presta por la cantidad de ?
                          el monto debe ser en USD y la fecha del día".
                        </h5>
                      </div>
                    )}
                  {/* No ha subido la imagen*/}
                  {requerimientoPrestamo.length === 0 && (
                    <h5 className='font-medium mb-4'>
                      Para recibir el préstamo debes adjuntar una selfie
                      sosteniendo una hoja donde hayas escrito con letra legible
                      "Solicito un PRÉSTAMO por $ {prestamoActual.montoPrestado}{' '}
                      dólares y me comprometo a pagar ${' '}
                      {prestamoActual.montoCompromisoPago} dólares el{' '}
                      {prestamoActual.fechaCompromisoPago}"
                    </h5>
                  )}
                  {/* si subio la imagen */}
                  {requerimientoPrestamo.length > 0 && (
                    <>
                      {requerimientoPrestamo[0].validadoSelfiePrestamo !==
                        'aprobado' && (
                        <>
                          <img
                            className='border m-auto rounded my-2'
                            alt=''
                            style={{ width: 500, height: 300 }}
                            src={
                              requerimientoPrestamo[0].selfiePrestamo !== ''
                                ? requerimientoPrestamo[0].selfiePrestamo
                                : Document
                            }
                          />
                          <label className='font-medium mb-4' htmlFor=''>
                            Imagen de Solicitud de Préstamo Cargada
                          </label>
                          <div>
                            {requerimientoPrestamo.length !== 0 && (
                              <a
                                href='javascript:;'
                                className='tooltip button inline-block bg-theme-1 text-white m-auto rounded-full mt-4'
                                title='Subir Selfie!!!!'
                                onClick={openExplorerSelfie}
                              >
                                <span className='w-6 h-6 flex items-center justify-center'>
                                  <Icon.FolderPlus className='w-6 h-6' />
                                </span>
                              </a>
                            )}
                          </div>
                        </>
                      )}
                      {/* si subio la imagen, y esta esta aprobada */}
                      {requerimientoPrestamo.length > 0 &&
                        requerimientoPrestamo[0].validadoSelfiePrestamo ===
                          'aprobado' && (
                          <>
                            <h5 className='text-base font-medium my-5 bg-theme-15 box p-1'>
                              Su último requerimiento ha sido verificado
                              exitosamente, presione siguiente para concretar la
                              operación.
                            </h5>
                          </>
                        )}
                      {requerimientoPrestamo.length > 0 &&
                        requerimientoPrestamo[0].validadoSelfiePrestamo ===
                          'aprobado' && (
                          <>
                            <div className='flex font-medium p-1 bg-theme-5 box mb-4 overflow-auto lg:overflow-visible'>
                              <div className='flex col-span-12 sm:col-span-6 w-full'>
                                <label className='ml-10'>
                                  Su fecha de compromiso de pago es la
                                  siguiente:
                                </label>
                              </div>
                              <div className='flex col-span-12 sm:col-span-6 w-full'>
                                <label className='ml-3 bg-theme-2 px-4 box'>
                                  {prestamoActual.fechaCompromisoPago}
                                </label>
                              </div>
                            </div>
                            <div className='flex font-medium p-1 bg-theme-5 box mb-4 overflow-auto lg:overflow-visible'>
                              <div className='flex col-span-12 sm:col-span-6 w-full items-center'>
                                <label className='ml-10'>
                                  Confirmar fecha o modificar :
                                </label>
                              </div>
                              <div className='flex col-span-12 sm:col-span-6 w-full'>
                                <label className='ml-3'>
                                  <input
                                    type='date'
                                    name='fecha'
                                    value={fecha}
                                    min={mindate}
                                    onChange={changeFecha}
                                    className='input input--sm w-40 border block rounded-full'
                                  />
                                </label>
                              </div>
                            </div>
                            {days.length > 0 && (
                              <div
                                className='col-span-12 sm:col-span-12 flex justify-center items-center my-2 overflow-auto lg:overflow-visible'
                                style={{ flexDirection: 'column' }}
                              >
                                <h2 className='font-medium bg-theme-2 box p-1 mb-2'>
                                  Así estará distribuido el interés por día
                                </h2>
                                {/*<div className="flex justify-center wrap crousel" id="interespordia" ref={divIntereses} >*/}
                                <div className='carousel'>
                                  <Slider {...settings} className='w-full'>
                                    {days.map((day, i) => {
                                      return (
                                        <div
                                          className='box px-2 py-2 mb-3 flex items-center zoom-in bg-theme-26'
                                          key={i}
                                        >
                                          <div>
                                            <div className='font-medium'>
                                              {`${formatFecha(
                                                prestamoActual.fechaInicio,
                                                day
                                              )}:`}
                                            </div>
                                            <div className='py-1 px-1 rounded-full text-xs bg-theme-9 text-white cursor-pointer font-medium text-center'>
                                              {`${calculateInteresByDia(
                                                day
                                              )}$$`}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </Slider>
                                </div>
                                {/*</div>*/}
                                <button
                                  className='button w-full xl:w-32 text-white bg-theme-1 xl:mr-3 p-2 mt-3 flex items-center justify-center'
                                  onClick={exportar}
                                >
                                  <Icon.FileText className='text-white mr-2 ml-1 w-4' />
                                  Exportar
                                </button>
                              </div>
                            )}
                            <div className='intro-y col-span-12 sm:col-span-12 text-center bg-theme-5 box p-1 my-2'>
                              <div className='flex flex-col sm:flex-row justify-center'>
                                <label className='flex font-medium mr-2'>
                                  ¿Estás de acuerdo? : presionar Siguiente
                                  <Icon.FastForward
                                    className='flex ml-3 w-5 p-1 pointer bg-theme-1 text-white'
                                    onClick={siguiente}
                                  />
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  )}
                  {/* si no ha subido la imagen */}
                  {requerimientoPrestamo.length === 0 && (
                    <img
                      className='border m-auto rounded'
                      alt=''
                      style={{ width: 500, height: 300 }}
                      src={Document}
                    />
                  )}
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={fileSelfieRef}
                    onChange={loadSelfie}
                  />
                  {requerimientoPrestamo.length === 0 && (
                    <a
                      href='javascript:;'
                      className='tooltip button inline-block bg-theme-1 text-white m-auto rounded-full mt-4'
                      title='Subir Selfie!????'
                      onClick={openExplorerSelfie}
                    >
                      <span className='w-6 h-6 flex items-center justify-center'>
                        <Icon.FolderPlus className='w-6 h-6' />
                      </span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          {prestamoActual.estado === 'rechazado' && (
            <h2 className='text-2xl text-theme-6 font-medium leading-none mb-4'>
              Estimado usuario su préstamo ha sido rechazado, en el chat podrá
              ver la razón.
            </h2>
          )}
        </div>
      )}
      <h5 className='font-medium mb-4'>
        {/* A partir de este momento, si desea comunicarse con nosotros deberá usar
        este chat, el correo electrónico se usa solo para el envío de
        notificaciones. */}
        {prestamoActual.estado === 'pendiente' && (
          <p className='box bg-theme-11 text-white pl-5 py-1'>
            Estado : {prestamoActual.estado}, aguarde por la revisión de sus
            datos...
          </p>
        )}
        {prestamoActual.estado === 'preAprobado' && (
          <p className='box bg-theme-11 text-white pl-5 py-1'>
            Estado : {prestamoActual.estado}, por favor cumpla con el último
            requerimiento...
          </p>
        )}
        {prestamoActual.estado === 'aprobado' && (
          <p className='box bg-theme-11 text-white pl-5 py-1'>
            Estado : {prestamoActual.estado}, en breves instantes le serán
            transferidos los fondos...
          </p>
        )}
        {prestamoActual.estado === 'prestado' && (
          <p className='box bg-theme-11 text-white pl-5 py-1'>
            Estado : {prestamoActual.estado}, los fondos fueron transferidos,
            revisé el chat para obtener más detalles...
          </p>
        )}
      </h5>
    </>
  );
};

export default Revicion;
