import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { axiosInstance } from '../../config/api';
import { confirm, custom } from '../../helpers/alertas';

import Modal from '../Modal';
import moment from 'moment';
import { app, createGeoPoint } from '../../config/firebaseConfig';
import Ilustracion from '../../statics/images/logo-chamopresta-PNG.png';

import {
  modalTerminoCondicion,
  modalPolitica,
  modalVerify,
  addLog,
} from '../../redux/actions/sesionAction';

const Register = (props) => {
  const dispatch = useDispatch();
  document.body.classList.remove('app');
  document.body.classList.add('login');

  //========== USE-STATES ==========//
  const [registerData, setRegisterData] = useState({
    email: '',
    password: '',
    repeatPasword: '',
  });
  const [errorRegister, setErrorRegister] = useState({
    color: '',
    message: '',
    view: false,
  });
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: -1,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });
  const [captchaRegister, setCaptchaRegister] = useState(false);
  const [view, setView] = useState('login');
  const [administrativos, setAdministrativos] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [correoVerify, setCorreoVerify] = useState({
    email: '',
  });

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const modalTerminoCondiciones = useSelector(
    (state) => state.sesion.modalTerminoCondiciones
  );
  const modalPoliticasPrivacidad = useSelector(
    (state) => state.sesion.modalPoliticasPrivacidad
  );
  const modalVerifyCorreo = useSelector(
    (state) => state.sesion.modalVerifyCorreo
  );
  const recaptchaInstanceRegister = useRef(null);

  useEffect(() => {
    getConfiguracion();
    userAdministrativos();
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
        } else {
          setConfig({
            diasMinimoPago: '',
            fondo: -1,
            montoInicialMaximoPrestamo: '',
            montoMaximoPrestamo: '',
            politicas: '',
            porcentajeIncremento: '',
            requisitos: '',
            tasaDolar: '',
            tasaInteresCompuesto: '',
            terminos: '',
          });
        }
      });
  };

  //========== Funciones y Metodos Asociados al Registro ==========//
  const onChangeRegsiter = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };
  const userAdministrativos = () => {
    app
      .firestore()
      .collection('usuarios')
      .where('rol', 'not-in', ['Usuario', 'Bloqueado'])
      .get()
      .then((data) => {
        let array = [];
        data.forEach((doc) => array.push(doc.data()));
        setAdministrativos(array);
        setTimeout(() => {
          // console.log(prestamoActual)
        }, 1000);
      });
  };
  const register = async (e) => {
    e.preventDefault();
    console.log(recaptchaInstanceRegister.current);
    confirm(
      `¿Está seguro(a) que su correo ${registerData.email} está escrito correctamente?`,
      'question'
    ).then(async (resp) => {
      if (resp) {
        if (
          registerData.email === '' ||
          registerData.password === '' ||
          registerData.repeatPasword === ''
        ) {
          showMessageRegister(
            'bg-theme-6',
            'Debe completar todos los campos *',
            true
          );
        } else if (registerData.password !== registerData.repeatPasword) {
          showMessageRegister(
            'bg-theme-6',
            'Las contraseñas no coinciden *',
            true
          );
        } else if (!captchaRegister) {
          showMessageRegister(
            'bg-theme-6',
            'Demuestra que eres Humano valida el captcha *',
            true
          );
        } else {
          showMessageRegister('', '', false);
          const email = registerData.email.trim();
          const password = registerData.password.trim();
          setSubmit(true);
          try {
            let { user } = await app
              .auth()
              .createUserWithEmailAndPassword(email, password);
            const datosGenerales = {
              apellidos: '',
              areaTrabajo: '',
              codigoPais: '',
              descripcionActividad: '',
              email: user.email,
              fechaNacimiento: '',
              fechaRegistro: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              geo: createGeoPoint(Number('10.491'), Number('-66.902')),
              habilitadoNuevoPrestamo: 'No',
              idUsuario: user.uid,
              ingresoMensualUSD: 0,
              localidad: '',
              metodosPagos: 'Venezuela-Transferencia',
              nombres: '',
              nombreEmpresa: '',
              nuevaTasaInteres: config.tasaInteresCompuesto,
              nuevoLimitePrestamo: config.montoInicialMaximoPrestamo,
              numeroCedula: '',
              numeroPasaporte: '',
              pais: '',
              rol: 'Usuario',
              sexo: 'M',
              telefonoOpcional: '',
              telegram: '',
              tiempoExtranjero: '',
              trabaja: 'No',
              whatsapp: '',
            };
            await app
              .firestore()
              .collection('usuarios')
              .doc(`${user.uid}`)
              .set(datosGenerales)
              .then((resp) => console.log('se creo el usuario', user.email))
              .catch((err) => console.log('error al crear el usuario', err));
            /*await user
            .sendEmailVerification()
            .then(function () {
              console.log('email send')
            })
            .catch(function (error) {
              console.log('no se envio el correo')
            })*/
            await axiosInstance.post(`/user/sendLinkEmailVerify`, {
              uid: user.uid,
              email: user.email,
            });

            //=== ENVIAR CORREO A LOS ADMINISTRADORES ===//
            const textoCorreoEnviar = `
              <p>El usuario _ Email: ${user.email}
              </p>
              <p>Se registro con exito en nuestra plataforma</p>
            `;
            const emailAdministrativos = {
              asunto: 'Registro Usuario',
              email: administrativos.map((admin) => admin.email),
              motivo: `<span>${textoCorreoEnviar}</span>`,
            };
            enviarNotificacion(emailAdministrativos);
            showMessageRegister(
              'bg-theme-9',
              'Usuario creado, se ha enviado un link de verificación de correo electrónico * Si no lo encuentras en la carpeta principal de tu correo, revisa tu bandeja de spam.',
              true
            );
            resetRecaptchaRegister();
            setSubmit(false);
            dispatch(
              addLog({
                accion: `${'El Usuario: '} ${
                  registerData.email
                } ${'Se Ha Registrado.'}`,
                fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                usuario: email,
              })
            );
          } catch (err) {
            console.log('error en catch', err);
            let msg = '';
            switch (err.code) {
              case 'auth/email-already-in-use':
                msg =
                  '*Correo ya se encuentra registrado en nuestra plataforma.';
                break;
              case 'auth/invalid-email':
                msg = '*Formato de email invalido.';
                break;
              case 'auth/operation-not-allowed':
                msg =
                  '*App no tiene habilitada la autenticacion mediante correo y contraseña.';
                break;
              case 'auth/weak-password':
                msg = '*La contraseña no es lo suficientemente segura.';
                break;
              default:
                msg = '*Ocurrió un error en el registro.';
                break;
            }
            showMessageRegister('bg-theme-6', msg, true);
          }
          setSubmit(false);
        }
      }
    });
  };
  const showMessageRegister = (color, message, view) => {
    setErrorRegister({ color, message, view });
  };
  const verifyrecaptchaRgister = (response) => {
    if (response) {
      setCaptchaRegister(true);
    }
  };
  const resetRecaptchaRegister = () => {
    recaptchaInstanceRegister.current.reset();
    setCaptchaRegister(false);
    setRegisterData({ email: '', password: '', repeatPasword: '' });
  };
  const enviarNotificacion = async (data) => {
    return await axiosInstance.post(`/notifications/sendmail`, data);
  };

  //========== Funciones y Metodos Comunes ==========//
  const recaptchaLoaded = () => {
    // console.log('capcha loaded successfull');
  };

  //========== Funciones Modal Terminos ==========//
  const closeModalTerminos = () => {
    dispatch(modalTerminoCondicion(false));
  };
  const cancelModalTerminos = () => {
    dispatch(modalTerminoCondicion(false));
  };
  const modalTerminos = (params) => {
    dispatch(modalTerminoCondicion(true));
  };

  //========== Funciones Politicas y Privacidad ==========//
  const closeModalPoliticas = () => {
    dispatch(modalPolitica(false));
  };
  const cancelModalPoliticas = () => {
    dispatch(modalPolitica(false));
  };
  const modalPoliticas = (params) => {
    dispatch(modalPolitica(true));
  };

  //========== Funciones Verificar Correo ==========//
  const closeModalVerify = () => {
    dispatch(modalVerify(false));
  };
  const cancelModalVerify = () => {
    dispatch(modalVerify(false));
  };
  const modalVerifyEmail = (params) => {
    dispatch(modalVerify(true));
  };
  const changeCorreoVerify = (e) => {
    const { name, value } = e.target;
    setCorreoVerify({ ...correoVerify, [name]: value });
  };
  const verificarCorreo = async () => {
    if (correoVerify.email === '') {
      custom('Debe llenar todos los campos obligatorios *', 'error');
    } else {
      await app
        .firestore()
        .collection('usuarios')
        .where('email', '==', correoVerify.email)
        .get()
        .then((data) => {
          console.log('DATA', data);
          if (data.empty === true) {
            custom(
              'Este correo no ha sido registrado en nuestro sistema, por favor verifique que este escrito correctamente.',
              'error'
            );
          } else {
            let User;
            let obj = {};
            data.forEach((doc) => {
              obj = doc.data();
              obj.id = doc.id;
              User = obj.id;
            });
            app
              .firestore()
              .collection('emailVerify')
              .where('uid', '==', User)
              .get()
              .then((data) => {
                let linkVerify;
                let verify = {};
                data.forEach((doc) => {
                  verify = doc.data();
                  verify.id = doc.id;
                  linkVerify = verify.id;
                });

                // Enviar Correo
                const verificationLink =
                  'https://chamopresta.com/verificacion?mode=verifyEmail&Code=' +
                  linkVerify;
                const textoCorreoEnviar = `
              <p>Hola, verifique su correo en el siguiente link.</p>
              <a href="${verificationLink}">${verificationLink}</a>
              <p>Si no solicitaste la verificación de esta dirección, ignora este correo electrónico.</p>
              <p>Gracias.</p>
            `;
                const emailUserVerify = {
                  asunto: 'Verificación Link Registro - ChamoPresta.Com',
                  email: correoVerify.email,
                  motivo: `<span>${textoCorreoEnviar}</span>`,
                };
                enviarNotificacion(emailUserVerify);

                dispatch(modalVerify(false));
                showMessageRegister(
                  'bg-theme-9',
                  'Se ha reenviado un link de verificación a su correo electrónico *',
                  true
                );
                setTimeout(() => {
                  showMessageRegister('', '', false);
                }, 10000);
              });
          }
        });
    }
  };

  return (
    <div>
      <div className='container sm:px-10'>
        <div className='block xl:grid grid-cols-2 gap-4'>
          <div className='xl:flex flex-col min-h-screen my-auto mx-auto w-2/5 sm:w-2/4 lg:w-2/5 xl:w-auto ocultar'>
            <a href='/'>
              <img
                alt='Midone Tailwind HTML Admin Template'
                className='-intro-x w-3\/4 -ml-20'
                src={Ilustracion}
              />
            </a>
            <div className='-intro-x text-white font-medium sm:text-4xl text-2xl leading-tight mt-10'>
              ¡Préstamos sin garantías!
              <br />
              Fácil y rápida aprobación…
            </div>
            <div className='-intro-x mt-6 sm:text-lg text-white'>
              Dirigido a venezolanos en el exterior.
            </div>
          </div>
          {/* Responsive */}
          <div className='lg:hidden ml-4'>
            <a href='/'>
              <img
                alt='Midone Tailwind HTML Admin Template'
                className='-intro-x w-3\/4 -ml-5'
                src={Ilustracion}
              />
            </a>
          </div>
          {/* Responsive Fin */}
          <form onSubmit={register}>
            <div className='h-screen flex py-5 custom-auth'>
              <div className='my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-y10 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto'>
                <h2 className='intro-x font-bold text-xl xl:text-xl text-center xl:text-left'>
                  Registrate
                </h2>
                <p className='text-gray-500 my-2'>
                  Por favor no utilizar correos de tipo Hotmail
                </p>
                <div className='intro-x mt-2'>
                  <input
                    type='email'
                    name='email'
                    placeholder=' Correo electronico'
                    className='intro-x login__input input rounded-full border border-gray-300 block mt-4'
                    value={registerData.email}
                    onChange={onChangeRegsiter}
                  />
                  <p className='text-gray-500 mt-5'>
                    Crear una contraseña para usar este servicio
                  </p>
                  <input
                    type='password'
                    name='password'
                    placeholder=' Contraseña'
                    className=' intro-x login__input input rounded-full border border-gray-300 block mt-3'
                    value={registerData.password}
                    onChange={onChangeRegsiter}
                  />
                  <input
                    type='password'
                    name='repeatPasword'
                    placeholder=' Repetir contraseña'
                    className='intro-x login__input input rounded-full border border-gray-300 block mt-3'
                    value={registerData.repeatPasword}
                    onChange={onChangeRegsiter}
                  />
                </div>
                {errorRegister.view && (
                  <div className='intro-x mt-3'>
                    <div
                      className={[
                        'rounded-md',
                        'px-5',
                        'py-4',
                        'mb-2',
                        'text-white',
                        `${errorRegister.color}`,
                      ].join(' ')}
                    >
                      {errorRegister.message}
                    </div>
                    <div className='intro-x mt-3 xl:mt-3 text-gray-700 bg-theme-5 ml-3 p-2'>
                      <span className='text-gray-700 font-semibold mr-2'>
                        Sí te llego el correo de verificación en blanco
                      </span>
                      <a
                        className='font-semibold text-theme-1 xl:mt-0 xs:w-full'
                        href='#!'
                        onClick={(e) => modalVerifyEmail()}
                      >
                        Haz clic aquí
                      </a>
                    </div>
                  </div>
                )}
                <div className='intro-x mt-5 xl:mt-5 text-center xl:text-center'>
                  <div>
                    <Recaptcha
                      ref={recaptchaInstanceRegister}
                      sitekey='6LfdezoaAAAAAMR-vF2KYhgIPNgCeo40ZKTshnIt'
                      render='explicit'
                      hl='es'
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyrecaptchaRgister}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                </div>
                <div className='intro-x mt-3 xl:mt-3'>
                  <button
                    type='submit'
                    className='button w-full xl:w-32 text-white bg-theme-1 xl:mr-3'
                  >
                    {!submit && (
                      <span className='indicator-label'>Registrarme </span>
                    )}
                    {submit && (
                      <span className='indicator-label'>
                        Cargando... _
                        <span className='spinner-border spinner-border-sm  align-middle ml-1'></span>
                      </span>
                    )}
                  </button>
                </div>
                <div className='intro-x mt-8 xl:mt-4 text-gray-700'>
                  <span className='text-gray-700 font-semibold mr-2'>
                    Si ya te registraste
                  </span>
                  <Link to='/login'>
                    <span className='font-semibold text-theme-1 xl:mt-0 xs:w-full'>
                      Ingresa aquí
                    </span>
                  </Link>
                </div>
                <div className='intro-x mt-3 xl:mt-3 text-gray-700'>
                  <span className='text-gray-700 font-semibold'>¿Dudas?</span>
                  <a
                    className='text-theme-1 font-medium mx-1'
                    href='https://www.youtube.com/watch?v=OBfSUvfcZ-o'
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target='_blank'
                  >
                    Haz clic aquí
                  </a>
                  para ver nuestro <b>videotutorial</b>
                  <br />
                  de cómo solicitar un préstamo.
                </div>
                <div className='intro-x mt-8 xl:mt-4 text-gray-700 text-center xl:text-center'>
                  Al ingresar y registrarse acepta nuestros
                  <br />
                  <a
                    className='text-theme-1 font-medium'
                    href='#!'
                    onClick={(e) => modalTerminos()}
                  >
                    Terminos y Condiciones
                  </a>{' '}
                  &{' '}
                  <a
                    className='text-theme-1 font-medium'
                    href='#!'
                    onClick={(e) => modalPoliticas()}
                  >
                    Politica de Privacidad
                  </a>
                  <p>de ChamoPresta.com</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/*===== Modal Terminos Condiciones =====*/}
      {modalTerminoCondiciones && (
        <Modal
          title='Nuestros Terminos y Condiciones'
          close={closeModalTerminos}
          cancel={cancelModalTerminos}
          save={() => dispatch(modalTerminoCondicion(false))}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          <div className='intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh'>
            <p className='my-1 whitespace-pre-wrap'>{config.terminos}</p>
          </div>
        </Modal>
      )}
      {/*===== Modal Politica y Privacidad =====*/}
      {modalPoliticasPrivacidad && (
        <Modal
          title='Nuestras Politica de Privacidad'
          close={closeModalPoliticas}
          cancel={cancelModalPoliticas}
          save={() => dispatch(modalPolitica(false))}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          <div className='intro-y col-span-12 sm:col-span-12 overflow-y-scroll h-vh'>
            <p className='my-1 whitespace-pre-wrap'>{config.politicas}</p>
          </div>
        </Modal>
      )}
      {/*===== Modal Verificar correo =====*/}
      {modalVerifyCorreo && (
        <Modal
          title='Verificar correo electrónico'
          close={closeModalVerify}
          cancel={cancelModalVerify}
          save={verificarCorreo}
          customBtn={true}
          textcustomBtn='Aceptar'
        >
          <div className='intro-y col-span-12'>
            <p className='font-semibold mb-2'>
              Indique el correo electrónico al que le llego el mensaje en
              blanco.
            </p>
            <hr />
          </div>
          <div className='intro-y col-span-12 sm:col-span-6'>
            <label className='font-medium bg-theme-26 box p-1'>Correo :</label>
            <input
              type='email'
              name='email'
              placeholder='Correo electrónico'
              className='intro-x login__input input rounded-full border border-gray-300 block mt-4'
              onChange={changeCorreoVerify}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Register;
