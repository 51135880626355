import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';

import Modal from '../../../components/Modal';
import * as Icon from 'react-feather';
import moment from 'moment';

import { app } from '../../../config/firebaseConfig';
import { setConfiguration } from '../../../redux/actions/adminAction';
import { custom } from '../../../helpers/alertas';
import { isURL } from '../../../helpers/generales';
import paises from '../../../config/paises';
import url from '../../../config/url';

import { addLog } from '../../../redux/actions/sesionAction';

import AreasDeTrabajo from './AreasDeTrabajo';
import AgregarPropiedades from './AgregarPropiedades';

const Configuration = () => {
  const dispatch = useDispatch();

  let arrayOptions = [];
  arrayOptions = paises.listado.map((item) => {
    return { label: item, value: item };
  });

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);

  //========== USE-STATES ==========//
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: -1,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });
  const [cuentasBancarias, setCuentasBancarias] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [modalCuenta, setModalCuenta] = useState(false);
  const [modelo, setModelo] = useState({
    banco: '',
    codigo: '',
    email: '',
    pais: '',
    tipo: '',
  });
  const [defaultValue, setDefaultValue] = useState({ label: '', value: '' });
  const [options, setOptions] = useState(arrayOptions);
  const [editar, setEditar] = useState(false);
  const [idDoc, setIdDoc] = useState('');
  const [redSocial, setRedSocial] = useState({
    id: '',
    tipo: '',
    url: '',
  });
  const [redesSociales, setredesSociales] = useState([]);
  const [modalRed, setModalRed] = useState(false);
  const [tasaDolarHoy, setTasaDolarHoy] = useState(0);

  useEffect(() => {
    const getCuentas = () => {
      app
        .firestore()
        .collection('cuentasInternacionales')
        .orderBy('pais', 'asc')
        .onSnapshot(manejarSnapshot);
    };
    const getRedes = () => {
      app
        .firestore()
        .collection('redesSocialesEmpresa')
        .onSnapshot(snapshotRedes);
    };
    getCuentas();
    getRedes();
    getConfiguracion();
    getDolarValue();
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
        } else {
          setConfig({
            diasMinimoPago: '',
            fondo: -1,
            informacion: '',
            montoInicialMaximoPrestamo: '',
            montoMaximoPrestamo: '',
            politicas: '',
            porcentajeIncremento: '',
            requisitos: '',
            tasaDolar: '',
            tasaInteresCompuesto: '',
            terminos: '',
          });
        }
      });
  };
  const getDolarValue = async () => {
    try {
      let response = await axios.get(`${url.apiprod}/monitor`);
      const { data } = response;
      let dataBien = data.resp.replaceAll('.', '');
      dataBien = dataBien.replace(',', '.');
      setTasaDolarHoy(parseFloat(dataBien));
      await app
        .firestore()
        .collection('configuracion')
        .doc('general')
        .update({ tasaDolar: parseFloat(dataBien) });
    } catch (err) {
      console.log(err, 'error');
    }
  };
  function manejarSnapshot(snapshot) {
    const cuentas = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      return obj;
    });
    setCuentasBancarias(cuentas);
  }
  function snapshotRedes(snapshot) {
    const redes = snapshot.docs.map((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      return obj;
    });
    setredesSociales(redes);
  }
  const saveInteres = (e) => {
    e.preventDefault();
    let data = { ...config, fondo: parseFloat(config.fondo) };
    dispatch(setConfiguration(data));
    viewAlert(true);
    setTimeout(() => {
      viewAlert(false);
    }, 2500);
  };

  //========== Cuentas ==========//
  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig({ ...config, [name]: value });
  };
  const changePais = (e) => {
    setDefaultValue(e);
    setModelo({
      ...modelo,
      pais: e.value,
    });
  };
  const viewAlert = (show) => {
    setShowAlert(show);
  };
  const emptyModelo = () => {
    setDefaultValue({ label: '', value: '' });
    setModelo({
      banco: '',
      codigo: '',
      email: '',
      pais: '',
      tipo: '',
    });
    setIdDoc('');
    setEditar(false);
  };
  const añadirCuenta = () => {
    emptyModelo();
    setModalCuenta(true);

    dispatch(
      addLog({
        accion: `${'Se Ha Registrado una Cuenta , Banco: '} ${
          modelo.banco
        } ${'De ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const editarCuenta = (item) => {
    setDefaultValue({ label: item.pais, value: item.pais });
    setModelo({
      banco: item.banco,
      codigo: item.codigo,
      email: item.email,
      pais: item.pais,
      tipo: item.tipo,
    });
    setEditar(true);
    setIdDoc(item.id);
    setModalCuenta(true);

    dispatch(
      addLog({
        accion: `${'Se Ha Editado una Cuenta , Banco: '} ${
          modelo.banco
        } ${'De ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const guardarCuenta = async () => {
    if (
      modelo.pais === '' ||
      modelo.codigo === '' ||
      modelo.banco === '' ||
      modelo.tipo === ''
    ) {
      custom('Debe llenar todos los campos obligatorios *', 'error');
    } else {
      if (editar) {
        try {
          await app
            .firestore()
            .collection('cuentasInternacionales')
            .doc(idDoc)
            .update(modelo);
          custom('Datos Actualizados correctamente', 'success');
          setModalCuenta(false);
          emptyModelo();
        } catch (error) {
          custom('Ocurrio un error intente nuevamente', 'error');
        }
      } else {
        try {
          await app
            .firestore()
            .collection('cuentasInternacionales')
            .add(modelo);
          custom('Cuenta Registrada correctamente', 'success');
          setModalCuenta(false);
          emptyModelo();
        } catch (error) {
          custom('Ocurrio un error intente nuevamente', 'error');
        }
      }
    }

    dispatch(
      addLog({
        accion: `${'Se Ha Guardado una Cuenta , Banco: '} ${
          modelo.banco
        } ${'De ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const changeCuenta = (e) => {
    const { name, value } = e.target;
    setModelo({ ...modelo, [name]: value });
  };
  const eliminarCuenta = async (cuenta) => {
    try {
      await app
        .firestore()
        .collection('cuentasInternacionales')
        .doc(cuenta.id)
        .delete();
      custom('Cuenta Eliminada correctamente', 'success');
    } catch (error) {
      custom('Ocurrio un error intente nuevamente', 'error');
    }
    dispatch(
      addLog({
        accion: `${'Se Ha Eliminado una Cuenta , Banco: '} ${
          cuenta.banco
        } ${'De ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };

  //========== Redes ==========//
  const handleChangeRedes = (e) => {
    const { name, value } = e.target;
    setRedSocial({ ...redSocial, [name]: value });
  };
  const emptyModeloRedes = () => {
    setRedSocial({
      url: '',
      tipo: '',
      id: '',
    });
  };
  const añadirRedSocial = () => {
    emptyModeloRedes();
    setModalRed(true);
  };
  const editarRedSocial = (item) => {
    setRedSocial(item);
    setModalRed(true);
    dispatch(
      addLog({
        accion: `${'Se Ha Editado una Red Social, De tipo: '} ${
          item.tipo
        } ${'de ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const buscarMetodo = (redS) => {
    const filtrado = redesSociales.filter(
      (red) => red.url.trim() === redS.trim()
    );
    if (filtrado.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const guardarRedSocial = async () => {
    if (redSocial.url === '') {
      custom('Ingrese un valor para url', 'error');
    } else {
      if (!isURL(redSocial.url)) {
        custom('Ingrese una url valida', 'error');
      } else if (buscarMetodo(redSocial.url)) {
        custom('Red Social Registrada', 'error');
      } else {
        let data = { ...redSocial, tipo: buildType(redSocial.url) };
        if (redSocial.id === '') {
          try {
            await app.firestore().collection('redesSocialesEmpresa').add(data);
            custom('Red Social Registrada correctamente', 'success');
            setModalRed(false);
            emptyModeloRedes();
          } catch (error) {
            custom('Ocurrio un error intente nuevamente', 'error');
          }
        } else {
          try {
            await app
              .firestore()
              .collection('redesSocialesEmpresa')
              .doc(redSocial.id)
              .update(data);
            custom('Red social actualizada correctamente', 'success');
            setModalRed(false);
            emptyModeloRedes();
          } catch (error) {
            custom('Ocurrio un error intente nuevamente', 'error');
          }
        }
      }
    }
    dispatch(
      addLog({
        accion: `${'Se Ha Guardado una Red Social, De tipo: '} ${
          redesSociales[0].tipo
        } ${'de ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const eliminarRedSocial = async (item) => {
    try {
      await app
        .firestore()
        .collection('redesSocialesEmpresa')
        .doc(item.id)
        .delete();
      custom('Red Social Eliminada correctamente', 'success');
    } catch (error) {
      custom('Ocurrio un error intente nuevamente', 'error');
    }
    dispatch(
      addLog({
        accion: `${'Se Ha Eliminado Red Social, De tipo: '} ${
          item.tipo
        } ${'De ChamoPresta.Com '}`,
        fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        usuario: myUser.email,
      })
    );
  };
  const buildType = (str) => {
    if (str.includes('facebook') || str.includes('fb')) {
      return 'facebook';
    } else if (str.includes('instagram')) {
      return 'instagram';
    } else {
      return '';
    }
  };

  return (
    <>
      <div className='grid grid-cols-12 gap-6 mt-5'>
        <div className='intro-y col-span-12 lg:col-span-12'>
          <div className='intro-y box'>
            <div className='flex flex-col sm:flex-row items-center p-5 border-b border-gray-200'>
              <h2 className='font-medium text-base mr-auto'>
                Parametros de Configuración
              </h2>
            </div>
            <div className='p-5' id='input'>
              <div className='preview'>
                <form onSubmit={saveInteres}>
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Monto Inicial Maximo a Prestar
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        name='montoInicialMaximoPrestamo'
                        value={config.montoInicialMaximoPrestamo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Monto Maximo a Prestar
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        name='montoMaximoPrestamo'
                        value={config.montoMaximoPrestamo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Porcentaje de Incremento %
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        name='porcentajeIncremento'
                        value={config.porcentajeIncremento}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Tasa de Interes Compuesto %
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        step='.01'
                        name='tasaInteresCompuesto'
                        value={config.tasaInteresCompuesto}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Tasa de Dolar
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        name='tasaDolar'
                        step='.01'
                        value={config.tasaDolar}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Días Minimo Para Realizar un Pago o Abono
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        name='diasMinimoPago'
                        value={config.diasMinimoPago}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className='mt-2 bg-theme-5 box p-1'>
                        Fondo Disponibles
                      </label>
                      <input
                        type='number'
                        className='input input--sm w-full border mt-2 rounded-full'
                        required
                        min='0'
                        name='fondo'
                        value={config.fondo}
                        onChange={handleChange}
                      />
                    </div>

                    {myUser.rol === 'SuperAdmin' && (
                      <>
                        <div className='col-span-12'>
                          <label className='mt-2 bg-theme-5 box p-1'>
                            Terminos Y Condiciones
                          </label>
                          <textarea
                            rows='3'
                            cols='40'
                            wrap='hard'
                            type='text'
                            className='input input--sm w-full border mt-2 rounded-lg'
                            required
                            name='terminos'
                            value={config.terminos}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className='col-span-12'>
                          <label className='mt-2 bg-theme-5 box p-1'>
                            Politicas de Privacidad
                          </label>
                          <textarea
                            rows='3'
                            cols='40'
                            wrap='hard'
                            type='text'
                            className='input input--sm w-full border mt-2 rounded-lg'
                            required
                            name='politicas'
                            value={config.politicas}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className='col-span-12'>
                          <label className='mt-2 bg-theme-5 box p-1'>
                            Requisitos
                          </label>
                          <textarea
                            rows='3'
                            cols='40'
                            wrap='hard'
                            type='text'
                            className='input input--sm w-full border mt-2 rounded-lg'
                            required
                            name='requisitos'
                            value={config.requisitos}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className='col-span-12'>
                          <label className='mt-2 bg-theme-5 box p-1'>
                            Informción General
                          </label>
                          <textarea
                            rows='3'
                            cols='40'
                            wrap='hard'
                            type='text'
                            className='input input--sm w-full border mt-2 rounded-lg'
                            required
                            name='informacion'
                            value={config.informacion}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </>
                    )}
                    {showAlert && (
                      <div className='col-span-12 mt-4'>
                        <div className='rounded-md flex items-center justify-center px-5 py-4 mb-2 bg-theme-9 text-white'>
                          Datos Guardados Exitosamente
                        </div>
                      </div>
                    )}
                    <div className='col-span-12'>
                      <button
                        type='submit'
                        className='flex button bg-theme-1 text-white mt-4'
                      >
                        <Icon.Save className='w-4 h-4 mr-2' />
                        Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12 gap-6 mt-5'>
        <div className='col-span-12 lg:col-span-12'>
          <div className='box overflow-auto lg:overflow-visible'>
            <div className='flex flex-col sm:flex-row items-center p-5 border-b border-gray-200'>
              <h2 className='font-medium text-base mr-auto'>
                Cuentas Bancarias Para Recibir Pagos
              </h2>
              <div className='w-full sm:w-auto flex mt-4 sm:mt-0'>
                <button
                  className='flex button text-white bg-theme-1 shadow-md mr-2'
                  onClick={añadirCuenta}
                >
                  <Icon.Edit3 className='w-4 h-4 mr-2' />
                  Registrar Cuenta
                </button>
              </div>
            </div>
            <div className='p-5' id='input'>
              <div className='preview'>
                {cuentasBancarias.length === 0 && (
                  <div className='flex justify-center'>
                    No se han Registrados Cuentas Bancarias
                  </div>
                )}

                {cuentasBancarias.length > 0 && (
                  <table className='table table-report sm:mt-2'>
                    <thead>
                      <tr className='box bg-theme-26 p-1'>
                        <th className='whitespace-no-wrap'>Pais</th>
                        <th className='whitespace-no-wrap'>Código Cuenta</th>
                        <th className='whitespace-no-wrap'>Banco</th>
                        <th className='whitespace-no-wrap'>Email</th>
                        <th className='whitespace-no-wrap'>Tipo</th>
                        <th className='whitespace-no-wrap text-center'>
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cuentasBancarias.map((cuenta) => (
                        <tr key={cuenta.codigo}>
                          <td>{cuenta.pais}</td>
                          <td>{cuenta.codigo}</td>
                          <td>{cuenta.banco}</td>
                          <td>{cuenta.email}</td>
                          <td>{cuenta.tipo}</td>
                          <td>
                            <div className='flex justify-center'>
                              <a
                                className='flex items-center mr-3 text-theme-1'
                                href='#!'
                                onClick={(e) => {
                                  e.preventDefault();
                                  editarCuenta(cuenta);
                                }}
                              >
                                <Icon.Edit className='w-5 h-5' />
                              </a>
                              <a
                                className='flex items-center text-theme-6'
                                href='#!'
                                onClick={(e) => {
                                  e.preventDefault();
                                  eliminarCuenta(cuenta);
                                }}
                              >
                                <Icon.Trash2 className='w-5 h-5' />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-12 gap-6 mt-5'>
        <div className='col-span-12 lg:col-span-12'>
          <div className='box overflow-auto lg:overflow-visible'>
            <div className='flex flex-col sm:flex-row items-center p-5 border-b border-gray-200'>
              <h2 className='font-medium text-base mr-auto'>
                Redes Sociales de ChamoPresta
              </h2>
              <div className='w-full sm:w-auto flex mt-4 sm:mt-0'>
                <button
                  className='flex button text-white bg-theme-1 shadow-md mr-2'
                  onClick={añadirRedSocial}
                >
                  <Icon.Edit3 className='w-4 h-4 mr-2' />
                  Registrar Red Social
                </button>
              </div>
            </div>
            <div className='p-5' id='input'>
              <div className='preview'>
                {redesSociales.length === 0 && (
                  <div className='flex justify-center'>
                    No se han Registrado Redes Sociales
                  </div>
                )}

                {redesSociales.length > 0 && (
                  <table className='table table-report sm:mt-2'>
                    <thead>
                      <tr className='box bg-theme-26 p-1'>
                        <th className='whitespace-no-wrap text-center'>Url</th>
                        <th className='whitespace-no-wrap'>Tipo</th>
                        <th className='whitespace-no-wrap text-center'>
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {redesSociales.map((red, i) => (
                        <tr key={i}>
                          <td>{red.url}</td>
                          <td>{red.tipo}</td>
                          <td>
                            <div className='flex justify-center'>
                              <a
                                className='flex items-center mr-3 text-theme-1'
                                href='#!'
                                onClick={(e) => {
                                  e.preventDefault();
                                  editarRedSocial(red);
                                }}
                              >
                                <Icon.Edit className='w-5 h-5' />
                              </a>
                              <a
                                className='flex items-center text-theme-6'
                                href='#!'
                                onClick={(e) => {
                                  e.preventDefault();
                                  eliminarRedSocial(red);
                                }}
                              >
                                <Icon.Trash2 className='w-5 h-5' />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AreasDeTrabajo />
      <AgregarPropiedades />

      {modalCuenta && (
        <Modal
          title='Agregar Cuenta Bancaria'
          close={(e) => setModalCuenta(false)}
          save={guardarCuenta}
          cancel={(e) => setModalCuenta(false)}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Pais: *</label>
            <Select
              defaultValue={defaultValue}
              options={options}
              onChange={changePais}
            />
          </div>

          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Código de Cuenta: *
            </label>
            <input
              type='text'
              name='codigo'
              className='w-full rounded-full border input p-1 mt-2'
              maxLength='28'
              placeholder='0000000000000000000'
              value={modelo.codigo}
              pattern='^-?[0-9]\d*\.?\d*$'
              onChange={changeCuenta}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>

          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Nombre del Banco: *
            </label>
            <input
              type='text'
              name='banco'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='indique el nombre del Banco'
              value={modelo.banco}
              onChange={changeCuenta}
            />
          </div>

          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Correo Electrónico : *
            </label>
            <input
              type='email'
              name='email'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='indique email asociado a la cuenta'
              value={modelo.email}
              onChange={changeCuenta}
            />
          </div>

          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Tipo de Cuenta : *
            </label>
            <select
              className='w-full rounded-full border input p-1 mt-2'
              name='tipo'
              value={modelo.tipo}
              onChange={changeCuenta}
            >
              <option value=''>Seleccione</option>
              <option value='Ahorros'>Ahorros</option>
              <option value='Cheque'>Cheque</option>
              <option value='Corriente'>Corriente</option>
              <option value='Electrónica'>Electrónica</option>
            </select>
          </div>
        </Modal>
      )}

      {modalRed && (
        <Modal
          title='Agregar Red Social'
          close={(e) => setModalRed(false)}
          save={guardarRedSocial}
          cancel={(e) => setModalRed(false)}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>Url: *</label>
            <input
              type='url'
              name='url'
              className='w-full rounded-full border input p-1 mt-2'
              placeholder='http://page.com'
              value={redSocial.url}
              onChange={handleChangeRedes}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Configuration;
