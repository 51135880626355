import $ from "jquery";
import moment from "moment";
import feather from "feather-icons";
// import daterangepicker from "daterangepicker";
import tooltipster from "tooltipster";
// import slick from 'slick-carousel'

export const useIcons = () => {
  return feather.replace({ "stroke-width": 1.5 });
};

export function useDaterangerpicker() {
  $(".datepicker").each(function () {
    let options = {
      singleDatePicker: true,
      showDropdowns: true,
      minYear: 1901,
      maxYear: parseInt(moment().format("YYYY"), 10),
    };
    if ($(this).data("daterange")) {
      options.singleDatePicker = false;
    }
    if ($(this).data("timepicker")) {
      options.timePicker = true;
      options.locale = {
        format: "M/DD hh:mm A",
      };
    }
    $(this).daterangepicker(options);
  });
}

export function useTooltip() {
  (function ($) {
    $(".tooltip").each(function () {
      let options = {
        delay: 0,
        theme: "tooltipster-borderless",
        multiple: true,
        maxWidth: 400,
      };
      if ($(this).data("event") === "on-click") {
        options.trigger = "click";
      }
      if ($(this).data("theme") === "light") {
        options.theme = "tooltipster-shadow";
      }
      if ($(this).data("side") !== undefined) {
        options.side = $(this).data("side");
      }
      $(this).tooltipster(options);
    });
  })($);
}

export function useTabs() {
  (function ($) {
    $("body").on("click", 'a[data-toggle="tab"]', function (key, el) {
      $(this)
        .closest(".nav-tabs")
        .find('a[data-toggle="tab"]')
        .removeClass("active");
      $(this).addClass("active");
      let elementId = $(this).attr("data-target");
      $(elementId)
        .closest(".tab-content")
        .find(".tab-content__pane")
        .removeClass("active");
      $(elementId).addClass("active");
    });
  })($);
}

export function useChat() {
  (function ($) {
    "use strict";
    $(".chat__chat-list")
      .children()
      .each(function () {
        $(this).on("click", function () {
          $(".chat__box")
            .children("div:nth-child(2)")
            .fadeOut({
              done: function () {
                $(".chat__box")
                  .children("div:nth-child(1)")
                  .fadeIn({
                    done: function () {
                      $(this).removeClass("hidden").removeAttr("style");
                    },
                  });
              },
            });
        });
      });
  })($);
}

export function useSlick() {
  (function ($) {
    // Slick Carousel
    if ($(".slick-carousel").length) {
      $(".slick-carousel").each(function () {
        $(this).slick({
          arrows: false,
          infinite: true,
          //autoplay: true,
          //autoplaySpeed: 5000
        });
      });
    }

    if ($(".slick-navigator").length) {
      $(".slick-navigator").each(function () {
        $(this).on("click", function () {
          if ($(this).data("target") === "prev") {
            $("#" + $(this).data("carousel")).slick("slickPrev");
          } else {
            $("#" + $(this).data("carousel")).slick("slickNext");
          }
        });
      });
    }

    // Slider widget page
    if ($(".single-item").length) {
      $(".single-item").slick();
    }

    if ($(".multiple-items").length) {
      $(".multiple-items").slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
      });
    }

    if ($(".responsive-mode").length) {
      $(".responsive-mode").slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }

    if ($(".center-mode").length) {
      $(".center-mode").slick({
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "40px",
              slidesToShow: 1,
            },
          },
        ],
      });
    }

    if ($(".fade-mode").length) {
      $(".fade-mode").slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: "linear",
      });
    }
  })($);
}
