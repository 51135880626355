import React from "react";

export default function Loading() {
  return (
    <div style={{height: '100%', background: 'rgba(70, 70, 73, 0.3)', position: 'fixed', width: '100%', top: '0', left: '0'}} className="flex items-center hs-lds-heart-container">
        <div className="ml-auto mr-auto font-medium" style={{color: '#ffffff'}}> 
            <h1>LOADING...</h1>
            <div className="hs-lds-heart"><div></div></div>
        </div>
    </div>

  );
}