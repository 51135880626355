import {
  GET_USUARIOSCONPRESTAMO,
  GET_USERS,
  GET_LISTPRESTAMOS,
  GET_PRESTAMO,
  GET_CONTACTOSOCIALES,
  GET_LINKSOCIALES,
  GET_REQUERIMIENTOSUSUARIOS,
  GET_REQUERIMIENTOPRESTAMO,
  GET_REDESSOCIALES,
  MODAL_CREARCONTACTOSOCIALES,
  MODAL_EDITARCONTACTOSOCIALES,
  MODAL_ENVIOCORREOMENSAJERECHAZO,
  MODAL_ENVIOCORREORECHAZOSOLICITUD,
  GETCHAT,
  GET_LISTPRESTAMOSAPROBADOS,
  GET_FILTERPRESTAMOS,
  GET_ABONOSREALIZADOS,
  GET_LISTPRESTAMOSGESTOR,
  GET_PRESTAMOSGESTORAPROBADO,
  GET_PRESTAMOSGESTORACOBRAR,
  GET_PRESTAMOSGESTORPROCESO,
  GET_PRESTAMOSGESTORPENDIENTE,
  GET_PRESTAMOSGESTORRECHAZADO,
  ENDLOADING,
} from '../types';

import { app } from '../../config/firebaseConfig';

import { custom } from '../../helpers/alertas';

export const getUsuarioConPrestamo = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .get()
    .then(async (data) => {
      let allUsers = [];
      let usuarioConPrestamo = [];
      let objUser = {};

      data.forEach((doc) => {
        allUsers.push(doc.data());
      });

      for (let i = 0; i < allUsers.length; i++) {
        let user = await app
          .firestore()
          .collection('usuarios')
          .doc(`${allUsers[i].idUsuario}`)
          .get();
        objUser = user.data();
        objUser.id = user.id;
        usuarioConPrestamo.push(objUser);
      }

      dispatch({
        type: GET_USUARIOSCONPRESTAMO,
        payload: usuarioConPrestamo,
      });
    });
};

export const getUsers = () => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .get()
    .then((data) => {
      let allUsers = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        allUsers.push(obj);
      });
      dispatch({
        type: GET_USERS,
        payload: allUsers,
      });
    });
};

//========== Listado de Prestamos ==========//
export const getListPrestamos = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    // .orderBy('fechaSolicitud', 'desc')
    .orderBy('fechaActualizacion', 'desc')
    .get()
    .then((data) => {
      let listPrestamos = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listPrestamos.push(obj);
      });
      dispatch({
        type: GET_LISTPRESTAMOS,
        payload: listPrestamos,
      });
    });
};

export const getListPrestamosGestor = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', `${app.auth().currentUser.uid}`)
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let listPrestamosGestor = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listPrestamosGestor.push(obj);
      });
      dispatch({
        type: GET_LISTPRESTAMOSGESTOR,
        payload: listPrestamosGestor,
      });
    });
};

export const getPrestamosGestorAprobado = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', `${app.auth().currentUser.uid}`)
    .where('aprobado', '==', true)
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let prestamosGestorAprobado = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        prestamosGestorAprobado.push(obj);
      });
      dispatch({
        type: GET_PRESTAMOSGESTORAPROBADO,
        payload: prestamosGestorAprobado,
      });
    });
};

export const getPrestamosGestorAcobrar = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', `${app.auth().currentUser.uid}`)
    .where('estado', '==', 'prestado')
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let prestamosGestorAcobrar = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        prestamosGestorAcobrar.push(obj);
      });
      dispatch({
        type: GET_PRESTAMOSGESTORACOBRAR,
        payload: prestamosGestorAcobrar,
      });
    });
};

export const getPrestamosGestorProceso = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', `${app.auth().currentUser.uid}`)
    .where('aprobado', '==', false)
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let prestamosGestorProceso = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        prestamosGestorProceso.push(obj);
      });
      dispatch({
        type: GET_PRESTAMOSGESTORPROCESO,
        payload: prestamosGestorProceso,
      });
    });
};

export const getPrestamosGestorPendiente = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', `${app.auth().currentUser.uid}`)
    .where('estado', '==', 'pendiente')
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let prestamosGestorPendiente = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        prestamosGestorPendiente.push(obj);
      });
      dispatch({
        type: GET_PRESTAMOSGESTORPENDIENTE,
        payload: prestamosGestorPendiente,
      });
    });
};

export const getPrestamosGestorRechazado = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', `${app.auth().currentUser.uid}`)
    .where('estado', '==', 'rechazado')
    // .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let prestamosGestorRechazado = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        prestamosGestorRechazado.push(obj);
      });
      dispatch({
        type: GET_PRESTAMOSGESTORRECHAZADO,
        payload: prestamosGestorRechazado,
      });
    });
};

export const getPrestamo = (id) => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .doc(id)
    .get()
    .then((doc) => {
      let obj = doc.data();
      obj.id = doc.id;
      dispatch({ type: GET_PRESTAMO, payload: obj });
      dispatch(getLinkSociales(doc.data().idUsuario));
      dispatch(getRequerimientosUsuarios(doc.data().idUsuario));
    });
};

export const getListPrestamosPorCobrar = () => async (dispatch) => {
  await app
    .firestore()
    .collection('prestamos')
    .where('estado', '==', 'prestado')
    .where('pagado', '==', false)
    .orderBy('fechaSolicitud', 'desc')
    .get()
    .then((data) => {
      let listPrestamosAprobados = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        listPrestamosAprobados.push(obj);
      });
      dispatch({
        type: GET_LISTPRESTAMOSAPROBADOS,
        payload: listPrestamosAprobados,
      });
      dispatch({ type: GET_FILTERPRESTAMOS, payload: listPrestamosAprobados });
    });
};

//==========Editar Contactos Sociales==========//
export const modalEditarContacto = (value) => (dispatch) => {
  dispatch({ type: MODAL_EDITARCONTACTOSOCIALES, payload: value });
};

export const getContactoSociales = (idUsuario) => async (dispatch) => {
  await app
    .firestore()
    .collection('contactosSociales')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then(async (data) => {
      let contactosSociales = [];
      let contacto = {};

      data.forEach((doc) => {
        contacto = doc.data();
        contacto.id = doc.id;
        contactosSociales.push(contacto);
      });

      dispatch({
        type: GET_CONTACTOSOCIALES,
        payload: contactosSociales,
      });
    });
};

export const updateContactoSociales = (contacto) => (dispatch) => {
  app
    .firestore()
    .collection('contactosSociales')
    .doc(`${contacto.id}`)
    .update(contacto)
    .then((resp) => {
      dispatch(getContactoSociales(contacto.idUsuario));
      dispatch({ type: MODAL_EDITARCONTACTOSOCIALES, payload: false });
    });
};

export const deleteContactoSociales = (contacto) => (dispatch) => {
  app
    .firestore()
    .collection('contactosSociales')
    .doc(`${contacto.id}`)
    .delete()
    .then((resp) => {
      dispatch(getContactoSociales(contacto.idUsuario));
    });
};

//==========Links Sociales==========//
export const getLinkSociales = (idUsuario) => async (dispatch) => {
  await app
    .firestore()
    .collection('sociales')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then(async (data) => {
      let linkSociales = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        linkSociales.push(obj);
      });

      dispatch({
        type: GET_LINKSOCIALES,
        payload: linkSociales,
      });
    });
};
export const deleteSociales = (contacto) => (dispatch) => {
  app
    .firestore()
    .collection('sociales')
    .doc(`${contacto.id}`)
    .delete()
    .then((resp) => {
      dispatch(getLinkSociales(contacto.idUsuario));
    });
};

//==========Requerimientos==========//
export const getRequerimientosUsuarios = (idUsuario) => async (dispatch) => {
  await app
    .firestore()
    .collection('requerimiento')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then(async (data) => {
      let requerimientosUsuarios = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        requerimientosUsuarios.push(obj);
      });

      dispatch({
        type: GET_REQUERIMIENTOSUSUARIOS,
        payload: requerimientosUsuarios,
      });
      dispatch({ type: ENDLOADING });
    });
};

export const getRequerimientoPrestamo = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('requerimientoPrestamo')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .get()
    .then(async (data) => {
      let requerimientoPrestamo = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        requerimientoPrestamo.push(obj);
      });

      dispatch({
        type: GET_REQUERIMIENTOPRESTAMO,
        payload: requerimientoPrestamo,
      });
    });
};

//========== ModalCrear Contactos Sociales ==========//
export const modalCrearContacto = (value) => (dispatch) => {
  dispatch({ type: MODAL_CREARCONTACTOSOCIALES, payload: value });
};

export const saveCrearContactoSociales = (contacto, userid) => (dispatch) => {
  app
    .firestore()
    .collection('contactosSociales')
    .add(contacto)
    .then((resp) => {
      dispatch(getContactoSociales(userid));
      dispatch({ type: MODAL_CREARCONTACTOSOCIALES, payload: false });
    });
};

//========== ModalEnvio Correo Mensaje Rechazo ==========//
export const modalEnvioCorreoMensaje = (value) => (dispatch) => {
  dispatch({ type: MODAL_ENVIOCORREOMENSAJERECHAZO, payload: value });
};

//========== ModalEnvio Correo Rechazo Solicitud ==========//
export const modalCorreoRechazoSolicitud = (value) => (dispatch) => {
  dispatch({ type: MODAL_ENVIOCORREORECHAZOSOLICITUD, payload: value });
};

//==========Redes Sociales==========//
export const getRedesSociales = () => (dispatch) => {
  app
    .firestore()
    .collection('sociales')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    .get()
    .then((data) => {
      let array = [];
      let sociales = {};
      data.forEach((doc) => {
        sociales = doc.data();
        sociales.id = doc.id;
        array.push(sociales);
      });
      dispatch({ type: GET_REDESSOCIALES, payload: array });
    });
};

export const getChat = (idPrestamo) => (dispatch) => {
  app
    .firestore()
    .collection('chat')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .get()
    .then((data) => {
      let array = [];
      let objeto = {};
      data.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETCHAT, payload: array[0] });
    });
};

export const sendMsg = (chat, mensajes) => (dispatch) => {
  let newChat = {};
  newChat = {
    id: chat.id,
    fecha: chat.fecha,
    fechaActualizacion: chat.fechaActualizacion,
    idChat: chat.idChat,
    idPrestamo: chat.idPrestamo,
    mensajes,
  };
  dispatch({ type: GETCHAT, payload: newChat });
  app
    .firestore()
    .collection('chat')
    .doc(`${chat.id}`)
    .update({
      mensajes,
      fechaActualizacion: chat.fechaActualizacion,
      idChat: chat.idChat,
    })
    .then((resp) => {
      //dispatch(getChat(chat.idPrestamo))
    });
};

export const addChat = (chat) => (dispatch) => {
  let newChat = {};
  dispatch({ type: GETCHAT, payload: newChat });
  app
    .firestore()
    .collection('chat')
    .add(chat)
    .then((resp) => {
      dispatch(getChat(resp.id));
    });
};

export const aprobarPrestamo = (idPrestamo, data) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .doc(`${idPrestamo}`)
    .update(data)
    .then((resp) => {
      dispatch(getPrestamo(idPrestamo));
    });
};

export const rechazarPrestamo = (idPrestamo, data) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .doc(`${idPrestamo}`)
    .update(data)
    .then((resp) => {
      custom('El prestamo ha sido rechazado', 'success');
      dispatch(getPrestamo(idPrestamo));
    });
};

export const getAbonosRealizados = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('abonos')
    .where('idPrestamo', '==', idPrestamo)
    .orderBy('fecha', 'asc')
    .get()
    .then(async (data) => {
      let abonosRealizados = [];
      let abono = {};

      data.forEach((doc) => {
        abono = doc.data();
        abono.id = doc.id;
        abonosRealizados.push(abono);
      });

      dispatch({
        type: GET_ABONOSREALIZADOS,
        payload: abonosRealizados,
      });
    });
};
