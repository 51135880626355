import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import logo from '../../statics/images/logo-chamopresta-PNG.png';
import * as Icon from 'react-feather';
import ModalExit from '../ModalExit';

const SideMenu = () => {
  const myUser = useSelector((state) => state.sesion.myUser);
  const [modalExit, setModalexit] = useState(false);

  const onClose = () => {
    setModalexit(false);
  };

  const handleLogout = () => {
    setModalexit(true);
  };
  return (
    <nav className='side-nav'>
      <div className='flex items-center'>
        <a href='#!' className='intro-x flex items-center pt-4'>
          <img alt='Midone Tailwind HTML Admin Template' src={logo} />
        </a>
      </div>
      <div className='side-nav__devider my-6'></div>
      <ul>
        {myUser.rol === 'Usuario' && (
          <>
            <li>
              <NavLink
                to='/inicio'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='side-menu__title'>Inicio</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/historial'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.ShoppingCart />
                </div>
                <div className='side-menu__title'>Historial</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.User />
                </div>
                <div className='side-menu__title'>Perfil</div>
              </NavLink>
            </li>
          </>
        )}

        {myUser.rol === 'Gestor' && (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Home />
                </div>
                <div className='side-menu__title'>Dashboard</div>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/prestamos"
                className="side-menu w-full relative Webs"
                activeClassName="side-menu--active"
              >
                <div className="side-menu__icon">
                  <Icon.DollarSign />
                </div>
                <div className="side-menu__title">Prestamos</div>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to='/calculadora'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Cpu />
                </div>
                <div className='side-menu__title'>Calculadora</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/cobranzas'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.PhoneCall />
                </div>
                <div className='side-menu__title'>Cobranzas</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.User />
                </div>
                <div className='side-menu__title'>Perfil</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamorapido'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Trello />
                </div>
                <div className='side-menu__title'>Préstamos Rapidos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/validarprestamos'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='side-menu__title'>Validar Préstamos</div>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/validarusuarios"
                className="side-menu w-full relative Webs"
                activeClassName="side-menu--active"
              >
                <div className="side-menu__icon">
                  <Icon.Users />
                </div>
                <div className="side-menu__title">Validar Usuarios</div>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to='/superchat'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.MessageCircle />
                </div>
                <div className='side-menu__title'>Super Chat</div>
              </NavLink>
            </li>
          </>
        )}

        {myUser.rol === 'Admin' && (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Home />
                </div>
                <div className='side-menu__title'>Dashboard</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamos'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='side-menu__title'>Préstamos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/usuarios'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Users />
                </div>
                <div className='side-menu__title'>Usuarios</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/mensajes'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.MessageSquare />
                </div>
                <div className='side-menu__title'>Mensajes Predeterminados</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/configuracion'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Settings />
                </div>
                <div className='side-menu__title'>Configuración</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.User />
                </div>
                <div className='side-menu__title'>Perfil</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/superchat'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.MessageCircle />
                </div>
                <div className='side-menu__title'>Super Chat</div>
              </NavLink>
            </li>
          </>
        )}

        {myUser.rol === 'SuperAdmin' && (
          <>
            <li>
              <NavLink
                to='/dashboard'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Home />
                </div>
                <div className='side-menu__title'>Dashboard</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/calculadora'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Cpu />
                </div>
                <div className='side-menu__title'>Calculadora</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/configuracion'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Settings />
                </div>
                <div className='side-menu__title'>Configuración</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/contabilidad'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.FolderPlus />
                </div>
                <div className='side-menu__title'>Contabilidad</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/cuentasporcobrar'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.CreditCard />
                </div>
                <div className='side-menu__title'>Cuentas x Cobrar</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/sinfondos'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.List />
                </div>
                <div className='side-menu__title'>Listado sin Fondos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/notificaciones'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.AtSign />
                </div>
                <div className='side-menu__title'>Notificaciones</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/perfil'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.User />
                </div>
                <div className='side-menu__title'>Perfil</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamos'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.DollarSign />
                </div>
                <div className='side-menu__title'>Préstamos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/prestamorapido'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Trello />
                </div>
                <div className='side-menu__title'>Préstamos Rapidos</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/registros'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Activity />
                </div>
                <div className='side-menu__title'>Registro Actividad</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/superchat'
                className='side-menu w-full relative'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.MessageCircle />
                </div>
                <div className='side-menu__title'>Super Chat</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/usuarios'
                className='side-menu w-full relative Webs'
                activeClassName='side-menu--active'
              >
                <div className='side-menu__icon'>
                  <Icon.Users />
                </div>
                <div className='side-menu__title'>Usuarios</div>
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            to='#'
            className='side-menu w-full relative'
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            <div className='side-menu__icon'>
              <Icon.LogOut />
            </div>
            <div className='side-menu__title'>Cerrar Sesión</div>
          </NavLink>
        </li>
      </ul>
      <ModalExit open={modalExit} onClose={onClose} />
    </nav>
  );
};

export default SideMenu;
