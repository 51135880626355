import { app } from '../../config/firebaseConfig';
import moment from 'moment';
import { custom } from '../../helpers/alertas';
import { axiosInstance } from '../../config/api';
import url from '../../config/url';
import { calCulardiasTranscurridos } from '../../helpers/generales';

import {
  LOADING,
  ENDLOADING,
  GET_USERS,
  GETPAGOSMOVILES,
  GETCUENTASBANCARIAS,
  PRESTAMOACTUAL,
  MODALCUENTA,
  MODALCUENTAECU,
  MODALCUENTACOL,
  MODALCUENTAPAN,
  MODALPAGOMOVIL,
  SELECTEDCUENTA,
  WIZARD,
  PRESTAMOSUSUARIOS,
  GETSOCIALES,
  GETSOCIALESUSER,
  REQUERIMIENTOS,
  GETCHAT,
  CLOSE_MODAL,
  GETMISPRESTAMOS,
  MODALABONO,
  GETABONOS,
  REQUERIMIENTOPRESTAMO,
  MODAL_EDITARPERFIL,
  GETCHATADMIN,
  MODAL_METODOPAGO,
} from '../types';

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
  });
};
export const loading = () => (dispatch) => {
  dispatch({ type: LOADING });
};
export const endLoading = () => (dispatch) => {
  dispatch({ type: ENDLOADING });
};

export const getCuentasBancarias = () => (dispatch) => {
  app
    .firestore()
    .collection('cuentasBancarias')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    .get()
    .then((data) => {
      let array = [];
      let cta = {};
      data.forEach((doc) => {
        cta = doc.data();
        cta.id = doc.id;
        array.push(cta);
      });
      dispatch({ type: GETCUENTASBANCARIAS, payload: array });
    });
};

export const saveCuentaBancaria = (cuenta) => (dispatch) => {
  app
    .firestore()
    .collection('cuentasBancarias')
    .add(cuenta)
    .then((resp) => {
      let data = {
        ...cuenta,
        id: resp.id,
      };
      dispatch(getCuentasBancarias());
      dispatch({ type: MODALCUENTA, payload: false });
      dispatch(changeSelected(data));
    });
};

export const updateCuentaBancaria = (cuenta) => (dispatch) => {
  app
    .firestore()
    .collection('cuentasBancarias')
    .doc(`${cuenta.id}`)
    .update(cuenta)
    .then((resp) => {
      let data = {
        ...cuenta,
        id: cuenta.id,
      };
      dispatch(getCuentasBancarias());
      dispatch({ type: MODALCUENTA, payload: false });
      dispatch(changeSelected(data));
    });
};

export const deleteCuentaBancaria = (cuenta) => (dispatch) => {
  app
    .firestore()
    .collection('cuentasBancarias')
    .doc(`${cuenta.id}`)
    .delete()
    .then((resp) => {
      dispatch(getCuentasBancarias());
    });
};

export const getPagosMoviles = () => (dispatch) => {
  app
    .firestore()
    .collection('pagosMovil')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    .get()
    .then((data) => {
      let array = [];
      let pgo = {};
      data.forEach((doc) => {
        pgo = doc.data();
        pgo.id = doc.id;
        array.push(pgo);
      });
      dispatch({ type: GETPAGOSMOVILES, payload: array });
    });
};

export const savePagoMovil = (pagoMovil) => (dispatch) => {
  app
    .firestore()
    .collection('pagosMovil')
    .add(pagoMovil)
    .then((resp) => {
      let data = {
        ...pagoMovil,
        id: resp.id,
      };
      dispatch(getPagosMoviles());
      dispatch({ type: MODALPAGOMOVIL, payload: false });
      dispatch(changeSelected(data));
    });
};

export const updatePgoMovil = (pagoMovil) => (dispatch) => {
  app
    .firestore()
    .collection('pagosMovil')
    .doc(`${pagoMovil.id}`)
    .update(pagoMovil)
    .then((resp) => {
      let data = {
        ...pagoMovil,
        id: pagoMovil.id,
      };
      dispatch(getPagosMoviles());
      dispatch({ type: MODALPAGOMOVIL, payload: false });
      dispatch(changeSelected(data));
    });
};

export const deletePgoMovil = (pagoMovil) => (dispatch) => {
  app
    .firestore()
    .collection('pagosMovil')
    .doc(`${pagoMovil.id}`)
    .delete()
    .then((resp) => {
      dispatch(getPagosMoviles());
    });
};

export const modalCuenta = (value) => (dispatch) => {
  dispatch({ type: MODALCUENTA, payload: value });
};

export const modalCuentaEcu = (value) => (dispatch) => {
  dispatch({ type: MODALCUENTAECU, payload: value });
};
export const modalCuentaCol = (value) => (dispatch) => {
  dispatch({ type: MODALCUENTACOL, payload: value });
};

export const modalCuentaPan = (value) => (dispatch) => {
  dispatch({ type: MODALCUENTAPAN, payload: value });
};

export const ModalPago = (value) => (dispatch) => {
  dispatch({ type: MODALPAGOMOVIL, payload: value });
};

export const modalMetodoPago = (value) => (dispatch) => {
  dispatch({ type: MODAL_METODOPAGO, payload: value });
};

export const changeSelected = (data) => (dispatch) => {
  dispatch({ type: SELECTEDCUENTA, payload: data });
};

export const generatePrestamo = (data) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .add(data)
    .then((docRef) => {
      dispatch({ type: SELECTEDCUENTA, payload: null });
      dispatch(getPrestamo(docRef.id));
    });
};

export const notificarPrestamoPendiente = (prestamo) => {
  app
    .firestore()
    .collection('usuarios')
    .where('rol', '==', 'Gestor')
    .get()
    .then((data) => {
      let array = [];
      data.forEach((doc) => array.push(doc.data()));
      const enviar = {
        asunto: 'Préstamo sin gestionar',
        email: array,
        motivo: `<p> NOTIFICACION !!! </p><br>

            <p><b>El préstamo con los siguientes datos tiene más de 1 día solicitado y no ha sido revisado.</b></p><br>

            <p>Id Prestamo: <b>${prestamo.id}</b></p> 
            <p>Usuario: <b>${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}</b></p>
            <p>Correo: <b>${prestamo.usuario.email}</b></p>
            <p>Fecha Inicio: <b>${prestamo.fechaInicio}</b></p> 
            <p>Monto Solicitado: <b>${prestamo.montoPrestado} $</b></p>
            <p>Monto total a pagar del préstamo: <b>${prestamo.montoCompromisoPago} $</b></p> `,
      };
      enviarNotificacion(enviar);
    });
};

export const getPrestamos = () => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    // .orderBy('fechaSolicitud', 'desc')}
    .orderBy('fechaActualizacion', 'desc')
    .get()
    .then((data) => {
      let array = [];
      let ptamo = {};
      data.forEach((doc) => {
        ptamo = doc.data();
        ptamo.id = doc.id;
        /*if(ptamo.estado === 'pendiente'){
          PrestamosPendiente.push(ptamo)
        }*/
        array.push(ptamo);
      });
      // if(PrestamosPendiente.length > 0){
      if (array.length > 0) {
        let ultimo = array[0];
        dispatch({ type: PRESTAMOACTUAL, payload: ultimo });
        dispatch({ type: WIZARD, payload: ultimo.indexWizard });
        dispatch(getChat(ultimo.id));

        // if (
        //   ultimo.estado === 'pendiente' &&
        //   calCulardiasTranscurridos(ultimo.fechaInicio) > 1
        // ) {
        //   notificarPrestamoPendiente(ultimo);
        // }
      } else {
        dispatch({ type: WIZARD, payload: 1 });
      }
      dispatch({ type: PRESTAMOSUSUARIOS, payload: array });
    });
};

export const getPrestamo = (id) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .doc(id)
    .get()
    .then((doc) => {
      let ptamo = {};
      ptamo = doc.data();
      ptamo.id = doc.id;
      if (ptamo.idGestor) {
        app
          .firestore()
          .collection('usuarios')
          .doc(ptamo.idGestor)
          .get()
          .then((document) => {
            ptamo.gestor = { ...document.data(), id: document.id };
            dispatch({ type: PRESTAMOACTUAL, payload: ptamo });
            dispatch({ type: WIZARD, payload: 2 });
          });
      } else {
        dispatch({ type: PRESTAMOACTUAL, payload: ptamo });
        dispatch({ type: WIZARD, payload: 2 });
      }
    });
};

export const getPrestamoOnly = (id) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .doc(id)
    .get()
    .then((doc) => {
      let ptamo = {};
      ptamo = doc.data();
      ptamo.id = doc.id;
      dispatch({ type: PRESTAMOACTUAL, payload: ptamo });
    });
};

export const getMisPrestamos = () => (dispatch) => {
  let arrayFinal = [];
  app
    .firestore()
    .collection('prestamos')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    .orderBy('fechaSolicitud', 'desc')
    .get()
    .then(async (data) => {
      let array = [];
      let ptamo = {};
      data.forEach((doc) => {
        ptamo = doc.data();
        ptamo.id = doc.id;
        array.push(ptamo);
      }); //hasta aqui obtengo los prestamos

      //recorro los prestamos y concateno los abonos a cada prestamo
      let arrayAbonos = [];
      let abono = {};
      for (let i = 0; i < array.length; i++) {
        // recorro cada prestamo
        //busco los abonos de cada prestamo iterable
        const result = await app
          .firestore()
          .collection('abonos')
          .where('idPrestamo', '==', `${array[i].id}`)
          .orderBy('fecha', 'asc')
          .get();
        result.forEach((abo) => {
          abono = abo.data();
          abono.id = abo.id;
          arrayAbonos.push(abono);
        });
        const arrayConcat = { ...array[i], abonos: arrayAbonos };
        arrayFinal.push(arrayConcat);
      }
      dispatch({ type: GETMISPRESTAMOS, payload: arrayFinal });
    });
};

export const getPrestamoUser = (idUsuario) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then((data) => {
      let array = [];
      let ptamo = {};
      data.forEach((doc) => {
        ptamo = doc.data();
        ptamo.id = doc.id;
        array.push(ptamo);
      });
      if (array.length > 0) {
        let ultimo = array[0];
        dispatch({ type: PRESTAMOACTUAL, payload: ultimo });
        dispatch({ type: WIZARD, payload: ultimo.indexWizard });

        // if (
        //   ultimo.estado === 'pendiente' &&
        //   calCulardiasTranscurridos(ultimo.fechaInicio) > 1
        // ) {
        //   notificarPrestamoPendiente(ultimo);
        // }
      } else {
        dispatch({ type: WIZARD, payload: 1 });
      }
      dispatch({ type: PRESTAMOACTUAL, payload: ptamo });
    });
};

export const getSociales = () => (dispatch) => {
  app
    .firestore()
    .collection('sociales')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    .get()
    .then((data) => {
      let array = [];
      let social = {};
      data.forEach((doc) => {
        social = doc.data();
        social.id = doc.id;
        array.push(social);
      });
      dispatch({ type: GETSOCIALES, payload: array });
    });
};

export const getSocialesUser = (idUsuario) => (dispatch) => {
  app
    .firestore()
    .collection('sociales')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then((data) => {
      let array = [];
      let social = {};
      data.forEach((doc) => {
        social = doc.data();
        social.id = doc.id;
        array.push(social);
      });
      dispatch({ type: GETSOCIALESUSER, payload: array });
    });
};

export const updateDatosGenereales =
  (data, prestamoActual, ultimoTab = true) =>
  (dispatch) => {
    let dataUpdate = Object.assign({}, prestamoActual.usuario, data);
    app
      .firestore()
      .collection('usuarios')
      .doc(`${app.auth().currentUser.uid}`)
      .update(data)
      .then((resp) => {
        if (ultimoTab === true) {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamoActual.id}`)
            .update({
              indexWizard: 3,
              usuario: dataUpdate,
              tab: prestamoActual.tab,
            });
          dispatch({ type: WIZARD, payload: 3 });
        } else {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamoActual.id}`)
            .update({ usuario: dataUpdate, tab: prestamoActual.tab });
        }
      });
  };

export const updateDatosPrestamoUser =
  (data, prestamoActual, ultimoTab = true) =>
  (dispatch) => {
    let dataUpdate = Object.assign({}, prestamoActual.usuario, data);
    app
      .firestore()
      .collection('usuarios')
      .doc(`${dataUpdate.idUsuario}`)
      .update(data)
      .then((resp) => {
        if (ultimoTab === true) {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamoActual.id}`)
            .update({
              indexWizard: 3,
              usuario: dataUpdate,
              tab: prestamoActual.tab,
            });
          dispatch({ type: WIZARD, payload: 3 });
        } else {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamoActual.id}`)
            .update({ usuario: dataUpdate, tab: prestamoActual.tab });
        }
      });
  };

export const updateDatosPrestamo =
  (prestamoActual, ultimoTab = true) =>
  (dispatch) => {
    let dataUpdate = Object.assign({}, prestamoActual.usuario);
    app
      .firestore()
      .collection('usuarios')
      .doc(`${prestamoActual.idUsuario}`)
      .update(dataUpdate)
      .then((resp) => {
        if (ultimoTab === true) {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamoActual.id}`)
            .update({
              indexWizard: 3,
              usuario: dataUpdate,
              tab: prestamoActual.tab,
            });
          dispatch({ type: WIZARD, payload: 3 });
        } else {
          app
            .firestore()
            .collection('prestamos')
            .doc(`${prestamoActual.id}`)
            .update({
              ...prestamoActual,
            });
        }
      });
  };

export const updateDatosUser = (data) => () => {
  app
    .firestore()
    .collection('usuarios')
    .doc(`${app.auth().currentUser.uid}`)
    .update(data)
    .then((resp) => {});
};

export const getRequrimientos = () => (dispatch) => {
  app
    .firestore()
    .collection('requerimiento')
    .where('idUsuario', '==', `${app.auth().currentUser.uid}`)
    .get()
    .then((data) => {
      let array = [];
      let req = {};
      data.forEach((doc) => {
        req = doc.data();
        req.id = doc.id;
        array.push(req);
      });
      dispatch({ type: REQUERIMIENTOS, payload: array });
      dispatch({ type: ENDLOADING });
    });
};

export const getRequerimientoPrestamo = (id) => (dispatch) => {
  app
    .firestore()
    .collection('requerimientoPrestamo')
    .where('idPrestamo', '==', `${id}`)
    .get()
    .then((data) => {
      let array = [];
      let req = {};
      data.forEach((doc) => {
        req = doc.data();
        req.id = doc.id;
        array.push(req);
      });
      dispatch({ type: REQUERIMIENTOPRESTAMO, payload: array });
      dispatch({ type: ENDLOADING });
    });
};

export const createChat = (prestamoActual) => (dispatch) => {
  app
    .firestore()
    .collection('chat')
    .add({
      idChat: '',
      idPrestamo: prestamoActual,
      mensajes: [
        {
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          rol: 'Sistema',
          texto: `Hola, es un placer, soy un gestor de préstamos en ChamoPresta.Com si nos escribes en horario de oficina (Venezuela) en menos de una hora evaluaremos la información que nos has proporcionado.<br/> 
          Por favor mantente atento a tus redes sociales (Facebook, Instagram, Telegram) y a tu correo electrónico, mientras evaluamos tu préstamo.`,
        },
      ],
      fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    })
    .then((resp) => {
      app
        .firestore()
        .collection('prestamos')
        .doc(`${prestamoActual}`)
        .update({ indexWizard: 4 });
      dispatch(getChat(prestamoActual));
      dispatch({ type: 'WIZARD', payload: 4 });
    });
};

export const getChat = (idPrestamo) => (dispatch) => {
  app
    .firestore()
    .collection('chat')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .get()
    .then((data) => {
      let array = [];
      let objeto = {};
      data.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETCHAT, payload: array[0] });
    });
};

export const getChatAdmin = (idUsuario) => (dispatch) => {
  app
    .firestore()
    .collection('chatAdmin')
    .where('idUsuario', '==', `${idUsuario}`)
    .get()
    .then((data) => {
      let array = [];
      let objeto = {};
      data.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETCHATADMIN, payload: array[0] });
    });
};

export const sendMsg = (chat, mensajes) => (dispatch) => {
  let newChat = {};
  newChat = {
    id: chat.id,
    fecha: chat.fecha,
    fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    idChat: chat.id,
    idPrestamo: chat.idPrestamo,
    mensajes,
  };
  dispatch({ type: GETCHAT, payload: newChat });
  app
    .firestore()
    .collection('chat')
    .doc(`${chat.id}`)
    .update({
      mensajes,
      idPrestamo: chat.idPrestamo,
      idChat: chat.id,
      fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    })
    .then((resp) => {
      //dispatch(getChat(chat.idPrestamo))
    });
};

export const sendMsgAdmin = (chat, mensajes) => (dispatch) => {
  let newChat = {};
  newChat = {
    fecha: chat.fecha,
    fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    idChatAdmin: chat.id,
    idUsuario: chat.idUsuario,
    mensajes,
  };
  dispatch({ type: GETCHATADMIN, payload: newChat });
  app
    .firestore()
    .collection('chatAdmin')
    .doc(`${chat.id}`)
    .update({
      mensajes,
      idUsuario: chat.idUsuario,
      idChatAdmin: chat.id,
      fechaActualizacion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    })
    .then((resp) => {
      dispatch(getChatAdmin(chat.idUsuario));
    });
};

export const culminarPrestamo = (idPrestamo) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .doc(`${idPrestamo}`)
    .update({ indexWizard: 5 })
    .then((doc) => {
      dispatch({ type: 'WIZARD', payload: 5 });
    });
};

export const agregarAbono = (pago, prestamo) => (dispatch) => {
  let usuario = `${prestamo.usuario.nombres} ${prestamo.usuario.apellidos}`;
  let para = 'info@chamopresta.com';
  const data = {
    email: para,
    motivo: `
      <p><b>Notificacion. Se Ha reportado un Pago/Abono.</b></p>  
      <p>Prestamo: <b>${prestamo.id}</b></p> 
      <p>Usuario: <b>${usuario}</b></p>
      <p>Correo: <b>${prestamo.usuario.email}</b></p>
      <p>Fecha: <b>${pago.fecha}</b></p> 
      <p>Monto Reportado: <b>${prestamo.montoPrestado}</b></p>
      <p>Monto Total del Prestamo: <b>${prestamo.montoCompromisoPago}</b></p>  
    `,
  };
  app
    .firestore()
    .collection('abonos')
    .add(pago)
    .then((resp) => {
      dispatch(misAbonos(pago.idPrestamo));
      enviarNotificacion(data);
      dispatch({ type: MODALABONO, payload: false });
    });
};

export const misAbonos = (idPrestamo) => async (dispatch) => {
  await app
    .firestore()
    .collection('abonos')
    .where('idPrestamo', '==', `${idPrestamo}`)
    .orderBy('fecha', 'asc')
    .get()
    .then((data) => {
      let array = [];
      let objeto = {};
      data.forEach((doc) => {
        objeto = doc.data();
        objeto.id = doc.id;
        array.push(objeto);
      });
      dispatch({ type: GETABONOS, payload: array });
    });
};

export const updateComentarios = (prestamo) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .doc(`${prestamo.id}`)
    .update({ comentarios: prestamo.comentarios })
    .then((resp) => {
      dispatch({ type: PRESTAMOACTUAL, payload: prestamo });
      custom('Gracias por tus comentarios', 'success');
    });
};

const enviarNotificacion = async (data) => {
  return await axiosInstance.post(`/notifications/sendmail`, data);
};

//========== Modal Editar Perfil ==========//
export const modalEditarPerfil = (value) => (dispatch) => {
  dispatch({ type: MODAL_EDITARPERFIL, payload: value });
};

export const getUsers = () => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .get()
    .then((data) => {
      let allUsers = [];
      let obj = {};

      data.forEach((doc) => {
        obj = doc.data();
        obj.id = doc.id;
        allUsers.push(obj);
      });
      dispatch({
        type: GET_USERS,
        payload: allUsers,
      });
    });
};

export const updateEditarPerfil = (usuario) => (dispatch) => {
  app
    .firestore()
    .collection('usuarios')
    .doc(`${usuario.idUsuario}`)
    .update(usuario)
    .then((resp) => {
      dispatch({ type: MODAL_EDITARPERFIL, payload: false });
      /*if(usuario.rol === 'Gestor') {
        console.log('es gestor')
        dispatch(updateGestorPestamos(usuario.idUsuario));
      }*/
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateGestorPestamos = (idGestor) => (dispatch) => {
  app
    .firestore()
    .collection('prestamos')
    .where('idGestor', '==', idGestor)
    .get()
    .then((docs) => {
      let allPrestamos = [];
      let obj = {};
      docs.forEach((doc) => {
        obj = doc.data();
        console.log(obj);
        /*obj.id = doc.id;
        allPrestamos.push(obj);*/
      });
    })
    .catch((err) => {
      console.log('error al leer', err);
    });
};
