// Menu Types
export const SET_VIEW = 'SET_VIEW';
export const LOGOUT = 'LOGOUT';

// Admin Types
export const ALL_PAGES = 'ALL_PAGES';
export const ALL_ELEMENTS = 'ALL_ELEMENTS';
export const GET_USERS = 'GET_USERS';
export const GET_HABILITADO = 'GET_HABILITADO';
export const SET_STATUS = 'SET_STATUS';
export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_EMAILEMPRESA = 'SET_EMAILEMPRESA';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_WHATSAPP = 'SET_WHATSAPP';
export const SET_FACEBOOKPRICE = 'SET_FACEBOOKPRICE';
export const SET_WEBSPRICE = 'SET_WEBSPRICE';
export const SET_WEBSTEXT = 'SET_WEBSTEXT';
export const SET_FBTEXT = 'SET_FBTEXT';
export const SET_ALLCHANGES = 'SET_ALLCHANGES';
export const ENDUPLOAD = 'ENDUPLOAD';
export const ENDUPLOAD_ELEMENTS = 'ENDUPLOAD_ELEMENTS';
export const CLOSE_MODAL_ADMIN = 'CLOSE_MODAL_ADMIN';
export const VIEW_REPORT = 'VIEW_REPORT';
export const TEST = 'TEST';
export const DELETE_USER = 'DELETE_USER';
export const SET_INTERES = 'SET_INTERES';
export const MODAL_AGREGARMENSAJES = 'MODAL_AGREGARMENSAJES';
export const MODAL_EDITARMENSAJES = 'MODAL_EDITARMENSAJES';
export const GET_MENSAJESPREDEFINIDOS = 'GET_MENSAJESPREDEFINIDOS';
export const GET_LISTGESTORES = 'GET_LISTGESTORES'; /**/

// Sesion Types
export const GET_MYUSER = 'GET_MYUSER';
export const UPDATE_CODE = 'UPDATE_CODE';
export const LOADING = 'LOADING';
export const ENDLOADING = 'ENDLOADING';
export const MODAL_TERMINOCONDICONES = 'MODAL_TERMINOCONDICONES';
export const MODAL_INFORMACION = 'MODAL_INFORMACION';
export const MODAL_INFORMACIONINICIAL = 'MODAL_INFORMACIONINICIAL';
export const MODAL_POLITICAPRIVACIDAD = 'MODAL_POLITICAPRIVACIDAD';
export const MODAL_VERIFYCORREO = 'MODAL_VERIFYCORREO';
export const MODAL_REQUISITOS = 'MODAL_REQUISITOS';

// User Types
export const GET_FACEBOOKPAGES = 'GET_FACEBOOKPAGES';
export const GET_WEBSPAGES = 'GET_WEBSPAGES';
export const MOREFACEBOOK = 'MOREFACEBOOK';
export const MOREWEBS = 'MOREWEBS';
export const MOREELEMENTS = 'MOREELEMENTS';
export const ALL_MY_PAGES = 'ALL_MY_PAGES';
export const ALL_MY_ELEMENTS = 'ALL_MY_ELEMENTS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const DONEFACEBOOK = 'DONEFACEBOOK';
export const DONEWEBS = 'DONEWEBS';
export const NODONEWEBS = 'NODONEWEBS';
export const DONEELEMENTS = 'DONEELEMENTS';
export const NODONEELEMENTS = 'NODONEELEMENTS';

//User Types nuestros
export const GETCUENTASBANCARIAS = 'GETCUENTASBANCARIAS';
export const SETCUENTABANCARIA = 'SETCUENTABANCARIA';
export const DELETECUENTABANCARIA = 'DELETECUENTABANCARIA';
export const GETPAGOSMOVILES = 'GETPAGOSMOVILES';
export const SETPAGOMOVIL = 'SETPAGOMOVIL';
export const DELETEPAGOMOVIL = 'DELETEPAGOMOVIL';
export const MODALCUENTA = 'MODALCUENTA';
export const MODALCUENTAECU = 'MODALCUENTAECU';
export const MODALCUENTACOL = 'MODALCUENTACOL';
export const MODALCUENTAPAN = 'MODALCUENTAPAN';
export const MODALPAGOMOVIL = 'MODALPAGOMOVIL';
export const MODAL_METODOPAGO = 'MODAL_METODOPAGO';
export const SELECTEDCUENTA = 'SELECTEDCUENTA';
export const WIZARD = 'WIZARD';
export const PRESTAMOSUSUARIOS = 'PRESTAMOSUSUARIOS';
export const DATOSGENERELES = 'DATOSGENERELES';
export const GETSOCIALES = 'GETSOCIALES';
export const GETSOCIALESUSER = 'GETSOCIALESUSER';
export const PRESTAMOACTUAL = 'PRESTAMOACTUAL';
export const REQUERIMIENTOS = 'REQUERIMIENTOS';
export const REQUERIMIENTOPRESTAMO = 'REQUERIMIENTOPRESTAMO';
export const GETCHAT = 'GETCHAT';
export const GETCHATADMIN = 'GETCHATADMIN';
export const GETMISPRESTAMOS = 'GETMISPRESTAMOS';
export const MODALABONO = 'MODALABONO';
export const GETABONOS = 'GETABONOS';
export const GET_CONFIGURACION = 'GET_CONFIGURACION';
export const REDESVISITADAS = 'REDESVISITADAS';
export const MODAL_EDITARPERFIL = 'MODAL_EDITARPERFIL';

//Gestor Types nuestros
export const GET_USUARIOSCONPRESTAMO = 'GET_USUARIOSCONPRESTAMO';
export const GET_LISTPRESTAMOS = 'GET_LISTPRESTAMOS';
export const GET_PRESTAMO = 'GET_PRESTAMO';
export const GET_USUARIO = 'GET_USUARIO';
export const GET_COBRANZA = 'GET_COBRANZA';
export const GET_CONTACTOSOCIALES = 'GET_CONTACTOSOCIALES';
export const GET_LINKSOCIALES = 'GET_LINKSOCIALES';
export const GET_REQUERIMIENTOSUSUARIOS = 'GET_REQUERIMIENTOSUSUARIOS';
export const GET_REQUERIMIENTOPRESTAMO = 'GET_REQUERIMIENTOPRESTAMO';
export const GET_CREARCONTACTOSOCIALES = 'GET_CREARCONTACTOSOCIALES';
export const GET_REDESSOCIALES = 'GET_REDESSOCIALES';
export const MODAL_CREARCONTACTOSOCIALES = 'MODAL_CREARCONTACTOSOCIALES';
export const MODAL_EDITARCONTACTOSOCIALES = 'MODAL_EDITARCONTACTOSOCIALES';
export const MODAL_ENVIOCORREOMENSAJERECHAZO =
  'MODAL_ENVIOCORREOMENSAJERECHAZO';
export const MODAL_ENVIOCORREORECHAZOSOLICITUD =
  'MODAL_ENVIOCORREORECHAZOSOLICITUD';
export const GET_FILTERPRESTAMOS = 'GET_FILTERPRESTAMOS';
export const GET_LISTPRESTAMOSGESTOR = 'GET_LISTPRESTAMOSGESTOR';
export const GET_PRESTAMOSGESTORAPROBADO = 'GET_PRESTAMOSGESTORAPROBADO';
export const GET_PRESTAMOSGESTORACOBRAR = 'GET_PRESTAMOSGESTORACOBRAR';
export const GET_PRESTAMOSGESTORPROCESO = 'GET_PRESTAMOSGESTORPROCESO';
export const GET_PRESTAMOSGESTORPENDIENTE = 'GET_PRESTAMOSGESTORPENDIENTE';
export const GET_PRESTAMOSGESTORRECHAZADO = 'GET_PRESTAMOSGESTORRECHAZADO';

//general types
export const OPENNOTIFICACION = 'OPENNOTIFICACION';
export const CLOSENOTIFICACION = 'CLOSENOTIFICACION';
export const SETNOTIFICACION = 'SETNOTIFICACION';

// SuperAdmin types
export const GET_INGRESOS = 'GET_INGRESOS';
export const GET_EGRESOS = 'GET_EGRESOS';
export const MODAL_INGRESOSCONTABLES = 'MODAL_INGRESOSCONTABLES';
export const MODAL_EGRESOSCONTABLES = 'MODAL_EGRESOSCONTABLES';
export const MODAL_PAGOS = 'MODAL_PAGOS';
export const MODAL_EDITARMOVIMIENTOSCONTABLES =
  'MODAL_EDITARMOVIMIENTOSCONTABLES';
export const GET_LISTPRESTAMOSAPROBADOS = 'GET_LISTPRESTAMOSAPROBADOS';
export const GET_PAGOSREALIZADOS = 'GET_PAGOSREALIZADOS';
export const GET_ABONOSREALIZADOS = 'GET_ABONOSREALIZADOS';
export const MODAL_NOTIFICACIONES = 'MODAL_NOTIFICACIONES';
export const MODAL_EDITARNOTIFICACIONES = 'MODAL_EDITARNOTIFICACIONES';
export const GET_NOTIFICACIONES = 'GET_NOTIFICACIONES';
export const GET_RESGISTROACTIVIDAD = 'GET_RESGISTROACTIVIDAD';
export const GET_LISTSINFONDOS = 'GET_LISTSINFONDOS';

// configuracion Types
export const REDESCHAMOPRESTA = 'REDESCHAMOPRESTA';
export const LISTADOACTIVO = 'LISTADOACTIVO';
