import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import $ from 'jquery';
import feather from 'feather-icons';
import { app } from '../config/firebaseConfig';
import { GET_CONFIGURACION } from '../redux/types';

export default function Modal(props) {
  const dispatch = useDispatch();

  //========== USE-STATES ==========//
  const [config, setConfig] = useState({
    diasMinimoPago: '',
    fondo: 100,
    informacion: '',
    montoInicialMaximoPrestamo: '',
    montoMaximoPrestamo: '',
    politicas: '',
    porcentajeIncremento: '',
    requisitos: '',
    tasaDolar: '',
    tasaInteresCompuesto: '',
    terminos: '',
  });

  useEffect(() => {
    getConfiguracion();
    (function ($) {
      feather.replace({ 'stroke-width': 1.5 });
    })($);
  }, []);

  const getConfiguracion = () => {
    app
      .firestore()
      .collection('configuracion')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setConfig(doc.data());
          dispatch({ type: GET_CONFIGURACION, payload: doc.data() });
          // calculateInteresTasa(5, 2, doc.data().tasaInteresCompuesto);
        }
      });
  };

  return (
    <>
      <div
        // className="modal overflow-y-auto show justify-center items-center flex "
        className="modal overflow-y-auto show"
        style={{
          marginTop: 0,
          marginLeft: 0,
          paddingLeft: 0,
          zIndex: 51,
          width: '100%',
          height: '100%',
        }}
      >
        <div
          className={
            'modal__content modal__content--' + (props.size ? props.size : 'lg')
          }
        >
          {config.fondo > 0 && (
            <>
              <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200">
                <h2 className="font-medium text-base mr-auto">{props.title}</h2>

                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={props.close}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>

                <div className="dropdown relative sm:hidden">
                  <a
                    className="dropdown-toggle w-5 h-5 block"
                    href="javascript:;"
                  >
                    <i
                      data-feather="more-horizontal"
                      className="w-5 h-5 text-gray-700"
                    ></i>
                  </a>
                  <div className="dropdown-box mt-5 absolute w-40 top-0 right-0 z-20">
                    <div className="dropdown-box__content box p-2">
                      <a
                        href="javascript:;"
                        className="flex items-center p-2 transition duration-300 ease-in-out bg-white hover:bg-gray-200 rounded-md"
                      >
                        <i
                          data-feather="file"
                          className="w-4 h-4 mr-2"
                        ></i>
                        Download Docs
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-5 grid grid-cols-12 gap-4 row-gap-3">
                {props.children}
              </div>
            </>
          )}

          {config.fondo <= 0 && <div className="">{props.children}</div>}

          {config.fondo > 0 && (
            <div className="px-5 py-3 text-right border-t border-gray-200">
              <button
                type="button"
                className="button w-20 border text-gray-700 mr-1"
                onClick={props.cancel}
              >
                Cancelar
              </button>
              {!props.customBtn && (
                <button
                  type="button"
                  className="button w-20 bg-theme-1 text-white"
                  onClick={props.save}
                >
                  Guardar
                </button>
              )}
              {props.customBtn && (
                <button
                  type="button"
                  className="button w-auto bg-theme-1 text-white"
                  onClick={props.save}
                >
                  {props.textcustomBtn}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
