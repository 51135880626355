import { SET_VIEW } from '../types'

const initialState = {
    view: "Dashboard",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_VIEW:
            return {
                ...state,
                view: action.payload
            }
            default:
                return state;
    }
}