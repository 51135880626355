import { SET_VIEW, LOGOUT } from '../types'
import firebase from 'firebase'


export const setView = (value) => dispatch => {
    dispatch({
        type: SET_VIEW,
        payload: value
    })
}

export const logout = () => dispatch => {
    firebase.auth().signOut();
    dispatch({
        type: LOGOUT,
    })
}