import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import feather from 'feather-icons';
import * as Icon from 'react-feather';
import Modal from '../../Modal';

import { getUsers } from '../../../redux/actions/adminAction';
import { getListPrestamos } from '../../../redux/actions/gestorActions';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

const GestionarUsuario = () => {
  const dispatch = useDispatch();

  //========== USE-STATE ==========//
  const [showModal, setShowModal] = useState(false);
  const [detallesUsuario, setDetallesUsuario] = useState({
    apellidos: '',
    areaTrabajo: '',
    codigoPais: '',
    descripcionActividad: '',
    email: '',
    fechaNacimiemnto: '',
    fechaRegistro: '',
    geo: '',
    habilitadoNuevoPrestamo: '',
    idUsuario: '',
    ingresoMensualUSD: '',
    localidad: '',
    metodosPagos: '',
    nombreEmpresa: '',
    nombres: '',
    nuevaTasaInteres: '',
    nuevoLimitePrestamo: '',
    numeroCedula: '',
    numeroPasaporte: '',
    pais: '',
    rol: '',
    sexo: '',
    telefonoOpcional: '',
    telegram: '',
    trabaja: '',
    whatsapp: '',
  });
  const [misPrestamos, setMisPrestamos] = useState([]);

  //========== USE-SELECTORS ==========//
  const listUsuarios = useSelector((state) => state.admin.allUsers);
  const prestamos = useSelector((state) => state.gestor.listPrestamos);

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();
    dispatch(getUsers());
    dispatch(getListPrestamos());
  }, []);

  const VerDatosUsuarios = (detallesUsuario) => {
    setDetallesUsuario({
      apellidos: detallesUsuario.apellidos,
      areaTrabajo: detallesUsuario.areaTrabajo,
      codigoPais: detallesUsuario.codigoPais,
      email: detallesUsuario.email,
      fechaNacimiemnto: detallesUsuario.fechaNacimiento,
      fechaRegistro: detallesUsuario.fechaRegistro,
      geo: detallesUsuario.geo,
      idUsuario: detallesUsuario.idUsuario,
      ingresoMensualUSD: detallesUsuario.ingresoMensualUSD,
      localidad: detallesUsuario.localidad,
      nombres: detallesUsuario.nombres,
      numeroCedula: detallesUsuario.numeroCedula,
      numeroPasaporte: detallesUsuario.numeroPasaporte,
      pais: detallesUsuario.pais,
      rol: detallesUsuario.rol,
      sexo: detallesUsuario.sexo,
      telefonoOpcional: detallesUsuario.telefonoOpcional,
      telegram: detallesUsuario.telegram,
      trabaja: detallesUsuario.trabaja,
      whatsapp: detallesUsuario.whatsapp,
    });

    let results = prestamos.filter(
      (item) => item.usuario.idUsuario === detallesUsuario.id
    );
    setMisPrestamos(results);

    setShowModal(true);
  };
  const close = () => {
    setShowModal(false);
  };
  const cancel = () => {
    setShowModal(false);
  };

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'nombre',
      headerName: 'NOMBRES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 240,
      valueGetter: (params) =>
        `${params.getValue('nombres') || ''} ${
          params.getValue('apellidos') || ''
        }`,
    },
    {
      field: 'fechaRegistro',
      headerName: 'REGISTRO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'date',
      width: 190,
    },
    {
      field: 'email',
      headerName: 'CORREO',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 325,
    },
    {
      field: 'pais',
      headerName: 'PAIS',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 135,
    },
    {
      field: 'usuarios',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 200,
      renderCell: (params) => (
        <div className='flex justify-center items-center'>
          <Link
            className='flex items-center mr-3 text-theme-12'
            to='#'
            onClick={() => VerDatosUsuarios(params.row)}
          >
            <Icon.Search className='w-5 h-5 mr-2 ml-5' />
            Ver Datos
            <Icon.FileText className='w-3 h-3 ml-2' />
          </Link>
        </div>
      ),
    },
  ];

  //========== Contenido Tabla Modal Mis Prestamos ==========//
  const columns1 = [
    {
      field: 'montoPrestado',
      headerName: 'Prestado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 115,
    },
    {
      field: 'fechaInicio',
      headerName: 'Fecha/Inicio',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 135,
    },
    {
      field: 'fechaCompromisoPago',
      headerName: 'Fecha/Pago',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 130,
    },
    {
      field: 'montoRealRecibido',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 135,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 110,
    },
    {
      field: 'pagado',
      headerName: 'Pagado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 105,
      valueGetter: (params) => {
        return `${params.row.pagado === true ? 'Si' : 'No'}`;
      },
    },
    {
      field: 'habilitadoNuevoPrestamo',
      headerName: 'Habilitado',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 120,
      valueGetter: (params) => {
        return `${params.row.usuario.habilitadoNuevoPrestamo}`;
      },
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  return (
    <>
      <div>
        <div className='flex border-b mt-3 mb-2'>
          <h2 className='text-lg font-medium ml-8'>Listado de Usuarios</h2>
        </div>
        <hr />
        <div className='grid grid-cols-12 gap-3'>
          <div className='col-span-12 overflow-auto lg:overflow-visible'>
            <div
              style={{ height: 460, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={listUsuarios}
                columns={columns}
                pageSize={50}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
          </div>
        </div>
        <div className='bg-theme-23 box p-2 text-center font-semibold mt-3'>
          <label htmlFor=''>
            Usuarios Registrados :
            <span className='rounded-lg bg-theme-3 text-white py-1 px-2 ml-1'>
              {listUsuarios.length}
            </span>
          </label>
        </div>
      </div>

      {/* ContenidoModal */}
      {showModal && (
        <Modal
          title='Datos Usuario'
          close={close}
          cancel={cancel}
          customBtn={true}
          textcustomBtn='Aceptar'
          save={() => {
            setShowModal(false);
          }}
          size='xl'
        >
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Nombres :
            </label>
            <span>{detallesUsuario.nombres}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Apellidos :
            </label>
            <span>{detallesUsuario.apellidos}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Correo :
            </label>
            <span>{detallesUsuario.email}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Cédula :
            </label>
            <span>{detallesUsuario.numeroCedula}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Pasaporte :
            </label>
            <span>{detallesUsuario.numeroPasaporte}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Fecha de nacimiento :
            </label>
            <span>{detallesUsuario.fechaNacimiemnto}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Sexo :
            </label>
            <span>{detallesUsuario.sexo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              País :
            </label>
            <span>{detallesUsuario.pais}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Localidad :
            </label>
            <span>{detallesUsuario.localidad}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Whatsapp activo :
            </label>
            <span>{detallesUsuario.whatsapp}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Otro número de contacto :
            </label>
            <span>{detallesUsuario.telefonoOpcional}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Usuario telegram :
            </label>
            <span>{detallesUsuario.telegram}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Trabaja :
            </label>
            <span>{detallesUsuario.trabaja}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Area de trabajo :
            </label>
            <span>{detallesUsuario.areaTrabajo}</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Ingreso mensual :
            </label>
            <span>{detallesUsuario.ingresoMensualUSD}</span>
            <span className='input-group-text ml-1'>(USD)</span>
          </div>
          <div className='col-span-12 sm:col-span-4 mb-1'>
            <label className='font-medium p-1 bg-theme-26 box ml-1 mr-1'>
              Fecha registro :
            </label>
            <span>{detallesUsuario.fechaRegistro}</span>
          </div>
          <div className='col-span-12 box bg-theme-2 p-1'>
            <div className='flex items-center mt-2 mb-1 col-span-12 sm:col-span-4'>
              <h3 className='font-medium m-auto p-1 bg-theme-26 box w-full'>
                Prestamos Solicitados :
              </h3>
            </div>
            <div className='col-span-12 overflow-auto lg:overflow-visible'>
              <div
                style={{ height: 260, width: '100%' }}
                className={classes.root}
              >
                <DataGrid
                  rows={misPrestamos}
                  columns={columns1}
                  pageSize={3}
                  autoHeight={true}
                  components={{
                    pagination: CustomPagination,
                  }}
                  localeText={{
                    columnMenuLabel: 'Menu',
                    columnMenuShowColumns: 'Show columns',
                    columnMenuFilter: 'Filtrar',
                    columnMenuSortAsc: 'Ordenar Ascendente',
                    columnMenuSortDesc: 'Ordenar Descendente',
                    columnMenuHideColumn: '',
                    columnMenuUnsort: '',
                    footerRowSelected: (count) => count !== 1,
                    //   ? `${count.toLocaleString()} Cambiando Rol`
                    //   : `${count.toLocaleString()} Cambiando Rol`,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default GestionarUsuario;
