import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { axiosInstance } from '../../../config/api';
import url from '../../../config/url';
import { app } from '../../../config/firebaseConfig';
import * as Icon from 'react-feather';
import moment from 'moment';
import Modal from '../../Modal';
import { success, confirm, custom } from '../../../helpers/alertas';

import {
  getUsers,
  getNotificaciones,
  registrarNotificacion,
  getListadoPrestamos,
  updateEditarNotificacion,
  modalEditarNotificacion,
  deleteNotificacion,
} from '../../../redux/actions/superAdminActions';

import { addLog } from '../../../redux/actions/sesionAction';
//========== Componentes  MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const Notificaciones = () => {
  const dispatch = useDispatch();

  //========== USE-STATES ==========//
  const [notificacion, setNotificacion] = useState({
    nombreNotificacion: '',
    textoNotificacion: '',
    clientes: '',
    frecuencias: '',
    correos: '',
    id: 0,
  });
  const [editNotificacion, setEditNotificacion] = useState({
    nombreNotificacion: '',
    textoNotificacion: '',
    frecuencias: '',
    clientes: '',
    correos: '',
    id: 0,
  });
  const [verListados, setVerListados] = useState(false);
  const [sinFondos, setSinFondos] = useState([]);
  const [notification, setNotification] = useState(null);
  const [clientesSeleccionados, setClientesSeleccionados] = useState([]);

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const listUsuarios = useSelector((state) => state.superadmin.allUsers);
  const prestamos = useSelector((state) => state.superadmin.listadoPrestamos);
  const listadoNotificaciones = useSelector(
    (state) => state.superadmin.listadoNotificaciones
  );
  const modalNotificaciones = useSelector(
    (state) => state.superadmin.modalNotificaciones
  );
  const modalEditarNotificaciones = useSelector(
    (state) => state.superadmin.modalEditarNotificaciones
  );

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getNotificaciones());
    dispatch(getListadoPrestamos());
    getUsuriosSinFondo();
  }, []);

  const getUsuriosSinFondo = () => {
    app
      .firestore()
      .collection('logsinfondos')
      .get()
      .then((data) => {
        let obj;
        let array = [];
        data.forEach((doc) => {
          obj = doc.data();
          obj.id = doc.id;
          array.push(obj);
        });
        setSinFondos(array);
      });
  };

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'nombreNotificacion',
      headerName: 'NOMBRE',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 150,
    },
    {
      field: 'textoNotificacion',
      headerName: 'TEXTO DE NOTIFICACION',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 330,
    },
    {
      field: 'superadmin',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 126,
      renderCell: (params) => (
        <div className='flex col-span-12 sm:col-span-6 text-theme-1 items-center'>
          <a
            className='flex items-center mr-3 ml-5 text-theme-1'
            href='#!'
            onClick={(e) => {
              e.preventDefault();
              editarNotificacion(params.row);
            }}
          >
            <Icon.Edit className='w-5 h-5 mr-1' />
          </a>
          <a
            className='flex items-center mr-3 text-theme-6'
            href='#!'
            onClick={(e) => {
              e.preventDefault();
              elimiarNotificacion(params.row);
            }}
          >
            <Icon.Trash2 className='w-5 h-5 mr-1' />
          </a>
          <a
            className='flex items-center mr-3 ml-5 text-theme-1'
            href='#!'
            onClick={(e) => {
              e.preventDefault();
              editarNotificacion(params.row);
            }}
          >
            <Icon.Edit className='w-5 h-5 mr-1' />
          </a>
        </div>
      ),
    },
    {
      field: 'gestionar',
      headerName: 'GESTIONAR',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      type: 'number',
      width: 465,
      renderCell: (params) => (
        <>
          <div className='flex col-span-12 sm:col-span-6 mb-1'>
            <Icon.UserPlus className='flex w-4 h-4 mr-1 mt-8 text-theme-1 items-center' />

            <FormControl className={classe.formControl}>
              <InputLabel id='demo-mutiple-checkbox-label'>Clientes</InputLabel>
              <Select
                labelId='demo-mutiple-checkbox-label'
                id='demo-mutiple-checkbox'
                multiple
                name='clientes'
                value={params.row.clientes}
                onChange={(e) =>
                  seleccionarCliente(e, params.row, params.rowIndex)
                }
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {clientes.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      color='primary'
                      checked={params.row.clientes.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='flex col-span-12 sm:col-span-6 mb-1'>
            <Icon.RefreshCcw className='flex w-4 h-4 mr-1 mt-8 text-theme-1 items-center' />
            <FormControl className={classes.formControl}>
              <InputLabel id='demo-simple-select-label'>Frecuencias</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={params.row.frecuencias}
                onChange={(e) => seleccionarFrecuencia(e, params)}
              >
                {frecuencias.map((frec) => (
                  <MenuItem value={frec} key={frec}>
                    {' '}
                    {frec}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='flex col-span-12 sm:col-span-6 text-theme-1 items-center'>
            <a
              className='flex items-center mr-3 ml-5 text-theme-1'
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                sendNotification(params.row);
              }}
            >
              <Icon.Send className='w-6 h-6 mr-2 mt-2' />
            </a>
          </div>
        </>
      ),
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    margin: {
      color: 'white',
    },
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 165,
      maxWidth: 165,
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color='primary'
        variant='outlined'
        shape='rounded'
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  //===== Funciones y Metodos del Select de la Tabla =====//
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 1;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  const clientes = [
    'Iniciado',
    'Solventes',
    'PorVencer',
    'Vencidos',
    'Finalizados',
    'Todos',
    'Sin Fondo',
  ];
  const frecuencias = ['Ahora', 'Diario', 'Semanal', 'Quincenal', 'Mensual'];
  const classe = useStyles();

  //========== Funciones Modal Agregar Notificaciones ==========//
  const agregarNotificacion = () => {
    setNotificacion({
      nombreNotificacion: '',
      textoNotificacion: '',
      clientes: '',
      frecuencias: '',
      correos: '',
    });
    dispatch({ type: 'MODAL_NOTIFICACIONES', payload: true });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNotificacion({ ...notificacion, [name]: value });
  };
  const closeModal = () => {
    dispatch({ type: 'MODAL_NOTIFICACIONES', payload: false });
  };
  const cancelModal = () => {
    dispatch({ type: 'MODAL_NOTIFICACIONES', payload: false });
  };
  const guardarNotificacion = () => {
    if (
      notificacion.nombreNotificacion === '' ||
      notificacion.textoNotificacion === '' ||
      notificacion.idNotificaciones === ''
    ) {
      custom('Debe llenar todos los datos', 'warning');
    } else {
      dispatch(
        registrarNotificacion({
          nombreNotificacion: notificacion.nombreNotificacion,
          textoNotificacion: notificacion.textoNotificacion,
          clientes: [],
          correos: [],
          frecuencias: '',
          idNotificaciones: '',
        })
      );
      dispatch(
        addLog({
          accion: `${'Se Ha Creado Nueva Notificacíon: De Nombre '} ${
            notificacion.nombreNotificacion
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
    }
  };

  //========== Funciones Modal Editar Notificaciones ==========//
  const closeEditarNotificacion = () => {
    dispatch(modalEditarNotificacion(false));
  };
  const cancelEditarNotificacion = () => {
    dispatch(modalEditarNotificacion(false));
  };
  const guardarEditarNotificacion = () => {
    if (
      notificacion.nombreNotificacion === '' ||
      notificacion.textoNotificacion === ''
    ) {
      custom('Debe llenar todos los datos', 'warning');
    } else {
      dispatch(
        updateEditarNotificacion({
          nombreNotificacion: notificacion.nombreNotificacion,
          textoNotificacion: notificacion.textoNotificacion,
          clientes: notificacion.clientes,
          correos: notificacion.correos,
          frecuencias: notificacion.frecuencias,
          idNotificaciones: notificacion.id,
        })
      );
      dispatch(
        addLog({
          accion: `${'Se Ha Editado Una Notificacion: De Nombre '} ${
            notificacion.nombreNotificacion
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      dispatch(modalEditarNotificacion(false));
    }
  };
  const editarNotificacion = (params) => {
    setEditNotificacion(true);
    setNotificacion(params);
    dispatch(modalEditarNotificacion(true));
  };
  const elimiarNotificacion = async (params) => {
    const resp = await confirm(
      'Seguro que Desea Eliminar la Notificacion.',
      'question'
    );
    if (resp) {
      dispatch(deleteNotificacion(params));
      dispatch(
        addLog({
          accion: `${'Se Ha Eliminado Una Notificacion: De Nombre '} ${
            params.nombreNotificacion
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      success('Notificacion Eliminada Exitosamente.', 'success');
    }
  };

  //===== Funciones y Metodos de Gestionar Notificaciones =====//
  const seleccionarCliente = (e, vuelta, index) => {
    let seleccionCliente = e.target.value;
    let arrayData = [...listadoNotificaciones];
    arrayData[index]['clientes'] = seleccionCliente;
    setClientesSeleccionados(seleccionCliente);
    dispatch({
      type: 'GET_NOTIFICACIONES',
      payload: arrayData,
    });
    //setPersonName(e.target.value);
    let ArrayConcat = [];
    let actualizar = false;
    seleccionCliente.forEach((element) => {
      switch (element) {
        case 'Iniciado':
          listUsuarios.map((row) => {
            if (row.rol === 'Usuario') {
              let consiguio = prestamos.filter(
                (prest) => prest.usuario.email === row.email
              );
              if (consiguio.length === 0) {
                ArrayConcat.push(row.email);
              }
            }
          });
          actualizar = true;
          break;
        case 'Todos':
          listUsuarios.map((fila) => {
            if (fila.rol === 'Usuario') {
              ArrayConcat.push(fila.email);
            }
          });
          actualizar = true;
          break;
        case 'Solventes':
          prestamos.map((row) => {
            if (row.pagado === true) {
              ArrayConcat.push(row.usuario.email);
            }
          });
          actualizar = true;
          break;
        case 'PorVencer':
          prestamos.map((item) => {
            let date = moment(new Date()).format('YYYY-MM-DD');
            let dia = item.fechaCompromisoPago;
            if (date <= dia && item.pagado !== true) {
              ArrayConcat.push(item.usuario.email);
            }
          });
          actualizar = true;
          break;
        case 'Vencidos':
          prestamos.map((item) => {
            let date = moment(new Date()).format('YYYY-MM-DD');
            let dia = item.fechaCompromisoPago;
            if (date > dia && item.pagado !== true) {
              ArrayConcat.push(item.usuario.email);
            }
          });
          console.log('array', ArrayConcat);
          actualizar = true;
          break;
        case 'Finalizados':
          prestamos.map((item) => {
            if (item.estado === 'finalizado') {
              ArrayConcat.push(item.usuario.email);
            }
          });
          actualizar = true;
          break;
        case 'Sin Fondo':
          setVerListados(true);
          setNotification(vuelta);
          actualizar = false;
          break;
        default:
          ArrayConcat = [];
          actualizar = true;
          break;
      }
    });

    if (actualizar) {
      app
        .firestore()
        .collection('notificaciones')
        .doc(`${vuelta.id}`)
        .update({ clientes: seleccionCliente, correos: ArrayConcat })
        .then((res) => {
          dispatch(getNotificaciones());
          dispatch(
            addLog({
              accion: `Actualizado lista de clientes a notificación ${vuelta.nombreNotificacion}`,
              fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              usuario: myUser.email,
            })
          );
        });
    }
    //success("Cambio de clientes realizada exitosamente.", "success");
  };
  const seleccionraListado = (listado) => {
    const arrayCorreos = listado.usuarios;
    console.log(clientesSeleccionados);
    console.log(notification);
    setVerListados(false);
    app
      .firestore()
      .collection('notificaciones')
      .doc(`${notification.id}`)
      .update({ clientes: clientesSeleccionados, correos: arrayCorreos })
      .then((res) => {
        dispatch(getNotificaciones());
        dispatch(
          addLog({
            accion: `${'Los Clientes Cambiados a: '}  ${notificacion.clientes}`,
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            usuario: myUser.email,
          })
        );
      });
  };
  const handleClose = () => {
    setVerListados(false);
  };
  const seleccionarFrecuencia = (e, params) => {
    app
      .firestore()
      .collection('notificaciones')
      .doc(`${params.row.id}`)
      .update({
        frecuencias: e.target.value,
      })
      .then((res) => {
        dispatch(getNotificaciones());
        dispatch(
          addLog({
            accion: `${'La Frecuencia Fue Cambiada a: '}  ${
              params.row.frecuencias
            }`,
            fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            usuario: myUser.email,
          })
        );
      });
    // success("Cambio de frecuencia realizada exitosamente.", "success");
  };
  const sendNotification = async (notificacion) => {
    console.log(notificacion);
    try {
      let response = await axiosInstance.post(`/notifications/send`, {
        notificacion,
      });
      success('Notificaciones enviadas exitosamente.', 'success');
    } catch (err) {
      success(err, 'error');
    }
  };

  return (
    <>
      <div className='flex border-b mt-3 mb-2'>
        <h2 className='text-lg font-medium ml-8'>Listado de Notificaciones</h2>
      </div>
      <hr />
      <div className='grid grid-cols-12 gap-3'>
        <div className='col-span-12 overflow-auto lg:overflow-visible'>
          <div style={{ height: 710, width: '100%' }} className={classes.root}>
            <DataGrid
              rows={listadoNotificaciones}
              columns={columns}
              pageSize={50}
              // rowHeight={125}
              // headerHeight={25}
              // autoHeight={true}
              components={{
                pagination: CustomPagination,
              }}
              localeText={{
                columnMenuLabel: 'Menu',
                columnMenuShowColumns: 'Show columns',
                columnMenuFilter: 'Filtrar',
                columnMenuSortAsc: 'Ordenar Ascendente',
                columnMenuSortDesc: 'Ordenar Descendente',
                columnMenuHideColumn: '',
                columnMenuUnsort: '',
                footerRowSelected: (count) => count !== 1,
                //   ? `${count.toLocaleString()} Cambiando Rol`
                //   : `${count.toLocaleString()} Cambiando Rol`,
              }}
            />
          </div>
          {/* Boton Modal Agregar Nueva Notificacion */}
          <div className='cursor-pointer'>
            <button
              className='flex box text-white bg-theme-3 p-3 ml-3 mt-3 items-center'
              onClick={agregarNotificacion}
            >
              <Icon.FilePlus className='w-4 h-4 mr-1' />
              Agregar Notificación
            </button>
          </div>
        </div>
      </div>

      {/*===== Modal Agregar Notificaciones =====*/}
      {modalNotificaciones && (
        <Modal
          title='Agregar Notificación'
          close={closeModal}
          save={guardarNotificacion}
          cancel={cancelModal}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Nombre de Notificacón :
            </label>
            <input
              type='text'
              name='nombreNotificacion'
              className='w-full rounded-full border input p-1 mt-2'
              autoFocus={true}
              placeholder=' Nombre Notificación'
              value={notificacion.nombreNotificacion}
              onChange={handleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Texto de la Notificacón :
            </label>
            <textarea
              type='text'
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Agregar Texto...'
              name='textoNotificacion'
              value={notificacion.textoNotificacion}
              onChange={handleChange}
              rows='5'
            />
          </div>
        </Modal>
      )}

      {/*===== Modal Editar Notificaciones =====*/}
      {modalEditarNotificaciones && (
        <Modal
          title='Editar Notificación'
          close={closeEditarNotificacion}
          save={guardarEditarNotificacion}
          cancel={cancelEditarNotificacion}
        >
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Nombre de Notificacón :
            </label>
            <input
              type='text'
              name='nombreNotificacion'
              className='w-full rounded-full border input p-1 mt-2'
              autoFocus={true}
              placeholder=' Nombre Notificación'
              value={notificacion.nombreNotificacion}
              onChange={handleChange}
            />
          </div>
          <div className='intro-y col-span-12 sm:col-span-12'>
            <label className='font-medium bg-theme-26 box p-1'>
              Texto de la Notificacón :
            </label>
            <textarea
              type='text'
              data-feature='basic'
              className='summernote bg-theme-2 input w-full rounded-md mt-2'
              placeholder=' Agregar Texto...'
              name='textoNotificacion'
              value={notificacion.textoNotificacion}
              onChange={handleChange}
              rows='5'
            />
          </div>
        </Modal>
      )}

      <Dialog
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={verListados}
      >
        <DialogTitle id='simple-dialog-title'>Selccione un listado</DialogTitle>
        <List>
          {sinFondos.map((list) => {
            const texto = `Desde ${moment(list.fechaInicio).format(
              'DD-MM-YYYY HH:mm:ss'
            )} hasta ${moment(list.fechaFin).format('DD-MM-YYYY HH:mm:ss')}`;
            return (
              <ListItem
                button
                onClick={() => seleccionraListado(list)}
                key={list.id}
              >
                <ListItemText primary={texto} />
              </ListItem>
            );
          })}
        </List>
      </Dialog>
    </>
  );
};

export default Notificaciones;
