import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import feather from 'feather-icons';
import * as Icon from 'react-feather';
import { ExportToCsv } from 'export-to-csv';

import { getListSinFondos } from '../../../redux/actions/superAdminActions';

const optionsExport = {
  fieldSeparator: ',',
  quoteStrings: '',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Usuarios pendientes',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const SinFondos = () => {
  const dispatch = useDispatch();

  //========== USE-SELECTORS ==========//
  const listUsuariosSinFondos = useSelector(
    (state) => state.superadmin.listUsuariosSinFondos
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();

    dispatch(getListSinFondos());
  }, []);

  //====== Funciones y Metodos de Exportar ======//
  const exportar = (e) => {
    const csvExporter = new ExportToCsv(optionsExport);
    const dataExportar = listUsuariosSinFondos.map((user) => {
      return {
        fecha: user.fechaInicio,
        email: user.usuarios.join('; '),
      };
    });
    csvExporter.generateCsv(dataExportar);
  };

  return (
    <>
      <div className="flex border-b mt-3 mb-2">
        <h2 className="text-lg font-medium ml-8">
          Listado de usuarios ingresados
        </h2>
      </div>
      <hr />

      <div className="intro-y col-span-12 mb-3 bg-theme-2 box p-2 w-full overflow-auto lg:overflow-visible">
        <table className="table-report w-full p-3">
          <thead>
            <tr className="box bg-theme-26 p-1">
              <th className="whitespace-no-wrap text-center p-3">
                FECHA INICIO
              </th>
              <th className="whitespace-no-wrap text-center">FECHA FINAL</th>
              <th className="whitespace-no-wrap text-center">ACTIVO</th>
              <th className="whitespace-no-wrap text-center">USUARIOS</th>
            </tr>
          </thead>
          <tbody>
            {listUsuariosSinFondos.map((list) => (
              <tr className="intro-x text-center">
                <td className="w-40 px-3">{list.fechaInicio}</td>
                <td className="w-40 px-3">{list.fechaFin}</td>
                <td className="w-20 px-3">
                  {list.activo === true ? 'Si' : 'No'}
                </td>
                <td className="h-auto text-left px-3">
                  {list.usuarios.join('; ')}
                </td>
              </tr>
            ))}
          </tbody>
          <div className="flex mt-3">
            {/* Boton Exportar*/}
            <div className="cursor-pointer mr-3">
              <button
                className="flex box text-white bg-theme-3 p-2 items-center"
                onClick={exportar}
              >
                <Icon.Upload className="w-4 h-4 mr-1" />
                <p>Exportar Archivo</p>
              </button>
            </div>
          </div>
        </table>
      </div>
    </>
  );
};

export default SinFondos;
