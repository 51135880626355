export default {
  bancosPanama: [
    { codigo: "0001", nombre: "Allbank Corp" },
    { codigo: "0002", nombre: "BAC International Bank, Inc." },
    { codigo: "0003", nombre: "Balboa Bank & Trust Corp" },
    { codigo: "0004", nombre: "Banco Aliado S.A." },
    { codigo: "0005", nombre: "Banco Azteca (Panamá), S.A." },
    { codigo: "0006", nombre: "Banco BAC de Panamá, S.A." },
    { codigo: "0007", nombre: "Banco Bolivariano (Panamá), S.A." },
    { codigo: "0008", nombre: "Banco Citibank (Panamá,) S.A." },
    { codigo: "0009", nombre: "Banco Davivienda (Panamá) S.A." },
    { codigo: "0010", nombre: "Banco de Bogotá, S.A." },
    { codigo: "0011", nombre: "Banco del Pacífico (Panamá), S.A." },
    { codigo: "0012", nombre: "Banco Delta, S.A." },
    { codigo: "0013", nombre: "Banco Ficohsa (Panamá), S.A." },
    { codigo: "0014", nombre: "Banco G&T Continental (Panamá) S.A. (BMF)" },
    { codigo: "0015", nombre: "Banco HIPOTECARIO NACIONAL" },
    { codigo: "0016", nombre: "Banco General, S.A." },
    {
      codigo: "0017",
      nombre: "Banco Internacional de Costa Rica, S.A (BICSA)",
    },
    { codigo: "0018", nombre: "Banco La Hipotecaria, S.A." },
    { codigo: "0019", nombre: "Banco Lafise Panamá S.A." },
    {
      codigo: "0020",
      nombre: "Banco Latinoamericano de Comercio Exterior, S.A. (BLADEX)",
    },
    { codigo: "0021", nombre: "Banco Nacional de Panamá" },
    { codigo: "0022", nombre: "Banco Panamá, S.A" },
    {
      codigo: "0023",
      nombre: "Banco Panameño de la Vivienda, S.A. (BANVIVIENDA)",
    },
    { codigo: "0024", nombre: "Banco Pichincha Panamá, S.A." },
    {
      codigo: "0025",
      nombre: "Banco Prival, S.A. (Español) o Prival Bank, S.A. (en inglés)",
    },
    { codigo: "0026", nombre: "Banco Universal, S.A." },
    { codigo: "0027", nombre: "Bancolombia S.A." },
    { codigo: "0028", nombre: "Banesco S.A." },
    { codigo: "0029", nombre: "BANISI, S.A." },
    { codigo: "0030", nombre: "Banistmo S.A." },
    { codigo: "0031", nombre: "Bank Leumi-Le Israel B.M." },
    { codigo: "0032", nombre: "Bank of China Limited" },
    { codigo: "0033", nombre: "BBP Bank S.A." },
    { codigo: "0034", nombre: "BCT Bank International S.A." },
    { codigo: "0035", nombre: "Caja de Ahorros" },
    { codigo: "0036", nombre: "Capital Bank Inc." },
    { codigo: "0037", nombre: "Citibank, N.A. Sucursal Panamá" },
    { codigo: "0038", nombre: "Credicorp Bank S.A." },
    { codigo: "0039", nombre: "FPB Bank Inc." },
    { codigo: "0040", nombre: "Global Bank Corporation" },
    { codigo: "0041", nombre: "Korea Exchange Bank, Ltd." },
    { codigo: "0042", nombre: "Mega International Commercial Bank Co. Ltd." },
    { codigo: "0043", nombre: "Mercantil Bank (Panamá), S.A." },
    { codigo: "0044", nombre: "Metrobank, S.A." },
    { codigo: "0045", nombre: "MiBanco, S.A.BMF" },
    { codigo: "0046", nombre: "MMG Bank Corporation" },
    { codigo: "0047", nombre: "Multibank Inc." },
    { codigo: "0048", nombre: "Produbank (Panamá) S.A." },
    { codigo: "0049", nombre: "St. Georges Bank & Company, Inc." },
    { codigo: "0050", nombre: "The Bank of Nova Scotia (Panamá), S.A." },
    { codigo: "0051", nombre: "The Bank of Nova Scotia (SCOTIABANK)" },
    { codigo: "0052", nombre: "Towerbank International Inc." },
    { codigo: "0053", nombre: "Unibank, S.A." },
  ],
};
