import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import feather from 'feather-icons';
import * as Icon from 'react-feather';
import moment from 'moment';
import Modal from '../../Modal';
import { success, confirm, custom } from '../../../helpers/alertas';

import {
  getMensajesPredefinidos,
  modalAgregarMensaje,
  modalEditarMensaje,
  saveAgregarMensajes,
  updateEditarMensaje,
  deleteMensaje,
} from '../../../redux/actions/adminAction';

import { addLog } from '../../../redux/actions/sesionAction';

//========== Import Data Grid MaterialUI ==========//
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

const MensajesPredeterminados = () => {
  const dispatch = useDispatch();

  //========== USE-STATES ==========//
  const [mensaje, setMensaje] = useState({
    nombreMensaje: '',
    textoMensaje: '',
    id: 0,
  });
  const [editarMensaje, setEditarMensaje] = useState({
    nombreMensaje: '',
    textoMensaje: '',
    id: 0,
  });

  //========== USE-SELECTORS ==========//
  const myUser = useSelector((state) => state.sesion.myUser);
  const mensajespredefinidos = useSelector(
    (state) => state.admin.mensajespredefinidos
  );
  const modalAgregarMensajes = useSelector(
    (state) => state.admin.modalAgregarMensajes
  );
  const modalEditarMensajes = useSelector(
    (state) => state.admin.modalEditarMensajes
  );

  useEffect(() => {
    (function () {
      'use strict';
      feather.replace({ 'stroke-width': 1.5 });
    })();

    dispatch(getMensajesPredefinidos());
  }, []);

  //========== Contenido Tabla ==========//
  const columns = [
    {
      field: 'nombreMensaje',
      headerName: 'NOMBRE DEL MENSAJE',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 230,
    },
    {
      field: 'textoMensaje',
      headerName: 'TEXTO DEL MENSAJE',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 630,
    },
    {
      field: 'admin',
      headerName: 'ACCIONES',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width: 230,
      renderCell: (params) => (
        <div className="flex col-span-12 sm:col-span-6 text-theme-1 items-center font-medium">
          <a
            className="flex items-center mr-3 text-theme-1"
            href="javascript:;"
            onClick={(e) => editarMensajes(params.row)}
          >
            <Icon.Edit className="w-4 h-4 mr-1" />
            Editar
          </a>

          <a
            className="flex items-center mr-3 text-theme-6"
            href="javascript:;"
            onClick={(e) => elimiarMensajes(params.row)}
          >
            <Icon.Trash2 className="w-4 h-4 mr-1" />
            Eliminar
          </a>
        </div>
      ),
    },
  ];

  //========== Funciones y Metodos de la Tabla ==========//
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(232, 238, 255, 0.9)',
      },
    },
  }));
  const classes = useStyles();
  function CustomPagination(props) {
    const { pagination, api } = props;
    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={pagination.page}
        count={pagination.pageCount}
        // @ts-expect-error
        renderItem={(props2) => (
          <PaginationItem
            {...props2}
            disableRipple
          />
        )}
        onChange={(event, value) => api.current.setPage(value)}
      />
    );
  }

  //========== Funciones Modal Agregar Mensajes ==========//
  const agregarNuevoMensaje = () => {
    setMensaje({
      nombreMensaje: '',
      textoMensaje: '',
    });

    dispatch(modalAgregarMensaje(true));
  };
  const changeAgregarMensajes = (e) => {
    const { name, value } = e.target;
    setMensaje({ ...mensaje, [name]: value });
  };
  const closeAgregarMensajes = () => {
    dispatch(modalAgregarMensaje(false));
  };
  const cancelAgregarMensajes = () => {
    dispatch(modalAgregarMensaje(false));
  };
  const guardarAgregarMensajes = () => {
    if (
      mensaje.nombreMensaje === '' ||
      mensaje.textoMensaje === '' ||
      mensaje.idMensajePredefinido === ''
    ) {
      custom('Debe llenar todos los datos', 'warning');
    } else {
      dispatch(
        saveAgregarMensajes({
          nombreMensaje: mensaje.nombreMensaje,
          textoMensaje: mensaje.textoMensaje,
          idMensajePredefinido: '',
        })
      );

      dispatch(
        addLog({
          accion: `${'Se Ha Creado Un Mensaje: De Nombre '} ${
            mensaje.nombreMensaje
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );

      dispatch(modalAgregarMensaje(false));
    }
  };

  //========== Funciones Modal Editar Mensajes ==========//
  const closeEditarMensajes = () => {
    dispatch(modalEditarMensaje(false));
  };
  const cancelEditarMensajes = () => {
    dispatch(modalEditarMensaje(false));
  };
  const guardarEditarMensajes = () => {
    if (mensaje.nombreMensaje === '' || mensaje.textoMensaje === '') {
      custom('Debe llenar todos los datos', 'warning');
    } else {
      dispatch(
        updateEditarMensaje({
          nombreMensaje: mensaje.nombreMensaje,
          textoMensaje: mensaje.textoMensaje,
          idMensajePredefinido: mensaje.id,
        })
      );

      dispatch(
        addLog({
          accion: `${'Se Ha Editado Un Mensaje: De Nombre '} ${
            mensaje.nombreMensaje
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      dispatch(modalEditarMensaje(false));
    }
  };
  const editarMensajes = (params) => {
    setEditarMensaje(true);
    setMensaje(params);
    dispatch(modalEditarMensaje(true));
  };
  const elimiarMensajes = async (params) => {
    const resp = await confirm(
      'Seguro que Desea Eliminar el Mensaje.',
      'question'
    );

    if (resp) {
      dispatch(deleteMensaje(params));

      dispatch(
        addLog({
          accion: `${'Se Ha Eliminado Un Mensaje: De Nombre '} ${
            params.nombreMensaje
          }`,
          fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          usuario: myUser.email,
        })
      );
      success('Mensaje Eliminado Exitosamente.', 'success');
    }
  };

  return (
    <>
      <div>
        <div className="flex border-b mt-3 mb-2">
          <h2 className="text-lg font-medium ml-8">Listado de Mensajes</h2>
        </div>
        <hr />

        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 overflow-auto lg:overflow-visible">
            <div
              style={{ height: 450, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={mensajespredefinidos}
                columns={columns}
                pageSize={50}
                components={{
                  pagination: CustomPagination,
                }}
                localeText={{
                  columnMenuLabel: 'Menu',
                  columnMenuShowColumns: 'Show columns',
                  columnMenuFilter: 'Filtrar',
                  columnMenuSortAsc: 'Ordenar Ascendente',
                  columnMenuSortDesc: 'Ordenar Descendente',
                  columnMenuHideColumn: '',
                  columnMenuUnsort: '',
                  footerRowSelected: (count) => count !== 1,
                  //   ? `${count.toLocaleString()} Cambiando Rol`
                  //   : `${count.toLocaleString()} Cambiando Rol`,
                }}
              />
            </div>
            {/* Boton Modal Agregar Nuevo mensaje */}
            <div className="cursor-pointer">
              <button
                className="flex box text-white bg-theme-3 p-3 ml-3 mt-3"
                onClick={agregarNuevoMensaje}
              >
                <Icon.FilePlus className="w-4 h-4 mr-1" />
                Agregar Nuevo Mensaje
              </button>
            </div>
          </div>
        </div>

        {/*===== Modal Agregar Mensajes =====*/}
        {modalAgregarMensajes && (
          <Modal
            title="Agregar Nuevos Mensajes"
            close={closeAgregarMensajes}
            save={guardarAgregarMensajes}
            cancel={cancelAgregarMensajes}
          >
            <div className="intro-y col-span-12 sm:col-span-12">
              <label className="font-medium bg-theme-26 box p-1">
                Nombre del Mensaje :
              </label>
              <input
                type="text"
                name="nombreMensaje"
                className="w-full rounded-full border input p-1 mt-2"
                autoFocus={true}
                placeholder=" Nombre Mensaje"
                value={mensaje.nombreMensaje}
                onChange={changeAgregarMensajes}
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-12">
              <label className="font-medium bg-theme-26 box p-1">
                Texto del Mensaje :
              </label>
              <textarea
                type="text"
                data-feature="basic"
                className="summernote bg-theme-2 input w-full rounded-md mt-2"
                placeholder=" Agregar Mensaje..."
                name="textoMensaje"
                value={mensaje.textoMensaje}
                onChange={changeAgregarMensajes}
                rows="5"
              />
            </div>
          </Modal>
        )}

        {/*===== Modal Editar Mensajes =====*/}
        {modalEditarMensajes && (
          <Modal
            title="Editar Nuevos Mensajes"
            close={closeEditarMensajes}
            save={guardarEditarMensajes}
            cancel={cancelEditarMensajes}
          >
            <div className="intro-y col-span-12 sm:col-span-12">
              <label className="font-medium bg-theme-26 box p-1">
                Nombre del Mensaje :
              </label>
              <input
                type="text"
                name="nombreMensaje"
                className="w-full rounded-full border input p-1 mt-2"
                autoFocus={true}
                placeholder=" Nombre Mensaje"
                value={mensaje.nombreMensaje}
                onChange={changeAgregarMensajes}
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-12">
              <label className="font-medium bg-theme-26 box p-1">
                Texto del Mensaje :
              </label>
              <textarea
                data-feature="basic"
                className="summernote bg-theme-2 input w-full rounded-md mt-2"
                placeholder=" Agregar Mensaje..."
                name="textoMensaje"
                value={mensaje.textoMensaje}
                onChange={changeAgregarMensajes}
                rows="5"
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default MensajesPredeterminados;
